import React from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import CustomModal from "../../shared/CustomModal";
import TableCard from "./TableCard";
import ReseatPlayerSeatsModal from "./ReseatPlayerSeatsModal";
import SwapSeatsModal from "./SwapSeatsModal";

class ReseatPlayerTablesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleReseatPlayerSeats: false,
      selectedTable: {},
      hideMe: false,
      hideLockedTables: props.hideLockedTables
    };
  }

  onChooseSeatClick = (table) => {
    console.log("tsfsdfsdfsd", table)
    if (table) {
      this.setState({
        toggleReseatPlayerSeats: true,
        selectedTable: table,
        hideMe: true,
      });
    }
  };

  toggleSeats = () => {
    this.setState({
      hideMe: false,
      toggleReseatPlayerSeats: false,
    });
  };

  setNewSeat = (seat) => {
    this.props.setNewSeat(this.state.selectedTable, seat);
    this.props.toggle();
  };

  render() {
    let tables = !this.props.selectedTable
      ? this.props.variant.tables
      : this.props.variant.tables.filter((table) => table.id !== this.props.selectedTable.id && table.state !== "closed");

    if (this.state.hideLockedTables) {
      tables = tables.filter(t => !t.seatingLocked)
    }

    return (
      <>
        {this.props.isOpen && (
          <CustomModal
            title={`Reseat ${this.props.selectedPlayer.firstName} ${this.props.selectedPlayer.lastName}`}
            open={true}
            closeHandler={this.props.toggle}
            className="reseat-player-tables"
            dialogClassName={`${this.state.hideMe ? "hidden" : ""}`}
          >
            {tables.length === 0 && <Row><h3>No tables reseat to</h3></Row>}

            <Row className="tables">
              {tables.map((table) =>
                <TableCard
                  onClickEvent={this.onChooseSeatClick}
                  table={{ ...table }}
                  reseat
                  variant={this.props.variant}
                />
              )}
            </Row>
          </CustomModal>
        )}
        {this.state.toggleReseatPlayerSeats && !this.props.isSwap && (
          <ReseatPlayerSeatsModal
            isOpen={true}
            toggle={this.toggleSeats}
            variant={this.props.variant}
            selectedTable={this.state.selectedTable}
            reservedSeats={this.props.reservedSeats}
            setNewSeat={this.setNewSeat}
            favorite={this.props.favoriteSeats}
            onAction={this.props.onAction}
          />
        )}

        {this.state.toggleReseatPlayerSeats && this.props.isSwap && (
          <SwapSeatsModal
            isOpen={true}
            toggle={this.toggleSeats}
            variant={this.props.variant}
            selectedTable={this.state.selectedTable}
            action="SwapSeats"
            player={this.props.selectedPlayer}
            onAction={this.props.onAction}
          />
        )}
      </>
    );
  }
}

ReseatPlayerTablesModal.defaultProps = {
  variant: { tables: [] },
};

export default connect(null, null)(ReseatPlayerTablesModal);
