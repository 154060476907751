import React from 'react';
import { Button } from 'reactstrap';
import CustomModal from './CustomModal';

class PlayerActionConfirmationModal extends React.Component {
  render() {
    return (
      <>
        {this.props.isOpen && (
          <CustomModal open={this.props.isOpen} closeHandler={this.props.toggle} className="action-confirm-modal" dialogClassName="modal-small-width">
            <>
              {this.props.icon}
              <h2>{this.props.action}</h2>
              <p>{this.props.text}</p>
              <div>
                <Button color="primary" className="confirm-btn" onClick={this.props.doAction}>
                  {`Confirm ${this.props.action}`}
                </Button>
                <Button className="cancel-btn outline m-1" onClick={this.props.toggle}>
                  Close
                </Button>
              </div>
            </>
          </CustomModal>
        )}
      </>
    );
  }
}

export default PlayerActionConfirmationModal;
