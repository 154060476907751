import React from 'react';
import Modal from 'react-bootstrap/Modal';

const CustomModal = (props) => (
  <Modal size="lg" show={props.open} className={`custom-modal ${props.className}`} dialogClassName={props.dialogClassName}>
    {props.imagesrc && <img src={props.imagesrc} height={props.height || '100px'} alt={props.alt || ''} />}
    {props.closeHandler && <div className="modal-close" onClick={props.closeHandler} />}
    <div className="modal-title">{props.title}</div>
    <div className="modal-text">{props.text}</div>
    {props.showLoading && <div className="modal-text">Loading...</div>}

    {props.children}
  </Modal>
);
export default CustomModal;
