import actionTypes from '../actiontypes/tournamentActionTypes';
import { isMultiday } from '../util/tournamentUtil';
import moment from "moment-mini";

const initState = {
  tournaments: [],
  finishedTournaments: undefined,
  tournamentValidationErrors: {},
  closed: [],
  tournament: {},
  variant: {},
  fees: [],
  tips: [],
  statistics: null,
  statisticsVariant: {},
  clockSettings: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },
  tournamentToSave: null,
  logs: [],
};

const getUpdatedSingleVariant = (eachVariantToUpdate, newVariants) => {
  const newVariant = newVariants.find((eachNew) => eachNew.id === eachVariantToUpdate.id);
  if (newVariant) {
    return {
      ...eachVariantToUpdate,
      ...newVariant,
      delta: moment().toDate().getTime(),
    };
  }
  return eachVariantToUpdate;
};

const getUpdatedVariant = (variantToUpdate, newVariants) => {
  const newUpdatedVariant = {};
  Object.keys(variantToUpdate).forEach((key, index) => {
    const eachVariantToUpdate = variantToUpdate[key];
    newUpdatedVariant[key] = getUpdatedSingleVariant(eachVariantToUpdate, newVariants);
  });
  return newUpdatedVariant;
};

const getTurnamentWithUpdatedVariants = (tournamentToUpdate, newVariants) => {
  (tournamentToUpdate.tournamentPhases || []).forEach((eachPhase) => {
    eachPhase.variants = eachPhase.variants.map((eachVariant) => getUpdatedSingleVariant(eachVariant, newVariants));
  });
  return { ...tournamentToUpdate };
};

const getTournamentsWithUpdatedVariants = (tournamentsToUpdate, newVariants) => tournamentsToUpdate.map((each) => getTurnamentWithUpdatedVariants(each, newVariants));

export default (state = initState, action) => {
  const storageId = action.storageId || '';
  switch (action.type) {
    case actionTypes.UPDATE_TOURNAMENT_DETAIL:
      return {
        ...state,
        tournamentToSave: { ...action.data, isSaved: false },
      };
    case actionTypes.DELETE_UNSAVED_TOURNAMENT_DETAIL: {
      return {
        ...state,
        tournamentToSave: undefined,
      };
    }
    case actionTypes.ADD_TOURNAMENT_REQUEST:
    case actionTypes.UPDATE_TOURNAMENT_REQUEST:
      return {
        ...state,
        tournament: initState.tournament,
      };
    case actionTypes.ADD_TOURNAMENT_RESPONSE:
    case actionTypes.UPDATE_TOURNAMENT_RESPONSE:
      const isMultidayValue = isMultiday(action.data)
      return {
        ...state,
        tournament: {...action.data, isMultiday: isMultidayValue },
        tournamentToSave: { ...action.data, isSaved: true, isMultiday: isMultidayValue },
      };
    case actionTypes.INIT_TOURNAMENT_TO_SAVE_REQUEST:
      return {
        ...state,
        tournamentToSave: { ...action.data, isSaved: true },
      };
    case actionTypes.TOURNAMENTS_GET_REQUEST:
      return {
        ...state,
        tournaments: initState.tournaments,
      };
    case actionTypes.FINISHED_TOURNAMENTS_GET_REQUEST:
      return {
        ...state,
        finishedTournaments: initState.finishedTournaments,
      };
    case actionTypes.NEW_TOURNAMENT_UPDATED:
      const newTournamentData = action.data || [];
      const newTournamentsDataArray = Array.isArray(newTournamentData) ? newTournamentData : [newTournamentData];
      return {
        ...state,
        tournaments: [...state.tournaments, ...newTournamentsDataArray],
      };
    case actionTypes.TOURNAMENT_UPDATED: {
      const tournamentUpdatedData = action.data || [];
      const newTournaments = Array.isArray(tournamentUpdatedData) ? tournamentUpdatedData : [tournamentUpdatedData];
      const getUpdatedTournament = (tournamentToUpdate) => {
        const newTournament = newTournaments.find((eachNew) => eachNew.id === tournamentToUpdate.id);
        return newTournament
          ? {
            ...tournamentToUpdate,
            ...newTournament,
          }
          : tournamentToUpdate;
      };
      const getUpdatedTournaments = (tournamentsUpdate) => {
        const tounamentsToUpdateList = Array.isArray(tournamentsUpdate) ? tournamentsUpdate : [tournamentsUpdate];
        return tounamentsToUpdateList.map((each) => getUpdatedTournament(each));
      };
      return {
        ...state,
        tournaments: getUpdatedTournaments(state.tournaments),
        tournament: getUpdatedTournament(state.tournament),
        finishedTournaments: getUpdatedTournaments(state.finishedTournaments || []),
      };
    }
    case actionTypes.VARIANT_UPDATED:
      const variantUpdatedData = action.data || [];
      const newVariants = Array.isArray(variantUpdatedData) ? variantUpdatedData : [variantUpdatedData];
      return {
        ...state,
        tournaments: getTournamentsWithUpdatedVariants(state.tournaments, newVariants),
        tournament: getTurnamentWithUpdatedVariants(state.tournament, newVariants),
        variant: getUpdatedVariant(state.variant, newVariants),
        finishedTournaments: getTournamentsWithUpdatedVariants(state.finishedTournaments || [], newVariants),
      };
    case actionTypes.TOURNAMENTS_GET_RESPONSE: {
      if (action.data) {
        action.data.forEach((tournament) => {
          tournament.isMultiday = isMultiday(tournament);
        });
      }
      return {
        ...state,
        tournamentsInitialized: true,
        tournaments: action.data || initState.tournaments,
      };
    }
    case actionTypes.FINISHED_TOURNAMENTS_GET_RESPONSE:
      return {
        ...state,
        finishedTournaments: action.data || initState.finishedTournaments,
      };
    case actionTypes.CLEAN_FINISHED_TOURNAMENTS: {
      return {
        ...state,
        finishedTournaments: initState.finishedTournaments,
      };
    }
    case actionTypes.TOURNAMENT_GET_REQUEST:
      return {
        ...state,
        tournament: initState.tournament,
      };
    case actionTypes.TOURNAMENT_GET_RESPONSE: {
      const tournament = action.data || initState.tournament;
      sessionStorage.setItem('tournamentId', tournament.id);
      tournament.isMultiday = isMultiday(tournament);
      return {
        ...state,
        tournament: tournament,
        tournamentToSave: undefined,
      };
    }
    case actionTypes.CLEAN_TOURNAMENT: {
      //localStorage.setItem('tournamentId', '');
      return {
        ...state,
        tournament: initState.tournament,
      };
    }
    case actionTypes.VARIANT_GET_REQUEST:
      return {
        ...state,
        variant: { ...state.variant, [storageId]: initState.variant },
      };
    case actionTypes.TOURNAMENT_STATISTICS_GET_RESPONSE:
      return {
        ...state,
        statistics: action.data || initState.statistics,
      };
    case actionTypes.TOURNAMENT_STATISTICS_CLEAN:
      return {
        ...state,
        statistics: initState.statistics,
      };
    case actionTypes.VARIANT_STATISTICS_GET_RESPONSE:
      const statisticsVariantToStore = { ...state.statisticsVariant };
      statisticsVariantToStore[storageId] = { ...action.data };
      return {
        ...state,
        statisticsVariant: statisticsVariantToStore,
      };
    case actionTypes.VARIANT_STATISTICS_CLEAN:
      const statisticsVariantToClean = { ...state.statisticsVariant };
      statisticsVariantToClean[storageId] = { ...initState.statisticsVariant };
      return {
        ...state,
        statisticsVariant: statisticsVariantToClean,
      };
    case actionTypes.CHANGE_VARIANT_STATE_REQUEST:
      return {
        ...state,
        statistics: initState.statistics,
      };
    case actionTypes.CLEAR_VARIANT:
     // localStorage.setItem('variantId', '');
      return {
        ...state,
        variant: { ...state.variant, [storageId]: {} },
      };
    case actionTypes.CHANGE_VARIANT_STATE_RESPONSE:
    case actionTypes.VARIANT_GET_RESPONSE:
      const variantGetResponse = action.data || initState.variant;
      //localStorage.setItem('variantId', variantGetResponse.id);
      return {
        ...state,
        variant: { [storageId]: { ...variantGetResponse, delta: moment().toDate().getTime() } },
      };
    case actionTypes.UPDATE_VARIANT:
    case actionTypes.VARIANT_POST_RESPONSE:
      const variant = action.data || initState.variant;
      return {
        ...state,
        variant: getUpdatedVariant(state.variant, [variant]),
      };
    case actionTypes.GET_FEES_REQUEST:
      return {
        ...state,
        fees: initState.fees,
      };
    case actionTypes.GET_FEES_RESPONSE:
      return {
        ...state,
        fees: action.data || initState.fees,
      };

    case actionTypes.GET_TIPS_REQUEST:
      return {
        ...state,
        tips: initState.tips,
      };
    case actionTypes.GET_TIPS_RESPONSE:
      return {
        ...state,
        tips: action.data || initState.tips,
      };
    case actionTypes.ADD_TIP_RESPONSE:
      return {
        ...state,
        tips: action.data || initState.tips,
      };

    case actionTypes.GET_CLOCK_SETTINGS_REQUEST:
      return {
        ...state,
        clockSettings: {
          isLoading: true,
          isLoaded: false,
          data: state.clockSettings.data // (state.clockSettings.data && state.clockSettings.data.bgFilename) ? {bgFilename: state.clockSettings.data.bgFilename} : null
        },
      };
    case actionTypes.GET_CLOCK_SETTINGS_RESPONSE:
      return {
        ...state,
        clockSettings: {
          isLoading: false,
          isLoaded: true,
          data: action.data,
        },
      };
    case actionTypes.GET_CLOCK_SETTINGS_CLEAN:
      return {
        ...state,
        clockSettings: initState.clockSettings
      };
    case actionTypes.CLEAN_TOURNAMENT_DETAIL: {
      sessionStorage.setItem('tournamentId', '');
      return {
        ...state,
        tournament: initState.tournament,
      };
    }
    case actionTypes.GET_LOGS_REQUEST:
      return {
        ...state,
        logs: [],
      };
    case actionTypes.GET_LOGS_RESPONSE:
      return {
        ...state,
        logs: action.data || initState.logs,
      };
    case actionTypes.CLEAR_LOGS:
      return {
        ...state,
        logs: initState.logs,
      };
    default:
      return state;
  }
};
