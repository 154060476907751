import { combineReducers } from 'redux';
import credentials from './credentialReducer';
import common from './commonReducer';
import globalError from './globalErrorReducer';

import socketsConnection from './socketConnectionReducer';
import chat from './chatReducer';
import users from './usersReducer';
import tournaments from './tournamentsReducer';
import templates from './templatesReducer';
import player from './playerReducer';
import tables from './tablesReducer';
import table from './tableReducer';
import rooms from './roomsReducer';
import redrawRemainingTables from './redrawRemainingTablesReducer';
import tournamentPayouts from './tournamentPayoutsReducer';
import component from './componentReducer';

import * as types from '../actiontypes/actionTypes';

const appReducer = combineReducers({
  chat,
  socketsConnection,
  users,
  tournaments,
  templates,
  player,
  globalError,
  credentials,
  common,
  tables,
  table,
  rooms,
  redrawRemainingTables,
  tournamentPayouts,
  component,
});

const rootReducers = (state, action) => {
  if (action.type === types.LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducers;
