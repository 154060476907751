import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "react-router-dom";
import Loader from "../shared/Loader";
import useVariant from "../useVariant";
import TournamentHeader from "../shared/TournamentHeader";
import GetSingleTournamentDataWrapper from "../shared/GetSingleTournamentDataWrapper";
import UpdatePlayerStateWrapper from "../shared/UpdatePlayerStateWrapper";
import { getRooms } from "../../actions/roomActions";
import { getActivePlayersByTournamentVariantId } from "../../actions/playerActions";
import {
  getReDrawRemainingTablesDraft,
  postReDrawRemainingTables,
  clearDraft,
} from "../../actions/redrawRemainingTablesAction";
import SliderBox from "../shared/Sliderbox";
import TablesV2 from "../shared/TablesV2";
import TableOccupancyState from "../../enum/TableOccupancyState";
import { formatPlayerName, formatTableSeat, getPlayerState } from "../../util/playerUtil";
import { history } from "../../service/history";
import CustomModal from "../shared/CustomModal";
import PlayerState from "../../enum/PlayerState";
import { setTableStyle } from "../../util/tournamentUtil";
import { isMobile } from 'react-device-detect';

const TABLE_SIZE = 10;

const NewTableSitting = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.variantId) {
      dispatch(
        getReDrawRemainingTablesDraft(props.variantId, props.numOfTables, props.tableMaxOccupancy)
      );
    }
    return () => {
      dispatch(clearDraft());
    };
  }, [props.variantId]);
  return (
    <div className="redrawRemainingTables">
      <h2>New seating</h2>
      <div className="line" />
      <div className="d-none d-md-block">
        <Row>
          {props.tables.map((eachTable) => (
            <Col xs={6} className="mt-3">
              <div xs={8} className="my-1 p-2 pl-4 blueBox">
                {`Table ${eachTable.tableName} - ${eachTable.players.length} / ${props.tableMaxOccupancy} players`}
              </div>
              {(eachTable.players || []).map((eachPlayer) => (
                <div className="my-1 p-2 pl-4 lightBox">
                  {`${eachPlayer.newSeatNum}. ${formatPlayerName(eachPlayer)}`}
                </div>
              ))}
            </Col>
          ))}
        </Row>
      </div>
      <div className="d-block d-md-none">
        <Row>
          {props.tables.map((eachTable) => (
            <Col xs={12} className="mt-3">
              <div xs={8} className="my-1 p-2 pl-4 blueBox">
                {`Table ${eachTable.tableName} - ${eachTable.players.length} / ${props.tableMaxOccupancy} players`}
              </div>
              {(eachTable.players || []).map((eachPlayer) => (
                <div className="my-1 p-2 pl-4 lightBox">
                  {`${eachPlayer.newSeatNum}. ${formatPlayerName(eachPlayer)}`}
                </div>
              ))}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

const NumberOfTablePicker = (props) => (
  <Row>
    <Col xs={12} md={8}>
      <h5 className="text-center">Max No. of players at table</h5>
      <SliderBox
        valueLabelFormat={(value) => `${value} players`}
        min={2}
        step={1}
        max={props.tableSize}
        value={props.tableMaxOccupancy}
        valueLabelDisplay="auto"
        onChange={props.onChange}
        marks={[
          {
            value: 2,
            label: "2",
          },
          {
            value: props.tableSize,
            label: `${props.tableSize} players`,
          },
        ]}
      />
    </Col>
    <Col xs={12} md={{ span: 2, offset: 2 }}>
      <div className="d-none d-md-block">
        <Row>{`Active players ${props.numOfPlayers}`}</Row>
        <Row>{`Table needed ${props.numOfTables}`}</Row>
      </div>
      <Row className="d-md-none py-2">
        <Col xs={6}>{`Active players ${props.numOfPlayers}`}</Col>
        <Col xs={6}>{`Table needed ${props.numOfTables}`}</Col>
      </Row>
    </Col>
  </Row>
);

const TableSelector = (props) => {
  const dispatch = useDispatch();
  const roomsTables = useSelector((state) => state.rooms.rooms);

  useEffect(() => {
    dispatch(getRooms());
  }, []);

  const isSelectedTable = (table) => props.selectedTables.find((t) => t.id === table.id);

  return (
    <TablesV2
      rooms={roomsTables || []}
      isSelectedTable={isSelectedTable}
      variantId={props.variant.id}
      selectedTables={props.selectedTables}
      onTableClick={props.processTableClick}
      setTableStyle={(table, tablesToSave, variantId) => {
        return setTableStyle(
          table,
          tablesToSave,
          variantId,
          null,
          "gray-table",
          "red-table",
          "gray-table",
          "gray-table",
          "darkGreen-table",
          "darkGreen-table",
          "gray-table"
        );
      }}
    />
  );
};

const currentSeat = (player) => {
  const state = getPlayerState(player);
  if (state === PlayerState.NOT_SEATED.key) {
    return "Without seat";
  }
  if (state === PlayerState.WAITING_LIST.key) {
    return "Waiting list";
  }
  return formatTableSeat(player);
};

const PlayersStep = (props) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");

  const handleFilterChange = (e) => {
    setSearchText(e.target.value);
    dispatch(getActivePlayersByTournamentVariantId(props.variant.id, e.target.value));
  };
  return (
    <>
      <div>
        <h3 className="d-inline-block">Players</h3>
        <InputGroup className="mb-3 input-right-icon-control-black">
          <FormControl
            id="filter"
            placeholder="Search player"
            name="filter"
            className="input-part"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={handleFilterChange}
            value={searchText}
          />
          <InputGroup.Text className="icon-part" id="basic-addon1">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup>
      </div>
      <Row className="graySeparator" />
      <UpdatePlayerStateWrapper>
        <Row>
          <Col xs={8}>{`Players (${props.players.length})`}</Col>
          <Col xs={4}>Current seat</Col>
        </Row>
        {props.players.map((player) => (
          <Row className="my-1 p-2 lightBox">
            <Col xs={8}>{formatPlayerName(player)}</Col>
            <Col xs={4}>{currentSeat(player)}</Col>
          </Row>
        ))}
      </UpdatePlayerStateWrapper>
    </>
  );
};

const RedrawRemainingTablesWizard = (props) => {
  const dispatch = useDispatch();

  const variant = useVariant(props.variantId, "redrawRemainingTablesWizard") || {};
  const tournament = useSelector((state) => state.tournaments.tournament);
  const players = useSelector((state) => state.player.playersInTournament.data || []) || [];
  const draft = useSelector((state) => state.redrawRemainingTables.draft);
  const completed = useSelector((state) => state.redrawRemainingTables.completed);

  const [wizardIndex, setWizardIndex] = useState(0);
  const [selectedTables, setSelectedTables] = useState([]);
  const [minNumOfTables, setMinNumOfTables] = useState(undefined);
  const [tableMaxOccupancy, setTableMaxOccupancy] = useState(TABLE_SIZE);
  const [isTableStepCompleted, setTableStepCompleted] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const numOfPlayers = players.length;

  const draftTables = (draft.tables || []).map((eachTable, tableIndex) => {
    const tableDef = selectedTables[tableIndex];
    return {
      ...eachTable,
      tableName: tableDef.tableName,
      id: tableDef.id,
      tableDefId: tableDef.tableDefId,
    };
  });

  useEffect(() => {
    if (variant.id) {
      dispatch(getActivePlayersByTournamentVariantId(variant.id));
    }
  }, [variant.id]);

  useEffect(() => {
    const numOfTables = Math.ceil(numOfPlayers / tableMaxOccupancy);
    setMinNumOfTables(numOfTables);
  }, [tableMaxOccupancy, numOfPlayers]);

  useEffect(() => {
    setTableStepCompleted(selectedTables.length >= minNumOfTables);
  }, [selectedTables.length, minNumOfTables]);

  const processOnTableClick = (table, state) => {
    const tableInEdit = selectedTables.find((t) => t.tableDefId === table.id);
    if (tableInEdit) {
      // cancel edit
      setSelectedTables(selectedTables.filter((t) => t.tableDefId !== table.id));
      return;
    }

    const variantId = parseInt(props.variantId);

    // const tableActiveOnAnotherVariant = table.tournamentVariant.filter((v) => parseInt(v.id) !== variantId && v.numOfPlayers > 0);
    // if (tableActiveOnAnotherVariant.length > 0) {
    //   return;
    // }

    const currentVariantOnTable = table.tournamentVariant.find((v) => parseInt(v.id) === variantId);

    if (currentVariantOnTable) {
      // if (currentVariantOnTable.numOfPlayers > 0) {
      //   return;
      // }

      setSelectedTables([
        ...selectedTables,
        {
          id: currentVariantOnTable.gameTableId,
          tableDefId: table.id,
          tableName: table.tableName,
          state: currentVariantOnTable.state === "open" ? "closed" : "open",
        },
      ]);
    } else {
      setSelectedTables([
        ...selectedTables,
        {
          tableDefId: table.id,
          gameTableId: table.tournamentVariant ? table.tournamentVariant.gameTableId : undefined,
          tableName: table.tableName,
          state: "open",
          tournamentPhaseVariantId: props.variantId,
          isNew: true,
          prevVariantId: table.tournamentVariant ? table.tournamentVariant.id : undefined,
        },
      ]);
    }
    setTableStepCompleted(selectedTables.length >= minNumOfTables);
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const save = (showOnClock) => {
    dispatch(postReDrawRemainingTables(variant.id, { ...draft, tables: draftTables, showOnClock }));
  };

  if (completed) {
    return (
      <Redirect to={{ pathname: `/tournament/${variant.tournamentId}/variant/${variant.id}` }} />
    );
  }
  if (!variant.tournamentId) {
    return <Loader />;
  }
  return (
    <div id="wizard">
      <Loader>
        <GetSingleTournamentDataWrapper id={variant.tournamentId}>
          <div className="d-none d-md-block">
            <TournamentHeader tournament={tournament} />
          </div>
          <Row className="py-4 navigation d-none d-md-flex">
            <Button
              className="menu-button step-done"
              onClick={() => {
                setWizardIndex(0);
              }}
            >
              <div className="mx-3 p-1 icon">
                <FontAwesomeIcon visibility="visible" icon={faCheck} />
              </div>
              <span>Players</span>
            </Button>

            <Button
              className={`menu-button ${wizardIndex >= 1 ? "step-done" : ""}`}
              onClick={() => {
                setWizardIndex(1);
              }}
            >
              <div className="mx-3 p-1 icon">
                <FontAwesomeIcon visibility="visible" icon={faCheck} />
              </div>
              <span>Table selection</span>
            </Button>

            <Button
              disabled={!isTableStepCompleted}
              className={`menu-button ${wizardIndex >= 2 ? "step-done" : ""}`}
              onClick={() => {
                setWizardIndex(2);
              }}
            >
              <div className="mx-3 p-1 icon">
                <FontAwesomeIcon visibility="visible" icon={faCheck} />
              </div>
              <span>New seating</span>
            </Button>
          </Row>
        </GetSingleTournamentDataWrapper>
        <div className="body m-5">
          {wizardIndex === 0 && <PlayersStep variant={variant} players={players} />}
          {wizardIndex === 1 && (
            <>
              <h3>Tables</h3>
              <Row className="graySeparator" />
              <NumberOfTablePicker
                numOfTables={minNumOfTables}
                numOfPlayers={numOfPlayers}
                tableSize={TABLE_SIZE}
                tableMaxOccupancy={tableMaxOccupancy}
                onChange={(e, newValue) => {
                  setTableMaxOccupancy(newValue);
                }}
              />
              <Row className="graySeparator" />
              <TableSelector
                variant={variant}
                selectedTables={selectedTables}
                processTableClick={processOnTableClick}
              />
            </>
          )}
          {wizardIndex === 2 && (
            <>
              <NewTableSitting
                variantId={variant.id}
                tables={draftTables}
                selectedTables={selectedTables}
                numOfTables={selectedTables.length}
                tableMaxOccupancy={tableMaxOccupancy}
              />
              {showConfirmationModal && (
                <>
                  <div>
                    <CustomModal
                      open={showConfirmationModal}
                      closeHandler={toggleConfirmationModal}
                      className="action-confirm-modal"
                      dialogClassName="modal-small-width"
                    >
                      <>
                        <h2>Do you want to show redraw at clock screen?</h2>
                        <div>
                          <Button
                            color="primary"
                            className="confirm-btn"
                            onClick={() => save(true)}
                          >
                            Yes
                          </Button>
                          <Button className="cancel-btn outline m-1" onClick={() => save(false)}>
                            No
                          </Button>
                        </div>
                      </>
                    </CustomModal>
                  </div>

                </>
              )}
            </>
          )}
        </div>
        <Row className="confirm-buttons">
          <Col xs={6} md={{ span: 3, offset: 2 }}>
            <div>
              <Button
                className="fit outline img-left"
                color="secondary"
                onClick={() => {
                  if (wizardIndex === 0) {
                    history.push("/");
                  } else {
                    setWizardIndex(wizardIndex - 1);
                  }
                }}
              >
                <FontAwesomeIcon visibility="visible" icon={faChevronLeft} />
                <span>Back</span>
              </Button>
            </div>
          </Col>
          <Col xs={6} md={7} align="right">
            <div>
              {(wizardIndex === 0 || wizardIndex === 1) && (
                <Button
                  className={isMobile ? "img-right" : "fit img-right ml-3"}
                  color="secondary"
                  onClick={() => {
                    if (wizardIndex === 0 || (wizardIndex === 1 && isTableStepCompleted)) {
                      setWizardIndex(wizardIndex + 1);
                    }
                  }}
                >
                  <span>Next step</span>
                  <FontAwesomeIcon visibility="visible" icon={faChevronRight} />
                </Button>
              )}
              {wizardIndex === 2 && (
                <Button
                  className="fit img-right"
                  color="secondary"
                  onClick={toggleConfirmationModal}
                >
                  <span>Confirm</span>
                  <FontAwesomeIcon visibility="visible" icon={faChevronRight} />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Loader>
    </div>
  );
};

export default RedrawRemainingTablesWizard;
