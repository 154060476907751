import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as IconTables } from "../../image/icon-tables.svg";
import { ReactComponent as IconTask } from "../../image/task-icon.svg";
import { ReactComponent as IconAdministration } from "../../image/icon-administration.svg";
import { ReactComponent as IconManageClock } from "../../image/icon-manage-clock.svg";
import { ReactComponent as IconPayouts } from "../../image/icon-payouts.svg";
import { ReactComponent as IconPlayers } from "../../image/icon-players.svg";
import { ReactComponent as IconWaitingListCoordinator } from "../../image/icon-waiting-list-coordinator.svg";
import { ReactComponent as IconRebuy } from "../../image/icon-rebuy.svg";
import { history } from "../../service/history";

import React, { useState } from "react";
import { Navbar, Nav, Dropdown, Container, Row, Col } from "react-bootstrap";

const redirect = (path) => {
    history.push(path);
};

const BottomNavbarMobile = ({ tournamentUrl, id }) => {


    const { playersCount } = useSelector((state) => state.player);

    return (
        <div className="d-block d-md-none bottom-bar justify-content-around flex-row" >
            <Navbar expand="lg" bg="#000000" variant="black" fixed="bottom" id="bottom-navbar-mobile" >
                <Dropdown drop={'up'} id="manageMtt">
                    <Dropdown.Toggle id="dropdown-basic" variant={"success"} className={"custom-nav-link"}>
                        <div className="nav-icon">
                            <IconWaitingListCoordinator />
                        </div>
                        Manage MTT
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-up">
                        <Dropdown.Item href={`${tournamentUrl}/logs`}>
                            <div className="nav-icon">
                                <IconManageClock style={{ fill: "white" }} />
                            </div>
                            <div>Log History</div>
                        </Dropdown.Item>
                        <Dropdown.Item href={`${tournamentUrl}/director-clock`}>
                            <div className="nav-icon">
                                <IconManageClock style={{ fill: "white" }} />
                            </div>
                            <div>Manage Clock</div>
                        </Dropdown.Item>
                        <Dropdown.Item href={`${tournamentUrl}/payout`} >
                            <div className="nav-icon">
                                <IconPayouts style={{ fill: "white" }} />
                            </div>
                            <div>Payout Structure</div>
                        </Dropdown.Item>
                        <Dropdown.Item href={`${tournamentUrl}/manage/structuremobile`}>
                            <div className="nav-icon">
                                <IconAdministration style={{ fill: "white" }} />
                            </div>
                            <div>Structure</div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Navbar.Text
                    id="seatedByFloor"
                    className={"custom-nav-link"}
                    onClick={() => {
                        id !== "seatedByFloor" && redirect(`${tournamentUrl}/notSeated`);
                    }}>
                    <div className="nav-icon">
                        <span className="number">
                            {playersCount && playersCount.waiting ? playersCount.waiting : "0"}
                        </span>
                        <IconTask style={{ fill: "#5B38B9" }} />
                    </div>
                    <div className={id === "seatedByFloor" ? "selected" : ""}>
                        Seat. by floor
                    </div>
                </Navbar.Text>
                <Navbar.Text
                    id="tables"
                    className={"custom-nav-link "}
                    onClick={() => {
                        id !== "tables" && redirect(`${tournamentUrl}`)
                    }}
                >
                    <div className="nav-icon">
                        <IconTables style={{ fill: "#5B38B9" }} />
                    </div>
                    <div className={id === "tables" ? "selected" : ""}>
                        Tables
                    </div>
                </Navbar.Text>
                <Navbar.Text
                    id="players"
                    className={"custom-nav-link"}
                    onClick={() => {
                        id !== "players" && redirect(`${tournamentUrl}/players`);
                    }}
                >
                    <div className="nav-icon">
                        <IconPlayers style={{ fill: "#5B38B9" }} />
                    </div>

                    <div className={id === "players" ? "selected" : ""}>
                        Players
                    </div>
                </Navbar.Text>
                <Navbar.Text
                    id="waitingList"
                    className={"custom-nav-link"}
                    onClick={() => {
                        id !== "waitingList" && redirect(`${tournamentUrl}/waitinglist`);
                    }}>
                    <div className="nav-icon">
                        <span className="number">
                            {playersCount && playersCount.waiting ? playersCount.waiting : "0"}
                        </span>
                        <IconWaitingListCoordinator />
                    </div>
                    <div className={id === "waitingList" ? "selected" : ""}>
                        Waiting list
                    </div>
                </Navbar.Text>
            </Navbar>
        </div >
    );
};


export default BottomNavbarMobile;
