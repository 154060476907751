import * as types from '../actiontypes/actionTypes';

const initState = {
  connectionState: 0,
};

export default function socketConnectionReducer(state = initState, action) {
  switch (action.type) {
    case types.SOCKET_CONNECTION_CHANGE:
      return { ...state, connectionState: action.connectionState };
    default:
      return state;
  }
}
