import React from 'react';
import { Tab } from 'react-bootstrap';

const CustomTab = (props) => (
  <Tab eventKey={props.key} title={props.title}>
    {this.props.children}
  </Tab>
);

export default CustomTab;
