import React from 'react';
import TemplateList from './template/TemplateList';
import TemplateType from '../../enum/TemplateType';

const TemplatePayout = (props) => (
  <div className="p-4">
    <TemplateList templateType={TemplateType.TOURNAMENT_PAYOUT} />
  </div>
);

export default TemplatePayout;
