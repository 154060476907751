export default {
  GET_REDRAW_REMAINING_TABLES_DRAFT_REQUEST: 'GET_REDRAW_REMAINING_TABLES_DRAFT_REQUEST',
  GET_REDRAW_REMAINING_TABLES_DRAFT_RESPONSE: 'GET_REDRAW_REMAINING_TABLES_DRAFT_RESPONSE',

  CLEAR_DRAFT: 'CLEAR_DRAFT',

  POST_REDRAW_REMAINING_TABLES_REQUEST: 'POST_REDRAW_REMAINING_TABLES_REQUEST',
  POST_REDRAW_REMAINING_TABLES_RESPONSE: 'POST_REDRAW_REMAINING_TABLES_RESPONSE',

};
