import React, { useRef, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import CustomModal from './CustomModal';
import { formatPlayerName } from '../../util/playerUtil';
import { ReactComponent as DqIcon } from '../../image/icon-DQ.svg';
import { disqualifyPlayerFromVariant } from '../../actions/playerActions';

const PlayerActionDisqualifyModal = ({
  isOpen, toggle, player, onAction,
}) => {
  const dispatch = useDispatch();
  const chipCountsRef = useRef(null);

  const [isChipCountInvalidState, setIsChipCountInvalid] = useState(false);

  function isChipCountInvalid() {
    const invalid = !chipCountsRef.current.value;
    setIsChipCountInvalid(invalid);
    return invalid;
  }

  function chipCountsOnChange() {
    setIsChipCountInvalid(isChipCountInvalid());
  }

  function disqualify() {
    if (isChipCountInvalid()) {
      return;
    }

    const chipCounts = chipCountsRef.current.value;
    dispatch(disqualifyPlayerFromVariant(player.playerInTournamentPhaseVariantId, chipCounts));
    toggle();

    if (onAction) {
      onAction();
    }
  }

  return (
    <>
      {isOpen && (
      <CustomModal open={isOpen} closeHandler={toggle} className="action-confirm-disqualify-modal" dialogClassName="modal-small-width">
        <>
          <DqIcon className="modal-action-icon" />
          <h2>Disqualify</h2>
          <p>{`Are you sure you want to disqualify ${formatPlayerName(player)} from this MTT?`}</p>
          <div className="chip-count">
            <span>Chip count *</span>
            <Input
              id="chipCounts"
              innerRef={chipCountsRef}
              invalid={isChipCountInvalidState}
              type="number"
              name="chipCounts"
              placeholder="Type chip count"
              className="mr-2 form-control light"
              onChange={chipCountsOnChange}
              autoFocus
            />
          </div>
          <div>
            <Button color="primary" className="confirm-btn" onClick={disqualify}>
              Confirm DQ
            </Button>
            <Button className="cancel-btn outline m-1" onClick={toggle}>
              Cancel
            </Button>
          </div>
          <div className="footer">
            <small>This chip count will be deducted from the total MTT chip count</small>
          </div>
        </>
      </CustomModal>
      )}
    </>
  );
};

export default PlayerActionDisqualifyModal;
