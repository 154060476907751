import React from "react";

export default function PokerTable(props) {
  const setName = () => {
    if (props.table.tableName) {
      return props.table.tableName;
    }
    return "";
  };

  const getToolTip = () => {
    var tournName = "";
    if (props.table.tournamentVariant) {
      tournName = props.table.tournamentVariant.tournamentName;
      return "Tournament name: " + tournName + "\n" + "MTT starts on: ";
    }
    return "";
  };

  const className = props.tableStyle(props.table);

  return (
    <input
      type="button"
      value={setName()}
      className={className}
      onClick={() => props.onTableClick(props.table, props.i, props.j)}
      title={getToolTip()}
    />
  );
}
