import actionTypes from '../actiontypes/roomActionTypes';

const initState = {
  rooms: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ROOMS_REQUEST:
      return {
        ...state,
        rooms: initState.rooms,
      };
    case actionTypes.GET_ROOMS_RESPONSE:
      return {
        ...state,
        rooms: action.data || initState.rooms,
      };
    case actionTypes.SAVE_ROOMS_REQUEST:
      return {
        ...state,
        rooms: initState.rooms,
      };
    case actionTypes.SAVE_ROOMS_RESPONSE:
      return {
        ...state,
        rooms: action.data,
      };
    case actionTypes.DELETE_ROOMS_RESPONSE:
      return {
        ...state,
        rooms: action.data || initState.rooms,
        // rooms: state.rooms.filter(room => action.data !== room.id),
      };
    default:
      return state;
  }
};
