import React, {useCallback, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Button, Row, Col} from "react-bootstrap";
import CustomModal from "../../shared/CustomModal";
import {ReactComponent as PlayersIcon} from "../../../image/icon-players.svg";
import { ReactComponent as TablesIcon } from "../../../image/icon-tables.svg";
import {ReactComponent as SwapIcon} from "../../../image/icon-swap.svg";
import SetPropertyModal from "../../modals/SetPropertyModal";
import DispPropertyModal from "../../modals/DispPropertyModal";
import {updateVariant} from "../../../actions/tournamentActions";
import {updateGameTable} from "../../../actions/tablesActions";
import Tables from "../../mttdirector/tableReservation/Tables";
import {getRooms} from "../../../actions/roomActions";
import {history} from "../../../service/history";
import Loader from "../../shared/Loader";
import GridLegend from "../../shared/GridLegend";
import SeatingPreferences from "../../shared/SeatingPreferences";
import {setTableStyle} from "../../../util/tournamentUtil";
import ConfirmationModal from "../../modals/ConfirmationModal";
import AddSeatModal from "./AddSeatModal";
import restClient from "../../../util/restClient";

const TableSetupModal = (props) => {
    const dispatch = useDispatch();
    const [maxOccupancy, setMaxOccupancy] = useState(false);
    const [editTables, setEditTables] = useState(false);
    const [currentRoom, setCurrentRoom] = useState(undefined);
    const [addSeatModal, setAddSeatModal] = useState(false);
    const [editableTablesForCurrentRoom, setEditableTables] = useState(undefined);
    const [tablesToSave, setTablesToSave] = useState([]);
    const [openTablesConfirmationModal, setOpenTablesConfirmationModal] = useState(false);
    const [roomstables, setRoomTables] = useState({data: null, error: null, isLoading: true})
    const seatingPreferencesEnabled = props.variant.phase <= 1 || props.variant.reseatCount > 0;


    const handleChange = (val) => {
        const seatingByFloorman = {
            id: props.variant.id,
            seatingByFloorman: val === "floorman" ? 1 : 0,
        };
        dispatch(updateVariant(seatingByFloorman));
    };

    const redirect = (path) => {
        history.push(path);
    };

    const reloadRooms = async () => {
        await fetchRooms()
    }

    const fetchRooms = useCallback(async () => {
        await restClient.getAsyncWrap(`/rooms/manage/${props.variant.id}`, roomstables, setRoomTables)
    }, [])

    useEffect(() => {
        fetchRooms().catch(console.error);
    }, [fetchRooms])


    useEffect(() => {
        if (roomstables && roomstables.data && roomstables.data.length > 0) {
            setEditableTables(JSON.parse(JSON.stringify(roomstables.data[0].tables)));
        }
    }, [roomstables]);

    useEffect(() => {
        if (currentRoom && roomstables.data && roomstables.data.length > 0) {
            setEditableTables(
                JSON.parse(JSON.stringify(roomstables.data.find((r) => r.id === currentRoom.id).tables))
            );
        }
    }, [currentRoom]);

    function toggleTables() {
        setEditTables(!editTables);
    }

    function redrawRemainingTables() {
        redirect(
            `/tournament/${props.variant.tournamentId}/variant/${props.variant.id}/redrawremainingtables`
        );
    }

    function toggleAddSeatModal() {
        let newValueModalOPen = !addSeatModal;
        setAddSeatModal(newValueModalOPen);
        if(!newValueModalOPen){
            props.toggle();
        }
    }

    function toggleMaxPlayersOccupancy() {
        setMaxOccupancy(!maxOccupancy);
    }

    function updateTableSize(tableSize) {
        const obj = {id: props.variant.id, tableSize: tableSize};
        dispatch(updateVariant(obj));
    }

    function updateTableOccupancy(occupancy) {
        const obj = {id: props.variant.id, tableMaxOccupancy: occupancy};
        dispatch(updateVariant(obj));
    }

    function isInputValid(input) {
        if (input && input !== "") {
            return Number.isInteger(parseInt(input));
        }

        return false;
    }

    function isTableMaxOccupancyValid(input) {
        if (isInputValid(input)) {
            const value = parseInt(input);

            return parseInt(input) <= props.variant.tableSize && value >= 2 && value <= 10;
        }
        return false;
    }

    function isTableSizeValid(input) {
        if (isInputValid(input)) {
            const value = parseInt(input);
            return value >= 2 && value <= 10;
        }
        return false;
    }

    function onTableClick(table) {
        const tableInEdit = tablesToSave.find((t) => t.tableDefId === table.id);
        if (tableInEdit) {
            // cancel edit
            setTablesToSave(tablesToSave.filter((t) => t.tableDefId !== table.tableDefId));
            return;
        }

        if(table.state === 'open' && table.numOfPlayers > 0) {
            return;
        }

        if(table.state === 'open' && table.numOfPlayers === 0) {
            //close table
            setTablesToSave([
                ...tablesToSave,
                {
                    id: table.gameTableId,
                    tableDefId: table.tableDefId,
                    state: "closed"
                }
            ]);

            return
        }

        if(table.state === 'closed' && table.numOfPlayers === 0) {
            //close table
            setTablesToSave([
                ...tablesToSave,
                {
                    id: table.gameTableId,
                    tableDefId: table.tableDefId,
                    state: "open"
                }
            ]);

            return
        }

        if (table.state === 'empty') {
             setTablesToSave([
                ...tablesToSave,
                {
                    tableDefId: table.tableDefId,
                    state: "open"
                },
            ]);
        }
    }

    function switchToAnotherRoom(room) {
        setCurrentRoom(room);
        setTablesToSave([]);
    }

    function saveAndSwitch(room) {
        saveChanges();
        setCurrentRoom(room);
        props.toggle();
    }

    function areTablesChanged() {
        return tablesToSave.length > 0;
    }

    function setTableStyleWrap(table, conflictTables) {
        return setTableStyle(table, tablesToSave, props.variant.id, conflictTables, props.variant);
    }

    function cancelChanges() {
        const {tables} = roomstables.data.find((r) => r.id === currentRoom.id);
        setEditableTables(JSON.parse(JSON.stringify(tables)));
        setTablesToSave([]);
        props.toggle();
    }

    function saveChanges() {
        const obj = {id: props.variant.id, tables: tablesToSave};
        const tablesToBeClosed = obj.tables.filter((t) => t.stolen === true);

        if (tablesToBeClosed.length > 0) {
            tablesToBeClosed.map((t, index) => {
                closeStolenTable(t.prevVariantId, t.gameTableId).then((resp) => {
                    if (index === tablesToBeClosed.length - 1) {
                        const action = updateVariant(obj);
                        dispatch((dispatch) => {
                            action(dispatch).then(() => {
                                reloadRooms();
                                // problem je ze variant tables ze nerefreshli
                                setTablesToSave([]);
                            });
                        });
                    }
                });
            });
        } else {
            const action = updateVariant(obj);
            dispatch((dispatch) => {
                action(dispatch).then(() => {
                    reloadRooms();
                    // problem je ze variant tables ze nerefreshli
                    setTablesToSave([]);
                });
            });
        }
        setOpenTablesConfirmationModal(true);
    }

    function closeStolenTable(variantId, tableId) {
        return dispatch(
            updateGameTable(variantId, [
                {
                    id: tableId,
                    state: "closed",
                },
            ])
        );
    }

    return (
        <>
            {props.isOpen && (
                <CustomModal
                    title="Setup tables"
                    open={props.isOpen}
                    closeHandler={props.toggle}
                    className="table-settings-modal centered width400px"
                >
                    <>
                        <Button className="actions" onClick={toggleAddSeatModal}>
                            <PlayersIcon class="action-icon"/>
                            Max No. of players at table
                        </Button>
                        <Button
                          className="actions"
                          onClick={() => {
                            toggleMaxPlayersOccupancy();
                          }}
                        >
                          <TablesIcon className="action-icon" />
                          Change max. table occupancy
                        </Button>
                        <Button className="actions" onClick={redrawRemainingTables}>
                            <SwapIcon className="action-icon"/>
                            Redraw at remaining tables
                        </Button>
                        <Button
                            className="actions"
                            onClick={toggleTables}
                            disabled={!seatingPreferencesEnabled}
                        >
                            <SwapIcon className="action-icon"/>
                            Add / remove tables
                        </Button>
                        <label className="seating">Seating preferences: </label>
                        <SeatingPreferences
                            disabled={!seatingPreferencesEnabled}
                            onChange={handleChange}
                            value={props.variant.seatingByFloorman === 1 ? "floorman" : "automatic"}
                        />
                        {!seatingPreferencesEnabled && (
                            <label className="mt-1 seating gray-label">
                                All new players registered to {props.variant.name} prior to its start, will be added to the
                                "seating by floorman" list and will be automatically seated after the "reseat for
                                new day" process has finished
                            </label>
                        )}
                    </>
                </CustomModal>
            )}
            {addSeatModal && (
                <AddSeatModal toggle={toggleAddSeatModal} tables={props.variant.tables} isOpen={addSeatModal} variantId = {props.variant.id}/>
            )}
            {maxOccupancy && (
                <SetPropertyModal
                    open
                    toggle={toggleMaxPlayersOccupancy}
                    inputLabel="Maximum table occupancy"
                    inputPlaceholder="Type max. table occupancy"
                    buttonText="Save table occupancy"
                    value={props.variant.tableMaxOccupancy}
                    onButtonClick={updateTableOccupancy}
                    isValid={isTableMaxOccupancyValid}
                    validationText="Value has to be number"
                />
            )}
            {openTablesConfirmationModal && (
                <ConfirmationModal
                    confirmationText={`Tables were saved`}
                    buttonText="Ok"
                    open={openTablesConfirmationModal}
                    close={() => {
                        setOpenTablesConfirmationModal(false);
                        props.toggle();
                    }}
                    onSubmitButtonClick={props.toggle}
                />
            )}
            {props.variant.tables && editTables && (
                <>
                    <CustomModal open closeHandler={toggleTables} className="edit-tables">
                        <Loader>
                            <Row>
                                <Col>
                                    <Tables
                                        tables={editableTablesForCurrentRoom}
                                        onTableClick={onTableClick}
                                        variant={props.variant}
                                        title="Add/remove tables"
                                        setTableStyle={setTableStyleWrap}
                                        switchToAnotherRoom={switchToAnotherRoom}
                                        saveAndSwitch={saveAndSwitch}
                                        areTablesChanged={areTablesChanged}
                                    />
                                </Col>

                                <Col className="col-sm-3 col-xs-12">
                                    <Row className="mb-5">
                                        <Button className="action-btn" variant="secondary" onClick={saveChanges}>
                                            Save change tables
                                        </Button>
                                        <Button
                                            className="action-btn"
                                            variant="outline-primary"
                                            onClick={cancelChanges}
                                        >
                                            Cancel changes
                                        </Button>
                                    </Row>
                                    <Row className="legend-text" style={{textAlign: "left"}}>
                                        <GridLegend/>
                                    </Row>
                                </Col>
                            </Row>
                        </Loader>
                    </CustomModal>
                </>
            )}
        </>
    );
};

export default TableSetupModal;
