import React from "react";
import { Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment-mini";
import { payoutPlayerInVariant } from "../../actions/playerActions";
import CustomModal from "../shared/CustomModal";
import { display } from "../../enum/Currency";
import { formatDate, formatTime } from "../../util/stringUtil";


class PayoutConfirmationModal extends React.Component {
  getPosition = () => {
    let pos = "th, ";
      let player = this.props.player || {};

      if (player.position === 1) pos = "st";
    else if (player.position === 2) pos = "nd";

    return player.position + pos;
  };

  getPayoutAmount = () => {
      let player = this.props.player || {};
      if (player.toPayout) return player.toPayout + display(this.props.selectedTournament.currency);
  };

  makePayout = () => {
      let player = this.props.player || {};
      this.props.payoutPlayerInVariant(player.id, this.props.selectedTournament.id, player.toPayout);
    this.props.toggle();
  };

  render() {
      let player = this.props.player || {};
      return (
      <>
        {this.props.isOpen && (
          <CustomModal open={this.props.isOpen} closeHandler={this.props.toggle} className="payout-confirmation-modal" title="Player payout confirmation" dialogClassName="modal-small-width">
            <>
              <Row>
                <Col className="light-style">Name</Col>
                <Col className="right-align">{`${player.firstName} ${player.lastName}`}</Col>
              </Row>
              <Row>
                <Col className="light-style">Nationality</Col>
                <Col className="right-align">{player.nationality}</Col>
              </Row>
              <Row>
                <Col className="light-style">ID number</Col>
                <Col className="right-align">{player.idCardNum}</Col>
              </Row>
              <Row>
                <Col className="light-style">Date of birth</Col>
                <Col className="right-align">{formatDate(player.dateOfBirth)}</Col>
              </Row>

              <Row className="graySeparator" />
              <Row className="light-style">
                <Col>TOURNAMENT</Col>
                <Col className="right-align">POSITION AND TIME</Col>
              </Row>
              <Row>
                <Col>{this.props.selectedTournament ? this.props.selectedTournament.name : " "}</Col>
                <Col className="right-align">{`${this.getPosition()}  ${formatTime(player.finishedAt)}`}</Col>
              </Row>
              <Row className="graySeparator" />
              <Row>
                <Col className="payout-label">PAYOUT</Col>
                <Col className="payout-value">{this.getPayoutAmount()}</Col>
              </Row>
              <Row className="greenDashedSeparator" />
              <Row>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    this.makePayout();
                  }}
                >
                  Confirm payout
                </Button>
              </Row>
            </>
          </CustomModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    payouts: state.player.payouts
  };
};

const mapDispatchToProps = {
  payoutPlayerInVariant
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutConfirmationModal);
