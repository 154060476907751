import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

const ErrorMessage = ({ errorMsg, errorTitle, errorTraceId }) => {
  if (errorMsg) {
    return (
      <Alert variant="danger">
        <h4>{errorTitle}</h4>
        <p>{`${errorMsg} TraceId: ${errorTraceId}`}</p>
      </Alert>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  errorMsg: state.globalError.errorMsg ? state.globalError.errorMsg.toString() : '',
  errorTitle: state.globalError.errorMsgTitle,
  errorTraceId: state.globalError.errorTraceId || '',
});

export default connect(mapStateToProps)(ErrorMessage);
