import "./index.scss";
import React, {useEffect, useState} from "react";
import {Route, Router, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store/configureStore";
import ErrorMessage from "./components/shared/ErrorMessage";
import LoginPage from "./pages/LoginPage";
import {SecuredBasePage} from "./pages/base/SecuredBasePage";
import {history} from "./service/history";
import ErrorBoundary from "./pages/ErrorBoundary";
import "../node_modules/animate.css/animate.css";
import EvSocket from './service/EvSocket'
import { initSocket } from './service/EvSocket'

function App() {
    useEffect(() => {
        if (window.location.pathname !== "/login") {
            history.push(window.location.pathname + window.location.search);
        }
        localStorage.setItem('showTablesWarning', '')
    }, [])

    useEffect(() => {
        initSocket();
        resetSessionStorage();

        return () =>  EvSocket.disconnect()
    }, []);

    const resetSessionStorage = () => {
        if(!window.location.pathname.startsWith("/tournament")) {
            sessionStorage.setItem('variantId', '');
            sessionStorage.setItem('tournamentId', '');
        }
    }

    return (
        <ErrorBoundary>
            <Provider store={store}>
                {/*<Console>*/}
                <ErrorMessage/>
                <Router history={history}>
                    <Switch>
                        <Route exact path="/login" component={LoginPage}/>
                        <Route component={SecuredBasePage}/>
                    </Switch>
                </Router>
                {/*</Console>*/}
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
