import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Icon from '../../../image/icon-chcek chipcounts.svg';
import PlayerActionLogsModal from '../../tournament/floor/PlayerActionLogsModal';

const SwapSeatsButton = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const player = props.player || {};


  const toggleModal = () => {
    setOpenModal(!openModal);
    if (props.toggleParent) {
      props.toggleParent();
    }
  };


  return (
    <>
      <div className="action">
        <Button color="primary" className="action-btn" onClick={toggleModal}>
          <img src={Icon} className="action-icon" alt="swap" />
        </Button>
        <a>History</a>
      </div>
      {openModal && <PlayerActionLogsModal isOpen={openModal} toggle={toggleModal} selectedPlayer={player}/>}
    </>
  );
};

export default SwapSeatsButton;
