import playerActionTypes from '../actiontypes/playerActionTypes';
import restClient from '../util/restClient';
import { combinedRegisterLoaderAndShowError, registerLoader } from './commonActions';
import { getTournamentPayouts } from './tournamentPayoutActions';

const insertPlayerRequest = () => ({ type: playerActionTypes.INSERT_PLAYER_REQUEST });
const insertPlayerSuccess = (data) => ({ type: playerActionTypes.INSERT_PLAYER_RECEIVE, data });
const insertPlayerFailed = () => ({ type: playerActionTypes.INSERT_PLAYER_FAILED });
const insertPlayerClean = () => ({ type: playerActionTypes.INSERT_PLAYER_CLEAN });

const updatePlayerRequest = (data) => ({
  type: playerActionTypes.UPDATE_PLAYER_IN_VARIANT_REQUEST,
  data,
});
const updatePlayerResponse = (data) => ({
  type: playerActionTypes.UPDATE_PLAYER_IN_VARIANT_RESPONSE,
  data,
});

const updatePlayerPositionRequest = (data) => ({
  type: playerActionTypes.UPDATE_PLAYER_IN_VARIANT_POSITION_REQUEST,
  data,
});
const updatePlayerPositionResponse = (data) => ({
  type: playerActionTypes.UPDATE_PLAYER_IN_VARIANT_POSITION_RESPONSE,
  data,
});

const getPlayersRequest = (data) => ({ type: playerActionTypes.GET_PLAYERS_BY_TOURNAMENT_ID_REQUEST, data });
const getPlayersSuccess = (data) => ({ type: playerActionTypes.GET_PLAYERS_BY_TOURNAMENT_ID_RECEIVE, data });
const getPlayersError = (data) => ({ type: playerActionTypes.GET_PLAYERS_BY_TOURNAMENT_ID_ERROR, data });
export const getPlayersClean = (data) => ({ type: playerActionTypes.GET_PLAYERS_BY_TOURNAMENT_ID_CLEAN, data });

const getFinishedPlayersRequest = (data) => ({ type: playerActionTypes.GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_REQUEST, data });
const getFinishedPlayersSuccess = (data) => ({ type: playerActionTypes.GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_RECEIVE, data });
const getFinishedPlayersError = (data) => ({ type: playerActionTypes.GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_ERROR, data });
export const getFinishedPlayersClean = (data) => ({ type: playerActionTypes.GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_CLEAN, data });

const deletePlayersRequest = () => ({ type: playerActionTypes.DELETE_BY_ID_REQUEST });
const deletePlayersSuccess = (data) => ({ type: playerActionTypes.DELETE_BY_ID_RECEIVE, data });

const reactivePlayerRequest = () => ({ type: playerActionTypes.REACTIVATE_BY_ID_REQUEST });
const reactivePlayersSuccess = (data) => ({ type: playerActionTypes.REACTIVATE_BY_ID_RECEIVE, data });

const disqualifyPlayerRequest = () => ({ type: playerActionTypes.DISQUALIFY_BY_ID_REQUEST });
const disqualifyPlayersSuccess = (data) => ({ type: playerActionTypes.DISQUALIFY_BY_ID_RECEIVE, data });

const getPlayerPayoutRequest = () => ({ type: playerActionTypes.GET_PLAYER_PAYOUT_REQUEST });
const getPlayerPayoutSuccess = (data) => ({ type: playerActionTypes.GET_PLAYER_PAYOUT_RESPONSE, data });

const updatePlayerPayoutRequest = () => ({ type: playerActionTypes.UPDATE_PAYOUT_REQUEST });
const updatePlayerPayoutSuccess = (data) => ({ type: playerActionTypes.UPDATE_PAYOUT_RESPONSE, data });

const getWaitingHistoryListRequest = (data) => ({ type: playerActionTypes.GET_WAITINGHISTORYLIST_FOR_VARIANT_REQUEST, data });
const getWaitingHistoryListSuccess = (data) => ({ type: playerActionTypes.GET_WAITINGHISTORYLIST_FOR_VARIANT_RESPONSE, data });
const getWaitingHistoryListClean = () => ({ type: playerActionTypes.GET_WAITINGHISTORYLIST_FOR_VARIANT_CLEAN });

const getMultiQualifPlayersForReseatForNewDaySuccess = (data) => ({ type: playerActionTypes.GET_MULTIQUALIF_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_RESPONSE, data });
const getMultiQualifPlayersForReseatForNewDayRequest = (data) => ({ type: playerActionTypes.GET_MULTIQUALIF_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_REQUEST, data });

const getPlayersForReseatForNewDaySuccess = (data) => ({ type: playerActionTypes.GET_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_RESPONSE, data });
const getPlayersForReseatForNewDayRequest = (data) => ({ type: playerActionTypes.GET_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_REQUEST, data });

const getWatingListRequest = (data) => ({ type: playerActionTypes.GET_WAITINGLIST_FOR_VARIANT_REQUEST, data });
const getWaitingListSuccess = (data) => ({ type: playerActionTypes.GET_WAITINGLIST_FOR_VARIANT_RESPONSE, data });
const getWaitingListClean = () => ({ type: playerActionTypes.GET_WAITINGLIST_FOR_VARIANT_CLEAN });

const getPlayersCountSuccess = (data) => ({ type: playerActionTypes.GET_PLAYERS_COUNT_RESPONSE, data });

const getBustedPlayersRequest = (data) => ({ type: playerActionTypes.GET_BUSTEDPLAYERS_FOR_VARIANT_REQUEST, data });
const getBustedPlayersSuccess = (data) => ({ type: playerActionTypes.GET_BUSTEDPLAYERS_FOR_VARIANT_RESPONSE, data });
const getBustedPlayersClean = () => ({ type: playerActionTypes.GET_BUSTEDPLAYERS_FOR_VARIANT_CLEAN });

const getWithoutSeatPlayersRequest = (data) => ({ type: playerActionTypes.GET_WITHOUTSEATPLAYERS_FOR_VARIANT_REQUEST, data });
const getWithoutSeatPlayersSuccess = (data) => ({ type: playerActionTypes.GET_WITHOUTSEATPLAYERS_FOR_VARIANT_RESPONSE, data });
const getWithoutSeatPlayersClean = () => ({ type: playerActionTypes.GET_WITHOUTSEATPLAYERS_FOR_VARIANT_CLEAN });

const getFreeSeatsRequest = (data) => ({ type: playerActionTypes.GET_FREESEATS_FOR_VARIANT_REQUEST, data });
const getFreeSeatsSuccess = (data) => ({ type: playerActionTypes.GET_FREESEATS_FOR_VARIANT_RESPONSE, data });

const sitPlayerFromWaitingListSuccess = () => ({ type: playerActionTypes.UPDATE_PLAYER_IN_WAITING_LIST_RECEIVE });

const reseatPlayerRequest = (data) => ({ type: playerActionTypes.RESEAT_PLAYER_REQUEST, data });
const _notifyPlayerInVariantChange = (data) => ({ type: playerActionTypes.NOTIFY_PLAYER_IN_VARIANT_CHANGE, data });

const reseatPlayerSuccess = (data) => ({
  type: playerActionTypes.RESEAT_PLAYER_RESPONSE,
  data,
});

const swapPlayersSeatsRequest = (data) => ({ type: playerActionTypes.SWAP_PLAYERS_SEATS_REQUEST, data });

const swapPlayersSeatsResponse = (data) => ({
  type: playerActionTypes.SWAP_PLAYERS_SEATS_RESPONSE,
  data,
});

const freeSeatsRequest = (data) => ({
  type: playerActionTypes.FREE_SEATS_REQUEST,
  data,
});

const freeSeatsSuccess = (data) => ({
  type: playerActionTypes.FREE_SEATS_RESPONSE,
  data,
});

export const insertPlayer = (player) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(insertPlayerRequest());
    return restClient
      .put('/player', player)
      .then((response) => {
        dispatch(insertPlayerSuccess(response.data));
        return Promise.resolve(response);
      })
      .catch((err) => {
        insertPlayerFailed();
        return Promise.reject(err);
      });
  },
  'insertPlayer',
  'Can not insert player',
);

export const cleanPlayer = () => (dispatch) => {
  dispatch(insertPlayerClean());
};

export const insertedPlayerPrinted = (id) => (dispatch) => {
  dispatch({ type: playerActionTypes.INSERT_PLAYER_PRINTED, id });
};

export const payoutPlayerInVariant = (playerInTournamentVariantId, tournamentId, payoutAmount) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updatePlayerPayoutRequest());
    return restClient
      .post('/player/playeringame/payout', {
        id: playerInTournamentVariantId,
        tournamentId: tournamentId,
        payoutAmount: payoutAmount,
      })
      .then((response) => {
        dispatch(updatePlayerPayoutSuccess(response.data));
        return Promise.resolve(response);
      });
  },
  'payoutPlayerInVariant',
  'Can payout player',
);

export const notifyPlayerInVariantChange = (player) => (dispatch) => {
  dispatch(_notifyPlayerInVariantChange(player));
};

export const rebuyPlayerInVariant = (tournamentPhaseVariantId, playerInTournamentPhaseVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient.post(`/player/rebuy/${tournamentPhaseVariantId}/${playerInTournamentPhaseVariantId}`),
  'rebuyPlayerInVariant',
);

export const addonPlayerInVariant = (tournamentPhaseVariantId, playerInTournamentPhaseVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient.post(`/player/addon/${tournamentPhaseVariantId}/${playerInTournamentPhaseVariantId}`),
  'addonPlayerInVariant',
);

export const bonusPlayerInVariant = (tournamentPhaseVariantId, playerInTournamentPhaseVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient.post(`/player/bonus/${tournamentPhaseVariantId}/${playerInTournamentPhaseVariantId}`),
  'bonusPlayerInVariant',
);

export const swapSeatAtTheTable = (player1InTournamentPhaseVariantId, player2InTournamentPhaseVariantId, tableId, seatNum1, seatNum2) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient
    .post('/player/seat', [
      {
        id: player1InTournamentPhaseVariantId,
        tableId: tableId,
        seatNum: seatNum2,
      },
      {
        id: player2InTournamentPhaseVariantId,
        tableId: tableId,
        seatNum: seatNum1,
      },
    ])
    .then((response) => {
      dispatch(updatePlayerPayoutSuccess(response.data));
      return Promise.resolve(response);
    }),
  'swapSeatAtTheTable',
  'Can switch players',
);

export const reseatPlayers = (listOfPlayersToReseat) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient.post('/player/seat', listOfPlayersToReseat).then((response) => {
    dispatch(updatePlayerPayoutSuccess(response.data));
    return Promise.resolve(response);
  }),
  'reseatPlayers',
  'Can not reseat players',
);

export const unregisterPlayerFromVariant = (playerInTournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(deletePlayersRequest());
    return restClient.post(`/player/playeringame/${playerInTournamentId}/unregistered`, { cashBack: 0 }).then((response) => {
      dispatch(deletePlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'unregisterPlayerFromVariant',
  'Can not unregister player',
);

export const startUnregisterProcessPlayerFromVariant = (playerInTournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(deletePlayersRequest());
    return restClient.post(`/player/playeringame/${playerInTournamentId}/unregistering`).then((response) => {
      dispatch(deletePlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'startUnregisterProcessPlayerFromVariant',
  'Can not unregister player',
);

export const putPlayerOnWaitingList = (playerInTournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(deletePlayersRequest());
    return restClient.post(`/player/playeringame/${playerInTournamentId}/created`, { cashBack: 0 }).then((response) => {
      dispatch(deletePlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'putPlayerOnWaitingList',
  'Can not put player on waiting list',
);

export const putPlayerOnSeatingByFloorman = (playerInTournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(
    dispatch,
    () => {
        dispatch(deletePlayersRequest());
        return restClient.post(`/player/playeringame/${playerInTournamentId}/floormanSeating`, { cashBack: 0 }).then((response) => {
            dispatch(deletePlayersSuccess(response.data));
            return Promise.resolve(response);
        });
    },
    'putPlayerOnSeatingByFloorman',
    'Can not put player on seating by floorman list',
);

export const disqualifyPlayerFromVariant = (playerInTournamentId, cashback) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(disqualifyPlayerRequest());
    return restClient.post(`/player/playeringame/${playerInTournamentId}/dq`, { cashback }).then((response) => {
      dispatch(disqualifyPlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'disqualifyPlayerFromVariant',
  'Can not disqualify player',
);

export const reactivatePlayerFromVariant = (playerInTournamentId, tableId, seatNum) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(reactivePlayerRequest());
    return restClient
      .post(`/player/playeringame/${playerInTournamentId}/active`, {
        seatNum,
        tableId,
      })
      .then((response) => {
        dispatch(reactivePlayersSuccess(response.data));
        return Promise.resolve(response);
      });
  },
  'reactivatePlayerFromVariant',
  'Can not reactivate player',
);

export const closePlayerInVariant = (playerInTournamentId, finishConfirmed) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(deletePlayersRequest());
    return restClient.post(`/player/playeringame/${playerInTournamentId}`, { finishTimeConfirmed: finishConfirmed }).then((response) => {
      dispatch(deletePlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'closePlayerInVariant',
  'Can not delete player',
);

export const updatePlayerInGame = (id, seated, finishTimeConfirmed, stack) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updatePlayerRequest());
    return restClient
      .post('/player/playeringame', {
        id,
        seated,
        finishTimeConfirmed,
        stack,
      })
      .then((response) => {
        dispatch(updatePlayerResponse(response));
        return Promise.resolve(response);
      });
  },
  'updatePlayerInGame',
  'Cannot process request to change player in game.',
);

export const updatePlayerInGamePosition = (id, tournamentId, position) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updatePlayerPositionRequest());
    return restClient
      .post('/player/playeringame/position/', {
        id,
        tournamentId,
        position,
      })
      .then((response) => {
        dispatch(updatePlayerPositionResponse(response));
        return Promise.resolve(response);
      })
      .then(() => getTournamentPayouts(tournamentId)(dispatch));
  },
  'updatePlayerInGamePosition',
  'Cannot change players position',
);

export const getPlayersByStateAndTournamentVariantId = (state, tournamentVariantId, searchText) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayersRequest({ tournamentVariantId }));
    return restClient.get(`/player/tournamentvariant/${tournamentVariantId}/${state}/${searchText ? `?searchText=${searchText}` : ''}`).then((response) => {
      dispatch(getPlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getPlayersByStateAndTournamentVariantId',
  'Can not get list of players',
);

export const getActivePlayersByTournamentVariantId = (tournamentVariantId, searchText) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayersRequest({ tournamentVariantId }));
    return restClient.get(`/player/tournamentvariant/${tournamentVariantId}/active/${searchText ? `?searchText=${searchText}` : ''}`).then((response) => {
      dispatch(getPlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getActivePlayersByTournamentVariantId',
  'Can not get list of players',
);

export const getPlayersByTournamentVariantIdState = (tournamentId, tournamentVariantId, state) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayersRequest({ tournamentVariantId }));
    return restClient
      .get(`/player/tournament/${tournamentId}/tournamentvariant/${tournamentVariantId}${state !== undefined ? `/${state}` : ''}`)
      .then((response) => {
        dispatch(getPlayersSuccess(response.data));
        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch(getPlayersError(err));
        return Promise.reject(err);
      });
  },
  'getPlayersByTournamentVariantIdState',
  'Can not get list of players',
);

export const getFinishedPlayersByTournamentVariantId = (tournamentId, tournamentVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getFinishedPlayersRequest({ tournamentVariantId }));
    return restClient
      .get(`/player/tournamentvariant/${tournamentVariantId}/busted`)
      .then((response) => {
        dispatch(getFinishedPlayersSuccess(response.data));
        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch(getFinishedPlayersError(err));
        return Promise.reject(err);
      });
  },
  'getFinishedPlayersByTournamentVariantIdState',
  'Can not get list of finished players',
);

export const getPlayersByTournamentId = (tournamentId, onlyInCash = true) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayersRequest({ tournamentId }));
    return restClient
      .get(`/player/payout/tournament/${tournamentId}?onlyInCash=${onlyInCash}`)
      .then((response) => {
        dispatch(getPlayersSuccess(response.data));
        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch(getPlayersError(err));
        return Promise.reject(err);
      });
  },
  'getPlayersByTournamentId',
  'Can not get list of players',
);

export const getPlayersByTournamentVariantId = (tournamentVariantId, playerId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () =>
    // dispatch(getPlayersRequest({ tournamentVariantId }));
    restClient.get(`/player/payout/tournament/${tournamentVariantId}/player/${playerId}}`).then((response) => Promise.resolve(response)),
  'getPlayersByTournamentVariantId',
  'Can not get player',
);

export const getPlayerPayout = (tournamentVariantId, playerName, paidConfirmend) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayerPayoutRequest());
    return restClient
      .get(`/player/payout/tournament${tournamentVariantId ? `/${tournamentVariantId}` : ''}?${playerName ? `name=${playerName}&` : ''}${paidConfirmend ? 'state=paidConfirmend' : ''}`)
      .then((response) => {
        dispatch(getPlayerPayoutSuccess(response.data));
        return Promise.resolve(response);
      });
  },
  'getPlayerPayout',
  'Can not get list of payouts',
);

export const getWaitingList = (tournamentVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getWatingListRequest({ tournamentVariantId }));
    return restClient.get(`/player/tournamentvariant/${tournamentVariantId}/waitingList`).then((response) => {
      dispatch(getWaitingListSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getWaitingList',
  'Can not get list of waiting players',
);

export const getPlayersCount = (tournamentId) => (dispatch) => {
  restClient.get(`/player/tournament/${tournamentId}/count`).then((response) => {
    dispatch(getPlayersCountSuccess({ tournamentId, ...response.data }));
    return Promise.resolve(response);
  });
};
export const cleanWaitingList = () => (dispatch) => {
  dispatch(getWaitingListClean());
};

export const getWaitingHistoryList = (tournamentVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getWaitingHistoryListRequest({ tournamentVariantId }));
    return restClient.get(`/player/tournamentvariant/${tournamentVariantId}/waitingListHistory`).then((response) => {
      dispatch(getWaitingHistoryListSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getWaitingHistoryList',
  'Can not get list of waiting players',
);

export const giveupStack = (playerInTournamentPhaseVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    return restClient.post(`/player/playeringame/${playerInTournamentPhaseVariantId}/giveup`).then((response) => {
      return Promise.resolve(response);
    });
  },
  'giveupStack',
  'Can not giveup stack',
);

export const getMultiQualifPlayersForReseatForNewDay = (tournamentId, variantIds) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getMultiQualifPlayersForReseatForNewDayRequest());
    if (!variantIds || variantIds === " " || !tournamentId)
      return Promise.resolve();
    return restClient.get(`/newday/players/tournament/${tournamentId}?ids=${variantIds}`).then((response) => {
      dispatch(getMultiQualifPlayersForReseatForNewDaySuccess(response.data));
      return Promise.resolve(response);

    });
  },
  'getMultiQualifPlayersForReseatForNewDay',
  'Can not get list of players for reseat for new day',
);

export const getPlayersForReseatForNewDay = (variantIds) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayersForReseatForNewDayRequest());
    if (!variantIds || variantIds === " ")
      return Promise.resolve();
    return restClient.get(`/player/tournamentvariant/?ids=${variantIds}`).then((response) => {
      dispatch(getPlayersForReseatForNewDaySuccess(response.data));
      return Promise.resolve(response);

    });
  },
  'getPlayersForReseatToNewDay',
  'Can not get list of players for reseat for new day',
);

export const reseatPlayersForNewDay = (payload) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient
    .post(`/newday`, payload)
    .then((response) => {
      dispatch(sitPlayerFromWaitingListSuccess());
      return Promise.resolve(response);
    }),
  'reseatPlayersForNewDay',
  'Cannot reseat players for newDay',
);

export const cleanWaitingHistoryList = () => (dispatch) => {
  dispatch(getWaitingHistoryListClean());
};

export const getBustedPlayers = (tournamentVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getBustedPlayersRequest({ tournamentVariantId }));
    return restClient.get(`/player/tournamentvariant/${tournamentVariantId}/busted`).then((response) => {
      dispatch(getBustedPlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getBustedPlayers',
  'Can not get list of busted players',
);

export const cleanBustedPlayers = () => (dispatch) => {
  dispatch(getBustedPlayersClean());
};

export const getWithoutSeatPlayers = (tournamentVariantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getWithoutSeatPlayersRequest({ tournamentVariantId }));
    return restClient.get(`/player/tournamentvariant/${tournamentVariantId}/floormanSeating`).then((response) => {
      dispatch(getWithoutSeatPlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getWithoutSeatPlayers',
  'Can not get list of players without seat',
);

export const cleanWithoutSeatPlayers = () => (dispatch) => {
  dispatch(getWithoutSeatPlayersClean());
};

export const getFreeSeat = (dispatch, tournamentVariantId) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getFreeSeatsRequest({ tournamentVariantId }));
    return restClient.get(`/player/findSeat/${tournamentVariantId}`).then((response) => {
      dispatch(getFreeSeatsSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getFreeSeat',
  'Can not get free seats',
);

export const getFreeSeats = (tournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(freeSeatsRequest());
    return restClient.get(`/player/findSeats/${tournamentId}`).then((response) => {
      dispatch(freeSeatsSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getFreeSeats',
  'Can not find free seats',
);

export const sitPlayerFromWaitingList = (id, tableId, seatNum) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient
    .post('/player/playeringame', {
      id,
      tableId,
      seatNum,
      seated: true,
    })
    .then((response) => {
      dispatch(sitPlayerFromWaitingListSuccess());
      return Promise.resolve(response);
    }),
  'sitPlayerFromWaitingList',
  'Cannot seat player from waiting list',
);

export const sitPlayerAndReturnToGameIfNeeded = (id, tableId, seatNum) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient
    .post('/player/playeringame', {
      id,
      tableId,
      seatNum,
      seated: true,
      returnToGame: true,
    })
    .then((response) => {
      dispatch(sitPlayerFromWaitingListSuccess());
      return Promise.resolve(response);
    }),
  'sitPlayerAndReturnToGameIfNeeded',
  'Cannot seat player from waiting list',
);

export const reseatPlayer = (playerInTournamentPhaseVariantId, tableId, seatNum) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(reseatPlayerRequest());
    return restClient
      .post('/player/playeringame/', [
        {
          id: playerInTournamentPhaseVariantId,
          tableId,
          seatNum,
        },
      ])
      .then((response) => {
        dispatch(reseatPlayerSuccess(response.data));
        return Promise.resolve(response);
      });
  },
  'reseatPlayer',
  'Can not reseat player',
);

export const swapPlayersSeats = (playerA, playerB) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(swapPlayersSeatsRequest());
    return restClient
      .post('/player/seat/swap', [
        {
          id: playerA.playerInTournamentPhaseVariantId,
          tableId: playerA.tableId,
          seatNum: playerA.seatNum,
        },
        {
          id: playerB.playerInTournamentPhaseVariantId,
          tableId: playerB.tableId,
          seatNum: playerB.seatNum,
        },
      ])
      .then((response) => {
        dispatch(swapPlayersSeatsResponse(response.data));
        return Promise.resolve(response);
      });
  },
  'swapPlayersSeats',
  'Can not swap players seats',
);

export const searchPlayersForBuyIn = (variantId, searchText) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayersRequest({ variantId }));
    return restClient.get(`/player/tournament/${variantId}/register/buyin?searchText=${searchText}`).then((response) => {
      dispatch(getPlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'searchPlayersForBuyIn',
  'Can not find player for buyin',
);

export const searchPlayersForReentry = (tournamentId, searchText) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayersRequest({ tournamentId }));
    return restClient.get(`/player/tournament/${tournamentId}/register/reentry?searchText=${searchText}`).then((response) => {
      dispatch(getPlayersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'searchPlayersForReentry',
  'Can not find player for reentry',
);

export const searchPlayersForReentryBuyin = (variantId, searchText) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPlayersRequest({ variantId }));
    return restClient.get(`/player/variant/${variantId}/register?searchText=${searchText}`).then((response) => {
      dispatch(getPlayersSuccess(response.data));

      return Promise.resolve(response);
    });
  },
  'searchPlayersForReentry',
  'Can not find player for reentry',
);