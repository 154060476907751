import React, { useState } from "react";
import { Button } from "reactstrap";
import ProfileModal from "../shared/ProfileModal";
import { ReactComponent as IconProfile } from "../../image/profile-icon.svg";

import currentUserUtil from "../../util/currentUser";

const DirectorMainMenuHeader = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  function toggleProfileModal() {
    setIsProfileModalOpen(!isProfileModalOpen);
  }

  return (
    <div id="director-header">
      <div className="d-none d-md-block">
        <h3>{`Hello, ${currentUserUtil.getUserAlias()}`}</h3>
        <div className="group">
          <span className="no-wrap">Check your&nbsp;</span>
          <Button
            className="profile-link"
            onClick={() => {
              toggleProfileModal();
            }}
          >
            <IconProfile className="card-icon tables" />
          </Button>
        </div>
      </div>
      <div className="d-block d-md-none">
        <div>
          <div
            className="profile-link"
            onClick={() => {
              toggleProfileModal();
            }}
          >
            <IconProfile className="card-icon tables" />
          </div>
        </div>
        <h3 className={"font-weight-bold"}>{`MTT list`}</h3>

        <div className="group">
          <span className="no-wrap">{`Hello, ${currentUserUtil.getUserAlias()}`}</span>
        </div>
      </div>
      {isProfileModalOpen && (
        <ProfileModal isOpen={isProfileModalOpen} toggle={toggleProfileModal} />
      )}
    </div>
  );
};

export default DirectorMainMenuHeader;
