import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import TournamentHeader from "../../shared/TournamentHeader";
import Loader from "../../shared/Loader";
import { faCheck, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransferPlayers from "./TransferPlayers";
import TablesForNewDay from "./TablesForNewDay";
import Overview from "./Overview";
import { updateVariant } from '../../../actions/tournamentActions';
import {
  getPlayersForReseatForNewDay, getMultiQualifPlayersForReseatForNewDay, reseatPlayersForNewDay
} from '../../../actions/playerActions';
import { history } from "../../../service/history";
import restClient from "../../../util/restClient";
import TableOccupancyWarning from "./TableOccuancyWarning";
import ReseatConfirmModal from "./ReseatConfirmModal";
import ConfirmationModal from "../../modals/ConfirmationModal";

const ReseatForNewDay = (props) => {

  const dispatch = useDispatch();
  //Overview
  const [finalSeatingPlan, setFinalSeatingPlan] = useState([]);
  //transferPlayers
  const initPlayersForReseat = [];
  const [selectedFromFlights, setSelectedFromFlights] = useState([]);
  const [selectedToFlight, setSelectedToFlight] = useState({});
  const [playersForReseat, setPlayersForReseat] = useState(initPlayersForReseat);
  const { multiQualifPlayersForNewDay } = useSelector((state) => state.player || []) || [];
  const [playersOnFlightTo, setPlayersOnFlightTo] = useState({data: null, error: null, isLoading: true})
  const [giveUpStackPlayers, setGiveUpStackPlayers] = useState([])
  const [allMultipleQualifPlayersResolved, setAllMultipleQualifPlayersResolved] = useState(true)
  const [showNotAllMultipleQualifPlayersResolved, setShowNotAllMultipleQualifPlayersResolved] = useState(false)

  useEffect(() => {
   reloadPlayers()
  }, [selectedFromFlights.length]);

  //tablesForNewDay
  const TABLE_SIZE = 10;
  const [wizardIndex, setWizardIndex] = useState(0);
  const [isTransferStepCompleted, setIsTransferStepCompleted] = useState(false);
  const [playersToBeReseatedForNewDay, setPlayersToBeReseatedForNewDay] = useState([]);
  const [minNumOfTables, setMinNumOfTables] = useState(undefined);
  const [isTableStepCompleted, setTableStepCompleted] = useState(false);

  const [tableMaxOccupancy, setTableMaxOccupancy] = useState(0);
  const [maxPlayersAtTable, setMaxPlayersAtTable] = useState(0);
  const [showTableOccupancyWarning, setShowTableOccupancyWarning] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [selectedTables, setSelectedTables] = useState([]);
  const tournament = useSelector((state) => state.tournaments.tournament);

  const isNextStepPossible = ((wizardIndex === 0 && isTransferStepCompleted) || (wizardIndex === 1 && isTransferStepCompleted && isTableStepCompleted))

  useEffect(() => {
    const numOfTables = Math.ceil((playersToBeReseatedForNewDay.length + playersOnFlightTo?.data?.length || 0) / tableMaxOccupancy);
    setMinNumOfTables(numOfTables);
  }, [tableMaxOccupancy]);

  useEffect(() => {
    setTableStepCompleted(selectedTables.length >= minNumOfTables);
  }, [selectedTables.length, minNumOfTables]);

  const reloadPlayers = () => {
    const tournamentVarianIds = selectedFromFlights.map(flight => flight.value.id).join(",");
    dispatch(getPlayersForReseatForNewDay(tournamentVarianIds));
    dispatch(getMultiQualifPlayersForReseatForNewDay(tournament.id, tournamentVarianIds));
  }

  const giveUpStackForPlayer = (player) => {
    const newGiveUpStackPlayers = [...giveUpStackPlayers]
    newGiveUpStackPlayers.push(player)
    setGiveUpStackPlayers(newGiveUpStackPlayers)
  }

  const processOnTableClick = (table, state) => {
    if (!table)
      return;
    const tableInEdit = selectedTables.find((t) => t.tableDefId === table.id);
    if (tableInEdit) {
      // cancel edit
      setSelectedTables(selectedTables.filter((t) => t.tableDefId !== table.id));
      return;
    }

    const variantId = parseInt((selectedToFlight.value || {}).id);

    // const tableActiveOnAnotherVariant = table.tournamentVariant.filter((v) => parseInt(v.id) !== variantId && v.numOfPlayers > 0);
    // if (tableActiveOnAnotherVariant.length > 0) {
    //   return;
    // }

    const currentVariantOnTable = table.tournamentVariant.find((v) => parseInt(v.id) === variantId);

    if (currentVariantOnTable) {
      // if (currentVariantOnTable.numOfPlayers > 0) {
      //   return;
      // }

      setSelectedTables([
        ...selectedTables,
        {
          id: currentVariantOnTable.gameTableId,
          tableDefId: table.id,
          tableName: table.tableName,
          //state: currentVariantOnTable.state === 'open' ? 'closed' : 'open',
        },
      ]);
    } else {
      setSelectedTables([
        ...selectedTables,
        {
          tableDefId: table.id,
          gameTableId: table.tournamentVariant ? table.tournamentVariant.gameTableId : undefined,
          tableName: table.tableName,
          state: 'open',
          tournamentPhaseVariantId: props.variantId,
          isNew: true,
          prevVariantId: table.tournamentVariant ? table.tournamentVariant.id : undefined,
        },
      ]);
    }
    setTableStepCompleted(selectedTables.length >= minNumOfTables);
  };

  const setAfterConfirm = () => {
    //setWizardIndex(0);
    setSelectedFromFlights([]);
    //setSelectedToFlight({});
    setShowConfirmModal(true);
  }

  const onSelectedToFlight = async (p) => {
    setSelectedToFlight(p)
    await fetchPlayersOnFlightTo(p)
  }

  const fetchPlayersOnFlightTo = useCallback(async (param) => {
    if (!param) {
      return
    }
    await restClient.getAsyncWrap(`/player/tournament/${param.value.tournamentId}/tournamentvariant/${param.value.id}?states=created,active`, playersOnFlightTo, setPlayersOnFlightTo)
  }, [])

  const confirmReseat = () => {
    const payload = {
      variantId: selectedToFlight.value.id,
      tables: selectedTables,
      finalSeatingPlan,
      maxPlayersAtTable,
      tableMaxOccupancy,
      giveUpStackPlayers: giveUpStackPlayers.map(p => p.playerInTournamentPhaseVariantId)
    }

    dispatch(reseatPlayersForNewDay(payload)).then(() => {
      setAfterConfirm();
    })
  }

  return (
    <div id="wizard">
      {
        <Loader>
          <TournamentHeader tournament={tournament} />
          <div className="py-4 navigation">
            <Button
              className="menu-button step-done"
              onClick={() => {
                setWizardIndex(0);
              }}
            >
              <div className="mx-3 p-1 icon">
                <FontAwesomeIcon visibility="visible" icon={faCheck} />
              </div>
              <span>Transfer Players</span>
            </Button>

            <Button
              disabled={!isTransferStepCompleted}
              className={`menu-button ${wizardIndex >= 1 ? "step-done" : ""}`}
              onClick={() => {
                setWizardIndex(1);
              }}
            >
              <div className="mx-3 p-1 icon">
                <FontAwesomeIcon visibility="visible" icon={faCheck} />
              </div>
              <span>Tables</span>
            </Button>

            <Button
              disabled={!isTransferStepCompleted}
              className={`menu-button ${wizardIndex >= 2 ? "step-done" : ""}`}
              onClick={() => {
                setWizardIndex(2);
              }}
            >
              <div className="mx-3 p-1 icon">
                <FontAwesomeIcon visibility="visible" icon={faCheck} />
              </div>
              <span>Overview</span>
            </Button>
          </div>
          <div className="body m-5">
            {wizardIndex === 0 && (
              <TransferPlayers
                  playersForNewDay={multiQualifPlayersForNewDay}
                  playersForReseat={playersForReseat}
                  setPlayersForReseat={setPlayersForReseat}
                  tournament={tournament}
                  selectedToFlight={selectedToFlight}
                  setSelectedToFlight={onSelectedToFlight}
                  setSelectedFromFlights={setSelectedFromFlights}
                  selectedFromFlights={selectedFromFlights}
                  setFinalPlayersForReseat={setPlayersToBeReseatedForNewDay}
                  setIsTransferStepCompleted={setIsTransferStepCompleted}
                 // reloadPlayers={reloadPlayers}
                  playersOnFlightTo={playersOnFlightTo.data}
                  giveUpStackForPlayer={giveUpStackForPlayer}
                  giveUpStackPlayers={giveUpStackPlayers}
                  setAllMultipleQualifPlayersResolved={setAllMultipleQualifPlayersResolved}
              />
            )}
            {wizardIndex === 1 && (
              <TablesForNewDay
                playersForReseat={playersToBeReseatedForNewDay}
                playersOnFlightTo={playersOnFlightTo.data}
                tournament={tournament}
                variantId={selectedToFlight.value.id}
                selectedTables={selectedTables}
                processTableClick={processOnTableClick}
                setMinNumOfTables={setMinNumOfTables}
                minNumOfTables={minNumOfTables}
                maxPlayersAtTable={maxPlayersAtTable}
                setMaxPlayersAtTable={setMaxPlayersAtTable}
                tableMaxOccupancy={tableMaxOccupancy}
                setTableMaxOccupancy={setTableMaxOccupancy}
                tableSize={TABLE_SIZE} />
            )}
            {wizardIndex === 2 && <Overview
              finalSeatingPlan={finalSeatingPlan}
              setFinalSeatingPlan={setFinalSeatingPlan}
              tournament={tournament}
              selectedTables={selectedTables}
              playersForReseat={playersToBeReseatedForNewDay.concat(playersOnFlightTo.data)}
              tableMaxOccupancy={tableMaxOccupancy}
              tableSize={maxPlayersAtTable}
              maxPlayersAtTable={maxPlayersAtTable} />
            }
          </div>
          <Row className="confirm-buttons">
            <Col xs={{ span: 1, offset: 2 }}>
              {wizardIndex !== 0 && <Button
                className="fit outline img-left"
                color="secondary"
                onClick={() => {
                  if (wizardIndex === 0) {
                    history.push("/");
                  } else {
                    setWizardIndex(wizardIndex - 1);
                  }
                }}
              >
                <FontAwesomeIcon visibility="visible" icon={faChevronLeft} />
                <span>Back</span>
              </Button>}
            </Col>
            <Col xs={{ span: 2, offset: 3 }}>
              <Button
                className="fit outline img-left"
                color="secondary"
                onClick={() => { history.push(`/tournament/${tournament.id}/newdayreseatHistory`); }}
              >
                <FontAwesomeIcon visibility="visible" />
                <span>Reseat history</span>
              </Button>
            </Col>
            <Col xs={3} align="right">
              {(wizardIndex === 0 || wizardIndex === 1) && (
                <Button
                  className="fit img-right ml-3"
                  color="secondary"
                  disabled={!isNextStepPossible}
                  onClick={() => {
                    if(!allMultipleQualifPlayersResolved) {
                      setShowNotAllMultipleQualifPlayersResolved(true)
                      return
                    }
                    if (isNextStepPossible) {
                      if(tableMaxOccupancy > maxPlayersAtTable) {
                        setShowTableOccupancyWarning(true);
                      } else {
                        setWizardIndex(wizardIndex + 1);
                      }
                    }
                  }}
                >
                  <span>Next step</span>
                  <FontAwesomeIcon visibility="visible" icon={faChevronRight} />
                </Button>
              )}
              {wizardIndex === 2 && (
                <Button
                  className="fit img-right ml-3"
                  color="secondary"
                  onClick={() => confirmReseat()}
                >
                  <span>Confirm</span>
                  <FontAwesomeIcon visibility="visible" icon={faChevronRight} />
                </Button>
              )}
            </Col>
          </Row>
          <TableOccupancyWarning open={showTableOccupancyWarning} submit={() => setShowTableOccupancyWarning(false)} />
          <ReseatConfirmModal open={showConfirmModal} submit={() => {
            setShowConfirmModal(false);
            sessionStorage.setItem('variantId', selectedToFlight.value.id);
            history.push(`/tournament/${tournament.id}`); }} />
          <ConfirmationModal
              confirmationText={`Please resolve multiple qualification for next step`}
              open={showNotAllMultipleQualifPlayersResolved}
              buttonText="Ok"
              close={() => setShowNotAllMultipleQualifPlayersResolved(false)}
              onSubmitButtonClick={() => setShowNotAllMultipleQualifPlayersResolved(false)}
          />
        </Loader>
      }
    </div>
  );
};

export default ReseatForNewDay;
