import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import CustomModal from "../../shared/CustomModal";
import ReseatIcon from "../../../image/icon-reseat all players.svg";
import CheckIcon from "../../../image/icon-chcek chipcounts.svg";
import SwapIcon from "../../../image/icon-swap.svg";
import ReseatPlayersModal from "./ReseatAllPlayersModal";
import CheckChipCountsModal from "./CheckChipCountsModal";
import MoveTablesModal from "./MoveTablesModal";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { updateGameTable } from "../../../actions/tablesActions";
import { history } from "../../../service/history";
import { cleanTournamentDetail } from "../../../actions/tournamentActions";
import { getVariant } from "../../../actions/tournamentActions";
import { loading, loadingDone } from "../../../actions/commonActions";

class TableSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleReseatAllPlayers: false,
      toggleCheckChipCounts: false,
      toggleMoveTables: false,
      toggleCloseTable: false,
      closeTableEnabled: true,
      active: true,
    };
  }

  setVisibility = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  toggle = () => {
    this.setState({
      toggleReseatAllPlayers: !this.state.toggleReseatAllPlayers,
    });
    this.setVisibility();
  };

  toggleCheckChipCounts = () => {
    this.setState({
      toggleCheckChipCounts: !this.state.toggleCheckChipCounts,
    });
    this.setVisibility();
  };

  toggleMoveTables = () => {
    var isOpen = this.state.toggleMoveTables;
    this.setState({
      toggleMoveTables: !this.state.toggleMoveTables,
    });

    if (isOpen === true) {
      this.props.toggle();
      history.push(`/tournament/${this.props.variant.tournamentId}`);
    }

    this.setVisibility();
  };

  tableHasOccupiedSeats = () => {
    return this.props.table.occupiedSeats > 0;
  };

  getCloseTableText = () => {
    return this.tableHasOccupiedSeats()
      ? "Please reaseat all players at the table, only then you will be able to close the table"
      : "Are you sure you want to close the table?";
  };

  toggleCloseTable = () => {
    if(this.props.players.length > 0) {
      this.setState({
        closeTableEnabled: false
      });
      return;
    }

    this.setState({
      toggleCloseTable: !this.state.toggleCloseTable,
    });
  };

  closeTable = () => {
    const variantId = this.props.variant.id;
    history.push(`/tournament/${this.props.variant.tournamentId}`);
    this.props.loading("getVariant");
    this.props
      .updateGameTable(variantId, [
        {
          id: this.props.table.id,
          state: "closed",
        },
      ])
      .then(() => {
        this.props.loadingDone("getVariant");
        this.props.getVariant(variantId);
      });
  };

  render() {
    return (
      <>
        {this.props.isOpen && this.state.active && (
          <CustomModal
            title={`Table ${this.props.table.tableName} settings`}
            open={this.props.isOpen}
            closeHandler={this.props.toggle}
            className="table-settings-modal table-setting-position"
          >
            <>
              <Button className="actions mt-4" onClick={this.toggle}>
                <img src={ReseatIcon} className="action-icon" />
                Reseat all players and close table
              </Button>
              {/*<Button className="actions" onClick={this.toggleCheckChipCounts}>*/}
              {/*  <img src={CheckIcon} className="action-icon" />*/}
              {/*  Check chip counts and close table*/}
              {/*</Button>*/}
              <Button className="actions" onClick={this.toggleMoveTables}>
                <img src={SwapIcon} className="action-icon" />
                Move table
              </Button>
              <Button className="actions" onClick={this.toggleCloseTable} disabled={!this.state.closeTableEnabled}>
                <img src={SwapIcon} className="action-icon" />
                Close table
              </Button>
              {!this.state.closeTableEnabled && <span className="info-text">Please reseat all players to be able to close the table</span> }
            </>
          </CustomModal>
        )}
        {this.state.toggleReseatAllPlayers && this.props.variant.id && (
          <ReseatPlayersModal
            isOpen={this.state.toggleReseatAllPlayers}
            toggle={this.toggle}
            players={this.props.players}
            variant={this.props.variant}
            selectedTable={this.props.table}
            isPlayerInTournamentWithoutSeat={this.props.isPlayerInTournamentWithoutSeat}
          />
        )}
        {this.state.toggleCheckChipCounts && (
          <CheckChipCountsModal
            isOpen={this.state.toggleCheckChipCounts}
            toggle={this.toggleCheckChipCounts}
            players={this.props.players}
            variant={this.props.variant}
            table={this.props.table}
          />
        )}
        {this.state.toggleMoveTables && (
          <MoveTablesModal
            isOpen={this.state.toggleMoveTables}
            toggle={this.toggleMoveTables}
            variant={this.props.variant}
            table={this.props.table}
          />
        )}
        {this.state.toggleCloseTable && (
          <ConfirmationModal
            confirmationText={this.getCloseTableText()}
            buttonText="Ok"
            open={this.state.toggleCloseTable}
            close={() => this.setState({ toggleCloseTable: false })}
            onSubmitButtonClick={
              this.tableHasOccupiedSeats()
                ? () => this.setState({ toggleCloseTable: false })
                : this.closeTable
            }
          />
        )}
      </>
    );
  }
}

TableSettingsModal.defaultProps = {
  table: {},
};

const mapDispatchToProps = {
  updateGameTable,
  cleanTournamentDetail,
  getVariant,
  loadingDone,
  loading,
};

export default connect(null, mapDispatchToProps)(TableSettingsModal);
