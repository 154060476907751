import React from 'react';
import {useSelector} from 'react-redux';
import {Alert} from 'react-bootstrap';
import {socketConst} from '../../constants/constants';

/*
* ERROR: -1,
  DISCONNECTED: 0,
  CONNECTED: 1,
  UNSUBSCRIBED: 2,
  SUBSCRIBED: 3,
*/
const Header = () => {
    const connectionState = useSelector((state) => state.socketsConnection.connectionState);
    if (connectionState === socketConst.ERROR || connectionState === socketConst.DISCONNECTED) {
        // red
        return (
            <Alert variant="danger">
                <h4>Connecting...</h4>
            </Alert>
        );
    }
    if (connectionState === socketConst.CONNECTED) {
        // green
        return null;
    }
    // orange
    return null;
};
export default Header;
