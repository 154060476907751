import React from "react";
import {clockNextLevelText} from "../../../../util/tournamentUtil";

const TileNextLevel = ({ nextUpComingLevel, className }) => {

    return (
        <div className={`item ${className}`}>
            <div className="title">Next Level</div>
            <div className="text">{clockNextLevelText(nextUpComingLevel)}</div>
        </div>
    )

}

export default TileNextLevel;