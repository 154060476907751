import React from "react";
import {resolveRegistrationState, formatDuration} from "../../../../util/tournamentUtil";
import StateVariantRegistration from "../../../../enum/StateVariantRegistration";

const TileRegCloseIn = ({ variant }) => {

    const registrationState = resolveRegistrationState(variant);

    let durationTillEndOfRegFormatted = "--:--";

    if (variant.registrationCloseAtLevel > 0) {
        if (registrationState.state === StateVariantRegistration.REGISTRATION_CLOSED) {
            durationTillEndOfRegFormatted = "closed";
        } else {
            const nowTillEndOfRegistrationInSec = registrationState.numSecTillEnd;
            durationTillEndOfRegFormatted = formatDuration(nowTillEndOfRegistrationInSec);
        }
    }

    return (
        <div className="item">
            <div className="title">Reg. close in</div>
            <div className="text">{durationTillEndOfRegFormatted}</div>
        </div>
    )

}

export default TileRegCloseIn;