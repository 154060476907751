import * as types from '../actiontypes/actionTypes';
import moment from "moment-mini";

const initState = {
  isLoading: false,
  errorMsg: '',
  result: null,
  logOutTime: 0,
};

export default function commonReducer(state = initState, action) {
  switch (action.type) {
    case types.LOGIN_REQ:
      return {
        ...state, isLoading: true, errorMsg: '', result: null,
      };
    case types.LOGIN_FAILURE:
      return { ...state, isLoading: false, errorMsg: action.data };
    case types.LOGIN_SUCCESS:
      return {
        ...state, isLoading: false, result: action.data, lastLoginAt: moment().toDate(),
      };
    // case types.LOGOUT_FAILURE:
    //     return Object.assign({}, state, {logOutTime: action.data});
    case types.LOGOUT_SUCCESS:
      return { ...state, isLoading: false, result: action.data };
    default:
      return state;
  }
}
