import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CustomModal from '../shared/CustomModal';

class SetPropertyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: undefined,
      showValidationText: false,
    };
  }

  handleChange(e) {
    if (e.target) {
      this.setState({ inputValue: e.target.value }, () => {
        const isValid = this.props.isValid(this.state.inputValue);
        this.setState({ showValidationText: !isValid });
      });
    }
  }

  onButtonClick() {
    if (this.props.isValid(this.state.inputValue)) {
      this.setState({ showValidationText: false });
      this.props.onButtonClick(this.state.inputValue);
      this.props.toggle();
    } else {
      this.setState({ showValidationText: true });
    }
  }

  render() {
    return (
      <CustomModal
        open={this.props.open}
        closeHandler={this.props.toggle}
        className="property-modal"
        dialogClassName="modal-small-width"
      >
        <div>
          <label>{this.props.inputLabel}</label>
          <input
            style={{ width: '100%' }}
            autoFocus
            type="text"
            value={this.state.inputValue !== undefined ? this.state.inputValue : this.props.value}
            placeholder={this.props.inputPlaceholder}
            id="roomName"
            onChange={(e) => this.handleChange(e)}
          />
          <label
            className="validation-text"
            style={{
              visibility: this.state.showValidationText ? 'visible' : 'hidden',
            }}
          >
            {this.props.validationText}
          </label>
          <Button style={{ width: '100%' }} onClick={() => this.onButtonClick()}>
            {this.props.buttonText}
          </Button>
        </div>
      </CustomModal>
    );
  }
}

SetPropertyModal.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isValid: PropTypes.func,
  validationText: PropTypes.string,
};

SetPropertyModal.defaultProps = {
  isValid: () => true,
};

export default SetPropertyModal;
