import React from "react";
import {formatDuration, resolveCurrentLevel} from "../../../../util/tournamentUtil";
import moment from "moment-mini";

const TileNextBreak = ({ variant, curLevel }) => {
    const {structureWithBreaks} = resolveCurrentLevel(variant);
    let durationTillNextBreak = "--:--";

    if (curLevel.mStartAt) {
        const nextBreakLevel = structureWithBreaks.find((level) => level.breakInMin  > 0 && level.level >= curLevel.level);
        if (nextBreakLevel) {
            const tillStartNextBreak = moment.duration(nextBreakLevel.mStartAt.diff(moment())).asSeconds();
            durationTillNextBreak = formatDuration(tillStartNextBreak);
        }
    }

    return (
        <div className="item">
            <div className="title">Next break</div>
            <div className="text">{durationTillNextBreak}</div>
        </div>
    )
}

export default TileNextBreak;