import React from "react";

import TileLevel from "./clock-tiles/tile-level";
import TileMainTime from "./clock-tiles/tile-main-time";
import TileNextLevel from "./clock-tiles/tile-next-level";
import TileRegCloseIn from "./clock-tiles/tile-reg-close-in";
import TileNextBreak from "./clock-tiles/tile-next-break";
import TilePlayers from "./clock-tiles/tile-players";
import TileUniquePlayers from "./clock-tiles/tile-unique-players";
import TileTotalChipcounts from "./clock-tiles/tile-total-chipcounts";
import TileAvgStack from "./clock-tiles/tile-avg-stack";
import TilePrizePool from "./clock-tiles/tile-prizepool";
import { Col, Row } from "react-bootstrap";
import TileNotes from "./clock-tiles/tile-notes";
import TileBlinds from "./clock-tiles/tile-blinds";
import TileMainTimeMobile from "./clock-tiles/title-main-time-mobile";

const AddRemoveMinutes = (props) => {
  const { variant, tournamentStats, hidePayouts } = props;

  let thisCurLevel;
  let thisNextUpComingLevel;

  if (variant.state === "created") {
    thisCurLevel = {};
    thisNextUpComingLevel = variant.currentLevel || {};
  } else {
    thisCurLevel = variant.currentLevel || {};
    thisNextUpComingLevel = variant.nextUpComingLevel || {};
  }

  return (
    <>
      <div className={"d-none d-md-block"}>
        <div className="director-times">
          <Row>
            <Col>
              <TileLevel curLevel={thisCurLevel} />
            </Col>
            <Col>
              <TileBlinds variant={variant} curLevel={thisCurLevel} />
            </Col>
            <Col>
              <TileRegCloseIn variant={variant} />
            </Col>
            <Col>
              <TilePlayers tournamentStats={tournamentStats} />
            </Col>
            <Col>
              <TileTotalChipcounts tournamentStats={tournamentStats} />
            </Col>
            <Col>
              <TilePrizePool
                variant={variant}
                tournamentStats={tournamentStats}
                hidePayouts={hidePayouts}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TileMainTime variant={variant} curLevel={thisCurLevel} />
            </Col>
            <Col>
              <TileNextLevel nextUpComingLevel={thisNextUpComingLevel} />
            </Col>
            <Col>
              <TileNextBreak variant={variant} curLevel={thisCurLevel} />
            </Col>
            <Col>
              <TileUniquePlayers tournamentStats={tournamentStats} />
            </Col>
            <Col>
              <TileAvgStack tournamentStats={tournamentStats} />
            </Col>
            <Col>
              <TileNotes variant={variant} />
            </Col>
          </Row>
        </div>
      </div>
      <div className={"d-block d-md-none"}>
        <div className="director-times">
          <Row xs={12}>
            <Col xs={12}>
              <TileMainTimeMobile variant={variant} curLevel={thisCurLevel} />
            </Col>
            <Col xs={12}>
              <TileNotes variant={variant} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddRemoveMinutes;
