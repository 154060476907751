import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Button, Form, Input} from 'reactstrap';
import {Col, Row} from 'react-bootstrap';

import PredefinedMsgEnum from '../../../enum/PrefilledMsgEnum';
import {publishEvent} from '../../../util/publishSocetUtil';


class ChatSender extends React.Component {
    state = {
        textMessage: '',
        showButtons: false
    };

    processInputBoxOnFocus = () => this.setState({showButtons: true});

    processInputBoxOnBlur = () => this.setState({showButtons: false});

    handleChange = (e) => this.setState({[e.target.name]: e.target.value});

    clearTextMessage = () => this.setState({textMessage: ''});

    sendMessage = (message) => {
        if (message) {
            const payload = {
                chatIdentifier: this.props.chatIdentifier,
                text: message.trim(),
                chatTypeId: 1,
                replyOnId: this.props.replyOnId
            };
            publishEvent('chatMessageRequest', payload, this.props.socket);
        }
        this.clearTextMessage();
    };

    render() {
        const showButtons = this.state.showButtons || !!this.state.textMessage;
        const isDisabledSendBtn = !this.state.textMessage.trim();
        return (
            <Fragment>
                <Row className="p-3 mb-4">
                    <Col><h4>Send message</h4></Col>
                </Row>
                {this.props.isFreeTextSender ? (
                        <Form className="mb-3">
                            <Input type="textarea" name="textMessage" id="text" value={this.state.textMessage}
                                   onChange={(e) => this.handleChange(e)}
                                   onBlur={this.processInputBoxOnBlur}
                                   onFocus={this.processInputBoxOnFocus}
                            />
                            {showButtons && <Row className="mt-1">
                                <Col><Button disabled={isDisabledSendBtn}
                                             onClick={() => this.sendMessage(this.state.textMessage)}>Send</Button> <Button
                                    onClick={() => this.clearTextMessage()}>Cancel</Button></Col>
                            </Row>}
                        </Form>
                    )
                    :
                    (
                        <Row>
                            <Col>
                                {PredefinedMsgEnum.map(msg => (
                                    <Button className="m-1" key={msg}
                                            onClick={() => this.sendMessage(msg)}>{msg}</Button>
                                ))}
                            </Col>
                        </Row>
                    )
                }
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

ChatSender.defaultProps = {
    replyOnId: undefined,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatSender);