import React from "react";
import { Button, Table, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment-mini";
import CircleIcon from "../functionalComponents/CircleIcon";
import CustomModal from "../shared/CustomModal";
import { getPlayerPayout } from "../../actions/playerActions";
import PayoutConfirmationModal from "./PayoutConfirmationModal";
import Loader from "../shared/Loader";

class PayoutModal extends React.Component {
  state = {
    modalPayoutConfirmation: false,
    selectedPlayer: {},
  };

  togglePayoutConfirmation = (selectedPlayer) => {
    this.setState({
      selectedPlayer: selectedPlayer !== {} ? selectedPlayer : {},
      modalPayoutConfirmation: !this.state.modalPayoutConfirmation,
    });
  };

  handleFilterChange = (e) => {
    this.setState({ filterPlayerName: e.target.value }, () => {
      this.props.getPlayerPayout(this.props.tournament.id, this.state.filterPlayerName);
    });
  };

  closeModal = () => {
    this.setState({ filterPlayerName: undefined });
    this.props.toggle();
  };

  getText = () => {
    if (this.props.tournament)
      return (
        <label>
          <CircleIcon color={this.props.tournament.color} />
          {this.props.tournament.name}
        </label>
      );
  };

  render() {
    const sortedPlayersList = this.props.payouts
      .filter((each) => {
        return each.toPayout > 0;
      })
      .sort((a, b) => {
        if (a.position && b.position) return a.position - b.position;
        if (a.position && !b.position) return -1;
        if (b.position && !a.position) return 1;
        return 0;
      });
    // const sortedPlayersList = JSON.parse(JSON.stringify(this.props.payouts));
    //
    // if (this.state.sortBy === "position") {
    //   sortedPlayersList.sort((a, b) => {
    //             if(a.position && b.position)
    //                 return a.position - b.position;
    //             if(a.position && !b.position)
    //                 return -1;
    //             if(b.position && !a.position)
    //                 return 1;
    //             return 0;
    //             });
    // } else {
    //   sortedPlayersList.sort(function(a, b) {
    //     return a.lastName.localeCompare(b.lastName);
    //   });
    // }

    if (!this.props.isOpen) {
      return <div />;
    }
    return (
      <>
        <CustomModal
          open
          closeHandler={this.closeModal}
          className="payout-modal"
          title="Payout"
          dialogClassName="modal-auto-width"
          text={this.getText}
        >
          <InputGroup className="mb-3 input-right-icon-control">
            <FormControl
              id="filter"
              placeholder="Search player"
              name="filter"
              className="input-part"
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={this.handleFilterChange}
              value={this.state.filterPlayerName}
            />
            <InputGroup.Text className="icon-part" id="basic-addon1">
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup>
          <Loader>
            <Table>
              <thead>
                <Row>
                  <Col key="position">POSITION</Col>
                  <Col key="name">NAME</Col>
                  <Col key="time">TIME</Col>
                  <Col key="payout">PAYOUT</Col>
                  <Col key="positionConfirm">POSITION CONFIRMED</Col>
                  <Col key="paid">PAID</Col>
                  <Col key="makepayout" />
                </Row>
              </thead>
              <tbody>
                {sortedPlayersList.map((player, index) => {
                  const paid = player.payoutAmount !== null;
                  const confirmedPosition = player.finishedAt && player.finishTimeConfirmed === 1;
                  return (
                    <Row key={index}>
                      <Col>
                        {player.position === undefined || player.position === null
                          ? " "
                          : player.position}
                      </Col>
                      <Col>
                        {player.firstName} {player.lastName}
                      </Col>
                      <Col>
                        {player.finishedAt === undefined || player.finishedAt === null
                          ? "-"
                          : moment(player.finishedAt).format("hh:mm:ss")}
                      </Col>
                      <Col>
                        {player.toPayout === undefined || player.toPayout === null
                          ? "-"
                          : player.toPayout}
                      </Col>
                      <Col>
                        <a className={confirmedPosition ? "green-text" : "purple-text"}>
                          {confirmedPosition ? "CONFIRMED" : "UNCONFIRMED"}
                        </a>
                      </Col>
                      <Col>{paid ? "Paid" : "Not yet"}</Col>
                      <Col>
                        <Button
                          className={
                            "mtt-btn " + (paid || !confirmedPosition ? "invisible" : "visible")
                          }
                          disabled={paid || !confirmedPosition}
                          key={index}
                          variant="outline-secondary"
                          onClick={() => {
                            this.togglePayoutConfirmation(player);
                          }}
                        >
                          Make Payout
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </tbody>
            </Table>
          </Loader>
        </CustomModal>
        {this.state.modalPayoutConfirmation && (
          <PayoutConfirmationModal
            isOpen={this.state.modalPayoutConfirmation}
            toggle={this.togglePayoutConfirmation}
            player={this.state.selectedPlayer}
            selectedTournament={this.props.tournament}
          />
        )}
      </>
    );
  }
}

PayoutModal.defaultProps = {
  isOpen: false,
  payouts: [],
};

const mapStateToProps = (state) => {
  return {
    payouts: state.player.payouts,
  };
};

const mapDispatchToProps = {
  getPlayerPayout,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutModal);
