import {v4 as uuidv4} from 'uuid';
import moment from 'moment-mini';
import TournamentState from '../enum/TournamentState';
import RegistrationState from '../enum/RegistrationState';
import StateVariantRegistration from '../enum/StateVariantRegistration';
import Colors from '../enum/Colors';
import Currency from '../enum/Currency';
import React from "react";

export const resolveState = (tournament) => {
    if (tournament.stateId === 1) {
        const variants = getFlatVariantList(tournament);
        for (let count = 0; count < variants.length; count++) {
            const variant = variants[count];
            if (variant.startedAt) {
                const startDate = moment(variant.startedAt).toDate();
                if (variant.stateId > 1 && startDate.getTime() < moment().toDate().getTime()) {
                    return TournamentState.ACTIVE;
                }
            }
        }
        return TournamentState.FUTURE;
    }
    if (tournament.stateId === 2) {
        return TournamentState.FINISHED;
    }
    throw new Error(`Unknown variant state ${tournament.stateId}`);
};

export const resolveStartOfEndOfStructureLevels = (variant) => {
    const tournamentVariant = {...variant};
    const structure = tournamentVariant._orgStructure ? tournamentVariant._orgStructure : (tournamentVariant.structure || []);

    if (structure.length === 0) {
        return {
            ...tournamentVariant,
            structure: structure,
        };
    }

    const mNow = moment();
    const mScheduledTime = moment.utc(tournamentVariant.scheduledStartAt, 'YYYY-MM-DD HH:mm:ssZ');
    const mScheduledTimeOrNow = mScheduledTime.isAfter(mNow) ? mScheduledTime : mNow;
    const mStartAt = (tournamentVariant.startedAt ? moment.utc(tournamentVariant.startedAt, 'YYYY-MM-DD HH:mm:ssZ') : mScheduledTimeOrNow).clone();

    const addStillOpenLevelFactor = (mEndAt, requireConfirmOnStart, startConfirmed) => {
        if (requireConfirmOnStart && !startConfirmed) {
            if (mNow.isAfter(mEndAt)) {
                const duration = moment.duration(mNow.diff(mEndAt)).asMinutes();
                mEndAt.add(duration, 'minutes');
            }
        }
    };

    const requireConfirmOnStartForLevel = 0;
    const startConfirmedForLevel = 1;
    const updatedStructure = [];
    let startingStructureLevel = variant.startingStructureLevel ?? 1;

    const structureFiltered = structure
        .sort((a, b) => {
            return a.level - b.level
        })
        .filter(s => {
            let levelInRange = (s.level >= startingStructureLevel) || s.level > variant.registrationCloseAtLevel;
            return levelInRange;
        });

    for (let index = 0; index < structureFiltered.length; index++) {
        const level = structureFiltered[index];
        const s = mStartAt.clone();
        const extratime = index === 0 && tournamentVariant.extraTime ? tournamentVariant.extraTime : 0;
        const pauseDuration = index === 0 && tournamentVariant.state === 'paused' ? moment.duration(mNow.diff(moment.utc(tournamentVariant.pausedAt))).asMinutes() : 0;
        const planedBreakDuration = level.breakInMin !== null ? level.breakInMin : 0;

        // add level
        mStartAt.add(level.durationInMin + extratime + pauseDuration, 'minutes');
        addStillOpenLevelFactor(mStartAt, requireConfirmOnStartForLevel, startConfirmedForLevel);
        const endOfLevel = mStartAt.clone();

        updatedStructure.push({
            ...level,
            type: 'level',
            breakInMin: 0,
            mStartAt: s.clone(),
            mEndAt: endOfLevel.clone(),
            requireConfirmOnStart: requireConfirmOnStartForLevel,
            startConfirmed: startConfirmedForLevel,
        });

        // add break
        mStartAt.add(planedBreakDuration, 'minutes');
        addStillOpenLevelFactor(mStartAt, level.requireConfirmOnStart, level.startConfirmed);
        const endOfBreak = mStartAt.clone();
        updatedStructure.push({
            ...level,
            type: 'break',
            durationInMin: 0,
            mStartAt: endOfLevel.clone(),
            mEndAt: endOfBreak.clone(),
        });
    }

    let response = {
        ...tournamentVariant,
        _orgStructure: structure,
        structure: updatedStructure,
        structureResolvedAt: mNow.clone(),
    };
    return response;
};

export const resolveCurrentLevel = (tournamentVariant) => {
    const mNow = moment();
    const tournamentVariantWithTimes = resolveStartOfEndOfStructureLevels(tournamentVariant);

    let currentLevel = -1;
    let currentLevelObj;
    let closestFutureLevelObj;
    if (tournamentVariantWithTimes.state === 'created') {
        currentLevel = 1;
        if (tournamentVariantWithTimes.structure && tournamentVariantWithTimes.structure.length > 0) {
            currentLevelObj = tournamentVariantWithTimes.structure[0];
        } else {
            currentLevelObj = {};
            console.warn('Variant structure not defined, current level can not be resolved!');
        }
        if (tournamentVariantWithTimes.structure && tournamentVariantWithTimes.structure.length > 1) {
            closestFutureLevelObj = tournamentVariantWithTimes.structure[1];
        } else {
            closestFutureLevelObj = {};
            console.warn('Variant structure not defined, upcomming level can not be resolved!');
        }
    } else {
        let isAllPrevLevelClosed = true;
        tournamentVariantWithTimes.structure.forEach((level) => {
            if (!level.mStartAt.isSame(level.mEndAt) || (level.requireConfirmOnStart === 1 && level.startConfirmed === 0)) {
                if ((currentLevel === -1) || (isAllPrevLevelClosed && level.level >= currentLevel && level.mStartAt.isBefore(mNow))) {
                    currentLevel = level.level;
                    currentLevelObj = level;
                } else if (currentLevel > -1 && closestFutureLevelObj === undefined && currentLevel <= level.level) {
                    closestFutureLevelObj = level;
                }
                if (isAllPrevLevelClosed) {
                    isAllPrevLevelClosed = !(level.requireConfirmOnStart && !level.startConfirmed);
                }
            }
        });
    }
    return {
        ...tournamentVariantWithTimes,
        structure: tournamentVariantWithTimes._orgStructure,
        structureWithBreaks: tournamentVariantWithTimes.structure,
        currentLevel: currentLevelObj,
        nextUpComingLevel: closestFutureLevelObj,
    };
};

export const getStructureByLevel = (level, type, structure = []) => {
    const structureBasedOnType = structure.filter((s) => s.type === type);
    const minLevel = Math.min(level, (structureBasedOnType[structureBasedOnType.length - 1] || {}).level ?? Number.MAX_SAFE_INTEGER);
    return structureBasedOnType.find((each) => each.level === minLevel);
};

export const isRegistrationOpen = (variant) => {
    const now = moment();
    if (variant.registration && variant.registrationStartAt && moment(variant.registrationStartAt).isBefore(now)) {
        if (variant.registrationCloseAtLevel === null || (variant.structure || []).length === 0) {
            return true;
        }
        const v = resolveStartOfEndOfStructureLevels(variant);
        let structureAtRegEnd = getStructureByLevel(v.registrationCloseAtLevel, 'break', v.structure);

        // case when "day starts at level" = 10 and "registration edns after this level" = 9
        if (!structureAtRegEnd && v.structure && v.structure.length > 0 && v.structure[0].level > v.registrationCloseAtLevel && !v.startedAt) {
            structureAtRegEnd = v.structure[0]
        }
        return structureAtRegEnd && now.isSameOrBefore(structureAtRegEnd.mEndAt);
    }
    return false;
};

export const resolveVariantRegistrationState = (variant) => {
    const now = moment();
    // 1	created
    // 2	active
    // 3	paused
    // 4	finished
    switch (variant.stateId) {
        case 1:
        case 2:
        case 3:
            if (isRegistrationOpen(variant)) {
                return RegistrationState.OPEN_REGISTRATION;
            }
            if (variant.registrationStartAt && moment(variant.registrationStartAt).isAfter(now)) {
                return RegistrationState.FUTURE;
            }
            return RegistrationState.REG_CLOSED;

        case 4:
            return RegistrationState.REG_CLOSED;
        default:
            throw new Error(`Unknown variant state ${variant.stateId}`);
    }
};

export const getRegistrationCloseAtLevel = (variantWithCurrentLevel) => {
    const numOfItems = (variantWithCurrentLevel.structure || []).length;
    let lastItemInStructure;
    if (numOfItems > 0) {
        lastItemInStructure = (variantWithCurrentLevel.structure[numOfItems - 1] || {})
    } else {
        lastItemInStructure = {};
    }
    const registrationCloseAtLevel = Math.min(variantWithCurrentLevel.registrationCloseAtLevel, lastItemInStructure.level ?? Number.MAX_SAFE_INTEGER);
    return registrationCloseAtLevel;
};

export const resolveRegistrationState = (variant) => {
    // const now = variant.stateId !== 3 ? moment() : variant.structureResolvedAt;
    const now = moment();
    if (!variant.registration) {
        return {state: StateVariantRegistration.NO_REGISTRATION};
    }
    if (moment(now).isBefore(variant.registrationStartAt)) {
        return {state: StateVariantRegistration.REGISTRATION_NOT_OPEN_YET};
    }
    if (variant.registrationCloseAtLevel > 0) {
        const v = resolveCurrentLevel(variant);
        const registrationCloseAtLevel = getRegistrationCloseAtLevel(v);
        const structureAtRegEnd = getStructureByLevel(registrationCloseAtLevel, 'break', v.structureWithBreaks);
        if (structureAtRegEnd && structureAtRegEnd.level && v.currentLevel && v.currentLevel.level && (v.currentLevel.level <= structureAtRegEnd.level)) {
            const {structureResolvedAt} = v;
            const numMinTillEnd = moment.duration(structureAtRegEnd.mEndAt.diff(structureResolvedAt)).asMinutes();
            const numSecTillEnd = moment.duration(structureAtRegEnd.mEndAt.diff(structureResolvedAt)).asSeconds();
            return {
                state: StateVariantRegistration.RUNNING_REGISTRATION,
                closeAt: structureAtRegEnd.mEndAt.toDate(),
                numMinTillEnd,
                numSecTillEnd,
            };
        }
        return {state: StateVariantRegistration.REGISTRATION_CLOSED};
    }
    return {state: StateVariantRegistration.RUNNING_NO_CLOSING_REGISTRATION};
};

export const getFlatVariantList = (tournament) => {
    const variants = [];
    (tournament.tournamentPhases || []).forEach((eachPhase) => {
        (eachPhase.variants || []).forEach((eachVariant) => {
            variants.push({phase: eachPhase.phase, phaseId: eachPhase.id, ...eachVariant});
        });
    });
    return variants;
};

export const isMultiday = (tournament) => !((tournament.tournamentPhases || []).length === 1 && (tournament.tournamentPhases[0].variants || []).length === 1);

export const createNewVariant = (name, tableSize, maxTableOccupancy, seatingByFloorman = 0) => ({
    _id: uuidv4(),
    name: name,
    structure: [{index: 0}],
    tables: [],
    tableSize: tableSize,
    tableMaxOccupancy: maxTableOccupancy,
    seatingByFloorman
});

export const getTournamentVariantTitle = (tournament, variant) => {
    if (!tournament) {
        return variant.name || '';
    }
    if ((tournament && !variant) || !isMultiday(tournament)) {
        return getTournamentTitle(tournament);
    }
    return `${tournament.num ? `#${tournament.num}` : ''} ${tournament.name ? tournament.name : ''}, ${variant.name ? variant.name : ''}`;
};

export const getTournamentTitle = (tournament) => `${tournament.eventNum ? `#${tournament.eventNum} ` : ''}${tournament.festivalName ? `${tournament.festivalName}: ` : ''}${tournament.name || ''}`;

export const getMttBgColorCss = (tournament) => {
    if (tournament.color) {
        return `bg-${tournament.color.toLowerCase()}`;
    }
    return '';
};

export const getFrColorCss = (color) => {
    if (color) {
        return `fr-${color.toLowerCase()}`;
    }
    return '';
};

export const countFreeSeats = (variant) => {
    const openTables = variant && variant.tables ? variant.tables.filter((t) => t.state === 'open') : [];
    const occupiedSeats = openTables.length === 0 ? 0 : openTables.map((t) => (t.occupiedSeats ? new Set(t.occupiedSeats.split(',').map((i) => i.trim())).size : 0)).reduce((total, a) => total + a);
    const allSeats = variant.tableSize ? (variant.tableSize * openTables.length) : 0;
    return allSeats - occupiedSeats;
};

export const constructEditMixin = (editList, playerPayouts, payoutsRecalculation, maxPosition, variantNames) => {
    return Array.from(Array(maxPosition).keys()).map((index) => {
        const position = index + 1;

        const payout = playerPayouts.find((each) => each.position === position);
        const editItem = editList.find((each) => each.position === position);
        const payoutsRecalculationItem = payoutsRecalculation.find(
            (each) => each.position === position,
        );
        if (payout && payout.tournamentPhaseVariantId && variantNames) {
            payout.variantName = variantNames[payout.tournamentPhaseVariantId]
        }

        return {
            ...payout,
            ...payoutsRecalculationItem,
            ...editItem,
            isEditItem: !!editItem,
            position,
        };
    })

        // const mix = Array.from(Array(maxPosition).keys()).map((index) => {
        //   const position = index + 1;
        //
        //   const payout = playerPayouts.find((each) => each.position === position);
        //   const editItem = editList.find((each) => each.position === position);
        //   const payoutsRecalculationItem = payoutsRecalculation.find(
        //     (each) => each.position === position,
        //   );
        //
        //   return {
        //     ...payout,
        //     ...payoutsRecalculationItem,
        //     ...editItem,
        //     isEditItem: !!editItem,
        //     position,
        //   };
        // });
        //
        // const payouts = [];
        // const onlyPlayers = [];
        //
        // mix.forEach((each) => {
        //   if ((each.id !== undefined || each._id !== undefined) && !each.toDelete) {
        //     if (each.playerId) {
        //       if (!each.rebuyOf) {
        //         const numOfDuplicate = (
        //           payouts.filter((player) => each.playerId === player.playerId) || []
        //         ).length;
        //         if (numOfDuplicate === 0) {
        //           each.entryState = 'buyIn';
        //           each.reEntryCount = 0;
        //         } else {
        //           const originalBuyIn = payouts.find(
        //             (player) => each.playerId === player.playerId && player.entryState === 'buyIn',
        //           );
        //           if (originalBuyIn) {
        //             originalBuyIn.hasReentry = true;
        //           }
        //           each.entryState = 'reentry';
        //           each.reEntryCount = numOfDuplicate - 1;
        //         }
        //       } else {
        //         each.entryState = 'reBuy';
        //       }
        //     }
        //     payouts.push(each);
        //   } else {
        //     onlyPlayers.push(each);
        //   }
        // });
        //
        // return {
        //   payouts,
        //   onlyPlayers,
        //   mix,
        // };
        ;
};

const logActions = [
    {key: 'all', name: 'All'},
    {key: 'swap_seats', name: 'Swap seats'},
    {key: 'player_reseat', name: 'Player reseat'},
    {key: 'new_player', name: 'New player'},
    {key: 'player_reentry', name: 'Reentry'},
    {key: 'variant_state_paused', name: 'Clock paused/started'},
    {key: 'price_pool_changed', name: 'Price pool changed'},
    {key: 'extra_time', name: 'Add/remove minute'},
    {key: 'level_changed', name: 'Level changed'},
    {key: 'seat_open', name: 'Seat open'},
];

export const getLogsActions = () => logActions;

export const getLogName = (key) => {
    const action = logActions.find((a) => a.key === key);
    if (action) {
        return action.name;
    }
    return key;
};

export const setTableStyle = (table, tablesToSave, variantId, conflictTables, variant, emptyTablesStyle = 'gray-table', occupiedTablesStyle = 'red-table',
                              thisVariantTableWithPlayersStyle = 'violet-table', thisVariantTableWithoutPlayersStyle = 'green-table',
                              selectedTablesStyle = 'darkGreen-table', selectedClosedTablesStyle = 'gray-table', closedTableStyle = 'gray-table') => {

    let baseStyle = 'poker-table-locker ';


    const isConflictTable = conflictTables && conflictTables.find(ct => ct.table_name === table.tableName) //table.tournamentVariant.find((v) => v.id !== variantId && v.numOfPlayers > 0);

    if (isConflictTable) {
        baseStyle = `${baseStyle}${occupiedTablesStyle} `;
    }

    if (table.id === undefined) {
        return `${baseStyle}noTable`;
    }
    const tableInEdit = tablesToSave.find((t) => t.tableDefId === table.id);
    if (tableInEdit) {
        if (tableInEdit.state === 'closed') {
            return `${baseStyle}${selectedClosedTablesStyle}`;
        }
        return `${baseStyle}${selectedTablesStyle}`;
    }

    if (table.state === 'open') {
        if (table.numOfPlayers > 0) {
            return `${baseStyle} ${thisVariantTableWithPlayersStyle}`;
        } else {
            return `${baseStyle} ${thisVariantTableWithoutPlayersStyle}`;
        }
    } else {
        return `${baseStyle} ${closedTableStyle}`;
    }

};

export const defaultEmptyTournament = () => ({
    color: Colors.GREEN,
    tournamentPhases: [{variants: [createNewVariant('Day 1', 0, 0)]}],
});

export const getCasinoNameByDomain = () => {
    const url = new URL(window.location)
    const hostname = url.hostname
    return hostname.split('.')[0]
}

export const getMainClockTime = (thisVariant, thisCurLevel) => {
    let mainClockFormatted = "--:--";
    if (thisVariant.stateId === 1) {
        const tillStart = moment
            .duration(moment(thisVariant.scheduledStartAt).diff(moment()))
            .asSeconds();
        mainClockFormatted = formatDuration(tillStart);
    } else {
        if (thisCurLevel.mEndAt) {
            const tillEndOfCurLevel = moment
                .duration(
                    thisCurLevel.mEndAt.diff(
                        thisVariant.stateId !== 3 ? moment() : thisVariant.structureResolvedAt
                    )
                )
                .asSeconds();
            mainClockFormatted = formatDuration(tillEndOfCurLevel);
        }
    }

    return mainClockFormatted
}

export const clockNextLevelText = (thisNextUpComingLevel) => {
    let value = "";
    if (thisNextUpComingLevel.type === "level") {
        value = thisNextUpComingLevel
            ? `Blinds: ${thisNextUpComingLevel.smallBlind !== undefined
                ? thisNextUpComingLevel.smallBlind
                : " "
            }/${thisNextUpComingLevel.bigBlind !== undefined
                ? thisNextUpComingLevel.bigBlind
                : " "
            }
             ${thisNextUpComingLevel.ante > 0 ? `, Ante:${thisNextUpComingLevel.ante}` : " "}`
            : ""
    }
    if (thisNextUpComingLevel.type === "break") {
        value = "BREAK";
    }

    return value;
}

export const formatDuration = (durationInSec) => {
    if (durationInSec >= 0) {
        const sec_num = parseInt(durationInSec, 10); // don't forget the second param
        const hours = Math.floor(sec_num / 3600);
        const minutes = Math.floor((sec_num - hours * 3600) / 60);
        const seconds = sec_num - hours * 3600 - minutes * 60;
        return `${hours > 0 ? `${hours > 9 ? hours : `0${hours}`}:` : ""}${minutes > 9 ? minutes : `0${minutes}`
        }:${seconds > 9 ? seconds : `0${seconds}`}`;
    }
    return "00:00";
};


export const getDefaultVariantStorageId = (variantId) => `useVariant_${variantId}`;
