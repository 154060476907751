import actionTypes from '../actiontypes/userActionTypes';

const initState = {
  users: [],
  updatePassword: {
    isUpdating: false,
    success: false,
    error: null,
  },
};
export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.USER_GET_RECEIVE:
      return {
        ...state,
        users: action.data,
      };
    case actionTypes.FIND_USER_RECEIVE:
      return {
        ...state,
        scanUser: action.data,
      };

    case actionTypes.USER_UPDATE_PASSWORD_REQUEST: {
      return { ...state, updatePassword: { ...initState.updatePassword, isUpdating: true } };
    }
    case actionTypes.USER_UPDATE_PASSWORD_SUCCESS: {
      return { ...state, updatePassword: { ...initState.updatePassword, success: true } };
    }
    case actionTypes.USER_UPDATE_PASSWORD_ERROR: {
      return { ...state, updatePassword: { ...initState.updatePassword, error: action.err } };
    }
    case actionTypes.USER_UPDATE_PASSWORD_CLEAN: {
      return { ...state, updatePassword: initState.updatePassword };
    }

    default:
      return state;
  }
};
