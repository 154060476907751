import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "../reducers";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();
const reactRouterMiddleware = routerMiddleware(history);

const logger = createLogger({
  predicate: () => true,
  collapsed: true,
});

const middleWares = [
  thunk,
  logger,
  reactRouterMiddleware
];

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(...middleWares),
  )
);




