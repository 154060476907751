import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import restClient from "../../../util/restClient";
import ConfirmationModal from "../../modals/ConfirmationModal";

const ManageBackground = (props) => {
  const { variant, tournament, showBgPreview } = props;
  const clockSettings = useSelector((state) => state.tournaments.clockSettings);
  const [bgPreviewVersion, setBgPreviewVersion] = useState(1);
  const [openConfirmDeleteBg, setOpenConfirmDeleteBg] = useState(false);

  const onFileChange = async (event) => {
    const formData = new FormData();

    // Update the formData object
    formData.append("image", event.target.files[0], event.target.files[0].name);

    if (clockSettings && clockSettings.data && clockSettings.data.id) {
      formData.append("id", clockSettings.data.id);
    }

    const res = await restClient.postAsync(`clock/${variant.id}/upload-clock-bg-preview`, formData);

    clockSettings.data = { ...clockSettings.data, ...res };

    props.setShowBgPreview(true);
    setBgPreviewVersion(bgPreviewVersion + 1);
    props.setBgPreviewVersion(bgPreviewVersion + 1);
  };

  function buildFileSelector() {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.onchange = onFileChange;
    return fileSelector;
  }

  const fileSelector = buildFileSelector();

  async function saveBgPreview(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", clockSettings.data.id);

    const res = await restClient.postAsync(`clock/${variant.id}/save-clock-bg-preview`, formData);
    clockSettings.data = { ...clockSettings.data, ...res };
    props.setShowBgPreview(false);
    setBgPreviewVersion(bgPreviewVersion + 1);
    props.setBgPreviewVersion(bgPreviewVersion + 1);
  }

  async function deleteBg() {
    const formData = new FormData();
    formData.append("id", clockSettings.data.id);
    formData.append("image", "");

    const res = await restClient.postAsync(`clock/${variant.id}/upload-clock-bg`, formData);
    clockSettings.data = { ...clockSettings.data, ...res };
    props.setShowBgPreview(false);
  }

  return (
    <div className="mt-4 d-none d-md-block">
      <Button
        color="primary"
        disabled={!(tournament.id >= 0 && variant.id >= 0)}
        className="confirm-btn"
        style={{
          width: "fit-content",
          margin: "7px",
        }}
        onClick={(e) => {
          e.preventDefault();
          fileSelector.click();
        }}
      >
        Load Background
      </Button>

      {!showBgPreview && clockSettings.data && clockSettings.data.bgFilename && (
        <Button
          color="primary"
          disabled={!(props.tournament.id >= 0 && variant.id >= 0)}
          className="confirm-btn"
          style={{
            width: "fit-content",
            margin: "7px",
          }}
          onClick={() => {
            setOpenConfirmDeleteBg(true);
          }}
        >
          Delete Background
        </Button>
      )}
      {showBgPreview && (
        <>
          <Button
            color="primary"
            disabled={!(props.tournament.id >= 0 && variant.id >= 0)}
            className="confirm-btn"
            style={{
              width: "fit-content",
              margin: "7px",
            }}
            onClick={async (e) => {
              saveBgPreview(e);
            }}
          >
            Save Background Preview
          </Button>
          <Button
            color="primary"
            disabled={!(props.tournament.id >= 0 && variant.id >= 0)}
            className="confirm-btn"
            style={{
              width: "fit-content",
              margin: "7px",
            }}
            onClick={(e) => {
              props.setShowBgPreview(false);
            }}
          >
            Cancel Background Preview
          </Button>
        </>
      )}
      <ConfirmationModal
        confirmationText={`Are you sure you want to delete background?`}
        buttonText="No"
        buttonText2="Yes"
        open={openConfirmDeleteBg}
        close={() => setOpenConfirmDeleteBg(false)}
        onSubmitButtonClick={() => setOpenConfirmDeleteBg(false)}
        onSubmitButton2Click={async () => {
          await deleteBg();
          setOpenConfirmDeleteBg(false);
        }}
      />
    </div>
  );
};

export default ManageBackground;
