import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVariantStatistics, getVariantStatisticsClean } from '../../actions/tournamentActions';
import socket from '../../service/EvSocket';

const useTournamentStats = (variantId, clientId, fetchDataTime) => {
  const dispatch = useDispatch();
  const variantStatistic = useSelector((state) => state.tournaments.statisticsVariant);

  const fetchStats = () => {
    if (variantId) {
      dispatch(getVariantStatistics(variantId, clientId));
    }
  };

  // useEffect(() => {
  //
  // }, []);

  useEffect(() => {
    socket.on('updateTournament', 'useTournamentStats', (payload) => {
      fetchStats();
    });
    socket.on('playersOnTableChange', 'useTournamentStats', (payload) => {
      fetchStats();
    });
    fetchStats();

    return () => {
      socket.removeHandler('updateTournament', 'useTournamentStats');
      socket.removeHandler('playersOnTableChange', 'useTournamentStats');

      dispatch(getVariantStatisticsClean(clientId));
    };
  }, [variantId, fetchDataTime]);
  return (variantStatistic[clientId] || {});
};

export default useTournamentStats;
