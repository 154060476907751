import React, {useState} from "react";
import {Col} from "react-bootstrap";
import {Prompt, Router, Switch} from "react-router-dom";
import {Button, Input, Label} from "reactstrap";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {history} from "../../service/history";
import Basic from "./detailcomponents/Basic";
import BasicVariant from "./detailcomponents/BasicVariant";
import Overview from "./detailcomponents/Overview";
import Profile from "./detailcomponents/Profile";
import Tables from "./tableReservation/Tables";
import {addTournament, deleteUnsavedTournamentDetail, updateTournamentDetail,} from "../../actions/tournamentActions";
import {insertTemplate} from "../../actions/templateActions";
import CustomModal from "../shared/CustomModal";
import TemplateImage from "../../image/template.png";
import {defaultEmptyTournament, isMultiday} from "../../util/tournamentUtil";
import SaveStructureTemplate from "./SaveStructureTemplate";
import {isValidByValidationResults} from "../../util/validationUtil";
import StructureWithStartingLevelContainer from "./detailcomponents/StructureWithStartingLevelContainer";

const PROFILE_GROUP = 0;
const BASIC_TOURNAMENT_GROUP = 1;
const BASIC_VARIANT_GROUP = 2;
const STRUCTURE_GROUP = 3;
const TABLES_GROUP = 4;
// const PERMISSIONS_GROUP = 5; if uncommented - change index of following
const OVERVIEW_GROUP = 5;


class MttDetail extends React.Component {
    constructor(props) {
        super(props);
        const locationState = props.location.state || {}
        this.state = {
            saveTemplateModalOpen: false,
            saveStructureModal: false,
            templateName: "",
            reachedRoute: locationState.reachedRoute || 0,
            currentRoute: locationState.currentRoute || 0,
            ignorePrompt: false,
        };
    }

    componentWillUnmount() {
        this.props.deleteUnsavedTournamentDetail();
    }

    getCurrentRelativePath() {
        return `${this.props.location.pathname}`;
    }

    getRelativePath(path) {
        return `${this.props.match.path}/${path}`;
    }

    isValid(errorObject) {
        return isValidByValidationResults(errorObject);
    }

    componentDidMount() {
        const {tournament} = this.props;
        this.props.updateTournamentDetail(tournament);
    }

    handleTemplateNameChange = (e) => {
        this.setState({
            templateName: e.target.value,
        });
    };

    openSaveTemplateModal() {
        this.setState({saveTemplateModalOpen: true});
    }

    toggleSaveStructureModal() {
        this.setState({saveStructureModal: !this.state.saveStructureModal});
    }

    saveTournament = () => {
        this.props.addTournament({
            ...this.props.tournament,
        });
    };

    saveTemplateAndTournament = () => {
        const {validationErrors, ...tournament} = this.props.tournament;
        tournament.tournamentPhases.forEach((eachPhase) => {
            eachPhase.variants.forEach((eachVariant) => {
                delete eachVariant.validationErrors;
                delete eachVariant._id;
                eachVariant.structure.forEach((eachStructure) => {
                    delete eachStructure.validationErrors;
                });
            });
        });
        this.props.insertTemplate("tournament_detail", this.state.templateName, tournament);
        this.saveTournament();
    };

    render() {
        const variants = [];
        if (this.props.tournament.tournamentPhases) {
            this.props.tournament.tournamentPhases.forEach((eachPhase) => {
                return eachPhase.variants.forEach((eachVariant) => {
                    eachVariant.phase = eachPhase.phase
                    variants.push(eachVariant);
                });
            });
        }

        const TablesAfterReseat = (props) => {
            return (
                <h3>Tables will be added after reseat to {props.variant.name}</h3>
            )
        }
        const routes = [
            {path: this.getRelativePath("profile"), group: PROFILE_GROUP, view: Profile},
            {
                path: this.getRelativePath("basic/tournament"),
                group: BASIC_TOURNAMENT_GROUP,
                view: Basic,
            },
        ]
            .concat(
                variants.map((eachVariant, index) => {
                    return {
                        path: this.getRelativePath(`basic/variant/${eachVariant._id}`),
                        group: BASIC_VARIANT_GROUP,
                        view: BasicVariant,
                        variantIndex: index,
                        variant: eachVariant,
                        showSubmenu: true,
                    };
                })
            )
            .concat(
                variants.map((eachVariant, index) => {
                    return {
                        path: this.getRelativePath(`structure/${eachVariant._id}`),
                        group: STRUCTURE_GROUP,
                        view: StructureWithStartingLevelContainer,
                        variantIndex: index,
                        variant: eachVariant,
                        showSubmenu: true,
                    };
                })
            )
            .concat(
                variants.map((eachVariant, index) => {
                    return {
                        path: this.getRelativePath(`tables/${eachVariant._id}`),
                        group: TABLES_GROUP,
                        view: eachVariant.phase > 1 ? TablesAfterReseat : Tables,
                        variantIndex: index,
                        variant: eachVariant,
                        showSubmenu: true
                    };
                })
            )
            .concat([
                /* {path: this.getRelativePath('permissions'), group: PERMISSIONS_GROUP}, */
                {path: this.getRelativePath("overview"), group: OVERVIEW_GROUP, view: Overview},
            ]);

        let isStructureTab = this.getCurrentRelativePath().includes("mtt/structure");

        let currentIndex = routes.findIndex((each) => {
            return each.path === this.getCurrentRelativePath();
        });

        if (currentIndex < 0) {
            currentIndex = 0;
        }

        const currentRouteParam = routes[currentIndex];
        const showSubmenu = currentRouteParam.showSubmenu && variants.length > 1;
        const Comp = currentRouteParam.view;
        const {tournament} = this.props;
        const variant = currentRouteParam.variant || {};

        const saveStructureTemplate = (templateName) => {
            this.props.insertTemplate("tournament_structure", templateName, variant.structure);
        };

        const setRoute = (route, reachedRoute) => {
            this.setState({currentRoute: routes[route].group});
            history.push(routes[route].path, {reachedRoute, currentRoute: routes[route].group});
        };

        const hasCurrentComponentNoValidationError = () => {
            /* finalize */
            if (typeof Comp.finalize === "function") {
                Comp.finalize(tournament, variant);
            }
            /* validation */
            let isValid;
            if (typeof Comp.getValidationErrors === "function") {
                const validationErrors = Comp.getValidationErrors(tournament, variant);
                isValid = this.isValid(validationErrors);
            } else {
                isValid = true;
            }

            return isValid;
        };

        const menuButtonOnClick = (route) => {
            if (this.state.reachedRoute === this.state.currentRoute) {
                setRoute(route, this.state.reachedRoute);
                return;
            }
            if (!hasCurrentComponentNoValidationError()) {
                return;
            }

            setRoute(route, this.state.reachedRoute);
        };

        const MenuButton = ({route, text}) => {
            const enabled = this.state.reachedRoute >= route;
            return (
                <Button
                    disabled={!enabled}
                    tabIndex={0}
                    onClick={() => menuButtonOnClick(route)}
                    className={`create-step-button ${enabled ? "mtt-phase-done" : ""}`}
                >
                    <div className="mx-3 p-1 icon">
                        <FontAwesomeIcon visibility="visible" icon={faCheck}/>
                    </div>
                    <span>{text}</span>
                </Button>
            );
        };

        return (
            <div id="create-tournament">
                <Prompt
                    when={!this.state.ignorePrompt && tournament && tournament.isSaved === false}
                    message={() => 'Are you sure? Please press "Save all changes" to save your changes.'}
                />
                <div className="mtt-create-steps">
                    <MenuButton route={PROFILE_GROUP} text="Create MTT"/>
                    <MenuButton route={BASIC_TOURNAMENT_GROUP} text="General MTT Settings"/>
                    <MenuButton
                        route={BASIC_VARIANT_GROUP}
                        text={
                            (tournament.tournamentPhases || []).length > 1 ? "Flights setup" : "Daily MTT setup"
                        }
                    />
                    <MenuButton route={STRUCTURE_GROUP} text="Structure"/>
                    <MenuButton route={TABLES_GROUP} text="Add tables"/>
                    <MenuButton route={OVERVIEW_GROUP} text="Overview"/>
                </div>
                <div className="pt-4 body" style={{backgroundColor: "black"}}>
                    {showSubmenu ? (
                        <Col sm={3}>
                            {variants.map((eachVariant, variantIndex) => {
                                let isDone =
                                    currentRouteParam.variantIndex !== undefined &&
                                    currentRouteParam.variantIndex >= variantIndex;
                                const isSkipped = this.state.currentRoute === TABLES_GROUP && eachVariant.phase > 1
                                if (isSkipped) {
                                    isDone = true
                                }

                                return (
                                    <div
                                        className={`mtt-create-submenu mb-3 ${isDone ? "mtt-phase-done" : ""} ${isSkipped ? "skipped" : ""}`}>
                                        <div className="mx-3 p-1">
                                            {isDone && <FontAwesomeIcon visibility="visible" icon={faCheck}/>}
                                        </div>
                                        <span>{eachVariant.name}</span>
                                    </div>
                                );
                            })}
                        </Col>
                    ) : null}
                    <Col sm={showSubmenu ? 9 : 12} className="mw-800">
                        <Router history={history}>
                            <Switch>
                                <Comp
                                    pageIndex={currentIndex}
                                    variant={variant}
                                    tournament={tournament}
                                    updateTournamentDetail={this.props.updateTournamentDetail}
                                />
                            </Switch>
                        </Router>
                    </Col>
                </div>
                <div className="confirm-buttons">
                    <div>
                        <Button
                            className="fit outline"
                            color="secondary"
                            onClick={() => {
                                this.setState({ignorePrompt: true}, () => {
                                    history.push("/");
                                })
                            }}
                        >
                            Back to MTT list
                        </Button>
                    </div>
                    <div align="right">
                        {isStructureTab && (
                            <Button
                                className="fit outline mr-3"
                                color="secondary"
                                onClick={() => {
                                    this.toggleSaveStructureModal();
                                }}
                            >
                                Save structure as a template
                            </Button>
                        )}
                        {currentIndex > 0 && (
                            <Button
                                className="fit outline img-left"
                                color="secondary"
                                onClick={() => {
                                    this.setState({ignorePrompt: true}, () => {
                                        const nextIndex = currentIndex > 0 ? currentIndex - 1 : 0;
                                        setRoute(nextIndex, this.state.reachedRoute);
                                    })
                                }}
                            >
                                <FontAwesomeIcon visibility="visible" icon={faChevronLeft}/>
                                <span>Previous step</span>
                            </Button>
                        )}
                        {currentIndex < routes.length - 1 ? (
                            <Button
                                className="fit img-right ml-3"
                                color="secondary"
                                onClick={() => {
                                    const isValid = hasCurrentComponentNoValidationError();

                                    if (isValid) {
                                        this.setState({ignorePrompt: true}, () => {
                                            const nextIndex = currentIndex < routes.length - 1 ? currentIndex + 1 : currentIndex;

                                            if (routes[nextIndex].group > this.state.reachedRoute) {
                                                this.setState({reachedRoute: routes[nextIndex].group});
                                            }
                                            setRoute(nextIndex, routes[nextIndex].group);
                                        })

                                    } else {
                                        console.error("Tournament is not valid!");
                                    }
                                }}
                            >
                                <span>Next step</span>
                                <FontAwesomeIcon visibility="visible" icon={faChevronRight}/>
                            </Button>
                        ) : (
                            <Button
                                className="fit ml-3"
                                color="secondary"
                                onClick={() => {
                                    this.openSaveTemplateModal();
                                }}
                            >
                                Activate MTT
                            </Button>
                        )}
                    </div>
                </div>
                <CustomModal
                    title="Save as a template"
                    text="Would you like to save this MTT template?"
                    open={this.state.saveTemplateModalOpen}
                    className="save-tournament-modal"
                    dialogClassName="modal-small-width"
                    closeHandler={() => {
                        this.setState({saveTemplateModalOpen: false});
                    }}
                    imagesrc={TemplateImage}
                    height="145px"
                >
                    <Col className="content">
                        <Label for="templateName">MTT template name</Label>
                        <Input
                            id="templateName"
                            placeholder="Type MTT template name"
                            name="templateName"
                            onChange={this.handleTemplateNameChange}
                            value={this.state.templateName}
                        />

                        <Button
                            disabled={this.state.templateName === ""}
                            color="primary mt-3"
                            onClick={() => {
                                this.saveTemplateAndTournament();
                                this.setState({saveTemplateModalOpen: false});
                            }}
                        >
                            Activate MTT and save template
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.saveTournament();
                                this.setState({saveTemplateModalOpen: false});
                            }}
                        >
                            Activate without saving template
                        </Button>
                    </Col>
                </CustomModal>
                <SaveStructureTemplate
                    openModal={this.state.saveStructureModal}
                    closeModal={() => {
                        this.toggleSaveStructureModal();
                    }}
                    saveTemplate={saveStructureTemplate}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tournament: state.tournaments.tournamentToSave
            ? state.tournaments.tournamentToSave
            : defaultEmptyTournament(),
    };
};

const mapDispatchToProps = {
    addTournament,
    insertTemplate,
    updateTournamentDetail,
    deleteUnsavedTournamentDetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(MttDetail);
