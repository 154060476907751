import { Button } from "react-bootstrap";
import { closeVariant } from "../../../actions/tournamentActions";
import React, { useState } from "react";
import FinishConfirmationModal from "./FinishConfirmationModal";
import { history } from "../../../service/history";
import ConfirmationModal from "../../modals/ConfirmationModal";
import CustomModal from "../../shared/CustomModal";
import { ReactComponent as DqIcon } from "../../../image/icon-DQ.svg";
import { useSelector, useDispatch } from "react-redux";

const FinishMtt = ({ variant, tournament }) => {
  const dispatch = useDispatch();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFinisConfirmationModalOpen, setIsFinisConfirmationModalOpen] = useState(false);
  const [isFinishedInfoModalOpen, setIsFinishedInfoModalOpen] = useState(false);

  const tournamentPayouts = useSelector((state) => state.tournamentPayouts.tournamentPayouts);
  const isFinishBtnDisabled = variant.id === undefined || variant.state === "finished";

  let isClocingVariant = false;
  const phases = (tournament || {}).tournamentPhases || [];
  if (phases.length > 0) {
    if ((phases[phases.length - 1].variants || []).length === 1) {
      isClocingVariant = phases[phases.length - 1].variants[0].id === variant.id;
    }
  }

  const onFinishBtnClick = () => {
    if (isClocingVariant && mttContainsUnassignedPayout()) {
      setIsConfirmationModalOpen(true);
    } else {
      setIsFinisConfirmationModalOpen(true);
    }
  };

  const mttContainsUnassignedPayout = () => {
    var payoutWithoutPlayer = tournamentPayouts.some((payout) => {
      return payout.playerId === undefined || (!payout.finishTimeConfirmed && payout.toPayout >= 0);
    });

    return payoutWithoutPlayer;
  };

  return (
    <div className="actions-panel-top action-panel d-none d-md-block">
      {tournament && tournament.id && (
        <Button
          className={isFinishBtnDisabled ? "disabled" : ""}
          disabled={isFinishBtnDisabled}
          onClick={onFinishBtnClick}
        >
          {isClocingVariant ? "Finish MTT" : "Finish day"}
        </Button>
      )}

      <ConfirmationModal
        confirmationText={"Please assign all payout positions to players"}
        buttonText="Ok"
        buttonText2="Finish anyway"
        open={isConfirmationModalOpen}
        close={() => setIsConfirmationModalOpen(false)}
        onSubmitButtonClick={() => setIsConfirmationModalOpen(false)}
        onSubmitButton2Click={() => setIsFinisConfirmationModalOpen(true)}
      />

      <FinishConfirmationModal
        open={isFinisConfirmationModalOpen}
        toggle={() => setIsFinisConfirmationModalOpen(false)}
        title={isClocingVariant ? "Finish MTT" : "Finish day"}
        variant={variant}
        onFinish={() => {
          dispatch(closeVariant(variant.id));
          setIsFinisConfirmationModalOpen(false);
          setIsFinishedInfoModalOpen(true);
        }}
      />

      <CustomModal
        open={isFinishedInfoModalOpen}
        toggle={() => setIsFinishedInfoModalOpen(false)}
        className="action-confirm-modal dark"
        title={isClocingVariant ? "MTT finished" : "Day finished"}
        dialogClassName="modal-small-width"
      >
        <>
          <DqIcon className="modal-action-icon" />
          <span>
            <b>
              If you need to find any additional info about this MTT, you can do it in MTT list /
              History
            </b>
          </span>
          <br />
          <Button
            color="primary"
            className="confirm-btn"
            onClick={() => {
              setIsFinishedInfoModalOpen(false);
              history.push("/");
            }}
          >
            Finish
          </Button>
        </>
      </CustomModal>
    </div>
  );
};

export default FinishMtt;
