import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import ToggleSwitch from "../../functionalComponents/ToggleSwitch";

const TemplatePayoutDetail = (props) => {
  const payouts = props.payouts || [];
  const [isMultiSelection, setMultiSelection] = useState(false);
  const [positionsNumber, setPositionsNumber] = useState(0);

  const setNumberOfITMPlayers = () => {
    var number = positionsNumber;
    if (number === payouts.length) return;

    var newPayouts = [...payouts];
    var newNumber = Math.abs(number - payouts.length);

    if (number > payouts.length) {
      for (var i = 0; i < newNumber; i++) {
        newPayouts.push({ position: newPayouts.length + 1, poolPrc: 0 });
      }
    } else {
      for (var i = 0; i < newNumber; i++) {
        newPayouts.pop();
      }
    }
    props.updatePayouts(newPayouts);

    setValid(positionsNumber);
  };

  const switchMultiSelection = () => {
    if (isMultiSelection) {
      var payoutsCopy = payouts.map((p) => {
        p.multiSelected = undefined;
        return p;
      });
      props.updatePayouts(payoutsCopy);
    }
    setMultiSelection(!isMultiSelection);
  };

  const onPayoutChange = (payout, newValue) => {
    var payoutsCopy = [...payouts];
    var pay = payoutsCopy.find((p) => {
      return p.position === payout.position;
    });

    if (isMultiSelection && payout.multiSelected === true) {
      var payoutsCopyAndValues = payoutsCopy.map((p) => {
        if (p.multiSelected === true) p.poolPrc = newValue;
        return p;
      });
      props.updatePayouts(payoutsCopyAndValues);
    } else {
      pay.poolPrc = newValue;
      props.updatePayouts(payoutsCopy);
    }
  };

  const onSelectionChange = (payout) => {
    var payoutsCopy = [...payouts];
    var pay = payoutsCopy.find((p) => {
      return p.position === payout.position;
    });

    if (pay.multiSelected === undefined) {
      pay.multiSelected = true;
    } else {
      pay.multiSelected = !pay.multiSelected;
    }
    props.updatePayouts(payoutsCopy);
  };

  const getAllocatedPercentage = () => {
    var sum = 0;
    payouts.map((p) => {
      sum += Math.round(parseFloat(p.poolPrc * 100));
    });
    return sum.toFixed(2);
  };

  const setValid = (count) => {
    if (count > 0) props.setValid(true);
    else props.setValid(false);
  };

  return (
    <div className="template-payout-structure p-5">
      <Row>
        <Col>
          <h2>{props.title}</h2>
        </Col>
      </Row>

      <div className="graySeparator" />
      {!props.isEditable && (
        <Row>
          <Col>
            <p className="info-text ">
              If you want to change the payout structure properites, please duplicate template and
              choose it from "self created" dropdown
            </p>
          </Col>
        </Row>
      )}

      {props.isEditable && (
        <>
          <Row className="pt-2 pb-2">
            <Col className="w-auto">
              <span className="mr-2">Payout template name </span>
              {props.isCreateMode && (
                <Input
                  type="text"
                  className="highlighted black-input d-inline"
                  value={payouts.name}
                  onChange={(e) => props.setupName(e.target.value)}
                />
              )}
              {!props.isCreateMode && (
                <Input
                  type="text"
                  className="highlighted black-input d-inline"
                  value={props.templateName}
                  defaultValue={props.originalName}
                  onChange={(e) => {
                    props.setupName(e.target.value);
                    props.updatePayouts(props.payouts, e.target.value);
                  }}
                />
              )}
            </Col>
          </Row>

          <div className="graySeparator" />

          <Row className="pt-2 pb-2">
            <Col className="w-auto">
              <span className="mr-2">Set NO. of ITM players</span>
              <Input
                type="number"
                className="highlighted black-input d-inline"
                onChange={(e) => setPositionsNumber(e.target.value)}
              />
              <button className="unstyled-button ml-1" onClick={setNumberOfITMPlayers}>
                Save
              </button>
            </Col>
          </Row>
          <Row className="pt-2 pb-5">
            <Col>
              <span className="mr-2">Assign same payout to multiple positions</span>
              <ToggleSwitch
                field="multiselect"
                switchValue={switchMultiSelection}
                isSwitchedOn={isMultiSelection}
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col xs={3} className="lightBox">
          <Row>
            <Col xs={6}>Position</Col>
            <Col xs={6}>Payout %</Col>
          </Row>
        </Col>
      </Row>
      <div className="payouts-table">
        {payouts.map((payout, index) => {
          return (
            <Row className="my-1 py-1">
              <Col xs={3} className="lightBox">
                <Row>
                  <Col xs={6}>{`${payout.position}.`}</Col>
                  <Col xs={6}>
                    <Input
                      type="number"
                      className="black-input"
                      id={`input_${index}`}
                      value={Math.round(payout.poolPrc * 100)}
                      onChange={(e) => onPayoutChange(payout, e.target.value / 100)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={1}>
                <Row>
                  {isMultiSelection ? (
                    <Col xs={1}>
                      <input
                        type="checkbox"
                        id={`multiselect_${index}`}
                        checked={payout.selected}
                        onChange={() => onSelectionChange(payout)}
                      />
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
          );
        })}
      </div>
      <Row
        className="allocated"
        style={{ visibility: payouts.length > 0 ? "visible" : "collapse" }}
      >
        <Col xs={3} className="blueBox">
          <Row>
            <Col xs={6} className="my-1 py-1">
              Allocated:
            </Col>
            <Col xs={6} className="my-1 py-1">
              {`${getAllocatedPercentage()} %`}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TemplatePayoutDetail;
