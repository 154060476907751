import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { Button } from "react-bootstrap";
import {
  changeTemplateObject,
  getTemplateById,
  updateTemplate,
  getTemplatePayoutStructureValues,
} from "../../actions/templateActions";
import { parseUrlNumericParameters } from "../../util/parseString";
import { history } from "../../service/history";
import TemplateType from "../../enum/TemplateType";
import TemplateStructureDetail from "./template/TemplateStructureDetail";
import TemplatePayoutDetail from "./template/TemplatePayoutDetail";
import { TournamentSettingComp } from "./EditTournamentComp";
import ConfirmationModal from "../modals/ConfirmationModal";

const TemplateDetail = (props) => {
  const dispatch = useDispatch();
  const match = props.match || { params: {} };
  const id = match.params.id;
  const groupId = match.params.groupid;

  const templateData = useSelector((state) => state.templates.template);
  const { name, content, type, createdAt } = templateData;

  const [templateEditName, setTemplateEditName] = useState(undefined);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isValid, setValid] = useState(true);

  const [clickedOnSave, setClickedOnSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (groupId === "undefined") {
      var intId = parseUrlNumericParameters(id);
      dispatch(getTemplateById(intId));
    } else {
      dispatch(getTemplatePayoutStructureValues(groupId, id));
    }
  }, [id]);

  useEffect(() => () => dispatch(changeTemplateObject({})), []);

  const getNumber = (n) => {
    return isNaN(n) ? 0 : parseFloat(n);
  };
  const isNot100 = (content) => {
    if (type !== TemplateType.TOURNAMENT_PAYOUT) return false;
    var sum = content
      .map((item) => item.poolPrc)
      .reduce((prev, next) => getNumber(prev) + getNumber(next));
    if (sum !== 1) {
      setShowInfoModal(true);
      return true;
    }
    return false;
  };
  const redirectToList = () => {
    let redirectUrl;
    switch (type) {
      case TemplateType.TOURNAMENT_DETAIL:
        redirectUrl = "/template/mtt";
        break;
      case TemplateType.TOURNAMENT_STRUCTURE:
        redirectUrl = "/template/structure";
        break;
      case TemplateType.TOURNAMENT_PAYOUT:
        redirectUrl = "/template/payout";
        break;
      default:
        redirectUrl = "/";
    }
    history.push(redirectUrl);
  };

  const saveAndExit = () => {
    setClickedOnSave(true);
    if (!templateData.name && (templateEditName === undefined || templateEditName === "")) {
      setErrorMessage("Template name has to be specified");
      setValid(false);
      return;
    }
    if (!isValid) {
      setErrorMessage("Please, fill all data correctly");
      return;
    }
    if (type !== TemplateType.TOURNAMENT_PAYOUT || !isNot100(templateData.content)) {
      const action = (dispatchObject) => {
        const process = updateTemplate(templateData);
        process(dispatchObject).then(() => {
          redirectToList();
        });
      };
      dispatch(action);
    }
  };
  return (
    <div id="templates">
      <Row className="body p-2">
        <Col>
          {type === TemplateType.TOURNAMENT_DETAIL && (
            <div id="editMttSetting">
              <TournamentSettingComp
                isTemplateMode
                tournament={content}
                updateTournament={(updateTournament, updatedVariant, editedName) => {
                  if (updatedVariant) {
                    (updateTournament.tournamentPhases || []).forEach((eachPhase) => {
                      eachPhase.variants = (eachPhase.variants || []).map((eachVariant) => {
                        if (eachVariant.id === updatedVariant.id) {
                          return { ...eachVariant, ...updatedVariant };
                        }
                        return { ...eachVariant };
                      });
                    });
                  }
                  dispatch(
                    changeTemplateObject({
                      ...templateData,
                      content: updateTournament,
                      name: editedName
                        ? editedName
                        : templateEditName === undefined
                        ? name
                        : templateEditName,
                    })
                  );
                }}
                templateName={templateEditName}
                setupName={setTemplateEditName}
                originalName={name}
              />
            </div>
          )}
          {type === TemplateType.TOURNAMENT_STRUCTURE && (
            <>
              <TemplateStructureDetail
                title={`Templates - Structure - ${name}`}
                templateName={templateEditName}
                setupName={setTemplateEditName}
                originalName={name}
                isTemplateMode
                template={content}
                onChange={(updatedTemplate, editedName, isValid = true) => {
                  setValid(isValid);
                  dispatch(
                    changeTemplateObject({
                      ...templateData,
                      content: updatedTemplate,
                      name: editedName
                        ? editedName
                        : templateEditName === undefined
                        ? name
                        : templateEditName,
                    })
                  );
                }}
              />
            </>
          )}
          {type === TemplateType.TOURNAMENT_PAYOUT && (
            <>
              <TemplatePayoutDetail
                isEditable={groupId === "undefined"}
                templateName={templateEditName}
                setupName={setTemplateEditName}
                originalName={name}
                title={`Templates - Payout - ${name}`}
                payouts={content}
                updatePayouts={(updatedTemplate, editedName) => {
                  dispatch(
                    changeTemplateObject({
                      ...templateData,
                      content: updatedTemplate,
                      name: editedName
                        ? editedName
                        : templateEditName === undefined
                        ? name
                        : templateEditName,
                    })
                  );
                }}
              />
            </>
          )}
        </Col>
      </Row>
      <Row className="text-danger error-msg">
        {clickedOnSave && !isValid && <p className="mr-20">{errorMessage}</p>}
      </Row>
      {createdAt !== undefined && (
        <>
          <Row className="offset-sm-2 p-2 footer">
            <Col sm={2} className="offset-sm-6 right-align">
              <Button
                color="primary"
                className="btn-dark outline my-auto fit"
                onClick={() => {
                  redirectToList();
                }}
              >
                Exit without changes
              </Button>
            </Col>
            <Col sm={2}>
              <Button color="secondary" onClick={saveAndExit}>
                Save and exit
              </Button>
            </Col>
          </Row>
        </>
      )}
      {showInfoModal && (
        <ConfirmationModal
          confirmationText={`Allocated payout must be equal 100%`}
          buttonText="Ok"
          open={true}
          close={() => setShowInfoModal(false)}
          onSubmitButtonClick={() => setShowInfoModal(false)}
        />
      )}
    </div>
  );
};

export default TemplateDetail;
