import React from "react";
import { getMainClockTime } from "../../../../util/tournamentUtil";

const TileMainTime = ({ variant, curLevel }) => {
  return (
    <div className="item">
      <div className="title">Time</div>
      <div className="text">{getMainClockTime(variant, curLevel)}</div>
    </div>
  );
};

export default TileMainTime;
