import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { reseatPlayer } from '../../../actions/playerActions';
import {getSeats} from "../../../util/playerUtil";

const ChooseSeatModal = ({
  variant, player, table, onChooseSeat, onCancel, isOpen,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {isOpen && (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={onCancel}>Choose seat</ModalHeader>
        <ModalBody>
          <Row>
            {getSeats(variant.tableSize, table.occupiedSeats).map((item, index) => (item ? (
              ''
            ) : (
              <Col className="col-12 py-2">
                <Row className="table-detail-main-title">
                  <Col className="col-1 my-auto">
                    <h3 style={{ marginBottom: 0 }}>{`0${index + 1}`.substr(`0${index + 1}`.length - 2)}</h3>
                    Seat
                  </Col>
                  <Col className="list-modal-table-edit-text my-auto">Free seat</Col>
                  <div className="my-auto list-modal-table-edit-newSeats">
                    <Button
                      onClick={() => {
                        dispatch(reseatPlayer(player.playerInTournamentPhaseVariantId, table.id, index + 1));
                        onChooseSeat(index);
                      }}
                    >
                      Sit in
                    </Button>
                  </div>
                </Row>
              </Col>
            )))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            Cancel tables
          </Button>
        </ModalFooter>
      </Modal>
      )}
    </>
  );
};

export default ChooseSeatModal;
