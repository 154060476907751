import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import TournamentHeader from "../../shared/TournamentHeader";
import Loader from "../../shared/Loader";
import { getReseatHistory, getReseatHistoryDetail, disconnectReseat } from '../../../actions/tournamentActions';
import { getDateTimeFormat } from '../../../util/parseString'
import { history } from "../../../service/history";

const ReseatDetail = (props) => {

  const dispatch = useDispatch();
  const tournament = useSelector((state) => state.tournaments.tournament);
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    dispatch((d) => {
      const getReseatHistoryAction = getReseatHistoryDetail(props.detailId);
      getReseatHistoryAction(d).then((response) => {
        setPlayers(response.data)
      })
    })
  }

  return (
    <div className="reseat-history">
      <Loader>
        <TournamentHeader tournament={tournament} />
        <h3>Reseat detail</h3>
        <div className="seating-table">
          <Row >
          </Row>
          {players.map((player, index) => {
            return (<>
              <Row className="graySeparator" />
              <Row className="player">
                <Col><><span className="mr-3">{index + 1}</span><span>{player.firstName + " " + player.lastName}</span><span className="gray-label ml-4">{player.variantName}</span></></Col>
                <Col>{player.stack}</Col>

              </Row></>)
          })}
        </div>


      </Loader>
    </div >
  );
}
export default ReseatDetail;
