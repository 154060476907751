import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socket from '../service/EvSocket';
import { cleanTournament, getTournament, notifyTournamentUpdate } from '../actions/tournamentActions';

const useTournament = (id) => {
  if (!id) {
    throw new Error('Id not provided');
  }
  const dispatch = useDispatch();
  const tournament = useSelector((state) => state.tournaments.tournament);

  useEffect(() => {
    socket.on('updateTournament', 'useTournament', (tournament) => {
      dispatch(notifyTournamentUpdate(tournament));
    });
    return () => {
      socket.removeHandler('updateTournamentVariant', 'useTournament');
    };
  }, []);

  useEffect(() => {
    dispatch(getTournament(id));
    return () => {
      dispatch(cleanTournament());
    };
  }, [id]);

  return tournament;
};

export default useTournament;
