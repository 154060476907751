import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FormGroup, Input } from "reactstrap";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Logout from "../logout/Logout";
import NextPageImage from "../../image/icon-adam.svg";
import currentUserUtil from "../../util/currentUser";
import CustomModal from "./CustomModal";
import socket from "../../service/EvSocket";
import { updatePassword, updatePasswordClean } from "../../actions/usersActions";
import { ReactComponent as IconView } from "../../image/icon-view.svg";
import { getPasswordValidator } from "../../util/stringUtil";

const ProfileModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();

  const [newPwdHasError, setNewPwdHasError] = useState(false);
  const [newPwdBtnEnabled, setNewPwdBtnEnabled] = useState(false);
  const [updatedPwdConfirmed, setUpdatedPwdConfirmed] = useState(false);
  const updatePwdStatus = useSelector((state) => state.users.updatePassword);
  const txtOldPassword = useRef(null);
  const txtNewPassword = useRef(null);

  useEffect(() => {
    dispatch(updatePasswordClean());
  }, []);

  if (updatedPwdConfirmed) {
    toggle();
  }

  function checkPassword() {
    const newPwd = txtNewPassword.current.value;
    const oldPwd = txtOldPassword.current.value;

    const pwdErrs = getPasswordValidator()
      .validate(txtNewPassword.current.value, { list: true });

    setNewPwdHasError(newPwd && pwdErrs.length > 0);
    setNewPwdBtnEnabled(newPwd && pwdErrs.length === 0 && oldPwd);

    dispatch(updatePasswordClean());
  }

  function saveNewPassword() {
    dispatch(
      updatePassword({
        identifier: currentUserUtil.getUserIdentifier(),
        oldPassword: txtOldPassword.current.value,
        newPassword: txtNewPassword.current.value,
      }),
    );
  }

  const ConfirmationModal = () => (
    <CustomModal open className="action-confirm-modal confirm-pwd" title="Password has been changed" dialogClassName="modal-small-width">
      <Button color="primary" className="confirm-btn" onClick={() => setUpdatedPwdConfirmed(true)}>
        Close
      </Button>
    </CustomModal>
  );

  if (updatePwdStatus.success) {
    return <ConfirmationModal/>;
  }

  function togglePassword(input) {
    if (input.current.type === "password") {
      input.current.type = "";
    } else {
      input.current.type = "password";
    }
  }

  return (
    <>
      {isOpen && (
        <CustomModal open={isOpen} id="toSomJa" closeHandler={toggle} className="profile-modal" dialogClassName="modal-width">
          <div className="content">
            <img src={NextPageImage} alt="person"/>
            <h3>{`${currentUserUtil.getUserAlias()}`}</h3>
            <Form.Label for="txtOldPassword" id="labOldPassword">
              Old password
            </Form.Label>
            <FormGroup className="input-with-icon right">
              <IconView onClick={() => togglePassword(txtOldPassword)}/>
              <Input type="password" id="txtOldPassword" innerRef={txtOldPassword} className="light" onChange={checkPassword}/>
            </FormGroup>
            {updatePwdStatus.error && updatePwdStatus.error.code === "INVALID_PASSWORD" && <small className="pwdError">Old password is wrong</small>}
            <Form.Label for="txtNewPassword" id="labNewPassword">
              New password
            </Form.Label>
            <FormGroup className="input-with-icon right">
              <IconView onClick={() => togglePassword(txtNewPassword)}/>
              <Input type="password" id="txtNewPassword" innerRef={txtNewPassword} className="light" onChange={checkPassword}/>
            </FormGroup>
            <small className="pwdError">{newPwdHasError ? "Passwrod length must be 6-20 and contains number" : ""}</small>
            <Button className="btn-save-pwd" disabled={!newPwdBtnEnabled} onClick={saveNewPassword}>
              Save new password
            </Button>
            {updatePwdStatus.error && updatePwdStatus.error.code !== "INVALID_PASSWORD" && <small className="pwdError">updatePwdStatus.error</small>}
            <Logout socket={socket} className="fit mt-5 outline"/>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default ProfileModal;
