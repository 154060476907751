import React from 'react';
import { Container } from 'react-bootstrap';
import GeneralLogin from '../components/login/GeneralLogin';

const LoginPage = () => (
  <Container>
    <GeneralLogin />
  </Container>
);
export default LoginPage;
