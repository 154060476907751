import React, {useEffect, useState} from "react";
import { Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import CustomModal from "../../shared/CustomModal";
import { formatPlayerName, formatSeatNum } from "../../../util/playerUtil";
import restClient from "../../../util/restClient";

const SwapSeatsModal = ({ player: inputPlayer, selectedTable, toggle, onAction, variant }) => {

  const [seatsData, setSeatsData] = useState([]);
  const seats = seatsData.data || []

  useEffect(() => {
      restClient.getAsyncWrap(`/tournament/table/${selectedTable.id}/seats`, seatsData, setSeatsData)
  }, [selectedTable.id])

  const activePlayers = useSelector((state) => state.player.playersInTournament.data || []).filter(
    (p) => p.tableId === selectedTable.id && p.seatNum
  );
  const EmptySeat = ({ seatNum , activeSeat }) => (
    <Col className="col-12 py-2">
      <Row className="player-row">
        <Col xs={2} md={2} className="list-modal-table-edit-newSeats">
          <h3 style={{ marginBottom: 0 }}>{formatSeatNum(seatNum)}</h3>
          <div className="new">Seat</div>
        </Col>
        <Col xs={6} md={5}></Col>
        <Col xs={4} md={4} className="my-auto list-modal-table-edit-newSeats">
          {!!activeSeat && <Button
            className="no-wrap"
            color="primary"
            onClick={() => onAction({ tableId: selectedTable.id, seatNum })}
          >
            Swap seat
          </Button>}
        </Col>
      </Row>
    </Col>
  );

  const PlayerCard = ({ player , activeSeat }) => (
    <Col className="col-12 py-2">
      <Row className="player-row">
        <Col xs={2} md={2} className="list-modal-table-edit-newSeats">
          <h3 style={{ marginBottom: 0 }}>{formatSeatNum(player.seatNum)}</h3>
          <div className="new">Seat</div>
        </Col>
        <Col xs={6} md={5}>
          {formatPlayerName(player)}
        </Col>
        <Col xs={4} md={4} className="my-auto list-modal-table-edit-newSeats">
          {!!activeSeat && player.id !== inputPlayer.id && (
            <Button className="no-wrap" color="primary" onClick={() => onAction(player)}>
              Swap seat
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );

  return (
    <>
      <CustomModal
        open
        closeHandler={toggle}
        className="swap-seats-modal"
        dialogClassName="modal-small-width"
        title="Swap seat"
      >
        <div className="players d-none d-md-block">
          {seats.map((seat, index) => {
            const playerOnSeat = activePlayers.find((eachPlayer) => eachPlayer.seatNum === seat.seatNum);
            let item;
            if (playerOnSeat) {
              item = <PlayerCard player={playerOnSeat} activeSeat={seat.active}/>;
            } else {
              item = <EmptySeat seatNum={seat.seatNum} activeSeat={seat.active}/>;
            }
            return (
              <>
                {item}
                {index !== seats.length - 1 && <Row className="graySeparator" />}
              </>
            );
          })}
        </div>
        <Row className="d-block d-md-none">
          {seats.map((seat,index) => {
            const playerOnSeat = activePlayers.find((eachPlayer) => eachPlayer.seatNum === seat.seatNum);
            let item;
            if (playerOnSeat) {
              item = <PlayerCard player={playerOnSeat} activeSeat={seat.active} />;
            } else {
              item = <EmptySeat seatNum={seat.seatNum} activeSeat={seat.active}/>;
            }
            return (
              <>
                {item}
                {index !== seats.length - 1 && <Row className="graySeparator" />}
              </>
            );
          })}
        </Row>
      </CustomModal>
    </>
  );
};

export default SwapSeatsModal;
