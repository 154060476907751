import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect, useSelector } from 'react-redux';

LoadingOverlay.propTypes = undefined

const Loader = (props) => {
  const loaderState = useSelector((state) => {
    const { loadingCount, actionIdList, isLoading } = state.common;
    return {
      loadingCount,
      actionIdList,
      isLoading,
    };
  });
  const isLoaderOn = props.actionId ? loaderState.actionIdList.indexOf(props.actionId) >= 0 : loaderState.isLoading;
  return (
    <LoadingOverlay classNamePrefix="loader_" active={isLoaderOn} className={`loader ${props.className} ${props.isLoading ? 'active' : ''}`} spinner text="Loading...">
      {props.children}
    </LoadingOverlay>
  );
};

export default Loader;
