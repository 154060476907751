import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTournamentStatistics, getTournamentStatisticsClean } from '../../actions/tournamentActions';
import socket from '../../service/EvSocket';

const useTournamentStats = (tournamentId) => {
  if (!tournamentId) {
    throw new Error('Tournament Id not provided');
  }

  const dispatch = useDispatch();
  const tournamentStats = useSelector((state) => state.tournaments.statistics) || {};

  const fetchStats = () => {
    dispatch(getTournamentStatistics(tournamentId));
  };

  useEffect(() => {
    socket.on('updateTournament', 'useTournamentStats', (payload) => {
      fetchStats();
    });
    socket.on('playersOnTableChange', 'useTournamentStats', (payload) => {
      fetchStats();
    });
    return () => {
      socket.removeHandler('updateTournament', 'useTournamentStats');
      socket.removeHandler('playersOnTableChange', 'useTournamentStats');
    };
  }, []);

  useEffect(() => {
    fetchStats();
    return () => {
      dispatch(getTournamentStatisticsClean());
    };
  }, [tournamentId]);

  return tournamentStats;
};

export default useTournamentStats;
