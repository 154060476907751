import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import TournamentHeader from "../../shared/TournamentHeader";
import Loader from "../../shared/Loader";
import { getReseatHistory, getReseatHistoryDetail, disconnectReseat } from '../../../actions/tournamentActions';
import { getDateTimeFormat } from '../../../util/parseString'
import { history } from "../../../service/history";
import ReseatBackConfirmModal from "./ReseatBackConfirmModal";
import { formatDateTime } from "../../../util/stringUtil";
import restClient from "../../../util/restClient";
import * as FileSaver from 'file-saver';

const ReseatHistory = (props) => {

  const dispatch = useDispatch();
  const tournament = useSelector((state) => state.tournaments.tournament);
  const [reseatHistory, setReseatHistory] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [selectedDay, setSelectedDay] = useState(null)

  useEffect(() => {
    dispatch((d) => {
      const getReseatHistoryAction = getReseatHistory(tournament.id);
      getReseatHistoryAction(d).then((response) => {
        setReseatHistory(response.data);
      })
    })
  }, []);

  const seeDetail = (id) => {
    dispatch((d) => {
      const getReseatHistoryAction = getReseatHistoryDetail(id);
      getReseatHistoryAction(d).then((response) => {
      })
    })
    history.push(`/tournament/${tournament.id}/newdayreseatHistoryDetail/${id}`);
  }
  const exportToExcel = async (e, id) => {
    e.preventDefault();

    const res = await restClient.getAsync(`/newday/detail/${id}?returnAsExcel=true`, true);
    const pdfBlob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
    FileSaver.saveAs(pdfBlob, "reseat.xlsx");
  }

  const disconnectReseatAction = (id) => {
    dispatch((d) => {
      const getReseatHistoryAction = disconnectReseat(id);
      getReseatHistoryAction(d).then((response) => {
        onConfirmDisconnectResetAction()
      })
    })
  }

  const onConfirmDisconnectResetAction = () => {
    setShowConfirmationModal(false)
    history.push(`/tournament/${tournament.id}/newdayreseat`);
  }

  return (
    <div className="reseat-history">
      <Loader>
        <TournamentHeader tournament={tournament} />
        <h3>Reseat for new day</h3>
        <Row className="graySeparator" />
        {reseatHistory.map(day => (
          <div className="history-row">
            <Row className="title">
              <Col sm={3}>TIME</Col>
              <Col sm={3}>FINISHED DAYS</Col>
              <Col sm={2}>CONNECTED TO</Col>
              <Col sm={2}></Col>
              <Col sm={2}></Col>
            </Row>
            <Row className="text">
              <Col sm={3}>{formatDateTime(day.createdAt)}</Col>
              <Col sm={3}>{day.sourceVariants.map(v => v.variantName).join(',')}</Col>
              <Col sm={2}>{day.destVariantName}</Col>
              <Col sm={1}><Button className="action" onClick={() => { seeDetail(day.id) }}>See reseat</Button></Col>
              <Col sm={2}><Button className="action" onClick={(e) => { exportToExcel(e, day.id) }}>Export to excel</Button></Col>
              <Col sm={1}><Button className="action" onClick={() => { setSelectedDay(day.id); setShowConfirmationModal(true) }}>Disconnect</Button></Col>
            </Row>
          </div>
        ))}
      </Loader>
      <ReseatBackConfirmModal open={showConfirmationModal} submit={() => { disconnectReseatAction(selectedDay) }} close={() => setShowConfirmationModal(false)} />
    </div >
  );
}
export default ReseatHistory;
