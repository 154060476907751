import React from 'react';
import { Table, Button } from 'reactstrap';

const TableBody = ({
  listOfRows, editAction, deleteAction, resetAction,
}) => (
  <tbody>
    {listOfRows.map((row, index) => (
      <tr key={row.id}>
        <td>{row.identifier}</td>
        <td>{row.alias}</td>
        <td>{row.type}</td>
        <td>
          <Button className="mr-1" onClick={(e) => editAction(e, row)}>
            Edit
          </Button>
          <Button className="mr-1" onClick={(e) => deleteAction(e, row)}>
            Delete
          </Button>
          <Button onClick={(e) => resetAction(e, row)}>Reset password</Button>
        </td>
      </tr>
    ))}
  </tbody>
);

const TableHeader = ({ listOfHeaders }) => (
  <thead>
    <tr>
      {listOfHeaders.map((header) => (
        <th key={header}>{header}</th>
      ))}
    </tr>
  </thead>
);
const UserTable = ({
  listOfHeaders, listOfRows, editAction, deleteAction, resetAction,
}) => (
  <Table dark>
    <TableHeader listOfHeaders={listOfHeaders} />
    <TableBody listOfRows={listOfRows} editAction={editAction} deleteAction={deleteAction} resetAction={resetAction} />
  </Table>
);

export default UserTable;
