import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Table } from "reactstrap";
import moment from "moment-mini";
import { getPlayerPayout } from "../../actions/playerActions";
import CustomModal from "../shared/CustomModal";
import useTournaments from "../shared/useTournaments";
import CircleIcon from "../functionalComponents/CircleIcon";
import PayoutModal from "./PayoutModal";
import Loader from "../shared/Loader";
import {
  getFlatVariantList,
  getRegistrationCloseAtLevel,
  getStructureByLevel,
  getTournamentTitle,
  getTournamentVariantTitle,
  resolveCurrentLevel,
  resolveRegistrationState,
  resolveVariantRegistrationState,
} from "../../util/tournamentUtil";
import StateVariantRegistration from "../../enum/StateVariantRegistration";
import { formatDate } from "../../util/stringUtil";

const PendingPayoutModal = (props) => {
  const dispatch = useDispatch();

  const tournaments = useTournaments("makepayout");
  const [modalPayout, setModalPayout] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState({});
  const variants = [];

  tournaments.forEach((eachTournament) => {
    getFlatVariantList(eachTournament).forEach((eachVariant) => {
      variants.push({
        tournamentId: eachTournament.id,
        tournamentName: getTournamentTitle(eachTournament),
        tournamentIsMultiday: eachTournament.isMultiday,
        tournament: eachTournament,
        ...eachVariant,
      });
    });
  });

  const togglePayout = (selectedTournament) => {
    setModalPayout(!modalPayout);
    setSelectedTournament(selectedTournament !== {} ? selectedTournament : {});
  };

  useEffect(() => {
    if ((selectedTournament || {}).id > 0 && modalPayout) {
      dispatch(getPlayerPayout(selectedTournament.id));
    }
  }, [(selectedTournament || {}).id, modalPayout]);

  return (
    <>
      {props.isOpen && (
        <CustomModal
          className={props.className}
          open={props.isOpen}
          closeHandler={props.toggle}
          title="Make payout"
          dialogClassName="modal-width"
          className="pending-payout-modal"
        >
          <Loader className="wv">
            <Table>
              <thead>
                <tr>
                  <th key="tournament">TOURNAMENT</th>
                  <th key="startOfTournament">START OF MTT</th>
                  <th key="endOfTournament">MTT FINISHED ON</th>
                  <th key="makepayout" />
                </tr>
              </thead>
              <tbody>
                {variants.map((variant, index) => {
                  const tournament = variant.tournament;

                  return (
                    <tr key={index}>
                      <td>
                        <CircleIcon color={tournament.color} />
                        <b>{getTournamentVariantTitle(tournament, variant)}</b>
                      </td>
                      <td>{formatDate(variant.startedAt)}</td>
                      <td>{formatDate(variant.finishedAt)}</td>
                      <td>
                        <Button
                          className="mtt-btn"
                          key={index}
                          variant="outline-primary"
                          onClick={() => {
                            togglePayout(tournament);
                          }}
                        >
                          Choose MTT
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Loader>
        </CustomModal>
      )}
      {modalPayout && (
        <PayoutModal isOpen={modalPayout} toggle={togglePayout} tournament={selectedTournament} />
      )}
    </>
  );
};

PendingPayoutModal.defaultProps = {
  isOpen: false,
};

export default PendingPayoutModal;
