import actionTypes from '../actiontypes/userActionTypes';
import restClient from '../util/restClient';
import compareUtil from '../util/compareUtil';
import { combinedRegisterLoaderAndShowError } from './commonActions';

const getUsersRequest = () => ({ type: actionTypes.USER_GET_REQUEST });

const getUsersSuccess = (data) => ({ type: actionTypes.USER_GET_RECEIVE, data });
const findUserSuccess = (data) => ({ type: actionTypes.FIND_USER_RECEIVE, data });

//  getUsersExceptVisitors only for admin users
export const getUsersExceptVisitors = () => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getUsersRequest());
    return restClient.get('/user/').then((response) => {
      dispatch(getUsersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getUsersExceptVisitors',
  'Can not resolve list of users',
);
export const getUsers = () => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getUsersRequest());
    return restClient.get('/user/').then((response) => {
      dispatch(getUsersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getUsers',
  'Can not resolve list of users',
);

export const getFilteredUsers = (userFilter) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getUsersRequest());
    return restClient.get(`/user?roles=[${userFilter}]`).then((response) => {
      dispatch(getUsersSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getFilteredUsers',
  'Can not resolve list of filtered users',
);

export const findUser = (user) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    getUsers().then((response) => {
      const fu = user ? response.data.find((each) => compareUtil(each.alias, user) || compareUtil(each.id.toString(), user)) : undefined;
      dispatch(findUserSuccess(fu));
      return Promise.resolve(fu);
    });
  },
  'findUser',
);

const addUserRequest = (data) => ({ type: actionTypes.USER_PUT_REQUEST });
const addUserSuccess = (data) => ({ type: actionTypes.USER_PUT_RECEIVE });

export const addUser = (data, isAdmin) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(addUserRequest());
    return restClient
      .put(
        '/user',
        {
          identifier: data.identifier,
          alias: data.alias,
          email: data.email,
          role: data.role,
          password: data.password,
        },
        dispatch,
      )
      .then((response) => {
        dispatch(addUserSuccess());
        if (isAdmin) {
          dispatch(getUsersExceptVisitors());
        }
        return Promise.resolve(response);
      });
  },
  'addUser',
);

const postUserRequest = (data) => ({ type: actionTypes.USER_POST_REQUEST });
const postUserSuccess = (data) => ({ type: actionTypes.USER_POST_RECEIVE });

// edit user only for admin users
export const postUser = (data) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(postUserRequest());
    return restClient
      .post(
        '/user',
        {
          identifier: data.identifier,
          newIdentifier: data.newIdentifier,
          alias: data.alias,
          email: data.email,
          role: data.role
        },
        dispatch,
      )
      .then((response) => {
        dispatch(postUserSuccess());
        dispatch(getUsersExceptVisitors());
        return Promise.resolve(response);
      });
  },
  'postUser',
);

const deleteUserRequest = () => ({ type: actionTypes.USER_DELETE_REQUEST });
const deleteUserSuccess = (data) => ({ type: actionTypes.USER_DELETE_RECEIVE });

export const deleteUser = (data) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(deleteUserRequest());
    return restClient
      .doDelete(
        '/user',
        {
          identifier: data.identifier,
        },
        dispatch,
        'User was not deactivated',
      )
      .then((response) => {
        dispatch(deleteUserSuccess());
        dispatch(getUsersExceptVisitors());
        return Promise.resolve(response);
      });
  },
  'deleteUser',
);

const resetPasswordRequest = (data) => ({ type: actionTypes.USER_RESET_PASSWORD_REQUEST });
const resetPasswordReceive = (data) => ({ type: actionTypes.USER_RESET_PASSWORD_RECEIVE });

export const resetPassword = (data) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(resetPasswordRequest());
    return restClient
      .post(
        '/user/passwordreset',
        {
          identifier: data.identifier,
          newPassword: data.password,
        },
        dispatch,
      )
      .then((response) => {
        dispatch(resetPasswordReceive());
        return Promise.resolve(response);
      });
  },
  'resetPassword',
);

const updatePasswordRequest = () => ({ type: actionTypes.USER_UPDATE_PASSWORD_REQUEST });
const updatePasswordReceive = () => ({ type: actionTypes.USER_UPDATE_PASSWORD_SUCCESS });
const updatePasswordError = (err) => ({ type: actionTypes.USER_UPDATE_PASSWORD_ERROR, err });

export const updatePassword = (data) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updatePasswordRequest());
    return restClient
      .post(
        '/user/updatepassword',
        {
          identifier: data.identifier,
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
        dispatch,
      )
      .then((response) => {
        dispatch(updatePasswordReceive());
        return Promise.resolve(response);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          dispatch(updatePasswordError(err.response.data));
        } else {
          dispatch(updatePasswordError(err));
        }

        return Promise.resolve(err);
      });
  },
  'updatepassword',
);

export const updatePasswordClean = () => (dispatch) => {
  dispatch({ type: actionTypes.USER_UPDATE_PASSWORD_CLEAN });
};
