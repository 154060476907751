export default {
  INSERT_TEMPLATE_REQUEST: 'INSERT_TEMPLATE_REQUEST',
  INSERT_TEMPLATE_RESPONSE: 'INSERT_TEMPLATE_RESPONSE',
  UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_RESPONSE: 'UPDATE_TEMPLATE_RESPONSE',
  GET_TEMPLATES_REQUEST: 'GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_RESPONSE: 'GET_TEMPLATES_RESPONSE',
  GET_PAYOUT_STRUCTURES_REQUEST:'GET_PAYOUT_STRUCTURES_REQUEST',
  GET_PAYOUT_STRUCTURES_RESPONSE: 'GET_PAYOUT_STRUCTURES_RESPONSE',
  GET_PAYOUT_STRUCTURE_VALUES_REQUEST:'GET_PAYOUT_STRUCTURE_VALUES_REQUEST',
  GET_PAYOUT_STRUCTURE_VALUES_RESPONSE:'GET_PAYOUT_STRUCTURE_VALUES_RESPONSE',
  GET_SINGLE_TEMPLATE_REQUEST: 'GET_SINGLE_TEMPLATE_REQUEST',
  GET_SINGLE_TEMPLATE_RESPONSE: 'GET_SINGLE_TEMPLATE_RESPONSE',
  CHANGE_SINGLE_TEMPLATE_RESPONSE: 'CHANGE_SINGLE_TEMPLATE_RESPONSE',
  DELETE_TEMPLATE_REQUEST: 'DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATES_RESPONSE: 'DELETE_TEMPLATES_RESPONSE',
};
