import globalHook from 'use-global-hook';
import restClient from '../util/restClient';
import React, {useEffect} from 'react';

const useGlobalDef = globalHook({initialized: false, config: {}}, {
    update: (store, newValue) => {
        store.setState({initialized: true, config: {...newValue}});
    }
});

const useConfig = () => {
    const [globalState, globalActions] = useGlobalDef();
    useEffect(() => {
        if (!globalState.initialized) {
            restClient.get('config')
                .then((response) => {
                    globalActions.update(response.data);
                });
        }
    }, []);
    return globalState;
}

export default useConfig;