import React, {useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Dropdown, Table } from "react-bootstrap";
import { Input, Label } from 'reactstrap';
import { Multiselect } from "multiselect-react-dropdown";
import {
  giveupStack
} from '../../../actions/playerActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort } from '@fortawesome/free-solid-svg-icons';

const TransferPlayers = (props) => {
  const initMultipleQualifPlayers = [];
  const txtSearchPlayer = useRef(0);
  const inputSelectAll = useRef(0);
  const dispatch = useDispatch();
  const [availableFromFlights, setAvailableFromFlights] = useState([]);
  const [availableToFlights, setAvailableToFlights] = useState([]);

  const [randomPlayersCount, setRandomPlayersCount] = useState();

  const [multipleQualifPlayers, setMultipleQualifPlayers] = useState(initMultipleQualifPlayers);

  const [nameSort, setNameSort] = useState(true);
  const [daySort, setDaySort] = useState(true);
  const [stackSort, setStackSort] = useState(true);

  useEffect(() => {
    var isPlayersSelected = props.playersForReseat.find(p => p.selected === true);
    var isToFligtSelected = props.selectedToFlight.value;

    if (isPlayersSelected && isToFligtSelected) {
      props.setIsTransferStepCompleted(true);
      return;
    }

    props.setIsTransferStepCompleted(false);
  }, [props.selectedToFlight, props.playersForReseat]);

  useEffect(() => {
    setMultipleQualifPlayers(initMultipleQualifPlayers);
    var dupl = getMultipleQualification();
    dupl.map(d => {
      setMultipleQualifPlayers((prevPlayers) => [
        ...prevPlayers,
        d
      ])
    })
    inputSelectAll.current.checked = false;
  }, [props.playersForNewDay.data.length, props.giveUpStackPlayers]);
  props.setAllMultipleQualifPlayersResolved(multipleQualifPlayers.length === 0)

  useEffect(() => {
    // temporary by default all players are selected
    if(props.playersForNewDay.data) {
      props.playersForNewDay.data.forEach(player => player.selected = true)
    }

    props.setPlayersForReseat(props.playersForNewDay.data);
    setRandomPlayersCount("");
  }, [props.playersForNewDay.data.length]);

  useEffect(() => {
    if (multipleQualifPlayers.length > 0)
      props.setPlayersForReseat(removeDuplicated(props.playersForNewDay.data));
  }, [multipleQualifPlayers])

  useEffect(() => {
    var selected = props.playersForReseat.filter(p => p.selected === true);
    props.setFinalPlayersForReseat(selected)
  }, [props.playersForReseat])

  useEffect(() => {
    if (props.tournament && props.tournament.tournamentPhases) {
      setAvailableFromFlights([]);
      setAvailableToFlights([]);
      props.tournament.tournamentPhases.map(phase => {
        phase.variants.map(variant => {
          if (variant.finishedAt !== null) {
            setAvailableFromFlights((prevFlights) => [
              ...prevFlights,
              {
                key: variant.name,
                value: variant
              }
            ]);
          }
          else if (variant.startedAt == null) {
            setAvailableToFlights((prevFlights) => [
              ...prevFlights,
              {
                key: variant.name,
                value: variant
              }
            ]);
          }
        })
      })

    }
  }, [props.tournament]);

  const onFromFlightsSelect = (action, allSelected, flight) => {
    if (action == "add") {
      props.setSelectedFromFlights(oldArray => [...oldArray, flight]);
    }
    else {
      props.setSelectedFromFlights(props.selectedFromFlights.filter((item) => (item.key !== flight.key)));
    }
  }

  const txtSearchPlayerChanged = () => {
    const searchString = txtSearchPlayer.current.value.trim().toLowerCase();
    var filtered = props.playersForReseat.filter((player) => {
      var containsInName = (player.firstName + player.lastName).toLowerCase().includes(searchString);
      if (containsInName)
        return player;
    })

    if (searchString === '')
      props.setPlayersForReseat(removeDuplicated(props.playersForNewDay.data));
    else
      props.setPlayersForReseat(filtered);
  };

  const getVariantName = (id) => {
    var flight = props.selectedFromFlights.find(flight => flight.value.id === id);
    if (flight)
      return flight.key;
  }


  const getMultipleQualification = () => {
    if (props.playersForNewDay.data.length === 0) return [];
    return props.playersForNewDay.data.filter(p =>
      p.playerGames.length > 0 && !props.giveUpStackPlayers.find(gp => gp.playerInTournamentId === p.playerInTournamentId));
  };

  const removeDuplicated = (playersForNewDayWithDuplicates) => {
    if (!playersForNewDayWithDuplicates)
      return;
    const distinctPlayers = playersForNewDayWithDuplicates.filter((player) => {
      const isMultipleQualification = multipleQualifPlayers.find(m => m.id === player.id) || props.giveUpStackPlayers.find(m => m.id === player.id)
      if (isMultipleQualification) {
        const mq = multipleQualifPlayers.filter(m => m.id === player.id)
        const gu = props.giveUpStackPlayers.filter(m => m.id === player.id)

        if (gu.length - mq.length === 1) {
          const playerInTournamentPhaseVariant = props.giveUpStackPlayers.find(m => m.playerInTournamentPhaseVariantId === player.playerInTournamentPhaseVariantId)
          if (playerInTournamentPhaseVariant) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }

      } else {
        return true
      }
    });

    return distinctPlayers;
  }

  const onGiveupStackClick = (player) => {
    props.giveUpStackForPlayer(player)
  }

  const onSortClick = (column) => {
    var sorted = [...props.playersForReseat];
    switch (column) {
      case "name":
        if (nameSort) {
          sorted.sort((a, b) =>
            (a.firstName + a.lastName).toLowerCase() < (b.firstName + b.lastName).toLowerCase() ? -1 : 1
          )
        }
        else {
          sorted.sort((a, b) =>
            (a.firstName + a.lastName).toLowerCase() > (b.firstName + b.lastName).toLowerCase() ? -1 : 1
          )
        }
        setNameSort(!nameSort)
        break;
      case "day":
        if (daySort) {
          sorted.sort((a, b) =>
            getVariantName(a.tournamentPhaseVariantId) < getVariantName(b.tournamentPhaseVariantId) ? -1 : 1
          );
        }
        else {
          sorted.sort((a, b) =>
            getVariantName(a.tournamentPhaseVariantId) > getVariantName(b.tournamentPhaseVariantId) ? -1 : 1
          );
        }
        setDaySort(!daySort);
        break;
      case "stack":
        if (stackSort) {
          sorted.sort((a, b) =>
            a.stack < b.stack ? -1 : 1
          );
        }
        else {
          sorted.sort((a, b) =>
            a.stack > b.stack ? -1 : 1
          );
        }
        setStackSort(!stackSort);
        break;
    }
    props.setPlayersForReseat(sorted);
  }

  const onCheckBoxClick = (player) => {
    var playersForReseatEdit = [...props.playersForReseat];
    playersForReseatEdit.map(p => {
      if (p.id === player.id)
        p.selected = !p.selected
    }
    )
    props.setPlayersForReseat(playersForReseatEdit)
  }

  const onAllCheckBoxClick = (e) => {
    var selected = [...props.playersForReseat];
    selected.map(pl => { pl.selected = e.target.checked });
    props.setPlayersForReseat(selected);
  }

  const selectRandomPlayers = (count) => {
    setRandomPlayersCount(count);
    if (count < 0) return;

    if (count === "") {
      props.setPlayersForReseat(removeDuplicated(props.playersForNewDay.data));
      return;
    }

    var random = [...removeDuplicated(props.playersForNewDay.data)];
    random = random.sort(() => Math.random() - Math.random()).slice(0, count);
    props.setPlayersForReseat(random);
  }

  return (
    <>
      <Row>
        <Col>
          <Label>From flights</Label>
          <Multiselect
            options={availableFromFlights}
            displayValue="key"
            showCheckbox={true}
            className="multiselect"
            onSelect={(val, item) => onFromFlightsSelect("add", val, item)}
            onRemove={(val, item) => onFromFlightsSelect("remove", val, item)}
            selectedValues={props.selectedFromFlights}
          />
        </Col>
        <Col>
          <Label>To flight</Label>
          <Dropdown className="to-flight-dd">
            <Dropdown.Toggle as="div" className="mtt-toggle form-control">
              {props.selectedToFlight.key ? props.selectedToFlight.key : ""}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {availableToFlights.map((p) => (
                <Dropdown.Item as="div" key={p.key} onClick={() => {
                  props.setSelectedToFlight(p);
                }}>
                  {p.key}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      {multipleQualifPlayers.length > 0 && <Row>
        <div className="mt-5">Multiple Qualification: (If multiselect ON)</div>
        <div id="transferPlayers">
          <Table className="tournament-table">
            <thead>
              <tr>
                <th key="player">PLAYER</th>
                <th key="from_day">DAY</th>
                <th key="stack">STACK</th>
                <th key="giveup_stack"></th>
              </tr>
            </thead>
            <tbody>
              {multipleQualifPlayers.map((player, index) => (
                <tr key={player.playerInTournamentPhaseVariantId}>
                  <td>
                    {player.firstName + " " + player.lastName}
                  </td>
                  <td>
                    {getVariantName(player.tournamentPhaseVariantId)}
                  </td>
                  <td>
                    {player.stack}
                  </td>
                  <td>
                    <button type="button" className="giveup-btn no-fill" onClick={() => onGiveupStackClick(player)}>Giveup stack
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Row>
      }
      <Row>
        <div id="transferPlayers">
          <Row className="float-right">
            {/*<Label for="randomPlayers" className="random-text">Select random number of players</Label>*/}
            {/*<Input className="random-input" type="number" name="randomPlayers" onChange={(e) => selectRandomPlayers(e.target.value)} value={randomPlayersCount}>Select ramndom players</Input>*/}

            <div className="search-player">
              <Input
                innerRef={txtSearchPlayer}
                id="txtSearchPlayer"
                placeholder="Search by name"
                onChange={txtSearchPlayerChanged}
              />
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </Row>
          <Table className="reseatForNewDay-table ">
            <thead>
              <tr>
                <th style={{display: "none"}} key="check"><Input type="checkbox" className="playerCheck" innerRef={inputSelectAll} onClick={(e) => onAllCheckBoxClick(e)}></Input></th>
                <th key="player">PLAYER <FontAwesomeIcon icon={faSort} className="sort-icon" onClick={() => onSortClick("name")} /></th>
                <th key="from_day">FROM DAY <FontAwesomeIcon icon={faSort} className="sort-icon" onClick={() => onSortClick("day")} /></th>
                <th key="stack">STACK <FontAwesomeIcon icon={faSort} className="sort-icon" onClick={() => onSortClick("stack")} /></th>
              </tr>
            </thead>
            <tbody>
              {props.playersForReseat.map((player) => (
                <tr>
                  <td style={{display: "none"}} >
                    <Input type="checkbox" className="playerCheck" checked={player ? player.selected : false}  onClick={() => onCheckBoxClick(player)}></Input>
                  </td>
                  <td>
                    {player.firstName + " " + player.lastName}
                  </td>
                  <td>
                    {getVariantName(player.tournamentPhaseVariantId)}
                  </td>
                  <td>
                    {player.stack}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {props.selectedToFlight?.key && (
            <>
               <h2>Players registered directly to {props.selectedToFlight.key}</h2>
              <Table className="reseatForNewDay-table ">
                <thead>
                <tr>
                  <th style={{display: "none"}} key="check"><Input type="checkbox" className="playerCheck" innerRef={inputSelectAll} onClick={(e) => onAllCheckBoxClick(e)}></Input></th>
                  <th key="player">PLAYER <FontAwesomeIcon icon={faSort} className="sort-icon" onClick={() => onSortClick("name")} /></th>
                  <th key="from_day">FROM DAY <FontAwesomeIcon icon={faSort} className="sort-icon" onClick={() => onSortClick("day")} /></th>
                  <th key="stack">STACK <FontAwesomeIcon icon={faSort} className="sort-icon" onClick={() => onSortClick("stack")} /></th>
                </tr>
                </thead>
                <tbody>
                {(props.playersOnFlightTo || []).map((player) => (
                    <tr>
                      <td>
                        {player.firstName + " " + player.lastName}
                      </td>
                      <td>
                        {getVariantName(player.tournamentPhaseVariantId)}
                      </td>
                      <td>
                        {player.stack}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </>
            )}
        </div>
      </Row>
    </>
  );
};

export default TransferPlayers;
