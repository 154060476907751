import React from "react";
import {connect} from "react-redux";
import {Tabs} from "react-bootstrap";
import moment from "moment-mini";
import InfoCard from "../../shared/InfoCard";
import CustomTab from "../../shared/Tabs";
import {history} from "../../../service/history";
import {getTournamentTitle, isMultiday} from "../../../util/tournamentUtil";

const InfoCardsComp = (props) => {
    return <>
        {(props.infoCards || []).map((card, index) => (
            <div key={index} className="mtt-card">
                <InfoCard itemsPerCol={3} title={card.title} list={card.list}/>
            </div>
        ))}
    </>
}

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.activeTab || "0"
        };
    }

    handleSelect = selectedTab => {
        this.setState({
            activeTab: selectedTab
        });
    };

    render() {
        if (this.props.tournament && this.props.tournament.tournamentPhases) {
            const MainInformation = {
                title: "Main Information",
                list: [
                    {title: "MTT name", value: getTournamentTitle(this.props.tournament)},
                    {title: "MTT color", value: this.props.tournament.color, color: this.props.tournament.color},
                    {title: "Currency", value: this.props.tournament.currency}
                ]
            };

            const eventNo = this.props.tournament.eventNum !== undefined ? this.props.tournament.eventNum : 'none';
            const EvenNo = {
                title: "Event No.",
                list: [{title: "Event No.", value: eventNo}]
            };

            const garanteedPool = this.props.tournament.guaranteedPool !== undefined ? this.props.tournament.guaranteedPool : 'none';
            const GuaranteedPool = {
                title: "Guaranted Pool",
                list: [{title: "Guaranteed Pool", value: garanteedPool}]
            };

            const MaxNoOfPlayers = {
                title: "Max No. of players at the table",
                list: [
                    {
                        title: "Max No. of players",
                        value: this.props.tournament.tournamentPhases[0].variants[0].tableSize
                    }
                ]
            };

            const UseDealerDevices = {
                title: "Do you use dealer device during MTT",
                list: [
                    {title: "Do you use dealer devices during MTT ?", value: "XXXXXX"} // todo: missing data
                ]
            };

            const phases = [];

            for (const phase of this.props.tournament.tournamentPhases) {
                for (const variant of phase.variants) {

                    const tempPhase = {};
                    tempPhase.infoCards = [];
                    tempPhase.name = variant.name;

                    // time settings
                    const timeSettingsList = [
                        {
                            title: "Registration starts at",
                            value: moment(variant.registrationStartAt).format("DD.MM.YYYY HH:mm")
                        },
                        {
                            title: "MTT starts at",
                            value: moment(variant.scheduledStartAt).format("DD.MM.YYYY HH:mm")
                        },
                        {title: "Registration ends after this level", value: variant.registrationCloseAtLevel}
                    ];

                    if (isMultiday(this.props.tournament)) {
                        timeSettingsList.push({title: "Day starts at level", value: variant.startingStructureLevel});
                    }
                    tempPhase.infoCards.push({
                        title: "MTT starts at",
                        list: timeSettingsList
                    });

                    // buy-in
                    tempPhase.infoCards.push({
                        title: "Buy-in",
                        list: [
                            {title: "Buyin", value: variant.buyInPool},
                            {title: "Buyin fee", value: variant.buyInFee},
                            {title: "Buyin chips", value: variant.buyInChips}
                        ]
                    });

                    // re-entry
                    if (variant.reEntry) {
                        tempPhase.infoCards.push({
                            title: "Re-entry",
                            list: [
                                {title: "Re-entry", value: variant.reEntryPool},
                                {title: "Re-entry fee", value: variant.reEntryFee},
                                {title: "Re-entry chips", value: variant.reEntryChips},
                                {title: "Max per player", value: variant.reEntryMaxPerPlayer}
                            ]
                        });
                    }

                    // re-buy
                    if (variant.reBuy) {
                        tempPhase.infoCards.push({
                            title: "Rebuy",
                            list: [
                                {title: "Rebuy", value: variant.reBuyPool},
                                {title: "Rebuy fee", value: variant.reBuyFee},
                                {title: "Rebuy chips", value: variant.reBuyChips},
                                {title: "Max per player", value: variant.reBuyMaxPerPlayer},
                                {title: "Rebuy ends after this level", value: variant.reBuyEndAtLevel}
                            ]
                        });
                    }

                    // add-on
                    if (variant.addon) {
                        tempPhase.infoCards.push({
                            title: "Add-on",
                            list: [
                                {title: "Add-on", value: variant.addonPool},
                                {title: "Add-on fee", value: variant.addonFee},
                                {title: "Add-on chips", value: variant.addonChips},
                                {title: "Max per player", value: variant.addonMaxPerPlayer},
                                {title: "Add-on ends after this level", value: variant.addonEndAtLevel}
                            ]
                        });
                    }

                    // bonus chips
                    if (variant.bonus) {
                        tempPhase.infoCards.push({
                            title: "Bonus chips",
                            list: [
                                {title: "Bonus chips", value: variant.bonusChips},
                                {title: "Max per player", value: variant.bonusMaxPerPlayer} // todo: missing data
                            ]
                        });
                    }

                    phases.push(tempPhase);
                }
            }

            return (
                <div className="mtt-overview">
                    <div className="mtt-card">
                        <InfoCard title={MainInformation.title} list={MainInformation.list}/>
                    </div>

                    <div className="mtt-card">
                        <InfoCard title={EvenNo.title} list={EvenNo.list}/>
                        <InfoCard title={GuaranteedPool.title} list={GuaranteedPool.list}/>
                    </div>

                    <div className="mtt-card">
                        <InfoCard title={MaxNoOfPlayers.title} list={MaxNoOfPlayers.list}/>
                        {false /*not implemented yet*/ &&
                            <InfoCard title={UseDealerDevices.title} list={UseDealerDevices.list}/>}
                    </div>

                    {(phases || []).length > 1 ?
                        <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect} className="mtt-tabs">
                            {phases.map((item, index) => (
                                <CustomTab key={index} eventKey={index} title={item.name}>
                                    <InfoCardsComp infoCards={item.infoCards}/>
                                </CustomTab>
                            ))}
                        </Tabs> : <InfoCardsComp infoCards={phases[0].infoCards}/>}
                </div>
            );
        }
        history.push("/mtt");
        return <div>Redirect to home page...</div>;
    }
}

const mapStateToProps = state => {
    return {tournament: state.tournaments.tournamentToSave};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
