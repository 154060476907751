import { Button } from "react-bootstrap";
import { changeLevel, changeVariantState, closeVariant } from "../../../actions/tournamentActions";
import moment from "moment-mini";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FinishConfirmationModal from "./FinishConfirmationModal";

const ClockInNewTab = (props) => {
  const { variant, tournament, showBgPreview } = props;

  return (
    <Button
      color="primary"
      disabled={!(tournament.id >= 0 && variant.id >= 0)}
      className="fit d-none d-md-block"
      onClick={() => {
        window.open(
          `/clock/tournament/${tournament.id}/variant/${variant.id}?showBgPreview=${showBgPreview}`,
          "_blank"
        );
      }}
    >
      Open clock
    </Button>
  );
};

export default ClockInNewTab;
