import React from 'react';
import ExpansibleSpaceGrid from './ExpansibleSpaceGrid';
import SpaceGrid from './SpaceGrid';

const components = {
  expansibleSpaceGrid: ExpansibleSpaceGrid,
  spaceGrid: SpaceGrid,
};

function GenericSpaceGrid(props) {
  const SpaceGridType = components[props.spaceGridType];
  return <SpaceGridType {...props} />;
}

GenericSpaceGrid.defaultProps = {
  SpaceGridType: 'spaceGrid',
};

export default GenericSpaceGrid;
