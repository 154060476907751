import React, {useCallback, useEffect, useState} from "react";
import {Row, Col, Dropdown} from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import restClient from "../../../util/restClient";
import {Button} from "reactstrap";
import {addExtraTime} from "../../../actions/tournamentActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {faCheck} from "@fortawesome/free-solid-svg-icons/index";
import ToggleSwitch from "../../functionalComponents/ToggleSwitch";
import Currency from "../../../enum/Currency";
import Nationality from "../../../enum/Nationality";

const CasinoSettingsItem = ({item}) => {
    const {key, desc } = item
    const [value, setValue ] = useState(item.value)
    const [newValue, setNewValue] = useState(value)

    const isBooleanSetting = ['playerRegistrationBirthDate', 'playerRegistrationEmail', 'playerRegistrationIdNum', 'playerRegistrationPaymentMethod'].includes(key)
    const isComboSettings = ['tournamentDefaultCurrency', 'playerRegistrationNationalityDefault'].includes(key)
    const isFreeTextSettings = !(isBooleanSetting || isComboSettings)

    let comboSettingsItems
    if(isComboSettings) {
        if('tournamentDefaultCurrency' === key) {
            comboSettingsItems = Currency
        }
        if('playerRegistrationNationalityDefault' === key) {
            comboSettingsItems = Nationality
        }
    }

    async function saveNewValue() {
        await restClient.putAsync(`/config/update/${key}`, {value: newValue})
        setValue(newValue)
    }

    async function resetValue() {
        setNewValue(value)
    }

    return <Row key={key} className="item">
        <Col xs={3}>{desc}</Col>
        <Col xs={7} className="item-value">
            {isBooleanSetting &&
                <ToggleSwitch switchValue={() => {
                    setNewValue(newValue === "true" ? "false" : "true")
                }} isSwitchedOn={newValue === "true" ? true : false}/>
            }
            {isFreeTextSettings &&
                <FormControl
                    id="filter"
                    placeholder={value}
                    className="input-part"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                        setNewValue(e.target.value)
                    }}
                    name={key}
                    value={newValue}
                />
            }
            {isComboSettings &&
                <Dropdown>
                    <Dropdown.Toggle as="div" className="mtt-toggle form-control">
                        {comboSettingsItems[newValue]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Object.keys(comboSettingsItems).map((item) => (
                            <Dropdown.Item
                                as="div"
                                key={item}
                                onClick={() =>
                                   setNewValue(item)
                                }
                            >
                                {comboSettingsItems[item]}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            }
        </Col>
        <Col xs={2}>
            {value !== newValue && <>
                <Button className={`icon green`} onClick={saveNewValue}>
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
                <Button className={`icon red`} onClick={resetValue}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </>}
        </Col>
    </Row>


}

export default CasinoSettingsItem;
