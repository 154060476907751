import React from "react";

const TileBlinds = ({variant, curLevel, className, blindsInOneLine = false}) => {

    const notStartedYet = variant.state === "created"

    if (notStartedYet) {
        return (
            <div className={`item ${className}`}>
                <div className="title msg">NOT STARTED YET</div>
            </div>
        )
    }

    const isBreak = curLevel.type === "break";
    if (isBreak) {
        const isColorUp = curLevel.colorUp;
        const breakClass = isColorUp ? "title" : "text";
        return (
            <div className={`item ${className}`}>
                <div className={`${breakClass} msg`}>BREAK</div>
                {isColorUp ? <div className="text colorup">COLOR UP</div> : null}
            </div>
        )
    }

    const blindsValue = `${curLevel.smallBlind !== undefined ? curLevel.smallBlind : " "} / ${curLevel.bigBlind !== undefined ? curLevel.bigBlind : " "}`;
    let blindsTitle = 'Blinds: ';
    if (blindsInOneLine) {
        blindsTitle = `${blindsTitle} ${blindsValue}`
    }
    const showAnte = curLevel.ante > 0

    return (
        <div className={`item ${className}`}>
            <div className="title">{blindsTitle}</div>
            <div className="text">
                {blindsInOneLine !== true &&
                    <>
                        <p className="mb-0">
                            {blindsValue}
                        </p>
                        {showAnte &&
                            <p>
                                Ante: {curLevel.ante}
                            </p>
                        }
                    </>
                }
            </div>
            {blindsInOneLine == true && showAnte &&
                <div className="title">
                    Ante: {curLevel.ante}
                </div>
            }
        </div>
    )

}

export default TileBlinds;