import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { Button } from "react-bootstrap";
import { changeTemplateObject, insertTemplate } from "../../actions/templateActions";
import { history } from "../../service/history";
import TemplateType from "../../enum/TemplateType";
import TemplateStructureDetail from "./template/TemplateStructureDetail";
import TemplatePayoutDetail from "./template/TemplatePayoutDetail";

const TemplateCreate = (props) => {
  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState("");
  const [isValid, setValid] = useState(true);
  const [clickedOnSave, setClickedOnSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const match = props.match || { params: {} };
  const { type } = match.params;

  const templateData = useSelector((state) => state.templates.template);
  const template = templateData.content;

  useEffect(() => () => dispatch(changeTemplateObject({})), []);

  const saveAndExit = () => {
    setClickedOnSave(true);
    if (templateName === "") {
      setErrorMessage("Template name has to be specified");
      setValid(false);
      return;
    }
    if (!isValid) {
      setErrorMessage("Please, fill all data correctly");
      return;
    }

    const action = (dispatchObject) => {
      const process = insertTemplate(type, templateName, template);
      process(dispatchObject).then(() => {
        redirectToList();
      });
    };
    dispatch(action);
  };

  const redirectToList = () => {
    let redirectUrl;
    switch (type) {
      case TemplateType.TOURNAMENT_DETAIL:
        redirectUrl = "/template/mtt";
        break;
      case TemplateType.TOURNAMENT_STRUCTURE:
        redirectUrl = "/template/structure";
        break;
      case TemplateType.TOURNAMENT_PAYOUT:
        redirectUrl = "/template/payout";
        break;
      default:
        redirectUrl = "/";
    }
    history.push(redirectUrl);
  };
  return (
    <div id="templates">
      {type === TemplateType.TOURNAMENT_STRUCTURE && (
        <>
          <TemplateStructureDetail
            isTemplateMode
            title="Create Structure template"
            template={template}
            setupName={setTemplateName}
            onChange={(updatedTemplate, editedName, isValid = true) => {
              setValid(isValid);
              dispatch(changeTemplateObject({ ...templateData, content: updatedTemplate }));
            }}
          />
        </>
      )}
      {type === TemplateType.TOURNAMENT_PAYOUT && (
        <TemplatePayoutDetail
          setValid={setValid}
          isEditable={true}
          isCreateMode={true}
          setupName={setTemplateName}
          title="Create Payout structure template"
          payouts={template}
          updatePayouts={(updatedTemplate) => {
            dispatch(
              changeTemplateObject({
                ...templateData,
                content: updatedTemplate || {},
              })
            );
          }}
        />
      )}

      <Row className="text-danger error-msg">
        {clickedOnSave && !isValid && <p className="mr-20">{errorMessage}</p>}
      </Row>
      <Row className="offset-sm-2 p-2 footer">
        <Col sm={2} className="offset-sm-6 right-align">
          <Button
            color="primary"
            className="btn-dark outline my-auto fit"
            onClick={() => {
              redirectToList();
            }}
          >
            Exit without changes
          </Button>
        </Col>
        <Col sm={2}>
          <Button color="secondary" onClick={saveAndExit} focus>
            Save and exit
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TemplateCreate;
