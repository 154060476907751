import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import ReseatIcon from '../../../image/icon-reseat.svg';
import ReseatPlayerTablesModal from '../../tournament/floor/ReseatPlayerTablesModal';
import { reseatPlayer } from '../../../actions/playerActions';

const ReseatButton = ({
  variant, player, toggleParent, onAction,
}) => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  function toggleModal() {
    setOpenModal(!openModal);

    if (toggleParent) {
      toggleParent();
    }
  }

  function setNewSeat(table, seat) {
    dispatch(reseatPlayer(player.playerInTournamentPhaseVariantId, table.id, seat));
    if (onAction) {
      onAction();
    }
  }

  return (
    <>
      <div className="action">
        <Button color="primary" className="action-btn" onClick={toggleModal}>
          <img src={ReseatIcon} className="action-icon" alt="reseat" />
        </Button>
        <a>Reseat</a>
      </div>
      {openModal && <ReseatPlayerTablesModal isOpen={openModal} toggle={toggleModal} variant={variant} selectedPlayer={player} setNewSeat={setNewSeat} onAction={onAction} />}
    </>
  );
};

export default ReseatButton;
