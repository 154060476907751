import React, { useEffect, useState } from "react";
import "./Style/VariantPicker.scss";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getFlatVariantList } from "../../util/tournamentUtil";
import FinishDay from "../../image/icon-finish-day.svg";
import PresentDay from "../../image/icon-present-day.svg";
import NextDay from "../../image/icon-next-day.svg";
import TournamentHeader from "./TournamentHeader";
import Loader from "./Loader";
import useVariant from "../useVariant";
import { Col } from "reactstrap";

const Icon = (props) => {
  switch (props.state) {
    case "finished":
      return <img alt="Lock" src={FinishDay} className="variant-picker-icon" />;
    case "active":
      return <img alt="Lock" src={PresentDay} className="variant-picker-icon" />;
    case "created":
      return <img alt="Lock" src={NextDay} className="variant-picker-icon" />;
    default:
      return "";
  }
};

const Switch = (props) => {
  const { tournament } = props;
  const { variants } = props;
  let tmpVariantId = sessionStorage.getItem("variantId");
  if (!tmpVariantId && variants[0]) {
    sessionStorage.setItem("variantId", variants[0].id);
    tmpVariantId = variants[0].id;
  } else {
    tmpVariantId = parseInt(tmpVariantId);
  }
  const [curVariantId, setCurVariantId] = useState(tmpVariantId);
  const variant = props.isEditMode
    ? variants.find((v) => v.id === curVariantId)
    : useVariant(curVariantId, props.clientId);
  const setVariant = (variantId) => {
    setCurVariantId(variantId);
    sessionStorage.setItem("variantId", variantId);
  };

  variants.sort(function (a, b) {
    return a.id - b.id;
  });

  return (
    <>
      <div className="d-none d-md-block">
        <div className={`variant-picker ${props.className}`}>
          <Row className="variants">
            {variants.map((each) => (
              <div className="variant-picker-col">
                <button
                  type="button"
                  className={
                    curVariantId === each.id ? "variant-picker-btn-selected" : "variant-picker-btn"
                  }
                  onClick={() => {
                    setVariant(each.id);
                  }}
                >
                  <Icon {...each} />
                  <span className="variant-picker-label">{each.name}</span>
                </button>
              </div>
            ))}
          </Row>
          {!props.hideHeader && (
            <TournamentHeader tournament={props.tournament} variant={variant} />
          )}
        </div>
      </div>
      <div className="d-block d-md-none">
        <div className={`variant-picker ${props.className}`}>
          <div className="">
            <div className="flex overflow-auto">
              {variants.map((each) => (
                <div className="variant-picker-col">
                  <button
                    type="button"
                    className={
                      curVariantId === each.id
                        ? "variant-picker-btn-selected"
                        : "variant-picker-btn"
                    }
                    onClick={() => {
                      setVariant(each.id);
                    }}
                  >
                    <Icon {...each} />
                    <span className="variant-picker-label">{each.name}</span>
                  </button>
                </div>
              ))}
            </div>
          </div>
          {!props.hideHeader && (
            <TournamentHeader tournament={props.tournament} variant={variant} />
          )}
        </div>
      </div>
      <Loader actionId="getVariant" className={props.contentClassName}>
        {props.getContent(variant)}
      </Loader>
    </>
  );
};

const SingleVariant = (props) => {
  const variant = useVariant(props.variant.id, props.clientId);
  variant.tournament = { ...props.tournament };
  sessionStorage.setItem("variantId", props.variant.id);
  const displayHeaderForSingleVariantTournament =
    props.displayHeaderForSingleVariantTournament !== undefined
      ? props.displayHeaderForSingleVariantTournament
      : true;
  return (
    <>
      {displayHeaderForSingleVariantTournament && (
        <div className={`variant-picker single ${props.className || ""}`}>
          <TournamentHeader tournament={props.tournament} id={props.clientId} />
        </div>
      )}
      <Loader actionId="getVariant" className={props.contentClassName}>
        {props.getContent(variant)}
      </Loader>
    </>
  );
};

const VariantPicker = (props) => {
  const [variants, updateVariants] = useState([]);
  const [variant, updateVariant] = useState({});
  useEffect(() => {
    let variants = getFlatVariantList(props.tournament);
    updateVariants(variants);
    if (variants.length === 1) {
      updateVariant(variants[0]);
    }
  }, [(props.tournament || {}).id]);

  const loaderState = useSelector((state) => state.common.isLoading);

  if (variants.length === 0) {
    if (loaderState) {
      return <Loader />;
    }
    return <div>No data found!</div>;
  }
  if (variants.length === 1) {
    return (
      <SingleVariant
        tournament={props.tournament}
        variant={variant}
        getContent={props.getContent}
        clientId={props.id}
        contentClassName={props.contentClassName}
      />
    );
  }
  return (
    <Switch
      tournament={props.tournament}
      variants={props.isEditMode ? getFlatVariantList(props.tournament) : variants}
      getContent={props.getContent}
      {...props}
      clientId={props.id}
    />
  );
};

export default VariantPicker;
