export const ERROR_REPORT = 'ERROR_REPORT';
export const ERROR_CLEAR = 'ERROR_CLEAR';
export const IS_LOADING = 'IS_LOADING';
export const IS_LOADING_DONE = 'IS_LOADING_DONE';

export const LOGIN_REQ = 'LOGIN_REQ';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AUTH_TOKEN_PROLONG_REQ = 'AUTH_TOKEN_PROLONG_REQ';
export const AUTH_TOKEN_PROLONG_FAILURE = 'AUTH_TOKEN_PROLONG_FAILURE';
export const AUTH_TOKEN_PROLONG_SUCCESS = 'AUTH_TOKEN_PROLONG_SUCCESS';
export const LOGOUT_REQ = 'LOGOUT_REQ';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UNSAVED_CHANGES = 'UNSAVED_CHANGES'
export const UNSAVED_CHANGES_CLEAR = 'UNSAVED_CHANGES_CLEAR';
export const UNSAVED_CHANGES_SHOW = 'UNSAVED_CHANGES_SHOW';
export const UNSAVED_CHANGES_HIDE = 'UNSAVED_CHANGES_HIDE';

// socket
export const SOCKET_CONNECTION_CHANGE = 'SOCKET_CONNECTION_CHANGE';
