import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row } from "react-bootstrap";
import { Button } from "reactstrap";
import CustomModal from "../../shared/CustomModal";
import { formatPlayerName, formatSeatNum } from "../../../util/playerUtil";
import { updateGameTable } from "../../../actions/tablesActions";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { getVariant } from "../../../actions/tournamentActions";
import { history } from "../../../service/history";
import { loading, loadingDone } from "../../../actions/commonActions";

const TableDetailClosedModal = (props) => {
  const dispatch = useDispatch();

  const { table } = props;
  const closedWithCheckChipCounts = !!table.closedTableChipCounts;
  const players = closedWithCheckChipCounts ? JSON.parse(table.closedTableChipCounts) : (table.closedTableOriginalSeating || []);

  const [toggleCloseTable, setToggleCloseTable] = useState(false);

  function reopenTable() {
    const variantId = props.variant.id;
    history.push(`/tournament/${props.variant.tournamentId}`);
    dispatch(loading("getVariant"));
    dispatch(updateGameTable(variantId, [{
      id: table.id,
      state: "open",
    }]))
      .then(() => {
        dispatch(loadingDone("getVariant"));
        props.toggle();
      });
  }

  const PlayerComp = ({ player }) => (
    <div className="player-row">
      <div className="col1">
        <h3 style={{ marginBottom: 0 }}>
          {formatSeatNum(player.seatNumOld || player.seatNum)}
        </h3>
        <span>Seat</span>
      </div>

      <span className="col2">
        {formatPlayerName(player)}
      </span>

      <div className="col3">
        <h3 style={{ marginBottom: 0 }}>
          {closedWithCheckChipCounts ? player.stack : `${player.tableName}/${player.seatNum}`}
        </h3>
        <span>{closedWithCheckChipCounts ? "Chips" : "New seat"}</span>
      </div>
    </div>
  );

  return (
    <>
      <CustomModal
        title={`Table ${table.tableName}, Closed`}
        open
        closeHandler={props.toggle}
        id="closed-table-detail-modal"
        className="closed-table-detail-modal dark"
        dialogClassName="modal-width"
      >
        <div className="players">
          {players.map((p, index) => (
            <>
              <PlayerComp player={p}/>
              {index !== players.length - 1 && <Row className="graySeparator"/>}
            </>
          ))}
        </div>

        <Button
          color="primary"
          className="fit"
          onClick={() => {
            setToggleCloseTable(true);
          }}
        >
          Reopen
        </Button>
      </CustomModal>
      {toggleCloseTable && (
        <ConfirmationModal
          confirmationText="Are you sure you want to reopen table?"
          buttonText="Ok"
          open={toggleCloseTable}
          close={() => setToggleCloseTable(false)}
          onSubmitButtonClick={() => {
            reopenTable();
          }}
        />
      )}
    </>
  );
};

export default TableDetailClosedModal;
