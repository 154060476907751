import React, { Fragment } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { history } from '../service/history';
import Header from '../components/shared/HeaderBoot';
import ManageUsers from '../components/user/ManageUsers';
import currentUserUtil from '../util/currentUser';

const redirect = (path) => {
  history.push(path);
};

const AdminPage = () => (
  <>
    <Header
      title="EV MTT"
      roleType="Admin:"
      roleId={currentUserUtil.getUserIdentifier()}
      showSocketIcon={false}
      children={(
        <>
          <NavItem>
            <NavLink id="nlUserManagement" className="border-right" href="#" onClick={() => redirect('/')}>
              User management
            </NavLink>
          </NavItem>
        </>
      )}
    />
    <Router history={history}>
      <Switch>
        <Route render={() => <ManageUsers />} />
      </Switch>
    </Router>
  </>
);

export default AdminPage;
