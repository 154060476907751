export default {
  ADD_TOURNAMENT_REQUEST: 'ADD_TOURNAMENT_REQUEST',
  ADD_TOURNAMENT_RESPONSE: 'ADD_TOURNAMENT_RESPONSE',

  UPDATE_TOURNAMENT_REQUEST: 'UPDATE_TOURNAMENT_REQUEST',
  UPDATE_TOURNAMENT_RESPONSE: 'UPDATE_TOURNAMENT_RESPONSE',

  TOURNAMENTS_GET_REQUEST: 'TOURNAMENTS_GET_REQUEST',
  TOURNAMENTS_GET_RESPONSE: 'TOURNAMENTS_GET_RESPONSE',

  FINISHED_TOURNAMENTS_GET_REQUEST: 'FINISHED_TOURNAMENTS_GET_REQUEST',
  FINISHED_TOURNAMENTS_GET_RESPONSE: 'FINISHED_TOURNAMENTS_GET_RESPONSE',
  CLEAN_FINISHED_TOURNAMENTS: 'CLEAN_FINISHED_TOURNAMENTS',

  TOURNAMENT_UPDATED: 'TOURNAMENT_UPDATED',
  NEW_TOURNAMENT_UPDATED: 'NEW_TOURNAMENT_UPDATED',

  TOURNAMENT_GET_RESPONSE: 'TOURNAMENT_GET_RESPONSE',
  TOURNAMENT_GET_REQUEST: 'TOURNAMENT_GET_REQUEST',
  TOURNAMENT_GET_RECEIVE: 'TOURNAMENT_GET_RECEIVE',

  CLEAN_TOURNAMENT: 'CLEAN_TOURNAMENT',

  TOURNAMENT_STATISTICS_GET_REQUEST: 'TOURNAMENT_STATISTICS_GET_REQUEST',
  TOURNAMENT_STATISTICS_GET_RESPONSE: 'TOURNAMENT_STATISTICS_GET_RESPONSE',
  TOURNAMENT_STATISTICS_CLEAN: 'TOURNAMENT_STATISTICS_CLEAN',

  VARIANT_STATISTICS_GET_REQUEST: 'VARIANT_STATISTICS_GET_REQUEST',
  VARIANT_STATISTICS_GET_RESPONSE: 'VARIANT_STATISTICS_GET_RESPONSE',
  VARIANT_STATISTICS_CLEAN: 'VARIANT_STATISTICS_CLEAN',

  VARIANT_GET_REQUEST: 'VARIANT_GET_REQUEST',
  VARIANT_GET_RESPONSE: 'VARIANT_GET_RESPONSE',
  VARIANT_POST_REQUEST: 'VARIANT_POST_REQUEST',
  CLEAR_VARIANT: 'CLEAR_VARIANT',

  VARIANT_UPDATED: 'VARIANT_UPDATED',

  UPDATE_VARIANT: 'UPDATE_VARIANT',

  CHANGE_VARIANT_STATE_RESPONSE: 'CHANGE_VARIANT_STATE_RESPONSE',
  CHANGE_VARIANT_STATE_REQUEST: 'CHANGE_VARIANT_STATE_REQUEST',

  VARIANT_POST_RESPONSE: 'VARIANT_POST_RESPONSE',

  GET_FEES_REQUEST: 'GET_FEES_REQUEST',
  GET_FEES_RESPONSE: 'GET_FEES_RESPONSE',

  GET_TIPS_REQUEST: 'GET_TIPS_REQUEST',
  GET_TIPS_RESPONSE: 'GET_TIPS_RESPONSE',

  ADD_TIP_REQUEST: 'ADD_TIP_REQUEST',
  ADD_TIP_RESPONSE: 'ADD_TIP_RESPONSE',

  GET_CLOCK_SETTINGS_REQUEST: 'GET_CLOCK_SETTINGS_REQUEST',
  GET_CLOCK_SETTINGS_RESPONSE: 'GET_CLOCK_SETTINGS_RESPONSE',
  GET_CLOCK_SETTINGS_CLEAN: 'GET_CLOCK_SETTINGS_CLEAN',

  UPDATE_TOURNAMENT_DETAIL: 'UPDATE_TOURNAMENT_DETAIL',

  DELETE_UNSAVED_TOURNAMENT_DETAIL: 'DELETE_UNSAVED_TOURNAMENT_DETAIL',
  INIT_TOURNAMENT_TO_SAVE_REQUEST: 'INIT_TOURNAMENT_TO_SAVE_REQUEST',

  CLEAN_TOURNAMENT_DETAIL: 'CLEAN_TOURNAMENT_DETAIL',

  TABLE_GET_REQUEST: 'TABLE_GET_REQUEST',
  TABLE_GET_RECEIVE: 'TABLE_GET_RECEIVE',

  GET_LOGS_REQUEST: 'GET_LOGS_REQUEST',
  GET_LOGS_RESPONSE: 'GET_LOGS_RESPONSE',
  CLEAR_LOGS: 'CLEAR_LOGS',
};
