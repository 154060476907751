import React from "react";
import { connect } from "react-redux";
import { updateTournamentDetail } from "../../../actions/tournamentActions";
import { createNewVariant } from "../../../util/tournamentUtil";

import GeneralSettings from "../mttGeneralSettings/GeneralSettings";

const updateVariants = (tournament, numOfPhases = 1) => {
  const numOfMissing = numOfPhases - tournament.tournamentPhases.length;
  for (let i = 0; i < numOfMissing; i++) {
    tournament.tournamentPhases.push({});
  }

  tournament.tournamentPhases = tournament.tournamentPhases.map((each, index) => {
    const day = index + 1;

    const newEach = {
      ...each,
      phase: day,
      variants: each.variants ? each.variants : [{}],
    };

    newEach.variants = newEach.variants.map((variant) => {
      if (variant._id) {
        return variant;
      }
      const tableSize = day > 1 ? undefined : tournament.tableSize;
      return createNewVariant(`Day ${day}`, tableSize, tournament.tableMaxOccupancy, day > 1 ? 1 : 0);
    });

    return newEach;
  });

  if (tournament.tournamentPhases.length > numOfPhases) {
    const phases = tournament.tournamentPhases.slice(0, numOfPhases);
    tournament.tournamentPhases = phases;
  }
  tournament.numOfPhases = numOfPhases;
};

class Basic extends React.Component {
  handleChange = (e) => {
    const value = e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
    const { tournament } = this.props;
    tournament[e.target.id] = value;

    this.props.updateTournamentDetail(tournament);
  };

  handlePhases = (numOfPhases) => {
    const { tournament } = this.props;
    updateVariants(tournament, numOfPhases);
    this.props.updateTournamentDetail(tournament);
  };

  updateMaxTableProps = (value) => {
    const { tournament } = this.props;
    tournament.tableSize = value.tableSize;
    tournament.tableMaxOccupancy = value.tableMaxOccupancy;

    if(tournament.tournamentPhases.length > 0) {
      tournament.tournamentPhases[0].variants.forEach((variant) => {
        variant.tableSize = value.tableSize;
        variant.tableMaxOccupancy = value.tableMaxOccupancy;
      });
    }

    this.props.updateTournamentDetail(tournament);
  };

  handleOptionalField = (field, enabled) => {
    const { tournament } = this.props;

    if (!enabled) {
      delete tournament[field];
    } else {
      tournament[field] = "";
    }

    this.props.updateTournamentDetail(tournament);
  };

  render() {
    const errors = Basic.getValidationErrors(this.props.tournament);
    return (
      <GeneralSettings
        handleChange={this.handleChange}
        handlePhases={this.handlePhases}
        updateMaxTableProps={this.updateMaxTableProps}
        handleOptionalField={this.handleOptionalField}
        tournament={this.props.tournament}
        errors={errors}
        updateTournament={this.props.updateTournamentDetail}
      />
    );
  }
}

Basic.finalize = (tournament, variant) => {
  updateVariants(tournament, tournament.numOfPhases);
};

Basic.getValidationErrors = (tournament) => {
  const isCreateMode = tournament.id === undefined;
  const validationResults = {
    eventNum: !(
      tournament.eventNum === undefined ||
      tournament.eventNum !== "" ||
      (tournament.eventNum > 0 &&
        tournament.eventNum <= 999 &&
        Number.isInteger(tournament.eventNum))
    ),
    name: !(tournament.name && tournament.name.length > 0),
    currency: !(
      tournament.currency !== undefined &&
      tournament.currency.length > 0 &&
      tournament.currency.length <= 3
    ),
    // guaranteedPool: tournament.guaranteedPool !== undefined && !(tournament.guaranteedPool > 0),
    // cutFromPoolPrc: !(tournament.cutFromPoolPrc > 0),
    // cutFromPoolCost: !(tournament.cutFromPoolCost > 0),
    tableSize:
      isCreateMode &&
      !(
        tournament.tableSize >= 2 &&
        tournament.tableSize <= 10 &&
        Number.isInteger(tournament.tableSize)
      ),
    // tableMaxOccupancy:
    //   isCreateMode &&
    //   !(
    //     tournament.tableMaxOccupancy >= 2 &&
    //     tournament.tableMaxOccupancy <= 10 &&
    //     tournament.tableMaxOccupancy <= tournament.tableSize &&
    //     Number.isInteger(tournament.tableMaxOccupancy)
    //   ),
    color: !(tournament.color !== undefined && tournament.color.length > 0),
  };
  console.log(`Basic tournament ${tournament.id} errors: ${JSON.stringify(validationResults)}`)
  return validationResults;
};

const mapStateToProps = (state) => {
  return {
    tournament: state.tournaments.tournamentToSave,
    tournamentValidationErrors: state.tournaments.tournamentValidationErrors,
  };
};

const mapDispatchToProps = {
  updateTournamentDetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(Basic);
