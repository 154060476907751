import React from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import CustomModal from "../../shared/CustomModal";
import PlayerRow from "./PlayerRow";
import { getFreeSeats, reseatPlayers } from "../../../actions/playerActions";
import { updateGameTable } from "../../../actions/tablesActions";
import ReseatPlayerTablesModal from "./ReseatPlayerTablesModal";
import { history } from "../../../service/history";
import { loading, loadingDone } from "../../../actions/commonActions";
import { getVariant } from "../../../actions/tournamentActions";

const NOT_ENOUGH_SEATS_MSG = "There is not enough new seats for players";

class ReseatAllPlayersModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reseatingConfirmed: false,
      selectedPlayer: {},
      toggleReseatPlayerTables: false,
      newPlayersSeats: [],
      validationMessage: "",
      notEnoughSeats: false,
      hideMe: false
    };
  }

  componentDidMount() {
    const players = this.props.players;
    this.props.getFreeSeats(this.props.variant.id)
      .then((response) => {
        const availableSeats = response.data.filter((each) => {
          return each.tableId !== (this.props.selectedTable || {}).id;
        });
        if (availableSeats.length > 0) {
          if (availableSeats.length >= players.length) {
            const newPlayersSeats = [];
            players.forEach((player, index) => {
              //first player to first available seat, and so on
              const availableSeat = availableSeats[index];
              newPlayersSeats.push({
                id: player.playerInTournamentPhaseVariantId,
                tableId: {
                  tableName: this.getTableName(availableSeat.tableId),
                  id: availableSeat.tableId || undefined,
                },
                seat: availableSeat.seatNum || undefined,
              });
            });
            this.setState({ newPlayersSeats });
          } else {
            this.setState({ notEnoughSeats: true });
            this.setState({ validationMessage: NOT_ENOUGH_SEATS_MSG });
          }
        }
      });
  }

  getTableName = (id) => {
    const table = this.props.variant.tables.find((t) => t.id === id) || {};
    return table.tableName;
  };

  clearModal = () => {
    this.setState({
      selectedPlayer: {},
      newPlayersSeats: [],
      validationMessage: "",
    });
    this.props.toggle();
  };

  toggle = (player) => {
    this.setState({
      toggleReseatPlayerTables: !this.state.toggleReseatPlayerTables,
      validationMessage: "",
    });
    if (player) {
      this.setState({
        selectedPlayer: player,
      });
    }
  };

  setPlayersNewSeat = (selectedTable, seat) => {
    if (selectedTable.id && this.state.selectedPlayer.playerInTournamentPhaseVariantId && seat) {
      const index = this.state.newPlayersSeats.findIndex((p) => {
        return p.id === this.state.selectedPlayer.playerInTournamentPhaseVariantId;
      });

      if (index >= 0) {
        this.state.newPlayersSeats.splice(index, 1);
      }

      const newSeats = this.state.newPlayersSeats.concat({
        id: this.state.selectedPlayer.playerInTournamentPhaseVariantId,
        tableId: selectedTable,
        seat: seat,
      });

      this.setState({ newPlayersSeats: newSeats }, () => {
      });
    }
  };

  getTableSeat = (player) => {
    let tableSeat;
    if (this.state.newPlayersSeats.length > 0) {
      tableSeat = this.state.newPlayersSeats.find((p) => {
        return p.id === player.playerInTournamentPhaseVariantId;
      });
    }

    if (tableSeat) {
      return {
        table: tableSeat.tableId.tableName,
        seat: tableSeat.seat
      };
    }

    return {
      table: "",
      seat: ""
    };
  };

  getNewSeatsForPlayers = () => {
    const playersWithNewSeats = this.state.newPlayersSeats.map((player) => {
      const playerObj = this.props.players.find(
        (each) => each.playerInTournamentPhaseVariantId === player.id
      );
      return {
        id: player.id,
        tableId: player.tableId.id,
        seatNum: player.seat,
        seatNumOld: playerObj.seatNum,
        firstName: playerObj.firstName,
        tableName: player.tableId.tableName,
        lastName: playerObj.lastName,
      };
    });

    return playersWithNewSeats;
  };

  confirmAndCloseTable = () => {
    if (!this.isValid()) return;
    const newSeating = this.getNewSeatsForPlayers();
    history.push(`/tournament/${this.props.variant.tournamentId}`);
    const variantId = this.props.variant.id;
    this.props.loading("getVariant");
    this.props.reseatPlayers(this.getNewSeatsForPlayers())
      .then(() => {
        this.props
          .updateGameTable(variantId, [
            {
              id: this.props.selectedTable.id,
              state: "closed",
              additionalData: { playersNewSeating: newSeating },
            },
          ])
          .then(() => {
            this.props.loadingDone("getVariant");
            this.props.getVariant(variantId);
          });
      });
  };

  isValid = () => {
    let validation = "";
    if (this.props.players.length !== this.getNewSeatsForPlayers().length) {
      validation += "Set a new seat for all players, ";
    }

    let hasDuplicates = false;
    this.state.newPlayersSeats.forEach((ps) => {
      const items = this.state.newPlayersSeats.filter(
        (ns) => ns.tableId.id === ps.tableId.id && ns.seat === ps.seat
      );
      if (items.length > 1) {
        hasDuplicates = true;
      }
    });

    if (hasDuplicates) {
      validation += " Each player has to have a unique seat";
    }
    if (this.state.notEnoughSeats) {
      validation = NOT_ENOUGH_SEATS_MSG;
    }

    if (validation !== "") {
      this.setState({ validationMessage: validation });
      return false;
    }

    return true;
  };

  render() {
    const playerRows =
      this.props.players.length === 0 ? (
        <h3>No players to reseat</h3>
      ) : (
        this.props.players
          .sort(function (a, b) {
            return a.seatNum - b.seatNum;
          })
          .map((player, index) => {
            return (
              <>
                <PlayerRow
                  player={player}
                  chgSeatEvent={(player) => {
                    this.toggle(player);
                    this.setState({ hideMe: true });
                  }}
                  tableSeat={this.getTableSeat(player)}
                />
                {index != this.props.players.length - 1 && <Row className="graySeparator" />}
              </>
            );
          })
      );
    return (
      <>
        {this.props.isOpen && (
          <CustomModal
            title="Reseat all players and close table"
            open={this.props.isOpen}
            closeHandler={this.props.toggle}
            className="reseat-all-players"
            dialogClassName={`${this.state.hideMe ? "hidden" : ""}`}
          >
            <div className="mx-3 p-1">
              {this.props.isPlayerInTournamentWithoutSeat && !this.state.reseatingConfirmed && (
                <>
                  <Row>
                    <Col className="mx-3 p-1">
                      Are you sure you want to close the table?
                      <br />
                      There are some players without a seat or at the waiting list
                    </Col>
                  </Row>
                  <Row className="confirm-btns">
                    <Col>
                      <Button
                        onClick={() => {
                          this.setState({ reseatingConfirmed: true });
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="small"
                        onClick={() => {
                          history.push(`/tournament/${this.props.variant.tournamentId}`);
                        }}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              {(!this.props.isPlayerInTournamentWithoutSeat || this.state.reseatingConfirmed) && (
                <>
                  <div className="players">
                    {playerRows}
                  </div>
                  {this.props.variant.tables.find(t => t.seatingLocked) && <span className="mb-0 info">Some tables are locked. If you wish to reseat to those tables, unlock them</span>}
                  <Row className="confirm-btns">
                    <Button onClick={this.confirmAndCloseTable}>Confirm and close table</Button>
                    <Button variant="outline-primary" className="small" onClick={() => {
                    }}>
                      Print
                    </Button>
                  </Row>
                  <Row className="validation-txt">{this.state.validationMessage}</Row>
                </>
              )}
            </div>
          </CustomModal>
        )}
        {!this.state.errorMsg &&
          this.state.toggleReseatPlayerTables &&
          this.props.variant.tables && (
            <ReseatPlayerTablesModal
              isOpen={this.state.toggleReseatPlayerTables}
              toggle={(player) => {
                this.toggle(player);
                this.setState({ hideMe: false });
              }}
              hideLockedTables={true}
              selectedPlayer={this.state.selectedPlayer}
              variant={this.props.variant}
              setNewSeat={this.setPlayersNewSeat}
              selectedTable={this.props.selectedTable}
              reservedSeats={this.state.newPlayersSeats}
            />
          )}
      </>
    );
  }
}

ReseatAllPlayersModal.defaultProps = {};

const mapDispatchToProps = {
  reseatPlayers,
  getFreeSeats,
  updateGameTable,
  getVariant,
  loadingDone,
  loading,
};

export default connect(null, mapDispatchToProps)(ReseatAllPlayersModal);
