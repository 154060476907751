import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { putPlayerOnSeatingByFloorman } from "../../../actions/playerActions";
import { ReactComponent as WatitingListIcon } from "../../../image/icon-waiting-list-coordinator_v2.svg";
import PlayerActionConfirmationModal from "../PlayerActionConfirmationModal";

class SeatingByFloormanButton extends React.Component {
  constructor(props) {
    super();
    this.state = {
      openModal: false,
    };
  }

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
    if (this.props.toggleParent) {
      this.props.toggleParent();
    }
  };

  closeModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  render() {
    return (
      <>
        <div className="action">
          <Button color="primary" className="action-btn" onClick={this.toggleModal}>
            <WatitingListIcon className="action-icon" />
          </Button>
          <a>Seating by floorman</a>
        </div>
        {this.state.openModal && (
          <PlayerActionConfirmationModal
            icon={<WatitingListIcon className="modal-action-icon" />}
            isOpen={this.state.openModal}
            toggle={this.toggleModal}
            action="Seating by floorman"
            doAction={() => {
              this.props.putPlayerOnSeatingByFloorman(
                this.props.player.playerInTournamentPhaseVariantId
              );
              if(this.props.onAction) {
                this.props.onAction();
              }
              this.closeModal();
            }}
            text={`Are you sure you want put player ${this.props.player.firstName} ${this.props.player.lastName} on Seating by floorman List?`}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  putPlayerOnSeatingByFloorman,
};

export default connect(null, mapDispatchToProps)(SeatingByFloormanButton);
