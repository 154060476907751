import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-mini";
import CollapsibleToggleSwitch from "../../functionalComponents/CollapsibleToggleSwitch";
import { history } from "../../../service/history";
import { isMultiday } from "../../../util/tournamentUtil";
import { formatDateShortDash, formatTime } from "../../../util/stringUtil";
import SeatingPreferences from "../../shared/SeatingPreferences";

class BasicVariant extends React.Component {
  constructor(props) {
    super(props);

    const variantState = this.mapVariantToLocalStateStructure(props.variant);
    this.state = {
      ...variantState,
    };
  }

  componentDidMount() {
    if (this.props.variant.registration === undefined)
      this.handleChangeChk("registration");
  }

  mapVariantToLocalStateStructure(variant) {
    return {
      scheduledStartAtDate: formatDateShortDash(variant.scheduledStartAt),
      scheduledStartAtTime: formatTime(variant.scheduledStartAt),
      registrationStartAt: formatDateShortDash(variant.registrationStartAt),
      registrationStartAtTime: formatTime(variant.registrationStartAt),
      variantId: this.getVariantId(variant),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.variant && this.getVariantId(nextProps.variant) !== this.state.variantId) {
      const variantState = this.mapVariantToLocalStateStructure(nextProps.variant);
      this.setState({
        ...variantState,
      });
    }
  }

  getVariantId(variant) {
    // newly created variant has _id, saved variant returned from server has id
    return variant.id || variant._id;
  }

  handleChangeEvent = (e) => {
    let value
    if (e.target.name === "seatingByFloorman") {
      value = e.target.value === "automatic" ? 0 : 1
    } else {
      value = e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
    }

    this.handleChange(e.target.name, value);
  };

  turnoffToggles = (field, variant) => {
    if (field === "registration" && variant.registration === false) {
      variant.buyIn = false;
      variant.reEntry = false;
      variant.reBuy = false;
      variant.addon = false;
      variant.bonus = false;
    }
  }

  handleChangeChk = (field) => {
    const { variant } = this.props;
    variant[field] = !variant[field];
    const { tournament } = this.props;

    this.turnoffToggles(field, variant);

    this.props.updateTournamentDetail(tournament, variant);
  };

  handleChange = (field, value) => {
    const { variant } = this.props;
    const { tournament } = this.props;
    if (field === "scheduledStartAtDate" || field === "scheduledStartAtTime") {
      this.setState({ [field]: value }, () => {
        if (this.state.scheduledStartAtDate && this.state.scheduledStartAtTime) {
          variant.scheduledStartAt = moment(`${this.state.scheduledStartAtDate} ${this.state.scheduledStartAtTime}`, "YYYY-MM-DD hh:mm").toDate();
        } else {
          variant.scheduledStartAt = undefined;
        }

        this.props.updateTournamentDetail(tournament, variant);
      });
    } else if (field === "registrationStartAt" || field === "registrationStartAtTime") {
      this.setState({ [field]: value }, () => {
        if (this.state.registrationStartAt && this.state.registrationStartAtTime) {
          variant.registrationStartAt = moment(`${this.state.registrationStartAt} ${this.state.registrationStartAtTime}`, "YYYY-MM-DD hh:mm").toDate()
        } else {
          variant.registrationStartAt = undefined;
        }
        this.props.updateTournamentDetail(tournament, variant);
      });
    } else {
      variant[field] = value;
      this.props.updateTournamentDetail(tournament, variant);
    }
  };

  setStartTime(variantDateTime, dateState, timeState) {
    const { variant } = this.props;
    const startAt = moment(variant[variantDateTime]).toDate();
    let date = "";
    let time = "";
    if (startAt) {
      date = `${startAt.getFullYear()}-${this.setTo2Digits(startAt.getMonth())}-${this.setTo2Digits(
        startAt.getDate()
      )}`;
      time = `${this.setTo2Digits(startAt.getHours())}:${this.setTo2Digits(startAt.getMinutes())}`;
    }

    this.setState({ [dateState]: date, [timeState]: time });
  }

  setTo2Digits(datePart) {
    return (datePart < 10 ? "0" : "") + datePart;
  }

  render() {
    const { variant, tournament } = this.props;
    const isMultiDay = isMultiday(tournament);

    if (variant) {
      const errors = BasicVariant.getValidationErrors(tournament, variant);
      const registrationSettings = (
        <>
          <Col sm={4}>
            <Label for="registrationStartAt">Date</Label>
            <Input
              id="registrationStartAt"
              type="date"
              name="registrationStartAt"
              invalid={errors.registrationStartAt}
              onChange={this.handleChangeEvent}
              value={
                this.state.registrationStartAt !== undefined ? this.state.registrationStartAt : ""
              }
            />
          </Col>
          <Col sm={4}>
            <Label for="registrationStartAtTime">Time</Label>
            <Input
              id="registrationStartAtTime"
              type="time"
              name="registrationStartAtTime"
              invalid={errors.registrationStartAtTime}
              onChange={this.handleChangeEvent}
              value={
                this.state.registrationStartAtTime !== undefined
                  ? this.state.registrationStartAtTime
                  : ""
              }
            />
          </Col>
          <Col sm={4}>
            <Label for="registrationCloseAtLevel">Registration ends after this level:</Label>
            <Input
              id="registrationCloseAtLevel"
              placeholder="Type level"
              type="number"
              name="registrationCloseAtLevel"
              invalid={errors.registrationCloseAtLevel}
              onChange={this.handleChangeEvent}
              value={
                variant.registrationCloseAtLevel !== undefined
                  ? variant.registrationCloseAtLevel
                  : ""
              }
            />
          </Col>
        </>
      );
      return (
        <div className="basic-variant">
          {this.props.showTitle && (
            <Row>
              <h1>
                {(tournament.tournamentPhases || []).length > 1
                  ? "Flights setup"
                  : "Daily MTT setup"}
              </h1>
              <div className="separator" />
            </Row>
          )}
          {this.props.showVariantName && (
            <Row>
              <h1>{variant.name}</h1>
              <div className="separator" />
            </Row>
          )}
          {!this.props.isTemplateMode && (
            <Row className="pt-5">
              <h3>MTT starts at</h3>
              <div className="separator" />
              <Col sm={4}>
                <Label for="scheduledStartAtDate">Date</Label>
                <Input
                  id="scheduledStartAtDate"
                  disabled={variant.stateId > 1}
                  type="date"
                  name="scheduledStartAtDate"
                  invalid={errors.scheduledStartAtDate}
                  onChange={this.handleChangeEvent}
                  value={
                    this.state.scheduledStartAtDate !== undefined
                      ? this.state.scheduledStartAtDate
                      : ""
                  }
                />
              </Col>
              <Col sm={4}>
                <Label for="scheduledStartAtTime">Time</Label>
                <Input
                  id="scheduledStartAtTime"
                  name="scheduledStartAtTime"
                  type="time"
                  invalid={errors.scheduledStartAtTime}
                  disabled={variant.stateId > 1}
                  onChange={this.handleChangeEvent}
                  value={
                    this.state.scheduledStartAtTime !== undefined
                      ? this.state.scheduledStartAtTime
                      : ""
                  }
                />
              </Col>
            </Row>
          )}
          {!this.props.isTemplateMode && (
            <Row className="pt-5">
              {isMultiDay ? (
                <CollapsibleToggleSwitch
                  field="registration"
                  text="Is registration open?"
                  switchValue={this.handleChangeChk.bind(this)}
                  isSwitchedOn={variant.registration}
                >
                  {registrationSettings}
                </CollapsibleToggleSwitch>
              ) : (
                <>
                  <h3>Registration starts at</h3>
                  <div className="separator" />
                  {registrationSettings}
                </>
              )}
            </Row>
          )}
          <Row className="pt-5">
            <h3>Seating preferences</h3>
            <div className="separator" />
            <div className="mt-2">
              {variant.phase === 1 && (
                  <SeatingPreferences name="seatingByFloorman" type="number" disabled={variant.phase > 1} onChange={(e) => this.handleChangeEvent({ target: { value: e, type: 'number', name: 'seatingByFloorman' } })} value={variant.seatingByFloorman === 0 ? "automatic" : "floorman"} />
              )}
              {variant.phase > 1 && (
                 <>
                    <br/>
                    <label className="ml-2 mt-1 gray-label">All new players registered to {variant.name} prior to its start, will be added to the "seating by floorman" list and will be automatically seated after the "reseat for new day" process has finished</label>
                 </>
              )}
            </div>
          </Row>
          {variant.registration &&
            <>
              <Row className="pt-5">
                <CollapsibleToggleSwitch
                  field="buyIn"
                  text="Buy in"
                  switchValue={this.handleChangeChk.bind(this)}
                  isSwitchedOn={variant.registration && variant.buyIn}
                >
                  <Col sm={4}>
                    <Label for="buyInPool">Buyin cost</Label>
                    <Input
                      id="buyInPool"
                      placeholder="Type buyin"
                      name="buyInPool"
                      type="number"
                      invalid={errors.buyInPool}
                      onChange={this.handleChangeEvent}
                      value={variant.buyInPool !== undefined ? variant.buyInPool : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="buyInFee">Buyin fee</Label>
                    <Input
                      id="buyInFee"
                      placeholder="Type buyin fee"
                      name="buyInFee"
                      invalid={errors.buyInFee}
                      onChange={this.handleChangeEvent}
                      type="number"
                      value={variant.buyInFee !== undefined ? variant.buyInFee : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="buyInChips">Buy in chips</Label>
                    <Input
                      id="buyInChips"
                      placeholder="Type buy in chips"
                      name="buyInChips"
                      invalid={errors.buyInChips}
                      onChange={this.handleChangeEvent}
                      type="number"
                      value={variant.buyInChips !== undefined ? variant.buyInChips : ""}
                    />
                  </Col>
                </CollapsibleToggleSwitch>
              </Row>
              <Row className="pt-5">
                <CollapsibleToggleSwitch
                  field="reEntry"
                  text="Re-entry"
                  switchValue={this.handleChangeChk.bind(this)}
                  isSwitchedOn={variant.registration && variant.reEntry}
                >
                  <Col sm={4}>
                    <Label for="reEntryPool">Re-entry cost</Label>
                    <Input
                      id="reEntryPool"
                      placeholder="Type re-entry"
                      name="reEntryPool"
                      invalid={errors.reEntryPool}
                      onChange={this.handleChangeEvent}
                      type="number"
                      value={variant.reEntryPool !== undefined ? variant.reEntryPool : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="reEntryFee">Re-entry fee</Label>
                    <Input
                      id="reEntryFee"
                      placeholder="Type re-entry fee"
                      name="reEntryFee"
                      invalid={errors.reEntryFee}
                      onChange={this.handleChangeEvent}
                      type="number"
                      value={variant.reEntryFee !== undefined ? variant.reEntryFee : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="reEntryChips">Re-entry chips</Label>
                    <Input
                      id="reEntryChips"
                      placeholder="Type re-entry chips"
                      name="reEntryChips"
                      invalid={errors.reEntryChips}
                      type="number"
                      onChange={this.handleChangeEvent}
                      value={variant.reEntryChips !== undefined ? variant.reEntryChips : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="reEntryMaxPerPlayer">Max per player</Label>
                    <Input
                      id="reEntryMaxPerPlayer"
                      placeholder="Type max per player"
                      name="reEntryMaxPerPlayer"
                      type="number"
                      invalid={errors.reEntryMaxPerPlayer}
                      onChange={this.handleChangeEvent}
                      value={
                        variant.reEntryMaxPerPlayer !== undefined ? variant.reEntryMaxPerPlayer : ""
                      }
                    />
                  </Col>
                </CollapsibleToggleSwitch>
              </Row>
              <Row className="pt-5">
                <CollapsibleToggleSwitch
                  field="reBuy"
                  text="Rebuy"
                  switchValue={this.handleChangeChk.bind(this)}
                  isSwitchedOn={variant.registration && variant.reBuy}
                >
                  <Col sm={4}>
                    <Label for="reBuyPool">Rebuy cost</Label>
                    <Input
                      id="reBuyPool"
                      placeholder="Type rebuy"
                      name="reBuyPool"
                      onChange={this.handleChangeEvent}
                      type="number"
                      invalid={errors.reBuyPool}
                      value={variant.reBuyPool !== undefined ? variant.reBuyPool : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="reBuyFee">Rebuy fee</Label>
                    <Input
                      id="reBuyFee"
                      placeholder="Type rebuy fee"
                      name="reBuyFee"
                      onChange={this.handleChangeEvent}
                      type="number"
                      invalid={errors.reBuyFee}
                      value={variant.reBuyFee !== undefined ? variant.reBuyFee : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="reBuyChips">Rebuy chips</Label>
                    <Input
                      id="reBuyChips"
                      placeholder="Type rebuy chips"
                      name="reBuyChips"
                      onChange={this.handleChangeEvent}
                      type="number"
                      invalid={errors.reBuyChips}
                      value={variant.reBuyChips !== undefined ? variant.reBuyChips : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="reBuyMaxPerPlayer">Max per player</Label>
                    <Input
                      id="reBuyMaxPerPlayer"
                      placeholder="Type max per player"
                      name="reBuyMaxPerPlayer"
                      type="number"
                      onChange={this.handleChangeEvent}
                      invalid={errors.reBuyMaxPerPlayer}
                      value={variant.reBuyMaxPerPlayer !== undefined ? variant.reBuyMaxPerPlayer : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="reBuyEndAtLevel">Rebuy ends after this level</Label>
                    <Input
                      id="reBuyEndAtLevel"
                      placeholder="Type rebuy ends after this level"
                      name="reBuyEndAtLevel"
                      type="number"
                      onChange={this.handleChangeEvent}
                      invalid={errors.reBuyEndAtLevel}
                      value={variant.reBuyEndAtLevel !== undefined ? variant.reBuyEndAtLevel : ""}
                    />
                  </Col>
                </CollapsibleToggleSwitch>
              </Row>
              <Row className="pt-5">
                <CollapsibleToggleSwitch
                  field="addon"
                  text="Addon"
                  switchValue={this.handleChangeChk.bind(this)}
                  isSwitchedOn={variant.registration && variant.addon}
                >
                  <Col sm={4}>
                    <Label for="addonPool">Addon cost</Label>
                    <Input
                      id="addonPool"
                      placeholder="Type addon"
                      name="addonPool"
                      onChange={this.handleChangeEvent}
                      type="number"
                      invalid={errors.addonPool}
                      value={variant.addonPool !== undefined ? variant.addonPool : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="addonFee">Addon fee</Label>
                    <Input
                      id="addonFee"
                      placeholder="Type addon fee"
                      name="addonFee"
                      onChange={this.handleChangeEvent}
                      type="number"
                      invalid={errors.addonFee}
                      value={variant.addonFee !== undefined ? variant.addonFee : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="addonChips">Addon chips</Label>
                    <Input
                      id="addonChips"
                      placeholder="Type addon chips"
                      name="addonChips"
                      onChange={this.handleChangeEvent}
                      invalid={errors.addonChips}
                      type="number"
                      value={variant.addonChips !== undefined ? variant.addonChips : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="addonMaxPerPlayer">Max per player</Label>
                    <Input
                      id="addonMaxPerPlayer"
                      placeholder="Type max per player"
                      name="addonMaxPerPlayer"
                      type="number"
                      onChange={this.handleChangeEvent}
                      invalid={errors.addonMaxPerPlayer}
                      value={variant.addonMaxPerPlayer !== undefined ? variant.addonMaxPerPlayer : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="addonEndAtLevel">Add-on ends after this level</Label>
                    <Input
                      id="addonEndAtLevel"
                      placeholder="Type Add-on ends after this level"
                      name="addonEndAtLevel"
                      type="number"
                      onChange={this.handleChangeEvent}
                      invalid={errors.addonEndAtLevel}
                      value={variant.addonEndAtLevel !== undefined ? variant.addonEndAtLevel : ""}
                    />
                  </Col>
                </CollapsibleToggleSwitch>
              </Row>
              <Row className="pt-5">
                <CollapsibleToggleSwitch
                  field="bonus"
                  text="Bonus chips"
                  switchValue={this.handleChangeChk.bind(this)}
                  isSwitchedOn={variant.registration && variant.bonus}
                >
                  <Col sm={4}>
                    <Label for="bonusChips">Bonus chips</Label>
                    <Input
                      id="bonusChips"
                      placeholder="Type bonus chips"
                      name="bonusChips"
                      onChange={this.handleChangeEvent}
                      type="number"
                      invalid={errors.bonusChips}
                      value={variant.bonusChips !== undefined ? variant.bonusChips : ""}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="bonusMaxPerPlayer">Max per player</Label>
                    <Input
                      id="bonusMaxPerPlayer"
                      placeholder="Type max per player"
                      name="bonusMaxPerPlayer"
                      type="number"
                      onChange={this.handleChangeEvent}
                      invalid={errors.bonusMaxPerPlayer}
                      value={variant.bonusMaxPerPlayer !== undefined ? variant.bonusMaxPerPlayer : ""}
                    />
                  </Col>
                </CollapsibleToggleSwitch>
              </Row>
            </>}
          {/* <Row className='pt-5 pb-5'>
                    <h3>Shot clock MTT</h3>
                    <ToggleSwitch field={"bonus"}
                        switchValue={this.handleChangeChk.bind(this)}
                        isSwitchedOn={variant.bonus}></ToggleSwitch>
                    <div className="separator" />
                    <Col sm={4}>
                        <Label for="secondsPerDecision">Seconds per decision</Label>
                        <Input id="secondsPerDecision" placeholder={"Type seconds per decision"} name='secondsPerDecision'
                            onChange={this.handleChangeEvent}
                            className={errors.bonusChips ? "error" : ""}
                            value={variant.bonusChips !== undefined ? variant.bonusChips : ""} />
                    </Col>
                </Row> TBD */}
        </div>
      );
    }
    history.push("/mtt");
    return <div>Flight not found, redirect to home page...</div>;
  }
}

BasicVariant.defaultProps = {
  tournament: {
    tournamentPhases: [{ variants: [] }],
  },
  showTitle: true,
};

BasicVariant.getValidationErrors = (tournament, variant) => {
  const isUpdateMode = variant.id !== undefined;
  const isMultiDay =
    tournament.numOfPhases > 1 ||
    (tournament.tournamentPhases && (tournament.tournamentPhases[0].variants || []).length > 1);

  // TODO PZE: from server we get all dates as string and if this method is called as "static" method i.e in EditTournament, dates not casted into date would cause problem
  const registrationStartAt =
    typeof variant.registrationStartAt === "string"
      ? moment(variant.registrationStartAt).toDate()
      : variant.registrationStartAt;
  const scheduledStartAt =
    typeof variant.scheduledStartAt === "string"
      ? moment(variant.scheduledStartAt).toDate()
      : variant.scheduledStartAt;

  const validationErrors = {
    scheduledStartAtDate: !(
      isUpdateMode ||
      (scheduledStartAt && moment().isBefore(moment(scheduledStartAt)))
    ),
    scheduledStartAtTime: !(
      isUpdateMode ||
      (scheduledStartAt && moment().isBefore(moment(scheduledStartAt)))
    ),

    registrationStartAt:
      (!isMultiDay || variant.registration) &&
      !(
        registrationStartAt &&
        scheduledStartAt &&
        registrationStartAt.getTime() < scheduledStartAt.getTime()
      ),
    registrationStartAtTime:
      (!isMultiDay || variant.registration) &&
      !(
        registrationStartAt &&
        scheduledStartAt &&
        registrationStartAt.getTime() < scheduledStartAt.getTime()
      ),
    registrationCloseAtLevel:
      (!isMultiDay || variant.registration) &&
      !(0 <= variant.registrationCloseAtLevel && variant.registrationCloseAtLevel <= 999),
  }

  if (variant.registration === true) {
    validationErrors.buyInPool = variant.buyIn && !(variant.buyInPool > -1);
    validationErrors.buyInFee = variant.buyIn && !(variant.buyInFee > -1 && Number.isInteger(variant.buyInFee));
    validationErrors.buyInChips = variant.buyIn && !(variant.buyInChips > 0 && Number.isInteger(variant.buyInChips));

    validationErrors.reEntryPool = variant.reEntry && !(variant.reEntryPool > 0);
    validationErrors.reEntryFee = variant.reEntry && !(variant.reEntryFee >= 0);
    validationErrors.reEntryChips =
      variant.reEntry && !(variant.reEntryChips > 0 && Number.isInteger(variant.reEntryChips));
    validationErrors.reEntryMaxPerPlayer =
      variant.reEntry &&
      !(variant.reEntryMaxPerPlayer > 0 && Number.isInteger(variant.reEntryMaxPerPlayer));

    validationErrors.reBuyPool = variant.reBuy && !(variant.reBuyPool > 0);
    validationErrors.reBuyFee = variant.reBuy && !(variant.reBuyFee >= 0);
    validationErrors.reBuyChips = variant.reBuy && !(variant.reBuyChips > 0 && Number.isInteger(variant.reBuyChips));
    validationErrors.reBuyEndAtLevel =
      variant.reBuy && !(variant.reBuyEndAtLevel > 0 && Number.isInteger(variant.reBuyEndAtLevel));
    validationErrors.reBuyMaxPerPlayer =
      variant.reBuy &&
      !(variant.reBuyMaxPerPlayer > 0 && Number.isInteger(variant.reBuyMaxPerPlayer));

    validationErrors.addonPool = variant.addon && !(variant.addonPool > 0);
    validationErrors.addonFee = variant.addon && !(variant.addonFee >= 0);
    validationErrors.addonChips = variant.addon && !(variant.addonChips > 0 && Number.isInteger(variant.addonChips));
    validationErrors.addonMaxPerPlayer =
      variant.addon &&
      !(variant.addonMaxPerPlayer > 0 && Number.isInteger(variant.addonMaxPerPlayer));
    validationErrors.addonEndAtLevel =
      variant.addon && !(variant.addonEndAtLevel > 0 && Number.isInteger(variant.addonEndAtLevel));

    validationErrors.bonusChips = variant.bonus && !(variant.bonusChips > 0);
    validationErrors.bonusMaxPerPlayer =
      variant.bonus &&
      !(variant.bonusMaxPerPlayer > 0 && Number.isInteger(variant.bonusMaxPerPlayer));
  }

  //console.log(`BasicVariant ${variant.id} errors: ${JSON.stringify(validationErrors)}`)
  return validationErrors;
};

export default BasicVariant;
