import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import CustomModal from '../shared/CustomModal';
import { resetPassword } from '../../actions/usersActions';

const ResetPasswordModal = (props) => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');

  useEffect(() => {
    getPassword();
  }, []);

  const getPassword = () => {
    const newPassword = Math.random()
      .toString(36)
      .substring(2, 9)
      .toUpperCase();

    setPassword(newPassword);
  };

  const submitResetPassword = () => {
    dispatch(
      resetPassword({
        identifier: props.user.identifier,
        password: password,
      }),
    );
  };

  return (
    <CustomModal
      open
      closeHandler={props.toggle}
      className="reset-password"
      dialogClassName="modal-width"
    >
      <>
        <div className="main-text">
          Are you sure you want to generate new password for
          {' '}
          {props.user.identifier}
          {' '}
          ?
        </div>
        <div className="small-text">New password</div>
        <div className="password">{password}</div>
        <Button className="confirmation-btn" color="primary" onClick={submitResetPassword}>
          Yes, I am sure
        </Button>
      </>
    </CustomModal>
  );
};

export default ResetPasswordModal;
