import React from "react";
import { Button } from "reactstrap";
import { ReactComponent as PlusCircle } from "../../../image/plus-circle-solid.svg";
import CustomModal from "../../shared/CustomModal";
import { ReactComponent as PlayersIcon } from "../../../image/icon-players.svg";

const PayoutSetupModal = (props) => (
  <CustomModal
    title="Payout setup"
    open={props.isOpen}
    closeHandler={props.toggle}
    className="table-settings-modal"
  >
    <>
      <Button className="actions mt-4" onClick={props.onSetNoITMPlayers}>
        <PlayersIcon className="action-icon" />
        Set No. ITM players
      </Button>
      <Button className="actions" onClick={props.onAssignSamePayoutToMultiPLayers}>
        <PlusCircle className="action-icon" />
        <span className="nobr">Assign same payout to multiple positions</span>
      </Button>
      <p className="info-text" style={{ visibility: props.showWarning ? "visible" : "hidden" }}>
        You need to set No. ITM players before assign same payout to multiple positions
      </p>
    </>
  </CustomModal>
);

export default PayoutSetupModal;
