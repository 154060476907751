import actionTypes from '../actiontypes/tableActionTypes';

const initState = []
export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.TABLES_GET_REQUEST:
      return [];
    case actionTypes.TABLES_GET_RESPONSE:
      return [].concat(action.data || initState);
    case actionTypes.TABLES_SET_LOCK_STATE:
      return [];
    default:
      return state;
  }
};
