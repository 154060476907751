import React from 'react';
import { Collapse as ReactStrapCollapse } from 'reactstrap';
import { useSelector } from 'react-redux';

const Collapse = (props) => {
  const lastMenuItemId = useSelector((state) => state.component.lastMenuItemId);

  return <ReactStrapCollapse {...props} className={`custom-collapse ${props.id && lastMenuItemId === props.id ? 'selected' : ''}`} />;
};

export default Collapse;
