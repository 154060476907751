import actionTypes from '../actiontypes/tableActionTypes';
import tournamentActionTypes from '../actiontypes/tournamentActionTypes';
import restClient from '../util/restClient';
import { combinedRegisterLoaderAndShowError } from './commonActions';

const getSeatingPlanResponse = (data) => ({ type: actionTypes.TABLES_GET_SEATINGPLAN_RESPONSE, data });
const getSeatingPlanRequest = (data) => ({ type: actionTypes.TABLES_GET_SEATINGPLAN_REQUEST });
const getSeatingPlanClean = (data) => ({ type: actionTypes.TABLES_GET_SEATINGPLAN_CLEAN });
const getTablesRequest = () => ({ type: actionTypes.TABLES_GET_REQUEST });
const getTablesResponse = (data) => ({ type: actionTypes.TABLES_GET_RESPONSE, data });
const updateGameTableRequest = () => ({ type: actionTypes.TABLES_SET_LOCK_STATE });
const updateVariant = (data) => ({ type: tournamentActionTypes.VARIANT_GET_RESPONSE, data });
const moveTableRequest = () => ({ type: actionTypes.TABLE_MOVE });

export const getSeatingPlan = (seatingObject) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getSeatingPlanRequest());
    return restClient.post(`/newday/seatingplan`, seatingObject).then((response) => {
      dispatch(getSeatingPlanResponse(response.data.tables));
      return Promise.resolve(response);
    });
  },
  'getSeatingPlan',
  'Can not get seating plan',
);

export const cleanSeatingPlan = () => (dispatch) => {
    dispatch(getSeatingPlanClean());
};


export const getTables = (tournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(dispatch, () => {
  dispatch(getTablesRequest());
  return restClient.get(`/table/${tournamentId}`).then((response) => {
    dispatch(getTablesResponse(response.data));
    return Promise.resolve(response);
  });
}, 'getTables', 'Can not resolve list of tournaments');

export const updateGameTable = (variantId, data) => (dispatch) => combinedRegisterLoaderAndShowError(dispatch, () => {
  dispatch(updateGameTableRequest());
  return restClient.post(`/tournament/variant/${variantId}/tables`, data).then((response) => {
    dispatch(updateVariant(response.data[0]));
    return Promise.resolve(response);
  });
}, 'updateGameTable', 'Can not set lock state');

export const moveTable = (variantId, gameTableId, tournamentTableId) => (dispatch) => combinedRegisterLoaderAndShowError(dispatch, () => {
  dispatch(moveTableRequest());
  return restClient
    .post(
      `/tournament/variant/${variantId}/movetable`,
      {
        gameTableId,
        tournamentTableId,
      },
    )
    .then((response) => {
      dispatch(updateVariant(response.data[0]));
      return Promise.resolve(response);
    });
}, 'moveTable', 'Can not move table');
