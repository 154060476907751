import React, { useState } from "react";
import { useSelector } from "react-redux";
import restClient from "../../../util/restClient";
import ToggleSwitch from "../../functionalComponents/ToggleSwitch";
import { toBoolean } from "../../../util/stringUtil";

const HidePayouts = (props) => {
  const { variant } = props;
  const clockSettings = useSelector((state) => state.tournaments.clockSettings);
  const [hidePayouts, setHidePayouts] = useState(false);

  if (clockSettings.isLoaded && toBoolean(clockSettings.data.hide_payouts) !== hidePayouts) {
    setHidePayouts(toBoolean(clockSettings.data.hide_payouts));
  }

  return (
    <div className="hide-payouts mt-4 d-none d-md-block">
      <span className="mr-2">Hide payouts and prize pool</span>
      <ToggleSwitch
        switchValue={async (e) => {
          const formData = {
            id: clockSettings.data.id,
            hide_payouts: hidePayouts === true ? 0 : 1,
          };

          const res = await restClient.postAsync(
            `clock/${variant.id}/clock-hide-payouts`,
            formData
          );
          clockSettings.data = { ...clockSettings.data, ...res };

          setHidePayouts(!hidePayouts);
        }}
        isSwitchedOn={hidePayouts}
      />
    </div>
  );
};

export default HidePayouts;
