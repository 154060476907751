import * as types from '../actiontypes/actionTypes';
import * as parse from '../util/parseString';
import restClient from '../util/restClient';
import { history } from '../service/history';
import EvSocket from '../service/EvSocket';
import { initSocket } from '../service/EvSocket';
import { UNSAVED_CHANGES_CLEAR } from "../actiontypes/actionTypes";

export function loading(actionId) {
  return { type: types.IS_LOADING, actionId };
}

export function loadingDone(actionId) {
  return { type: types.IS_LOADING_DONE, actionId };
}

export function loginRequest() {
  return { type: types.LOGIN_REQ };
}

export function loginFailure(data) {
  return { type: types.LOGIN_FAILURE, data };
}

export function loginSuccess(data) {
  return { type: types.LOGIN_SUCCESS, data };
}

const setLocals = (response) => {
  localStorage.setItem('xsrf-token', response.headers['xsrf-token']);
  localStorage.setItem('currentUserIdentifier', response.data.identifier);
  localStorage.setItem('currentUserAlias', response.data.alias);
  localStorage.setItem('currentUserRoleId', response.data.roleID);
  localStorage.setItem('currentUserRole', response.data.role);
};

export function prolongAuthToken() {
  return restClient
    .post('/prolongAuthToken')
    .then((response) => {
      setLocals(response);
      return Promise.resolve();
    })
    .catch((er) => Promise.reject(er));
}

export const login = (loginData, socket) => (dispatch) => showError(
  dispatch,
  () => {
    dispatch(loginRequest());
    const credentials = {};
    credentials.identifier = loginData.identifier;
    credentials.password = loginData.password;

    return restClient
      .post('/login', credentials, dispatch, 'User login failed')
      .then((response) => {
        setLocals(response);

          initSocket()
        dispatch(
          loginSuccess({
            userRole: response.headers['user-role'],
          }),
        );
        history.push('/');
      });
  },
  'Login failed',
);

export function logOutSuccess() {
  return { type: types.LOGOUT_SUCCESS };
}

export function logOut(socket) {
  return function (dispatch) {
    restClient.post('/logout', {}, dispatch, 'User logout failed').then(() => {
      EvSocket.disconnect();

      localStorage.removeItem('xsrf-token');
      localStorage.removeItem('currentUserIdentifier');
      localStorage.removeItem('currentUserAlias');
      localStorage.removeItem('currentUserRoleId');
      localStorage.removeItem('currentUserRole');

      dispatch(logOutSuccess());
      //history.push('/login');
      history.go(0)
    });
  };
}

export const registerLoader = (dispatch, action, actionId) => {
  dispatch(loading(actionId));
  return action()
    .then((res) => {
      dispatch(loadingDone(actionId));
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch(loadingDone());
      return Promise.reject(err);
    });
};

export const showError = (dispatch, action, errorTitle = 'Unknown error') => action().catch((err) => {
  dispatch({ type: types.ERROR_REPORT, data: err, errorTitle });
  setTimeout(() => {
    dispatch({ type: types.ERROR_CLEAR });
  }, 7000);
  return Promise.reject(err);
});

export const combinedRegisterLoaderAndShowError = (dispatch, action, actionId, errorTitle) => showError(
  dispatch,
  () => registerLoader(dispatch, action, actionId),
  errorTitle,
);


export const getPlayersCount = (tournamentId) => (dispatch) => {
  restClient.get(`/player/tournament/${tournamentId}/count`).then((response) => {
    dispatch(getPlayersCountSuccess({ tournamentId, ...response.data }));
    return Promise.resolve(response);
  });
};

export const unsavedChanges = (message) => (dispatch) => {
  dispatch({ type: types.UNSAVED_CHANGES, data: { message } });
};

export const unsavedChangesClear = () => (dispatch) => {
  dispatch({ type: types.UNSAVED_CHANGES_CLEAR });
};

export const unsavedChangesShow = () => (dispatch) => {
  dispatch({ type: types.UNSAVED_CHANGES_SHOW });
};
