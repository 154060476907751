import { createBrowserHistory } from "history";
import { store } from "../store/configureStore";

const hist = createBrowserHistory({ forceRefresh: true });

// the purpose of this config is to select menu item, when application is opened by particular url
const urlNavlink = [
  {
    path: "^/$",
    navlink: "nlMttList"
  },
  {
    path: "^/mtt",
    navlink: ""
  },
  {
    path: "^/tmplmtt",
    navlink: "nlTemplate"
  },
  {
    path: "^/roomlayout",
    navlink: "nlPokerRoomLayout"
  },
  {
    path: "^/users",
    navlink: "nlUserManagement"
  },
  {
    path: "^/settings",
    navlink: "nlCasinoSettings"
  },
  {
    path: "^/players",
    navlink: "nlAdminPlayers"
  },
  {
    path: "^/template/mtt",
    navlink: "nlTemplateMtt"
  },
  {
    path: "^/template/structure",
    navlink: "nlTemplateStructure"
  },
  {
    path: "^/help",
    navlink: "nlHelp"
  },
  {
    path: "^/template/payout",
    navlink: "nlTemplatePayout"
  },
  {
    path: "^/template/detail/[0-9]+/",
    navlink: "nlTemplates"
  },
  {
    path: "^/tournament/[0-9]+/manageclock",
    navlink: "nlManageClock"
  },
  {
    path: "^/tournament/[0-9]+/manage/settings",
    navlink: "nlMttSettings"
  },
  {
    path: "^/tournament/[0-9]+/manage/structure",
    navlink: "nlStructure"
  },
  {
    path: "^/tournament/[0-9]+/manage/structuremobile",
    navlink: "nlStructureMobile"
  },
  {
    path: "^/tournament/[0-9]+/waitinglist",
    navlink: "nlWaitingList"
  },
  {
    path: "^/tournament/[0-9]+/notSeated",
    navlink: "nlNotSeated"
  },
  {
    path: "^/tournament/[0-9]+/players",
    navlink: "nlPlayers"
  },
  {
    path: "^/tournament/[0-9]+/newdayreseat",
    navlink: "nlReseat"
  },
  {
    path: "^/tournament/[0-9]+/logs",
    navlink: "nlLogs"
  },
  {
    path: "^/tournament/[0-9]+/chat",
    navlink: "nlMessages"
  },
  {
    path: "^/tournament/[0-9]+/payout",
    navlink: "nlPayout"
  },
  {
    path: "^/tournament/[0-9]+",
    navlink: "nlTables"
  },
];

hist.listen((location) => {
  const navlink = urlNavlink.find((item) => {
    const result = `${location.location.pathname}`.match(item.path);
    return result && result.length > 0;
  });

  store.dispatch({
    type: "NAVLINK",
    data: { id: navlink ? navlink.navlink : "nlMttList" }
  });
});

export const history = hist;
