import React, {useEffect, useState} from "react";
import {Redirect} from 'react-router-dom';
import currentUser from '../../util/currentUser';
import AdminPage from '../AdminPage';
import FloorPage from '../FloorPage';
import DealerPage from '../DealerPage';
import CashierPage from '../CashierPage';
import LoginPage from '../LoginPage';
import MttDirectorPage from '../MttDirectorPage';
import SpectatorPage from '../SpectatorPage';
import Loader from '../../components/shared/Loader';
import HeaderBoot from '../../components/shared/HeaderBoot';
import {prolongAuthToken} from '../../actions/commonActions';
import {useSelector} from "react-redux";
import moment from 'moment-mini';
import useConfig from '../../components/useConfig';


const PROLONG_AUTH_TOKEN_EVERY_MINUTES = 60 * 4;

const WithConnectionStateHeader = (props) =>{
    const userRole = currentUser.getUserRole();
    let page;
    switch (userRole) {
        case 'floor':
            page = <FloorPage/>;
            break;
        case 'mmtDirector':
        case 'mttDirector':
            page = <MttDirectorPage/>;
            break;
        case 'dealer':
            page = <DealerPage/>;
            break;
        case 'cashier':
            page = <CashierPage/>;
            break;
        case 'spectator':
            page = <SpectatorPage/>;
            break;
        default:
            return <Redirect to={{pathname: '/login'}}/>;
    }
    return <>
        <HeaderBoot />
        {page}
    </>
}

export const SecuredBasePage = () => {
    const lastLoginAt = useSelector(state => state.credentials.lastLoginAt);
    const [initialized, setInitalized] = useState(false);
    useConfig();
    useEffect(() => {
        const intervalRef = setInterval(() => {
            if (currentUser.isLoggedIn()) {
                prolongAuthToken();
            }
        }, PROLONG_AUTH_TOKEN_EVERY_MINUTES * 60 * 1000);
        if (currentUser.isLoggedIn() && (!lastLoginAt || moment.duration(moment().diff(moment(lastLoginAt))).asSeconds() > 5)) {
            prolongAuthToken()
                .finally(()=>{
                    setInitalized(true);
                })
        }else{
            setInitalized(true);
        }
        return () => {
            clearInterval(intervalRef);
        };
    }, []);

    if (!currentUser.isLoggedIn()) {
        return <LoginPage />
    } else if (initialized) {
        const userRole = currentUser.getUserRole();
        switch (userRole) {
            case 'admin':
                return <AdminPage/>;
            case 'floor':
            case 'mmtDirector':
            case 'mttDirector':
            case 'dealer':
            case 'cashier':
            case 'spectator':
                return <WithConnectionStateHeader />;
            default:
                return <LoginPage />
        }
    }else{
        return <Loader/>
    }


};
