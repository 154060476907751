import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as commonAction from '../../actions/commonActions';
import socket from '../../service/EvSocket';

const Logout = (props) => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(commonAction.logOut(socket));
  };

  return (
    <Button icon="log out" className={props.className} onClick={() => logout()}>
      Logout
    </Button>
  );
};

export default Logout;
