import React, { useEffect } from 'react';
import { NavLink as ReactStrapNavLink, NavbarToggler, DropdownToggle } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {UNSAVED_CHANGES_SHOW} from "../../actiontypes/actionTypes";

const NavLink = (props) => {
  const dispatch = useDispatch();
  const lastMenuItemId = useSelector((state) => state.component.lastMenuItemId);
  const unsavedWarning = useSelector((state) => state.common.unsavedWarning);

  // when particular page is open and navlink is in "Collapse", it's not enough when we just mark selected Navlink, we have to
  // open parent "Collapse" too
  if (lastMenuItemId === props.id && props.opendropdown) {
    props.opendropdown();
  }

  // opendropdown would cause error in console log
  const copyProps = {...props}
  delete copyProps.opendropdown

  return (
    <ReactStrapNavLink
      {...copyProps}
      className={`custom-navlink ${props.id && lastMenuItemId === props.id ? 'selected ' : ' '} ${
        props.isopen ? 'isOpen ' : ''
      } ${props.isdisabled ? 'disabled' : ''}`}
      onClick={(e) => {

        let dispatchData = null
        if((lastMenuItemId !== props.id || props.id === 'nlTables' || props.id === 'nlReseat') && !props.isdropdownparent) {
          dispatchData = {
            type: 'NAVLINK',
            data: { id: props.id }
          }
        }

        if(unsavedWarning && unsavedWarning.message) {
          dispatch({
            type: UNSAVED_CHANGES_SHOW,
            dispatchData: dispatchData || {},
            //action: () => {props.onClick(e)}
          });
          return
        }


        if (lastMenuItemId !== props.id || props.id === 'nlTables' || props.id === 'nlReseat') {
          if (!props.isdropdownparent) {
            dispatch(dispatchData);
          }
          props.onClick(e);
        }
      }}
    >
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        {props.children}
        {props.isdropdownparent && (
          <FontAwesomeIcon
            style={{ marginLeft: 'auto' }}
            icon={props.isOpen ? faChevronUp : faChevronDown}
          />
        )}
      </div>
    </ReactStrapNavLink>
  );
};

export default NavLink;
