import React, {useState} from "react";
import StateVariantRegistration from "../../../../enum/StateVariantRegistration";
import {formatDuration, resolveRegistrationState} from "../../../../util/tournamentUtil";
import { Col, Row } from "react-bootstrap";

const TileNotes = ({ variant }) => {

    let curLevel = variant.state === "created" ? {} : (variant.currentLevel || {})

    const isClockPaused = variant.state === "paused";
    const registrationState = resolveRegistrationState(variant);
    const isRegistrationClosed = registrationState.state === StateVariantRegistration.RUNNING_REGISTRATION;

    const note = []

    if (isClockPaused || isRegistrationClosed) {
        if (isClockPaused) {
            note.push("CLOCK IS PAUSED");
        }
    }

    // end of registration
    if (variant.registrationCloseAtLevel > 0 && registrationState.state !== StateVariantRegistration.REGISTRATION_CLOSED) {
        if (
            variant.registrationCloseAtLevel > 0 &&
            isRegistrationClosed &&
            curLevel.level === variant.registrationCloseAtLevel &&
            curLevel.type === "break" &&
            registrationState.numSecTillEnd <= 0
        ) {
            note.push("REGISTRATION IS CLOSED");
        }
    }

return (
    <div className="item notes">
        {note.map((each) => (
            <span key={each} className="note">
                {each}
              </span>
        ))
        }
    </div>

)

}

export default TileNotes;