import moment from 'moment-mini';
import { resolveCurrentLevel } from '../util/tournamentUtil';

class VariantLevelService {
  constructor() {
    if (VariantLevelService.instance) {
      return VariantLevelService.instance;
    }
    VariantLevelService.instance = this;
    this.timers = {};
    return this;
  }

  resolveCurrentLevel(tournamentVariant) {
    return resolveCurrentLevel(tournamentVariant);
  }

  registerLevelChangeHandler(tournamentVariant, handler) {
    const updatedtournamentVariant = resolveCurrentLevel(tournamentVariant);
    this._registerLevelChangeHandler(updatedtournamentVariant, handler);
    return updatedtournamentVariant;
  }

  _registerLevelChangeHandler(tournamentVariant, handler) {
    this.unregisterLevelChangeHandler(tournamentVariant);
    if (tournamentVariant.state === 'active') {
      const { currentLevel } = tournamentVariant;
      if(!currentLevel) {
        return
      }

      const endOfCurrent = currentLevel.mEndAt;
      const deplay = endOfCurrent.diff(moment()) + 1;
      if (deplay > 1) {
        const timerId = setTimeout(() => {
          const updatedTournament = resolveCurrentLevel(tournamentVariant);
          delete this.timers[tournamentVariant.id.toString()];
          handler(updatedTournament);
          this._registerLevelChangeHandler(updatedTournament, handler);
        }, deplay);
        this.timers[tournamentVariant.id.toString()] = timerId;
      }
    }
  }

  unregisterLevelChangeHandler(tournamentVariant) {
    if (tournamentVariant.id) {
      const timerId = this.timers[tournamentVariant.id.toString()];
      clearInterval(timerId);
      delete this.timers[tournamentVariant.id.toString()];
    }
  }
}
const variantLevelService = new VariantLevelService();
export default Object.freeze(variantLevelService);
