import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import TableActivity from "../../../enum/TableActivity";
import Rooms from "./Rooms";
import ExpansibleRoomSpaceGrid from "../../functionalComponents/ExpansibleRoomSpaceGrid";
import { getRooms, saveRooms, deleteRoom } from "../../../actions/roomActions";
import { Room } from "../../../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AddItemModal from "../../modals/AddItemModal";
import ConfirmationModal from "../../modals/ConfirmationModal";

class RoomLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableActivity: TableActivity.NONE,
      openPokerTableModal: false,
      openConfirmationModal: false,
      openSaveConfirmationModal: false,
      openInfoModal: false,
      currentTables: [],
      selectedTable: {},
      activeRoom: undefined,
      coor: {},
    };
  }

  componentDidMount() {
    this.props.getRooms().then(() => {
      if (this.props.rooms.length === 1) this.setActiveRoom(this.props.rooms[0]);
    });
  }

  // ROOMS
  roomTablesHasChanged() {
    if (!this.state.activeRoom) return false;
    var areIdentical =
      JSON.stringify(this.state.currentTables) === JSON.stringify(this.state.activeRoom.tables);
    return this.state.currentTables === [] || areIdentical ? false : true;
  }

  saveRoom(roomName) {
    var newRoom = { roomName: roomName, tables: [] };
    this.props.saveRooms([newRoom]);
  }

  deleteRoom() {
    this.props.deleteRoom(this.state.activeRoom.id);
    this.setState({ activeRoom: undefined });
  }

  setActiveRoom(room) {
    this.setState({ activeRoom: room });
    if (room !== undefined) {
      this.setState({ currentTables: JSON.parse(JSON.stringify(room.tables)) });
    } else {
      this.setState({ grid: [], currentTables: [] });
    }
  }
  // ROOMS END

  //TABLES
  isTableNameUnique(number) {
    var nonActiveRooms = this.props.rooms.filter((room) => {
      return room.id !== this.state.activeRoom.id;
    });
    var nameExistsInCurrentTempRoom = this.state.currentTables.find(
      (table) => table.tableName === number && table.active !== false
    );
    var nameExistsInOtherRooms = nonActiveRooms.find((room) => {
      return room.tables.find((table) => table.tableName === number);
    });

    var nameExists =
      nameExistsInCurrentTempRoom !== undefined || nameExistsInOtherRooms !== undefined;

    if (!number || number === "" || nameExists) {
      return false;
    }

    return true;
  }

  tableHasRegistration() {
    if (this.state.selectedTable === "" || !this.state.selectedTable.tournamentVariant) {
      return false;
    }

    const tableToBeDeleted = this.state.currentTables.find(
      (table) => table.tableName === this.state.selectedTable.tableName
    );

    return (tableToBeDeleted.tournamentVariant || {}).id > 0;
  }

  addPokerTableToList(value) {
    var pokerTable = {
      xCoordinate: this.state.coor.x,
      yCoordinate: this.state.coor.y,
      tableName: value,
      tournamentVariant: [],
    };

    this.setState({ currentTables: [...this.state.currentTables, pokerTable] });
  }

  removeTableFromList() {
    var tableToBeDeletedFc = (table) => table.tableName === this.state.selectedTable.tableName;
    var index = this.state.currentTables.findIndex(tableToBeDeletedFc);

    if (index >= 0) {
      var tableToBeDeleted = this.state.currentTables[index];
      if (tableToBeDeleted.id) {
        var altered = this.state.currentTables.map((table) => {
          if (table.id === tableToBeDeleted.id) {
            return { ...table, active: false };
          }
          return table;
        });
        this.setState({ currentTables: altered });
      } else {
        this.setState({
          currentTables: this.state.currentTables.filter(
            (t) => t.tableName !== this.state.selectedTable.tableName
          ),
        });
      }
    }
  }

  tableAction() {
    if (this.state.tableActivity === TableActivity.NONE) return;

    if (this.state.tableActivity === TableActivity.ADD) {
      if (this.state.selectedTable.tableName) {
        this.setState({ openInfoModal: true });
        return;
      }
      this.setState({ openPokerTableModal: true });
    }

    if (this.state.tableActivity === TableActivity.DELETE) {
      if (this.state.selectedTable === "") {
        return;
      }
      if (this.tableHasRegistration()) {
        this.setState({ openConfirmationModal: true });
      } else {
        this.removeTableFromList();
      }
    }
  }

  onTableClick(table, i, j) {
    this.setState({ selectedTable: table, coor: { x: i, y: j } }, () => {
      this.tableAction();
    });
  }

  setTableStyle(table) {
    const baseStyle = "poker-table ";
    if (table.tableName === undefined) {
      return baseStyle + "noTable";
    }

    return baseStyle + "base-table";
  }
  // TABLES END

  //COMMON
  onSaveButtonClick() {
    this.setState(
      {
        activeRoom: { ...this.state.activeRoom, tables: this.state.currentTables },
        openSaveConfirmationModal: true,
      },
      () => {
        this.props.saveRooms([this.state.activeRoom]).then((res) => {
          let activeRoomTmp = res.data.find((r) => r.id === this.state.activeRoom.id);
          this.setActiveRoom(activeRoomTmp);
        });
      }
    );
  }

  saveSwitchRoom = () => {
    return this.props
      .saveRooms([{ ...this.state.activeRoom, tables: this.state.currentTables }])
      .then((res) => {
        return Promise.resolve();
      });
  };

  onResetButtonClick() {
    this.setActiveRoom(this.state.activeRoom);
  }
  //COMMON END

  render() {
    return (
      <>
        <Row>
          <Col>
            <h2>{"Poker room layout"}</h2>
          </Col>
        </Row>
        <Row>
          <div className="line" />
        </Row>
        <Row style={{ margin: "30px 0 120px 0" }}>
          <Col sm={3}>
            <Row>
              <div>
                <Button
                  id="add"
                  style={{ margin: "0 0 12px 0", width: "95%" }}
                  variant={
                    this.state.tableActivity === TableActivity.ADD ? "primary" : "outline-primary"
                  }
                  onClick={() => {
                    this.setState({ tableActivity: TableActivity.ADD });
                  }}
                >
                  Add table
                </Button>
                <Button
                  id="delete"
                  style={{ margin: "0 0 12px 0", width: "95%" }}
                  variant={
                    this.state.tableActivity === TableActivity.DELETE
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => {
                    this.setState({ tableActivity: TableActivity.DELETE });
                  }}
                >
                  Delete table
                </Button>
              </div>
            </Row>
            <div>Created rooms:</div>
            <Row>
              <Rooms
                saveRoom={this.saveRoom.bind(this)}
                saveSwitchRoom={this.saveSwitchRoom.bind(this)}
                deleteRoom={this.deleteRoom.bind(this)}
                rooms={this.props.rooms}
                setActiveRoom={this.setActiveRoom.bind(this)}
                roomTablesHasChanged={this.roomTablesHasChanged.bind(this)}
                saveChanges={this.onSaveButtonClick.bind(this)}
                activeRoom={this.state.activeRoom}
              />
            </Row>
            <Row style={{ margin: "30px 3px 3px 0px" }}>
              <div className="table-legend-filled" />
              <label>- Created tables</label>
            </Row>
            <Row style={{ margin: "10px 3px 3px 0px" }}>
              <div className="table-legend-empty" />
              <label>- Empty space</label>
            </Row>
          </Col>
          {this.props.rooms.length === 0 && (
            <Col sm={6} className="info-label">
              You need to create poker room first
            </Col>
          )}
          {this.props.rooms.length > 1 && this.state.activeRoom === undefined && (
            <Col sm={6} className="info-label">
              Please select the room
            </Col>
          )}
          <Col
            style={{
              visibility: this.state.activeRoom === undefined ? "hidden" : "visible",
              margin: "2px",
            }}
          >
            <Row
              style={{
                visibility: this.state.tableActivity === TableActivity.NONE ? "hidden" : "visible",
              }}
            >
              <div className="info-text">
                <FontAwesomeIcon className={"info-icon"} icon={faInfoCircle} />
                {this.state.tableActivity === TableActivity.ADD
                  ? "Click to add tables"
                  : "Click to delete tables"}
              </div>
            </Row>
            <Row style={{ margin: "0 0 20px 0" }}>
              <ExpansibleRoomSpaceGrid
                tables={this.state.currentTables}
                onTableClick={this.onTableClick.bind(this)}
                tableStyle={this.setTableStyle.bind(this)}
              />
            </Row>
          </Col>
        </Row>
        <div className="footer-panel">
          <Row>
            <Button
              className="confirm-btn m-auto"
              variant="outline-primary"
              style={{ width: "20%" }}
              onClick={() => this.onResetButtonClick()}
            >
              Cancel Changes
            </Button>
            <Button
              className="confirm-btn m-auto"
              style={{ backgroundColor: "green", width: "20%" }}
              onClick={() => this.onSaveButtonClick()}
            >
              Save Changes
            </Button>
          </Row>
        </div>
        <AddItemModal
          inputLabel="Table number"
          inputPlaceholder="Type table number"
          buttonText="Save table number"
          open={this.state.openPokerTableModal}
          close={() => this.setState({ openPokerTableModal: false })}
          onButtonClick={this.addPokerTableToList.bind(this)}
          isValid={this.isTableNameUnique.bind(this)}
          validationText={"Table with the same number already exists"}
        />
        <ConfirmationModal
          confirmationText={
            "Can not delete table " +
            this.state.selectedTable.tableName +
            ". The table has tournament registration"
          }
          buttonText="Ok"
          open={this.state.openConfirmationModal}
          close={() => this.setState({ openConfirmationModal: false })}
          onSubmitButtonClick={() => this.setState({ openConfirmationModal: false })}
        />
        <ConfirmationModal
          confirmationText={"Your changes have been saved!"}
          buttonText="Ok"
          open={this.state.openSaveConfirmationModal}
          close={() => this.setState({ openSaveConfirmationModal: false })}
          onSubmitButtonClick={() => this.setState({ openSaveConfirmationModal: false })}
        />
        <ConfirmationModal
          confirmationText={"To add new table you need to firstly delete current table"}
          buttonText="Ok"
          open={this.state.openInfoModal}
          close={() => this.setState({ openInfoModal: false })}
          onSubmitButtonClick={() => this.setState({ openInfoModal: false })}
        />
      </>
    );
  }
}

RoomLayout.propTypes = {
  getRooms: PropTypes.func.isRequired,
  saveRooms: PropTypes.func.isRequired,
  deleteRoom: PropTypes.func.isRequired,
};

RoomLayout.defaultProps = {
  rooms: [],
};

RoomLayout.propTypes = {
  rooms: PropTypes.arrayOf(Room),
};

const mapStateToProps = (state) => {
  return {
    rooms: state.rooms.rooms,
  };
};

const mapDispatchToProps = {
  getRooms,
  saveRooms,
  deleteRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomLayout);
