import React, { useState, useEffect } from "react";
import GenericSpaceGrid from "./GenericSpaceGrid";

export default function RoomSpaceGrid(props) {
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    if (props.tables) {
      const newGrid = setRoomSpace(props.tables);
      setGrid(newGrid);
    }
  }, [props.tables]);

  const setRoomSpace = (tables) => {
    const gridflat = grid.flat();
    const isNewRoom = !gridflat.some(
      (g) => g !== {} && props.tables[0] && g.roomId === props.tables[0].roomId
    );

    if (tables.length > 0) {
      const activeTables = tables.filter(
        (table) => table.active === undefined || table.active === true
      );

      const xCoor = Math.max(
        isNewRoom ? 0 : grid.length,
        Math.max.apply(
          Math,
          activeTables.map((t) => t.xCoordinate)
        ) + 1
      );
      const yCoor = Math.max(
        isNewRoom ? 0 : grid[0].length,
        Math.max.apply(
          Math,
          activeTables.map((t) => t.yCoordinate)
        ) + 1
      );
      const gridTmp = Array(xCoor)
        .fill(0)
        .map((x) => Array(yCoor).fill({}));
      setTableInGrid(activeTables, gridTmp);

      return gridTmp;
    }
    return Array(1)
      .fill(0)
      .map((x) => Array(1).fill({}));
  };

  const setTableInGrid = (tables, grid) => {
    tables.map((table) => {
      grid[table.xCoordinate][table.yCoordinate] = table;
    });
  };

  const expandRoomSpace = (axis) => {
    if (axis === "y") {
      const expandedGrid = grid.map((element) => [...element, ""]);

      setGrid(expandedGrid);
    }
    if (axis === "x") {
      if (grid) {
        setGrid([...grid, new Array(grid[0].length).fill("")]);
      }
    }
  };

  const shrinkRoomSpace = (axis) => {
    if (axis === "x" && grid.length > 2) {
      if (!containsActiveTable(grid[grid.length - 1])) {
        const popped = grid.pop();
        setGrid(grid.filter((g) => g !== popped));
      }
    } else if (axis === "y" && grid[0].length > 2) {
      const lastColumn = [];
      grid.forEach((element) => {
        lastColumn.push(element[element.length - 1]);
      });

      if (!containsActiveTable(lastColumn)) {
        grid.map((element) => {
          const popped = element.pop();
          setGrid(grid.filter((g) => g !== popped));
        });
      }
    }
  };

  const containsActiveTable = (tables) => {
    if (tables.find((table) => table !== "")) {
      return true;
    }
    return false;
  };

  return (
    <GenericSpaceGrid
      {...props}
      grid={grid}
      expandRoomSpace={expandRoomSpace}
      shrinkRoomSpace={shrinkRoomSpace}
    />
  );
}
