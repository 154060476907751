import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ChatMsg from './ChatMsg';

class ChatHistory extends React.Component {
  render() {
    const messages = this.props.messages || [];
    return (
      <Col>
        <Row>
          <h5>Messages:</h5>
        </Row>
        <Row>
          <div className="border-top m-2 p-1">
            {messages.length > 0 ? (
              messages.map((message) => (
                <div key={message.id}>
                  <ChatMsg msg={message} toogle={this.toggle} />
                </div>
              ))
            ) : (
              <div>No chat messages</div>
            )}
          </div>
        </Row>
      </Col>
    );
  }
}

export default ChatHistory;
