import React, { useEffect, useState } from "react";

import VariantClock from '../shared/VariantClock';
import UpdatePlayerStateWrapper from '../shared/UpdatePlayerStateWrapper';
import useVariant from '../useVariant';
import { getClockSettings, } from "../../actions/tournamentActions";
import { useDispatch, useSelector } from "react-redux";
import RotatingTables from "./RotatingTables";

const SpectatorClock = (props) => {
    const dispatch = useDispatch();

    const variant = useVariant(props.variantId, 'spectatorClock');
    const clockSettings = useSelector((state) => state.tournaments.clockSettings);
    const [bgPreviewVersion, setBgPreviewVersion] = useState(1);

    let hasBg = false
    let style = {}
    if (clockSettings.data && clockSettings.data.bgFilename) {
        style.backgroundImage = `url(/images/${props.variantId}?${clockSettings.data.bgFilename})`
        hasBg = true
    }
    if (props.showBgPreview && clockSettings.data && clockSettings.data.bgPreviewFilename) {
        style.backgroundImage = `url("/images/${clockSettings.data.bgPreviewFilename}?${setBgPreviewVersion})`
        hasBg = true
    }

    useEffect(() => {
        dispatch(getClockSettings(variant.id));
        setBgPreviewVersion(bgPreviewVersion + 1)
    }, [variant.id, variant.fetchDataTime]);

    if (clockSettings.isLoaded && clockSettings.data.seating) {
        return <RotatingTables variant={variant} clockSettings={clockSettings} />;
    } else {
        return <div className={`clock ${hasBg ? 'bg' : ''}`} style={{ ...style }} >
            <VariantClock isFullScreen={true} variant={variant} tournamentId={props.tournamentId} hidePayouts={props.hidePayouts} showBgPreview={props.showBgPreview} />
        </div>
    }
};
export default SpectatorClock;
