import React from "react";
import { connect } from "react-redux";
import { getTournaments, notifyTournamentUpdate, notifyNewTournamentUpdate, notifyVariantUpdate,  cleanTournamentDetail } from "../../actions/tournamentActions";
import socket from "../../service/EvSocket";

class _getTournamentDataWrapper extends React.Component {
  componentDidMount() {
    if (this.props.isLoading !== true && this.props.tournamentsInitialized !== true) {
      this.props.getTournaments(this.props.activeTournaments);
    }
    socket.on("updateTournament", "GetTournamentDataWrapper", (payload, dispatch) => {
        this.props.notifyTournamentUpdate(payload);
    });

    socket.on("newTournament", "GetTournamentDataWrapper", (payload, dispatch) => {
        this.props.notifyNewTournamentUpdate(payload);
    });

    socket.on("updateTournamentVariant", "GetTournamentDataWrapper", (payload, dispatch) => {
        this.props.notifyVariantUpdate(payload);
    });
  }

  componentWillUnmount = () => {
    socket.removeHandler("updateTournament", "GetTournamentDataWrapper");
    socket.removeHandler("newTournament", "GetTournamentDataWrapper");
    this.props.cleanTournamentDetail();
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeTournaments !== prevProps.activeTournaments) {
      this.props.getTournaments(this.props.activeTournaments);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.globalError.isLoading,
    tournamentsInitialized: state.globalError.tournamentsInitialized,
  };
};

const mapDispatchToProps = {
  getTournaments,
  notifyTournamentUpdate,
  notifyNewTournamentUpdate,
  notifyVariantUpdate,
  cleanTournamentDetail
};

const InnerGetTournamentDataWrapper = connect(mapStateToProps, mapDispatchToProps)(_getTournamentDataWrapper);

const GetTournamentDataWrapper = (props) =>{
  return (<InnerGetTournamentDataWrapper {...props}>{props.children}</InnerGetTournamentDataWrapper>)
}
export default GetTournamentDataWrapper;

