import React from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import CustomModal from '../shared/CustomModal';

const DisplayPropertyModal = (props) => {
    return (
        <CustomModal
            open={props.open}
            closeHandler={props.toggle}
            className="property-modal"
            dialogClassName="modal-small-width"
        >
            <div>
                <label>{props.label}</label>
                <p>{props.value}</p>
            </div>
            {props.onButtonClick && <Button style={{width: '100%'}} onClick={() => props.onButtonClick()}>
                {props.buttonText}
            </Button>}
        </CustomModal>
    );
}

DisplayPropertyModal.propTypes = {
    inputLabel: PropTypes.string.isRequired,
    inputValue: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default DisplayPropertyModal;
