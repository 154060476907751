import React, { Component } from "react";
import ReseatIcon from "../image/icon-ev-logo.svg";

class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null, hasError: false };

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.hasError === true) {
      return (
        <div style={{ padding: "2rem" }}>
          <h2>
            <span style={{ color: "#5b38b9", fontWeight: "800" }}>
              <img src={ReseatIcon} alt="EV" style={{ height: "4rem" }} />
            </span>
            <span style={{ marginLeft: "3rem" }}>We are sorry, something went wrong...</span>
          </h2>
          <details open style={{ marginTop: "3rem" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo ? this.state.errorInfo.componentStack : ""}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
