import React, {useEffect, useState} from "react";
import {validateTournamentStructure} from "../../../util/validationUtil";
import {isMultiday} from "../../../util/tournamentUtil";
import {Input, Label} from "reactstrap";
import StructureContainer from "./StructureContainer";

const StructureWithStartingLevelContainer = (props) => {

    const [startingStructureLevel, setStartingStructureLevel] = useState(props.variant.startingStructureLevel);
    const [startingStructureLevelNotKnown, setStartingStructureLevelNotKnown] = useState(false);

    const updateVariant = (variant) => {
        const variantToUpdate = {...variant}
        if ((variantToUpdate.structure || []).length === 0 && variantToUpdate.startingStructureLevel >= 1) {
            variantToUpdate.structure = [{level: variantToUpdate.startingStructureLevel}];
        }

        if (props.updateTournamentDetail) {
            (props.tournament.tournamentPhases || [])
                .forEach(phase => {
                        phase.variants = (phase.variants || [])
                            .map(eachVariant => {
                                if ((eachVariant._id && eachVariant._id === variantToUpdate._id) || (eachVariant.id && eachVariant.id === variantToUpdate.id)) {
                                    return {...eachVariant, structure: variantToUpdate.structure, startingStructureLevel: variantToUpdate.startingStructureLevel};
                                } else {
                                    return {...eachVariant}
                                }
                            })
                    }
                )
            props.updateTournamentDetail(props.tournament);
        }
        if (props.updateVariant) {
            props.updateVariant(variantToUpdate);
        }
    }

    useEffect(() => {
        setStartingStructureLevel(props.variant.startingStructureLevel)
        setStartingStructureLevelNotKnown(props.variant.startingStructureLevel === null)
    }, [props.variant.id])

    const isMultiDay = isMultiday(props.tournament)
    if (isMultiDay) {
        return <>
            {!startingStructureLevelNotKnown && <>
                <Label for="startingStructureLevel">Day starts at level</Label>
                <Input
                    id="startingStructureLevel"
                    placeholder="Type level"
                    name="startingStructureLevel"
                    type="number"
                    invalid={!(startingStructureLevel > 0 || startingStructureLevelNotKnown)}
                    onChange={(e) => {
                        const startingStructureLevel = parseInt(e.target.value);

                        setStartingStructureLevel(startingStructureLevel);

                        const variant = {...props.variant};
                        variant.startingStructureLevel = startingStructureLevel;

                        // if (startingStructureLevel !== props.variant.startingStructureLevel) {

                        if(variant.structure && variant.structure.length > 0) {
                            if (startingStructureLevel < variant.structure[0].level) {
                                for (let count = startingStructureLevel; count < variant.structure[0].level; count++) {
                                    variant.structure.splice(count, 0, {level: count});
                                }
                            } else if (startingStructureLevel > variant.structure[0].level) {
                                const numOfItemDelete = startingStructureLevel - variant.structure[0].level;

                                if (!variant.structureDeleted) {
                                    variant.structureDeleted = [];
                                }
                                for (let count = 0; count < numOfItemDelete; count++) {
                                    const toDelete = variant.structure.shift();
                                    if(toDelete && toDelete.id) {
                                        variant.structureDeleted.push(toDelete.id);
                                    }
                                }
                            }
                        }
                        // }

                        updateVariant(variant)
                    }}
                    value={startingStructureLevel}
                />
            </>}
            <div>
                <input type="checkbox" className="startingStructureLevelNotKnown"
                       checked={startingStructureLevelNotKnown}
                       onClick={(e, a, b) => {
                           const newValue = e.target.checked;
                           setStartingStructureLevelNotKnown(newValue)

                           const variant = {...props.variant};
                           variant.startingStructureLevel = null;

                           let structure = [];
                           if (newValue) {
                               structure = [];
                           } else {
                               structure = [{level: 1}];
                           }
                           variant.structure = structure;
                           updateVariant(variant);
                       }}/>
                <Label for="startingStructureLevelNotKnown" className="pl-3">I don't know yet</Label>
            </div>

            {!startingStructureLevelNotKnown &&
                <StructureContainer startingStructureLevel={props.variant.startingStructureLevel ?? 1} {...props}
                                    updateVariant={updateVariant}/>}
            {startingStructureLevelNotKnown &&
                <div className="pt-3">This day starts at unknown level at the moment. Please enter the structure for
                    this day later in Manage&nbsp;MTT&nbsp;/&nbsp;Structure</div>}
        </>
    } else {
        return <StructureContainer startingStructureLevel={1} {...props} updateVariant={updateVariant}/>
    }
}

StructureWithStartingLevelContainer.getValidationErrors = (tournament, variant) => {
    if (variant.structure.length === 0) {
        return [];
    }
    const structureErrors = validateTournamentStructure(variant.structure);
    const startingStructureLevelErrors = [];
    if (isMultiday(tournament)) {
        const isStartingStructureLevelValidNumber = variant.startingStructureLevel > 0 && Number.isInteger(variant.startingStructureLevel);
        if (isStartingStructureLevelValidNumber) {
            const isOutOfRange = !variant.structure.some(structure => {
                return structure.level >= variant.startingStructureLevel;
            });
            if (isOutOfRange) {
                startingStructureLevelErrors.push({'isOutOfRange': true});
            }
        } else {
            startingStructureLevelErrors.push({'invalidNumber': true});
        }
    }
    return structureErrors.concat(startingStructureLevelErrors);
};

export default StructureWithStartingLevelContainer;