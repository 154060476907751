import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button, Input, Form, FormGroup, Label,
} from 'reactstrap';
import Role from '../../enum/UserRole';
import { addUser } from '../../actions/usersActions';
import CustomModal from '../shared/CustomModal';
import { isEmailValid, getPasswordValidator } from '../../util/stringUtil';

const AddNewUserModal = (props) => {
  const dispatch = useDispatch();

  const [identifier, setIdentifier] = useState('');
  const [alias, setAlias] = useState('');
  const [type, setType] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (ev) => {
    ev.preventDefault();
    dispatch(
      addUser(
        {
          identifier: identifier,
          alias: alias,
          role: type,
          password: password,
        },
        true,
      ),
    );
    clearForm();
    props.toggle();
  };

  const clearForm = () => {
    setIdentifier('');
    setAlias('');
    setType('');
    setPassword('');
  };

  const isUsernameValid = !isEmailValid(identifier);
  const isPasswordValid = !getPasswordValidator().validate(password);
  const isSaveDisabled = () => !!(password.localeCompare(confirmPassword) !== 0 || isPasswordValid || identifier === '' || type === '' || isUsernameValid);

  return (
    <CustomModal open closeHandler={() => props.setShowAddUserModal(false)} title="Add new user" className="add-account register-modal" dialogClassName="modal-width">
      <>
        <Form>
          <FormGroup>
            <Label for="identifierElm">Screen name: </Label>
            <Input id="identifierElm" placeholder="Screen name" name="identifier" onChange={(e) => setAlias(e.target.value)} value={alias} />
          </FormGroup>
          <FormGroup>
            <Label for="identifierElm">Email as login: </Label>
            <Input id="identifierElm" disabled={props.user} placeholder="Email as login" name="identifier" onChange={(e) => setIdentifier(e.target.value)} value={identifier} />
            {identifier && isUsernameValid && <small className="validation-text">Enter valid email</small>}
          </FormGroup>
          <FormGroup>
            <Label for="typeElm">Type of user: </Label>
            <Input id="typeElm" type="select" placeholder="Type of user" name="type" onChange={(e) => setType(e.target.value)} value={type}>
              <option />
              {Object.values(Role).filter(r => r !== Role.SPECTATOR && r !== Role.ADMIN).map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="passwordElm1">Password: </Label>
            <Input id="passwordElm1" type="password" placeholder="Password" name="password1" onChange={(e) => setPassword(e.target.value)} />
            {password && isPasswordValid && <small className="validation-text">Passwrod length must be 6-20 and contains number</small>}
          </FormGroup>
          <FormGroup>
            <Label for="passwordElm2">Confirmation password: </Label>
            <Input id="passwordElm2" type="password" placeholder="Confirmation Password" onChange={(e) => setConfirmPassword(e.target.value)} name="password2" />
          </FormGroup>
        </Form>
        <div className="w-50">
          <Button color="primary" onClick={(e) => handleSubmit(e)} disabled={isSaveDisabled()}>
            Save user
          </Button>
          <Button color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
        </div>
      </>
    </CustomModal>
  );
};

export default AddNewUserModal;
