import React, {useState} from "react";

const TilePlayers = ( { tournamentStats } ) => {

    const numOfPlayers = tournamentStats.numOfPlayers > 0 ? tournamentStats.numOfPlayers : 0;
    const numOfFinishedPlayers = tournamentStats.numOfFinishedPlayers > 0 ? tournamentStats.numOfFinishedPlayers : 0;

    return (
        <div className="item">
            <div className="title">Players</div>
            <div className="text">{`${tournamentStats.numOfPlayers !== undefined ? numOfPlayers : ""
            } / ${tournamentStats.numOfPlayers !== undefined ? numOfPlayers + numOfFinishedPlayers : ""
            }`}</div>
        </div>
    )

}

export default TilePlayers;