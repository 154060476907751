import React from "react";
import { Col, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import Structure from "../tournamentStructure/Structure";
import { isValidByValidationResults, validateTournamentStructure} from "../../../util/validationUtil";

const TemplateStructureDetail = (props) => {
  const template = props.template || [];
  const getEmptyStructureEntry = (level, levelLength) => ({
    ante: 0,
    bigBlind: 0,
    durationInMin: levelLength ? levelLength : 0,
    level,
    smallBlind: 0,
  });

  const addLevel = (addAfterLevelIndex = -1) => {
    const addAfterLevel = addAfterLevelIndex + 1;
    const levelToAdd = addAfterLevel + 1;
    const index = template.findIndex((e) => e.level === levelToAdd);
    const levelLength = template[addAfterLevelIndex] ?  template[addAfterLevelIndex].durationInMin : 60;
    if (index >= 0) {
      template.splice(index, 0, getEmptyStructureEntry(levelToAdd, levelLength));
      template.forEach((each, eachIndex) => {
        each.level = eachIndex + 1;
      });
    } else {
      template.push(getEmptyStructureEntry(levelToAdd, levelLength));
    }
    props.onChange(template, props.originalName, isValidByValidationResults(validateTournamentStructure(template)));
  };

  // add initial level if empty
  if (template.length === 0) {
    addLevel();
  }

  return (
    <div className="p-5">
      <Row>
        <Col>
          <h2>{props.title}</h2>
        </Col>
      </Row>
      <div className="graySeparator" />
      <Row className="pt-2 pb-2">
        <Col xs={2}>
          <span className="mr-2">Structure template name </span>
          {props.isCreateMode && (
            <Input
              type="text"
              className="highlighted black-input d-inline"
              value={template.name}
              onChange={(e) => props.setupName(e.target.value)}
            />
          )}
          {!props.isCreateMode && (
            <Input
              type="text"
              className="highlighted black-input d-inline"
              value={props.templateName}
              defaultValue={props.originalName}
              onChange={(e) => {
                props.setupName(e.target.value);
                props.onChange(props.template, e.target.value, isValidByValidationResults(validateTournamentStructure(template)));
              }}
            />
          )}
        </Col>
      </Row>

      <div className="graySeparator" />
      <Structure
        isTemplateMode={props.isTemplateMode}
        structures={template}
        handleChange={(level, field, value) => {
          template[level][field] = value;
          props.onChange(template, props.originalName, isValidByValidationResults(validateTournamentStructure(template)));
        }}
        addLevel={(addAfterLevelIndex) => addLevel(addAfterLevelIndex)}
        deleteLevel={(levelToDelete) => {
          template.splice(levelToDelete, 1);
          props.onChange(template, props.originalName, isValidByValidationResults(validateTournamentStructure(template)));
        }}
        deleteBreak={(level) => {
          level.breakInMin = null;
          level.requireConfirmOnStart = false;
          props.onChange(template, props.originalName, isValidByValidationResults(validateTournamentStructure(template)));
        }}
        saveBreak={(level, breakInMin) => {
          level.breakInMin = breakInMin;
          props.onChange(template, props.originalName, isValidByValidationResults(validateTournamentStructure(template)));
        }}
      />
    </div>
  );
};
export default TemplateStructureDetail;
