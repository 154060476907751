import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import * as JsSearch from "js-search";
import { getPlayersByTournamentVariantIdState, getPlayersClean, } from "../../../actions/playerActions";
import PlayerCard from "./PlayerCard";
import VariantPicker from "../../shared/VariantPicker";
import UpdatePlayerStateWrapper from "../../shared/UpdatePlayerStateWrapper";
import Loader from "../../shared/Loader";
import PlayerState from "../../../enum/PlayerState";
import PlayerDetail from "./PlayerDetail";
import PlayerActionsModal from "../../shared/PlayerActionsModal";
import { getPlayerState } from "../../../util/playerUtil";
import { history } from "../../../service/history";
import BottomNavbarMobile from "../../shared/BottomNavbarMobile";
import { isMobile } from 'react-device-detect';

const filterValues = [
    { key: "ALL", value: "All players" } /* all except ureg */,
    { key: "PLAYING", value: PlayerState.PLAYING.label },
    { key: "FINISHED", value: PlayerState.FINISHED.label },
    { key: "NOT_SEATED", value: PlayerState.NOT_SEATED.label },
    { key: "WAITING_LIST", value: PlayerState.WAITING_LIST.label },
    { key: "UNREGISTERED", value: PlayerState.UNREGISTERED.label },
    { key: "UNREGISTERING", value: PlayerState.UNREGISTERING.label },
    { key: "DQ", value: "DQ" },
    { key: "GIVE_UP", value: PlayerState.GIVE_UP.label },
];

const Players = ({ playersInTournamentState, filteredPlayers, variant, selectPlayer, redirectToTable, mode }) => {
    const hideGoToTable = mode === 'seatingByFloorman'
    if (playersInTournamentState.error) {
        return <span>{JSON.stringify(playersInTournamentState.error)}</span>;
    }
    if (playersInTournamentState.isInitialized) {
        if (filteredPlayers.length <= 0) {
            return <h1 style={{ textAlign: "center" }}>No players...</h1>;
        }

        return (
            <UpdatePlayerStateWrapper>
                {filteredPlayers.map((player) => (
                    <PlayerCard key={`player_card_${player.playerInTournamentPhaseVariantId}`} player={player} hideGoToTable={hideGoToTable}
                        variant={variant} clickOnMoreAction={selectPlayer} clickOnGoToTable={redirectToTable} />
                ))}
            </UpdatePlayerStateWrapper>
        );
    }

    return <Loader />;
};


const PlayersFloor = (props) => {
    const tournament = useSelector((state) => state.tournaments.tournament);
    return (
        <VariantPicker tournament={tournament} id="PlayersFloor" getContent={(variant) => {
            return <Content
                id={props.id}
                variant={variant}
                parent={props.parent}
                mode={props.mode}
                hideStateFilter={props.hideStateFilter}
                defaultState={props.state || "ALL"}
            />
        }} />
    );
};

function getFilteredPlayers(filter, players) {
    let filteredPlayers =
        filter.state === "ALL"
            ? players.filter((player) => player.stateForFE !== "UNREGISTERED")
            : players.filter((player) => player.stateForFE === filter.state);
    const numOfAllPlayersInFilter = filteredPlayers.length;

    if (filter.name) {

        // there could be one player twice, in two different states, ie finished and playing (after reentry)
        // for proper search we need to generate new unique id
        const search = new JsSearch.Search("id2");
        filteredPlayers.forEach((p, i) => p.id2 = (i + 1))

        search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        search.addIndex("firstName");
        search.addIndex("lastName");
        search.addDocuments(filteredPlayers);
        filteredPlayers = search.search(filter.name);
    }

    return { filteredPlayers, numOfAllPlayersInFilter };
}

const Content = ({ parent, id, mode, variant, hideStateFilter, defaultState }) => {
    const tournamentVariantId = variant ? variant.id : null;
    const tournamentId = variant ? variant.tournamentId : null;
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ name: "", state: defaultState });
    const [selectedPlayerId, setSelectedPlayerId] = useState(null);
    const playersInTournamentState = useSelector((state) => state.player.playersInTournament);
    const allPlayers = playersInTournamentState.data.map((player) => ({
        ...player,
        stateForFE: getPlayerState(player),
    }));
    const { filteredPlayers, numOfAllPlayersInFilter } = getFilteredPlayers(filter, allPlayers);

    function shouldReloadPlayersList() {
        if (
            playersInTournamentState.error &&
            tournamentVariantId === playersInTournamentState.tournamentVariantId
        ) {
            return false;
        }
        return (
            tournamentVariantId &&
            ((!playersInTournamentState.isLoading && !playersInTournamentState.isInitialized) ||
                tournamentVariantId !== playersInTournamentState.tournamentVariantId)
        );
    }

    function reloadPlayers() {
        dispatch(getPlayersByTournamentVariantIdState(tournamentId, tournamentVariantId));
    }

    // clean players onMount and nUnMount
    useEffect(() => {
        dispatch(getPlayersClean());
        return () => {
            dispatch(getPlayersClean());
        };
    }, []);

    useEffect(() => {
        if (shouldReloadPlayersList()) {
            reloadPlayers();
        }
    });

    const selectPlayer = (player) => {
        setSelectedPlayerId(player ? player.playerInTournamentPhaseVariantId : null);
    };

    function onClosePlayerDetail() {
        selectPlayer(null);
        reloadPlayers();
    }
    const redirectToTable = (player) => {
        const redirectUrl = `/tournament/${variant.tournamentId}/variant/${variant.id}/table/${player.tableId}`;
        history.push(redirectUrl);
    }

    const selectedPlayer = (allPlayers || []).find(
        (each) => each.playerInTournamentPhaseVariantId === selectedPlayerId
    );
    return (
        <div id="players">
            <Row>
                <Col className="header">
                    <h3 className="left">{`${mode === "seatingByFloorman" ? "Seating by floorman" : "Players"
                        } ${filteredPlayers.length} / ${numOfAllPlayersInFilter}`}</h3>

                    {hideStateFilter === "false" && (
                        <Dropdown className="mtt-ddm">
                            <Dropdown.Toggle as="div" className="form-control">
                                <h3>{filterValues.find((f) => f.key === filter.state).value}</h3>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {filterValues.map((filterItem) => (
                                    <Dropdown.Item
                                        as="div"
                                        id={`item_${filterItem.key}`}
                                        style={{ filter }}
                                        key={filterItem.key}
                                        onClick={() => {
                                            setFilter({ ...filter, state: filterItem.key });
                                        }}
                                    >
                                        {filterItem.value}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={5}>
                    <Input
                        id="txtSearchByName"
                        className="searchByName"
                        placeholder="search by name, i.e. John Doe"
                        name="txtSearchByName"
                        onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                    />
                </Col>
            </Row>
            <Row className="pb-5">
                <Col className="table">
                    <Players playersInTournamentState={playersInTournamentState} filteredPlayers={filteredPlayers} mode={mode}
                        variant={variant} selectPlayer={selectPlayer} redirectToTable={redirectToTable} />
                </Col>
            </Row>
            {isMobile && <BottomNavbarMobile id={id ? id : "players"} tournamentUrl={"/tournament/" + variant.tournamentId} />}
            {selectedPlayer &&
                (selectedPlayer.stateForFE === PlayerState.PLAYING.key ? (
                    <>
                        <PlayerActionsModal
                            isOpen
                            player={selectedPlayer}
                            toggle={onClosePlayerDetail}
                            players={allPlayers || {}}
                            variant={variant}
                        />
                    </>
                ) : (
                    <PlayerDetail
                        player={selectedPlayer}
                        closeAction={onClosePlayerDetail}
                        variant={variant}
                        players={allPlayers}
                    />
                ))}
        </div>
    );
};

export default PlayersFloor;
