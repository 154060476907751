import React, {useRef, useState} from "react";
import CustomModal from "../../shared/CustomModal";
import {ReactComponent as DqIcon} from "../../../image/icon-DQ.svg";
import {getTournamentVariantTitle} from "../../../util/tournamentUtil";
import {Input} from "reactstrap";
import {Button} from "react-bootstrap";

const FinishConfirmationModal = ({open, toggle, variant, title, onFinish}) => {
    const [isFinishBtnDisabled, setIsFinishBtnDisabled] = useState(true);

    const confirmTextRef = useRef(null);
    const finishBtn = useRef(null);
    const {tournament} = variant;

    function confirmTextOnChange() {
        setIsFinishBtnDisabled(confirmTextRef.current.value.toLowerCase() !== confirmText);
    }

    function generateConfirmText() {
        const colors = ["red", "blue", "pink", "green", "black"];
        const animals = ["dog", "cat", "rat", "frog", "shark", "ant", "fly", "fox"];

        return `${colors[Math.floor(Math.random() * colors.length)]} ${
            animals[Math.floor(Math.random() * animals.length)]
        }`;
    }
    const [confirmText, setConfirmText] = useState(generateConfirmText());

    return (
        <CustomModal
            open={open}
            toggle={toggle}
            closeHandler={() => {
                toggle();
                setConfirmText(generateConfirmText())
            }}
            className="action-confirm-finish-modal dark"
            title={title}
            dialogClassName="modal-small-width"
        >
            <>
                <DqIcon className="modal-action-icon"/>
                <span>{"Are you sure you want finish tournament "}
                    <b className="white no-wrap">{getTournamentVariantTitle(tournament, variant)}</b>
                    {
                        // eslint-disable-next-line react/jsx-curly-brace-presence
                    }
                    ?
                </span>
                <br/>
                <span>
                     {"If you are really sure, write text "}
                    <b className="white no-wrap">{confirmText}</b>
                    {" in the textbox below."}
                </span>
                <br/>
                <Input id="confirmText" autoComplete="off" innerRef={confirmTextRef} placeholder={confirmText}
                       className="mt-2 light" onChange={confirmTextOnChange} autoFocus/>
                <br/>
                <Button color="primary" className="confirm-btn" ref={finishBtn} disabled={isFinishBtnDisabled}
                        onClick={() => {
                            onFinish();
                        }}>
                    Finish
                </Button>
            </>
        </CustomModal>
    );
};

export default FinishConfirmationModal;