import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddItemModal from "../../modals/AddItemModal";
import ConfirmationModal from "../../modals/ConfirmationModal";

class Rooms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeRoom: {},
      nextActiveRoom: undefined,
      openRoomModal: false,
      openConfirmationModal: false,
      openSwitchConfirmationModal: false,
      openConfirmationModal2: false,
    };
  }

  onRoomButtonClick(room) {
    this.setState({ nextActiveRoom: room }, () => {
      if (this.props.roomTablesHasChanged()) {
        this.setState({ openSwitchConfirmationModal: true });
      } else {
        this.switchToAnotherRoom();
      }
    });
  }

  isRoomNameUnique(name) {
    if (
      !name ||
      name.trim() === "" ||
      this.props.rooms.find((room) => room.roomName === name)
    ) {
      return false;
    }
    return true;
  }

  switchToAnotherRoom() {
    this.setState({ activeRoom: this.state.nextActiveRoom }, () => {
      this.props.setActiveRoom(this.state.activeRoom);
    });
  }

  saveAndSwitch() {
    this.props.saveSwitchRoom().then(() => this.switchToAnotherRoom());
  }

  isActiveRoom(roomId) {
    if (this.state.activeRoom.id === roomId) return true;
    return false;
  }

  componentWillUpdate() {
    if (
      this.props.rooms.length === 1 &&
      JSON.stringify(this.state.activeRoom) === JSON.stringify({})
    ) {
      this.setState({ activeRoom: this.props.rooms[0] });
    }
  }

  onDeleteRoomClick() {
    const tableWithTournament = (this.state.activeRoom.tables.tournamentVariant || {}).id
      ? this.state.activeRoom.tables.tournamentVariant
      : undefined;
    if (tableWithTournament === undefined) {
      this.setState({ openConfirmationModal: true });
    } else {
      this.setState({ openConfirmationModal2: true });
    }
  }

  render() {
    const rooms = this.props.rooms.map((i) => (
      <div key={i.id}>
        <div>
          <button
            className={`room${this.isActiveRoom(i.id) ? " active-room" : " "}`}
            onClick={() => this.onRoomButtonClick(i)}
          >
            {i.roomName}
          </button>
          <FontAwesomeIcon
            visibility={this.isActiveRoom(i.id) ? "visible" : "hidden"}
            className="room-icon"
            onClick={() => this.onDeleteRoomClick()}
            icon={faTrash}
            size="xs"
          />
        </div>
      </div>
    ));
    return (
      <>
        <div>
          {rooms}
          <button
            style={{ margin: 10 }}
            className="unstyled-button"
            onClick={() => this.setState({ openRoomModal: true })}
          >
            + Add room
          </button>
        </div>
        <AddItemModal
          inputLabel="Room name"
          inputPlaceholder="Type room name"
          buttonText="Save room name"
          open={this.state.openRoomModal}
          close={() => this.setState({ openRoomModal: false })}
          onButtonClick={this.props.saveRoom}
          isValid={this.isRoomNameUnique.bind(this)}
          validationText="Room name is not defined or the name already exists"
        />
        <ConfirmationModal
          confirmationText={`Are you sure you want to delete ${this.state.activeRoom.roomName}?`}
          buttonText="Yes"
          open={this.state.openConfirmationModal}
          close={() => this.setState({ openConfirmationModal: false })}
          onSubmitButtonClick={this.props.deleteRoom.bind(this)}
        />
        <ConfirmationModal
          confirmationText={`You have unsaved changes in ${this.state.activeRoom.roomName}. Are you sure you want to switch to another room?`}
          buttonText="Switch"
          open={this.state.openSwitchConfirmationModal}
          close={() => this.setState({ openSwitchConfirmationModal: false })}
          onSubmitButtonClick={this.switchToAnotherRoom.bind(this)}
          buttonText2="Save & Switch"
          onSubmitButton2Click={this.saveAndSwitch.bind(this)}
        />
        <ConfirmationModal
          confirmationText={`Can not delete room  ${this.state.activeRoom.roomName} . It contains tables with registration`}
          buttonText="Ok"
          open={this.state.openConfirmationModal2}
          close={() => this.setState({ openConfirmationModal2: false })}
          onSubmitButtonClick={() => this.setState({ openConfirmationModal2: false })}
        />
      </>
    );
  }
}

export default Rooms;
