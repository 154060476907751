import actionTypes from '../actiontypes/tableActionTypes';

const initState = {
  seatingPlan: {
    data: null,
    isLoading: false,
    isLoaded: false
  }
};
export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.TABLES_GET_SEATINGPLAN_REQUEST:
      return {
        ...state,
        seatingPlan: {
          data: null,
          isLoading: true,
          isLoaded: false
        },
      };
    case actionTypes.TABLES_GET_SEATINGPLAN_RESPONSE:
      return {
        ...state,
        seatingPlan: {
          data: action.data,
          isLoading: false,
          isLoaded: true
        },
      };
    case actionTypes.TABLES_GET_SEATINGPLAN_CLEAN:
      return {
        ...state,
        seatingPlan: initState.seatingPlan
      };
    default:
      return state;
  }
};
