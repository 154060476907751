import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonGroup, Table } from "reactstrap";
import ReactToPrint from "react-to-print";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import {
  cleanWaitingList,
  getFreeSeat,
  getWaitingHistoryList,
  getWaitingList,
  sitPlayerFromWaitingList,
} from "../../actions/playerActions";
import VariantPicker from "../shared/VariantPicker";
import UpdatePlayerStateWrapper from "../shared/UpdatePlayerStateWrapper";
import Loader from "../shared/Loader";
import { formatPlayerName } from "../../util/playerUtil";
import { countFreeSeats } from "../../util/tournamentUtil";
import { formatTime } from "../../util/stringUtil";
import socket from "../../service/EvSocket";
import CustomModal from "../shared/CustomModal";
import PrintContent from "../functionalComponents/print/PrintContent";
import BottomNavbarMobile from "../shared/BottomNavbarMobile";
import { isMobile } from 'react-device-detect';

const PrintSeatTicketModal = (props) => {
  const node = useRef();

  function closePrintSeatTicketModal() {
    props.onClose();
  }

  return (
    <CustomModal
      id="printSeatTickedModal"
      open
      className="action-confirm-modal dark printSeatTickedModal"
      title="Do you want to print a seat?"
      dialogClassName="modal-small-width"
      toggle={closePrintSeatTicketModal}
    >
      <div>
        <span>
          <b>
            If you need to find any additional info about this MTT, you can do it in MTT list /
            History
          </b>
        </span>
        <br />
        <br />
        <Row>
          <Col>
            <Row className="center">Table</Row>
            <Row className="center">{props.tableName}</Row>
          </Col>
          <Col>
            <Row className="center">Seat</Row>
            <Row className="center">{props.seat}</Row>
          </Col>
        </Row>
        <br />

        <Row className="confirm-btns" id="printSeatTickedModalButtons">
          <div>
            <ReactToPrint
              trigger={() => <Button color="primary">Yes</Button>}
              content={() => node.current}
              onAfterPrint={closePrintSeatTicketModal}
            />
            <PrintSeatTicketContent
              ref={node}
              player={props.playerToPrint}
              tableName={props.tableName}
              seat={props.seat}
            />
          </div>
          <Button color="secondary" onClick={closePrintSeatTicketModal}>
            No
          </Button>
        </Row>
      </div>
    </CustomModal>
    // <>
    //   <Modal
    //     id="printSeatTickedModal"
    //     isOpen
    //     toggle={() => {
    //       closePrintSeatTicketModal();
    //     }}
    //   >
    //     <ModalHeader>Do you want to print a seat?</ModalHeader>
    //     <ModalBody>
    //       <table>
    //         <tbody>
    //           <tr>
    //             <td style={{ width: '100px' }}>Table</td>
    //             <td>Seat</td>
    //           </tr>
    //           <tr>
    //             <td>{props.tableName}</td>
    //             <td>{props.seat}</td>
    //           </tr>
    //         </tbody>
    //       </table>
    //     </ModalBody>
    //     <ModalFooter>
    //       <div style={{ width: '100%' }}>
    //         <ReactToPrint trigger={() => <Button color="primary">Yes</Button>} content={() => node.current} onAfterPrint={closePrintSeatTicketModal} />
    //         <PrintSeatTicketContent ref={node} player={props.playerToPrint} tableName={props.tableName} seat={props.seat} />
    //       </div>
    //       <Button color="secondary" onClick={closePrintSeatTicketModal}>
    //         No
    //       </Button>
    //     </ModalFooter>
    //   </Modal>
    // </>
  );
};

const PrintSeatTicketModalMemo = React.memo(PrintSeatTicketModal);

/* Functional component doesn't work here. I dont know why */
class PrintSeatTicketContent extends React.Component {
  render() {
    // eslint-disable-next-line react/no-this-in-sfc
    const { player } = this.props;

    return (
      <div className="print-seat-ticket">
        <h1>{`Hi ${formatPlayerName(player)},`}</h1>
        <span>
          {`here is you ticket at table ${this.props.tableName} / seat: ${this.props.seat}`}
          <br />
          Good Luck!
        </span>
      </div>
    );
  }
}

const WaitingListCoordingator = (props) => {
  const { variant } = props;
  const [tournamentVarianId, setTournmantVariantId] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);
  const dispatch = useDispatch();
  const [playearAllowedToSitIndex, setPlayearAllowedToSitIndex] = useState(0);
  const [isPrintSeatTicketModalOpened, setIsPrintSeatTicketModalOpened] = useState(false);
  const [printData, setPrintData] = useState(null);
  const { waitingList, waitingListFreeSeats } = useSelector((state) => state.player);
  const { tableId, tableName, seat } = waitingListFreeSeats.data || {};

  if (variant && variant.id && variant.id !== tournamentVarianId) {
    setTournmantVariantId(variant.id);
  }

  if (variant && variant.tournamentId && !tournamentId) {
    setTournamentId(variant.tournamentId);
  }

  useEffect(() => {
    if(!tournamentVarianId) {
      return
    }
    dispatch(getWaitingList(tournamentVarianId));
    if (tournamentVarianId !== null) getFreeSeat(dispatch, tournamentVarianId);

    return () => {
      cleanWaitingList();
    };
  }, [tournamentVarianId]);

  const freeSeats = tournamentVarianId ? countFreeSeats(variant) : 0;

  if (waitingList.data) {
    waitingList.data.sort((a, b) =>
      a.playerInTournamentPhaseVariantId > b.playerInTournamentPhaseVariantId ? 1 : -1
    );
  }

  const Seats = () => (
    <div className="seats">
      <span className="no-wrap bold big-text">{`${tableName}/${seat}`}</span>
      <br />
      <span className="no-wrap small-text">Seat available</span>
    </div>
  );

  const MoveButtons = ({ index, count }) => {
    const btnUpDisabled = index === 0;
    const btnDownDisabled = index >= count - 1;

    const btnUpClassNameDown = `btn-move ${btnUpDisabled ? "non-active" : ""}`;
    const btnUpClassNameUp = `btn-move down ${btnDownDisabled ? "non-active" : ""}`;

    return (
      <>
        <div className="move-btns">
          <button
            type="button"
            disabled={btnUpDisabled}
            className={btnUpClassNameDown}
            onClick={() => setPlayearAllowedToSitIndex(index - 1)}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
          <button
            type="button"
            disabled={btnDownDisabled}
            className={btnUpClassNameUp}
            onClick={() => setPlayearAllowedToSitIndex(index + 1)}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        </div>
      </>
    );
  };

  function shouldReloadWaitingList() {
    return (
      tournamentVarianId &&
      ((!waitingList.isLoading && !waitingList.isInitialized) ||
        tournamentVarianId !== waitingList.tournamentVariantId)
    );
  }

  useEffect(() => {
    socket.on("playersOnTableChange", "WaitingListUpdateFreeSeats", () => {
      getFreeSeat(dispatch, tournamentVarianId);
    });
    socket.on(
      "updateTournamentVariant",
      "WaitingListUpdateFreeSeats",
      (payload) => {
        if (
          payload.length > 0 &&
          payload[0].tournamentId &&
          payload[0].tournamentId === tournamentId
        ) {
          getFreeSeat(dispatch, tournamentVarianId);
          dispatch(getWaitingList(tournamentVarianId));
        }
      },
      []
    );
    return () => {
      socket.removeHandler("playersOnTableChange", "WaitingListUpdateFreeSeats");
      socket.removeHandler("updateTournamentVariant", "WaitingListUpdateFreeSeats");
      cleanWaitingList();
    };
  });

  function btnSitInOnClick(player, playerId) {
    dispatch(sitPlayerFromWaitingList(playerId, tableId, seat)).then((x) => {
      setPrintData({ playerToPrint: { ...player, tableName: `${tableName}`, seatNum: seat + 0 } });
      setIsPrintSeatTicketModalOpened(true);
    });
  }

  return (
    <>
      {isPrintSeatTicketModalOpened && waitingList.data.length > 0 && (
        <PrintContent
          createdPlayerId={printData.playerToPrint.id}
          customer={printData.playerToPrint}
          createdPlayer={printData.playerToPrint}
          variant={variant}
          onPrinted={() => {
            setIsPrintSeatTicketModalOpened(false);
            setPrintData(null);
            getFreeSeat(dispatch, tournamentVarianId);
            dispatch(getWaitingList(tournamentVarianId));
          }}
        />
      )}

      <UpdatePlayerStateWrapper>
        <div className={isMobile ? "no-ml" : ""}>
          <div style={{ marginLeft: "15px", marginTop: "15px" }}>{`Free seats: ${freeSeats}`}</div>
          {waitingList.data && waitingList.data.length > 0 ? (
            <Table id="players-table">
              <tbody>
                {waitingList.data
                  .filter((player) => player.stateId === 0)
                  .map((player, index) => {
                    const allowedToSit = index === playearAllowedToSitIndex;
                    const existFreeSeat = tableId && seat;

                    return (
                      <tr key={index}>
                        <td>
                          <span className={isMobile ? "smaller-text" : "no-wrap big-text"}>{`${index + 1}.`}</span>
                        </td>
                        <td style={{ width: "100%" }}>
                          <span className={isMobile ? "break-spaces smaller-text" : "no-wrap big-text"}>{formatPlayerName(player)}</span>
                        </td>

                        {existFreeSeat && allowedToSit && (
                          <td className={isMobile ? "smaller-text" : ""}>
                            <Seats freeSeats={waitingListFreeSeats} />
                          </td>
                        )}
                        {existFreeSeat && allowedToSit && (
                          <td>
                            <Button
                              className={isMobile ? "btn-sit-in-mobile" : "btn-sit-in"}
                              onClick={() =>
                                btnSitInOnClick(
                                  player,
                                  player.playerInTournamentPhaseVariantId,
                                  tableId,
                                  seat
                                )
                              }
                            >
                              Sit in
                            </Button>
                          </td>
                        )}
                        {existFreeSeat && allowedToSit && (
                          <td className="dark">
                            <MoveButtons
                              index={index}
                              count={waitingList.data.length}
                              setPlayearAllowedToSitIndex={setPlayearAllowedToSitIndex}
                            />
                          </td>
                        )}

                        {!allowedToSit && <td colSpan={4} />}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          ) : (
            <h1 style={{ textAlign: "center" }}>No players...</h1>
          )}
        </div>
        <div className="d-block d-md-none">
          <BottomNavbarMobile id="waitingList" tournamentUrl={"/tournament/" + variant.tournamentId} />
        </div>
      </UpdatePlayerStateWrapper>
    </>
  );
};

const History = (props) => {
  const { variantId } = props;
  const dispatch = useDispatch();
  const { waitingHistoryList } = useSelector((state) => state.player);

  function shouldReloadWaitingList() {
    return variantId && waitingHistoryList.tournamentVariantId !== variantId;
  }

  useEffect(() => {
    if (shouldReloadWaitingList()) {
      dispatch(getWaitingHistoryList(variantId));
    }
  }, []);

  return (
    <>
      {waitingHistoryList.isLoading || !waitingHistoryList.isInitialized ? (
        <Loader />
      ) : (
        <>
          {waitingHistoryList.data && waitingHistoryList.data.length > 0 ? (
            <Table id="players-table">
              <tbody>
                {waitingHistoryList.data.map((historyRecord, index) => (
                  <tr key={index}>
                    <td>
                      <span className={"no-wrap big-text"}>{formatPlayerName(historyRecord)}</span>
                    </td>
                    <td>
                      <p className={"no-wrap big-text"}>{`${historyRecord.actionData.tableName} / ${historyRecord.actionData.seatNum}`}</p>
                      <p className={"no-wrap small-text"}>Seat</p>
                    </td>
                    <td>
                      <p className={"no-wrap big-text"}>{`${formatTime(historyRecord.createdAt)}`}</p>
                      <p className={"no-wrap small-text"}>Time</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h1 style={{ textAlign: "center" }}>No history records...</h1>
          )}
        </>
      )}
    </>
  );
};

const Content = ({ variant }) => {
  const [showHistory, setShowHistory] = useState(false);

  return (
    <>
      <div className={"d-none d-md-block"}>
        <div id="waiting-list">
          <Row noGutters className="tab-container">
            <ButtonGroup className="btn-switch big">
              <Button
                className="no-wrap"
                variant="dark"
                active={!showHistory}
                onClick={() => setShowHistory(false)}
              >
                Waiting list coordinator
              </Button>
              <div style={{ flex: 1 }} />
              <Button variant="dark" active={showHistory} onClick={() => setShowHistory(true)}>
                History
              </Button>
            </ButtonGroup>
          </Row>
          {showHistory ? (
            <History variantId={variant.id} />
          ) : (
            <WaitingListCoordingator variant={variant} />
          )}
        </div>
      </div>
      <div className={"d-block d-md-none"}>
        <div id="waiting-list">
          <WaitingListCoordingator variant={variant} />
        </div>
      </div>
    </>
  );
};

const WaitingList = (props) => {
  const tournament = useSelector((state) => state.tournaments.tournament);
  const variant = useSelector((state) => state.tournaments.variant);
  return (
    <VariantPicker
      tournament={tournament}
      variant={variant}
      id="WaitingList"
      getContent={(variant) => {
        return <Content variant={variant} />;
      }}
    />
  );
};

export default WaitingList;
