import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useSelector, useDispatch, connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { getActivePlayersByTournamentVariantId } from "../../actions/playerActions";
import { updateClockSettings } from "../../actions/tournamentActions";
import TournamentClockHeader from "../shared/TournamentClockHeader";
import { formatPlayerName, formatSeatNum } from "../../util/playerUtil";

const PlayerCard = ({ player }) => (
  <Col lg={4} md={6} xs={12} className="player" key={player.id}>
    <div className="seat">
      <span className="seat-num">{formatSeatNum(player.seatNum || "00")}</span>
      <span className="seat-txt">Seat</span>
    </div>
    {/* <span className="name">{formatPlayerName(player)}</span> */}
    <span className="name">{`${player.firstName} ${player.lastName}`}</span>
  </Col>
);

const Players = ({ tablePlayers, className }) => (
  <Row id="players" className={`players ${className}`}>
    {tablePlayers.length === 0 ? (
      <h1>No Players</h1>
    ) : (
      tablePlayers.map((player) => <PlayerCard player={player} />)
    )}
  </Row>
);

const RotatingTables = (props) => {
  const dispatch = useDispatch();
  const node = useRef(null);

  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const { variant, clockSettings } = props;
  const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const intervalCurrentTableIndex = React.useRef();
  intervalCurrentTableIndex.current = currentTableIndex;

  const players = useSelector((state) => state.player.playersInTournament);
  const intervalPlayers = React.useRef();
  intervalPlayers.current = players;

  useEffect(() => {
    dispatch(getActivePlayersByTournamentVariantId(variant.id));
  }, []);

  let nextTableTimer;
  useEffect(() => {
    nextTableTimer = setInterval(() => {
      goToNext();
    }, 7000);
    return () => clearInterval(nextTableTimer);
  }, []);

  const goToNext = () => {
    let newTableIndex = intervalCurrentTableIndex.current + 1;
    if (newTableIndex >= getVariantTables().length) {
      newTableIndex = 0;
    }
    if (newTableIndex === intervalCurrentTableIndex.current) {
      return;
    }
    setFadeIn(false);
    setFadeOut(true);
    setTimeout(() => {
      setCurrentTableIndex(newTableIndex);
      setFadeIn(true);
      setFadeOut(false);
      setTimeout(() => {
        setFadeIn(false);
        setFadeOut(false);
      }, 500);
    }, 500);
  };

  const closeSeating = () => {
    dispatch(updateClockSettings(variant.id, clockSettings.data.id, false));
  };

  const getVariantTables = () => {
    const variantTables =
      (variant.tables || []).filter(
        (table) =>
          (intervalPlayers.current.data || []).filter((p) => p.tableId === table.id).length > 0
      ) || [];
    return variantTables;
  };

  const variantTables = getVariantTables();
  const currentTable = variantTables[intervalCurrentTableIndex.current] || {};
  const tableId = currentTable.id;
  const tablePlayers = intervalPlayers.current.data
    .filter((p) => p.tableId === tableId)
    .sort((a, b) => (a.seatNum > b.seatNum ? 1 : b.seatNum > a.seatNum ? -1 : 0));

  return (
    <Col id="docElm" className="rotating-tables">
      <TournamentClockHeader
        variant={variant}
        tournament={variant.tournament}
      />
      <div className="table">
        <Row className="table-name line">
          <h1>{`Table ${currentTable.tableName || ""}`}</h1>
        </Row>
        <Players
          tablePlayers={tablePlayers}
          className={`${fadeIn ? "fadeIn" : ""} ${fadeOut ? "fadeOut" : ""}`}
        />
      </div>
      <Row className="paging-buttons">
        {variantTables.map((table, i) => (
          <Button
            disabled
            onClick={() => {
              clearInterval(nextTableTimer);
              setCurrentTableIndex(i);
            }}
            className={`${table.id === currentTable.id ? "selected" : ""}`}
          >
            {i + 1}
          </Button>
        ))}
      </Row>
      <div>
        <Button onClick={closeSeating} className="float-right btn-dark outline fit">
          Close reseat window
          <FontAwesomeIcon icon={faCog} className="btn-icon" />
        </Button>
      </div>
    </Col>
  );
};

export default RotatingTables;
