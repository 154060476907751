import React from "react";
import {Button, Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import NextPageImage from "../../image/next-page.png";
import CircleIcon from "../functionalComponents/CircleIcon";
import CustomModal from "../shared/CustomModal";
import {
    getPlayersByStateAndTournamentVariantId,
    getPlayersClean,
    unregisterPlayerFromVariant
} from "../../actions/playerActions";

class UnregisterPlayerModal extends React.Component {
    state = {
        searchText: undefined
    };

    componentWillUnmount() {
        this.props.getPlayersClean();
    }

    handleFilterChange = e => {
        this.setState({searchText: e.target.value}, () => {
            this.props.getPlayersByStateAndTournamentVariantId('unregistering', this.props.variant.id, this.state.searchText);
        });
    };

    closeModal = () => {
        this.setState({searchText: undefined});
        this.props.toggle();
    };

    render() {
        return (
            <>
                {this.props.isOpen && (
                    <CustomModal
                        open={this.props.isOpen}
                        dialogClassName="modal-width"
                        closeHandler={this.closeModal}
                        className="unregister-player-modal"
                        title="Unregister player"
                        text={
                            <label>
                                <CircleIcon color={this.props.variant.color}/>
                                {`${this.props.variant.tournamentName} ${this.props.variant.tournamentIsMultiday ? `, ${this.props.variant.name}` : ""}`}
                            </label>
                        }
                    >
                        <Container>
                            <InputGroup className="mb-3 input-right-icon-control">
                                <FormControl
                                    id="filter"
                                    placeholder="Search player"
                                    name="filter"
                                    className="input-part"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={this.handleFilterChange}
                                    value={this.state.searchText}
                                />
                                <InputGroup.Text className="icon-part" id="basic-addon1">
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup>

                            <div className="players">
                                {this.props.playersInTournament.map((item, key) => {
                                    return (
                                        <>
                                            <Row className="main-row">
                                                <Col key={`name_${item.playerId}`}>
                                                    <Row
                                                        className="name-text">{`${item.firstName} ${item.lastName}`}</Row>
                                                    <Row className="id-text">
                                                        ID
                                                        {item.playerId}
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row rowSpan="2">
                                                        <Button
                                                            className="unregister-btn"
                                                            key={`button_${item.playerId}`}
                                                            onClick={() => {
                                                                this.props.unregisterPlayerFromVariant(item.playerInTournamentPhaseVariantId);
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <img src={NextPageImage}/>
                                                            Unregister
                                                        </Button>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="graySeparator"/>
                                            </Row>
                                        </>
                                    );
                                })}
                            </div>
                        </Container>
                    </CustomModal>
                )}
            </>
        );
    }
}

UnregisterPlayerModal.defaultProps = {
    isOpen: false
};

const mapStateToProps = state => {
    return {
        playersInTournament: state.player.playersInTournament.data
    };
};

const mapDispatchToProps = {
    getPlayersByStateAndTournamentVariantId,
    unregisterPlayerFromVariant,
    getPlayersClean
};

export default connect(mapStateToProps, mapDispatchToProps)(UnregisterPlayerModal);
