export const socketConst = {
  ERROR: -1,
  DISCONNECTED: 0,
  CONNECTED: 1,
  UNSUBSCRIBED: 2,
  SUBSCRIBED: 3,
};

export const colors = {
  purple: 'purple',
};
