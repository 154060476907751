import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import CustomModal from "../shared/CustomModal";

class TableListFilterModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.addToFilter = this.addToFilter.bind(this);
    this.getActiveFlag = this.getActiveFlag.bind(this);
    this.state = {
      tables: [],
    };
  }

  handleResetClick() {
    this.setState({ tables: [] });
  }

  applyFilter() {
    this.props.apply(this.state.tables);
  }

  addToFilter(tableId) {
    const i = this.state.tables.indexOf(tableId);
    const tables = [...this.state.tables];
    if (i === -1) {
      tables.push(tableId);
    } else {
      tables.splice(i, 1);
    }
    this.setState({ tables });
  }

  getActiveFlag(tableId) {
    return this.state.tables.some((item) => item === tableId);
  }

  render() {
    return (
      <div>
        <CustomModal
          open={this.props.open}
          className="table-list-modal dark d-none d-md-block"
          title="Table filter"
          closeHandler={this.props.close}
          dialogClassName="modal-small-width"
        >
          <Row className="table-list">
            {this.props.tables.map((table) => (
              <div
                className={`table-list-filter-option ${
                  this.getActiveFlag(table.id) ? "active" : ""
                }`}
                key={table.id}
                onClick={() => this.addToFilter(table.id)}
              >
                <span>{table.tableName}</span>
              </div>
            ))}
          </Row>
          <div className="confirm-btns">
            <Button className="fit" onClick={() => this.applyFilter()}>
              Apply filter
            </Button>
            <Button
              className="fit"
              onClick={() => this.handleResetClick()}
              variant="outline-primary"
              inverted
            >
              Clear filter
            </Button>
          </div>
        </CustomModal>
        <CustomModal
          open={this.props.open}
          className="d-md-none d-block table-list-modal dark w-100"
          title="Table filter"
          closeHandler={this.props.close}
          dialogClassName="modal-small-width"
        >
          <Row className="table-list">
            {this.props.tables.map((table) => (
              <div
                className={`table-list-filter-option ${
                  this.getActiveFlag(table.id) ? "active" : ""
                }`}
                key={table.id}
                onClick={() => this.addToFilter(table.id)}
              >
                <span>{table.tableName}</span>
              </div>
            ))}
          </Row>
          <div className="confirm-btns">
            <Button className="fit" onClick={() => this.applyFilter()}>
              Apply filter
            </Button>
            <Button
              className="fit"
              onClick={() => this.handleResetClick()}
              variant="outline-primary"
              inverted
            >
              Clear filter
            </Button>
          </div>
        </CustomModal>
      </div>
    );
  }
}

export default TableListFilterModal;
