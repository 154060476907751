import React from 'react';
import { Row } from 'react-bootstrap';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import TableCard from './TableCard';

const TablesModal = (props) => {
  function getTables() {
    if (!props.variant || !props.variant.tables) {
      return [];
    }

    const tables = props.variant.tables.map((item, index) => ({ ...item, countId: index + 1 }));

    return tables;
  }

  return (
    <>
      {props.isOpen && (
        <Modal id="playerDetail" isOpen={props.isOpen}>
          <ModalHeader toggle={props.onCancel}>
            <span className="col-12">Sit in to:</span>
          </ModalHeader>
          <ModalBody>
            <Row>
              {props.variant
                && getTables().map((table) => (
                  <TableCard
                    table = {{...table}}
                    isLockUnlock={false}
                    showChooseSeat
                    onChooseSeat={() => props.onChooseSeat(table)}
                    seatingLocked={table.seatingLocked}
                    variant={props.variant}
                    hideText
                    lockUnlockChangeHandler={() => {
                    }}
                    lockUnlockEventHandler={() => {
                    }}
                  />
                ))}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel tables
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default TablesModal;
