import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';

const GridLegend = () => (
  <Col>
    <div className="grid-legend-item">
      <FontAwesomeIcon className="info-icon" style={{ color: '#014725' }} icon={faSquare} />
      <label>- Selected</label>
    </div>
    <div className="grid-legend-item">
      <FontAwesomeIcon className="info-icon" style={{ color: '#5b38b9' }} icon={faSquare} />
      <label>- Occupied</label>
    </div>
    <div className="grid-legend-item">
      <FontAwesomeIcon className="info-icon" style={{ color: '#03a656' }} icon={faSquare} />
      <label>- Empty active tables</label>
    </div>
    <div className="grid-legend-item">
      <FontAwesomeIcon className="info-icon" style={{ color: '#3b3b3b' }} icon={faSquare} />
      <label>- Free</label>
    </div>
    <div className="grid-legend-item">
      <FontAwesomeIcon className="info-icon" style={{ color: 'transparent', border: '3px solid #e32b41', borderRadius: '4px' }} icon={faSquare} />
      <label>- Conflict tables</label>
    </div>
  </Col>
);

export default GridLegend;
