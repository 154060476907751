import * as types from '../actiontypes/actionTypes';
import * as parse from '../util/parseString';

const initState = {
  errorMsg: '',
  errorTraceId: '',
  isLoading: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.ERROR_REPORT:
      return {
        ...state, errorMsg: parse.getMessage(action.data), errorTraceId: parse.traceId(action.data), errorMsgTitle: action.errorTitle, code: (((action.data || {}).response || {}).data || {}).code,
      };
    case types.ERROR_CLEAR:
      return { ...state, errorMsg: action.data, errorTraceId: '', code: undefined };
    default:
      return state;
  }
};
