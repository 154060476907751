/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {Button, Input} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonGroup} from 'react-bootstrap';
import * as JsSearch from 'js-search';
import Loader from '../../shared/Loader';
import PlayerDetail from './PlayerDetail';

import {
    cleanWaitingList,
    cleanWithoutSeatPlayers,
    getFinishedPlayersByTournamentVariantId as getBustedPlayers,
    getFinishedPlayersClean,
    getWaitingList,
    getWithoutSeatPlayers,
    sitPlayerAndReturnToGameIfNeeded,
} from '../../../actions/playerActions';

import CustomModal from '../../shared/CustomModal';
import {formatPlayerName} from '../../../util/playerUtil';
import restClient from "../../../util/restClient";
import ConfirmationModal from "../../modals/ConfirmationModal";

const PlayerCard = ({player, tableId, seatNum, toggle}) => {
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const confirmError = () => {
        setShowErrorMsg(false)
        setErrorMsg('')
    }

    async function sitPlayer() {
        try {
            await restClient.postAsync(`/player/playeringame`, {

                id: player.playerInTournamentPhaseVariantId,
                tableId,
                seatNum,
                seated: true,
                returnToGame: true,
            })
            toggle()
        } catch (e) {
            if(e.response && e.response.data && e.response.data.code === 'IERR_ACTIVE_PLAYER_IN_TOURNAMENT_PHASE_VARIANT') {
                setShowErrorMsg(true)
                setErrorMsg('The player already made a re-entry and is still active in the tournament')
            }
        }
    }

    return <div className="player line">
        <div className="name">{`${formatPlayerName(player)}${player.position !== undefined ? `, ${player.position}th` : ''}`}</div>
        <div className="player-buttons">
            <Button
                color="secondary"
                className="fit"
                onClick={sitPlayer}
            >
                Sit in
            </Button>
        </div>
        <ConfirmationModal
            confirmationText={errorMsg}
            open={showErrorMsg}
            buttonText="Ok"
            close={() => confirmError()}
            onSubmitButtonClick={() => confirmError()}
        />
    </div>
};

const ChoosePlayerModal = ({
                               isOpen, toggle, variant, tableId, seatNum,
                           }) => {
    const dispatch = useDispatch();
    const [display, setDisplay] = useState('WAITING_LIST');
    const [filter, setFilter] = useState('');
    const {waitingList, finishedPlayersInTournament: playersInTournament, withoutSeatPlayers} = useSelector((state) => state.player);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    let players = [];
    switch (display) {
        case 'REGISTERED_NO_SEAT': {
            players = withoutSeatPlayers;
            break;
        }
        case 'WAITING_LIST': {
            players = waitingList;
            break;
        }
        case 'FINISHED': {
            players = playersInTournament;
            break;
        }
    }

    const filteredPlayers = filerPlayers();


    useEffect(() => {
        chooseTab('WAITING_LIST');
        return () => {
            cleanData();
        };
    }, []);

    const cleanData = () => {
        dispatch(cleanWaitingList());
        dispatch(getFinishedPlayersClean());
        dispatch(cleanWithoutSeatPlayers());
    };


    function filerPlayers() {
        if (!filter) {
            return players.data || [];
        }

        const search = new JsSearch.Search('id');
        search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        search.addIndex('firstName');
        search.addIndex('lastName');
        search.addDocuments(players.data);
        return search.search(filter) || [];
    }

    function chooseTab(tab) {
        setDisplay(tab);
        switch (tab) {
            case 'REGISTERED_NO_SEAT': {
                if (!withoutSeatPlayers.isInitialized && !withoutSeatPlayers.isLoading && variant.id) {
                    dispatch(getWithoutSeatPlayers(variant.id));
                }
                break;
            }
            case 'WAITING_LIST': {
                if (!waitingList.isInitialized && !waitingList.isLoading && variant.id) {
                    dispatch(getWaitingList(variant.id));
                }
                break;
            }
            case 'FINISHED': {
                if (variant.id) {
                    dispatch(getBustedPlayers(variant.tournamentId, variant.id));
                }
                break;
            }
        }
    }

    return (
        <>
            {isOpen && (
                <CustomModal
                    open={isOpen}
                    closeHandler={toggle}
                    className="choose-player-modal"
                    dialogClassName="modal-small-width"
                    title="Choose player"
                >
                    <ButtonGroup className="btn-switch tournament-tab ">
                        <Button
                            className="tab-button btn-translucent"
                            variant="dark"
                            active={display === 'REGISTERED_NO_SEAT'}
                            onClick={() => {
                                chooseTab('REGISTERED_NO_SEAT');
                            }}
                        >
                            Seating by floorman
                        </Button>
                        <Button
                            className="tab-button btn-translucent"
                            variant="dark"
                            active={display === 'WAITING_LIST'}
                            onClick={() => {
                                chooseTab('WAITING_LIST');
                            }}
                        >
                            Waiting list
                        </Button>
                        <Button
                            className="tab-button btn-translucent"
                            variant="dark"
                            active={display === 'FINISHED'}
                            onClick={() => {
                                chooseTab('FINISHED');
                            }}
                        >
                            Finished
                        </Button>
                    </ButtonGroup>
                    <Input
                        id="txtSearchByName"
                        className="dark mt-4"
                        placeholder="search by name, i.e. John Doe"
                        name="txtSearchByName"
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <div className="line"/>
                    <Loader>
                        <div className="players">
                            {filteredPlayers.map((player, index) => (
                                <PlayerCard player={player} tableId={tableId} seatNum={seatNum} toggle={toggle} key={`card_${index}`}/>
                            ))}
                        </div>
                    </Loader>
                    {selectedPlayer && (
                        <PlayerDetail
                            player={selectedPlayer}
                            closeAction={() => {
                                setSelectedPlayer(null);
                            }}
                            variant={variant}
                        />
                    )}
                </CustomModal>
            )}
        </>
    );
};

export default ChoosePlayerModal;
