import React from "react";
import { connect } from "react-redux";
import { Button, Table } from "reactstrap";
import { addTips, getFees, getTips } from "../../actions/tournamentActions";
import CustomModal from "../shared/CustomModal";
import CircleIcon from "../functionalComponents/CircleIcon";
import { getPlayersByStateAndTournamentVariantId, getPlayersClean, } from "../../actions/playerActions";
import PlayerListModal from "./PlayerListModal";
import ReactHTMLTableToExcel from "../shared/excel/ReactHTMLTableToExcel";
import Loader from "../shared/Loader";

class BalanceSheetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPlayerList: false,
      newTipAmount: 0,
    };
  }

  action = {
    ADD: "add",
    SUBTRACT: "subtract",
  };

  componentWillUnmount() {
    this.props.getPlayersClean();
  }

  componentWillMount() {
    this.props.getTips(this.props.variant.id);
  }

  calculateTableValues(eachCashier) {
    const buyinFeeCount = eachCashier.buyin.totalCount ? eachCashier.buyin.totalCount : 0;
    const reentryFeeCount = eachCashier.reentry.totalCount ? eachCashier.reentry.totalCount : 0;
    const rebuyFeeCount = eachCashier.rebuy.totalCount ? eachCashier.rebuy.totalCount : 0;
    const addonFeeCount = eachCashier.addon.totalCount ? eachCashier.addon.totalCount : 0;

    const buyinFeeSum = eachCashier.buyin.feeSum ? eachCashier.buyin.feeSum : 0;
    const reentryFeeSum = eachCashier.reentry.feeSum ? eachCashier.reentry.feeSum : 0;
    const rebuyFeeSum = eachCashier.rebuy.feeSum ? eachCashier.rebuy.feeSum : 0;
    const addonFeeSum = eachCashier.addon.feeSum ? eachCashier.addon.feeSum : 0;

    const buyinPoolSum = eachCashier.buyin.poolSum ? eachCashier.buyin.poolSum : 0;
    const reentryPoolSum = eachCashier.reentry.poolSum ? eachCashier.reentry.poolSum : 0;
    const rebuyPoolSum = eachCashier.rebuy.poolSum ? eachCashier.rebuy.poolSum : 0;
    const addonPoolSum = eachCashier.addon.poolSum ? eachCashier.addon.poolSum : 0;
    return {
      buyinFeeCount,
      reentryFeeCount,
      rebuyFeeCount,
      addonFeeCount,
      buyinFeeSum,
      reentryFeeSum,
      rebuyFeeSum,
      addonFeeSum,
      buyinPoolSum,
      reentryPoolSum,
      rebuyPoolSum,
      addonPoolSum,
    };
  }

  openPlayerModal = () => {
    if (this.props.variant !== undefined) {
      this.props.getPlayersByStateAndTournamentVariantId(`registered`, this.props.variant.id);
    }
    this.setState({
      modalPlayerList: !this.state.modalPlayerList,
    });
  };

  togglePlayerList = () => {
    this.setState({ modalPlayerList: !this.state.modalPlayerList });
  };

  getTipForCashier(personId) {
    var tip = this.props.tips.find((tip) => tip.person_id === personId);
    return tip ? tip.value : 0;
  }

  render() {
    const cashierEntries = [];
    let totalTotalEntries = 0;
    let totalUniqueEntries = 0;
    let totalReentries = 0;
    let totalRebuy = 0;
    let totalAddon = 0;
    let totalMoneyCollected = 0;
    let totalFee = 0;

    this.props.fees.forEach((each) => {
      const {
        feeTypeId,
        feeType,
        feeSum,
        feeCount,
        poolSum,
        poolCount,
        totalCount,
        ...eachCashier
      } = each;
      if (feeType !== "cashback") {
        let matchCashier = cashierEntries.find((orgCashier) => {
          return orgCashier.receivedByIdentifier === eachCashier.receivedByIdentifier;
        });
        if (!matchCashier) {
          eachCashier.buyin = {};
          eachCashier.duplicateBuyin = {};
          eachCashier.reentry = {};
          eachCashier.rebuy = {};
          eachCashier.addon = {};
          eachCashier[feeType] = {
            feeSum,
            feeCount,
            poolSum,
            poolCount,
            totalCount
          };
          cashierEntries.push(eachCashier);
        } else {
          matchCashier[feeType] = {
            feeSum,
            feeCount,
            poolSum,
            poolCount,
            totalCount
          };
        }
      }
    });

    const handleChange = (e) => {
      this.setState({
        newTipAmount: e.target.value,
      });
    };

    const addSubtractTip = (tipAction, personId) => {
      switch (tipAction) {
        case this.action.SUBTRACT:
          var negativeValue = 0 - this.state.newTipAmount;
          var currentTip = this.getTipForCashier(personId);
          if (currentTip < this.state.newTipAmount) {
            negativeValue = 0 - currentTip;
          }

          this.props.addTips({
            value: negativeValue,
            variantId: this.props.variant.id,
            personId: personId,
          });
          break;
        case this.action.ADD:
          this.props.addTips({
            value: this.state.newTipAmount,
            variantId: this.props.variant.id,
            personId: personId,
          });
          break;
      }
    };

    return (
      <>
        {this.props.isOpen && (
          <CustomModal
            open={this.props.isOpen}
            closeHandler={this.props.toggle}
            className="balance-sheet-modal"
            title="Balance sheet"
            dialogClassName="modal-width"
            text={
              <label>
                <CircleIcon color={this.props.variant.color}/>{" "}
                {`${this.props.variant.tournamentName} ${
                  this.props.variant.tournamentIsMultiday ? `, ${this.props.variant.name}` : ""
                }`}
              </label>
            }
          >
            <>
              <Loader className="wv">
                <Table id="table-to-xls">
                  <thead>
                  <tr>
                    <th key="cashier">CASHIER</th>
                    <th key="total_entries">TOTAL ENTRIES</th>
                    <th key="unique_entries">UNIQUE ENTRIES</th>
                    <th key="reentry">REENTRY</th>
                    <th key="rebuy">REBUY</th>
                    <th key="addon">ADDON</th>
                    <th key="money_collected">MONEY COLLECTED</th>
                    <th key="fee">FEE</th>
                    <th colspan="2" key="dealer_tip">
                      DEALERS TIP
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {cashierEntries.map((eachCashier, index) => {
                    var tableValues = this.calculateTableValues(eachCashier);
                    totalTotalEntries += tableValues.buyinFeeCount + tableValues.reentryFeeCount;
                    totalUniqueEntries += tableValues.buyinFeeCount;
                    totalReentries += tableValues.reentryFeeCount;
                    totalRebuy += tableValues.rebuyFeeCount;
                    totalAddon += tableValues.addonFeeCount;
                    totalMoneyCollected +=
                      tableValues.buyinPoolSum +
                      tableValues.reentryPoolSum +
                      tableValues.rebuyPoolSum +
                      tableValues.addonPoolSum;
                    totalFee +=
                      tableValues.buyinFeeSum +
                      tableValues.reentryFeeSum +
                      tableValues.rebuyFeeSum +
                      tableValues.addonFeeSum;
                    /*dont show */
                    return (
                      <tr key={index}>
                        <td>{eachCashier.receivedByAlias}</td>
                        <td>{tableValues.buyinFeeCount + tableValues.reentryFeeCount}</td>
                        <td>{tableValues.buyinFeeCount}</td>
                        <td>{tableValues.reentryFeeCount}</td>
                        <td>{tableValues.rebuyFeeCount}</td>
                        <td>{tableValues.addonFeeCount}</td>
                        <td>
                          {tableValues.buyinPoolSum +
                          tableValues.reentryPoolSum +
                          tableValues.rebuyPoolSum +
                          tableValues.addonPoolSum}
                        </td>
                        <td>
                          {tableValues.buyinFeeSum +
                          tableValues.reentryFeeSum +
                          tableValues.rebuyFeeSum +
                          tableValues.addonFeeSum}
                        </td>
                        <td>{this.getTipForCashier(eachCashier.receivedById)}</td>
                        <td className="d-flex flex-row p-0">
                          <button
                            className="plus-button"
                            onClick={() =>
                              addSubtractTip(this.action.SUBTRACT, eachCashier.receivedById)
                            }
                          >
                            -
                          </button>
                          <input
                            type="number"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <button
                            className="plus-button"
                            onClick={() =>
                              addSubtractTip(this.action.ADD, eachCashier.receivedById)
                            }
                          >
                            +
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr key="total">
                    <td>Total</td>
                    <td>{totalTotalEntries}</td>
                    <td>{totalUniqueEntries}</td>
                    <td>{totalReentries}</td>
                    <td>{totalRebuy}</td>
                    <td>{totalAddon}</td>
                    <td>{totalMoneyCollected}</td>
                    <td>{totalFee}</td>
                    <td colspan="3">Tips</td>
                  </tr>
                  </tbody>
                </Table>
                <div className="footer">
                  <ReactHTMLTableToExcel
                    id="table-xls-button"
                    className="button"
                    table="table-to-xls"
                    filename="BalanceSheet"
                    sheet="balanceSheet"
                    buttonText="Save as Excel"
                  />
                  <Button onClick={this.openPlayerModal}>Player list</Button>
                </div>
              </Loader>
            </>

            {this.state.modalPlayerList && (
              <PlayerListModal
                isOpen={this.state.modalPlayerList}
                toggle={this.togglePlayerList}
                variant={this.props.variant}
                playersUrl={`/player/tournamentvariant/${this.props.variant.id}/registered?returnAsExcel=true`}
              />
            )}
          </CustomModal>
        )}
      </>
    );
  }
}

BalanceSheetModal.defaultProps = {
  isOpen: false,
};

const mapStateToProps = (state) => {
  return {
    fees: state.tournaments.fees,
    tips: state.tournaments.tips,
  };
};

const mapDispatchToProps = {
  getFees,
  getTips,
  addTips,
  getPlayersByStateAndTournamentVariantId,
  getPlayersClean,
};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheetModal);
