import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { formatSeatNum, formatPlayerName } from '../../../util/playerUtil';

class PlayerRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <div className="player-row">
        <div className="col1">
          <h3 style={{ marginBottom: 0 }}>
            {formatSeatNum(this.props.player.seatNum)}
          </h3>
          <span>Seat</span>
        </div>

        <span className="col2">
          {formatPlayerName(this.props.player)}
        </span>

        <div className="col3">
          <h3 style={{ marginBottom: 0 }}>
            {this.props.tableSeat.table}
            /
            {this.props.tableSeat.seat}
          </h3>
          <span>New seat</span>
          <Button
            className="underlined-btn"
            onClick={() => this.props.chgSeatEvent(this.props.player)}
          >
            Change
          </Button>
        </div>

      </div>

    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerRow);
