import { Button } from "react-bootstrap";
import { addExtraTime, changeLevel, changeVariantState } from "../../../actions/tournamentActions";
import moment from "moment-mini";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPause, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { isMobile } from 'react-device-detect';

const LevelUpDown = (props) => {
  const { variant } = props;
  const dispatch = useDispatch();

  const changeLevelDisabled =
    variant.id === undefined || (variant.state !== "active" && variant.state !== "paused");

  return (
    <>
      <div className={isMobile ? "action-panel-mobile" : "action-panel"}>
        <p className="title">Level</p>
        <div className={isMobile ? "buttons" : ""}>
          <Button
            className={`fit ${changeLevelDisabled ? "disabled" : ""}`}
            disabled={changeLevelDisabled}
            onClick={() => {
              dispatch(changeLevel(variant.id, "up"));
            }}
          >
            Up
          </Button>
          <Button
            disabled={changeLevelDisabled}
            className={`fit ${changeLevelDisabled ? "disabled" : ""}`}
            onClick={() => {
              const currentLevel = variant.currentLevel || {};
              if (currentLevel.level >= 1) {
                dispatch(changeLevel(variant.id, "down"));
              }
            }}
          >
            Down
          </Button>
        </div>
      </div>
    </>
  );
};

export default LevelUpDown;
