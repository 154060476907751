import actionTypes from '../actiontypes/tournamentPayoutActionTypes';

const initState = {
  tournamentPayouts: [],
  totalPool: undefined,
  isLoading: false,
  error: undefined,
  payoutsRecalculation: [],
  payoutsRecalculationRemnant: 0,
};
export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TOURNAMENT_PAYOUT_ITEM:
      const tournamentPayouts = [...state.tournamentPayouts];
      tournamentPayouts.push({
        poolPrc: 0,
        position: tournamentPayouts[tournamentPayouts.length - 1] !== undefined ? tournamentPayouts[tournamentPayouts.length - 1].position + 1 : 1,
        ...action.data,
      });
      return {
        ...state,
        tournamentPayouts,
      };
    case actionTypes.GET_PAYOUT_RECALCULATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PAYOUT_RECALCULATION_RESPONSE:
      return {
        ...state,
        isLoading: false,
        payoutsRecalculation: action.data.payoutStructure,
        payoutsRecalculationRemnant: action.data.remnant,
      };
    case actionTypes.CLEAR_PAYOUT_RECALCULATION_RESPONSE:
      return {
        ...state,
        payoutsRecalculation: [],
        payoutsRecalculationRemnant: 0,
      };
    case actionTypes.GET_TOURNAMENT_PAYOUT_REQUEST:
      return {
        ...state,
        isLoading: false,
        tournamentPayouts: [],
        error: undefined,
      };
    case actionTypes.GET_TOURNAMENT_PAYOUT_RESPONSE:
      return {
        ...state,
        isLoading: true,
        tournamentPayouts: action.data.payouts,
        remnant: action.data.remnant,
        totalPool: action.data.totalPool,
        error: undefined,
      };
    case actionTypes.GET_TOURNAMENT_PAYOUT_ERROR:
      return {
        ...state,
        isLoading: true,
        tournamentPayouts: [],
        remnant: undefined,
        totalPool: undefined,
        error: action.error,
      };
    case actionTypes.CLEAR_PAYOUT_RESPONSE:
      return {
        ...state,
        tournamentPayouts: [],
        remnant: undefined,
        totalPool: undefined,
      };
    default:
      return state;
  }
};
