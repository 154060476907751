export const getMessage = (error) => {
  if (error.response && error.response.data) {
    const body = error.response.data;
    if (body.code && body.statusText && body.code.startsWith('IERR')) {
      return body.statusText;
    }
    console.log('Following error can not be parsed!');
    console.log(error);
    return 'Technical error, please report a problem.';
  }
  if (error.message) {
    return error.message;
  }
  console.log('Following error can not be parsed!');
  console.log(error);
  return 'Technical error, please report a problem.';
};

export const traceId = (error) => {
  const traceId = (error && error.response && error.response.headers) ? error.response.headers.traceid : '';
  return traceId
}

export const getTimeFormat = (str) => str.split('T')[1].split('.')[0];

export const getDateTimeFormat = (str) => str.split('T')[0] + ", " + str.split('T')[1].split('.')[0];

export const formattedHoursMinSecBySeconds = (sec) => {
  const hours = `0${Math.floor((sec % (60 * 60 * 60)) / (60 * 60))}`.slice(-2);
  return `${hours}:${formattedMinSecBySeconds(sec)}`;
};
export const formattedMinSecBySeconds = (sec) => {
  const minutes = `0${Math.floor((sec % (60 * 60)) / 60)}`.slice(-2);
  const seconds = `0${Math.floor(sec % 60)}`.slice(-2);
  return `${minutes}:${seconds}`;
};

export const parseUrlNumericParameters = (param) => parseInt(param, 10);
