import React from 'react';
import { Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import CustomModal from '../shared/CustomModal';
import CircleIcon from '../functionalComponents/CircleIcon';
import RegisterCustomer from './RegisterCustomer';
import useConfig from '../useConfig';

const RegisterPlayerModal = (props) => {
  const configData = useConfig();
  const errorCode = useSelector((state) => state.globalError.code);
  const variant = props.variant || {};
  const tournament = variant.tournament || {};

  const getTotalChips = (customer, chips) => {
    let totalChips = chips;
    if (variant.addon) {
      totalChips += customer.addon ? variant.addonChips * customer.addon : 0;
    }
    if (variant.bonus) {
      totalChips += customer.bonus ? variant.bonusChips : 0;
    }
    if (variant.reBuy) {
      totalChips += customer.reBuy ? variant.reBuyChips * customer.reBuy : 0;
    }

    return totalChips;
  };

  const getTotalPayment = (customer, mustPay) => {
    let totalPay = mustPay;
    if (variant.addon) {
      totalPay += customer.addon ? (variant.addonFee + variant.addonPool) * customer.addon : 0;
    }
    if (variant.reBuy) {
      totalPay += customer.reBuy ? (variant.reBuyFee + variant.reBuyPool) * customer.reBuy : 0;
    }
    return totalPay;
  };

  const getTotalChipsReentry = (customer) => getTotalChips(customer, variant.reEntryChips);

  const getTotalChipsBuyin = (customer) => getTotalChips(customer, variant.buyInChips);

  const getTotalPayReentry = (customer) => getTotalPayment(customer, variant.reEntryFee + variant.reEntryPool);

  const getTotalPayBuyin = (customer) => getTotalPayment(customer, variant.buyInFee + variant.buyInPool);

  return (
    <>
      {configData.initialized && (
        <CustomModal
          title='Register player'
          open={props.isOpen}
          closeHandler={props.toggle}
          name="cstmmodal"
          id="cstmmodal"
          className="register-modal"
          dialogClassName="modal-width"
          text={(
            <Form.Label>
              <CircleIcon color={variant.color} />
              {`${props.variant.tournamentName} ${variant.tournamentIsMultiday ? `, ${variant.name}` : ''
                }`}
            </Form.Label>
          )}
        >
          <label className="float-left w-100">Enter credentials for a new customer or use a search box for an existing customer</label>
          {errorCode === 'IERR_PERSON_MAX_REENTRY_REACHED'
            && (
              <Alert style={{ width: '100%' }} variant="danger">
                No. of reentry exceeded
              </Alert>
            )}

          <RegisterCustomer
            isOpen={true}
            tournament={tournament}
            variant={variant}
            toggle={props.toggle}
            isReentry
            config={configData.config}
            getTotalChipsReentry={getTotalChipsReentry}
            getTotalPayReentry={getTotalPayReentry}
            getTotalChipsBuyin={getTotalChipsBuyin}
            getTotalPayBuyin={getTotalPayBuyin}
          />
        </CustomModal>
      )}
    </>
  );
};

export default RegisterPlayerModal;
