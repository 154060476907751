import * as types from '../actiontypes/actionTypes';
import { UNSAVED_CHANGES, UNSAVED_CHANGES_CLEAR, UNSAVED_CHANGES_SHOW } from "../actiontypes/actionTypes";

const initState = {
  isLoading: false,
  loadingCount: 0,
  actionIdList: [],
  unsavedWarning: {
    message: "",
    show: false
  }
};

export default function commonReducer(state = initState, action) {
  let loadingCount;
  switch (action.type) {
    case types.IS_LOADING:
      const actionIdList = Array.from(state.actionIdList);
      if (action.actionId) {
        actionIdList.push(action.actionId);
      }
      loadingCount = state.loadingCount + 1;
      return {
        ...state, isLoading: loadingCount > 0, loadingCount, actionIdList,
      };
    case types.IS_LOADING_DONE:
      const actionIdListForRemove = [...state.actionIdList];
      if (action.actionId) {
        const ind = actionIdListForRemove.indexOf(action.actionId);
        if (ind >= 0) {
          actionIdListForRemove.splice(ind, 1);
        }
      }
      loadingCount = state.loadingCount - 1;
      return {
        ...state, isLoading: loadingCount > 0, loadingCount, actionIdList: actionIdListForRemove,
      };
    case types.UNSAVED_CHANGES:
      return {
        ...state, unsavedWarning: { message: action.data.message, show: false}
      };
    case types.UNSAVED_CHANGES_SHOW:
      return {
        ...state, unsavedWarning: { message: state.unsavedWarning.message, show: true, dispatchData: action.dispatchData, action: action.action}
      };
    case types.UNSAVED_CHANGES_HIDE:
      return {
        ...state, unsavedWarning: { message: state.unsavedWarning.message, show: false}
      };
    case types.UNSAVED_CHANGES_CLEAR:
      return {
        ...state, unsavedWarning: initState.unsavedWarning,
      };
    default:
      return state;
  }
}
