import { store } from '../store/configureStore';
import io from "socket.io-client";
import { socketConst } from '../constants/constants';
import * as socketsActions from '../actions/socketConnectionActions';

class EvSocket {
    constructor() {
        this.store = store;
        this.listeners = {};
        this.isInitialized = false;
        this.socketInst = null;
        console.log('EvSocket.new')
    }

    _handleEvent(eventIdentifier, payload) {
        console.log(`EvSocket._handleEvent ${eventIdentifier} ${JSON.stringify(payload)}`);
        if (this.listeners[eventIdentifier]) {
            this.listeners[eventIdentifier].forEach((each) => {
                console.log(`EvSocket._handleEvent - data processed by ${eventIdentifier} ${each.id}`);
                each.handler(payload, this.store.dispatch);
            });
        }
    }

    on(event, id, handler) {
        console.log(`EvSocket._handleEvent - socket channel register new listener on event '${event}`);
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push({ id, handler });
    }

    removeHandler(event, id) {
        console.log(`EvSocket.removeHandler '${event}`);
        if (!this.listeners[event]) {
            return;
        }
        const index = this.listeners[event].findIndex((each) => each.id === id);
        if (index >= 0) {
            this.listeners[event].splice(index, 1);
        }
    }

    disconnect() {
        if(this.socketInst) {
            console.log('EvSocket.disconnect - try to disconnect socket')
            this.socketInst.close()
        } else {
            console.log('EvSocket.disconnect - socket instance is null')
        }
    }
}

const initSocket = () => {

    const socketUrl = getSocketUrl();

    const newSocket = io(socketUrl, {
        withCredentials: true,
        auth: {
            'xsrf-token': localStorage.getItem('xsrf-token'),
        },
        extraHeaders: {
            "Access-Control-Allow-Origin": "*"
        }
    });

    newSocket.on("connect", () => {
        console.log(`socketInst 'connected' ${newSocket.id}`);
        socket.socketInst = newSocket
        store.dispatch(socketsActions.connectionChanged(socketConst.CONNECTED));
    });

    newSocket.on("disconnect", () => {
        console.log(`socketInst 'disconnect'`);
        store.dispatch(socketsActions.connectionChanged(socketConst.DISCONNECTED));
    });

    newSocket.on("error", () => {
        console.log(`socketInst 'error'`);
        store.dispatch(socketsActions.connectionChanged(socketConst.ERROR));
    });

    newSocket.onAny((data, ...args) => {
        console.log(`socketInst 'onAny' - start`);
        const parsed = JSON.parse(data)
        const {event, payload} = parsed
        console.log(`socketInst 'onAny' - ${event}`);
        socket._handleEvent(event, payload)
    });

    return newSocket;
}

const getSocketUrl = () => {
    const location = window.location;
    const port = process.env.REACT_APP_SOCKET_PORT || location.port;
    const secured =  process.env.REACT_APP_SOCKET_SECURED !== false;
    const hostname = process.env.REACT_APP_SOCKET_HOSTNAME || location.hostname;
    const protocol = secured ? 'http' : 'https';

    const url = `${protocol}://${hostname}:${port}`;

    return url;
}

const socket = new EvSocket()
export default socket;

export { initSocket };
