import { Button } from "react-bootstrap";
import { changeLevel, changeVariantState } from "../../../actions/tournamentActions";
import moment from "moment-mini";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useDispatch } from "react-redux";
import { isMobile } from 'react-device-detect';

const StartPauseClock = ({ variant }) => {

    const dispatch = useDispatch();

    const isBreakFinished = variant.currentLevel
        ? variant.currentLevel.type === "break" && variant.currentLevel.mEndAt < Date.now()
        : false;

    const isPuaseBtnDisabled =
        variant.id === undefined || variant.state !== "active" || isBreakFinished;

    const isStructureDefined = (variant.structure || []).length > 0;

    const isPlayBtnDisabled =
        variant.id === undefined ||
        !isStructureDefined ||
        variant.state === "finished" ||
        (variant.state === "active" && !isBreakFinished);


    return (
        <div className={isMobile ? "action-panel-mobile" : "action-panel"}>
            <p className="title">Pause / start clock</p>
            <div className={isMobile ? "buttons" : ""}>
                <Button
                    color="primary"
                    className={`circle ${isPlayBtnDisabled ? "disabled" : ""}`}
                    disabled={isPlayBtnDisabled}
                    onClick={() => {
                        if (isBreakFinished) {
                            dispatch(changeLevel(variant.id, "up"));
                        } else {
                            const currentLevel = variant.currentLevel || {};
                            const currentLevelLeft = moment().isAfter(currentLevel.mEndAt);
                            if (variant.state === "created" || variant.state === "paused") {
                                dispatch(changeVariantState(variant.id, "start", `${variant.id}`));
                            } else if (currentLevel.requireConfirmOnStart && currentLevelLeft) {
                                dispatch(changeLevel(variant.id, "up"));
                            } else {
                                console.error("Unexpected state of tournament - can not resume");
                            }
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faPlay} />
                </Button>
                <Button
                    className={`circle ${isPuaseBtnDisabled ? "disabled" : ""}`}
                    disabled={isPuaseBtnDisabled}
                    onClick={() => {
                        dispatch(changeVariantState(variant.id, "pause", `${variant.id}`));
                    }}
                >
                    <FontAwesomeIcon icon={faPause} />
                </Button>
            </div>
        </div>)

}

export default StartPauseClock;