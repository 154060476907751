import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from "react-bootstrap";
import { formatDate, formatAmount } from "../../../util/stringUtil";
import CustomModal from "../../shared/CustomModal";
import {
  getPlayersByTournamentVariantId,
  closePlayerInVariant,
} from "../../../actions/playerActions";
import { getTournament } from "../../../actions/tournamentActions";
import { getTournamentPayouts, clearPayout } from "../../../actions/tournamentPayoutActions";

import ViewIcon from "../../../image/icon-view.svg";

const SeatOpenModal = (props) => <SeatOpen {...props} />;

const SeatOpen = (props) => {
  const dispatch = useDispatch();
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [helperValue, setHelperValue] = useState(false);
  const [player, setPlayerWithPosition] = useState({});
  const tournament = useSelector((state) => state.tournaments.tournament || {});
  const tournamentPayouts = useSelector((state) => state.tournamentPayouts.tournamentPayouts || []);

  useEffect(() => {
    GetPlayer();

    if (!tournament || !tournament.id) {
      dispatch(getTournament(props.variant.tournamentId));
    }
  }, []);

  useEffect(
    () => () => {
      dispatch(clearPayout());
    },
    []
  );

  function closePlayer() {
    dispatch(closePlayerInVariant(player.playerInTournamentPhaseVariantId, !checkboxValue));
    props.toggle();
  }

  function GetPlayer() {
    dispatch(
      getPlayersByTournamentVariantId(props.variant.tournamentId, props.player.playerInTournamentId)
    ).then((resp) => {
      setPlayerWithPosition(resp.data[0]);
    });
    dispatch(getTournamentPayouts(props.variant.tournamentId));
  }

  function handleChange() {
    setCheckboxValue(!checkboxValue);
  }

  function handleHelperChange() {
    setHelperValue(!helperValue);
  }

  function isCustomerNotLoaded() {
    return player.firstName === undefined;
  }

  const payout = tournamentPayouts.find((p) => p.position === player.upcomingPosition);

  return (
    <>
      <CustomModal
        title={`Seat open for ${player.firstName} ${player.lastName}`}
        open={props.isOpen}
        closeHandler={props.toggle}
        className="seat-open-modal"
        dialogClassName="modal-width"
      >
        <Row>
          <Col>
            <p className="title float-left">Nationality</p>
          </Col>
          <Col>
            <p className="customer float-right">{props.player.nationality}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="title float-left">ID number</p>
          </Col>
          <Col>
            <p className="customer float-right">{props.player.idCardNum}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="title float-left">Date of birth</p>
          </Col>
          <Col>
            <p className="customer float-right">{formatDate(props.player.dateOfBirth)}</p>
          </Col>
        </Row>
        <Row className="graySeparator" />
        <Row>
          <Col>
            <p className="title float-left">TOURNAMENT</p>
          </Col>
          <Col>
            <p className="title float-right">POSITION</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="customer float-left">{tournament.name}</p>
          </Col>
          <Col>
            <p className="customer float-right">
              {checkboxValue === false ? player.upcomingPosition : ""}
            </p>
          </Col>
        </Row>
        <Row className="graySeparator" />
        <Row>
          <Col>
            <p className="payout-title title float-left">Payout</p>
          </Col>
          <Col>
            <p className="payout float-right">
              {checkboxValue === false && payout ? formatAmount(payout.toPayout, tournament.currency) : ""}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={10} sm={10}>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
                onChange={handleChange}
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Change position or payout manually in Payouts at desktop interface
              </label>
            </div>
          </Col>
          <Col xs={2}>
            <img src={ViewIcon} className="helper-icon" alt="help" onClick={handleHelperChange} />
          </Col>
        </Row>
        <Row>
          <Button disabled={isCustomerNotLoaded()} className="btn-confirm" onClick={closePlayer}>
            {checkboxValue === true
              ? "Confirm seat open"
              : "Confirm seat open, position and payout"}
          </Button>
        </Row>
        {helperValue && (
          <CustomModal
            dialogClassName="modal-width"
            className="helper-modal"
            open
            title="Change position and payout
        manually in Payouts/position
        at desktop interface"
          >
            <>
              <div>
                We recommend using this feature for players who were busted during the hand for hand
                phase. It is possible that they will share the position/payout with other players
                busted in the same hand, and you will need to split prizes manually in
                Payouts/position at desktop interface
              </div>
              <Button onClick={handleHelperChange}>Understand</Button>
            </>
          </CustomModal>
        )}
      </CustomModal>
    </>
  );
};

export default SeatOpenModal;
