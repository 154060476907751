import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

class AddItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      showValidationText: false,
    };
  }

  handleChange(e) {
    this.setState({ inputValue: e.target.value });
  }

  onButtonClick() {
    if (this.props.isValid(this.state.inputValue)) {
      this.setState({ showValidationText: false });
      this.props.onButtonClick(this.state.inputValue);
      this.props.close();
    } else {
      this.setState({ showValidationText: true });
    }
  }

  render() {
    return (
      <>
        {this.props.open && (
          <Modal isOpen={this.props.open} onClosed={this.clearForm} style={{ width: '349px', height: '258px' }}>
            <ModalBody className="light-modal-body">
              <FontAwesomeIcon onClick={() => this.props.close()} icon={faTimes} size="xs" className="string-filter-icon" />
              <div>
                <label>{this.props.inputLabel}</label>
                <input style={{ width: '100%' }} autoFocus type="text" placeholder={this.props.inputPlaceholder} id="roomName" onChange={(e) => this.handleChange(e)} />
                <label className="validation-text" style={{ visibility: this.state.showValidationText ? 'visible' : 'hidden' }}>
                  {this.props.validationText}
                </label>
                <Button style={{ width: '100%' }} onClick={() => this.onButtonClick()}>
                  {this.props.buttonText}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

AddItemModal.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isValid: PropTypes.func,
  validationText: PropTypes.string,
};

AddItemModal.defaultProps = {
  isValid: () => true,
};

export default AddItemModal;
