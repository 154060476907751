import actionTypes from '../actiontypes/redrawRemainingTablesActionTypes';
import restClient from '../util/restClient';
import { combinedRegisterLoaderAndShowError } from './commonActions';

const getReDrawRemainingTablesDraftRequest = () => ({ type: actionTypes.GET_REDRAW_REMAINING_TABLES_DRAFT_REQUEST });
const getReDrawRemainingTablesDraftSuccess = (data) => ({ type: actionTypes.GET_REDRAW_REMAINING_TABLES_DRAFT_RESPONSE, data });

const clearDraftRequest = () => ({ type: actionTypes.CLEAR_DRAFT });

const postReDrawRemainingTablesRequest = () => ({ type: actionTypes.POST_REDRAW_REMAINING_TABLES_REQUEST });
const postReDrawRemainingTablesSuccess = (data) => ({ type: actionTypes.POST_REDRAW_REMAINING_TABLES_RESPONSE, data });

export const getReDrawRemainingTablesDraft = (variantId, numOfTables, tableMaxOccupancy) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getReDrawRemainingTablesDraftRequest());
    return restClient.get(`/redrawTable/${variantId}?numOfTables=${numOfTables}&tableMaxOccupancy=${tableMaxOccupancy}`).then((response) => {
      dispatch(getReDrawRemainingTablesDraftSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'getReDrawRemainingTablesDraft',
  'Can not resolve new player seats',
);

export const clearDraft = () => (dispatch) => {
  dispatch(clearDraftRequest());
};

export const postReDrawRemainingTables = (variantId, draft) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(postReDrawRemainingTablesRequest());
    return restClient.post(`/redrawTable/${variantId}`, draft).then((response) => {
      dispatch(postReDrawRemainingTablesSuccess(response.data));
      return Promise.resolve(response);
    });
  },
  'postReDrawRemainingTables',
  'Can not redraw tables',
);
