import React from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment-mini';
import classNames from 'classnames';
import currentUserUtil from '../../../util/currentUser';

const displayReplyButton = (msg) => !(msg.replyOnId || currentUserUtil.getUserIdentifier() === msg.identifier);

const ChatMsg = ({ msg, toogle }) => (
  <>
    <Col xs={7} className={classNames({ 'text-right': msg.replyOnId })}>
      <Row>{msg.textMessage}</Row>
    </Col>
    <Col xs={5}>
      <Row className="justify-content-end">
        <Col xs={3}>
          <Row>{moment(msg.sentAt).format('HH:MM')}</Row>
        </Col>
        <Col xs={7}>
          <Row>
            <strong>{msg.authorAlias}</strong>
          </Row>
        </Col>
        <Col xs={2}>
          <Row>{displayReplyButton(msg)}</Row>
        </Col>
      </Row>
    </Col>
  </>
);

export default ChatMsg;
