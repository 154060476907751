import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Prompt } from "react-router-dom";
import { MainInfo, EventNoInfo, PhasesInfo, PoolInfo } from "./mttGeneralSettings/GeneralSettings";
import BasicVariant from "./detailcomponents/BasicVariant";
import Basic from "./detailcomponents/Basic";
import Loader from "../shared/Loader";
import { createNewVariant, getFlatVariantList, isMultiday } from "../../util/tournamentUtil";
import TournamentHeader from "../shared/TournamentHeader";
import UpdatePlayerStateWrapper from "../shared/UpdatePlayerStateWrapper";
import VariantPicker from "../shared/VariantPicker";

const GeneralMttSettings = (props) => {
  const { tournament } = props;

  const handleChange = (e) => {
    const value = e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
    if (tournament[e.target.id] !== value) {
      tournament[e.target.id] = value;
      props.updateTournamentDetail(tournament);
    }
  };

  const handlePhases = (numOfPhases) => {
    if (tournament.tournamentPhases.length < numOfPhases) {
      for (let day = tournament.tournamentPhases.length + 1; day <= numOfPhases; day++) {
        tournament.tournamentPhases.push({
          phase: day,
          variants: [
            createNewVariant(`${day}A`, tournament.tableSize, tournament.tableMaxOccupancy, day > 1 ? 1 : 0),
          ],
        });
      }
    } else if (tournament.tournamentPhases.length > numOfPhases) {
      const phases = tournament.tournamentPhases.slice(0, numOfPhases);
      tournament.tournamentPhases = phases;
    }
    tournament.numOfPhases = numOfPhases;

    props.updateTournamentDetail(tournament);
  };

  const handleOptionalField = (field, enabled) => {
    if (!enabled) {
      delete tournament[field];
    } else {
      tournament[field] = "";
    }

    props.updateTournamentDetail(tournament);
  };

  const errors = Basic.getValidationErrors(tournament);
  return (
    <div className="general-mtt-settings">
      <div className="mw-800">
        <EventNoInfo
          tournament={tournament}
          handleOptionalField={handleOptionalField}
          handleChange={handleChange}
          errors={errors}
        />
        <MainInfo handleChange={handleChange} errors={{}} tournament={tournament} />
        <PoolInfo
          tournament={tournament}
          handleOptionalField={handleOptionalField}
          handleChange={handleChange}
          errors={errors}
        />
      </div>
      {!props.isTemplateMode && (
        <Prompt
          when={tournament && tournament.isSaved === false}
          message={() => 'Are you sure? Please press "Save all changes" to save your changes.'}
        />
      )}
    </div>
  );
};

const DayFlightSettings = (props) => {
  const { tournament } = props;
  const [variantId, setVariantId] = useState();
  const variants = getFlatVariantList(tournament);
  let variant;
  if (variantId) {
    variant = variants.find((varr) => varr.id === variantId);
  } else {
    variant = variants[0];
  }

  if (isMultiday(tournament)) {
    return (
      <UpdatePlayerStateWrapper >
        <VariantPicker hideHeader tournament={tournament} isEditMode={true}
          getContent={(variant) => {
            return (
              <div className="flight-settings">
                <Row className="mw-820">
                  <BasicVariant
                    isTemplateMode={props.isTemplateMode}
                    variant={variant}
                    tournament={tournament}
                    updateTournamentDetail={(updatedTournament, updatedVariant) => {
                      props.onVariantUpdate(updatedTournament, updatedVariant);
                    }}
                    showTitle={false}
                  />
                </Row>
              </div>
            );
          }} />
      </UpdatePlayerStateWrapper >
    );
  }
  return (
    <div className="flight-settings">
      <div className="mw-800">
        <BasicVariant
          isTemplateMode={props.isTemplateMode}
          variant={variant}
          tournament={tournament}
          updateTournamentDetail={(updatedTournament, updatedVariant) => {
            props.onVariantUpdate(updatedTournament, updatedVariant);
          }}
          showTitle={false}
        />
      </div>
    </div>
  );
};

const TournamentSettingComp = (props) => {
  const { tournament } = props;
  const [showGeneralSettings, setShowGeneralSettings] = useState(true);

  const setShowGeneralSettingsWrap = (e, value) => {
    setShowGeneralSettings(value);
    e.preventDefault();
  };

  return (
    <>
      {tournament && (
        <div className="pl-3 full-height">
          {props.isTemplateMode && (
            <Row className="pt-2 pb-2">
              <Col xs={2}>
                <span className="mr-2">MTT template name </span>
                {props.isCreateMode && (
                  <Input
                    type="text"
                    className="highlighted black-input d-inline"
                    value={tournament.name}
                    onChange={(e) => props.setupName(e.target.value)}
                  />
                )}
                {!props.isCreateMode && (
                  <Input
                    type="text"
                    className="highlighted black-input d-inline"
                    value={props.templateName}
                    defaultValue={props.originalName}
                    onChange={(e) => {
                      props.setupName(e.target.value);
                      props.updateTournament(props.tournament, undefined, e.target.value);
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          <TournamentHeader tournament={tournament} />
          <div className="header">
            <a
              href="#"
              className={`${showGeneralSettings ? "active" : "pad-right non-active"}`}
              onClick={(e) => setShowGeneralSettingsWrap(e, true)}
            >
              <h3>General MTT settings</h3>
            </a>
            <a
              href="#"
              className={`${showGeneralSettings ? "pad-left non-active" : "active"}`}
              onClick={(e) => setShowGeneralSettingsWrap(e, false)}
            >
              <h3>{isMultiday(tournament) ? "Flight settings" : "Daily MTT setup"}</h3>
            </a>
            <div className="non-active rest" />
          </div>
          {showGeneralSettings ? (
            <GeneralMttSettings
              isTemplateMode={props.isTemplateMode}
              tournament={tournament}
              updateTournamentDetail={(updatedTournament) => {
                props.updateTournament(updatedTournament);
              }}
            />
          ) : (
            <DayFlightSettings
              isTemplateMode={props.isTemplateMode}
              tournament={tournament}
              onVariantChange={(variantId) => {
                props.updateTournament(tournament);
              }}
              onVariantUpdate={(updatedTournament, updatedVariant) => {
                props.updateTournament(updatedTournament, updatedVariant);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

const EditTournamentComp = (props) => {
  const { tournament } = props;
  const [showValidationWarningModal, setShowValidationWarningModal] = useState(false);
  const closeValidationWarningModal = () => {
    setShowValidationWarningModal(false);
  };
  const resetChanges = () => {
    props.reloadTournament(tournament.id);
  };

  const saveAllChanges = () => {
    // const errors = Object.values(Basic.getValidationErrors(tournament))
    // const realErrors = errors.find(
    //     (val) => val
    // )
    // console.log("real errors: " + JSON.stringify(realErrors))

    const hasGeneralSettingsErrors = Object.values(Basic.getValidationErrors(tournament)).some(
      (val) => val
    );
    const hasDayFlightSettingsErrors = tournament.tournamentPhases
      .flatMap((phase) => phase.variants)
      .map((variant) => {
        // const variantErrors = BasicVariant.getValidationErrors(tournament, variant)
        // Object.keys(variantErrors).forEach(k => {
        //   if(variantErrors[k]) {
        //     console.log(`variant ${variant.id} real error ${k}`)
        //   }
        // })

        return Object.values(BasicVariant.getValidationErrors(tournament, variant)).some((val) => val)
      }



      )
      .some((val) => val);

    if (hasGeneralSettingsErrors || hasDayFlightSettingsErrors) {
      setShowValidationWarningModal(true);
    } else {
      props.storeTournament(tournament);
    }
  };
  return (
    <Loader className="full-height">
      {tournament && (
        <div id="editMttSetting">
          {tournament && (
            <TournamentSettingComp
              tournament={tournament}
              updateTournament={props.updateTournament}
            />
          )}

          <div className="confirm-buttons">
            <Button className="btn-primary fit left" onClick={() => resetChanges()}>
              Cancel changes
            </Button>

            <Button className="right fit" onClick={() => saveAllChanges()}>
              Save all changes
            </Button>
          </div>

          {showValidationWarningModal && (
            <Modal id="modShowValidationWarning" isOpen={showValidationWarningModal}>
              <ModalHeader>Warning</ModalHeader>
              <ModalBody>
                <h3>Fix errors before save!</h3>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={closeValidationWarningModal}>
                  OK
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      )}
    </Loader>
  );
};

export default EditTournamentComp;
export { TournamentSettingComp };
