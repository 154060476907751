import React from 'react';
import { Row, Container, Collapse } from 'react-bootstrap';
import ToggleSwitch from './ToggleSwitch';

export default function CollapsibleToggleSwitch(props) {
  return (
    <>
      <h3>{props.text}</h3>
      <ToggleSwitch field={props.field} switchValue={props.switchValue} isSwitchedOn={props.isSwitchedOn} />
      <div className="separator" />
      <Collapse in={props.isSwitchedOn}>
        <Container>
          <Row>{props.children}</Row>
        </Container>
      </Collapse>
    </>
  );
}
