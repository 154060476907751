import React, {useState} from "react";
import {formatAmount, toBoolean} from "../../../../util/stringUtil";
import {useSelector} from "react-redux";

const TilePrizePool = ({ variant, tournamentStats }) => {

    const [hidePayouts, setHidePayouts] = useState(false);
    const clockSettings = useSelector((state) => state.tournaments.clockSettings);

    if(clockSettings.isLoaded && toBoolean(clockSettings.data.hide_payouts) !== hidePayouts) {
        setHidePayouts(toBoolean(clockSettings.data.hide_payouts))
    }

    return (
        <div className="item">
            <div className="title">Prizepool</div>
            <div className="text">
                {hidePayouts ? "---" : formatAmount(Math.floor(tournamentStats.poolValue), variant.tournament.currency, 0)}
            </div>
        </div>
    )
}

export default TilePrizePool;