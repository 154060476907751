import React from "react";
import Ticker from "react-ticker";

const TextScroller = ({ text }) => {
  if (text.length > 0) {
    return (
      <Ticker mode={"await"}>
        {({}) => (
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "pre",
            }}
          >
            {text}
          </span>
        )}
      </Ticker>
    );
  }
  return <div />;
};

export default TextScroller;
