import React from "react";

const TileUniquePlayers = ( { tournamentStats }) => {

    return (
        <div className="item">
            <div className="title">Unique players</div>
            <div className="text">
                {tournamentStats.buyInCount > 0 ? tournamentStats.buyInCount : 0}
            </div>
        </div>
    )
}

export default TileUniquePlayers;