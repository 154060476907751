import React from 'react';
import { Col } from 'react-bootstrap';

const FloorChat = (props) => <Col sm={12} />;

FloorChat.defaultProps = {
  match: { params: { variantId: undefined } },
};

export default FloorChat;
