import React, { useState } from "react";
import Switch from 'react-switch';
import { Button, Input } from "reactstrap";
import { ButtonGroup } from "react-bootstrap";

export default function PlusMinus(props) {
  const [value, setValue] = useState(0);
  const {max} = props;

  function plus() {
    setValue(value + 1)
    props.onChange({target: {value: value + 1, name: props.name}})

  }

  function minus() {
    setValue(value - 1)
    props.onChange({target: {value: value  - 1, name: props.name}})
  }

  return (
    <ButtonGroup className="plusminus"  {...props}>
      <Button color="primary" onClick={plus} disabled={props.disabled || (max && value >= max)}>+</Button>
      <Input disabled className="txt"
        id="addpms"
         value={value}
      />
      <Button color="primary" disabled={props.disabled || value <= 0} onClick={minus}>-</Button>
    </ButtonGroup>
  );
}
