import React, { useState, useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import PokerTable from '../mttdirector/roomLayout/PokerTable';

export default function SpaceGrid(props) {
  const getPokerTables = (grid) => {
    if (grid) {
      return grid.map((r, i) => (
        <tr key={`row_${i}`}>
          {r.map((d, j) => (
            <PokerTable key={i + j} {...props} i={i} j={j} table={grid[i][j]} />
          ))}
        </tr>
      ));
    }
    return null;
  };

  return (
    <ScrollContainer className="scrollable-space" vertical horizontal>
      <table id="table">
        <tbody>{getPokerTables(props.grid)}</tbody>
      </table>
    </ScrollContainer>
  );
}
