import React from "react";
import {connect} from "react-redux";
import {deleteUnsavedTournamentDetail, updateTournament, updateTournamentDetail,getTournament, initTournamentToSave} from "../../actions/tournamentActions";
import EditTournamentComp from "./EditTournamentComp";

class EditTournamentContainer extends React.Component {
  componentDidMount() {
    const { tournament } = this.props;
    const tournamentToSave = { ...tournament };

    this.props.initTournamentToSave(tournamentToSave);
  }

  componentWillUnmount() {
    this.props.deleteUnsavedTournamentDetail();
  }

  invokeUpdateTournamentDetail = (tournament, variant) => {
    if(variant){
      (tournament.tournamentPhases || []).forEach(eachPhase => {
        eachPhase.variants = (eachPhase.variants || []).map(eachVariant => {
          if(eachVariant.id === variant.id) {
            return {...eachVariant, ...variant};
          }else{
              return eachVariant;
          }
        });
      });
    }
    this.props.updateTournamentDetail(tournament);
  };

  render() {
    return (
      <EditTournamentComp
          tournament={this.props.tournament}
          updateTournament={this.invokeUpdateTournamentDetail}
          storeTournament={(tournament) => {
            this.props.updateTournament(tournament);
          }}
          reloadTournament={(id) => {
            this.props.getTournament(id);
        }}/>
    );
  }
}

const mapStateToProps = state => {
  const stateTournament = state.tournaments.tournament;
  if (!state.tournaments.tournament.id) {
    return {
      tournament: undefined
    };
  }
  const isMultiday = stateTournament.tournamentPhases.length > 1 || (stateTournament.tournamentPhases && (stateTournament.tournamentPhases[0].variants || []).length > 1);

  return {
    tournament: state.tournaments.tournamentToSave
      ? state.tournaments.tournamentToSave
      : {
          ...state.tournaments.tournament,
          numOfPhases: stateTournament.tournamentPhases.length,
          isMultiday
        }
  };
};

const mapDispatchToProps = {
  getTournament,
  updateTournamentDetail,
  updateTournament,
  deleteUnsavedTournamentDetail,
  initTournamentToSave
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTournamentContainer);
