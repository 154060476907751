import React, { useState, useEffect } from "react";
import { Col, Row, Input } from "reactstrap";
import { Button } from "react-bootstrap";
import FinishDay from '../../../image/icon-finish-day.svg';
import Collapse from "../Collapse";
import CustomModal from '../CustomModal';
import HelpTexts from '../../../help-texts.json';

const Help = () => {

    const [showHelpModal, setShowHelpModal] = useState(false);
    const [activeHelpText, setActiveHelpText] = useState("");
    const [searchHelpText, setSearchHelpText] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [searchMode, setSearchMode] = useState(false);

    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }

    useEffect(() => {
        if (searchHelpText === "")
            setSearchMode(false);
    }, [searchHelpText]);

    const images = importAll(require.context('../../../image/help', false, /\.(png|jpe?g|svg)$/));

    const onSearchClick = () => {
        var findings = [];

        if (searchMode === true) {
            setSearchHelpText("");
            setSearchMode(false);
            return;
        }

        setSearchMode(true);

        var texts = HelpTexts.Cards.map(c => c.Steps.find((s, index) => {
            if (s.Text) {
                if (s.Text.toLowerCase().includes(searchHelpText.toLowerCase())) {
                    findings.push({ "Id": c.Id, "StepNumber": index + 1, "Title": c.Title, "Text": s.Text });
                }
            }
            if (s.SubTexts.length > 0) {
                s.SubTexts.map(st => {
                    if (st.Text) {
                        if (st.Text.toLowerCase().includes(searchHelpText.toLowerCase())) {
                            findings.push({ "Id": c.Id, "StepNumber": index + 1, "Title": c.Title, "Text": s.Text, "Text2": st.Text });
                        }

                        if (st.SubTexts) {
                            st.SubTexts.map(st2 => {
                                if (st2.Text) {
                                    if (st2.Text.toLowerCase().includes(searchHelpText.toLowerCase())) {
                                        findings.push({ "Id": c.Id, "StepNumber": index + 1, "Title": c.Title, "Text": s.Text, "Text2": st.Text, "Text3": st2.Text });
                                    }

                                    if (st2.SubTexts) {
                                        st2.SubTexts.map(st3 => {
                                            if (st3.Text) {
                                                if (st3.Text.toLowerCase().includes(searchHelpText.toLowerCase())) {
                                                    findings.push({ "Id": c.Id, "StepNumber": index + 1, "Title": c.Title, "Text": s.Text, "Text2": st.Text, "Text3": st2.Text, "Text4": st3.Text });
                                                }
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
            }
        }))

        setSearchResult(findings);
    }

    const onSearchLinkClick = (id) => {
        var card = HelpTexts.Cards.find(c => c.Id === id);
        setActiveHelpText(card);
        setShowHelpModal(true);
    }

    return (
        <>
            <Row className="mt-2">
                <Col>
                    <h2>{"Learn and level up"}</h2>
                </Col>
                <Col className="help-search">
                    <Input disabled={searchMode === true} placeholder="Search what you are looking for" value={searchHelpText} onChange={(e) => setSearchHelpText(e.target.value)}></Input>
                    <Button disabled={searchHelpText === ""} onClick={() => onSearchClick()}>{searchMode === false ? "Search" : "Clear"}</Button>
                </Col>
            </Row>
            <Row>
                <div className="line" />
            </Row>
            {
                searchMode === false ? HelpTexts.Cards.map(card =>
                    <div className="help-cards" onClick={() => { setShowHelpModal(true), setActiveHelpText(card) }}>
                        {card.Img !== "" ? <input id={card.Id} type="image" src={images[card.Img].default ? images[card.Img].default : images[card.Img]} /> : <input id="first-run" type="image" src={FinishDay} />}
                        <label>{card.Title}</label>
                        <label className="link">Read more</label>
                    </div>
                )
                    :
                    <>
                        <Row>
                            <Col>
                                <h3>{searchResult.length + " results for \"" + searchHelpText + "\" in all categories"}</h3>
                            </Col>
                        </Row>
                        {
                            searchResult.map(search => {
                                return <Row className="help-search-result">
                                    <Col sm={1}><label className="circle">{search.StepNumber}</label></Col>
                                    <Col>
                                        <Row>
                                            {search.Text}
                                        </Row>
                                        {search.Text2 && <Row className="navigation mt-2">
                                            <Col xs={1} className="w-auto"><i className="point" /></Col>
                                            <Col className="w-auto">{search.Text2}</Col>
                                        </Row>}
                                        {search.Text3 && <Row className="navigation point-circle-margin">
                                            <Col xs={1}><i className="point-circle" /></Col>
                                            <Col>{search.Text3}</Col>
                                        </Row>}
                                        {search.Text4 && <Row className="navigation square-margin">
                                            <Col xs={1}><i className="square" /></Col>
                                            <Col>{search.Text4}</Col>
                                        </Row>}
                                        <Row>
                                            <label onClick={() => onSearchLinkClick(search.Id)} className="link">{search.Title}</label>
                                        </Row>
                                    </Col>

                                </Row>
                            })
                        }
                    </>
            }
            <HelpModal isOpen={showHelpModal} toggle={() => setShowHelpModal(!showHelpModal)} helpTexts={activeHelpText} images={images} />
        </>

    );
}

export const HelpContent = (props) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
        <Row>
            <Col sm={2}><label className="circle">{props.index + 1}</label></Col>
            <Col>
                <Row>{props.step.Text}</Row>
                <Collapse isOpen={!isOpened}>
                    {props.step.SubTexts && props.step.SubTexts.map(subText => (
                        <>
                            <Row className="navigation mt-2">
                                <Col xs={1} className="w-auto"><i className="point" /></Col>
                                <Col className="w-auto">{subText.Text}</Col>
                            </Row>
                            {subText.SubTexts && subText.SubTexts.map(text => (
                                <>
                                    <Row className="navigation point-circle-margin">
                                        <Col xs={1}><i className="point-circle" /></Col>
                                        <Col>{text.Text}</Col>
                                    </Row>
                                    {text.SubTexts && text.SubTexts.map(text => (
                                        <Row className="navigation square-margin">
                                            <Col xs={1}><i className="square" /></Col>
                                            <Col>{text.Text}</Col>
                                        </Row>
                                    ))}
                                </>
                            ))}
                        </>
                    ))}

                </Collapse>
                <Collapse isOpen={isOpened}>
                    {props.step.SubTexts && props.step.SubTexts.map(subText => (
                        <>
                            <Row className="navigation mt-2">
                                <Col xs={1} className="w-auto"><i className="point" /></Col>
                                <Col className="w-auto">{subText.Text}</Col>
                            </Row>
                            {subText.Img.length > 0 && subText.Img.map(i => (
                                <Row>
                                    <img className="flow-img" src={props.images[i].default ? props.images[i].default : props.images[i]} />
                                </Row>
                            ))}
                            {subText.SubTexts && subText.SubTexts.map(text => (
                                <>
                                    <Row className="navigation point-circle-margin">
                                        <Col xs={1}><i className="point-circle" /></Col>
                                        <Col>{text.Text}</Col>
                                    </Row>
                                    {text.SubTexts && text.SubTexts.map(text => (
                                        <Row className="navigation square-margin">
                                            <Col xs={1}><i className="square" /></Col>
                                            <Col>{text.Text}</Col>
                                        </Row>
                                    ))}
                                </>
                            ))}
                        </>
                    ))}
                </Collapse>
                <Row>
                    <Button key={props.index} variant={isOpened ? "primary" : "outline-primary"} onClick={() => { setIsOpened(!isOpened) }}>{isOpened ? "Hide flow" : "Show flow"}</Button>
                </Row>
            </Col>
        </Row>
    )

}

export const HelpModal = (props) => {

    return (
        <>
            <CustomModal
                open={props.isOpen}
                closeHandler={props.toggle}
                name="cstmmodal"
                id="cstmmodal"
                className="dark help-modal"
                dialogClassName="modal-width"
            >
                <>
                    <h3>{props.helpTexts.Title}</h3>
                    <div className="line" />
                    {props.helpTexts && props.helpTexts.Steps.map((step, index) => (
                        <HelpContent key={index} index={index} step={step} images={props.images} ></HelpContent>
                    ))}
                </>
            </CustomModal>
        </>
    )
}

export default Help;
