import actionTypes from '../actiontypes/tournamentPayoutActionTypes';
import { combinedRegisterLoaderAndShowError, registerLoader } from './commonActions';
import restClient from '../util/restClient';

const getPayoutRecalculationRequest = () => ({ type: actionTypes.GET_PAYOUT_RECALCULATION_REQUEST });
const getPayoutRecalculationSuccess = (data) => ({ type: actionTypes.GET_PAYOUT_RECALCULATION_RESPONSE, data });
const clearPayoutRecalculationSuccess = () => ({ type: actionTypes.CLEAR_PAYOUT_RECALCULATION_RESPONSE });
const clearPayoutSuccess = () => ({ type: actionTypes.CLEAR_PAYOUT_RESPONSE });

const getTournamentPayoutsRequest = () => ({ type: actionTypes.GET_TOURNAMENT_PAYOUT_REQUEST });
const getTournamentPayoutsResponse = (data) => ({ type: actionTypes.GET_TOURNAMENT_PAYOUT_RESPONSE, data });

const addTournamentPayoutRequest = (data) => ({ type: actionTypes.ADD_TOURNAMENT_PAYOUT_ITEM, data });

export const addTournamentPayout = (payoutItem) => (dispatch) => {
  dispatch(addTournamentPayoutRequest(payoutItem));
};

export const getTournamentPayouts = (tournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getTournamentPayoutsRequest());
    return restClient.get(`/tournament/payout/${tournamentId}`).then((response) => {
      dispatch(getTournamentPayoutsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTournamentPayouts',
  'Can not resolve tournament payouts',
);

export const updateTournamentPayouts = (tournamentId) => (dispatch) => {
  restClient.get(`/tournament/payout/${tournamentId}`)
    .then((response) => {
      dispatch(getTournamentPayoutsResponse(response.data));
      return Promise.resolve(response);
    });
};

export const payoutRecalculation = (totalPool, numTopPlayersRedistributingRemnantPool, payoutstructure) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPayoutRecalculationRequest());
    return restClient
      .post('/tournament/payout/recalculate', {
        totalPool, numTopPlayersRedistributingRemnantPool, payoutstructure,
      })
      .then((response) => {
        dispatch(getPayoutRecalculationSuccess(response.data));
        return Promise.resolve(response);
      });
  },
  'payoutRecalculation',
  'Can not recalculate payouts',
);

export const clearPayoutRecalculation = () => (dispatch) => {
  dispatch(clearPayoutRecalculationSuccess());
};

export const clearPayout = () => (dispatch) => {
  dispatch(clearPayoutSuccess());
};
