import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Router, Switch, useLocation, Prompt } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Button, NavItem } from "reactstrap";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "../service/history";
import MttDetail from "../components/mttdirector/MttDetail";
import Help from "../components/shared/help/Help";
import DirectorClock from "../components/mttdirector/DirectorClock";
import SpectatorClock from "../components/mttdirector/SpectatorClock";
import Payouts from "../components/mttdirector/Payouts";
import RedrawRemainingTables from "../components/mttdirector/RedrawRemainingTables";
import RoomLayout from "../components/mttdirector/roomLayout/RoomLayout";
import TournamentList from "../components/tournament/TournamentList";
import GetSingleTournamentDataWrapper from "../components/shared/GetSingleTournamentDataWrapper";
import { ReactComponent as IconTables } from "../image/icon-tables.svg";
import { ReactComponent as IconTemplates } from "../image/icon-templates.svg";
import { ReactComponent as IconPokerRoomLayout } from "../image/icon-poker-room-layout.svg";
import { ReactComponent as IconAdministration } from "../image/icon-administration.svg";
import { ReactComponent as IconMttList } from "../image/icon-mtt-list.svg";
import { ReactComponent as IconManageClock } from "../image/icon-manage-clock.svg";
import { ReactComponent as IconPayouts } from "../image/icon-payouts.svg";
import { ReactComponent as IconPlayers } from "../image/icon-players.svg";
import { ReactComponent as IconWaitingListCoordinator } from "../image/icon-waiting-list-coordinator.svg";
import { ReactComponent as IconMessages } from "../image/icon-message.svg";
import { ReactComponent as IconSwap } from "../image/icon-swap.svg";
import TableList from "../components/tournament/floor/TableList";
import Players from "../components/tournament/floor/Players";
import FloorChat from "../components/tournament/floor/Chat";
import UserManagement from "../components/user/UserManagement";
import PlayersManagement from "../components/user/PlayersManagement";
import PlayerDetail from "../components/tournament/floor/PlayerDetail";
import TableDetail from "../components/tournament/floor/TableDetail";
import WaitingList from "../components/mttdirector/WaitingList";
import TemplatePayout from "../components/mttdirector/TemplatePayout";
import TemplateStructure from "../components/mttdirector/TemplateStructure";
import TemplateMtt from "../components/mttdirector/TemplateMtt";
import EditTournamentContainer from "../components/mttdirector/EditTournamentContainer";
import EditStructure from "../components/mttdirector/EditStructure";
import StructureMobile from "../components/mttdirector/tournamentStructure/StructureMobile";
import DirectorMainMenuHeader from "../components/mttdirector/DirectorMainMenuHeader";
import { ReactComponent as Logo } from "../image/icon-ev-logo.svg";
import UpdatePlayerStateWrapper from "../components/shared/UpdatePlayerStateWrapper";
import { parseUrlNumericParameters } from "../util/parseString";
import NavLink from "../components/shared/NavLink";
import NavLinkWaitingList from "../components/shared/NavLinkWaitingList";
import Collapse from "../components/shared/Collapse";
import TemplateDetail from "../components/mttdirector/TemplateDetail";
import TemplateCreate from "../components/mttdirector/TemplateCreate";
import LogHistory from "../components/tournament/LogHistory";
import ReseatForNewDay from "../components/tournament/reseatForNewDay/ReseatForNewDay";
import ReseatHistory from "../components/tournament/reseatForNewDay/ReseatHistory";
import CustomModal from "../components/shared/CustomModal";
import { ReactComponent as DqIcon } from "../image/icon-DQ.svg";
import { UNSAVED_CHANGES_CLEAR, UNSAVED_CHANGES_HIDE } from "../actiontypes/actionTypes";
import ReseatDetail from "../components/tournament/reseatForNewDay/ReseatDetail";
import CasinoSettings from "../components/mttdirector/casinoSettings/CasinoSettings";
import BottomNavbarMobile from "../components/shared/BottomNavbarMobile";

const redirect = (path) => {
  history.push(path);
};

const MttDirectorPage = (props) => {
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);
  const toggleAdmin = (value) => setIsOpenAdmin(value || !isOpenAdmin);

  const dispatch = useDispatch();

  const unsavedWarning = useSelector((state) => state.common.unsavedWarning);

  const [isOpenTemplates, setIsOpenTemplates] = useState(false);
  const toggleTemplates = (value) => {
    setIsOpenTemplates(value || !isOpenTemplates);
  };

  const [isOpenManageMTT, setIsOpenManageMTT] = useState(false);
  const toggleManageMTT = (value) => setIsOpenManageMTT(value || !isOpenManageMTT);

  const { playersCount } = useSelector((state) => state.player);
  const tournament = useSelector((state) => state.tournaments.tournament);
  const search = new URLSearchParams(useLocation().location?.search);

  return (
    <Container id="mttDirectorPage" fluid="fluid" key={history.location.pathname}>
      <Prompt when={unsavedWarning.message ? true : false} message="warning" />
      <BrowserRouter forceRefresh={true}>
        <Switch>
          <Route
            path="/tournament/:tournamentId"
            render={(props) => {
              const match = props.match || { params: {} };
              const tournamentUrl = `/tournament/${match.params.tournamentId}`;
              const tournamentId = parseUrlNumericParameters(match.params.tournamentId);
              return (
                <Row className="full-screen">
                  <Col md={2} className="submenu d-none d-md-block">
                    <NavItem>
                      <Logo />
                    </NavItem>
                    <NavItem className="mt30">
                      <Button
                        color="primary"
                        className="btn-back"
                        onClick={() => {
                          sessionStorage.setItem("variantId", "");
                          sessionStorage.setItem("tournamentId", "");
                          localStorage.setItem("showTablesWarning", "");
                          redirect("/");
                        }}
                      >{`< Back to MTT list`}</Button>
                    </NavItem>
                    <NavItem className="mt20 group">
                      {/*<NavLink*/}
                      {/*  href="#"*/}
                      {/*  id="nlManageClock"*/}
                      {/*  onClick={() => redirect(`${tournamentUrl}/manageclock`)}*/}
                      {/*>*/}
                      {/*  <IconManageClock className="card-icon tables" />*/}
                      {/*  Manage clock*/}
                      {/*</NavLink>*/}

                      <NavLink
                        href="#"
                        id="nlDirectorClock"
                        onClick={() => redirect(`${tournamentUrl}/director-clock`)}
                      >
                        <IconManageClock className="card-icon tables" />
                        Manage clock
                      </NavLink>

                      <NavLink
                        id="nlPayout"
                        href="#"
                        onClick={() => {
                          redirect(`${tournamentUrl}/payout`);
                        }}
                      >
                        <IconPayouts className="card-icon tables" />
                        Payout
                      </NavLink>
                      <div className="line" />
                      <NavLink
                        id="nlManageMtt"
                        isdropdownparent="true"
                        href="#"
                        onClick={() => toggleManageMTT()}
                        isopen={isOpenManageMTT ? "true" : "false"}
                      >
                        <IconAdministration className="card-icon tables" />
                        Manage MTT
                      </NavLink>
                      <Collapse isOpen={isOpenManageMTT}>
                        <NavLink
                          id="nlMttSettings"
                          href="#"
                          opendropdown={() => {
                            toggleManageMTT(true);
                          }}
                          onClick={() => redirect(`${tournamentUrl}/manage/settings`)}
                        >
                          <FontAwesomeIcon icon={faListUl} className="card-icon" />
                          MTT settings
                        </NavLink>
                        <NavLink
                          id="nlStructure"
                          href="#"
                          opendropdown={() => {
                            toggleManageMTT(true);
                          }}
                          onClick={() => redirect(`${tournamentUrl}/manage/structure`)}
                        >
                          <FontAwesomeIcon icon={faListUl} className="card-icon" />
                          Structure
                        </NavLink>
                      </Collapse>
                      <NavLink isdisabled="true" id="nlTasks" onClick={(e) => e.preventDefault()}>
                        <IconMttList className="card-icon tables" />
                        Tasks
                      </NavLink>
                      <NavLink
                        id="nlTables"
                        href="#"
                        onClick={() => {
                          redirect(`${tournamentUrl}`);
                        }}
                      >
                        <IconTables className="card-icon tables" />
                        Tables
                      </NavLink>
                      <NavLink
                        id="nlPlayers"
                        href="#"
                        onClick={() => {
                          redirect(`${tournamentUrl}/players`);
                        }}
                      >
                        <IconPlayers className="card-icon tables" />
                        Players
                      </NavLink>
                      <NavLink
                        isdisabled="true"
                        href="#"
                        id="nlMessages"
                        onClick={() => {
                          redirect(`${tournamentUrl}/chat`);
                        }}
                      >
                        <IconMessages className="card-icon tables" />
                        Messages
                      </NavLink>
                      <div className="line" />
                      <NavLink
                        id="nlWaitingList"
                        href="#"
                        onClick={() => {
                          redirect(`${tournamentUrl}/waitinglist`);
                        }}
                      >
                        <IconWaitingListCoordinator className="card-icon tables" />
                        <div className="notify-number">
                          Waiting list Coordinator{" "}
                          <span className="number">
                            {playersCount && playersCount.waiting ? playersCount.waiting : "0"}
                          </span>
                        </div>
                        <NavLinkWaitingList />
                      </NavLink>
                      <NavLink
                        id="nlNotSeated"
                        href="#"
                        onClick={() => {
                          redirect(`${tournamentUrl}/notSeated`);
                        }}
                      >
                        <IconWaitingListCoordinator className="card-icon tables" />
                        <div className="notify-number">
                          Seating by floorman{" "}
                          <span className="number">
                            {playersCount && playersCount.withoutSeat
                              ? playersCount.withoutSeat
                              : "0"}
                          </span>
                        </div>
                      </NavLink>
                      <div className="line" />
                      {tournament.isMultiday && (
                        <>
                          <NavLink
                            href="#"
                            id="nlReseat"
                            onClick={() => {
                              redirect(`${tournamentUrl}/newdayreseat`);
                            }}
                          >
                            <IconSwap className="card-icon tables" />
                            Reseat for new day
                          </NavLink>
                          <div className="line" />
                        </>
                      )}
                      <NavLink
                        href="#"
                        id="nlLogs"
                        onClick={() => {
                          redirect(`${tournamentUrl}/logs`);
                        }}
                      >
                        <IconMessages className="card-icon tables" />
                        Log history
                      </NavLink>
                    </NavItem>
                    <NavItem className="help">
                      <NavLink id="nlHelp" href="#" onClick={() => redirect("/help")}>
                        <Button color="primary" className="fit">
                          Help
                        </Button>
                      </NavLink>
                    </NavItem>
                  </Col>

                  <Col xs={12} md={10} className="content">
                    <Router history={history}>
                      <Switch>
                        <Route
                          path="/tournament/:tournamentId/variant/:variantId/table/:tableId/player/:playerId"
                          render={(props) => <PlayerDetail {...props} />}
                        />
                        <Route
                          path="/tournament/:tournamentId/variant/:variantId/redrawremainingtables"
                          render={(props) => (
                            <RedrawRemainingTables variantId={props.match.params.variantId} />
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/variant/:variantId/table/:tableId"
                          render={(props) => (
                            <UpdatePlayerStateWrapper>
                              <TableDetail
                                variantId={parseUrlNumericParameters(props.match.params.variantId)}
                                tableId={parseUrlNumericParameters(props.match.params.tableId)}
                                tournamentId={parseUrlNumericParameters(
                                  props.match.params.tournamentId
                                )}
                              />
                            </UpdatePlayerStateWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/payout"
                          render={(props) => <Payouts id={tournamentId} />}
                        />
                        <Route
                          path="/tournament/:tournamentId/manageclock"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <ManageClock {...props} />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/director-clock"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <DirectorClock {...props} />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/variant/:variantId/clock"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <SpectatorClock
                                variantId={parseUrlNumericParameters(props.match.params.variantId)}
                                tournamentId={tournamentId}
                              />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/players"
                          render={(props) => (
                            <UpdatePlayerStateWrapper>
                              <GetSingleTournamentDataWrapper id={tournamentId}>
                                <Players
                                  {...props}
                                  key="Players"
                                  hideStateFilter="false"
                                  state="ALL"
                                />
                              </GetSingleTournamentDataWrapper>
                            </UpdatePlayerStateWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/newdayreseat"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <ReseatForNewDay {...props} />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/newdayreseatHistory"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <ReseatHistory {...props} />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/newdayreseatHistoryDetail/:id"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <ReseatDetail {...props} detailId={props.match.params.id} />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/logs"
                          render={(props) => (
                            <UpdatePlayerStateWrapper>
                              <GetSingleTournamentDataWrapper id={tournamentId}>
                                <LogHistory {...props} />
                              </GetSingleTournamentDataWrapper>
                            </UpdatePlayerStateWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/chat"
                          render={(props) => <FloorChat {...props} />}
                        />
                        <Route
                          path="/tournament/:tournamentId/waitinglist"
                          render={(props) => (
                            <UpdatePlayerStateWrapper>
                              <GetSingleTournamentDataWrapper id={tournamentId}>
                                <WaitingList {...props} />
                              </GetSingleTournamentDataWrapper>
                            </UpdatePlayerStateWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/notSeated"
                          render={(props) => (
                            <UpdatePlayerStateWrapper>
                              <GetSingleTournamentDataWrapper id={tournamentId}>
                                <Players
                                  id="seatedByFloor"
                                  key="SeatingByFloorman"
                                  parent="SeatingByFloorman"
                                  mode="seatingByFloorman"
                                  {...props}
                                  hideStateFilter
                                  state="NOT_SEATED"
                                />
                              </GetSingleTournamentDataWrapper>
                            </UpdatePlayerStateWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/manage/settings"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <EditTournamentContainer {...props} edit />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/manage/structure"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <EditStructure {...props} edit />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId/manage/structuremobile"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <StructureMobile {...props} />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                        <Route
                          path="/tournament/:tournamentId"
                          render={(props) => (
                            <GetSingleTournamentDataWrapper id={tournamentId}>
                              <TableList {...props} />
                            </GetSingleTournamentDataWrapper>
                          )}
                        />
                      </Switch>
                    </Router>
                  </Col>
                </Row>
              );
            }}
          />
          <Route
            path="/clock/tournament/:tournamentId/variant/:variantId"
            render={(props) => {
              const match = props.match || { params: {} };
              return (
                <GetSingleTournamentDataWrapper
                  id={parseUrlNumericParameters(match.params.tournamentId)}
                >
                  <div className="clock-parent">
                    <SpectatorClock
                      variantId={parseUrlNumericParameters(props.match.params.variantId)}
                      tournamentId={parseUrlNumericParameters(match.params.tournamentId)}
                      hidePayouts={search.get("hidePayouts") === "true"}
                      showBgPreview={search.get("showBgPreview") === "true"}
                    />
                  </div>
                </GetSingleTournamentDataWrapper>
              );
            }}
          />
          <Route
            render={(props) => {
              return (
                <Row className="full-screen">
                  <Col xs={12} className={"d-block d-md-none"}>
                    <DirectorMainMenuHeader />
                  </Col>
                  <Col md={2} className="submenu d-none d-md-block">
                    <NavItem>
                      <Logo />
                    </NavItem>
                    <NavItem>
                      <DirectorMainMenuHeader />
                    </NavItem>
                    <NavItem className="mt30 group">
                      <NavLink href="#" id="nlMttList" onClick={() => redirect("/")}>
                        <IconMttList className="card-icon tables" />
                        MTT List
                      </NavLink>
                      <NavLink
                        id="nlPokerRoomLayout"
                        href="#"
                        onClick={() => redirect("/roomlayout")}
                      >
                        <IconPokerRoomLayout className="card-icon tables" />
                        Poker room layout
                      </NavLink>
                      <NavLink
                        id="nlAdministration"
                        href="#"
                        isdropdownparent="true"
                        isopen={isOpenAdmin ? "true" : "false"}
                        onClick={() => toggleAdmin()}
                      >
                        <IconAdministration className="card-icon tables" />
                        Administration
                      </NavLink>
                      <Collapse isOpen={isOpenAdmin}>
                        <NavLink
                          id="nlUserManagement"
                          href="#"
                          opendropdown={() => {
                            toggleAdmin(true);
                          }}
                          onClick={() => redirect("/users")}
                        >
                          User management
                        </NavLink>
                        <NavLink
                          id="nlAdminPlayers"
                          href="#"
                          opendropdown={() => {
                            toggleAdmin(true);
                          }}
                          onClick={() => redirect("/players")}
                        >
                          Players database
                        </NavLink>
                        <NavLink
                          id="nlCasinoSettings"
                          href="#"
                          opendropdown={() => {
                            toggleAdmin(true);
                          }}
                          onClick={() => redirect("/settings")}
                        >
                          Casino settings
                        </NavLink>
                      </Collapse>
                      <NavLink
                        id="nlTemplates"
                        href="#"
                        isdropdownparent="true"
                        isopen={isOpenTemplates ? "true" : "false"}
                        onClick={() => toggleTemplates()}
                      >
                        <IconTemplates className="card-icon tables" />
                        Templates
                      </NavLink>
                      <Collapse isOpen={isOpenTemplates}>
                        <NavLink
                          id="nlTemplateMtt"
                          href="#"
                          opendropdown={() => toggleTemplates(true)}
                          onClick={() => redirect("/template/mtt")}
                        >
                          MTT
                        </NavLink>

                        <NavLink
                          id="nlTemplateStructure"
                          href="#"
                          opendropdown={() => toggleTemplates(true)}
                          onClick={() => redirect("/template/structure")}
                        >
                          Structure
                        </NavLink>

                        <NavLink
                          id="nlTemplatePayout"
                          href="#"
                          opendropdown={() => {
                            toggleTemplates(true);
                          }}
                          onClick={() => redirect("/template/payout")}
                        >
                          Payout
                        </NavLink>
                      </Collapse>
                    </NavItem>
                    <NavItem className="help">
                      <NavLink id="nlHelp" href="#" onClick={() => redirect("/help")}>
                        <Button color="primary" className="fit">
                          Help
                        </Button>
                      </NavLink>
                    </NavItem>
                  </Col>

                  <Col sm={10} className="content">
                    <Router history={history}>
                      <Switch>
                        <Route path="/mtt" render={(props) => <MttDetail {...props} />} />
                        <Route
                          path="/template/detail/:id/:groupid"
                          render={(props) => <TemplateDetail {...props} />}
                        />
                        <Route
                          path="/template/create/:type"
                          render={(props) => <TemplateCreate {...props} />}
                        />
                        <Route path="/roomlayout" render={(props) => <RoomLayout {...props} />} />
                        <Route path="/users" render={(props) => <UserManagement {...props} />} />
                        <Route
                          path="/players"
                          render={(props) => <PlayersManagement {...props} />}
                        />
                        <Route
                          path="/settings"
                          render={(props) => <CasinoSettings {...props} />}
                        />
                        <Route
                          path="/template/mtt"
                          render={(props) => <TemplateMtt {...props} />}
                        />
                        <Route
                          path="/template/structure"
                          render={(props) => <TemplateStructure {...props} />}
                        />
                        <Route
                          path="/template/payout"
                          render={(props) => <TemplatePayout {...props} />}
                        />
                        <Route path="/help" render={(props) => <Help {...props} />} />
                        <Route
                          path="/template/detail/:id/:groupid"
                          render={(props) => <TemplateDetail {...props} />}
                        />
                        <Route
                          path="/template/create/:type"
                          render={(props) => <TemplateCreate {...props} />}
                        />
                        <Route path="/roomlayout" render={(props) => <RoomLayout {...props} />} />
                        <Route path="/users" render={(props) => <UserManagement {...props} />} />
                        <Route path="/settings" render={(props) => <CasinoSettings {...props} />} />
                        <Route
                          render={() => (
                            <UpdatePlayerStateWrapper>
                              <TournamentList />
                            </UpdatePlayerStateWrapper>
                          )}
                        />
                      </Switch>
                    </Router>
                  </Col>
                </Row>
              );
            }}
          />
        </Switch>
      </BrowserRouter>
      <CustomModal
        open={unsavedWarning.show}
        className="action-confirm-modal dark"
        title={"Unsaved changes"}
        dialogClassName="modal-small-width"
      >
        <>
          <DqIcon className="modal-action-icon" />
          <span>
            <b>{unsavedWarning.message}</b>
          </span>
          <br />
          <div className="confirm-btns">
            {/*<Button color="primary" className="confirm-btn mr-2" onClick={() => { dispatch(unsavedWarning.dispatchData); dispatch({ type: UNSAVED_CHANGES_CLEAR }); unsavedWarning.action()}}>*/}
            {/*  Yes*/}
            {/*</Button>*/}
            <Button
              color="primary"
              className="confirm-btn"
              onClick={() => {
                dispatch({ type: UNSAVED_CHANGES_HIDE });
              }}
            >
              Ok
            </Button>
          </div>
        </>
      </CustomModal>
    </Container>
  );
};

export default MttDirectorPage;
