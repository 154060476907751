import React, { useState, useEffect } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Input, Label, Button } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Phases from "./Phases";
import CollapsibleToggleSwitch from "../../functionalComponents/CollapsibleToggleSwitch";
import Colors from "../../../enum/Colors";
import Currency from "../../../enum/Currency";
import { isMultiday } from "../../../util/tournamentUtil";
import TournamentHeader from "../../shared/TournamentHeader";
import useConfig from "../../useConfig";

export const EventNoInfo = ({ tournament, handleOptionalField, handleChange, errors }) => (
  <Row className="pt-3 px-3">
    <CollapsibleToggleSwitch
      field="festivalName"
      text="Festival"
      switchValue={(fieldName, checked) => {
        handleOptionalField("festivalName", checked);
      }}
      isSwitchedOn={Boolean(tournament.festivalName) || tournament.festivalName === ""}
    >
      <Col sm={4} className="pl-0 pt-3 event-no">
        <Label for="festivalName">Festival Name</Label>
        <Input
          id="festivalName"
          placeholder="Festival name"
          name="festivalName"
          invalid={errors.festivalName}
          onChange={handleChange}
          value={tournament.festivalName || ""}
        />
      </Col>
    </CollapsibleToggleSwitch>
  </Row>
);

export const MainInfo = ({ handleChange, errors, tournament }) => {
  /* Dynamic colors maybe? */
  const possibleColors = Object.keys(Colors).map((k) => Colors[k]);
  const possibleCurrency = Object.keys(Currency).map((k) => Currency[k]);
  const configData = useConfig();
  const config = configData.initialized ? configData.config : {}
  const currencyValue = tournament.currency

  if(!currencyValue) {
      handleChange({
          target: {id: "currency", type: "text", value: config.tournamentDefaultCurrency.value},
      })
  }


  return (
    <Row className="pt-5">
      <Col sm={12}>
        <h4 className="title">Main information</h4>
      </Col>
      <Col sm={8} className="pt-3">
        <Label for="name">MTT Name</Label>
        <Input
          id="name"
          placeholder="Type MTT name"
          name="name"
          onChange={handleChange}
          invalid={errors.name}
          value={tournament.name || ""}
        />
      </Col>
      <Col sm={4} className="pt-3">
        <Label for="mttColor">MTT Color</Label>
        <Dropdown>
          <Dropdown.Toggle
            as="div"
            style={{ color: tournament.color || "#6c747b" }}
            className="mtt-toggle form-control"
          >
            {tournament.color || "Select MTT color"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {possibleColors.map((color) => (
              <Dropdown.Item
                as="div"
                id="color"
                key={color}
                style={{ color }}
                onClick={() =>
                  handleChange({
                    target: { id: "color", type: "text", value: color },
                  })
                }
              >
                {color}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>

      <Col sm={4} className="pt-3">
        <Label for="eventNum">Event No.</Label>
        <Input
          id="eventNum"
          type="number"
          placeholder="Type No."
          name="eventNum"
          invalid={errors.eventNum}
          onChange={handleChange}
          value={tournament.eventNum || ""}
        />
      </Col>

      <Col sm={4} className="pt-3">
        <Label for="currency">Currency</Label>
        <Dropdown>
          <Dropdown.Toggle as="div" className="mtt-toggle form-control">
            {currencyValue}
          </Dropdown.Toggle>
          <Dropdown.Menu className="light">
            {possibleCurrency.map((currency) => (
              <Dropdown.Item
                as="div"
                key={currency}
                id="currency"
                onClick={() =>
                  handleChange({
                    target: { id: "currency", type: "text", value: currency },
                  })
                }
              >
                {currency}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export const PhasesInfo = ({ tournament, handlePhases, updateTournament, errors }) => {
  const [isMultiDay, setMultiDay] = useState(isMultiday(tournament));
  const [currentDaysCount, setCurrentDaysCount] = useState(isMultiday(tournament) ? tournament.numOfPhases ? tournament.numOfPhases : 2 : 1);

  useEffect(() => {
    handlePhases(currentDaysCount);
  }, [currentDaysCount]);

  const onAddDayClick = () => {
    setCurrentDaysCount(currentDaysCount + 1);
  }

  const onRemoveDayClick = () => {
    if (currentDaysCount === 2)
      return;

    setCurrentDaysCount(currentDaysCount - 1);
  }

  return (
    <Row className="pt-5 px-3">
      <CollapsibleToggleSwitch
        field="eventNum"
        text="Multiday MTT"
        switchValue={(fieldName, checked) => {
          setMultiDay(checked);
          setCurrentDaysCount(checked ? 2 : 1);
        }}
        isSwitchedOn={isMultiDay}
      >
        <Col className="mx-2">
          <div className="pt-3">
            <Button className="fit" onClick={() => { onAddDayClick() }}>Add</Button>
            <Phases
              onRemoveDayClick={onRemoveDayClick}
              flightTableSize={tournament.tableSize}
              flightTableMaxOccupancy={tournament.tableMaxOccupancy}
              tournamentPhases={tournament.tournamentPhases}
              updatePhaseInfo={() => {
                updateTournament(tournament);
              }}
              errors={errors}
            />
          </div>
        </Col>
      </CollapsibleToggleSwitch>
    </Row>
  );
};

export const PoolInfo = ({ tournament, handleOptionalField, handleChange, errors }) => {
  const [hasGuaranteedPool, setGuaranteedPool] = useState(Boolean(tournament.guaranteedPool));

  return (
    <Row className="pt-5 px-3">
      <CollapsibleToggleSwitch
        field="guaranteedPool"
        text="Guaranteed pool"
        switchValue={(fieldName, checked) => {
          setGuaranteedPool(checked);
          handleOptionalField("guaranteedPool", null);
          handleChange({ target: { id: "guaranteedPool", value: null } });
        }}
        isSwitchedOn={hasGuaranteedPool}
      >
        <Col sm={4} className="pl-0 pt-3">
          <Label for="guaranteedPool">Guaranteed pool</Label>
          <Input
            id="guaranteedPool"
            type="number"
            placeholder="Type guaranteed pool"
            name="guaranteedPool"
            invalid={errors.guaranteedPool}
            onChange={handleChange}
            value={tournament.guaranteedPool || ""}
          />
        </Col>
      </CollapsibleToggleSwitch>
    </Row>
  );
};

export const TableOccupancy = ({ tournament, updateMaxTableProps, errors }) => (
  <Row className="py-5">
    <Col sm={12}>
      <div className="title with-switch">
        <span>Table occupancy preference</span>
      </div>
    </Col>
    <Col sm={4} className="pt-3">
      <Label for="tableSize">Max No. of players at table</Label>
      <Input
        id="tableSize"
        type="number"
        placeholder="Type max No. of players at table"
        name="tableSize"
        onChange={(e) => {
          updateMaxTableProps({
            tableSize: e.target.valueAsNumber,
            tableMaxOccupancy: tournament.tableMaxOccupancy,
          });
        }}
        invalid={errors.tableSize}
        value={tournament.tableSize || ""}
      />
    </Col>
    {/*<Col sm={4} className="pt-3">*/}
    {/*  <Label for="tableSize">Max table occupancy at start of MTT</Label>*/}
    {/*  <Input*/}
    {/*    id="tableMaxOccupancy"*/}
    {/*    type="number"*/}
    {/*    placeholder="Max table occupancy at start of MTT"*/}
    {/*    name="tableMaxOccupancy"*/}
    {/*    onChange={(e) => {*/}
    {/*      updateMaxTableProps({*/}
    {/*        tableSize: tournament.tableSize,*/}
    {/*        tableMaxOccupancy: e.target.valueAsNumber,*/}
    {/*      });*/}
    {/*    }}*/}
    {/*    invalid={errors.tableMaxOccupancy}*/}
    {/*    value={tournament.tableMaxOccupancy || ""}*/}
    {/*  />*/}
    {/*</Col>*/}
  </Row>
);

const GeneralSettings = ({
  tournament,
  errors,
  handleChange,
  handlePhases,
  updateMaxTableProps,
  handleOptionalField,
  updateTournament,
}) => (
        <>
        <TournamentHeader tournament={tournament}/>
        <Row className="mt-3">
            <Col>
                <h2 className="title">General MTT settings</h2>
            </Col>
        </Row>
        <Row className="general-mtt-settings">
            <Col>
                <EventNoInfo
                    tournament={tournament}
                    handleOptionalField={handleOptionalField}
                    handleChange={handleChange}
                    errors={errors}
                />

        <MainInfo handleChange={handleChange} errors={errors} tournament={tournament} />
                <PhasesInfo
                    tournament={tournament}
                    handlePhases={handlePhases}
                    updateTournament={updateTournament}
                    errors={errors}
                />
                <PoolInfo
                    tournament={tournament}
                    handleOptionalField={handleOptionalField}
                    handleChange={handleChange}
                    errors={errors}
                />
                <TableOccupancy
                    tournament={tournament}
                    updateMaxTableProps={updateMaxTableProps}
                    errors={errors}
                />
            </Col>
        </Row>
  </>
);

GeneralSettings.propTypes = {};

export default GeneralSettings;
