import React from "react";
import { connect } from "react-redux";
import { notifyVariantUpdate } from "../../actions/tournamentActions";
import { notifyPlayerInVariantChange } from "../../actions/playerActions";
import socket from "../../service/EvSocket";
import BottomNavbarMobile from "./BottomNavbarMobile";

class _getInner extends React.Component {
  componentDidMount() {
    socket.on("playersOnTableChange", "UpdatePlayerStateWrapper", (message, dispatch) => {
      this.props.notifyVariantUpdate(message.variant);
      this.props.notifyPlayerInVariantChange(message.player);
    });

    if (this.props.socketUpdateTournamentVariant) {
      socket.on("updateTournamentVariant", "UpdatePlayerStateWrapper", (message, dispatch) => {
        this.props.notifyVariantUpdate(message);
      });
    }
  }

  componentWillUnmount = () => {
    socket.removeHandler("playersOnTableChange", "UpdatePlayerStateWrapper");
    if (this.props.socketUpdateTournamentVariant) {
      socket.removeHandler("updateTournamentVariant", "UpdatePlayerStateWrapper");
    }
  };

  render() {
    return <>{this.props.children}</>;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  notifyVariantUpdate,
  notifyPlayerInVariantChange,
};

const Inner = connect(mapStateToProps, mapDispatchToProps)(_getInner);

const UpdatePlayerStateWrapper = (props) => {
  return (
    <>
      <Inner {...props}>{props.children}</Inner>
    </>
  );
};
export default UpdatePlayerStateWrapper;
