import React from "react";
import ConfirmationModal from "../../modals/ConfirmationModal";

const ReseatConfirmModal = (props) => {
    const {open, submit } = props
    return (
        <ConfirmationModal
            confirmationText={`Players were transferred properly`}
            open={open}
            buttonText="Ok"
            close={submit}
            onSubmitButtonClick={submit}
        />
    )
}

export default ReseatConfirmModal;