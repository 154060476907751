import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button, Dropdown, Card, Col, Row,
} from 'react-bootstrap';
import { Input, Label } from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postUser } from '../../actions/usersActions';
import UserRole from '../../enum/UserRole';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownToggle from 'react-bootstrap/DropdownToggle';

const StaffCard = (props) => {
  const dispatch = useDispatch();

  const [showDetails, setShowDetails] = useState(false);
  const [identifier, setIdentifier] = useState('');
  const [alias, setAlias] = useState('');
  const [type, setType] = useState('');

  const possibleRoles = Object.keys(UserRole).filter(r => r !== "SPECTATOR" && r !== "ADMIN").map((k) => UserRole[k]);
  useEffect(() => {
    resetChanges();
  }, []);

  const hideDetails = () => {
    props.setSelectedUser(props.user);
    setShowDetails(!showDetails);
    resetChanges();
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    dispatch(
      postUser({
        identifier: props.user.identifier,
        newIdentifier: identifier,
        alias: alias,
        role: type,
      }),
    );
    // props.toggle();
  };

  const resetChanges = () => {
    setIdentifier(props.user.identifier);
    setType(props.user.type);
    setAlias(props.user.alias)
  };

  return (
    <Card className="card center staff-card">
      <Card.Text>
        <Row className="mb-3">
          <Col>
            <Row className="identifier">{props.user.identifier}</Row>
            <Row className="type">{props.user.type}</Row>
          </Col>
          <Col>
            <div className="actions">
              <Button
                color="primary"
                variant={showDetails ? 'outline-primary' : 'primary'}
                className="fit details-btn"
                onClick={hideDetails}
              >
                {showDetails ? 'Hide details' : 'See details'}
              </Button>
              <FontAwesomeIcon
                style={{
                  cursor: 'pointer',
                  visibility: props.hasWritePermission === true ? 'visible' : 'collapse',
                }}
                icon={faTrash}
                className="colored trash-icon"
                onClick={() => props.deleteUser(props.user)}
              />
            </div>
          </Col>
        </Row>
        {showDetails && (
          <>
            <Row className="line mb-3" />
            <Row>
              <Col xs={4}>
                <Label for="name">E-mail as login</Label>
                <Input
                  disabled={props.hasWritePermission === false}
                  id="name"
                  name="name"
                  onChange={(e) => setIdentifier(e.target.value)}
                  value={identifier}
                />
              </Col>
              <Col xs={4}>
                <Row>
                  <Label for="dd">Role</Label>
                </Row>
                <Row>
                  <Dropdown name="dd" id="dd" className="mtt-ddm">
                    <DropdownToggle as="div" className="mtt-toggle form-control">
                      {type || 'Select role'}
                    </DropdownToggle>
                    <DropdownMenu>
                      {possibleRoles.map((role) => (
                        <DropdownItem
                          as="div"
                          id="roles"
                          onClick={(evKey, ev) => {
                            props.hasWritePermission === true ? setType(role) : ev.preventDefault();
                          }}
                        >
                          {role}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </Row>
              </Col>
              <Col xs={4}>
                <Row>
                  <Label for="name2" />
                </Row>
                <Row
                  style={{ visibility: props.hasWritePermission === true ? 'visible' : 'collapse' }}
                >
                  <Button
                    className="float-left"
                    onClick={() => props.setShowResetPasswordModal(true)}
                  >
                    Generate new password
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row
              className="mt-3"
              style={{ visibility: props.hasWritePermission === true ? 'visible' : 'collapse' }}
            >
              <Col xs={4}>
                <Label for="name">Screen name</Label>
                <Input
                  disabled={props.hasWritePermission === false}
                  id="alias"
                  name="alias"
                  onChange={(e) => setAlias(e.target.value)}
                  value={alias}
                />
              </Col>
              <Col xs={8}>
                <div className="w-100">
                  <Button
                    className="confirm-button"
                    variant="secondary"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save changes
                  </Button>
                  <Button className="confirm-button" variant="secondary" onClick={resetChanges}>
                    Cancel changes
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Card.Text>
    </Card>
  );
};

export default StaffCard;
