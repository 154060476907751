import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import moment from "moment-mini";
import VariantPicker from "../shared/VariantPicker";
import LogCard from "./floor/LogCard";
import { getFilteredUsers } from "../../actions/usersActions";
import { clearVariantLogs, getVariantLogs } from "../../actions/tournamentActions";
import { getLogsActions } from "../../util/tournamentUtil";
import { history } from "../../service/history";

const Logs = (props) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const users = useSelector((state) => state.users.users);
  const logs = useSelector((state) => state.tournaments.logs);
  const { variant } = props;

  if (!users.find((u) => u.id === "all")) {
    users.unshift({ id: "all", identifier: "All" });
  }

  useEffect(() => {
    setFilter({
      ...filter,
      logType: "all",
      performedBy: "all",
      dateFrom: moment().add(-10, "days").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    });
    dispatch(getFilteredUsers([2, 3, 4]));

    return () => {
      dispatch(clearVariantLogs());
    };
  }, []);

  function updateFilter(updatedFilter) {
    setFilter(updatedFilter);
    dispatch(clearVariantLogs());
  }

  const redirect = (path) => {
    history.push(path);
  };

  return (
    <>
      <div className={"d-block d-md-none"}>
        <Row>
          <Col>
            <h3>Log history</h3>
          </Col>
        </Row>
        <div className="line" />
        <Row>
          <Col xs={6}>
            <Label>Choose user</Label>
            <Dropdown>
              <Dropdown.Toggle as="div" className="mtt-toggle form-control">
                {filter.performedBy
                  ? users.find((f) => f.id === filter.performedBy).identifier
                  : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {users.map((user) => (
                  <Dropdown.Item
                    as="div"
                    id={`item_${user.id}`}
                    key={user.id}
                    onClick={() => {
                      updateFilter({ ...filter, performedBy: user.id });
                    }}
                  >
                    {user.identifier}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={6}>
            <Label>Choose action</Label>
            <Dropdown>
              <Dropdown.Toggle as="div" className="mtt-toggle form-control">
                {filter.logType ? getLogsActions().find((a) => a.key === filter.logType).name : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {getLogsActions().map((action) => (
                  <Dropdown.Item
                    as="div"
                    id="action"
                    onClick={() => {
                      updateFilter({ ...filter, logType: action.key });
                    }}
                  >
                    {action.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={6}>
            <Label>History From:</Label>
            <Input
              id="historyFrom"
              type="date"
              name="historyFrom"
              onChange={(e) => {
                updateFilter({ ...filter, dateFrom: e.target.value });
              }}
              value={filter.dateFrom ? filter.dateFrom : moment().add(10, "days")}
            />
          </Col>
          <Col xs={6}>
            <Label>History To:</Label>
            <Input
              id="historyTo"
              type="date"
              name="historyTo"
              onChange={(e) => {
                updateFilter({ ...filter, dateTo: `${e.target.value}` });
              }}
              value={filter.dateTo ? filter.dateTo : ""}
            />
          </Col>
          <Col xs={12}>
            <br />
            <Button
              onClick={() => {
                const filterToSent = { ...filter };
                filterToSent.variantId = variant.id;
                filterToSent.dateTo = filter.dateTo
                  ? moment(filter.dateTo).endOf("day")
                  : filter.dateTo;
                filterToSent.datefrom = filter.dateFrom
                  ? moment(filter.dateFrom).startOf("day")
                  : filter.dateFrom;
                if (filter.logType === "all") {
                  delete filterToSent.logType;
                }
                if (filter.performedBy === "all") {
                  delete filterToSent.performedBy;
                }
                dispatch(getVariantLogs(JSON.stringify(filterToSent)));
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row>
          {logs.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "5rem",
                width: "100%",
              }}
            >
              <h3>No logs, try to search</h3>
            </div>
          ) : (
            <Col>
              <Row className="mt-2 log-card no-wrap title no-gutters">
                <Col xs={6} className="colX remXL">
                  <span>User</span>
                </Col>
                <Col xs={6} className="colLast">
                  Details
                </Col>
              </Row>
              {logs.map((log) => (
                <LogCard log={log} />
              ))}
            </Col>
          )}
        </Row>
      </div>
      <div className={"d-none d-md-block"}>
        <Row>
          <Col>
            <h3>Log history</h3>
          </Col>
        </Row>
        <div className="line" />
        <Row>
          <Col>
            <Label>Choose user</Label>
            <Dropdown>
              <Dropdown.Toggle as="div" className="mtt-toggle form-control">
                {filter.performedBy
                  ? users.find((f) => f.id === filter.performedBy).identifier
                  : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {users.map((user) => (
                  <Dropdown.Item
                    as="div"
                    id={`item_${user.id}`}
                    key={user.id}
                    onClick={() => {
                      updateFilter({ ...filter, performedBy: user.id });
                    }}
                  >
                    {user.identifier}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <Label>Choose action</Label>
            <Dropdown>
              <Dropdown.Toggle as="div" className="mtt-toggle form-control">
                {filter.logType ? getLogsActions().find((a) => a.key === filter.logType).name : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {getLogsActions().map((action) => (
                  <Dropdown.Item
                    as="div"
                    id="action"
                    onClick={() => {
                      updateFilter({ ...filter, logType: action.key });
                    }}
                  >
                    {action.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <Label>History From:</Label>
            <Input
              id="historyFrom"
              type="date"
              name="historyFrom"
              onChange={(e) => {
                updateFilter({ ...filter, dateFrom: e.target.value });
              }}
              value={filter.dateFrom ? filter.dateFrom : moment().add(10, "days")}
            />
          </Col>
          <Col>
            <Label>History To:</Label>
            <Input
              id="historyTo"
              type="date"
              name="historyTo"
              onChange={(e) => {
                updateFilter({ ...filter, dateTo: `${e.target.value}` });
              }}
              value={filter.dateTo ? filter.dateTo : ""}
            />
          </Col>
          <Col>
            <br />
            <Button
              onClick={() => {
                const filterToSent = { ...filter };
                filterToSent.variantId = variant.id;
                filterToSent.dateTo = filter.dateTo
                  ? moment(filter.dateTo).endOf("day")
                  : filter.dateTo;
                filterToSent.datefrom = filter.dateFrom
                  ? moment(filter.dateFrom).startOf("day")
                  : filter.dateFrom;
                if (filter.logType === "all") {
                  delete filterToSent.logType;
                }
                if (filter.performedBy === "all") {
                  delete filterToSent.performedBy;
                }
                dispatch(getVariantLogs(JSON.stringify(filterToSent)));
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row>
          {logs.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "5rem",
                width: "100%",
              }}
            >
              <h3>No logs, try to search</h3>
            </div>
          ) : (
            <Col>
              <Row className="mt-2 log-card no-wrap title no-gutters">
                <div className="colX">Date</div>
                <div className="colX remXL">
                  <span>User</span>
                </div>
                <div className="colX">Log Type</div>
                <div className="colX">Player</div>
                <div className="colLast">Details</div>
              </Row>
              {logs.map((log) => (
                <LogCard log={log} />
              ))}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

const LogHistory = (props) => {
  const tournament = useSelector((state) => state.tournaments.tournament);
  return (
    <>
      <VariantPicker
        tournament={tournament}
        id="LogHistory"
        getContent={(variant) => {
          return <Logs tournament={tournament} variant={variant} />;
        }}
      />
    </>
  );
};

export default LogHistory;
