import actionTypes from '../actiontypes/roomActionTypes';
import restClient from '../util/restClient';
import { combinedRegisterLoaderAndShowError } from './commonActions';

const getRoomsRequest = () => ({ type: actionTypes.GET_ROOMS_REQUEST });
const getRoomsResponse = (data) => ({ type: actionTypes.GET_ROOMS_RESPONSE, data });

const saveRoomsRequest = () => ({ type: actionTypes.SAVE_ROOMS_REQUEST });
const saveRoomsResponse = (data) => ({ type: actionTypes.SAVE_ROOMS_RESPONSE, data });

const deleteRoomsRequest = () => ({ type: actionTypes.DELTE_ROOMS_REQUEST });
const deleteRoomsResponse = (data) => ({ type: actionTypes.DELETE_ROOMS_RESPONSE, data });

export const getRooms = () => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getRoomsRequest({}));
    return restClient.get('/rooms').then((response) => {
      dispatch(getRoomsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getRooms',
  'Can not fetch rooms',
);

export const saveRooms = (rooms) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () =>
  // dispatch(saveRoomsRequest(rooms));
    restClient.post('/rooms', rooms).then((response) => {
      dispatch(saveRoomsResponse(response.data));
      return Promise.resolve(response);
    }),
  'saveRooms',
  'Can not save rooms',
);

export const deleteRoom = (roomId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  async () => {
    const res = await restClient.doDelete(`/rooms/${roomId}`)
    dispatch(deleteRoomsResponse(res.data));
  },
  'deleteRoom',
  'Can not delete room',
);
