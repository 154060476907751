import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "reactstrap";
import { formatPlayerName } from "../../util/playerUtil";
import { formatDate } from '../../util/stringUtil';
import ConfirmationModal from "../modals/ConfirmationModal";

const PlayerCard = ({ player, anonymize }) => {

  const [showAnonymizePrompt, setShowAnonymizePrompt] = useState(false)
  return (
    <Row noGutters className="player-card no-wrap" key={player.id}>
      <Col>
        <Row noGutters className="values"><b>{formatPlayerName(player)}</b></Row>
        <div className="line" />
      <Row>
          <div className="col1">Nationality</div>
          <div className="col1">Personal ID</div>
          <div className="col1">Date of birth</div>
          <div className="col1">System ID</div>
          <div className="col3"></div>
      </Row>
      <Row className="values">
        <div className="col1">{player.nationality}</div>
        <div className="col1">{player.idCardNum}</div>
        <div className="col1">{formatDate(player.dateOfBirth)}</div>
        <div className="col1">{player.id}</div>
        <div className="col3">
          <Button color="primary" className="fit" disabled={player.anonymized} onClick={() => {
              setShowAnonymizePrompt(true)
          }}>
            Anonymize
          </Button>
        </div>
        </Row>
      </Col>
      <ConfirmationModal
          confirmationText={`Are you sure, you want to anonymize ${formatPlayerName(player)} in your database?`}
          buttonText="No"
          buttonText2="Yes"
          open={showAnonymizePrompt}
          close={() => setShowAnonymizePrompt(false)}
          onSubmitButtonClick={() => {setShowAnonymizePrompt(false)}}
          onSubmitButton2Click={async () => {
              await anonymize(player);
              setShowAnonymizePrompt(false)}}
      />
    </Row>

  )
};

export default PlayerCard;
