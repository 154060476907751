import React from "react";
import { connect } from "react-redux";
import TournamentVariantList from "../components/tournament/TournamentVariantList";
import RegisterPlayerModal from "../components/cashier/RegisterPlayerModal";
import ListOfPlayersModal from "../components/cashier/ListOfPlayersModal";
import UnregisterPlayerModal from "../components/cashier/UnregisterPlayerModal";
import BalanceSheetModal from "../components/cashier/BalanceSheetModal";
import PendingPayoutModal from "../components/cashier/PendingPayoutModal";
import { isRegistrationOpen } from "../util/tournamentUtil";
import { getPlayerPayout, getPlayersByStateAndTournamentVariantId, getPlayersClean, } from "../actions/playerActions";
import { getFees, getTips, getVariants } from "../actions/tournamentActions";

class CashierPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalRegisterPlayer: false,
            modalListOfPlayers: false,
            registerPlayerReentry: false,
            variantRegisterPlayer: {},
            modalUnregisterPlayer: false,
            modalPayout: false,
            modalPendingPayout: false,
            modalBalanceSheet: false,
        };
    }

    componentWillUnmount() {
        this.props.getPlayersClean();
    }

    toggleRegisterPlayer = (open, variant, reentry) => {
        this.setState({
            modalRegisterPlayer: open,
            registerPlayerReentry: reentry,
            variantRegisterPlayer: variant || {},
        });
    };

    toggleListOfPlayers = (open, variant) => {
        this.setState({
            modalListOfPlayers: open,
            variantRegisterPlayer: variant || {},
        });
    };

    toggleUnregisterPlayer = (selectedVariantTournament) => {
        if (selectedVariantTournament !== undefined) {
            this.props.getPlayersByStateAndTournamentVariantId('unregistering', selectedVariantTournament.id);
        }
        this.setState({
            variantRegisterPlayer: selectedVariantTournament || {},
            selectedTournamentPhaseVariantId:
                selectedVariantTournament !== undefined
                    ? selectedVariantTournament.tournamentPhaseVariantId
                    : undefined,
            modalUnregisterPlayer: !this.state.modalUnregisterPlayer,
        });
    };

    togglePendingPayout = () => {
        this.setState(
            {
                modalPendingPayout: !this.state.modalPendingPayout,
            },
            () => {
                // if (this.state.modalPendingPayout) {
                //   this.props.getPlayerPayout();
                // }
            }
        );

        if (this.state.modalPendingPayout) {
            this.props.getVariants();
        }

    };

    toggleBalanceSheet = (selectedVariantTournament) => {
        if (selectedVariantTournament !== undefined) {
            this.props.getFees(selectedVariantTournament.tournamentId);
        }
        this.setState({
            selectedTournamentPhaseVariantId:
                selectedVariantTournament !== undefined
                    ? selectedVariantTournament.tournamentPhaseVariantId
                    : undefined,
            modalBalanceSheet: !this.state.modalBalanceSheet,
            variantRegisterPlayer: selectedVariantTournament || {},
        });
    };

    render() {
        const registerPlayerButton = (variant) => {
            if (isRegistrationOpen(variant)) {
                return (
                    <>
                        <button
                            id="register_player"
                            type="button"
                            className="modal-btn green"
                            onClick={() => {
                                this.toggleRegisterPlayer(true, variant, false);
                            }}
                        >
                            Register player
                        </button>
                    </>
                );
            }
            return <></>;
        };

        const listOfPlayersButton = (variant) => {
            if (isRegistrationOpen(variant)) {
                return (
                    <>
                        <button
                            id="listOfPlayers"
                            type="button"
                            className="modal-btn purple-fill"
                            onClick={() => {
                                this.toggleListOfPlayers(true, variant, false);
                            }}
                        >
                            List of players
                        </button>
                    </>
                );
            }
            return <></>;
        };

        const unregisterPlayerButton = (variant, now) => {
            return (
                <button
                    id="unregister_player"
                    type="button"
                    className="modal-btn no-fill"
                    onClick={() => {
                        this.toggleUnregisterPlayer(variant);
                    }}
                >
                    Unregister
                </button>
            );
        };

        const balanceSheetButton = (variant, now) => {
            return (
                <button
                    id="balance_sheet"
                    type="button"
                    className="modal-btn purple"
                    onClick={() => {
                        this.toggleBalanceSheet(variant);
                    }}
                >
                    Balance sheet
                </button>
            );
        };
        const columns = [registerPlayerButton, listOfPlayersButton, unregisterPlayerButton, balanceSheetButton];
        return (
            <>
                {this.state.modalRegisterPlayer && (
                    <RegisterPlayerModal
                        isOpen={this.state.modalRegisterPlayer}
                        toggle={() => this.toggleRegisterPlayer(false)}
                        variant={this.state.variantRegisterPlayer}
                    />
                )}

                {this.state.modalListOfPlayers && (
                    <ListOfPlayersModal
                        isOpen={this.state.modalListOfPlayers}
                        toggle={() => this.toggleListOfPlayers(false)}
                        variant={this.state.variantRegisterPlayer}
                    />
                )}

                {this.state.modalUnregisterPlayer && (
                    <UnregisterPlayerModal
                        tournamentPhaseVariantId={this.state.selectedTournamentPhaseVariantId}
                        variant={this.state.variantRegisterPlayer}
                        isOpen={this.state.modalUnregisterPlayer}
                        toggle={() => {
                            this.toggleUnregisterPlayer();
                        }}
                    />
                )}

                {this.state.modalBalanceSheet && (
                    <BalanceSheetModal
                        isOpen={this.state.modalBalanceSheet}
                        toggle={this.toggleBalanceSheet}
                        variant={this.state.variantRegisterPlayer}
                    />
                )}

                {this.state.modalPendingPayout && (
                    <PendingPayoutModal
                        isOpen={this.state.modalPendingPayout}
                        toggle={this.togglePendingPayout}
                    />
                )}

                <TournamentVariantList columns={columns} togglePendingPayout={this.togglePendingPayout} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    getPlayersByStateAndTournamentVariantId,
    getPlayerPayout,
    getFees,
    getTips,
    getPlayersClean,
    getVariants
};
export default connect(mapStateToProps, mapDispatchToProps)(CashierPage);
