/* eslint-disable react/destructuring-assignment */
import React from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import "./Style/tables.scss";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faFilter } from "@fortawesome/free-solid-svg-icons";
import TableListFilterModal from "../../modals/TableListFilterModal";
import { updateGameTable } from "../../../actions/tablesActions";
import { getVariant } from "../../../actions/tournamentActions";
import TableCard from "./TableCard";
import TableSetupModal from "./TableSetupModal";
import VariantPicker from "../../shared/VariantPicker";
import UpdatePlayerStateWrapper from "../../shared/UpdatePlayerStateWrapper";
import TableDetailClosedModal from "./TableDetailClosedModal";
import ConfirmationModal from "../../modals/ConfirmationModal";
import CustomModal from "../../shared/CustomModal";
import { formatDateTime } from "../../../util/stringUtil";
import moment from "moment-mini";
import { history } from "../../../service/history";
import BottomNavbarMobile from "../../shared/BottomNavbarMobile";
import { isMobile } from 'react-device-detect';

class TableListInVariant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilter: false,
      showSaving: false,
      lockUnlock: false,
      filterTables: undefined,
      tables: [],
      setupModal: false,
      closedTableModal: false,
      selectedTable: null,
      showCancelModal: false,
      showShowTablesConflict: true,
    };

    this.applyFilter = this.applyFilter.bind(this);
    this.getTables = this.getTables.bind(this);
  }

  getTables() {
    if (!this.props.variant || !this.props.variant.tables) {
      return [];
    }

    let tables = this.props.variant.tables.map((item, index) =>
      Object.assign({}, item, { countId: index + 1 })
    );
    if (!this.state.filterTables) {
      // tables  tables;
    } else {
      tables = tables.filter((table) => this.state.filterTables.some((item) => item === table.id));
    }
    return this.sortTables(tables);
  }

  sortTables(tables) {
    let openTables = tables.filter((t) => t.state !== "closed");
    const closedTables = tables.filter((t) => t.state === "closed");

    openTables = openTables.sort((a, b) => {
      if (!isNaN(a.tableName) && !isNaN(b.tableName)) {
        const dif = Number(a.tableName) - Number(b.tableName);
        if (dif > 0) return 1;
        if (dif < 0) return -1;
        return 0;
      }

      if (!isNaN(a.tableName) && isNaN(b.tableName)) {
        return 1;
      }

      if (isNaN(a.tableName) && !isNaN(b.tableName)) {
        return -1;
      }

      return a.tableName.localeCompare(b.tableName);
    });

    closedTables.sort((a, b) => {
      return moment(a.closedAt).toDate().getTime() - moment(b.closedAt).toDate().getTime();
    });

    openTables.push(...closedTables);

    return openTables;
  }

  handleLockUnlockClicked = () => {
    this.setState({ showSaving: true });
  };

  handleLockUnlockTableUpdate = () => {
    const { id, tables } = this.state;
    const data = Object.values(tables);

    this.props.updateGameTable(id, data).then(() => {
      this.props.getVariant(id);
      this.setState({ showSavelModal: true });
    });
  };

  handleLockUnlockTableCancel = () => {
    this.setState({ showSaving: false });
  };

  handleLockUnlockChanged(newData) {
    this.props.variant.tables.forEach((table) => {
      if (table.id === newData.table.id) {
        table.seatingLocked = newData.table.seatingLocked;
      }
    });
    this.setState({
      id: this.props.variant.id,
      tables: { ...this.props.variant.tables },
    });
  }

  applyFilter(tables) {
    if (!tables.length) {
      return this.setState({ filterTables: undefined, openFilter: false });
    }
    this.setState({ filterTables: tables, openFilter: false });
  }

  toggleSetupModal = () => {
    this.setState({
      setupModal: !this.state.setupModal,
    });
    this.props.getVariant(this.props.variant.id);
  };

  toggleClosedTableModal = () => {
    this.setState({
      closedTableModal: !this.state.closedTableModal,
    });

    this.props.getVariant(this.props.variant.id);
  };

  showClosedTableDetail = (table) => {
    this.toggleClosedTableModal();
    this.setState({
      selectedTable: table,
    });
  };

  actionBtns = () => {
    return <>
      <Row className="right">
        <Button
          color="primary"
          onClick={() =>
            this.setState((prevState) => ({
              lockUnlock: !prevState.lockUnlock,
            }))
          }
          className="fit mr-3"
        >
          Lock tables
        </Button>
        <Button
          onClick={() => {
            this.toggleSetupModal();
          }}
          className="fit"
          variant="outline-primary"
        >
          Setup tables
          <FontAwesomeIcon icon={faCog} className="btn-icon" />
        </Button>
      </Row>
    </>
  }
  render() {
    return (
      <div>
        <div className="table-list-content">

          {!isMobile ? "" : <Row className="justify-content-center">{this.actionBtns()}</Row>}
          <Row>
            <Col xs={6}>
              {!isMobile ?
                <>
                  <p className="my-1">
                    Active tables:
                    {` ${this.props.variant.tables &&
                      this.props.variant.tables.filter((item) => item.state === "open").length
                      }`}
                  </p>
                  <p className="my-1">
                    Empty tables:
                    {` ${this.props.variant.tables &&
                      this.props.variant.tables.filter(
                        (item) => !item.occupiedSeats && item.state === "open"
                      ).length
                      }`}
                  </p>
                </> : ""}
              <p className="my-1">
                Seating:
                {` ${this.props.variant.seatingByFloorman ? "By floorman" : "Automatic"}`}
              </p>

            </Col>
            <Col xs={6}>
              {isMobile ? "" : this.actionBtns()}
              <Row className="right">
                <Button
                  onClick={() => this.setState({ openFilter: true })}
                  variant="dark"
                  className="fit btn-dark btn-translucent"
                >
                  Filter
                  <FontAwesomeIcon icon={faFilter} className="btn-icon" />
                </Button>
              </Row>
            </Col>
          </Row>
          {isMobile ? "" : <hr className="list-card-hr" />}
          {this.props.variant &&
            this.props.variant.tables &&
            this.props.variant.tables.length === 0 && (
              <Row className="d-flex justify-content-center">
                Please add tables to the MTT through Setup tables -> Add/remove tables
              </Row>
            )}
          <Row className="tables-rows">
            {this.props.variant &&
              this.getTables()
                .filter(
                  (each) =>
                    !this.state.lockUnlock ||
                    each.state !== "closed" /*dont show closed on lock / unlock mode*/
                )
                .map((table) => {
                  if (table.state === "closed") {
                    return (
                      <TableCard
                        table={{ ...table }}
                        forceReload={this.props.variant.fetchDataTime}
                        key={table.id}
                        cardClassName={"xs-6"}
                        isLockUnlock={this.state.lockUnlock}
                        seatingLocked={table.seatingLocked}
                        variant={this.props.variant}
                        lockUnlockChangeHandler={this.handleLockUnlockChanged.bind(this)}
                        lockUnlockEventHandler={this.handleLockUnlockClicked}
                        onClickEvent={() => this.showClosedTableDetail(table)}
                      />
                    );
                  } else {
                    return (
                      <TableCard
                        table={{ ...table }}
                        forceReload={this.props.variant.fetchDataTime}
                        link={`/tournament/${this.props.variant.tournamentId}/variant/${this.props.variant.id}/table/${table.id}`}
                        key={table.id}
                        cardClassName={"xs-6"}
                        isLockUnlock={this.state.lockUnlock}
                        seatingLocked={table.seatingLocked}
                        variant={this.props.variant}
                        lockUnlockChangeHandler={this.handleLockUnlockChanged.bind(this)}
                        lockUnlockEventHandler={this.handleLockUnlockClicked}
                      />
                    );
                  }
                })}
          </Row>
          {this.props.variant && this.props.variant.tables && (
            <TableListFilterModal
              open={this.state.openFilter}
              tables={this.props.variant.tables}
              apply={this.applyFilter}
              close={() => this.setState({ openFilter: false })}
            />
          )}
          {this.state.lockUnlock && (
            <Row className="confirm-buttons fixed-bottom">
              <Button
                className="fit outline"
                onClick={() => this.setState({ showCancelModal: true })}
              >
                Cancel Changes
              </Button>
              <Button className="fit right" onClick={this.handleLockUnlockTableUpdate}>
                Save Changes
              </Button>
            </Row>
          )}
          {this.state.setupModal && this.props.variant.tables && (
            <TableSetupModal
              isOpen={true}
              toggle={this.toggleSetupModal}
              table={[]}
              players={[]}
              variant={this.props.variant}
            />
          )}
          {this.state.closedTableModal && (
            <TableDetailClosedModal
              isOpen={true}
              toggle={this.toggleClosedTableModal}
              table={this.state.selectedTable}
              tournamentId={this.props.variant.tournamentId}
              variant={this.props.variant}
            />
          )}
          {this.state.showCancelModal && (
            <ConfirmationModal
              confirmationText={"Are you sure you want to cancel changes?"}
              buttonText="Yes"
              buttonText2="Cancel"
              open={true}
              close={() => this.setState({ showCancelModal: false })}
              onSubmitButton2Click={() => this.setState({ showCancelModal: false })}
              onSubmitButtonClick={() => {
                this.handleLockUnlockTableCancel();
                this.setState({ showCancelModal: false, lockUnlock: false });
              }}
            />
          )}
          {this.state.showSavelModal && (
            <ConfirmationModal
              confirmationText={"Your changes have been saved!"}
              buttonText="OK"
              open={true}
              close={() => this.setState({ showSavelModal: false })}
              onSubmitButtonClick={() =>
                this.setState({ showSavelModal: false, lockUnlock: false })
              }
            />
          )}
          {localStorage.getItem("showTablesWarning") !== "false" &&
            this.props.tournament.conflictTables &&
            this.props.tournament.conflictTables.length > 0 && (
              <CustomModal open={true} title="Tables conflict">
                {this.props.tournament.conflictTables.map((conflict) => {
                  return (
                    <Row style={{ whiteSpace: "pre" }} className={"mb-2"}>
                      - Table <b>{conflict.table_name}</b> is used on tournament{" "}
                      <b>{conflict.name}</b> scheduled for{" "}
                      <b>{formatDateTime(conflict.scheduled_start_at)}</b>
                    </Row>
                  );
                })}

                <div className="confirm-btns">
                  <Button
                    className="fit"
                    onClick={() => {
                      this.setState({ showShowTablesConflict: false });
                      localStorage.setItem("showTablesWarning", "false");
                    }}
                  >
                    OK
                  </Button>
                </div>
              </CustomModal>
            )}
        </div>
        <BottomNavbarMobile id="tables" tournamentUrl={"/tournament/" + this.props.variant.tournamentId} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tables: state.tables,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGameTable,
      getVariant,
    },
    dispatch
  );

const Content = connect(mapStateToProps, mapDispatchToProps)(TableListInVariant);

const redirect = (path) => {
  history.push(path);
};

const TableList = () => {
  const tournament = useSelector((state) => state.tournaments.tournament);
  return (
    <div id="table-list">
      <div className={"flex pl-2 pr-2 d-block d-md-none"}>
        <div
          color="primary"
          onClick={() => {
            sessionStorage.setItem("variantId", "");
            sessionStorage.setItem("tournamentId", "");
            localStorage.setItem("showTablesWarning", "");
            redirect("/");
          }}
        >
          <p className="text-uppercase">
            {" "}
            <span style={{ color: "#5b38b9" }}>{`<`}</span>
            {` Back to MTT list`}
          </p>
        </div>
      </div>
      <UpdatePlayerStateWrapper>
        <VariantPicker
          id="tableList"
          tournament={tournament}
          contentClassName="table-list-content-parent"
          getContent={(variant) => {
            return <Content tournament={tournament} variant={variant} />;
          }}
        />
      </UpdatePlayerStateWrapper>
    </div>
  );
};

export default TableList;
