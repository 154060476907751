import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap"; /* replace with react-bootstrap */
import { Row, Col } from "react-bootstrap";
import UserTable from "./UserTable";
import ModalAddEditUser from "./ModalAddEditUser";
import ModalEditPassword from "./ModalEditPassword";
import { getUsersExceptVisitors, resetPassword, deleteUser } from "../../actions/usersActions";
import Loader from "../shared/Loader";
import groupBy from "../../util/groupByUtil";

class ManageUsers extends React.Component {
  state = {
    modal: false,
    modalResetPassword: false,
    editUser: undefined,
    filterValue: "",
    filterOptions: []
  };

  componentDidMount() {
    this.props.getUsersExceptVisitors().then(res => {
      this.setState({ filterOptions: groupBy(res.data) });
    });
  }

  toggle = (e, user) => {
    this.setState({
      modal: !this.state.modal,
      editUser: user || undefined
    });
  };

  deleteUser = (e, user) => {
    const result = window.confirm("Are you sure you want to delete user ? ");
    if (result) {
      this.props.deleteUser(user);
    }
  };

  toggleResetPassword = (e, user) => {
    this.setState({
      modalResetPassword: !this.state.modalResetPassword,
      editUser: user || undefined
    });
  };

  changeFilter = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let users = this.state.filterValue ? this.props.users : this.props.users;
    users.sort(function (a, b) {
      return a.identifier.localeCompare(b.identifier);
    });
    return <>
        {this.state.modal && <ModalAddEditUser isOpen={this.state.modal} toggle={this.toggle} user={this.state.editUser} />}
        {this.state.modalResetPassword && <ModalEditPassword isOpen={this.state.modalResetPassword} toggle={this.toggleResetPassword} user={this.state.editUser} />}
        <Row className="p-3">
          <Col><h3>Users list</h3></Col>
          <Col><Button className="float-right m-1" color="primary" onClick={this.toggle}>Add User</Button></Col>
        </Row>
        {this.props.isLoading ? <Loader /> : <UserTable
            listOfRows={users}
            editAction={this.toggle}
            deleteAction={this.deleteUser}
            resetAction={this.toggleResetPassword}
            listOfHeaders={['Identifier', 'Alias', 'Type', 'Action']}
        />}
      </>
  }
}

ManageUsers.defaultProps = {
  users: []
};
const mapStateToProps = state => {
  return {
    isLoading: state.globalError.isLoading,
    users: state.users.users
  };
};

const mapDispatchToProps = {
  getUsersExceptVisitors,
  resetPassword,
  deleteUser
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
