import React from "react";
import { Button } from "reactstrap";
import DQIcon from "../../../image/icon-DQ.svg";
import PlayerActionDisqualifyModal from "../PlayerActionDisqualifyModal";

class DQButton extends React.Component {
  constructor() {
    super();
    this.state = {
      openModal: false
    };
  }

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal
    });
    if (this.props.toggleParent) {
      this.props.toggleParent();
    }
  };

  render() {
    return (
      <>
        <div className="action">
          <Button color="primary" className="action-btn" onClick={this.toggleModal}>
            <img src={DQIcon} className="action-icon" alt="dq" />
          </Button>
          <a>Disqualify player</a>
        </div>
        {this.state.openModal && <PlayerActionDisqualifyModal isOpen={this.state.openModal} toggle={this.toggleModal} onAction={this.props.onAction} player={this.props.player} />}
      </>
    );
  }
}

export default DQButton;
