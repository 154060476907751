import React, {PureComponent} from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,  Input, Form, FormGroup, Label} from 'reactstrap';
import {addUser, postUser} from '../../actions/usersActions';
import Role from "../../enum/UserRole";

class ModalAddEditUser extends PureComponent {

  constructor(props){
    super();
    if(props.user) {
      const user = props.user
      this.state = {
        identifier: user.email,
        alias: user.alias,
        type: user.type,
        password: ""
      };
    }
  }


  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    const { identifier, alias, email, type, password } = this.state;
    if (this.props.user) {
      this.props.postUser({
        identifier,
        alias,
        email: identifier
      });
    } else {
      this.props.addUser(
        {
          identifier,
          alias,
          email: identifier,
          role: type,
          password
        },
        true
      );
    }
    this.props.toggle();
  };

  clearForm = () => {
    this.setState({
      identifier: "",
      type: "",
      alias: "",
      password: "",
      email: ""
    });
  };

  render() {
    return <>
        {this.props.isOpen &&
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}
               onClosed={this.clearForm}>
            <ModalHeader toggle={this.props.toggle}>Save user</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="identifierElm">E-mail as login: </Label>
                        <Input id="identifierElm" disabled={this.props.user} placeholder={"E-mail as login"}
                               name='identifier' onChange={this.handleChange} value={this.state ? this.state.identifier : ""}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="aliasElm">Screen name: </Label>
                        <Input id="aliasElm" placeholder={"Screen name"} name='alias' onChange={this.handleChange}
                               value={this.state ? this.state.alias : ""}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="typeElm">Type of user: </Label>
                        {this.props.user ? (
                                <Input id="typeElm" disabled={this.props.user} placeholder={"Type of user"} name='type'
                                       onChange={this.handleChange} value={this.state ? this.state.type : ""}/>)
                            :
                            (
                                <Input id="typeElm" type="select" placeholder={"Type of user"} name='type'
                                       onChange={this.handleChange} value={this.state ? this.state.type : ""}>
                                    <option/>
                                    {Object.values(Role).map(role => (
                                        <option key={role} value={role}>{role}</option>
                                    ))}
                                </Input>
                            )
                        }
                    </FormGroup>
                    {!this.props.user && <FormGroup>
                        <Label for="passwordElm">Password: </Label>
                        <Input id="passwordElm" type="password" placeholder={"Password"} name='password'
                               onChange={this.handleChange} value={this.state ? this.state.password : ""}/>
                    </FormGroup>}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.handleSubmit}>Save user</Button>
                <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
        }
        </>
  }
}

ModalAddEditUser.defaultProps = {
  isOpen: false,
  user: undefined,
  isDetailView: false
};
const mapStateToProps = state => ({});
const mapDispatchToProps = {
  addUser,
  postUser
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddEditUser);
