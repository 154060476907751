import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { startUnregisterProcessPlayerFromVariant } from "../../../actions/playerActions";
import { ReactComponent as UnregisterIcon } from "../../../image/icon-unreg.svg";
import PlayerActionConfirmationModal from "../PlayerActionConfirmationModal";

class UnregPlayerButton extends React.Component {
  constructor(props) {
    super();
    this.state = {
      openModal: false,
    };
  }

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
    if (this.props.toggleParent) {
      this.props.toggleParent();
    }
  };

  closeModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  render() {
    return (
      <>
        <div className="action">
          <Button color="primary" className="action-btn" onClick={this.toggleModal}>
            <UnregisterIcon className="action-icon" />
          </Button>
          <a>Unreg player</a>
        </div>
        {this.state.openModal && (
          <PlayerActionConfirmationModal
            icon={<UnregisterIcon className="modal-action-icon" />}
            isOpen={this.state.openModal}
            toggle={this.toggleModal}
            action="Unregister"
            doAction={() => {
              this.props.startUnregisterProcessPlayerFromVariant(
                this.props.player.playerInTournamentPhaseVariantId
              );
              if (this.props.onAction) {
                this.props.onAction();
              }
              this.closeModal();
            }}
            text={`To finish the unregister process, please confirm unregister in the cashier / desired MTT / unregister button`}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  startUnregisterProcessPlayerFromVariant,
};

export default connect(null, mapDispatchToProps)(UnregPlayerButton);
