import ConfirmationModal from "../../modals/ConfirmationModal";
import React from "react";

const TableOccupancyWarning = ({open, submit}) => {
    return <ConfirmationModal
        className="dark"
        confirmationText={`Max table occupancy can not be higher than Max No. of players at table!`}
        buttonText="Ok"
        open={open}
        close={submit}
        onSubmitButtonClick={submit}
   />
}

export default TableOccupancyWarning;
