import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVariant, clearVariant, notifyVariantUpdate } from '../actions/tournamentActions';
import socket from '../service/EvSocket';
import variantLevelService from '../service/VariantLevelService';
import { getDefaultVariantStorageId } from '../util/tournamentUtil';

const useVariant = (id, clientIdentifier = 'useVariant', forceReload = '') => {
  if (!id) {
    throw new Error('Id not provide d');
  }
  const dispatch = useDispatch();

  const storageId = clientIdentifier ? `${clientIdentifier}_${id}` : getDefaultVariantStorageId(id);

  const [variantWithCurLevel, setVariantWithLevels] = useState({});

  const tournament = useSelector((state) => state.tournaments.tournament || {});
  const variant = useSelector((state) => state.tournaments.variant[storageId] || {});
  const tournamentRef = useRef(tournament);
  tournamentRef.current = tournament;

  console.log(`useVariant variant-start: ${variant? variant.fetchDataTime : 'xx'}`)

  const registerLevelChangeHandler = (variantToRegister) => {
    const tournament = tournamentRef.current; // useSelector((state) => state.tournaments.tournament || {});
    const v = { ...variantToRegister };
    variantLevelService.unregisterLevelChangeHandler(v);
    variantLevelService.registerLevelChangeHandler(v, (updatedVariant) => {
      registerLevelChangeHandler(updatedVariant);
    });
    if (v.tournamentId === tournament.id) {
      v.tournament = tournament;
    } else {
      v.tournament = {};
    }
    const resolveCurrentLevel = variantLevelService.resolveCurrentLevel(v);
    setVariantWithLevels(resolveCurrentLevel);
  };

  useEffect(() => {
    console.log(`useVariant sockets:`)
    socket.on('updateTournamentVariant', storageId, (newVariant) => {
      const variantInput = newVariant['0'] ? newVariant['0'] : newVariant;
      if (id === variantInput.id && tournamentRef.current.id === variantInput.tournamentId) {
        registerLevelChangeHandler(variantInput);
      }
    });
    return () => {
      socket.removeHandler('updateTournamentVariant', storageId);
    };
  }, []);

  useEffect(() => {
    console.log(`useVariant tournament:`)
    const t = tournamentRef.current;
    const v = { ...variantWithCurLevel };
    if (v.tournamentId === t.id) {
      v.tournament = t;
      setVariantWithLevels(v);
    }
  }, [tournament.id]);


  useEffect(() => {
    console.log(`useVariant getVariant:`)
    dispatch(getVariant(id, storageId));
    return () => {
      dispatch(clearVariant(storageId));
    };
  }, [id, forceReload]);

  useEffect(() => {
    console.log(`useVariant fetchDataTime: ${variant.fetchDataTime}`)
    if (variant.fetchDataTime) {
      registerLevelChangeHandler(variant);
    }
    return () => {
      if (variant.fetchDataTime) {
        variantLevelService.unregisterLevelChangeHandler(variant);
      }
    };
  }, [variant.fetchDataTime]);

  useEffect(() => {
    let everySec;
    if (everySec) {
      clearInterval(everySec);
    }
    everySec = setInterval(() => {
      if (variantWithCurLevel.id === id && variantWithCurLevel.stateId === 3) {
        const _variantWithCurLevel = variantLevelService.resolveCurrentLevel(variantWithCurLevel);
        if(/*if variant changed - to reduce redrawing of childs components*/
            _variantWithCurLevel.id != variantWithCurLevel.id ||
            _variantWithCurLevel.stateId != variantWithCurLevel.stateId ||
            (_variantWithCurLevel.currentLevel || {}).id != (variantWithCurLevel.currentLevel || {}).id ||
            (_variantWithCurLevel.currentLevel || {}).type != (variantWithCurLevel.currentLevel || {}).type
        ){
          console.log('Updating variant details - state changed');

          setVariantWithLevels(_variantWithCurLevel);
        }
      }
    }, 1000);
    return () => {
      if (everySec) {
        clearInterval(everySec);
      }
    };
  }, [variantWithCurLevel.fetchDataTime]);

  return variantWithCurLevel;
};

export default useVariant;
