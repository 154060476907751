import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";

import SliderBox from '../../shared/Sliderbox';
import TablesV2 from '../../shared/TablesV2';
import { getRooms } from '../../../actions/roomActions';
import { setTableStyle } from '../../../util/tournamentUtil';


const TablesForNewDay = (props) => {

  return (
    <div>
      <h3>Tables</h3>
      <Row className="graySeparator" />
      <NumberOfTablePicker
        playersForReseat={props.playersForReseat}
        playersOnFlightTo={props.playersOnFlightTo}
        minNumOfTables={props.minNumOfTables}
        numOfPlayers={props.numOfPlayers}
        tableSize={props.tableSize}
        tableMaxOccupancy={props.tableMaxOccupancy}
        maxPlayersAtTable={props.maxPlayersAtTable}
        onChange={(e, newValue) => {
          props.setTableMaxOccupancy(newValue);
        }}
        onChangeMaxPlayers={(e, newValue) => {
          props.setMaxPlayersAtTable(newValue);
        }}
      />
      <Row className="graySeparator" />
      <TableSelector
        variantId={props.variantId}
        selectedTables={props.selectedTables}
        processTableClick={props.processTableClick}
      />
    </div >
  );
};

const TableSelector = (props) => {
  const dispatch = useDispatch();
  const roomsTables = useSelector((state) => state.rooms.rooms);

  useEffect(() => {
    dispatch(getRooms());
  }, []);

  const isSelectedTable = (table) => props.selectedTables.find((t) => t.id === table.id);

  return (
    <TablesV2
      rooms={roomsTables || []}
      isSelectedTable={isSelectedTable}
      variantId={props.variantId}
      selectedTables={props.selectedTables}
      onTableClick={props.processTableClick}
      setTableStyle={(table, tablesToSave, variantId) => {
        return setTableStyle(table, tablesToSave, variantId, null, null,
          'gray-table', 'red-table',
          'gray-table', 'gray-table',
          'darkGreen-table', 'darkGreen-table', 'gray-table');
      }}
    />
  );
};

const NumberOfTablePicker = (props) => {
    return (
        <Row>
            <Col>
                <h5 className="text-center">Max No. of players at table</h5>
                <SliderBox
                    valueLabelFormat={(value) => `${value} players`}
                    min={2}
                    step={1}
                    max={props.tableSize}
                    value={props.maxPlayersAtTable}
                    valueLabelDisplay="auto"
                    onChange={props.onChangeMaxPlayers}
                    marks={[
                        {
                            value: 2,
                            label: '2',
                        },
                        {
                            value: props.tableSize,
                            label: `${props.tableSize} players`,
                        },
                    ]}
                />
            </Col>

            <Col>
                <h5 className="text-center">Max table occupancy</h5>
                <SliderBox
                    valueLabelFormat={(value) => `${value} players`}
                    min={2}
                    step={1}
                    max={props.tableSize}
                    value={props.tableMaxOccupancy}
                    valueLabelDisplay="auto"
                    onChange={props.onChange}
                    marks={[
                        {
                            value: 2,
                            label: '2',
                        },
                        {
                            value: props.tableSize,
                            label: `${props.tableSize} players`,
                        },
                    ]}
                />
            </Col>

            <Col xs={{span: 2, offset: 2}}>
                <Row>{`Active players: ${props.playersForReseat.length + props.playersOnFlightTo.length}`}</Row>
                <Row>{`Tables needed: ${props.minNumOfTables || ""}`}</Row>
            </Col>
        </Row>
    );
};

export default TablesForNewDay;
