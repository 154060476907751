import React, {useEffect, useState} from 'react';
import {Button, Input} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonGroup} from 'react-bootstrap';
import * as JsSearch from 'js-search';
import Loader from '../../shared/Loader';
import PlayerDetail from './PlayerDetail';

import {
    cleanWaitingList,
    cleanWithoutSeatPlayers,
    getFinishedPlayersByTournamentVariantId as getBustedPlayers,
    getFinishedPlayersClean,
    getWaitingList,
    getWithoutSeatPlayers,
    sitPlayerAndReturnToGameIfNeeded,
} from '../../../actions/playerActions';

import CustomModal from '../../shared/CustomModal';
import {formatPlayerName} from '../../../util/playerUtil';
import restClient from "../../../util/restClient";


const RemoveSeatModal = ({isOpen, toggle, tableId, seatNum}) => {
    return (
        <>
            {isOpen && (
                <CustomModal
                    open={isOpen}
                    closeHandler={toggle}
                    className="choose-remove-seat"
                    dialogClassName="modal-small-width"
                    title="Remove seat"
                >
                    Are you sure you want to remove seat {seatNum}?
                    <Button onClick={() => {
                        return restClient.post(`/tournament/table/${tableId}/closeseat/${seatNum}`)
                            .then((response) => {
                        toggle()
                            })
                    }}
                    >
                        Yes, Im sure
                    </Button>
                </CustomModal>
            )}
        </>
    );
};

export default RemoveSeatModal;
