import React from "react";

const TileTotalChipcounts = ({ tournamentStats } ) => {

    return (
        <div className="item">
            <div className="title">Total chipcount</div>
            <div className="text">
                {tournamentStats.sumOfStack > 0 ? tournamentStats.sumOfStack : 0}
            </div>
        </div>
    )
}

export default TileTotalChipcounts;