import actionTypes from '../actiontypes/redrawRemainingTablesActionTypes';

const initState = {
  draft: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_REDRAW_REMAINING_TABLES_DRAFT_REQUEST:
      return {
        ...state,
        draft: initState.draft,
      };
    case actionTypes.GET_REDRAW_REMAINING_TABLES_DRAFT_RESPONSE:
      return {
        ...state,
        draft: action.data,
      };
    case actionTypes.CLEAR_DRAFT:
      return {
        ...state,
        completed: false,
        draft: initState.draft,
      };
    case actionTypes.POST_REDRAW_REMAINING_TABLES_REQUEST:
      return {
        ...state,
        completed: false,
      };
    case actionTypes.POST_REDRAW_REMAINING_TABLES_RESPONSE:
      return {
        ...state,
        completed: true,
      };
    default:
      return state;
  }
};
