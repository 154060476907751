export default {
  INSERT_PLAYER_REQUEST: 'INSERT_PLAYER_REQUEST',
  INSERT_PLAYER_RECEIVE: 'INSERT_PLAYER_RECEIVE',
  INSERT_PLAYER_PRINTED: 'INSERT_PLAYER_PRINTED',
  INSERT_PLAYER_FAILED: 'INSERT_PLAYER_FAILED',
  INSERT_PLAYER_CLEAN: 'INSERT_PLAYER_CLEAN',
  GET_PLAYERS_BY_TOURNAMENT_ID_REQUEST: 'GET_PLAYERS_BY_TOURNAMENT_ID_REQUEST',
  GET_PLAYERS_BY_TOURNAMENT_ID_RECEIVE: 'GET_PLAYERS_BY_TOURNAMENT_ID_RECEIVE',
  GET_PLAYERS_BY_TOURNAMENT_ID_ERROR: 'GET_PLAYERS_BY_TOURNAMENT_ID_ERROR',
  GET_PLAYERS_BY_TOURNAMENT_ID_CLEAN: 'GET_PLAYERS_BY_TOURNAMENT_ID_CLEAN',
  GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_REQUEST: 'GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_REQUEST',
  GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_RECEIVE: 'GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_RECEIVE',
  GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_ERROR: 'GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_ERROR',
  GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_CLEAN: 'GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_CLEAN',
  DELETE_BY_ID_REQUEST: 'DELETE_BY_ID_REQUEST',
  DELETE_BY_ID_RECEIVE: 'DELETE_BY_ID_RECEIVE',
  REACTIVATE_BY_ID_REQUEST: 'REACTIVATE_BY_ID_REQUEST',
  REACTIVATE_BY_ID_RECEIVE: 'REACTIVATE_BY_ID_RECEIVE',
  DISQUALIFY_BY_ID_REQUEST: 'DISQUALIFY_BY_ID_REQUEST',
  DISQUALIFY_BY_ID_RECEIVE: 'DISQUALIFY_BY_ID_RECEIVE',
  GET_PLAYER_PAYOUT_REQUEST: 'GET_PLAYER_PAYOUT_REQUEST',
  GET_PLAYER_PAYOUT_RESPONSE: 'GET_PLAYER_PAYOUT_RESPONSE',
  RESEAT_PLAYER_REQUEST: 'RESEAT_PLAYER_REQUEST',
  RESEAT_PLAYER_RESPONSE: 'RESEAT_PLAYER_RESPONSE',
  SWAP_PLAYERS_SEATS_REQUEST: 'SWAP_PLAYERS_SEATS_REQUEST',
  SWAP_PLAYERS_SEATS_RESPONSE: 'SWAP_PLAYERS_SEATS_RESPONSE',
  UPDATE_PAYOUT_REQUEST: 'UPDATE_PAYOUT_REQUEST',
  UPDATE_PAYOUT_RESPONSE: 'UPDATE_PAYOUT_RESPONSE',
  FREE_SEATS_REQUEST: 'FREE_SEATS_REQUEST',
  FREE_SEATS_RESPONSE: 'FREE_SEATS_RESPONSE',
  UPDATE_PLAYER_IN_VARIANT_REQUEST: 'UPDATE_PLAYER_IN_VARIANT_REQUEST',
  UPDATE_PLAYER_IN_VARIANT_RESPONSE: 'UPDATE_PLAYER_IN_VARIANT_RESPONSE',
  UPDATE_PLAYER_IN_VARIANT_POSITION_REQUEST: 'UPDATE_PLAYER_IN_VARIANT_POSITION_REQUEST',
  UPDATE_PLAYER_IN_VARIANT_POSITION_RESPONSE: 'UPDATE_PLAYER_IN_VARIANT_POSITION_RESPONSE',

  GET_WAITINGHISTORYLIST_FOR_VARIANT_REQUEST: 'GET_WAITINGHISTORYLIST_FOR_VARIANT_REQUEST',
  GET_WAITINGHISTORYLIST_FOR_VARIANT_RESPONSE: 'GET_WAITINGHISTORYLIST_FOR_VARIANT_RESPONSE',
  GET_WAITINGHISTORYLIST_FOR_VARIANT_CLEAN: 'GET_WAITINGHISTORYLIST_FOR_VARIANT_CLEAN',
  
  GET_MULTIQUALIF_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_REQUEST: 'GET_MULTIQUALIF_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_REQUEST',
  GET_MULTIQUALIF_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_RESPONSE: 'GET_MULTIQUALIF_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_RESPONSE',

  GET_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_REQUEST: 'GET_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_REQUEST',
  GET_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_RESPONSE: 'GET_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_RESPONSE',

  GET_WAITINGLIST_FOR_VARIANT_REQUEST: 'GET_WAITINGLIST_FOR_VARIANT_REQUEST',
  GET_WAITINGLIST_FOR_VARIANT_RESPONSE: 'GET_WAITINGLIST_FOR_VARIANT_RESPONSE',
  GET_WAITINGLIST_FOR_VARIANT_CLEAN: 'GET_WAITINGLIST_FOR_VARIANT_CLEAN',

  GET_PLAYERS_COUNT_RESPONSE: 'GET_PLAYERS_COUNT_RESPONSE',

  GET_BUSTEDPLAYERS_FOR_VARIANT_REQUEST: 'GET_BUSTEDPLAYERS_FOR_VARIANT_REQUEST',
  GET_BUSTEDPLAYERS_FOR_VARIANT_RESPONSE: 'GET_BUSTEDPLAYERS_FOR_VARIANT_RESPONSE',
  GET_BUSTEDPLAYERS_FOR_VARIANT_CLEAN: 'GET_BUSTEDPLAYERS_FOR_VARIANT_CLEAN',

  GET_WITHOUTSEATPLAYERS_FOR_VARIANT_REQUEST: 'GET_WITHOUTSEATPLAYERS_FOR_VARIANT_REQUEST',
  GET_WITHOUTSEATPLAYERS_FOR_VARIANT_RESPONSE: 'GET_WITHOUTSEATPLAYERS_FOR_VARIANT_RESPONSE',
  GET_WITHOUTSEATPLAYERS_FOR_VARIANT_CLEAN: 'GET_WITHOUTSEATPLAYERS_FOR_VARIANT_CLEAN',

  GET_FREESEATS_FOR_VARIANT_REQUEST: 'GET_FREESEATS_FOR_VARIANT_REQUEST',
  GET_FREESEATS_FOR_VARIANT_RESPONSE: 'GET_FREESEATS_FOR_VARIANT_RESPONSE',
  UPDATE_PLAYER_IN_WAITING_LIST_RECEIVE: 'UPDATE_PLAYER_IN_WAITING_LIST_RECEIVE',
  NOTIFY_PLAYER_IN_VARIANT_CHANGE: 'NOTIFY_PLAYER_IN_VARIANT_CHANGE',
};
