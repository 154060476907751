import React from "react";
import useTournament from "../useTournament";

const GetSingleTournamentDataWrapper =  (props) => {
  const tournament = useTournament(props.id);
    const childWithProp = React.Children.map(props.children, (child) => {
        return React.cloneElement(child, { tournament: tournament });
    });
    return <>{childWithProp}</>;
}

export default GetSingleTournamentDataWrapper;
