import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, InputGroup, Row, ToggleButton, ToggleButtonGroup, } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import { getTemplates } from "../../../actions/templateActions";
import { updateTournamentDetail, updateTournamentDetailFromTemplate } from "../../../actions/tournamentActions";
import { defaultEmptyTournament } from "../../../util/tournamentUtil";

const Profile = (props) => {
  const LIST_SIZE = 5;

  const [showResultsState, setShowResultsState] = useState("newProfile");
  const [templateList, setTemplateList] = useState([]);
  const [originalTemplateList, setOriginalTemplateList] = useState([]);
  const [templateListSize, setTemplateListSize] = useState(LIST_SIZE);
  const [searchString, setSearchString] = useState('');
  const [activeTemplate, setActiveTemplate] = useState(null);

  const _getTemplates = () => {
    if (templateList.length === 0) {
      props.getTemplates('tournament_detail').then((response) => {
        setOriginalTemplateList(response.data);
        setTemplateList(response.data);
      });
    }
  };

  const handleChangeProfileMode = (status) => {
    setShowResultsState(status);
    if (status === "existingProfile") _getTemplates();
    else resetTournament();
  };

  const handleActivateTemplate = (template) => {
    if (activeTemplate === template.id) {
      highlightActiveTemplate(null);
      resetTournament();
    } else {
      props.updateTournamentDetailFromTemplate(template.id);
      highlightActiveTemplate(template.id);
    }
  };

  const highlightActiveTemplate = (id) => {
    if (activeTemplate !== id) setActiveTemplate(id);
    else setActiveTemplate(null);
  };

  const resetTournament = () => {
    props.updateTournamentDetail(defaultEmptyTournament());
  };

  const showMoreResults = () => {
    setTemplateListSize(templateListSize + LIST_SIZE);
  };

  const filterResults = (e) => {
    const text = e.target.value;
    setSearchString(text);
    if (text) setTemplateList(originalTemplateList.filter((item) => (item.name).toLowerCase().indexOf(text.toLowerCase()) !== -1));
    else setTemplateList(originalTemplateList);
  };

  return (
    <>
      <Row>
        <div style={{ marginTop: '40px', marginBottom: '20px' }}>Which type of MTT profile?</div>
      </Row>
      <ToggleButtonSwitch handleButtonClick={handleChangeProfileMode} value={showResultsState} />
      {showResultsState === "existingProfile" && <ExistingProfile searchString={searchString} handleSearch={filterResults} />}
      {showResultsState === "existingProfile" && <SearchResults templateList={templateList} listSize={templateListSize} handleShowMoreResults={showMoreResults} handleActivateTemplate={handleActivateTemplate} activeTemplate={activeTemplate} />}
    </>
  );
};

const ToggleButtonSwitch = ({ handleButtonClick, value }) => {
  return <>
    <Row style={{ marginBottom: "40px" }}>
      <ToggleButtonGroup type="checkbox" className="custom-toggle" value={value} name="options">

        <ToggleButton active={value === "newProfile"}
                      value="newProfile"
                      onClick={() => {
                        handleButtonClick("newProfile");
                      }}
        >
          New profile
        </ToggleButton>

        <ToggleButton active={value === "existingProfile"}
                      value="existingProfile"
                      onClick={() => {
                        handleButtonClick("existingProfile");
                      }}
        >
          Choose an existing MTT profile
        </ToggleButton>

      </ToggleButtonGroup>
    </Row>
  </>;
};

const ExistingProfile = ({ searchString, handleSearch }) => (
  <>
    <Row>
      <div style={{ marginBottom: '10px' }}>Choose from existing MTT profile</div>
    </Row>
    <Row>
      <InputGroup className="mtt-input-box">

        <Input id="templateSearchBox" style={{ color: 'white' }} type="text" placeholder="Search MMT from list" value={searchString} onChange={handleSearch} />

        <Button
          variant="outline-secondary"
          onClick={(e) => { document.getElementById('templateSearchBox').focus(); }}
        >
          <FontAwesomeIcon visibility="visible" icon={faSearch} />
        </Button>

      </InputGroup>
    </Row>
  </>
);

const SearchResults = ({
  templateList, listSize, handleShowMoreResults, handleActivateTemplate, activeTemplate,
}) => {
  const handleLongList = (data, size) => data.filter((item, index) => index < size);

  const showMoreResults = templateList.length > listSize;
  const resultsList = showMoreResults ? handleLongList(templateList, listSize) : templateList;

  return (
    <Row>
      <div style={{ padding: '20px 5px', width: '440px' }}>
        {resultsList.map((template) => (
          <div onClick={() => { handleActivateTemplate(template); }} className={`mtt-result-item ${activeTemplate === template.id ? 'active' : ''}`} key={template.id}>{template.name}</div>
        ))}
        {showMoreResults && <div onClick={() => { handleShowMoreResults(); }} className="mtt-result-item show-more">Show more</div>}
      </div>
    </Row>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getTemplates,
  updateTournamentDetailFromTemplate,
  updateTournamentDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
