import actionTypes from '../actiontypes/playerActionTypes';

const initState = {
  createdPlayer: {},
  playersInTournament: {
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: [],
  },
  finishedPlayersInTournament: {
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: [],
  },
  waitingList: {
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: [],
  },
  waitingHistoryList: {
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: [],
  },
  multiQualifPlayersForNewDay:{
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: [],
  },
  playersForNewDay: {
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: [],
  },
  bustedPlayers: {
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: [],
  },
  withoutSeatPlayers: {
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: [],
  },
  waitingListFreeSeats: {
    tournamentVariantId: null,
    isLoading: false,
    isInitialized: false,
    data: {},
  },
};
export default (state = initState, action) => {
  const upsert = (original = [], items, idField = 'id') => {
    items = Array.isArray(items) ? items : [items];
    const result = original.filter((eachOld) => eachOld !== null && !items.some((eachNew) => eachNew[idField] === eachOld[idField]));
    return result.concat(items);
  };

  const updatePayouts = (newState) => {
    const s = newState || state;
    return {
      ...s,
      payouts: upsert(s.payouts, action.data, 'playerInTournamentPhaseVariantId'),
    };
  };

  const updatePlayerInTournament = (newState) => {
    const s = newState || state;
    return {
      ...s,
      playersInTournament: {
        ...s.playersInTournament,
        data: (!s.playersInTournament.tournamentVariantId || s.playersInTournament.tournamentVariantId === action.data.tournamentPhaseVariantId)  ?
            upsert(s.playersInTournament.data, action.data) : s.playersInTournament.data,
      },
    };
  };

  const updateWaitingList = (newState) => {
    const s = newState || state;
    return {
      ...s,
      waitingList: {
        tournamentVariantId: s.waitingList.tournamentVariantId,
        data: upsert(s.waitingList.data, action.data),
      },
    };
  };
  const updateBustedPlayersList = (newState) => {
    const s = newState || state;
    return {
      ...s,
      bustedPlayers: {
        tournamentVariantId: s.bustedPlayers.tournamentVariantId,
        data: upsert(s.bustedPlayers.data, action.data),
      },
    };
  };
  
  const updateReseatToNewDayMultiQualifPlayers = (newState) => {    
    const s = newState || state;
    return {
      ...s,
      multiQualifPlayersForNewDay: {
        data: upsert(s.multiQualifPlayersForNewDay.data, action.data),
      },
    };
  };
  const updateReseatToNewDayPlayers = (newState) => {    
    const s = newState || state;
    return {
      ...s,
      playersForNewDay: {
        data: upsert(s.playersForNewDay.data, action.data),
      },
    };
  };
  const updateWithoutSeatPlayersList = (newState) => {
    const s = newState || state;
    return {
      ...s,
      withoutSeatPlayers: {
        tournamentVariantId: s.withoutSeatPlayers.tournamentVariantId,
        data: upsert(s.withoutSeatPlayers.data, action.data),
      },
    };
  };
  switch (action.type) {
    case actionTypes.INSERT_PLAYER_REQUEST:
      return {
        ...state,
        createdPlayer: {},
      };
    case actionTypes.INSERT_PLAYER_RECEIVE:
      return {
        ...state,
        createdPlayer: { ...action.data, printed: false },
      };
    case actionTypes.INSERT_PLAYER_PRINTED:
      const createdPlayer = { ...state.createdPlayer };
      if (createdPlayer.id === action.id) {
        createdPlayer.printed = true;
      }
      return {
        ...state,
        createdPlayer: createdPlayer,
      };
    case actionTypes.INSERT_PLAYER_FAILED:
      return {
        ...state,
        createdPlayer: {},
      };
    case actionTypes.INSERT_PLAYER_CLEAN:
      return {
        ...state,
        createdPlayer: {},
      };
    case actionTypes.GET_PLAYERS_BY_TOURNAMENT_ID_REQUEST:
      return {
        ...state,
        playersInTournament: {
          isLoading: true,
          isInitialized: false,
          data: [],
          tournamentVariantId: action.data.tournamentVariantId,
        },
      };
    case actionTypes.GET_PLAYERS_BY_TOURNAMENT_ID_RECEIVE:
      return {
        ...state,
        playersInTournament: {
          tournamentVariantId: state.playersInTournament.tournamentVariantId,
          isLoading: false,
          isInitialized: true,
          data: action.data,
        },
      };
    case actionTypes.GET_PLAYERS_BY_TOURNAMENT_ID_ERROR:
      return {
        ...state,
        playersInTournament: {
          tournamentVariantId: state.playersInTournament.tournamentVariantId,
          isLoading: false,
          isInitialized: false,
          error: action.data,
          data: [],
        },
      };
    case actionTypes.GET_PLAYERS_BY_TOURNAMENT_ID_CLEAN: {
      return {
        ...state,
        playersInTournament: initState.playersInTournament,
      };
    }
    case actionTypes.GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_REQUEST:
      return {
        ...state,
        finishedPlayersInTournament: {
          isLoading: true,
          isInitialized: false,
          data: [],
          tournamentVariantId: action.data.tournamentVariantId,
        },
      };
    case actionTypes.GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_RECEIVE:
      return {
        ...state,
        finishedPlayersInTournament: {
          tournamentVariantId: state.finishedPlayersInTournament.tournamentVariantId,
          isLoading: false,
          isInitialized: true,
          data: action.data,
        },
      };
    case actionTypes.GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_ERROR:
      return {
        ...state,
        finishedPlayersInTournament: {
          tournamentVariantId: state.finishedPlayersInTournament.tournamentVariantId,
          isLoading: false,
          isInitialized: false,
          error: action.data,
          data: [],
        },
      };
    case actionTypes.GET_FINISHED_PLAYERS_BY_TOURNAMENT_ID_CLEAN: {
      return {
        ...state,
        finishedPlayersInTournament: initState.finishedPlayersInTournament,
      };
    }
    case actionTypes.GET_PLAYER_PAYOUT_REQUEST:
      return {
        ...state,
        payouts: [],
      };
    case actionTypes.RESEAT_PLAYER_REQUEST:
      return {
        ...state,
      };
    case actionTypes.RESEAT_PLAYER_RESPONSE:
      return {
        ...state,
        payouts: action.data,
      };
    case actionTypes.GET_PLAYER_PAYOUT_RESPONSE:
    case actionTypes.UPDATE_PAYOUT_RESPONSE:
      return updatePayouts();
    case actionTypes.NOTIFY_PLAYER_IN_VARIANT_CHANGE:
      return { ...updateWaitingList(updatePlayerInTournament(updatePayouts())) };
    case actionTypes.UPDATE_PLAYER_IN_VARIANT_POSITION_RESPONSE:
      return updatePlayerInTournament();
    case actionTypes.GET_WAITINGLIST_FOR_VARIANT_REQUEST: {
      return {
        ...state,
        waitingList: {
          isLoading: true,
          isInitialized: false,
          data: [],
          tournamentVariantId: action.data.tournamentVariantId,
        },
      };
    }
    case actionTypes.GET_PLAYERS_COUNT_RESPONSE: {
      return {
        ...state,
        playersCount: {
          tournamentId: action.data.tournamentId,
          waiting: action.data.waiting,
          withoutSeat: action.data.withoutSeat,
        },
      };
    }
    case actionTypes.GET_WAITINGLIST_FOR_VARIANT_RESPONSE:
      return updateWaitingList();
    case actionTypes.GET_WAITINGLIST_FOR_VARIANT_CLEAN:
      return {
        ...state,
        waitingList: { ...initState.waitingList },
      };
    case actionTypes.GET_WAITINGHISTORYLIST_FOR_VARIANT_REQUEST:
      return {
        ...state,
        waitingHistoryList: {
          tournamentVariantId: action.data.tournamentVariantId,
          isLoading: true,
          isInitialized: false,
          data: [],
        },
      };
    case actionTypes.GET_WAITINGHISTORYLIST_FOR_VARIANT_RESPONSE:      
      return {
        ...state,
        waitingHistoryList: {
          isLoading: false,
          isInitialized: true,
          data: action.data,
        },
      };      
    case actionTypes.GET_WAITINGHISTORYLIST_FOR_VARIANT_CLEAN:
      return {
        ...state,
        waitingHistoryList: initState.waitingHistoryList,
      };

      case actionTypes.GET_MULTIQUALIF_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_REQUEST: {
        return {
          ...state,
          multiQualifPlayersForNewDay: {
            isLoading: true,
            isInitialized: false,
            data: []
          },
        };
      }

      case actionTypes.GET_MULTIQUALIF_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_RESPONSE:
      return updateReseatToNewDayMultiQualifPlayers();

      case actionTypes.GET_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_REQUEST: {
        return {
          ...state,
          playersForNewDay: {
            isLoading: true,
            isInitialized: false,
            data: []
          },
        };
      }
      
      case actionTypes.GET_PLAYERS_FOR_RESEAT_FOR_NEW_DAY_RESPONSE:
        return updateReseatToNewDayPlayers();
      
    case actionTypes.GET_BUSTEDPLAYERS_FOR_VARIANT_REQUEST: {
      return {
        ...state,
        bustedPlayers: {
          isLoading: true,
          isInitialized: false,
          data: [],
          tournamentVariantId: action.data.tournamentVariantId,
        },
      };
    }
    case actionTypes.GET_BUSTEDPLAYERS_FOR_VARIANT_RESPONSE:
      return updateBustedPlayersList();
    case actionTypes.GET_BUSTEDPLAYERS_FOR_VARIANT_CLEAN:
      return {
        ...state,
        bustedPlayers: initState.bustedPlayers,
      };
    case actionTypes.GET_WITHOUTSEATPLAYERS_FOR_VARIANT_REQUEST: {
      return {
        ...state,
        withoutSeatPlayers: {
          isLoading: true,
          isInitialized: false,
          data: [],
          tournamentVariantId: action.data.tournamentVariantId,
        },
      };
    }
    case actionTypes.GET_WITHOUTSEATPLAYERS_FOR_VARIANT_RESPONSE:
      return updateWithoutSeatPlayersList();
    case actionTypes.GET_WITHOUTSEATPLAYERS_FOR_VARIANT_CLEAN:
      return {
        ...state,
        withoutSeatPlayers: initState.withoutSeatPlayers,
      };
    case actionTypes.GET_FREESEATS_FOR_VARIANT_REQUEST: {
      return {
        ...state,
        withoutSeatPlayers: {
          tournamentVariantId: action.data.tournamentVariantId,
          isLoading: true,
          isInitialized: false,
          data: null,
        },
      };
    }
    case actionTypes.GET_FREESEATS_FOR_VARIANT_RESPONSE:
      return {
        ...state,
        waitingListFreeSeats: {
          tournamentVariantId: state.waitingListFreeSeats.tournamentVariantId,
          isLoading: false,
          isInitialized: true,
          data: action.data,
        },
      };
    case actionTypes.FREE_SEATS_REQUEST:
      return {
        ...state,
      };
    case actionTypes.FREE_SEATS_RESPONSE:
      return {
        ...state,
        freeSeats: action.data,
      };
    default:
      return state;
  }
};
