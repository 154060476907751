import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { getRooms } from "../../../actions/roomActions";
import { updateTournamentDetail } from "../../../actions/tournamentActions";
import { Room } from "../../../models";
import RoomSpaceGrid from "../../functionalComponents/RoomSpaceGrid";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-mini";

class Tables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grid: [],
      activeRoom: {},
      nextRoom: {},
      switchRoom: false,
    };
  }

  componentDidMount = () => {
    this.props.getRooms().then(() => {
      this.setState({ activeRoom: this.props.rooms[0] });
      if (this.props.switchToAnotherRoom) {
        this.props.switchToAnotherRoom(this.props.rooms[0]);
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.tables !== prevProps.tables) {
      var roomId = this.props.tables[0].roomId;
      var active = this.props.rooms.find((r) => r.id === roomId);
      if (this.state.activeRoom.id !== active.id) this.setState({ activeRoom: active });
    }
  };

  onRoomButtonClick(room) {
    if (this.state.activeRoom.id === room.id) return;

    var existingChanges = this.props.areTablesChanged ? this.props.areTablesChanged() : false;
    if (existingChanges) {
      this.setState({ switchRoom: true });
      this.setState({ nextRoom: room });
    } else {
      this.setState({ activeRoom: room });
      if (this.props.switchToAnotherRoom) {
        this.props.switchToAnotherRoom(room);
      }
    }
  }

  switchToAnotherRoom() {
    if (this.props.switchToAnotherRoom) {
      this.props.switchToAnotherRoom(this.state.nextRoom);
    }

    this.setState({ activeRoom: this.state.nextRoom });
  }

  saveAndSwitch() {
    if (this.props.saveAndSwitch) {
      this.props.saveAndSwitch(this.state.nextRoom);
      this.setState({ activeRoom: this.state.nextRoom });
    }
  }

  onTableClick(table, i, j) {
    if (this.props.onTableClick) {
      this.props.onTableClick(table, i, j);
    } else {
      const variant = this.props.variant;
      var index = variant.tables.findIndex((t) => t.tableDefId === table.id);

      if (index >= 0) {
        variant.tables.splice(index, 1);
      } else {
        variant.tables.push({
          tableDefId: table.id,
          fromTime: moment(variant.scheduledStartAt).startOf("day"),
          tillTime: moment(variant.scheduledStartAt).endOf("day"),
        });
      }
      this.props.updateTournamentDetail(this.props.tournament);
    }
  }

  setTableStyle(table) {
    if (this.props.setTableStyle) {
      return this.props.setTableStyle(table, this.props.variant.tournament.conflictTables);
    } else {
      const baseStyle = "poker-table-locker ";
      if (table.id === undefined) {
        return baseStyle + "noTable";
      }

      const variant = this.props.variant;
      if (variant.tables.some((t) => t.tableDefId === table.id)) {
        return baseStyle + "green-table";
      }
      // if (table.tournamentVariant && table.tournamentVariant.length > 0) {
      //   return baseStyle + "red-table";
      // }
      return baseStyle + "gray-table";
    }
  }

  render() {
    if (!this.state.activeRoom) {
      return <div>There is not room. Go to "Poker room layout" and add room</div>;
    }

    var roomButtons = this.props.rooms
      .filter((room) => room.tables.length > 0)
      .map((room) => {
        return (
          <input
            type="button"
            className={
              room.id === this.state.activeRoom.id
                ? "border-line-bottom-active"
                : "border-line-bottom"
            }
            onClick={() => this.onRoomButtonClick(room)}
            value={room.roomName}
            key={room.id}
          />
        );
      });

    return (
      <>
        <Container fluid="sm" style={{ backgroundColor: "#151c2b", whiteSpace: "inherit" }}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <h2>{this.props.title ? this.props.title : "Add tables"}</h2>
                </Col>
              </Row>
              <Row>
                <div className="line"></div>
              </Row>
              <Row>{roomButtons}</Row>
              <Row
                style={{
                  visibility:
                    this.state.activeRoom && this.state.activeRoom.id === undefined
                      ? "collapse"
                      : "visible",
                }}
              >
                <div style={{ float: "right" }} className="info-text d-none d-md-none">
                  <FontAwesomeIcon className={"info-icon"} icon={faInfoCircle} />
                  {"-click on field to add or remove table"}
                </div>
                <RoomSpaceGrid
                  tables={this.props.tables ? this.props.tables : this.state.activeRoom.tables}
                  onTableClick={this.onTableClick.bind(this)}
                  spaceGridType={"spaceGrid"}
                  tableStyle={this.setTableStyle.bind(this)}
                />
              </Row>
            </Col>
          </Row>
        </Container>
        {this.state.switchRoom && this.props.saveAndSwitch && (
          <ConfirmationModal
            confirmationText={
              "You have unsaved changes in " +
              this.state.activeRoom.roomName +
              ". Are you sure you want to switch to another room?"
            }
            buttonText="Switch"
            open={true}
            close={() => this.setState({ switchRoom: false })}
            onSubmitButtonClick={this.switchToAnotherRoom.bind(this)}
            buttonText2="Save & Switch"
            onSubmitButton2Click={this.saveAndSwitch.bind(this)}
          />
        )}
      </>
    );
  }
}

Tables.propTypes = {
  getRooms: PropTypes.func.isRequired,
};

Tables.defaultProps = {
  rooms: [],
};

Tables.propTypes = {
  rooms: PropTypes.arrayOf(Room),
};

const mapStateToProps = (state) => {
  return {
    tournament: state.tournaments.tournamentToSave,
    rooms: state.rooms.rooms,
  };
};

const mapDispatchToProps = {
  getRooms,
  updateTournamentDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
