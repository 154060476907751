import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { history } from '../service/history';
import TournamentVariantList from '../components/tournament/TournamentVariantList';
import TableDetail from '../components/tournament/floor/TableDetail';
import GetTournamentDataWrapper from '../components/shared/GetTournamentDataWrapper';
import PlayerDetail from '../components/tournament/floor/PlayerDetail';
import TableList from '../components/tournament/floor/TableList';
import Players from '../components/tournament/floor/Players';
import FloorChat from '../components/tournament/floor/Chat';

const FloorPage = () => (
  <Container>
    <GetTournamentDataWrapper>
      <Router history={history}>
        <Switch>
          <Route path="/variant/:variantId/table/:tableId/player/:playerId" render={(props) => <PlayerDetail {...props} />} />
          <Route path="/variant/:variantId/table/:tableId" render={(props) => <TableDetail {...props} />} />
          <Route path="/players" render={(props) => <Players {...props} />} />
          <Route
            path="/chat"
            render={(props) => (
              <FloorChat {...props} />
            )}
          />
          <Route render={(props) => <TableList {...props} />} />
          <Route render={() => <TournamentVariantList hideHistory />} />
        </Switch>
      </Router>
    </GetTournamentDataWrapper>
  </Container>
);

export default FloorPage;
