import React, { useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { Col } from 'react-bootstrap';
import CustomModal from '../../shared/CustomModal';
import TemplateImage from '../../../image/template.png';

const SaveTemplateModal = (props) => {
  const [templateName, setTemplateName] = useState('');
  return (
    <CustomModal
      title="Save as a template"
      text="Would you like to save this payout structure temlate?"
      open={props.isOpen}
      className="save-tournament-modal"
      dialogClassName="modal-small-width"
      closeHandler={() => {
        props.onCancel();
      }}
      imagesrc={TemplateImage}
      height="145px"
    >
      <Col className="content">
        <Label for="templateName">Template name</Label>
        <Input
          id="templateName"
          placeholder="example: payout structure Monday"
          name="templateName"
          onChange={(e) => setTemplateName(e.target.value)}
          value={templateName}
        />
        <Button
          color="primary mt-3"
          onClick={() => {
            props.onConfirm(templateName);
          }}
        >
          Save by this name
        </Button>
      </Col>
    </CustomModal>
  );
};

export default SaveTemplateModal;
