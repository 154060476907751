import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPlayersCount,
} from '../../actions/playerActions';
import UpdatePlayerStateWrapper from './UpdatePlayerStateWrapper';
import socket from '../../service/EvSocket';
import useVariant from '../useVariant';
import { getFlatVariantList } from '../../util/tournamentUtil';

const Content = (props) => {
  const [tournamentVariantId, setTournamentVariantId] = useState(null);
  const dispatch = useDispatch();

   if (props.variantId !== tournamentVariantId) {
     setTournamentVariantId(props.variantId);
   }

  const getPlayersCountWrap = () => {
    if(tournamentVariantId) {
      dispatch(getPlayersCount(tournamentVariantId));
    }
  }

  useEffect(() => {
    getPlayersCountWrap();
  }, [tournamentVariantId]);

  useEffect(() => {
    socket.on('playersOnTableChange', 'WaitingListUpdateFreeSeats2', () => {
      getPlayersCountWrap()
    });
    socket.on(
      'updateTournamentVariant',
      'WaitingListUpdateFreeSeats2',
      (payload) => {
        if (
          payload
          && payload.length > 0
          && payload[0].id
          && payload[0].id === tournamentVariantId
        ) {
          getPlayersCountWrap();
        }
      },
      [],
    );
    return () => {
      socket.removeHandler('playersOnTableChange', 'WaitingListUpdateFreeSeats2');
      socket.removeHandler('updateTournamentVariant', 'WaitingListUpdateFreeSeats2');
    };
  }, []);

  return (
    <UpdatePlayerStateWrapper />
  );
};

const ContentParent = (props) => {
  let variantId = sessionStorage.getItem('variantId');
  const variants = getFlatVariantList(props.tournament);

  if (variants.length === 0) {
    return <></>;
  }


  if(!variantId && variants) {
    variantId = variants[0].id
  }

  const variant = useVariant(variantId, props.id);
  variant.tournament = { ...props.tournament };

  // const variant = useVariant(variants[0].id, props.id);
  // variant.tournament = { ...props.tournament };

  return <Content variant={variant} variantId={variantId} />;


};

const NavLinkWaitingList = () => {
  const tournament = useSelector((state) => state.tournaments.tournament);
  return (
    <ContentParent tournament={tournament} id="WaitingList" />
  );
};

export default NavLinkWaitingList;
