import moment from 'moment-mini';
import passwordValidator from 'password-validator';
import { display } from '../enum/Currency';
import Nationality from "../enum/Nationality";

const passwordValidatorSchema = new passwordValidator();
passwordValidatorSchema
  .is()
  .min(6)
  .is()
  .max(20)
  .digits();

export const getLeadingZeroIndex = (index) => {
  const indexString = `0${index}`;
  return indexString.slice(indexString.length - 2, indexString.length);
};

export const formatDate = (value) => {
  if (!value) return '';
  return moment(value).format('DD.MM.YYYY');
};

export const formatDateTime = (value) => {
  if (!value) return '';
  return moment(value).format('DD.MM.YYYY HH:mm');
};

export const formatDateShortDash = (value) => {
  if (!value) return '';
  return moment(value).format('YYYY-MM-DD');
};

export const formatTime = (value) => {
  if (!value) return '';
  return moment(value).format('HH:mm');
};

export const formatAmount = (value, currency = 'EUR', minimumFractionDigits = 2) => {
  if (!value && value !== 0) return '';

  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits,
  });

  return formatter.format(value);
};

function formatBonus(bonus, currency) {
  if (!bonus) {
    return "";
  }

  let formattedBonus;
  if (isNaN(bonus)) {
    formattedBonus = bonus;
  } else {
    formattedBonus = formatAmount(bonus, currency);
  }
  return ` ${formattedBonus} `;
}

export const formatPosition = (positions, amount, bonus, currency) => {

  if(!amount) {
    return ""
  }

  let pos;

  if(positions.length === 1) {
    let position = positions[0]
    switch (position) {
      case 1:
        pos = '1st';
        break;
      case 2:
        pos = '2nd';
        break;
      case 3:
        pos = '3rd';
        break;
      default:
        pos = `${position}th`;
    }
  } else {
    const lastPosition = positions[positions.length - 1]
    let firstPosition = positions[0]
    switch (lastPosition) {
      case 2:
        pos = 'nd';
        break;
      case 3:
        pos = 'rd';
        break;
      default:
        pos = 'th';
    }
    pos = `${firstPosition} - ${lastPosition}${pos}`;
  }

  return `${pos}: ${formatAmount(amount, currency, 0)}${formatBonus(bonus, currency)}           `
};

export const formatPositions = (tournamentPayouts, currency) => {
  const payouts = tournamentPayouts

  const formattedPositions = []
  let equalPositions = []


  for(let i = 0; i < payouts.length; i++)
  {
    const payout = payouts[i]

    if(i === 0) {
      equalPositions.push(payout.position)
    } else {
      const prevPayout = payouts[i - 1]
      if(payout.toPayout === prevPayout.toPayout && payout.bonus === prevPayout.bonus) {
        equalPositions.push(payout.position)
      } else {
        formattedPositions.push(formatPosition(equalPositions, prevPayout.toPayout, prevPayout.bonus, currency))
        equalPositions = []
        equalPositions.push(payout.position)
      }
    }

    if(i === payouts.length - 1 && equalPositions.length > 0) {
      formattedPositions.push(formatPosition(equalPositions, payout.toPayout, payout.bonus, currency))
    }
  }

  return formattedPositions.join("")

  // payouts.forEach
  //
  //     .map(
  //         (payout) =>
  //             `${formatPosition(payout.position)}: ${formatAmount(
  //                 payout.toPayout,
  //                 thisTournament.currency,
  //                 0
  //             )}${formatBonus(payout)}              `
  //     )
  //     .join("")}
}

export const displayMoney = (number, currency) => {
  if (isNaN(number)) {
    return '--';
  }
  if (number !== undefined && number !== null) {
    return `${display(currency || '', number.toFixed(2))}`;
  }
  return '--';
};

export const displayNumber = (number) => {
  if (isNaN(number)) {
    return '--';
  }
  if (number == null) {
    return 0;
  }

  return number;
};

export const arrayContainsSome = (array, anyValues) => {
  if (!array || !anyValues) {
    return false;
  }

  return anyValues.some((val) => array.indexOf(val) > -1);
};

export const getPasswordValidator = () => passwordValidatorSchema;

export const isEmailValid = (value) => {
  if (!value) {
    return false;
  }
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
};

export const toBoolean = (value) => {
   if(value === 1 || value === "1" || value === "true" || value === true) {
     return true;
   }
   return false;
};

export const getNationalityDesc = (value) => {
  if(!value) {
    return ""
  }
  const desc = Nationality[value]
  if(desc) {
    return desc
  }
  return ""
}
