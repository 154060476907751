import React, { Component } from 'react';
import { Row } from 'reactstrap';
import Loader from '../shared/Loader';
import { formatPlayerName } from '../../util/playerUtil';
import { formatDate } from '../../util/stringUtil';

class RegisterFoundPlayers extends Component {
  shouldComponentUpdate(prevProps) {
    if (!this.props.selectedCustomerId) {
      return true;
    }
    const should = this.props.selectedCustomerId !== prevProps.selectedCustomerId;
    if (should) return true;

    const prevPlayersIds = prevProps.playersState.data.map((p) => p.id);
    const newPlayersIds = this.props.playersState.data.map((p) => p.id);

    if (prevPlayersIds.length !== newPlayersIds.length) {
      return true;
    }

    return !prevPlayersIds.every((p) => newPlayersIds.indexOf(p) > -1);
  }

  getReentryInfo = (input) => {
    if (input === "PLAYER_IS_STILL_PLAYING")
      return "Player is still playing";
    else if (input === "MAX_NUM_REENTRY_EXCEEDED")
      return "Exceeded max reentry count";

    return "";
  }

  canSelect = (player) => {
    return !(player.buyInAllowed === false && player.reasonReEntryForbidden);
  }

  render() {
    const { playersState } = this.props;

    const players = this.props.playersState && this.props.playersState.data;
    const playersUnique = [];
    for (let index = 0; index < players.length; index++) {
      const eachPlayerState = players[index];
      const existingIndex = playersUnique.findIndex(each => each.id === eachPlayerState.id);
      if (existingIndex < 0) {
        playersUnique.push(eachPlayerState);
      }
    }

    if (!playersState.isLoading && !playersState.isInitialized) {
      return (
        <div className="seach-msg">
          <span>Start to search players with at least 3 letters</span>
        </div>
      );
    }

    if (!playersState.isLoading && playersState.isInitialized && playersUnique.length === 0) {
      return (
        <div className="seach-msg">
          <span>No players found</span>
        </div>
      );
    }

    const PlayerCard = ({ player }) => (
      <Row
        key={player.id}
        className={`player ${player.id === this.props.selectedCustomerId ? 'selected' : ''}`}
        noGutters
        onClick={(e) => {
          this.canSelect(player) && this.props.setSelectedCustomerId(player.id, e)
        }}
      >
        <div className="col1">
          <span>{formatPlayerName(player)}</span>
        </div>
        <div className="col5">
          <span>{this.getReentryInfo(player.reasonReEntryForbidden)}</span>
        </div>
        <div className="col2">{player.nationality}</div>
        <div className="col3">
          <span>{player.idCardNum}</span>
        </div>
        <div className="col3">{formatDate(player.dateOfBirth)}</div>
        <div className="col4">{player.id}</div>
      </Row>
    );

    return (
      <Loader className="wv">
        <div className="players">
          <Row className="player header" noGutters>
            <div className="col1">PLAYER</div>
            <div className="col1"></div>
            <div className="col2">NATIONALITY</div>
            <div className="col3">PERSONAL ID</div>
            <div className="col3">DATE OF BIRTH</div>
            <div className="col4">SYSTEM ID</div>
          </Row>
          <div className="scroll">
            {playersUnique.map((player) => (
              <PlayerCard player={player} />
            ))}
          </div>
        </div>
      </Loader>
    );
  }
}

export default RegisterFoundPlayers;
