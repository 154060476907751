import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-mini";
import {
  faClock,
  faUsers,
  faCalendarDays,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons/index";
import Loader from "../shared/Loader";
import "react-day-picker/lib/style.css";
import DateFilterModal from "../modals/DateFilterModal";
import TournamentState from "../../enum/TournamentState";
import {
  getFlatVariantList,
  getMttBgColorCss,
  getTournamentTitle,
  isMultiday,
  resolveRegistrationState,
  resolveState,
} from "../../util/tournamentUtil";
import GetTournamentDataWrapper from "../shared/GetTournamentDataWrapper";
import { history } from "../../service/history";
import { ReactComponent as TournamentIcon } from "../../image/icon-tournament.svg";
import TournamentHistoryDetailModal from "./floor/TournamentHistoryDetailModal";
import StateVariantRegistration from "../../enum/StateVariantRegistration";
import { isMobile } from 'react-device-detect';

const DISPLAY = {
  RUNNING_UPCOMING: "runningUpcoming",
  HISTORY: "history",
};

const Tournament = (props) => {
  const tournament = props.tournament;
  const [nowTillEndOfRegistrationInMinutes, setNowTillEndOfRegistrationInMinutes] = useState(null);
  const [registrationState, setRegistrationState] = useState({});
  const isMultiDayValue = isMultiday(tournament);
  const isSingleDay = !isMultiDayValue;
  let singleVariantInfo;
  let lables = [];

  if (isSingleDay) {
    const variant = tournament.tournamentPhases[0].variants[0];
    useEffect(() => {
      const state = resolveRegistrationState(variant);
      setNowTillEndOfRegistrationInMinutes(state.numMinTillEnd);
      setRegistrationState(state);
      const timer = setInterval(() => {
        if (variant.stateId === 2) {
          //const compareAgainstTime= moment(variant.stateId === 2 ? moment() : variant.fetchDataTime);
          const state = resolveRegistrationState(variant /*, compareAgainstTime*/);
          setNowTillEndOfRegistrationInMinutes(state.numMinTillEnd);
        }
      }, 1000);
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }, [
      variant.registrationCloseAtLevel,
      variant.scheduledStartAt,
      variant.extraTime,
      variant.stateId,
      variant.fetchDataTime,
    ]);

    singleVariantInfo = (
      <Row>
        <Col style={{ width: "120px" }}>
          <FontAwesomeIcon icon={faCalendarDays} className="card-icon" />
          {`${moment(variant.scheduledStartAt).format("DD.MM.YYYY HH:mm ")}`}
        </Col>

        {nowTillEndOfRegistrationInMinutes !== undefined && nowTillEndOfRegistrationInMinutes != 0 && (
          <Col className={isMobile ? "pl-0" : ""}>
            <FontAwesomeIcon icon={faClock} className="card-icon" />
            {`${Math.floor(nowTillEndOfRegistrationInMinutes)}min late reg.`}
          </Col>
        )}
      </Row>
    );

    //Labels
    let stateLabelStyle, stateLabel;
    switch (resolveState(tournament)) {
      case TournamentState.ACTIVE:
        stateLabelStyle = "green-label";
        stateLabel = "Running";
        break;
      case TournamentState.FUTURE:
        stateLabelStyle = "violet-label";
        stateLabel = "Upcoming";
        break;
    }
    if (stateLabel) {
      lables.push(
        <span key="stateLabel" className={stateLabelStyle}>
          {stateLabel}
        </span>
      );
    }

    let regStateLabel, regStateStyle;
    switch (registrationState.state) {
      case StateVariantRegistration.RUNNING_REGISTRATION:
      case StateVariantRegistration.RUNNING_NO_CLOSING_REGISTRATION:
        regStateLabel = "Open reg";
        regStateStyle = "green-label";
        break;
    }
    if (regStateLabel) {
      lables.push(
        <span key="regStateLabel" className={regStateStyle}>
          {regStateLabel}
        </span>
      );
    }
  }
  const variants = getFlatVariantList(tournament);
  const numOfActivePlayers = variants.reduce((sum, eachVariant) => {
    return sum + eachVariant.numOfActivePlayers;
  }, 0);
  const numOfPlayers = variants.reduce((sum, eachVariant) => {
    return sum + eachVariant.numOfPlayers;
  }, 0);
  // https://trello.com/c/Bchs1e4G/349-mtt-list-running-a-upcoming-num-ost%C3%A1vaj%C3%BAcich-regnut%C3%BDch-hr%C3%A1%C4%8Dov
  const playersInfo = (
    <>
      <span className={"float-right d-none d-md-block"}>
        <FontAwesomeIcon icon={faUsers} className="card-icon" />
        {`${numOfActivePlayers}/${numOfPlayers}`}
      </span>
      <span className={"text-muted float-right d-block d-md-none"}>
        <FontAwesomeIcon icon={faUsers} className="card-icon" />
        {`${numOfActivePlayers}/${numOfPlayers}`}
      </span>
    </>
  );

  const conflictInfo = (conflictTables) => {
    if (tournament.conflictTables && tournament.conflictTables.length) {
      return (
        <span>
          <FontAwesomeIcon icon={faTriangleExclamation} className="card-icon violet" />
          {`Tables in conflict with ${conflictTables.map((t) => t.tournament_name).join(", ")}`}
        </span>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <Card
        className="card center d-md-none d-block pl-0 pr-0"
        onClick={() => {
          props.setSelectedTournament(tournament);
          if (props.activeTournaments) {
            history.push(`/tournament/${tournament.id}`);
          } else {
            props.setOpenHistoryDetail(true);
          }
        }}
      >
        {/*<Card.Text>*/}
        <Row>
          <Col xs={1}>
            <div className={`tournament-icon ${getMttBgColorCss(tournament)}`}>
              <TournamentIcon className="icon2" />
            </div>
          </Col>
          <Col xs={11} className={"pl-4"}>
            <h4>
              <b>{getTournamentTitle(tournament)}</b>
              {lables}
            </h4>
            <div className="text-muted">{isMultiDayValue ? `Multiday MTT` : singleVariantInfo}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12 }}>{playersInfo}</Col>
        </Row>
        {/*</Card.Text>*/}
      </Card>
      <Card className="card center d-none d-md-block">
        {/*<Card.Text>*/}
        <Row>
          <Col xs={1}>
            <div className={`tournament-icon ${getMttBgColorCss(tournament)}`}>
              <TournamentIcon className="icon2" />
            </div>
          </Col>
          <Col xs={2}>
            <Row>
              <b>{getTournamentTitle(tournament)}</b>
              {lables}
            </Row>
          </Col>
          <Col xs={2}>{conflictInfo(tournament.conflictTables)}</Col>
          <Col xs={2}>{isMultiDayValue ? `Multiday MTT` : singleVariantInfo}</Col>
          <Col xs={2}>{playersInfo}</Col>
          <Col xs={2}>
            <Button
              color="primary"
              className="fit"
              onClick={() => {
                props.setSelectedTournament(tournament);
                if (props.activeTournaments) {
                  history.push(`/tournament/${tournament.id}`);
                } else {
                  props.setOpenHistoryDetail(true);
                }
              }}
            >
              Open MTT
            </Button>
          </Col>
        </Row>
        {/*</Card.Text>*/}
      </Card>
    </>
  );
};

function Tournaments(props) {
  const [openHistoryDetail, setOpenHistoryDetail] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);

  return (
    <div className="tournaments">
      {props.tournaments.map((tournament) => {
        return (
          <Tournament
            key={`tournament_${tournament.id}`}
            tournament={tournament}
            activeTournaments={props.activeTournaments}
            setOpenHistoryDetail={setOpenHistoryDetail}
            setSelectedTournament={setSelectedTournament}
          />
        );
      })}
      {openHistoryDetail && (
        <TournamentHistoryDetailModal
          tournament={selectedTournament}
          open
          toggle={() => {
            setOpenHistoryDetail(false);
            setSelectedTournament(null);
          }}
        />
      )}
    </div>
  );
}

class TournamentVariantList extends React.Component {
  state = {
    display: DISPLAY.RUNNING_UPCOMING,
    dateFrom: undefined,
    tournaments: [],
    openDateFilter: false,
    upcomingFrom: null,
    upcomingTo: null,
  };

  setDisplay = (display) => {
    this.setState({ display });
  };

  applyFilter = (from, to) => {
    this.setState({
      openDateFilter: false,
      upcomingFrom: from,
      upcomingTo: to,
    });
  };

  getTournamentsByState = (state) => {
    const tournaments = [];
    this.props.tournaments.forEach((eachTournament) => {
      const resolvedState = resolveState(eachTournament);
      if (resolvedState === state) {
        tournaments.push(eachTournament);
      }
    });

    return tournaments;
  };

  getRunningTournamentVariants = () => {
    return this.getTournamentsByState(TournamentState.ACTIVE).sort((tournamentA, tournamentB) => {
      const isMultidayValueA = isMultiday(tournamentA);
      const isMultidayValueB = isMultiday(tournamentB);
      if (isMultidayValueA && isMultidayValueB) {
        return 0;
      }
      if (isMultidayValueA) {
        return 1;
      }
      if (isMultidayValueB) {
        return -1;
      }
      const variantA = tournamentA.tournamentPhases[0].variants[0];
      const variantB = tournamentB.tournamentPhases[0].variants[0];
      return (
        moment(variantB.startedAt).toDate().getTime() -
        moment(variantA.startedAt).toDate().getTime()
      );
    });
  };

  getUpcomingTournamentVariants = () => {
    return this.getTournamentsByState(TournamentState.FUTURE).sort((tournamentA, tournamentB) => {
      const isMultidayValueA = isMultiday(tournamentA);
      const isMultidayValueB = isMultiday(tournamentB);
      if (isMultidayValueA && isMultidayValueB) {
        return 0;
      }
      if (isMultidayValueA) {
        return 1;
      }
      if (isMultidayValueB) {
        return -1;
      }
      const variantA = tournamentA.tournamentPhases[0].variants[0];
      const variantB = tournamentB.tournamentPhases[0].variants[0];
      return (
        moment(variantA.scheduledStartAt).toDate().getTime() -
        moment(variantB.scheduledStartAt).toDate().getTime()
      );
    });
  };

  getClosedTournamentVariants = () => {
    var closed = this.getTournamentsByState(TournamentState.FINISHED);
    var sorted = closed.sort(function (a, b) {
      return moment(b.endAt).toDate() - moment(a.endAt).toDate();
    });

    return sorted;
  };

  render() {
    return (
      <>
        <div id="tournamentList">
          <Row>
            <Col>
              <h3>Tournaments</h3>
            </Col>
          </Row>
          <Row className="line" />

          <div className="tab-container d-none d-md-block">
            <Row>
              <Col md={10}>
                <ButtonGroup className="btn-switch big">
                  {!this.props.hideRunning && (
                    <Button
                      variant="dark"
                      active={this.state.display === DISPLAY.RUNNING_UPCOMING}
                      onClick={() => this.setDisplay(DISPLAY.RUNNING_UPCOMING)}
                    >
                      Running {"&"} upcoming
                    </Button>
                  )}
                  {!this.props.hideHistory && (
                    <Button
                      variant="dark"
                      active={this.state.display === DISPLAY.HISTORY}
                      onClick={() => this.setDisplay(DISPLAY.HISTORY)}
                    >
                      History
                    </Button>
                  )}
                </ButtonGroup>
              </Col>
              <Col md={2}>
                <Button
                  className="mtt-header-btn"
                  variant="outline-secondary"
                  onClick={() => {
                    history.push("/mtt");
                  }}
                >
                  Create new MTT
                </Button>
              </Col>
            </Row>
          </div>

          {this.state.display === DISPLAY.UPCOMING && (
            <Row>
              <Col>
                <div className="filter">
                  <a>Filter by date</a>
                  <FontAwesomeIcon
                    onClick={() => this.setState({ openDateFilter: true })}
                    icon={faFilter}
                    className="btn-icon"
                  />
                </div>
              </Col>
            </Row>
          )}
          <Loader>
            <Row>
              <Col xs={12} className="list">
                {this.state.display === DISPLAY.RUNNING_UPCOMING && (
                  <GetTournamentDataWrapper activeTournaments>
                    <Tournaments
                      tournaments={this.getRunningTournamentVariants()}
                      activeTournaments
                    />
                    <Tournaments
                      tournaments={this.getUpcomingTournamentVariants()}
                      activeTournaments
                    />
                  </GetTournamentDataWrapper>
                )}
                {this.state.display === DISPLAY.HISTORY && (
                  <GetTournamentDataWrapper activeTournaments={false}>
                    <Tournaments
                      tournaments={this.getClosedTournamentVariants()}
                      activeTournaments={false}
                    />
                  </GetTournamentDataWrapper>
                )}
              </Col>
            </Row>
          </Loader>
          <DateFilterModal
            open={this.state.openDateFilter}
            apply={this.applyFilter}
            close={() => this.setState({ openDateFilter: false })}
          />
        </div>
      </>
    );
  }
}

TournamentVariantList.defaultProps = {
  tournaments: [],
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.globalError.isLoading,
    tournaments: state.tournaments.tournaments,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentVariantList);
