import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getFrColorCss } from '../../util/tournamentUtil';

const InfoCard = (props) => {
  const colSizing = props.itemsPerCol ? 12 / props.itemsPerCol : true;

  return (
    <div className="card-container">
      <Col md={12} className="card-title">
        {props.title}
      </Col>
      <hr />
      <Row>
        {props.list.map((item, index) => (
          <Col key={index} md={colSizing}>
            <div className="card-item-desc">{item.title}</div>
            <div className={`card-item-content ${getFrColorCss(item.color)}`}>{item.value}</div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

const listStructure = PropTypes.shape({
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
});

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  itemsPerCol: PropTypes.number,
  list: PropTypes.arrayOf(listStructure).isRequired,
};

export default InfoCard;
