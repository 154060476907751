import axios from 'axios';
import { useSelector } from 'react-redux';
import { history } from '../service/history';

const getApuUrl = (url) => {
  if (url.startsWith('/')) {
    return `/api${url}`;
  }
  return `/api/${url}`;
};

const getTournamentId = () => {
  const tournament = sessionStorage.getItem('tournamentId');
  if (tournament) {
    return tournament;
  }
  return '';
};

const getVariantId = () => {
  const variantId = sessionStorage.getItem('variantId');
  if (variantId) {
    return variantId;
  }
  return '';
};

const get = (url) => axios({
  method: 'get',
  url: getApuUrl(url),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'xsrf-token': localStorage.getItem('xsrf-token'),
    tournamentId: getTournamentId(),
    variantId: getVariantId(),
  },
})
  .catch((err) => {
    if (err.response && err.response.status === 403) {
      setTimeout(() => {
        history.push('/login');
      }, 1500);
    }
    return Promise.reject(err);
  });

const getAsyncWrap = async (url, state, setState) => {
  setState({...state, isLoading: true, error: null})
  try {
    const data = await getAsync(url)
    setState({...state, data: data.data, isLoading: false});
  } catch(e) {
    setState({...state, data: null, isLoading: false, error: e});
  }
}

const getAsync = async (url, isBlob=undefined) => {
  let data
  try {
    data = await axios.get(getApuUrl(url), {
      withCredentials: true,
      responseType: isBlob ? 'blob' : undefined,
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': localStorage.getItem('xsrf-token'),
          tournamentId: getTournamentId(),
          variantId: getVariantId(),
        }
      }
    )
  } catch(err) {
      if (err.response && err.response.status === 403) {
        setTimeout(() => {
          history.push('/login');
        }, 1500);
      }
      throw err;
  }

  return data;
}


const post = (url, data) => axios({
  method: 'post',
  url: getApuUrl(url),
  data,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'xsrf-token': localStorage.getItem('xsrf-token'),
    tournamentId: getTournamentId(),
    variantId: getVariantId(),
  },
});
const put = (url, data) => axios({
  method: 'put',
  url: getApuUrl(url),
  data,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'xsrf-token': localStorage.getItem('xsrf-token'),
    tournamentId: getTournamentId(),
    variantId: getVariantId(),
  },
});
const putAsync = async (url, data) => {
  let response
  try {
    response = await axios.put(getApuUrl(url), data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': localStorage.getItem('xsrf-token'),
          tournamentId: getTournamentId(),
          variantId: getVariantId(),
        }
      }
    )
  } catch(err) {
    if (err.response && err.response.status === 403) {
      setTimeout(() => {
        history.push('/login');
      }, 1500);
    }
    throw err;
  }

  return response;
}

const postAsync = async (url, data) => {
  let response
  try {
    response = await axios.post(getApuUrl(url), data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': localStorage.getItem('xsrf-token'),
          tournamentId: getTournamentId(),
          variantId: getVariantId(),
        }
      }
    )
  } catch(err) {
    if (err.response && err.response.status === 403) {
      setTimeout(() => {
        history.push('/login');
      }, 1500);
    }
    throw err;
  }

  return response.data;
}

const doDelete = (url, data) => axios({
  method: 'delete',
  url: getApuUrl(url),
  withCredentials: true,
  data,
  headers: {
    'Content-Type': 'application/json',
    'xsrf-token': localStorage.getItem('xsrf-token'),
    tournamentId: getTournamentId(),
    variantId: getVariantId(),
  },
});

export default {
  post,
  postAsync,
  get,
  getAsync,
  getAsyncWrap,
  put,
  putAsync,
  doDelete,
  getApuUrl
};
