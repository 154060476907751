export const validateTournamentStructure = (structure) => {
  const validationErrors = [];
  if (!structure)
    return;
  structure.forEach((each) => {
    const eachValidationErrors = {
      smallBlind: !(each.smallBlind > 0),
      bigBlind: !(each.bigBlind > 0),
      ante: each.ante ? !(each.ante >= 0) : false,
      durationInMin: !(each.durationInMin > 0),
    };
    validationErrors.push(eachValidationErrors);
  })
  return validationErrors;
};

export const isValidByValidationResults = (errorObject) => {
  const errorAsArray = Array.isArray(errorObject) ? errorObject : [errorObject];
  for (let countErrorAr = 0; countErrorAr < errorAsArray.length; countErrorAr++) {
    const each = errorAsArray[countErrorAr];
    for (const errorProp in each) {
      if (Object.prototype.hasOwnProperty.call(each, errorProp)) {
        const error = each[errorProp];
        if (error) {
          return false;
        }
      }
    }
  }
  return true;
};
