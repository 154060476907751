import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTournamentsByState, notifyTournamentUpdate, notifyNewTournamentUpdate, notifyVariantUpdate, cleanTournamentDetail,
} from '../../actions/tournamentActions';
import socket from '../../service/EvSocket';

const useTournaments = (tournamentState) => {
  const dispatch = useDispatch();
  const tournaments = useSelector((state) => state.tournaments.tournaments);

  useEffect(() => {
    socket.on('updateTournament', 'GetTournamentDataWrapper', (payload, dispatch) => {
      dispatch(notifyTournamentUpdate(payload));
    });

    socket.on('newTournament', 'GetTournamentDataWrapper', (payload, dispatch) => {
      dispatch(notifyNewTournamentUpdate(payload));
    });

    socket.on('updateTournamentVariant', 'GetTournamentDataWrapper', (payload, dispatch) => {
      dispatch(notifyVariantUpdate(payload));
    });
    return () => {
      socket.removeHandler('updateTournament', 'GetTournamentDataWrapper');
      socket.removeHandler('newTournament', 'GetTournamentDataWrapper');
    };
  }, []);

  useEffect(() => {
    dispatch(getTournamentsByState(tournamentState));
    return () => {
      dispatch(cleanTournamentDetail());
    };
  }, [tournamentState]);

  return tournaments || [];
};

export default useTournaments;
