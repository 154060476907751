import actionTypes from '../actiontypes/templateActionTypes';
import restClient from '../util/restClient';
import { combinedRegisterLoaderAndShowError } from './commonActions';

const insertRequest = () => ({ type: actionTypes.INSERT_TEMPLATE_REQUEST });
const insertResponse = (data) => ({ type: actionTypes.INSERT_TEMPLATE_RESPONSE, data });
const updateRequest = () => ({ type: actionTypes.UPDATE_TEMPLATE_REQUEST });
const updateResponse = (data) => ({ type: actionTypes.UPDATE_TEMPLATE_RESPONSE, data });
const getRequest = () => ({ type: actionTypes.GET_TEMPLATES_REQUEST });
const getResponse = (data) => ({ type: actionTypes.GET_TEMPLATES_RESPONSE, data });

const getPayoutStructuresRequest = () => ({ type: actionTypes.GET_PAYOUT_STRUCTURES_REQUEST });
const getPayoutStructuresResponse = (data) => ({ type: actionTypes.GET_PAYOUT_STRUCTURES_RESPONSE, data });

const getPayoutStructureValuesRequest = () => ({ type: actionTypes.GET_PAYOUT_STRUCTURE_VALUES_REQUEST });
const getPayoutStructureValuesResponse = (data) => ({ type: actionTypes.GET_PAYOUT_STRUCTURE_VALUES_RESPONSE, data });

const getSingleRequest = () => ({ type: actionTypes.GET_SINGLE_TEMPLATE_REQUEST });
const getSingleResponse = (data) => ({ type: actionTypes.GET_SINGLE_TEMPLATE_RESPONSE, data });
const changeObject = (data) => ({ type: actionTypes.CHANGE_SINGLE_TEMPLATE_RESPONSE, data });
const deleteRequest = () => ({ type: actionTypes.DELETE_TEMPLATE_REQUEST });
const deleteResponse = (id) => ({ type: actionTypes.DELETE_TEMPLATES_RESPONSE, id });

export const duplicateTemplate = (orgTemplateId, templateName, url) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    const detailUrl = url || `/template/detail/${orgTemplateId}`;
    dispatch(insertRequest());
    return restClient.get(detailUrl)
      .then((originalTemplateResponse) => {
        const originalTemplate = originalTemplateResponse.data;
        return restClient.put('/template', {
          name: templateName,
          type: originalTemplate.type,
          content: originalTemplate.content,
        }).then((response) => {
          dispatch(insertResponse(response.data));
          return Promise.resolve(response);
        });
      });
  },
  'insertTemplate',
  'Can not save template',
);

export const insertTemplate = (templateType, templateName, payload) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(insertRequest());
    return restClient
      .put('/template', {
        name: templateName,
        type: templateType,
        content: payload,
      })
      .then((response) => {
        dispatch(insertResponse(response.data));
        return Promise.resolve(response);
      });
  },
  'insertTemplate',
  'Can not save template',
);

export const updateTemplate = (template) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updateRequest());
    return restClient
      .post('/template', template)
      .then((response) => {
        dispatch(updateResponse(response.data));
        return Promise.resolve(response);
      });
  },
  'updateTemplate',
  'Can not save template',
);

export const deleteTemplate = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(deleteRequest([]));
    return restClient.doDelete(`/template/${id}`).then((response) => {
      dispatch(deleteResponse(id));
      return Promise.resolve(response);
    });
  },
  'deleteTemplates',
  'Can not delete template',
);

export const getTemplateById = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getSingleRequest([]));
    return restClient.get(`/template/detail/${id}`).then((response) => {
      dispatch(getSingleResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTemplateById',
  'Can not load template',
);

export const changeTemplateObject = (template) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(changeObject(template));
    return Promise.resolve();
  },
  'changeTemplateObject',
  'Can update template object',
);

export const getTemplates = (templateType, name) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getRequest([]));
    return restClient.get(`/template/${templateType}${name ? `/${name}` : ''}`).then((response) => {
      dispatch(getResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTemplates',
  'Can not resolve list of templates',
);

export const getTemplatePayoutStructure = () => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPayoutStructuresRequest([]));
    return restClient.get('/template/templatepayoutstructure').then((response) => {
      dispatch(getPayoutStructuresResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTemplatePayoutStructure',
  'Can not resolve template payout structures',
);

export const getTemplatePayoutStructureValues = (groupid, payoutDescriptionId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getPayoutStructureValuesRequest([]));
    return restClient.get(`template/templatepayoutstructure/${groupid}/${payoutDescriptionId}`).then((response) => {
      dispatch(getPayoutStructureValuesResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTemplatePayoutStructure',
  'Can not resolve template payout structures',
);
