import React from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { resetPassword } from "../../actions/usersActions";

class ModalEditPassword extends React.Component {
  state = {
    identifier: "",
    alias: "",
    type: "",
    password: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      const { user } = nextProps;
      this.setState({
        password: user.password
      });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    const { password } = this.state;
    this.props.resetPassword({
      identifier: this.props.user.identifier,
      password
    });
    this.props.toggle();
  };

  clearForm = () => {
    this.setState({
      password: ""
    });
  };

  render() {
    return <>
        {this.props.isOpen && <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className} onClosed={this.clearForm}>
        <ModalHeader toggle={this.props.toggle}>Reset password</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="passwordElm">New password: </Label>
              <Input id="passwordElm" type="password" placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSubmit}>
            Save password
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>}
      </>
  }
}

ModalEditPassword.defaultProps = {
  isOpen: false,
  user: undefined
};
const mapStateToProps = state => ({});
const mapDispatchToProps = {
  resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditPassword);
