import React, { useEffect, useState } from "react";
import moment from "moment-mini";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import { resolveRegistrationState } from "../../util/tournamentUtil";
import CircleIcon from "../functionalComponents/CircleIcon";
import Loader from "../shared/Loader";

export const TableItem = (props) => {
  //const compareAgainstTime = compareAgainstTimes[index];
  const variant = props.variant;

  const [nowTillEndOfRegistrationInMinutes, setNowTillEndOfRegistrationInMinutes] = useState(
    undefined
  );

  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const registrationState = resolveRegistrationState(variant /*, compareAgainstTime*/);
    setNowTillEndOfRegistrationInMinutes(registrationState.numMinTillEnd);
    const timer = setInterval(() => {
      setCurrentTime(moment());
      //const compareAgainstTime= moment(variant.stateId === 2 ? moment() : variant.fetchDataTime);
      if (variant.stateId === 2) {
        const registrationState = resolveRegistrationState(variant /*, compareAgainstTime*/);
        let nowTillEndOfRegistrationInMinutes = registrationState.numMinTillEnd;
        setNowTillEndOfRegistrationInMinutes(nowTillEndOfRegistrationInMinutes);
      }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [variant.registrationCloseAtLevel, variant.scheduledStartAt, variant.extraTime, variant.stateId, variant.fetchDataTime]);

  const tableSize = Math.min(variant.tableMaxOccupancy, variant.tableSize);
  return (
    <tr>
      <td>
        <CircleIcon color={variant.color} />
        <b>{getVariantTitle(variant)}</b>
        {variant.stateId === 2 ? <span className="green-label"> RUNNING</span> : null}
      </td>
      <td>{`${moment(variant.scheduledStartAt).format("DD.MM.YYYY HH:mm ")}`}</td>
      <td>
        {nowTillEndOfRegistrationInMinutes === undefined
          ? ""
          : `${Math.floor(nowTillEndOfRegistrationInMinutes)} min`}
      </td>
      <td>{`${variant.numOfActivePlayers}/${variant.numOfPlayers}`}</td>
      <td>{`${tableSize * variant.numOfTables - variant.numOfActivePlayers > 0
          ? tableSize * variant.numOfTables - variant.numOfActivePlayers
          : 0
        }`}</td>
      <td className="d-flex flex-row">{props.columns.map((col) => col(variant, currentTime))}</td>
    </tr>
  );
};

export const TournamentVariantTableRunning = (props) => {
  return (
    <Loader>
      <Table className="tournament-table">
        <thead>
          <tr>
            <th key="tournament">TOURNAMENT</th>
            <th key="starts_on">MTT STARTS ON</th>
            <th key="reg_close_in">REG. CLOSE IN</th>
            <th key="no_players">NO. PLAYERS</th>
            <th key="free_seats">FREE SEATS</th>
            <th key="modal_buttons" />
          </tr>
        </thead>
        <tbody>
          {props.variants.map((variant) => {
            return <TableItem key={variant.id} variant={variant} columns={props.columns} />;
          })}
        </tbody>
      </Table>
    </Loader>
  );
};

const getVariantTitle = (variant) => {
  if (variant.tournamentIsMultiday || variant.tournament.tournamentPhases.length > 1) {
    return `${variant.num !== null ? `#${variant.num}, ` : ""}${variant.tournamentName}_${variant.name
      }`;
  }

  return variant.tournamentName;
};

export const TournamentVariantTableUpcoming = (props) => (
  <Loader>
    <Table className="tournament-table">
      <thead>
        <tr>
          <th key="tournament">TOURNAMENT</th>
          <th key="starts_on">MTT STARTS ON</th>
          <th key="register_starts">REGISTISTRATION STARTS AT</th>
        </tr>
      </thead>
      <tbody>
        {props.variants.map((variant) => (
          <tr>
            <td>
              <CircleIcon color={variant.color} />
              <b>{getVariantTitle(variant)}</b>
            </td>
            <td>{`${moment(variant.scheduledStartAt).format("DD.MM.YYYY HH:mm ")}`}</td>
            <td>{`${moment(variant.registrationStartAt).format("DD.MM.YYYY HH:mm ")}`}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Loader>
);

export const TournamentVariantTableHistory = (props) => (
  <Loader>
    <div>
      <a>Filter by date</a>
      <FontAwesomeIcon onClick={props.openFilterModal} icon={faFilter} className="btn-icon" />
    </div>
    <Table className="tournament-table">
      <thead>
        <tr>
          <th key="tournament">TOURNAMENT</th>
          <th key="finished_on">FINISHED ON</th>
          <th key="balance_btn" />
        </tr>
      </thead>
      <tbody>
        {props.variants.map((variant) => (
          <tr>
            <td>
              <CircleIcon color={variant.color} />
              <b>{getVariantTitle(variant)}</b>
              {variant.stateId === 2 ? <span className="green-label">RUNNING</span> : null}
            </td>
            <td>{`${variant.finishedAt ? moment(variant.finishedAt).format("DD.MM.YYYY HH:mm ") : ""
              }`}</td>
            <td className="d-flex flex-row">
              {props.columns.map((col) => {
                if (col(variant, moment()).props.id === "balance_sheet") {
                  return col(variant, moment());
                }
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Loader>
);
