import React from 'react';
import SpectatorClock from '../components/mttdirector/SpectatorClock';

const SpectatorPage = (props) => {
    return (
      <SpectatorClock />
    );
}

export default SpectatorPage;
