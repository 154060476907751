import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import { Button, Card, Dropdown } from "react-bootstrap";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "../../../service/history";
import TemplateType from "../../../enum/TemplateType";
import { formatDate } from "../../../util/stringUtil";
import ConfirmationModal from "../../modals/ConfirmationModal";
import {
  getTemplates,
  deleteTemplate,
  duplicateTemplate,
  getTemplatePayoutStructure,
} from "../../../actions/templateActions";
import Loader from "../../shared/Loader";

const TemplateList = (props) => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templates.templates);

  const payoutStructures = useSelector((state) => state.templates.payoutStructures);
  const [nameFilter, setNameFilter] = useState();
  const [payoutStructureType, setPayoutStructureType] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [allPayoutTemplates, setAllPayoutTemplates] = useState([{ label: "Self created" }]);

  useEffect(() => {
    dispatch(getTemplates(props.templateType, (nameFilter || "").length > 0 ? nameFilter : null));
  }, [props.templateType, nameFilter]);

  useEffect(() => {
    dispatch(getTemplatePayoutStructure());
  }, []);

  useEffect(() => {
    if (payoutStructures.length > 0) {
      setAllPayoutTemplates([
        ...payoutStructures.map((group) => {
          const { groupId, payoutRedistribution } = group;
          const payoutRedistributionWithUrl = payoutRedistribution.map((each) => {
            const { id } = each;
            return { ...each, url: `/template/templatepayoutstructure/${groupId}/${id}` };
          });
          return { ...group, payoutRedistribution: payoutRedistributionWithUrl };
        }),
        { label: "Self created", payoutRedistribution: templates },
      ]);
    }
    setPayoutStructureType(allPayoutTemplates[0]);
  }, [payoutStructures]);

  useEffect(() => {
    setPayoutStructureType(allPayoutTemplates[0]);
  }, [allPayoutTemplates.length]);

  const handlePayoutSelect = (payoutStructureType) => {
    setPayoutStructureType(payoutStructureType);
  };

  const onDuplicateClick = (template) => {
    if (props.templateType === TemplateType.TOURNAMENT_PAYOUT) {
      setShowInfoModal(true);
    }
    dispatch(duplicateTemplate(template.id, `${template.name}_copy`, template.url));
  };

  const enableDelete = () => {
    return (
      props.templateType !== TemplateType.TOURNAMENT_PAYOUT ||
      (payoutStructureType && payoutStructureType.label === "Self created")
    );
  };

  let titleType;
  let createNewButtonlabel;
  switch (props.templateType) {
    case TemplateType.TOURNAMENT_DETAIL:
      titleType = "MTT";
      createNewButtonlabel = "Create MTT template";
      break;
    case TemplateType.TOURNAMENT_STRUCTURE:
      titleType = "Structures";
      createNewButtonlabel = "Create structure template";
      break;
    case TemplateType.TOURNAMENT_PAYOUT:
      titleType = "Payout Structure";
      createNewButtonlabel = "Create payout structure template";
      break;
    default:
  }
  return (
    <Loader>
      <h2>{`Templates ${titleType ? `- ${titleType}` : ""}`}</h2>
      <div className="graySeparator" />
      <Row className="my-3">
        <Col xs={2} className="filter">
          <Input
            id="filterText"
            placeholder="Find template"
            onChange={(e) => setNameFilter(e.target.value)}
          />
          <FontAwesomeIcon icon={faSearch} />
        </Col>
        <Col xs={{ span: 2, offset: 5 }}>
          <Dropdown
            className="payout-dropdown"
            style={{
              visibility:
                props.templateType === TemplateType.TOURNAMENT_PAYOUT ? "visible" : "hidden",
            }}
          >
            <Dropdown.Toggle as="div" className="mtt-toggle form-control">
              {payoutStructureType ? payoutStructureType.label : ""}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {allPayoutTemplates.map((p) => (
                <Dropdown.Item as="div" key={p.label} onClick={() => handlePayoutSelect(p)}>
                  {p.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={{ span: 3 }}>
          {props.templateType !== TemplateType.TOURNAMENT_DETAIL && (
            <Button
              color="primary"
              className="fit"
              onClick={() => {
                history.push(`/template/create/${props.templateType}`);
              }}
            >
              {createNewButtonlabel}
            </Button>
          )}
        </Col>
      </Row>
      {(
        (props.templateType === TemplateType.TOURNAMENT_PAYOUT &&
          payoutStructureType &&
          payoutStructureType.payoutRedistribution) ||
        templates ||
        []
      ).map((template) => (
        <Card className="card center" key={template.id}>
          <Card.Title>
            <Row>
              <Col sm={3}>{template.name}</Col>
              <Col sm={2}>{formatDate(template.createdAt)}</Col>
              <Col sm={2}>{template.createdByAlias}</Col>
              <Col sm={2}>
                <Button
                  color="primary"
                  className="fit"
                  onClick={() => {
                    history.push(
                      `/template/detail/${template.id}/${
                        props.templateType === TemplateType.TOURNAMENT_PAYOUT
                          ? payoutStructureType.groupId
                          : undefined
                      }`
                    );
                  }}
                >
                  See Detail
                </Button>
              </Col>
              <Col sm={2}>
                <Button
                  onClick={() => {
                    onDuplicateClick(template);
                  }}
                  className="btn-dark outline my-auto fit"
                >
                  Duplicate
                </Button>
              </Col>
              <Col sm={1}>
                {enableDelete() && (
                  <FontAwesomeIcon
                    color="#4a389b"
                    className=""
                    onClick={() => {
                      setSelectedTemplate(template);
                      setShowConfirmModal(true);
                    }}
                    icon={faTrash}
                    size="lg"
                  />
                )}
              </Col>
            </Row>
          </Card.Title>
        </Card>
      ))}
      {showConfirmModal && (
        <ConfirmationModal
          confirmationText={`Are you sure you want to delete ${selectedTemplate.name}?`}
          buttonText="Yes"
          buttonText2="Cancel"
          open={true}
          close={() => setShowConfirmModal(false)}
          onSubmitButton2Click={() => setShowConfirmModal(false)}
          onSubmitButtonClick={() => dispatch(deleteTemplate(selectedTemplate.id))}
        />
      )}
      {showInfoModal && (
        <ConfirmationModal
          confirmationText={`This duplicate will appear in self created payout structures`}
          buttonText="Ok"
          open={true}
          close={() => setShowInfoModal(false)}
          onSubmitButtonClick={() => setShowInfoModal(false)}
        />
      )}
    </Loader>
  );
};
export default TemplateList;
