import { Button } from "react-bootstrap";
import { addExtraTime, changeLevel, changeVariantState } from "../../../actions/tournamentActions";
import moment from "moment-mini";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPause, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { isMobile } from 'react-device-detect';

const AddRemoveMinutes = (props) => {
  const { variant } = props;
  const dispatch = useDispatch();

  const isAddMinuteDisabled =
    variant.id === undefined || (variant.state !== "active" && variant.state !== "paused");

  return (
    <>
      <div className={isMobile ? "action-panel-mobile" : "action-panel"}>
        <p className="action-panel-title">Minutes</p>
        <div className={isMobile ? "buttons" : ""}>
          <Button
            className={`circle ${isAddMinuteDisabled ? "disabled" : ""}`}
            disabled={isAddMinuteDisabled}
            onClick={() => {
              // const currentLevel = variant.currentLevel || {};
              // const left = Math.min(moment.duration(moment().diff(currentLevel.mStartAt)).asSeconds(), 60);
              // if (left > 0) {
              //   dispatch(addExtraTime(variant.id, left / 60));
              // }
              dispatch(addExtraTime(variant.id, 1));
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Button
            className={`circle ${isAddMinuteDisabled ? "disabled" : ""}`}
            disabled={isAddMinuteDisabled}
            onClick={() => {
              const currentLevel = variant.currentLevel || {};
              if (moment.duration(currentLevel.mEndAt.diff(moment())).asSeconds() >= 60) {
                dispatch(addExtraTime(variant.id, -1));
              }
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddRemoveMinutes;
