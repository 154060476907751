import actionTypes from '../actiontypes/templateActionTypes';

const initState = {
  templates: [],
  payoutStructures:[],
  template: {}

  // [{"id":4,"name":"TEst","type":"tournament_detail","createdAt":"2020-02-28T15:19:39.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":5,"name":"TEst","type":"tournament_detail","createdAt":"2020-02-28T15:20:55.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":6,"name":"TEst","type":"tournament_detail","createdAt":"2020-02-28T16:21:01.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":7,"name":"TEst","type":"tournament_detail","createdAt":"2020-03-02T17:44:16.000Z","createdById":2,"createdByAlias":"mmtDirector"},{"id":8,"name":"tttttttt","type":"tournament_detail","createdAt":"2020-03-18T15:14:00.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":9,"name":"","type":"tournament_detail","createdAt":"2020-03-23T20:04:50.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":10,"name":"","type":"tournament_detail","createdAt":"2020-04-01T16:14:46.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":12,"name":"TEst","type":"tournament_detail","createdAt":"2020-05-11T09:46:10.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":13,"name":"","type":"tournament_detail","createdAt":"2020-11-25T21:22:24.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":16,"name":"tttttttt_copy","type":"tournament_detail","createdAt":"2021-01-24T20:40:36.000Z","createdById":9,"createdByAlias":"mttDirector"},{"id":17,"name":"tttttttt_copy_copy","type":"tournament_detail","createdAt":"2021-01-24T20:46:37.000Z","createdById":9,"createdByAlias":"mttDirector"}]

};
export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_SINGLE_TEMPLATE_REQUEST:
      return {
        ...state,
        template: initState.template,
      };
    case actionTypes.GET_SINGLE_TEMPLATE_RESPONSE:
      const content = JSON.parse(action.data.content);
      if(action.data.type === 'tournament_detail') {
        let i = 1
        //to prevent error in multiday tournament template view detail
        content.tournamentPhases.forEach((eachPhase) => {
          eachPhase.variants.forEach((eachVariant) => {
            eachVariant.id = i++;
          });
        });
      }
      return {
        ...state,
        template: { ...action.data, content },
      };
    case actionTypes.CHANGE_SINGLE_TEMPLATE_RESPONSE:
      return {
        ...state,
        template: action.data,
      };
    case actionTypes.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        templates: initState.templates,
      };
    case actionTypes.GET_TEMPLATES_RESPONSE:
      return {
        ...state,
        templates: action.data,
      };
    case actionTypes.GET_PAYOUT_STRUCTURES_REQUEST:
      return {
        ...state,
        payoutStructures: initState.payoutStructures,
      };
    case actionTypes.GET_PAYOUT_STRUCTURES_RESPONSE:
      return {
        ...state,
        payoutStructures: action.data,
      };
      case actionTypes.GET_PAYOUT_STRUCTURE_VALUES_REQUEST:
        return {
          ...state,
          template: initState.template,
        };
    case actionTypes.GET_PAYOUT_STRUCTURE_VALUES_RESPONSE:
      const cont = action.data.content;
      return {
        ...state,
        template: { ...action.data, cont},
      };
    case actionTypes.INSERT_TEMPLATE_RESPONSE:
      const templatesInInsertAction = [...state.templates];
      templatesInInsertAction.push(action.data);
      return {
        ...state,
        templates: templatesInInsertAction,
      };
    case actionTypes.UPDATE_TEMPLATE_RESPONSE:
      const templatesInUpdateAction = state.templates.map((each) => {
        if (each.id === action.data.id) {
          return action.data;
        }
        return each;
      });
      return {
        ...state,
        templates: templatesInUpdateAction,
      };
    case actionTypes.DELETE_TEMPLATES_RESPONSE:
      const templatesInDeleteAction = [...state.templates];
      const indexToDelete = templatesInDeleteAction.findIndex((t) => t.id === action.id);
      if (indexToDelete >= 0) {
        templatesInDeleteAction.splice(indexToDelete, 1);
      }
      return {
        ...state,
        templates: templatesInDeleteAction,
      };
    default:
      return state;
  }
};
