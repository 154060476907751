import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row } from "reactstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import StructureContainer from "./detailcomponents/StructureContainer";
import {
    updateTournamentDetail,
    deleteUnsavedTournamentDetail,
    updateVariant,
    getTournament,
    initTournamentToSave,
} from "../../actions/tournamentActions";
import {
    unsavedChanges,
    unsavedChangesClear
} from "../../actions/commonActions"
import { resolveCurrentLevel } from "../../util/tournamentUtil";
import Loader from "../shared/Loader";
import UpdatePlayerStateWrapper from "../shared/UpdatePlayerStateWrapper";
import VariantPicker from "../shared/VariantPicker";
import StructureWithStartingLevelContainer from "./detailcomponents/StructureWithStartingLevelContainer";

class EditStructure extends React.Component {
    state = {
        editedVariantId: undefined,
        showValidationWarningModal: false,
    };

    componentDidMount() {
        const { tournament } = this.props;
        this.props.initTournamentToSave(tournament);
    }

    componentWillUnmount() {
        this.props.deleteUnsavedTournamentDetail();
    }


    hasVariantValidationError(variant) {
        if (!variant.structure) {
            return true;
        }

        const validationErrors = Object.values(
            StructureContainer.getValidationErrors({}/*not needed*/, variant)
        );
        return validationErrors.some((errorItem) => Object.values(errorItem).some((val) => val));
    }

    saveAllChanges(variant) {

        const someValidationError = this.hasVariantValidationError(variant);

        if (someValidationError) {
            this.setState({ showValidationWarningModal: true });
        } else {
            this.props.updateVariant(variant);
        }

        this.props.unsavedChangesClear()
    }

    closeValidationWarningModal = () => {
        this.setState({ showValidationWarningModal: false });
    };

    resetChanges = () => {
        const { tournament } = this.props;
        this.props.getTournament(tournament.id);

        this.props.unsavedChangesClear()
    };

    showUnsavedWarning() {
        this.props.unsavedChanges("You have unsaved changes, please save or cancel chagnes?")
    }

    render() {
        const { tournament } = this.props;

        if (!tournament) {
            return <Loader />;
        }
        return (
            <div id="editStructure">
                <UpdatePlayerStateWrapper>
                    <VariantPicker hideHeader tournament={tournament} contentClassName="scrl" isEditMode={true}
                        getContent={(variant) => {
                            const [variantToUpdate, setVariantToUpdate] = useState({})

                            useEffect(() => {
                                setVariantToUpdate(variant);
                            }, [(variant || {}).id]);

                            const { currentLevel } = resolveCurrentLevel(variant);
                            if (currentLevel && currentLevel.level > 0) {
                                variant.structure.map(eachLevel => {
                                    if (eachLevel.level < currentLevel.level) {
                                        eachLevel.readOnly = true;
                                    }
                                })
                            }

                            return (
                                <>
                                    <div className="structure-title">
                                        <h2 className="title">Structure</h2>
                                    </div>
                                    <Loader actionId="updateVariant">
                                        <div className="structure">
                                            <StructureWithStartingLevelContainer
                                                variant={variantToUpdate}
                                                tournament={tournament}
                                                currentLevel={currentLevel}
                                                onChange={() => this.showUnsavedWarning()}
                                                updateVariant={(variantToUpdate) => {
                                                    setVariantToUpdate(variantToUpdate)
                                                }} />
                                        </div>
                                    </Loader>
                                    <div className="confirm-buttons">
                                        <Button className="btn-primary img-left fit outline"
                                            onClick={() => this.resetChanges()}>
                                            <FontAwesomeIcon visibility="visible" icon={faTimes} />
                                            <span>Cancel changes</span>
                                        </Button>

                                        <Button className="right fit"
                                            onClick={() => this.saveAllChanges(variantToUpdate)}>
                                            Save all changes
                                        </Button>
                                    </div>
                                </>
                            );
                        }} />
                </UpdatePlayerStateWrapper>
                {this.state.showValidationWarningModal && (
                    <Modal id="modShowValidationWarning" isOpen={this.state.showValidationWarningModal}>
                        <ModalHeader>Warning</ModalHeader>
                        <ModalBody>
                            <h3>Fix errors before save!</h3>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.closeValidationWarningModal}>
                                OK
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        );
    }
}

EditStructure.propTypes = {};

const mapStateToProps = (state) => {
    const stateTournament = state.tournaments.tournament;
    if (!state.tournaments.tournament.id) {
        return {
            tournament: undefined,
        };
    }
    const isMultiday =
        stateTournament.tournamentPhases.length > 1 ||
        (stateTournament.tournamentPhases &&
            (stateTournament.tournamentPhases[0].variants || []).length > 1);

    return {
        tournament: state.tournaments.tournamentToSave
            ? state.tournaments.tournamentToSave
            : {
                ...stateTournament,
                numOfPhases: stateTournament.tournamentPhases.length,
                isMultiday,
            },
    };
};

const mapDispatchToProps = {
    updateTournamentDetail,
    deleteUnsavedTournamentDetail,
    updateVariant,
    getTournament,
    initTournamentToSave,
    unsavedChanges,
    unsavedChangesClear
};
export default connect(mapStateToProps, mapDispatchToProps)(EditStructure);
