const initState = {
  lastMenuItemId: 'nlMttList',
};

export default function chatReducer(state = initState, action) {
  switch (action.type) {
    case 'NAVLINK': {
      return { ...state, lastMenuItemId: action.data.id };
    }

    default:
      return state;
  }
}
