import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import printJS from 'print-js/src/index';
import moment from 'moment-mini';
import kajot from '../../../image/casino/kajot.png';
import { getCasinoNameByDomain, getTournamentTitle, isMultiday } from '../../../util/tournamentUtil';
import { formatTableSeat, formatPlayerName } from '../../../util/playerUtil';
import { getNationalityDesc } from "../../../util/stringUtil";

const PrintContent = ({
  createdPlayerId, customer, createdPlayer, variant, onPrinted,
}) => {
  const [printedId, setPrintedId] = useState();
  const { tournament } = variant;

  const getTotal = () => {
    const addOn = (variant.addon && createdPlayer.addonCount) ? (((variant.addonPool || 0) * createdPlayer.addonCount) + ((variant.addonFee || 0) * createdPlayer.addonCount)) : 0;
    const buyIn = (variant.buyInPool || 0) + (variant.buyInFee || 0)
    const rebuy = (variant.reBuy && createdPlayer.rebuyCount) ? (((variant.reBuyPool || 0) * createdPlayer.rebuyCount) + ((variant.reBuyFee || 0) * createdPlayer.rebuyCount)) : 0;
    const x = buyIn + rebuy + addOn;
    return x;
  };

  const getTotalStackChips = () => {
    const rebuyChips = (createdPlayer.rebuyCount && variant.reBuy) ? ((variant.reBuyChips || 0) * createdPlayer.rebuyCount || 0) : 0
    const addOnChips = (createdPlayer.addonCount && variant.addon) ? ((variant.addonChips || 0) * createdPlayer.addonCount || 0) : 0
    const bonusChips = (variant.bonus && customer.bonus) ? (variant.bonusChips || 0) : 0
    const x = variant.buyInChips + rebuyChips + addOnChips + bonusChips;
    return x;
  };

  const formatSeatLabel = (player) => {
    if(player.stateId === 0) {
      return "Waiting list"
    }

    if(player.stateId === 8) {
      return "Seating by floorman"
    }

    return formatTableSeat(createdPlayer)
  }

  const seatLabel = formatSeatLabel(createdPlayer)
  const seatFontSize = seatLabel.length > 7 ? "25px" : "50px"

  useEffect(() => {
    if (createdPlayer.id && createdPlayerId !== printedId) {
      const addOn = (createdPlayer.addonCount && variant.addon) ? (
        `<tr>
          <td>Addon: ${variant.addonPool * createdPlayer.addonCount || ''}</td>
          <td>Fee: ${variant.addonFee * createdPlayer.addonCount || ''}</td>
        </tr>`
      ) : ""

      const reBuy = (createdPlayer.rebuyCount && variant.reBuy) ? (
        `<tr>
          <td>Rebuy: ${variant.reBuyPool * createdPlayer.rebuyCount || ''}</td>
          <td>Fee: ${variant.reBuyFee * createdPlayer.rebuyCount || ''}</td>
        </tr>`
      ) : ""

      const addChips = (createdPlayer.addonCount && variant.addon) ? (
        `<p>
          Addon chips: ${variant.addonChips ? (variant.addonChips * createdPlayer.addonCount) : ''}
        </p>`) : ""

      const rebuyChips = (createdPlayer.rebuyCount && variant.reBuy) ? (
        `<p>
            Rebuy chips: ${variant.reBuyChips ? (variant.reBuyChips * createdPlayer.rebuyCount) : ''}
        </p>`) : ""

      const bonusChips = (variant.bonus && customer.bonus) ? (
        `<p>
          Bonus chips: ${variant.bonusChips || ''}
        </p>`
      ) : ""

      const casino = getCasinoNameByDomain()
      const casinoImagePath = `/images/casino/${casino}.png`;

      const rawHTML = `
      <div style="width:250px;font-family:Arial;font-size:14px;margin:0;padding:0">
       <img src="${casinoImagePath}" style="margin-bottom:0px" width="250px" target="" />
        <h1 style="margin-top:0px; text-align:center">
          &#9825; &#9826; &#9831; &#9828;
        </h1>
        <p>
          Event: ${getTournamentTitle(tournament)}
        </p>
        ${isMultiday(tournament) ? `Flight: ${variant.name}` : ''}
  
        <table>
          <tr>
            <td>Buy in: ${variant.buyInPool || ''}</td>
            <td>Fee: ${variant.buyInFee || ''}</td>
          </tr>
         ${reBuy}
         ${addOn}
        </table>
        
        <p>
          Total: ${getTotal()}
        </p>

        <p style="margin-top:20px">
          Buy in chips: ${variant.buyInChips || ''}
        </p>
        ${rebuyChips}
        ${addChips}
        ${bonusChips}
        <p>
          Total Stack chips: ${getTotalStackChips()}
        </p>

        <p style="margin-top:20px">
          Date ${moment(variant.scheduledStartAt).format('DD.MM.YYYY [at] HH:mm')}
        </p>

        <h1 style="margin-top:10px; text-align:center">
          &#9825; &#9826; &#9831; &#9828;
        </h1>

        <p style="margin-top:10px;">
          Name: ${formatPlayerName(createdPlayer)}
        </p>
        <p>
          Country: ${getNationalityDesc(createdPlayer.nationality)}
        </p>
        <p>
        System ID: ${createdPlayer.playerId || ''}
        </p>

        <p style="margin-top:20px">
          Date ${moment(variant.scheduledStartAt).format('DD.MM.YYYY [at] HH:mm')}
        </p>

        <div style="margin-top:20px;text-align:center">
          <p>Seat</p>
          <span style="text-align:center;font-size:${seatFontSize};font-weight:bold">
            ${seatLabel}
          </span>
        </div>

      </div>
    `;

      printJS({
        type: 'raw-html',
        printable: rawHTML,
        maxWidth: '250px',
        imageStyle: 'margin-bottom:0px;',
        font: 'Arial',
        style:
          '@page { size: auto; margin: 5mm; } body { margin: 0; } p {margin: 5px} h1{margin: 0px}',
      });

      setPrintedId(createdPlayerId);
      onPrinted();
    }
  }, [createdPlayer.id, createdPlayer.printed]);

  return (
    <div>
      Printing...
    </div>
  );
};

export default PrintContent;
