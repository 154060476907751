import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';

import CustomModal from '../../shared/CustomModal';
import { formatPlayerName, formatSeatNum } from '../../../util/playerUtil';
import { updateGameTable } from '../../../actions/tablesActions';
import { history } from '../../../service/history';
import { loading, loadingDone } from '../../../actions/commonActions';
import { getVariant } from '../../../actions/tournamentActions';

const CheckChipCountsModal = ({
  isOpen, players, variant, table, toggle,
}) => {
  const dispatch = useDispatch();

  const PlayerCard = ({ player }) => (
    <Col className="col-12 py-2">
      <Row className="player-row">
        <Col sm={2} className="list-modal-table-edit-newSeats">
          <h3 style={{ marginBottom: 0 }}>{formatSeatNum(player.seatNum)}</h3>
          <div className="new">Seat</div>
        </Col>
        <Col sm={6}>{formatPlayerName(player)}</Col>
        <Col className="my-auto col-2 list-modal-table-edit-newSeats">
          <h3 style={{ marginBottom: 0 }} className="no-wrap">
            {player.stack}
          </h3>
          <div className="new">Chips</div>
        </Col>
      </Row>
    </Col>
  );

  return (
    <>
      {isOpen && (
      <CustomModal open={isOpen} closeHandler={toggle} className="check-chip-counts-modal" dialogClassName="modal-small-width" title="Check chip counts">
        <div className="players">
          {players.length === 0 && <h3>No players...</h3>}
          {players.map((player, index) => (
            <>
              <PlayerCard player={player} />
              {index !== players.length - 1 && <Row className="graySeparator" />}
            </>
          ))}
        </div>
        <Row className="confirm-btns">
          <Button
            color="primary"
            onClick={() => {
              history.push(`/tournament/${variant.tournamentId}`);
              dispatch(loading('getVariant'));
              dispatch(updateGameTable(variant.id, [{
                id: table.id,
                state: 'closed',
                additionalData: {
                  playersChipcountClose: players.map((p) => ({
                    id: p.id, seatNum: p.seatNum, firstName: p.firstName, lastName: p.lastName, stack: p.stack,
                  })),
                },
              }])).then(() => {
                dispatch(loadingDone('getVariant'));
                dispatch(getVariant(variant.id));
              });
            }}
          >
            Confirm and close table
          </Button>
        </Row>
      </CustomModal>
      )}
    </>
  );
};

export default CheckChipCountsModal;
