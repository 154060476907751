import React, { useCallback, useEffect, useState } from "react";
import Loader from "../shared/Loader";
import restClient from "../../util/restClient";
import PlayerCard from "./PlayerCard";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";

const PlayersManagement = (props) => {
  const [players, setPlayers] = useState({data: null, error: null, isLoading: true})
  const [searchText, setSearchText] = useState("")

  //https://devtrium.com/posts/async-functions-useeffect
  const fetchPlayers = useCallback(async (param) => {
    await restClient.getAsyncWrap(`/player/all?searchText=${param || ""}`, players, setPlayers)
  }, [])

  useEffect(() => {
    fetchPlayers().catch(console.error);
  }, [fetchPlayers])

  async function anonymize(player) {
    const res = await restClient.putAsync(`/player/anonymize/${player.id}`)

    setPlayers({...players, isLoading: true, error: null});
    try {
      const newPlayers = players.data.map(p => (p.id === player.id)? res.data : p);
      setPlayers({...players, data: newPlayers, isLoading: false});
    } catch(e) {
      setPlayers({...players, data: null, isLoading: false, error: e});
    }
  }

  let timeout;
  function txtSearchPlayerChanged(e) {
    const newSearchText = e.target.value
    setSearchText(newSearchText)

    if(newSearchText.length >= 3) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(async () => {
        await fetchPlayers(newSearchText);
      }, 600);
    }

  }

  let Content;
  if(players.isLoading) {
    Content = () => <div><Loader isLoading active className="full-height"></Loader></div>
  }
  if(players.error) {
    Content = () =>  <span>{players.error}</span>
  }
  if (players.data) {
    Content = () =>
      <div className="players-rows">
        {players.data.map(p => <PlayerCard player={p} anonymize={anonymize}/>)}
      </div>;
  }

  return <div className="players-management">
    <div className="header">
      <Row><h3>Players database</h3></Row>
      <Row><div className="line"/></Row>
      <Row>
        <Col>
          <h4>Players: {players.data ? players.data.length : ""}</h4>
        </Col>
        <Col>
        <InputGroup className="input-right-icon-control-black">
          <FormControl
            id="filter"
            placeholder="Search player"
            name="filter"
            className="input-part"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={txtSearchPlayerChanged}
            value={searchText}
          />
          <InputGroup.Text className="icon-part" id="basic-addon1">
            <FontAwesomeIcon icon={faSearch}/>
          </InputGroup.Text>
        </InputGroup>
        </Col>

      </Row>
      <div className="line"/>
    </div>

    <Content/>
  </div>


};

export default PlayersManagement;
