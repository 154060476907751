import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import StructureLevel from "./StructureLevel";

const TableHeader = () => (
  <Row className="structure-table-header p-3">
    <Col sm={1} key="level">
      Level
    </Col>
    <Col sm={2} key="smallBlind">
      Small blind
    </Col>
    <Col sm={2} key="bigBlind">
      Big blind
    </Col>
    <Col sm={2} key="ante">
      Ante
    </Col>
    <Col sm={2} key="levelLength">
      Level length
    </Col>
    <Col sm={2} key="addLevel" />
  </Row>
);

const Structure = ({
  selectFromDropdown,
  template,
  id,
  title,
  structures,
  validationErrors = [],
  handleChange,
  addLevel,
  deleteLevel,
  deleteBreak,
  saveBreak,
  registrationCloseAtLevel,
  isTemplateMode,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      {!isTemplateMode && (
        <div className="multiselect-dropdown2" onClick={() => setOpened(!opened)}>
          <Row>
            <span className="header">Use existing template</span>
            <Button className="scroll">
              {opened ? (
                <FontAwesomeIcon icon={faAngleDown} />
              ) : (
                <FontAwesomeIcon icon={faAngleUp} />
              )}
            </Button>
          </Row>
          {opened &&
            template.map((item1, index) => {
              return (
                <Row
                  className="sub-item"
                  onClick={() => {
                    setOpened(!opened);
                    selectFromDropdown(item1);
                  }}
                >
                  {item1.name}
                </Row>
              );
            })}
        </div>
      )}

      {structures && <Container className="structure-table" key={id}>
        <TableHeader />
        {structures.map((structure, index) => (
          <StructureLevel
            key={structure.id || structure.uniqueId}
            structure={structure}
            validationErrors={validationErrors[index]}
            index={index}
            handleChange={handleChange}
            addLevel={addLevel}
            deleteDisabled={index === 0}
            deleteLevel={deleteLevel}
            deleteBreak={deleteBreak}
            saveBreak={saveBreak}
          />
        ))}
        {!isTemplateMode && (
          <small
            className={registrationCloseAtLevel >= structures.length ? "info" : "error"}
          >{`Registration close after level ${registrationCloseAtLevel}`}</small>
        )}
      </Container>}
    </>
  );
};

Structure.propTypes = {
  handleChange: PropTypes.func.isRequired,
  addLevel: PropTypes.func.isRequired,
  deleteLevel: PropTypes.func.isRequired,
  deleteBreak: PropTypes.func.isRequired,
  saveBreak: PropTypes.func.isRequired,
};

export default Structure;
