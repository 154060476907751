import PropTypes from 'prop-types';

export const TournamentVariant = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  registrationStartAt: PropTypes.instanceOf(Date),
});

export const Table = PropTypes.shape({
  id: PropTypes.number,
  roomId: PropTypes.number,
  active: PropTypes.bool,
  xCoordinate: PropTypes.number,
  yCoordinate: PropTypes.number,
  tableName: PropTypes.string,
  tournamentVariant: PropTypes.arrayOf(TournamentVariant),
});

export const Room = PropTypes.shape({
  id: PropTypes.number,
  roomName: PropTypes.string,
  tables: PropTypes.arrayOf(Table),
});
