import React from 'react';
import { Col, Row } from 'react-bootstrap';

const StructureLevelMobile = ({
    structure
}) => {
    const {
        level, smallBlind, bigBlind, ante, durationInMin, breakInMin, colorUp
    } = structure;

    const renderBreakInfo =
        <Row className="mb-2">
            <Col className="break-in">
                BREAK
                {' '}
                {breakInMin}
                {' '}
                min
                {colorUp ? ' - color up' : ''}
            </Col>
        </Row>


    return (
        <>
            <Row className="px-3 pb-3">
                <Col>{level}</Col>
                <Col>{smallBlind}</Col>
                <Col>{bigBlind}</Col>
                <Col>{ante}</Col>
                <Col>{durationInMin}</Col>
            </Row>
            {breakInMin ? renderBreakInfo : ""}
        </>
    )
}

export default StructureLevelMobile;