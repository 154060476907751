import React from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment-mini";
import { getLogName } from "../../../util/tournamentUtil";

const LogCard = ({ log }) => {
  const getDescription = (logg) => {
    const actionData = logg.actionData || {};
    if (logg.logType === "player_reseat") {
      return `Seat ${actionData.tableName || ""}/${actionData.seatNum || ""}`;
    }

    if (logg.logType === "swap_seats") {
      return `From seat ${actionData.fromTableName || ""}/${actionData.fromSeatNum || ""} to ${
        actionData.toTableName || ""
      }/${actionData.toSeatNum || ""}`;
    }

    if (logg.logType === "extra_time") {
      if (actionData.extraTime) {
        const minutes = parseInt(actionData.extraTime);
        return `${minutes >= 0 ? "Added" : "Removed"} ${Math.abs(minutes)} minute`;
      }
    }

    if (logg.logType === "level_changed") {
      if (actionData.changeDirection) {
        const direction = actionData.changeDirection.toLowerCase();
        return direction.replace(direction[0], direction[0].toUpperCase());
      }
    }

    if (logg.logType === "price_pool_changed") {
      return `Prize pool ${actionData.adjustedPool}%, Fee ${actionData.fixedTotalFee}%, Cut ${actionData.cutted}%`;
    }

    if (logg.logType === "seat_open") {
      return `Seat ${actionData.tableName || ""}/${
        actionData.seatNum || ""
      }, Payout: ${!actionData.finish_time_confirmed}`;
    }

    return "";
  };

  return (
    <>
      <div className={"d-none d-md-block"}>
        <Row className="log-card no-wrap no-gutters" key={log.id}>
          <div className="colX">
            <span>{`${moment(log.performedAt).format("DD.MM.YYYY HH:mm ")}`}</span>
          </div>
          <div className="colX remXL">
            <span>{log.performedByIdentifier}</span>
          </div>
          <div className="colX">
            <span>{getLogName(log.logType)}</span>
          </div>
          <div className="colX">
            <span>{`${log.playerFirstName || ""} ${log.playerLastName || ""}`}</span>
          </div>
          <div className="colLast">
            <span>{getDescription(log)}</span>
          </div>
        </Row>
      </div>
      <div className={"d-block d-md-none"}>
        <Row xs={12} key={log.id} style={{ borderBottom: "1px solid #343642" }}>
          <Col xs={6}>{log.performedByIdentifier}</Col>
          <Col xs={6}>{getDescription(log)}</Col>
        </Row>
      </div>
    </>
  );
};

export default LogCard;
