import React, {useCallback, useEffect, useState} from "react";
import Loader from "../../shared/Loader";
import restClient from "../../../util/restClient";
import CasinoSettingsItem from "./CasinoSettingsItem";


const CasinoSettings = () => {
    const [settings, setSettings] = useState({data: null, error: null, isLoading: true});

    const floormanSettings = ['tournamentDefaultCurrency']
    const cashierSettings = (!settings.isLoading && settings.data) ? Object.keys(settings.data).filter(key => !floormanSettings.includes(key)) : []

    const fetchSettings = useCallback(async (param) => {
        await restClient.getAsyncWrap(`/config`, settings, setSettings)
    }, [])

    useEffect(() => {
        try {
            fetchSettings()
        } catch (e) {
            console.error(e)
        }
    }, [fetchSettings])

   return <div className="casino-settings">
        <h3>Casino settings</h3>
        {settings.isLoading &&
            <div><Loader isLoading active className="full-height"></Loader></div>
        }
        {settings.error &&
            <span>{settings.error}</span>
        }
        {(!settings.isLoading && settings.data) &&

            <div className="casino-content">
                <>
                    <h2>Cashier:</h2>
                    {cashierSettings.map((key) => {
                        return <CasinoSettingsItem key={key} item={{key, ...settings.data[key]}} />
                    })}
                    <h2>Floorman or director</h2>
                    {floormanSettings.map((key) => {
                        return <CasinoSettingsItem key={key} item={{key, ...settings.data[key]}} />
                    })}
                </>


            </div>
        }
    </div>

}

export default CasinoSettings;
