import React from "react";

const TileLevel = ({ curLevel }) => {
    return (
        <div className="item">
            <div className="title">Level</div>
            <div className="text">{curLevel.level || 0}</div>
        </div>
    )
}

export default TileLevel;