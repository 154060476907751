import React from 'react';
import Switch from 'react-switch';

export default function ToggleSwitch(props) {
  return (
    <Switch
      className="toggleSwitch"
      onChange={(checked) => props.switchValue(props.field, checked)}
      checked={props.isSwitchedOn}
      checkedIcon={false}
      uncheckedIcon={false}
      offColor="#2f2f2f"
      onColor="#5b38b9"
      height={18}
      width={51}
      handleDiameter={25}
    />
  );
}
