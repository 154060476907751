import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import SwapIcon from '../../../image/icon-swap.svg';
import SwapSeatsModal from '../../tournament/floor/SwapSeatsModal';
import ReseatPlayerTablesModal from '../../tournament/floor/ReseatPlayerTablesModal';
import { swapPlayersSeats, reseatPlayer } from '../../../actions/playerActions';

const SwapSeatsButton = (props) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const player = props.player || {};

  const toggleModal = () => {
    setOpenModal(!openModal);
    if (props.toggleParent) {
      props.toggleParent();
    }
  };

  function swapSeats(inputPlayer) {
    if (player.playerInTournamentPhaseVariantId) {
      dispatch(swapPlayersSeats(inputPlayer, player));
    } else {
      dispatch(reseatPlayer(inputPlayer.playerInTournamentPhaseVariantId, player.tableId, player.seatNum));
    }
    if (props.onAction) {
      props.onAction();
    }
  }

  return (
    <>
      <div className="action">
        <Button color="primary" className="action-btn" onClick={toggleModal}>
          <img src={SwapIcon} className="action-icon" alt="swap" />
        </Button>
        <a>Swap seats</a>
      </div>
      {/* {openModal && <SwapSeatsModal isOpen={openModal} toggle={toggleModal} action="SwapSeats" player={player} players={props.players} onAction={props.onAction} />} */}
      {openModal && <ReseatPlayerTablesModal isOpen={openModal} toggle={toggleModal} variant={props.variant} selectedPlayer={player} isSwap setNewSeat={swapSeats} onAction={swapSeats} />}
    </>
  );
};

export default SwapSeatsButton;
