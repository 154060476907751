import { v4 as uuidv4 } from 'uuid';
import actionTypes from '../actiontypes/tournamentActionTypes';
import { combinedRegisterLoaderAndShowError } from './commonActions';
import restClient from '../util/restClient';
import { history } from '../service/history';
import { getTournamentPayouts } from './tournamentPayoutActions';
import { getDefaultVariantStorageId } from '../util/tournamentUtil';

const addtTournamentsRequest = () => ({ type: actionTypes.ADD_TOURNAMENT_REQUEST });
const addTournamentsResponse = (data) => ({ type: actionTypes.ADD_TOURNAMENT_RESPONSE, data });

const updateTournamentsRequest = () => ({ type: actionTypes.UPDATE_TOURNAMENT_REQUEST });
const updateTournamentsResponse = (data) => ({ type: actionTypes.UPDATE_TOURNAMENT_RESPONSE, data });

const getTournamentsRequest = () => ({ type: actionTypes.TOURNAMENTS_GET_REQUEST });
const getTournamentsResponse = (data) => ({ type: actionTypes.TOURNAMENTS_GET_RESPONSE, data });
const notifyTournamentUpdated = (data) => ({ type: actionTypes.TOURNAMENT_UPDATED, data });
const notifyNewTournamentUpdated = (data) => ({ type: actionTypes.NEW_TOURNAMENT_UPDATED, data });

const getFinishedTournamentsRequest = () => ({ type: actionTypes.FINISHED_TOURNAMENTS_GET_REQUEST });
const getFinishedTournamentsResponse = (data) => ({ type: actionTypes.FINISHED_TOURNAMENTS_GET_RESPONSE, data });
const cleanFinishedTournamentsRequest = () => ({ type: actionTypes.CLEAN_FINISHED_TOURNAMENTS });

const getTournamentRequest = () => ({ type: actionTypes.TOURNAMENT_GET_REQUEST });
const getTournamentResponse = (data) => ({ type: actionTypes.TOURNAMENT_GET_RESPONSE, data });

const getTournamentStatisticsRequest = () => ({ type: actionTypes.TOURNAMENT_STATISTICS_GET_REQUEST });
const getTournamentStatisticsResponse = (data) => ({ type: actionTypes.TOURNAMENT_STATISTICS_GET_RESPONSE, data });
export const getTournamentStatisticsClean = () => ({ type: actionTypes.TOURNAMENT_STATISTICS_CLEAN });

const getVariantStatisticsRequest = (storageId) => ({ type: actionTypes.VARIANT_STATISTICS_GET_REQUEST, storageId });
const getVariantStatisticsResponse = (data, storageId) => ({
  type: actionTypes.VARIANT_STATISTICS_GET_RESPONSE,
  data,
  storageId,
});
export const getVariantStatisticsClean = (storageId) => ({ type: actionTypes.VARIANT_STATISTICS_CLEAN, storageId });

const changeVariantStateRequest = () => ({ type: actionTypes.CHANGE_VARIANT_STATE_REQUEST });
const changeVariantStateResponse = (data, storageId) => ({
  type: actionTypes.CHANGE_VARIANT_STATE_RESPONSE,
  data,
  storageId,
});

const updateVariantRequest = () => ({ type: actionTypes.VARIANT_POST_REQUEST });
const updateVariantResponse = (data) => ({ type: actionTypes.VARIANT_POST_RESPONSE, data });

const getVariantRequest = (storageId) => ({ storageId, type: actionTypes.VARIANT_GET_REQUEST });
const getVariantResponse = (data, storageId) => ({ storageId, type: actionTypes.VARIANT_GET_RESPONSE, data });
const clearVariantRequest = (storageId) => ({ storageId, type: actionTypes.CLEAR_VARIANT });

const notifyVariantUpdated = (data) => ({ type: actionTypes.VARIANT_UPDATED, data });

const getFeesRequest = () => ({ type: actionTypes.GET_FEES_REQUEST });
const getFeesResponse = (data) => ({ type: actionTypes.GET_FEES_RESPONSE, data });

const getTipsRequest = () => ({ type: actionTypes.GET_TIPS_REQUEST });
const getTipsResponse = (data) => ({ type: actionTypes.GET_TIPS_RESPONSE, data });

const addTipRequest = () => ({ type: actionTypes.ADD_TIP_REQUEST });
const addTipResponse = (data) => ({ type: actionTypes.ADD_TIP_RESPONSE, data });

const getClockSettingsRequest = () => ({ type: actionTypes.GET_CLOCK_SETTINGS_REQUEST });
const getClockSettingsResponse = (data) => ({ type: actionTypes.GET_CLOCK_SETTINGS_RESPONSE, data });
const getClockSettingsClean = () => ({ type: actionTypes.GET_CLOCK_SETTINGS_CLEAN });

const updateTournamentDetailRequest = (data) => ({ type: actionTypes.UPDATE_TOURNAMENT_DETAIL, data });
const updateVariantDetailRequest = (data) => ({ type: actionTypes.UPDATE_VARIANT, data });

const deleteUnsavedTournamentDetailRequest = () => ({ type: actionTypes.DELETE_UNSAVED_TOURNAMENT_DETAIL });

const cleanTournamentDetailRequest = (storageId) => ({ storageId, type: actionTypes.CLEAN_TOURNAMENT_DETAIL });

const initTournamentToSaveRequest = (data) => ({ type: actionTypes.INIT_TOURNAMENT_TO_SAVE_REQUEST, data });

const getLogsRequest = () => ({ type: actionTypes.GET_LOGS_REQUEST });
const getLogsResponse = (data) => ({ type: actionTypes.GET_LOGS_RESPONSE, data });

export const getReseatHistory = (tournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    return restClient.get(`/newday/tournament/${tournamentId}`).then((response) => {
      return Promise.resolve(response);
    });
  },
  'getReseatHistory',
  'Can not resolve list of tournaments',
);

export const getReseatHistoryDetail = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    return restClient.get(`/newday/detail/${id}`).then((response) => {
      return Promise.resolve(response);
    });
  },
  'getReseatHistoryDetail',
  'Can not resolve list of tournaments',
);

export const disconnectReseat = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    return restClient.post(`/newday/cancel/${id}`).then((response) => {
      
      return Promise.resolve(response);
    });
  },
  'disconnectReseat',
  'Can not disconnect reseat',
);

export const updateVariant = (variant) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updateVariantRequest(variant));
    return restClient.post('/tournament/variant', variant).then((response) => {
      dispatch(updateVariantResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'updateVariant',
  'Can not save variant',
);

export const changeVariantState = (id, state, storageId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(changeVariantStateRequest());
    return restClient.post(`/tournament/variant/${id}/state/${state}`, {}).then((response) => {
      dispatch(changeVariantStateResponse(response.data, storageId));
      return Promise.resolve(response);
    });
  },
  'changeVariantState',
  'Can not change variant state',
);

export const addExtraTime = (id, extraTime) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updateVariantRequest());
    return restClient.post('/tournament/variant/extratime', { id, extraTime }).then((response) => {
      dispatch(updateVariantResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'addExtraTime',
  'Can not add extra time to flight',
);
export const addExtraTimeForBreak = (id, extraTime, levelId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updateVariantRequest());
    return restClient.post('/tournament/variant/extratimeforbreak', { id, extraTime, levelId }).then((response) => {
      dispatch(updateVariantResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'addExtraTimeForBreak',
  'Can not add extra time to break',
);

export const changeLevel = (id, direction) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updateVariantRequest());
    return restClient.post('/tournament/variant/level', { id, direction }).then((response) => {
      dispatch(updateVariantResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'changeLevel',
  'Can not change level',
);

export const updateTournamentDetailFromTemplate = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => restClient.get(`/template/detail/${id}`)
    .then((response) => {
      const template = response.data;
      if (template.type === 'tournament_detail') {
        const tournament = JSON.parse(template.content);
        (tournament.tournamentPhases || []).forEach((eachPhase) => {
          (eachPhase.variants || []).forEach((eachVariant) => {
            eachVariant._id = uuidv4();
            delete eachVariant.id;
          });
        });
        tournament._id = uuidv4();
        dispatch(updateTournamentDetailRequest(tournament));
        return Promise.resolve();
      }
      return Promise.reject('Can not load template - not compatible template type');
    }),
  'updateTournamentDetailFromTemplate',
  'Can not load template',
);

export const updateTournamentDetail = (tournament) => (dispatch) => {
  dispatch(updateTournamentDetailRequest(tournament));
};

export const deleteUnsavedTournamentDetail = () => (dispatch) => {
  dispatch(deleteUnsavedTournamentDetailRequest());
};

export const cleanTournamentDetail = (storageId) => (dispatch) => {
  dispatch(cleanTournamentDetailRequest(storageId));
};

export const updateVariantDetail = (variant) => (dispatch) => {
  dispatch(updateVariantDetailRequest(variant));
};

export const notifyVariantUpdate = (variant) => (dispatch) => {
  dispatch(notifyVariantUpdated(variant));
};

export const notifyTournamentUpdate = (tournament) => (dispatch) => {
  dispatch(notifyTournamentUpdated(tournament));
};

export const notifyNewTournamentUpdate = (tournament) => (dispatch) => {
  dispatch(notifyNewTournamentUpdated(tournament));
};

export const getTournamentsByState = (state = 'active') => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getTournamentsRequest([]));
    return restClient.get(`/tournament/state/${state}`).then((response) => {
      dispatch(getTournamentsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTournaments',
  'Can not resolve list of tournaments',
);

export const getTournaments = (active) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getTournamentsRequest([]));
    return restClient.get(`/tournament/state/${active ? 'active' : 'finished'}`).then((response) => {
      dispatch(getTournamentsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTournaments',
  'Can not resolve list of tournaments',
);

/*
 * Response object of getTournaments and getVariants is the same - therefor share reducer and action type
 * */
export const getVariants = () => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getTournamentsRequest([]));
    return restClient.get('/tournament/variant/state/active').then((response) => {
      dispatch(getTournamentsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getVariants',
  'Can not resolve list of variants',
);

export const getFinishedVariants = () => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getFinishedTournamentsRequest([]));
    return restClient.get('/tournament/variant/state/finished').then((response) => {
      dispatch(getFinishedTournamentsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getFinishedVariants',
  'Can not resolve list of finished variants',
);

export const cleanFinishedTournaments = () => (dispatch) => {
  dispatch(cleanFinishedTournamentsRequest());
};

export const cleanTournament = () => (dispatch) => {
  dispatch(cleanTournamentDetailRequest());
};

export const getTournament = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getTournamentRequest({}));
    return restClient.get(`/tournament/${id}`).then((response) => {
      dispatch(getTournamentResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTournament',
  'Can not resolve tournament',
);
export const getTournamentStatistics = (tournamentId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getTournamentStatisticsRequest({}));
    return restClient.get(`/tournament/stats/${tournamentId}`).then((response) => {
      dispatch(getTournamentStatisticsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTournamentStatistics',
  'Can not resolve tournament statistics',
);

export const getVariantStatistics = (id, storageId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    // loadWrapper(dispatch, () => {
    dispatch(getVariantStatisticsRequest(storageId));
    return restClient.get(`/tournament/stats/variant/${id}`).then((response) => {
      response.data.id = id;
      dispatch(getVariantStatisticsResponse(response.data, storageId));
      return Promise.resolve(response);
    });
    // });
  },
  'getVariantStatistics',
  'Can not resolve variant stats',
);

export const addTournament = (tournament) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(addtTournamentsRequest({}));
    return restClient.put('/tournament', tournament).then((response) => {
      dispatch(addTournamentsResponse(response.data));
      history.push('/');
      return Promise.resolve(response);
    });
  },
  'addTournament',
  'Can not create tournament',
);

export const updateTournament = (tournament) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updateTournamentsRequest({}));
    return restClient
      .post('/tournament', tournament)
      .then((response) => {
        dispatch(updateTournamentsResponse(response.data));
        return Promise.resolve(response);
      })
      .then(() => getTournamentPayouts(tournament.id)(dispatch));
  },
  'updateTournament',
  'Can not update tournament',
);

export const closeVariant = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(updateVariantRequest());
    return restClient.post(`/tournament/variant/${id}/state/close`, {}).then((response) => {
      dispatch(notifyVariantUpdated(response.data));
      return Promise.resolve(response);
    });
  },
  'closeVariant',
  'Can not close tournament',
);

export const clearVariant = (storageId) => (dispatch) => {
  dispatch(clearVariantRequest(storageId));
};

export const getVariant = (id, storageId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    const storageIdChecked = storageId || getDefaultVariantStorageId(id);
    dispatch(getVariantRequest(storageIdChecked));
    return restClient.get(`/tournament/variant/${id}`).then((response) => {
      dispatch(getVariantResponse(response.data, storageIdChecked));
      return Promise.resolve(response);
    });
  },
  'getVariant',
  'Can not resolve tournament',
);

export const getFees = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getFeesRequest({}));
    return restClient.get(`/tournament/fees/${id}`).then((response) => {
      dispatch(getFeesResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getFees',
  'Can not resolve fees',
);

export const getTips = (id) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getTipsRequest({}));
    return restClient.get(`/tips/${id}`).then((response) => {
      dispatch(getTipsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getTips',
  'Can not resolve tips',
);

export const addTips = (tip) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getTipsRequest({}));
    return restClient.put('/tips', tip).then((response) => {
      dispatch(addTipResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'addTips',
  'Can not resolve tips',
);

export const getClockSettings = (variantId) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getClockSettingsRequest({}));
    return restClient.get(`/clock/${variantId}`).then((response) => {
      dispatch(getClockSettingsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getClockSettings',
  'Can not resolve getClockSettings',
);

export const cleanClockSetting = () => (dispatch) => {
    dispatch(getClockSettingsClean({}))
}

export const updateClockSettings = (variantId, clockSettingsId, seating) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getClockSettingsRequest({}));
    return restClient.post(`/clock/${variantId}`, { id: clockSettingsId, seating }).then((response) => {
      dispatch(getClockSettingsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'updateClockSettings',
  'Can not update ClockSettings',
);

export const getVariantLogs = (filter) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getLogsRequest({}));
    return restClient.get(`/tournament/actionLog?filter=${filter}`).then((response) => {
      dispatch(getLogsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getVariantLogs',
  'Can not resolve variant logs',
);

export const clearVariantLogs = () => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch({ type: actionTypes.CLEAR_LOGS });
    return Promise.resolve();
  },
  'clearVariantLogs',
  'Can not clear variant logs',
);

export const getVariantLogsFiltered = (id, user) => (dispatch) => combinedRegisterLoaderAndShowError(
  dispatch,
  () => {
    dispatch(getLogsRequest({}));
    return restClient.get(`/tournament/actionLog/${id}?performedBy=${user}`).then((response) => {
      dispatch(getLogsResponse(response.data));
      return Promise.resolve(response);
    });
  },
  'getVariantLogsFiltered',
  'Can not resolve variant logs',
);

export const initTournamentToSave = (tournament) => (dispatch) => {
  dispatch(initTournamentToSaveRequest(tournament));
};
