import React, { useEffect, useState } from "react";
import { Button, Col, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import CustomModal from "../../shared/CustomModal";
import restClient from "../../../util/restClient";
import DispPropertyModal from "../../modals/DispPropertyModal";

const ConfirmationModal = ({ tableSize, handler }) => {
    return <DispPropertyModal
        toggle={true}
        label={`Extra seat was added as the last seat at table, i.e seat #${tableSize}`}
        open
        value={null}
        buttonText="Close"
        onButtonClick={handler}
    />
}

const ModalContent = (props) => {
    const tables = props.tables;
    const [allSeatsMode, setAllSeatsMode] = useState(true);
    const [selectedTables, setSelectedTables] = useState([]);
    const [confirmationModalProps, setConfirmationModalProps] = useState({ open: false, maxTableSize: null })

    return <>
        <Row>
            {confirmationModalProps.open && <ConfirmationModal tableSize={confirmationModalProps.maxTableSize} handler={props.toggle} />}
            <Col>
                <ToggleButtonGroup
                    className="custom-toggle mt-1"
                    name="value"
                    type="radio"
                    value={allSeatsMode}
                >
                    <ToggleButton type="checkbox" active={allSeatsMode} onClick={() => {
                        setAllSeatsMode(!allSeatsMode)
                    }} className="disabled-dark">
                        All tables
                    </ToggleButton>
                    <ToggleButton type="checkbox" active={!allSeatsMode} onClick={() => {
                        setAllSeatsMode(!allSeatsMode)
                    }} className="disabled-dark">
                        Select tables
                    </ToggleButton>
                </ToggleButtonGroup>
            </Col>
        </Row>
        {!allSeatsMode && <Row>
            <Row>
                {tables.map(table => {
                    const isAlreadySelectedIndex = selectedTables.findIndex(each => {
                        return each.id === table.id
                    });
                    const styles = (isAlreadySelectedIndex >= 0 ? "darkGreen-table" : "gray-table") + " poker-table"
                    return <Col xs={2}>
                        <input
                            type="button"
                            className={styles}
                            value={table.tableName}
                            onClick={() => {
                                if (isAlreadySelectedIndex >= 0) {
                                    selectedTables.splice(isAlreadySelectedIndex, 1);
                                    setSelectedTables([...selectedTables]);
                                } else {
                                    selectedTables.push({ ...table });
                                    setSelectedTables([...selectedTables]);
                                }
                            }
                            } />
                    </Col>
                })}
            </Row>
        </Row>}
        <Row>
            <Col>
                <Button style={{ width: '100%' }} onClick={() => {
                    const tablesToUpdate = (allSeatsMode ? tables : selectedTables).map(table => table.id);
                    restClient.postAsync(`/tournament/addSeat`, tablesToUpdate)
                        .then((res) => {
                            setConfirmationModalProps({ open: true, maxTableSize: res.maxTableSize })
                        })
                }}>Confirm</Button>
            </Col>
        </Row>
    </>
}

const AddSeatModal = (props) => {
    return (
        <>
            <CustomModal
                title={`Add seat to:`}
                open={props.isOpen}
                closeHandler={props.toggle}
                className="seat-open-modal"
                dialogClassName="modal-width"
            >
                <ModalContent tables={props.tables} variantId={props.variantId} toggle={props.toggle} />
            </CustomModal>
        </>
    );
};

export default AddSeatModal;
