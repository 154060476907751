import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  getFees,
} from "../../../actions/tournamentActions";
import socket from "../../../service/EvSocket";
import { displayMoney, displayNumber } from "../../../util/stringUtil";
import Loader from "../../shared/Loader";
import CustomModal from "../../shared/CustomModal";
import EditPrizePoolModal from "./EditPrizePoolModal";

const PrizePoolModal = (props) => {
  const dispatch = useDispatch();
  const statistics = props.statistics;
  const remnant = useSelector((state) => state.tournamentPayouts.remnant);
  const fees = useSelector((state) => state.tournaments.fees);

  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [
    numTopPlayersRedistributingRemnantPool,
    setNumTopPlayersRedistributingRemnantPool,
  ] = useState();

  const tournament = props.tournament || {};
  const { id } = tournament;
  useEffect(() => {
    if (id) {
      dispatch(getFees(id));
    }

    socket.on("playersOnTableChange", "PrizePoolModal", () => {
      if (id) {
        dispatch(getFees(id));
      }
    });
    return () => {
      socket.removeHandler("playersPositionChanged", "PrizePoolModal");
    };
  }, [id]);

  const saveNumTopPlayersRedistributingRemnantPool = (
    id,
    numTopPlayersRedistributingRemnantPool
  ) => {
    props.onSaveNumTopPlayersRedistributingRemnantPool(id, numTopPlayersRedistributingRemnantPool);
  };

  const Overlay = tournament.guaranteedPool - statistics.moneyPool;

  const showAddon = () => {
    if (tournament.tournamentPhases === undefined) return;
    var addon = tournament.tournamentPhases.find((phase) =>
      phase.variants.find((variant) => variant.addon === 1)
    );
    return addon ? true : false;
  };

  const showRebuy = () => {
    if (tournament.tournamentPhases === undefined) return;
    var rebuy = tournament.tournamentPhases.find((phase) =>
      phase.variants.find((variant) => variant.reBuy === 1)
    );
    return rebuy ? true : false;
  };

  let poolBuyin = 0;
  let poolReentry = 0;
  let poolRebuy = 0;
  let poolAddon = 0;
  let feeBuyin = 0;
  let feeReentry = 0;
  let feeRebuy = 0;
  let feeAddon = 0;

  fees.forEach((eachFee) => {
    switch (eachFee.feeType) {
      case "buyin":
        poolBuyin += eachFee.poolSum;
        feeBuyin += eachFee.feeSum;
        break;
      case "reentry":
        poolReentry += eachFee.poolSum;
        feeReentry += eachFee.feeSum;
        break;
      case "rebuy":
        poolRebuy += eachFee.poolSum;
        feeRebuy += eachFee.feeSum;
        break;
      case "addon":
        poolAddon += eachFee.poolSum;
        feeAddon += eachFee.feeSum;
        break;
      case "cashback":
        break;
      default:
        throw new Error(`Unknown payment type ${eachFee.feeType}`);
    }
  });

  const getUnallocated = () => {
    const tmpPrizePool = tournament.adjustedPool === null ? statistics.prizePool : tournament.adjustedPool;
    return tmpPrizePool + statistics.collectedFee + tournament.cutted - statistics.moneyPool;
  }

  return (
    showAddon(),
    (
      <>
        {props.isOpen && (
          <>
            <CustomModal
              open={props.isOpen}
              closeHandler={props.toggle}
              className="prize-pool"
              dialogClassName={props.isMobile ? "modal-small-width-mobile" : "modal-small-width"}
            >
              <Loader>
                <Row>
                  <Col className="money-pool-title" xs={12}>
                    Collected money pool
                  </Col>
                  <Col xs={5}>Buy ins</Col>
                  <Col xs={2}>
                    <b className="float-right">{`${displayNumber(statistics.buyInCount, 0)}`}</b>
                  </Col>
                  <Col xs={1}>=</Col>
                  <Col className="value" xs={4}>
                    <b>{`${displayMoney(poolBuyin, tournament.currency)}`}</b>
                  </Col>

                  <Col xs={5}>Rentries</Col>
                  <Col xs={2}>
                    <b className="float-right">{`${displayNumber(statistics.reentryCount, 0)}`}</b>
                  </Col>
                  <Col xs={1}>=</Col>
                  <Col className="value" xs={4}>
                    <b>{`${displayMoney(poolReentry, tournament.currency)}`}</b>
                  </Col>

                  <Col xs={5}>Unique entries</Col>
                  <Col xs={2}>
                    <b className="float-right">{`${displayNumber(statistics.buyInCount)}`}</b>
                  </Col>
                  <Col xs={5}></Col>
                  <Col xs={5}>Bonus chips</Col>
                  <Col xs={2}>
                    <b className="float-right">{`${displayNumber(statistics.bonusCount)}`}</b>
                  </Col>
                  <Col xs={5}></Col>
                  {showRebuy() && (
                    <>
                      <Col xs={5}>Rebuys</Col>
                      <Col xs={2}>
                        <b className="float-right">{`${displayNumber(statistics.rebuyCount)}`}</b>
                      </Col>
                      <Col xs={1}>=</Col>
                      <Col className="value" xs={4}>
                        <b>{`${displayMoney(poolRebuy, tournament.currency)}`}</b>
                      </Col>
                    </>
                  )}

                  {showAddon() && (
                    <>
                      <Col xs={5}>Add-ons</Col>
                      <Col xs={2}>
                        <b className="float-right">{`${displayNumber(statistics.addonCount)}`}</b>
                      </Col>
                      <Col xs={1}>=</Col>
                      <Col className="value" xs={4}>
                        <b>{`${displayMoney(poolAddon, tournament.currency)}`}</b>
                      </Col>{" "}
                    </>
                  )}
                  <Col xs={7}>Fee collected</Col>
                  <Col xs={1}>=</Col>
                  <Col className="value" xs={4}>
                    <b>{`${displayMoney(statistics.collectedFee, tournament.currency)}`}</b>
                  </Col>

                  <Col className="value" xs={7}>
                    Total collected
                  </Col>
                  <Col className="value" xs={1}>
                    =
                  </Col>
                  <Col className="value" xs={4}>
                    <b>{`${displayMoney(statistics.moneyPool, tournament.currency)}`}</b>
                  </Col>

                  {tournament.guaranteedPool > 0 && (
                    <>
                      <Col xs={7}>Guaranteed prize pool</Col>
                      <Col xs={1}>=</Col>
                      <Col className="value" xs={4}>
                        <b>{`${displayMoney(tournament.guaranteedPool, tournament.currency)}`}</b>
                      </Col>
                      {Overlay > 0 && (
                        <>
                          <Col xs={7}>Overlay</Col>
                          <Col xs={1}>=</Col>
                          <Col className="value" xs={4}>
                            <b>{`${displayMoney(Overlay, tournament.currency)}`}</b>
                          </Col>
                        </>
                      )}
                    </>
                  )}

                  <div className="graySeparator m-2" />

                  <Col className="money-pool-title" xs={12}>
                    Prize pool
                  </Col>

                  <Col xs={7}>Prize pool</Col>
                  <Col xs={1}>=</Col>
                  <Col className="value" xs={4}>
                    <b>{`${displayMoney(
                      tournament.adjustedPool === null
                        ? statistics.prizePool
                        : tournament.adjustedPool,
                      tournament.currency
                    )}`}</b>
                  </Col>
                  <Col xs={7}>Fee</Col>
                  <Col xs={1}>=</Col>
                  <Col className="value" xs={4}>
                    <b>{`${displayMoney(
                      tournament.fixedTotalFee === null
                        ? statistics.collectedFee
                        : tournament.fixedTotalFee,
                      tournament.currency
                    )}`}</b>
                  </Col>
                  <Col xs={7}>Cut</Col>
                  <Col xs={1}>=</Col>
                  <Col className="value" xs={4}>
                    <b>{`${displayMoney(tournament.cutted, tournament.currency)}`}</b>
                  </Col>

                  <Col xs={7}>Unallocated</Col>
                  <Col xs={1}>=</Col>
                  <Col className="value" xs={4}>
                    <b>{`${displayMoney(getUnallocated(), tournament.currency)}`}</b>
                  </Col>

                  {!props.isMobile && <Col xs={12} className="text-center">
                    <Button
                      className="w-50"
                      color="primary"
                      onClick={() => {
                        setChangeModalOpen(true);
                      }}
                    >
                      Change
                    </Button>
                  </Col>}
                </Row>
              </Loader>
            </CustomModal>
            {changeModalOpen && (
              <EditPrizePoolModal
                saveValues={props.onSavePrizePoolField}
                isOpen={true}
                toggle={() => {
                  setChangeModalOpen(false);
                }}
                tournament={tournament}
                statistics={statistics}
              />
            )}
          </>
        )}
      </>
    )
  );
};

export default PrizePoolModal;
