import PlayerState from '../enum/PlayerState';

export const formatPlayerName = (player) => `${player.firstName} ${player.lastName}`;

export const formatSeatNum = (value) => (value ? `0${value}`.substr(`0${value}`.length - 2) : '');

export const formatTableSeat = (player) => {
  return `${player.tableName || player.tableId || '?'}/${player.seatNum || '?'}`;
};


export const getSeats = (noOfSeats, occupiedSeats) => {
  const players = occupiedSeats ? occupiedSeats.split(",").map((each) => parseInt(each)) : [];
  const seats = [];
  for (let i = 0; i < noOfSeats; i += 1) {
    seats.push(players.some((player) => player === i + 1));
  }
  return seats;
}

export const getPlayerState = (player) => {
  const { state, tableName, seatNum } = player;

  if (state === 'finished') return PlayerState.FINISHED.key;
  if (state === 'unregistered') return PlayerState.UNREGISTERED.key;
  if (state === 'unregistering') return PlayerState.UNREGISTERING.key;
  if (state === 'proceed') return PlayerState.PROCEED.key;
  if (state === 'dq') return PlayerState.DQ.key;
  if (state === 'created') return PlayerState.WAITING_LIST.key;
  if (state === 'give_up_stack') return PlayerState.GIVE_UP.key;
  if (state === 'active') return PlayerState.PLAYING.key;
  if (state === 'floormanSeating') return PlayerState.NOT_SEATED.key;

  return PlayerState.NOT_SEATED.key;
};
