import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { history } from "../../../service/history";
import { validateTournamentStructure } from "../../../util/validationUtil";

import { getTemplateById, getTemplates } from "../../../actions/templateActions";
import TemplateType from "../../../enum/TemplateType";

import Structure from "../tournamentStructure/Structure";

const StructureContainer = (props) => {
  const dispatch = useDispatch();

  const variant = props.variant;

  const templates = useSelector((state) => state.templates.templates);
  const templateData = useSelector((state) => state.templates.template);
  const [activeTemlateContent, setActiveTemlateContent] = useState(undefined);

  const [temlateContent, setTemlateContent] = useState([]);

  useEffect(() => {
    dispatch(getTemplates(TemplateType.TOURNAMENT_STRUCTURE));
  }, []);

  useEffect(() =>{
    recalculateLevel(props.startingStructureLevel, variant.structure);
    props.updateVariant(variant);
  }, [props.startingStructureLevel]);


  useEffect(() => {
    if (!activeTemlateContent) {
      return;
    }

    if (
      templateData !== undefined &&
      templateData.content !== undefined &&
      templateData.content.length > 0
    ) {
      if (!variant.structureDeleted) {
        variant.structureDeleted = [];
      }
      const currentLevel = (props.currentLevel || {}).level || 0;
      const newStructure = variant.structure.filter(each => each.level <= currentLevel).concat(templateData.content.filter(each => each.level > currentLevel && each.level >= props.startingStructureLevel));
      for (let i = 0; i < variant.structure.length; i++) {
        if (i < newStructure.length && i < variant.structure.length) {
          newStructure[i].id = variant.structure[i].id;
        } else {
          variant.structureDeleted.push(variant.structure[i].id);
        }
      }

      //remove all items - keeping the same reference
      variant.structure.splice(0,variant.structure.length);
      newStructure.forEach(each => variant.structure.push(each));

      props.updateVariant(variant);
    }
  }, [templateData.content]);

  const selectFromDropdown = (item) => {
    setActiveTemlateContent(true);

    dispatch(getTemplateById(item.id));
  };

  const handleChange = (index, field, value) => {
    if (variant.structure[index][field] !== value) {
      variant.structure[index][field] = value;
      props.updateVariant(variant);
    }

    if (props.onChange) {
      props.onChange()
    }
  };

  const recalculateLevel = (startingStructureLevel = 1, structure = []) => {
    structure.forEach((each, index) => {
      each.level = index + startingStructureLevel;
    });
  };

  const addLevel = (index) => {
    var parentDuration = variant.structure[index].durationInMin;
    variant.structure.splice(index + 1, 0, {
      index: index + 1,
      uniqueId: Math.random(),
      durationInMin: parentDuration,
    });
    recalculateLevel(props.startingStructureLevel, variant.structure);
    props.updateVariant(variant);
  };

  const saveBreak = (structure, breakInMin) => {
    structure.breakInMin = breakInMin;
    props.updateVariant(variant);
  };

  const deleteBreak = (structure) => {
    structure.breakInMin = null;
    structure.requireConfirmOnStart = false;

    props.updateVariant(variant);
  };

  const deleteLevel = (index) => {
    if (variant.structure[index].id) {
      if (!variant.structureDeleted) {
        variant.structureDeleted = [];
      }
      variant.structureDeleted.push(variant.structure[index].id);
    }
    variant.structure.splice(index, 1);
    recalculateLevel(props.startingStructureLevel, variant.structure);
    props.updateVariant(variant);
  };

  const validationErrors = StructureContainer.getValidationErrors(undefined, variant);

  if (variant) {
    let structures;
    if (temlateContent !== undefined && temlateContent.length > 0) {
      const currentLevel = (props.currentLevel || {}).level || 0;
      structures = variant.structure.filter(each => each.level <= currentLevel).concat(temlateContent.filter(each => each.level > currentLevel));
    } else {
      structures = variant.structure;
    }
    return (
      <><Structure
        currentLevel={props.currentLevel}
        selectFromDropdown={selectFromDropdown}
        template={templates}
        id={variant._id}
        structures={structures}
        validationErrors={validationErrors}
        handleChange={handleChange}
        addLevel={addLevel}
        deleteLevel={deleteLevel}
        deleteBreak={deleteBreak}
        saveBreak={saveBreak}
        registrationCloseAtLevel={variant.registrationCloseAtLevel}
      />
        {(templateData?.content && props.startingStructureLevel > templateData?.content[templateData?.content.length - 1]?.level) && <small
            style={{color: 'red', paddingLeft:'15px'}}>The template doesn´t contain enough levels, please load another one or fill the structure manually</small>
        }
      </>
    );
  }
  history.push("/mtt");
  return <div>Flight not found, redirect to home page...</div>;
};

StructureContainer.defaultProps = {
  tournament: {
    tournamentPhases: [
      {
        variants: [
          {
            structure: [],
          },
        ],
      },
    ],
  },
  showVariantName: false,
};

StructureContainer.getValidationErrors = (tournament, variant) => {
  return validateTournamentStructure(variant.structure);
};

const mapStateToProps = (state) => {
  return {
    tournament: state.tournaments.tournamentToSave
      ? state.tournaments.tournamentToSave
      : {
        ...state.tournaments.tournament,
        numOfPhases:
          state.tournaments.tournament && state.tournaments.tournament.tournamentPhases
            ? state.tournaments.tournament.tournamentPhases.length
            : 0,
      },
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(StructureContainer);
