import React, { useEffect } from "react";
import moment from "moment-mini";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../shared/CustomModal";
import { getVariantLogs, clearVariantLogs } from "../../../actions/tournamentActions";
import { getLogName } from "../../../util/tournamentUtil";

const PlayerActionLogsModal = ({ isOpen, selectedPlayer, toggle }) => {
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.tournaments.logs);

  useEffect(() => {
    dispatch(getVariantLogs(JSON.stringify({ playerId: selectedPlayer.playerId })));

    return () => {
      dispatch(clearVariantLogs());
    };
  }, []);

  return (
    <>
      {isOpen && (
        <CustomModal
          title={`History ${selectedPlayer.firstName} ${selectedPlayer.lastName}`}
          open={isOpen}
          closeHandler={toggle}
          dialogClassName=""
        >
          {/* {isLoading && <Loader>Loading...</Loader>}
          {!isLoading && ( */}
          <>
            <Table className="text-dark" responsive>
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Time</th>
                  <th>Performed by</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((each) => (
                  <tr>
                    <td>{getLogName(each.logType)}</td>
                    <td>{moment(each.performedAt).format("DD.MM.YYYY HH:mm ")}</td>
                    <td>{each.performedByIdentifier}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        </CustomModal>
      )}
    </>
  );
};

export default PlayerActionLogsModal;
