import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input } from 'reactstrap';
import { Form, Row, FormLabel, FormGroup, Dropdown, FormCheck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import Nationality from '../../enum/Nationality';
import {
  cleanPlayer,
  getPlayersClean,
  insertPlayer,
  searchPlayersForReentryBuyin
} from '../../actions/playerActions';
import PrintContent from '../functionalComponents/print/PrintContent';
import { display } from '../../enum/Currency';
import { formatDateShortDash, getNationalityDesc, toBoolean } from '../../util/stringUtil'
import RegisterFoundPlayers from './RegisterFoundPlayers';
import PlusMinus from "../functionalComponents/PlusMinus";
import { isEmailValid } from "../../util/stringUtil";
import CustomModal from "../shared/CustomModal";

let GENERATED_ID_NUM_PREFIX = 'GEN_';

const RegisterCustomer = ({
  variant, tournament, toggle, getTotalChipsReentry, getTotalPayReentry, getTotalChipsBuyin, getTotalPayBuyin, isOpen, config
}) => {


  const emptyCustomer = {
    firstName: '',
    lastName: '',
    nationality: '',
    email: '',
    idCardNum: '',
    dateOfBirth: '',
  };

  const dispatch = useDispatch();
  const node = useRef(null);
  const [customer, setNewCustomer] = useState(emptyCustomer);
  const [printing, setPrinting] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const [registerClicked, setRegisterClicked] = useState(false);
  const [showValidationWarningModal, setShowValidationWarningModal] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState([]);

  const [countrySearch, setCountrySearch] = useState("");
  const [filteredNations, setFilteredNations] = useState([]);

  const { createdPlayer } = useSelector((state) => state.player);

  const playersState = useSelector((state) => state.player.playersInTournament);

  const txtSearchPlayer = useRef(0);

  useEffect(() => {
    dispatch(cleanPlayer());
    return () => {
      dispatch(getPlayersClean());
    };
  }, []);

  useEffect(() => {
    const selectedCustomer = playersState.data.find((p) => p.id === selectedCustomerId);

    if (!selectedCustomer)
      return;

    var tmpCustomer = {};
    tmpCustomer.firstName = selectedCustomer.firstName;
    tmpCustomer.lastName = selectedCustomer.lastName;
    tmpCustomer.nationality = selectedCustomer.nationality;
    tmpCustomer.idCardNum = selectedCustomer.idCardNum;
    tmpCustomer.dateOfBirth = formatDateShortDash(selectedCustomer.dateOfBirth);

    tmpCustomer.tournamentId = tournament.id;
    tmpCustomer.tournamentPhaseVariantId = variant.id;
    tmpCustomer.playerId = selectedCustomerId;
    tmpCustomer.membershipCardNum = undefined;

    setNewCustomer(tmpCustomer);

  }, [selectedCustomerId]);

  const handleChangeChk = (e) => {
    const newCustomer = { ...customer };
    newCustomer[e.target.name] = e.target.checked;
    setNewCustomer(newCustomer);
  };

  const handleAddOn = (e) => {
    const newOne = { ...customer };
    newOne[e.target.name] = e.target.value;
    setNewCustomer(newOne);
  };
  const mandatoryFields = [
    "firstName", "lastName",
    toBoolean(config.playerRegistrationBirthDate.value) ? "dateOfBirth" : null,
    toBoolean(config.playerRegistrationEmail.value) ? "email" : null,
    toBoolean(config.playerRegistrationIdNum.value) ? "idCardNum" : null,
    toBoolean(config.playerRegistrationPaymentMethod.value) ? "paymentMethod" : null
  ].filter(f => !!f)

  const isValid = (name) => {
    const isMandatory = mandatoryFields.includes(name)
    let value = customer[name]
    if (value && (typeof value === 'string')) {
      value = value.trim()
    }
    if (isMandatory) {
      if (!value) {
        return false
      }

      if (name === 'email') {
        if (value) {
          return isEmailValid(value);
        } else {
          return false
        }
      }

      if (name === 'idCardNum' && value.length < 2) {
        return false
      }

      return true
    } else if (name === 'email' && value) {
      return isEmailValid(value);
    } else if (value && name === 'idCardNum' && value.length < 2) {
      return false
    }

    return true
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  ////////////validation//////////////////////////////////////////////////////////////////

  const getReentryInfo = (input) => {
    if (input === "PLAYER_IS_STILL_PLAYING")
      return "Player is still playing";
    else if (input === "MAX_NUM_REENTRY_EXCEEDED")
      return "Exceeded max reentry count";

    return "";
  }

  const getValidationErrors = () => {
    var tmpvalidationErrorMessages = [];

    if (!customer.firstName || customer.firstName.trim() === "") {
      tmpvalidationErrorMessages.push("Name is mandatory");
    }

    if (!customer.lastName || customer.lastName.trim() === "") {
      tmpvalidationErrorMessages.push("Surname is mandatory");
    }


    if (toBoolean(config.playerRegistrationEmail.value) && !customer.email) {
      tmpvalidationErrorMessages.push("Email is mandatory");
    } else if (customer.email && customer.email !== "" && !isEmailValid(customer.email)) {
      tmpvalidationErrorMessages.push("Email is not in a valid format");
    }

    if (toBoolean(config.playerRegistrationIdNum.value) && !customer.idCardNum) {
      tmpvalidationErrorMessages.push("Personal ID number is mandatory");
    } else if (customer.idCardNum && customer.idCardNum !== "" && customer.idCardNum.length <= 2) {
      tmpvalidationErrorMessages.push("Personal ID number is not valid, please enter at least 3 characters");
    }

    if (toBoolean(config.playerRegistrationBirthDate.value) && !customer.dateOfBirth) {
      tmpvalidationErrorMessages.push("Date of birth is mandatory");
    }

    if (toBoolean(config.playerRegistrationPaymentMethod.value) && !customer.paymentMethod) {
      tmpvalidationErrorMessages.push("Payment method is mandatory");
    }

    if (tmpvalidationErrorMessages.length > 0) {
      setValidationErrorMessages(tmpvalidationErrorMessages);
      return true;
    }

    if (playersState.data !== undefined) {
      var existing = playersState.data.find(p => p.idCardNum === customer.idCardNum);

      if (!existing)
        return false;

      if (existing.firstName + existing.lastName !== customer.firstName + customer.lastName) {
        tmpvalidationErrorMessages.push("Personal ID number is already taken, please choose a player from the list above or change the personal ID number");

        setValidationErrorMessages(tmpvalidationErrorMessages)
        return true;
      }

      if (existing.reasonReEntryForbidden) {
        tmpvalidationErrorMessages.push(getReentryInfo(existing.reasonReEntryForbidden));
        setValidationErrorMessages(tmpvalidationErrorMessages)
        return true;
      }
    }

    return false;
  }

  const isInvalid = (name) => {
    return !isValid(name)
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  let timeout;
  const txtSearchPlayerChanged = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      startToSearch();
    }, 600);
  };

  const countryChanged = (value) => {
    setCountrySearch(value);
    var filtered = Object.keys(Nationality).reduce(function (filtered, key) {
      if (Nationality[key].toLowerCase().includes(value.toLowerCase()))
        filtered[key] = Nationality[key];
      return filtered;
    }, {});

    setFilteredNations(filtered)
  }

  const handleChange = (e) => {
    const newOne = { ...customer };
    if (e.target.name === 'idCardNum') {
      idSearchPlayerChanged(e.target.value);
    }
    if (e.target.name === 'paymentMethod') {
      let paymentMethods = []
      node.current.querySelectorAll("input[name='paymentMethod']").forEach(a => {
        if (a.checked) {
          paymentMethods.push(a.labels[0].innerText)
        }
      })

      newOne[e.target.name] = paymentMethods.join(',');
      setNewCustomer(newOne);

    } else {
      newOne[e.target.name] = e.target.value;
      setNewCustomer(newOne);
    }
  };

  const idSearchPlayerChanged = (idValue) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      startToSearchBasedOnId(idValue);
    }, 600);
  };

  function startToSearchBasedOnId(idValue) {
    txtSearchPlayer.current.value = "";
    if (idValue.length >= 3) {
      dispatch(searchPlayersForReentryBuyin(variant.id, idValue));
    }
  }

  function startToSearch() {
    setSelectedCustomerId(null);
    const searchString = txtSearchPlayer.current.value.trim();
    if (searchString.length >= 3) {
      dispatch(searchPlayersForReentryBuyin(variant.id, searchString));
    } else {
      dispatch(getPlayersClean());
    }
  }

  const clearAll = () => {
    setNewCustomer(emptyCustomer);
    setSelectedCustomerId(null)
    txtSearchPlayer.current.value = "";
  }

  const isReentryCustomer = () => {
    if (selectedCustomerId !== null) {
      var existingPlayer = playersState.data.find(ps => ps.id === selectedCustomerId);
      return existingPlayer.reEntryAllowed;
    }

    var existingPlayer = playersState.data.find(ps => ps.idCardNum === customer.idCardNum);
    return existingPlayer ? existingPlayer.reEntryAllowed : false;
  }

  //////////////////////////////////////////submit///////////////////////////////////////////////////

  const submitExistingCustomer = () => {
    var existingPlayer = selectedCustomerId ? playersState.data.find((p) => p.id === selectedCustomerId) : playersState.data.find(ps => ps.idCardNum === customer.idCardNum);

    customer.firstName = existingPlayer.firstName;
    customer.lastName = existingPlayer.lastName;
    customer.nationality = existingPlayer.nationality;
    customer.idCardNum = existingPlayer.idCardNum;
    customer.dateOfBirth = existingPlayer.dateOfBirth;

    customer.tournamentId = tournament.id;
    customer.tournamentPhaseVariantId = variant.id;
    customer.playerId = selectedCustomerId;
    customer.reEntry = existingPlayer.reEntryAllowed;
    customer.membershipCardNum = undefined;
    customer.paymentMethod = customer.paymentMethod === "" ? undefined : customer.paymentMethod;
    dispatch(insertPlayer(customer));
    setPrinting(true);
  };

  const submitNewCustomer = () => {

    const requestCustomer = { ...customer }

    requestCustomer.tournamentId = tournament.id;
    requestCustomer.tournamentPhaseVariantId = variant.id;
    requestCustomer.reEntry = false;
    requestCustomer.membershipCardNum = undefined;
    if (!toBoolean(config.playerRegistrationIdNum.value) && (customer.idCardNum === "" || customer.idCardNum === undefined)) {
      requestCustomer.idCardNum = `${GENERATED_ID_NUM_PREFIX}${new Date().getTime()}`;
    }
    if (requestCustomer.dateOfBirth === "") {
      requestCustomer.dateOfBirth = null;
    }
    if (requestCustomer.email === "") {
      requestCustomer.email = undefined;
    }

    dispatch(insertPlayer(requestCustomer));
    setPrinting(true);
  };

  const isExistingPlayerBasedOnIdCard = () => {
    if (playersState.data.length > 0) {
      return playersState.data.some(ps => ps.idCardNum === customer.idCardNum);
    }

    return false;
  }

  const submit = (ev) => {
    if (getValidationErrors()) {
      setShowValidationWarningModal(true);
      return;
    }

    ev.preventDefault();
    setRegisterClicked(true);

    if (selectedCustomerId !== null || isExistingPlayerBasedOnIdCard()) {
      submitExistingCustomer();
    }
    else {
      submitNewCustomer();
    }
  };


  return (<>
    {printing && createdPlayer && createdPlayer.printed === false &&
      <PrintContent createdPlayerId={createdPlayer.id} customer={customer} createdPlayer={createdPlayer}
        variant={variant} onPrinted={() => {
          setPrinting(false); toggle()
        }} />}
    <Form id="addExistingCustomer" ref={node} className={'d-block'}>
      <div className="search-player">
        <Input
          innerRef={txtSearchPlayer}
          id="txtSearchPlayer"
          placeholder="By Name, personal ID or system ID"
          onChange={txtSearchPlayerChanged}
        />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <Row>
        <RegisterFoundPlayers
          playersState={playersState}
          setSelectedCustomerId={setSelectedCustomerId}
          selectedCustomerId={selectedCustomerId}
        />
      </Row>
    </Form>
    <Form id="addNewCustomer" ref={node} className={isOpen ? 'd-block' : 'd-none'}>
      <Row>
        <FormGroup>
          <FormLabel for="firstNameElm">
            Name
            {mandatoryFields.includes("firstName") && <span className="required">*</span>}
          </FormLabel>
          <Input
            id="firstNameElm"
            disabled={selectedCustomerId !== null}
            invalid={isInvalid("firstName")}
            placeholder="Type name"
            name="firstName"
            onChange={handleChange}
            value={customer.firstName}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel for="lastNameElm">
            Surname
            {mandatoryFields.includes("lastName") && <span className="required">*</span>}
          </FormLabel>
          <Input
            id="lastNameElm"
            disabled={selectedCustomerId !== null}
            invalid={isInvalid("lastName")}
            placeholder="Type surname"
            name="lastName"
            onChange={handleChange}
            value={customer.lastName}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel for="email">
            Email
            {mandatoryFields.includes("email") && <span className="required">*</span>}
          </FormLabel>
          <Input
            id="emailElm"
            disabled={selectedCustomerId !== null}
            placeholder="Type email"
            invalid={isInvalid("email")}
            name="email"
            onChange={handleChange}
            value={customer.email}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <FormLabel for="nationality">
            Nationality
          </FormLabel>
          <Dropdown id="mationalityElm" focusFirstItemOnShow={false}>
            <DropdownToggle as="div" className="mtt-toggle">
              {selectedCustomerId !== null ? customer.nationality : getNationalityDesc(customer.nationality)}
            </DropdownToggle>
            <DropdownMenu>
              <Dropdown.Header>
                <Input
                  className='nationality-input'
                  placeholder="Search country"
                  onChange={(e) => countryChanged(e.target.value)}
                  value={countrySearch}>
                </Input>
              </Dropdown.Header>
              {Object.keys(countrySearch === "" ? Nationality : filteredNations).map((nationality, index) => (
                <>
                  <DropdownItem
                    as="div"
                    disabled={selectedCustomerId !== null}
                    onClick={(e) => {
                      handleChange({
                        target: {
                          id: 'mationalityElm',
                          type: 'text',
                          name: 'nationality',
                          value: nationality,
                        },
                      });
                    }}
                  >
                    {getNationalityDesc(nationality)}
                  </DropdownItem>
                </>
              ))}
            </DropdownMenu>
          </Dropdown>
        </FormGroup>
        <FormGroup>
          <FormLabel for="idCardNum">
            Personal ID number
            {mandatoryFields.includes("idCardNum") && <span className="required">*</span>}
          </FormLabel>
          <Input
            id="idCardNumElm"
            disabled={selectedCustomerId !== null}
            invalid={isInvalid("idCardNum")}
            placeholder="Type ID number"
            name="idCardNum"
            onChange={handleChange}
            value={((customer.idCardNum || '').toString().startsWith(GENERATED_ID_NUM_PREFIX)) ? '' : customer.idCardNum}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel for="dateOfBirth">
            Date of Birth
            {mandatoryFields.includes("dateOfBirth") && <span className="required">*</span>}
          </FormLabel>
          <Input
            id="dateOfBirthElm"
            disabled={selectedCustomerId !== null}
            placeholder="dd.mm.yyyy"
            invalid={isInvalid("dateOfBirth")}
            className={!customer.dateOfBirth ? 'placeholder' : ''}
            type="date"
            name="dateOfBirth"
            onChange={handleChange}
            value={customer.dateOfBirth}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <Button className="btt-link" onClick={clearAll}>
            Clear all
          </Button>
        </FormGroup>
      </Row>
      <Row>
        <div className="graySeparator" />
      </Row>
      <Row>
        <FormLabel>
          Additional actions
        </FormLabel>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Check
            custom
            type="checkbox"
            id="custom-checkbox3"
            name="bonus"
            label="Bonus chips"
            checked={customer.bonus}
            disabled={!variant.bonus}
            onChange={handleChangeChk}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <div className="addOnDiv" >
            <PlusMinus id="addOn" onChange={handleAddOn} name="addon" disabled={!variant.addon} max={variant.addonMaxPerPlayer} />
            <Form.Label className={`${!variant.addon ? 'disabled' : ''}`} disabled={!variant.addon}>{`Addon - for ${variant.addonFee + variant.addonPool} ${tournament.currency} / ${variant.addonChips
              } chips`} </Form.Label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <div className="rebuyDiv">
            <PlusMinus id="rebuy" onChange={handleAddOn} name="reBuy" disabled={!variant.reBuy} max={variant.reBuyMaxPerPlayer} />
            <Form.Label className={`${!variant.reBuy ? 'disabled' : ''}`} disabled={!variant.reBuy}>{`Rebuy - for ${variant.reBuyFee + variant.reBuyPool} ${tournament.currency} / ${variant.reBuyChips
              } chips`} </Form.Label>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="graySeparator" />
      </Row>
      <><Row>
        <FormLabel>Payment method</FormLabel>
        {toBoolean(config.playerRegistrationPaymentMethod.value) && <span className="required">*</span>}
      </Row>
        <Row>
          <Col>
            <FormCheck custom name="paymentMethod" type="checkbox" id="custom-checkbox18" label="Cash" onChange={handleChange} />
          </Col>
          <Col>
            <FormCheck
              custom
              name="paymentMethod"
              type="checkbox"
              id="custom-checkbox8"
              label="Credit Card"
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormCheck custom name="paymentMethod" type="checkbox" id="custom-checkbox9" label="Ticket" onChange={handleChange} />
          </Col>
        </Row></>
      <Row>
        <div className="graySeparator" />
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <FormLabel>
                <h4>Total</h4>
              </FormLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>
                {`
                ${isReentryCustomer() ? getTotalPayReentry(customer) : getTotalPayBuyin(customer)}
                ${display(variant && variant.tournament ? variant.tournament.currency : '')}
                ${' / '}
                ${isReentryCustomer() ? getTotalChipsReentry(customer) : getTotalChipsBuyin(customer)}
                ${' chips'}
                `}
              </h3>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button color="primary" disabled={registerClicked} onClick={submit} className="fit">
            Register and print receipt
          </Button>
        </Col>
      </Row>
    </Form>
    {showValidationWarningModal && (
      <CustomModal
        closeHandler={() => { setShowValidationWarningModal(false) }}
        title={`Can not register player due to errors:`}
        open={showValidationWarningModal}
      >
        {validationErrorMessages.map(error => {
          return <Row className="text-danger error-msg">
            <b>{error}</b>
          </Row>
        })}
        <div>
          <Button color="primary" className="fit" onClick={() => {
            setShowValidationWarningModal(false);
          }}>
            OK
          </Button>
        </div>
      </CustomModal>
    )}
  </>
  );
};

export default RegisterCustomer;
