import React from "react";
import { connect } from "react-redux";
import { Button, Table } from "reactstrap";
import ReactHTMLTableToExcel from "../shared/excel/ReactHTMLTableToExcel";
import { getFees } from "../../actions/tournamentActions";
import CustomModal from "../shared/CustomModal";
import CircleIcon from "../functionalComponents/CircleIcon";
import { formatPlayerName } from "../../util/playerUtil";
import { formatDate } from "../../util/stringUtil";
import { Row, Col } from "react-bootstrap";
import restClient from "../../util/restClient";
import * as FileSaver from 'file-saver';
import {getTournamentVariantTitle} from "../../util/tournamentUtil";

class PlayerListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "name",
    };
  }

  sortByName = () => {
    this.setState({ sortBy: "name" });
  };

  sortByPosition = () => {
    this.setState({ sortBy: "position" });
  };

  async getExcel(e) {
    e.preventDefault();

    const res = await restClient.getAsync(this.props.playersUrl, true);
    const pdfBlob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
    FileSaver.saveAs(pdfBlob, "players.xlsx");
  }

  render() {
    const sortedPlayersList = JSON.parse(JSON.stringify(this.props.playersInTournament));

    if (this.state.sortBy === "position") {
      sortedPlayersList.sort((a, b) => {
        if (a.position && b.position) return a.position - b.position;
        if (a.position && !b.position) return -1;
        if (b.position && !a.position) return 1;
        return 0;
      });
    } else {
      sortedPlayersList.sort(function (a, b) {
        return a.lastName.localeCompare(b.lastName);
      });
    }

    return (
      <CustomModal
        open={this.props.isOpen}
        closeHandler={this.props.toggle}
        className={`players-list-modal ${this.props.dark ? "dark" : ""}`}
        title="Player list"
        dialogClassName="modal-width"
        text={
          this.props.variant && this.props.variant.name ? (
            <label>
              <CircleIcon color={this.props.variant.color} />{" "}
              {`${getTournamentVariantTitle(this.props.tournament, this.props.variant)}`}
            </label>
          ) : (
            <div>
              <CircleIcon color={this.props.tournament.color} /> {`${this.props.tournament.name}`}
            </div>
          )
        }
      >
        <div style={{ height: "80%", display: "flex", flexDirection: "column", width: "100%" }}>
          <div className="sort" style={{ flexGrow: 0 }}>
            <Button
              onClick={this.sortByPosition}
              className={this.state.sortBy === "position" ? "selected" : ""}
            >
              Sort by position
            </Button>
            <label>/</label>
            <Button
              onClick={this.sortByName}
              className={this.state.sortBy === "name" ? "selected" : ""}
            >
              Sort by A-Z
            </Button>
          </div>

          <div
            className="players"
            style={{
              flexGrow: 1,
            }}
          >
            <Row className="no-gutters header">
              <Col noGutters md={2}>
                PLAYER
              </Col>
              <Col noGutters md={1}>
                POS.
              </Col>
              <Col noGutters md={1}>
                PAYOUT
              </Col>
              <Col noGutters md={2}>
                NATIONALITY
              </Col>
              <Col noGutters md={2}>
                NUMBER
              </Col>
              <Col noGutters md={2}>
                DATE OF BIRTH
              </Col>
              <Col noGutters md={2}>
                CHIPSCOUNT
              </Col>
            </Row>

            {sortedPlayersList.map((player, index) => {
              return (
                <Row className="no-gutters" key={index}>
                  <Col noGutters md={2} className="three-dots">
                    {formatPlayerName(player)}
                  </Col>
                  <Col noGutters md={1}>
                    {player.position === undefined || player.position === null
                      ? "-"
                      : player.position}
                  </Col>
                  <Col noGutters md={1}>
                    {player.payoutAmount === undefined || player.payoutAmount === null
                      ? "-"
                      : player.payoutAmount}
                  </Col>
                  <Col noGutters md={2}>
                    {player.nationality}
                  </Col>
                  <Col noGutters md={2} className="three-dots">
                    {player.idCardNum}
                  </Col>
                  <Col noGutters md={2}>
                    {formatDate(player.dateOfBirth)}
                  </Col>

                  <Col noGutters md={2}>
                    {this.props.showChipCount && <Col xl={1}>{player.stack}</Col>}
                  </Col>

                </Row>
              );
            })}
            {/*  </tbody>*/}
            {/*</Table>*/}
          </div>
          <div className="footer" style={{ flexGrow: 0 }}>
            <Button
                color="primary"
                className="confirm-btn"
                onClick={async (e) => {
                  await this.getExcel(e);
                }}
            >
              Save as Excel
            </Button>
          </div>
        </div>
      </CustomModal>
    );
  }
}

PlayerListModal.defaultProps = {
  isOpen: false,
};

const mapStateToProps = (state) => {
  return {
    playersInTournament: state.player.playersInTournament.data,
  };
};

const mapDispatchToProps = {
  getFees,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerListModal);
