import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Button, Col, Row } from 'react-bootstrap';
import CustomModal from '../shared/CustomModal';

class DateFilterModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  applyFilter() {
    this.props.apply(this.state.from, this.state.to);
  }

  closeFilter() {
    if (this.state.from === undefined && this.state.to === undefined) {
      this.props.apply(null, null);
    }

    this.props.close();
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <CustomModal title="Date filter" open={this.props.open} closeHandler={() => this.closeFilter()} className="date-filter-modal" dialogClassName="modal-width">
        <>
          <DayPicker className="Selectable" numberOfMonths={1} selectedDays={[from, { from, to }]} modifiers={modifiers} onDayClick={this.handleDayClick} />
          <Row noGutters="true">
            <Col>
              <Button className="apply" onClick={() => this.applyFilter()}>
                Apply filter
              </Button>
            </Col>
            <Col>
              <Button className="clear" onClick={() => this.handleResetClick()} variant="outline-primary" inverted>
                Clear filter
              </Button>
            </Col>
          </Row>
        </>
      </CustomModal>
    );
  }
}

export default DateFilterModal;
