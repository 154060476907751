import React, { useEffect, useRef, useState } from "react";
import moment from "moment-mini";
import { useDispatch, useSelector } from "react-redux";
import TournamentClockHeader from "../shared/TournamentClockHeader";
import { getTournamentPayouts } from "../../actions/tournamentPayoutActions";
import { formatAmount, formatPositions, toBoolean } from "../../util/stringUtil";
import useVariantStats from "../shared/useVariantStats";
import {
  getMainClockTime,
  resolveRegistrationState,
  formatDuration,
} from "../../util/tournamentUtil";
import socket from "../../service/EvSocket";
import StateVariantRegistration from "../../enum/StateVariantRegistration";
import TextScroller from "../../components/shared/TextScroller";
import { getClockSettings } from "../../actions/tournamentActions";
import TileLevel from "../mttdirector/directorClock/clock-tiles/tile-level";
import TileNextLevel from "../mttdirector/directorClock/clock-tiles/tile-next-level";
import TileRegCloseIn from "../mttdirector/directorClock/clock-tiles/tile-reg-close-in";
import TileNextBreak from "../mttdirector/directorClock/clock-tiles/tile-next-break";
import TilePlayers from "../mttdirector/directorClock/clock-tiles/tile-players";
import TileUniquePlayers from "../mttdirector/directorClock/clock-tiles/tile-unique-players";
import TileTotalChipcounts from "../mttdirector/directorClock/clock-tiles/tile-total-chipcounts";
import TileAvgStack from "../mttdirector/directorClock/clock-tiles/tile-avg-stack";
import TilePrizepool from "../mttdirector/directorClock/clock-tiles/tile-prizepool";
import TileBlinds from "../mttdirector/directorClock/clock-tiles/tile-blinds";

const VariantClock = (props) => {
  const dispatch = useDispatch();
  const thisVariant = props.variant;

  const clockSettings = useSelector((state) => state.tournaments.clockSettings);
  const [hidePayouts, setHidePayouts] = useState(false);

  const [currentTime, setCurrentTime] = useState(moment());
  const tournamentStats = useVariantStats(
    thisVariant.id,
    "VariantClock",
    thisVariant.fetchDataTime
  );
  const tournamentPayouts = useSelector((state) => state.tournamentPayouts.tournamentPayouts);
  const thisTournament = thisVariant.tournament || {};
  thisVariant.tournament = thisTournament;

  if (clockSettings.isLoaded && toBoolean(clockSettings.data.hide_payouts) !== hidePayouts) {
    setHidePayouts(toBoolean(clockSettings.data.hide_payouts));
  }

  useEffect(() => {
    if (thisVariant && thisVariant.id) {
      if (!clockSettings.isLoaded && !clockSettings.isLoading) {
        dispatch(getClockSettings(thisVariant.id));
      }
      if (thisTournament && thisTournament.id) {
        dispatch(getTournamentPayouts(thisTournament.id));
      }
    }
  }, [thisVariant.id, thisVariant.fetchDataTime]);

  let timeTillStart = moment
    .duration(moment((thisVariant.currentLevel || {}).mStartAt).diff(currentTime))
    .asSeconds();
  if (timeTillStart < 0) {
    timeTillStart = 0;
  }
  let thisCurLevel;
  let thisNextUpComingLevel;
  if (thisVariant.state === "created") {
    thisCurLevel = {};
    thisNextUpComingLevel = thisVariant.currentLevel || {};
  } else {
    thisCurLevel = thisVariant.currentLevel || {};
    thisNextUpComingLevel = thisVariant.nextUpComingLevel || {};
  }

  const isClockPaused = thisVariant.state === "paused";
  const registrationState = resolveRegistrationState(thisVariant);
  const isRegistrationClosed =
    registrationState.state === StateVariantRegistration.RUNNING_REGISTRATION;

  const note = [];
  if (isClockPaused || isRegistrationClosed) {
    if (isClockPaused) {
      note.push("CLOCK IS PAUSED");
    }
  }

  let durationSinceTournamentStartFormatted = "--:--";

  // end of registration
  if (thisVariant.registrationCloseAtLevel > 0) {
    if (registrationState.state !== StateVariantRegistration.REGISTRATION_CLOSED) {
      //durationTillEndOfRegFormatted = "closed";
    } else {
      const nowTillEndOfRegistrationInSec = registrationState.numSecTillEnd;
      //durationTillEndOfRegFormatted = formatDuration(nowTillEndOfRegistrationInSec);

      if (
        thisVariant.registrationCloseAtLevel > 0 &&
        isRegistrationClosed &&
        thisCurLevel.level === thisVariant.registrationCloseAtLevel &&
        thisCurLevel.type === "break" &&
        nowTillEndOfRegistrationInSec <= 0
      ) {
        note.push("REGISTRATION IS CLOSED");
      }
    }
  }

  //since the start of tournament
  if (thisVariant.state === "active" || thisVariant.state === "paused") {
    const durationSinceStartOfGame = moment
      .duration(currentTime.diff(moment(thisVariant.startedAt)))
      .asSeconds();
    durationSinceTournamentStartFormatted = formatDuration(durationSinceStartOfGame);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [thisVariant.id]);

  useEffect(() => {
    dispatch(getTournamentPayouts(props.tournamentId));
    socket.on("updateTournament", "VariantClock", (payload, dispatch) => {
      const originalTournamentId = props.tournamentId;
      const tournamentId = payload.id;
      if (originalTournamentId && originalTournamentId === tournamentId) {
        dispatch(getTournamentPayouts(originalTournamentId));
      }
    });
    return () => {
      socket.removeHandler("updateTournament", "VariantClock");
    };
  }, [props.tournamentId]);

  //prepare ticker value
  const reducedTournamentPayouts = [];
  tournamentPayouts
    .filter((each) => each.toPayout !== undefined)
    .forEach((each) => {
      if (
        reducedTournamentPayouts.length === 0 ||
        reducedTournamentPayouts[reducedTournamentPayouts.length - 1].toPayout !== each.toPayout
      ) {
        reducedTournamentPayouts.push({
          ...each,
          position: [each.position],
        });
      } else {
        reducedTournamentPayouts[reducedTournamentPayouts.length - 1].position.push(each.position);
      }
    });

  const setClockStyle = () => {
    if (props.isFullScreen) {
      if (is4KResolution()) return "mtt-clock full-size-4k";
      return "full-size";
    }
    return "mtt-clock";
  };

  function is4KResolution() {
    return screen.height < screen.width ? screen.width > 3839 : screen.height > 3839;
  }

  return (
    <div id="mtt-clock" className={setClockStyle()}>
      <TournamentClockHeader
        variant={thisVariant}
        tournament={thisTournament}
        goToFullScreen={props.goToFullScreen}
      />

      <div className="clock-panel">
        <div className="clock-left-panel">
          <TileLevel curLevel={thisCurLevel} />
          <TilePlayers tournamentStats={tournamentStats} />
          <TileUniquePlayers tournamentStats={tournamentStats} />
          <TileTotalChipcounts tournamentStats={tournamentStats} />
          <TileAvgStack tournamentStats={tournamentStats} />
          <TilePrizepool variant={thisVariant} tournamentStats={tournamentStats} />
        </div>

        <div className="clock-middle-panel">
          <div className="clock-tournament-time">
            <span className="big">{getMainClockTime(thisVariant, thisCurLevel)}</span>
            {note.map((each) => (
              <span key={each} className="note">
                {each}
              </span>
            ))}
          </div>
          <TileBlinds
            variant={thisVariant}
            curLevel={thisCurLevel}
            className="clock-tournament-blinds"
            blindsInOneLine
          />
          <TileNextLevel
            nextUpComingLevel={thisNextUpComingLevel}
            className="clock-tournament-nextlevel"
          />
        </div>
        <div className="clock-right-panel">
          <div className="item">
            <div className="title">Current time</div>
            <div className="text">{currentTime.format("HH:mm:ss")}</div>
          </div>
          <div className="item">
            <div className="title">Elapsed Time</div>
            <div className="text">{durationSinceTournamentStartFormatted}</div>
          </div>
          <TileNextBreak variant={thisVariant} curLevel={thisCurLevel} />
          <TileRegCloseIn variant={thisVariant} />
          {!!thisVariant.reBuy && (
            <div className="item">
              <div className="title">Rebuys</div>
              <div className="text">{tournamentStats.rebuyCount}</div>
            </div>
          )}
        </div>
      </div>
      <div className="clock-bottom">
        {hidePayouts ? (
          <div className="ticker" style={{ overflow: "hidden", whiteSpace: "pre" }}></div>
        ) : (
          <TextScroller
            text={hidePayouts ? " " : formatPositions(tournamentPayouts, thisTournament.currency)}
          />
        )}
      </div>
    </div>
  );
};

export default VariantClock;
