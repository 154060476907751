import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap";
import Loader from "../shared/Loader";
import GetVariantDataWrapper from "../shared/GetVariantDataWrapper";
import UpdatePlayerStateWrapper from "../shared/UpdatePlayerStateWrapper";
import "react-day-picker/lib/style.css";
import DateFilterModal from "../modals/DateFilterModal";
import {
    getFlatVariantList,
    getRegistrationCloseAtLevel,
    getStructureByLevel,
    resolveCurrentLevel,
    resolveRegistrationState,
    resolveVariantRegistrationState,
    getTournamentTitle
} from "../../util/tournamentUtil";
import RegistrationState from "../../enum/RegistrationState";
import StateVariantRegistration from "../../enum/StateVariantRegistration";
import {
    TournamentVariantTableHistory,
    TournamentVariantTableRunning,
    TournamentVariantTableUpcoming
} from "./TournamentVariantTables";
import currentUserUtil from "../../util/currentUser";
import Logout from "../logout/Logout";
import moment from "moment-mini";

import HelpTexts from '../../help-texts.json';
import { HelpModal } from "../shared/help/Help";

const DISPLAY = {
    RUNNING: "running",
    UPCOMING: "upcoming",
    HISTORY: "history"
};

class TournamentVariantList extends React.Component {
    state = {
        display: DISPLAY.RUNNING,
        dateFrom: undefined,
        tournaments: [],
        openDateFilter: false,
        openHelpModal: false,
        upcomingFrom: null,
        upcomingTo: null
    };

    getRunningTournamentVariants = () => {
        return this.getVariants(this.props.tournaments, RegistrationState.OPEN_REGISTRATION);
    };

    getUpcomingTournamentVariants = () => {
        return this.getVariants(this.props.tournaments, RegistrationState.FUTURE);
    };

    getClosedTournamentVariants = () => {
        const closed = this.getVariants(this.props.finishedTournaments, RegistrationState.REG_CLOSED);

        var sorted = closed.sort(function (a, b) {
            return moment(b.finishedAt).toDate() - moment(a.finishedAt).toDate();
        });

        if (this.state.upcomingFrom == null || this.state.upcomingTo == null) return sorted;

        const filtered = sorted.filter(
            tournament =>
                moment(tournament.finishedAt).toDate() >= this.state.upcomingFrom &&
                moment(tournament.finishedAt).toDate() <= this.state.upcomingTo
        );

        return filtered;
    };

    getVariants = (tournaments, tournamentState) => {
        const variants = [];
        const now = moment().toDate();
        let refreshAt;
        const registerRefreshTime = refreshTime => {
            if (refreshTime) {
                const refreshTimeObject = moment(refreshTime).toDate();
                if (now.getTime() < refreshTimeObject.getTime() && (refreshAt === undefined || refreshTimeObject.getTime() < refreshAt.getTime())) {
                    refreshAt = refreshTimeObject;
                }
            }
        };
        tournaments.forEach(eachTournament => {
            getFlatVariantList(eachTournament).forEach(eachVariant => {
                registerRefreshTime(eachVariant.registrationStartAt);
                if (eachVariant.stateId === 2) {
                    const registrationState = resolveRegistrationState(eachVariant);
                    if (registrationState.state === StateVariantRegistration.RUNNING_REGISTRATION) {
                        const v = resolveCurrentLevel(eachVariant);
                        const registrationCloseAtLevel = getRegistrationCloseAtLevel(v);
                        const structureAtRegEnd = getStructureByLevel(registrationCloseAtLevel, 'break', v.structure);
                        if (structureAtRegEnd && (structureAtRegEnd.startConfirmed || !structureAtRegEnd.requireConfirmOnStart)) {
                            registerRefreshTime(registrationState.closeAt);
                        }
                    }
                }
                if (resolveVariantRegistrationState(eachVariant) === tournamentState) {
                    variants.push({
                        tournamentId: eachTournament.id,
                        tournamentName: getTournamentTitle(eachTournament),
                        tournamentIsMultiday: eachTournament.isMultiday,
                        tournament: eachTournament,
                        ...eachVariant
                    });
                }
            });
        });
        // refresh at time when one of variant change registration state
        if (refreshAt !== undefined) {
            const deplay = refreshAt.getTime() - moment().toDate().getTime();
            if (deplay > 0 && deplay < 24 * 60 * 60 * 1000) {
                console.log('Register refresh in ', deplay);
                const timer = setTimeout(() => {
                    console.log('Updating variant list');
                    this.forceUpdate();
                }, deplay);
            }
        }
        return variants;
    };

    setDisplay = display => {
        this.setState({ display });
    };

    applyFilter = (from, to) => {
        this.setState({
            openDateFilter: false,
            upcomingFrom: from,
            upcomingTo: to
        });
    };

    openFilterModal = () => {
        this.setState({ openDateFilter: !this.state.openDateFilter });
    };

    openHelpModal = () => {
        this.setState({ openHelpModal: !this.state.openHelpModal });
    };

    importAll = (r) => {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }

    getImages = () => {
        return importAll(require.context('../../image/help', false, /\.(png|jpe?g|svg)$/));
    }

    render() {
        return (
            <>
                {this.props.isLoading ? (
                    <Loader />
                ) : (
                    <div className="tournament-variants">
                        <Row noGutters>
                            <Col>
                                <Row className="header">
                                    <Col>EV</Col>
                                    <Col className="logout">
                                        <Dropdown className="mtt-ddm">
                                            <Dropdown.Toggle as="div" className="form-control mtt-toggle">
                                                <span>{currentUserUtil.getUserAlias()}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dark">
                                                <Logout />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Row className="tab-container">
                                    <Col>
                                        <ButtonGroup className="btn-switch big tournament-tab">
                                            {!this.props.hideRunning && (
                                                <Button
                                                    className="tab-button"
                                                    variant="dark"
                                                    active={this.state.display === DISPLAY.RUNNING}
                                                    onClick={() => this.setDisplay(DISPLAY.RUNNING)}
                                                >
                                                    Open registration
                                                </Button>
                                            )}
                                            {!this.props.hideUpcomming && (
                                                <Button
                                                    className="tab-button"
                                                    variant="dark"
                                                    active={this.state.display === DISPLAY.UPCOMING}
                                                    onClick={() => this.setDisplay(DISPLAY.UPCOMING)}
                                                >
                                                    Upcoming
                                                </Button>
                                            )}
                                            {!this.props.hideHistory && (
                                                <Button
                                                    className="tab-button"
                                                    variant="dark"
                                                    active={this.state.display === DISPLAY.HISTORY}
                                                    onClick={() => this.setDisplay(DISPLAY.HISTORY)}
                                                >
                                                    History
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        <Button
                                            id="payout"
                                            className="payout-btn"
                                            variant="outline-secondary"
                                            onClick={() => {
                                                this.props.togglePendingPayout();
                                            }}
                                        >
                                            Make Payout
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col xs={12} className="tournament-table">
                                {this.state.display === DISPLAY.RUNNING && (
                                    <UpdatePlayerStateWrapper socketUpdateTournamentVariant={true}>
                                        <GetVariantDataWrapper activeVariants>
                                            <TournamentVariantTableRunning
                                                display={this.state.display}
                                                className="tournament-table-content"
                                                variants={this.getRunningTournamentVariants()}
                                                columns={this.props.columns}
                                            />
                                        </GetVariantDataWrapper>
                                    </UpdatePlayerStateWrapper>
                                )}
                                {this.state.display === DISPLAY.UPCOMING && (
                                    <UpdatePlayerStateWrapper>
                                        <GetVariantDataWrapper activeVariants>
                                            <TournamentVariantTableUpcoming
                                                display={this.state.display}
                                                className="tournament-table-content"
                                                variants={this.getUpcomingTournamentVariants()}
                                                columns={this.props.columns}
                                            />
                                        </GetVariantDataWrapper>
                                    </UpdatePlayerStateWrapper>
                                )}
                                {this.state.display === DISPLAY.HISTORY && (
                                    <UpdatePlayerStateWrapper>
                                        <GetVariantDataWrapper activeVariants={false}>
                                            <TournamentVariantTableHistory
                                                display={this.state.display}
                                                className="tournament-table-content"
                                                variants={this.getClosedTournamentVariants()}
                                                columns={this.props.columns}
                                                openFilterModal={this.openFilterModal}
                                            />
                                        </GetVariantDataWrapper>
                                    </UpdatePlayerStateWrapper>
                                )}
                            </Col>
                        </Row>
                        <Row className="float-right">
                            <Button color="primary" className="fit" onClick={() => this.openHelpModal()}>Help</Button>
                        </Row>
                        <HelpModal
                            isOpen={this.state.openHelpModal}
                            toggle={() => this.setState({ openHelpModal: false })}
                            helpTexts={HelpTexts.Cards.find(c => c.Id === 4)}
                            images={() => this.getImages()}>
                        </HelpModal>
                        <DateFilterModal
                            open={this.state.openDateFilter}
                            apply={this.applyFilter}
                            close={() => this.setState({ openDateFilter: false })}
                        />
                    </div>
                )}
            </>
        );
    }
}

TournamentVariantList.defaultProps = {
    tournaments: [],
    finishedTournaments: []
};
const mapStateToProps = state => {
    return {
        isLoading: state.globalError.isLoading,
        tournaments: state.tournaments.tournaments,
        finishedTournaments: state.tournaments.finishedTournaments
    };
};

export default connect(mapStateToProps)(TournamentVariantList);
