import React from "react";

const TileAvgStack = ({ tournamentStats }) => {

    return (
        <div className="item">
            <div className="title">Avg stack</div>
            <div className="text">
                {tournamentStats.avgStack > 0 ? Math.round(tournamentStats.avgStack) : 0}
            </div>
        </div>
    )
}

export default TileAvgStack;