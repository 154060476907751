import React, { useState } from "react";
import { useSelector } from "react-redux";
import restClient from "../../../util/restClient";
import { Button, Row } from "react-bootstrap";
import { Input } from "reactstrap";

const MttDescription = (props) => {
  const { variant } = props;
  const clockSettings = useSelector((state) => state.tournaments.clockSettings);
  const [clockDescription, setClockDescription] = useState(null);

  const currentDescription =
    clockSettings.isLoaded && clockSettings.data ? clockSettings.data.description : null;
  if (clockDescription === null && currentDescription !== null) {
    setClockDescription(currentDescription);
  }

  async function save() {
    const formData = {};
    formData.id = clockSettings.data.id;
    formData.description = clockDescription;

    const res = await restClient.postAsync(`clock/${variant.id}/clock-description`, formData);
    clockSettings.data = { ...clockSettings.data, ...res };
  }

  return (
    <div className="clock-description mt-4 d-none d-md-block">
      <p>Mtt description at clock screen</p>
      <Row>
        <Input
          type="text"
          className="input mr-2"
          maxLength={65}
          value={clockDescription}
          disabled={false}
          onChange={(e) => {
            setClockDescription(e.target.value);
          }}
        />

        <Button
          color="primary"
          disabled={!clockSettings.isLoaded || currentDescription === clockDescription}
          className="confirm-btn fit"
          onClick={async (e) => {
            e.preventDefault();
            await save();
          }}
        >
          Save
        </Button>
      </Row>
    </div>
  );
};

export default MttDescription;
