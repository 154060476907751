import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup, Col, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUsersExceptVisitors } from '../../actions/usersActions';
import StaffCard from './StaffCard';
import ResetPasswordModal from './ResetPasswordModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import AddNewUserModal from './AddNewUserModal';
import Loader from '../shared/Loader';
import currentUserUtil from '../../util/currentUser';
import UserRole from '../../enum/UserRole';

const UserManagement = (props) => {
  const dispatch = useDispatch();
  const DISPLAY = {
    STAFF: 'staff',
    MEDIA: 'mediaReporter',
  };

  const [display, setDisplay] = useState(DISPLAY.STAFF);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const users = useSelector((state) => state.users.users.filter(user => user.type !== "admin" && user.type !== "spectator"));

  const hasWritePermission = () => currentUserUtil.getUserRole() === UserRole.MTTTDIRECTOR;

  const onDeleteUserClick = (user) => {
    setSelectedUser(user);
    setIsConfirmationModalOpen(true);
  };

  const deleteUsers = () => {
    setIsConfirmationModalOpen(false);
    dispatch(deleteUser(selectedUser));
  };

  useEffect(() => {
    getUsers();
  }, []);

  function getUsers() {
    dispatch(getUsersExceptVisitors());
  }

  return (
    <Loader>
      <div className="user-management">
        <Row>
          <Col>
            <h3>User management</h3>
          </Col>
        </Row>
        <Row className="line" />
        <Row noGutters className="tab-container mb-3">
          <Col>
            <ButtonGroup className="btn-switch big">
              <Button
                className="tab-button auto"
                variant="dark"
                active={display === DISPLAY.STAFF}
                onClick={() => setDisplay(DISPLAY.STAFF)}
              >
                Staff
              </Button>
              <Button
                className="tab-button auto"
                variant="dark"
                active={display === DISPLAY.MEDIA}
                onClick={() => setDisplay(DISPLAY.MEDIA)}
              >
                Media reporter
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row style={{ visibility: hasWritePermission() === true ? 'visible' : 'collapse' }}>
          <Col>
            <Button className="fit" onClick={() => setShowAddUserModal(true)}>
              Add account
            </Button>
          </Col>
        </Row>
        <Row className="h-100">
          <Col xs={12}>
            {display === DISPLAY.STAFF && (
              <div className="staff-list">
                {users.map((user) => (
                  <StaffCard
                    hasWritePermission={hasWritePermission()}
                    setSelectedUser={setSelectedUser}
                    user={user}
                    deleteUser={onDeleteUserClick}
                    setShowResetPasswordModal={setShowResetPasswordModal}
                  />
                ))}
              </div>
            )}
            {display === DISPLAY.MEDIA && <div />}
          </Col>
        </Row>
      </div>
      {showAddUserModal && (
        <AddNewUserModal
          open={showAddUserModal}
          setShowAddUserModal={setShowAddUserModal}
          toggle={() => setShowAddUserModal(false)}
        />
      )}
      <ConfirmationModal
        confirmationText={`Are you sure you want to delete ${selectedUser.identifier} account?`}
        buttonText="Yes, Im sure"
        open={isConfirmationModalOpen}
        close={() => setIsConfirmationModalOpen(false)}
        onSubmitButtonClick={() => deleteUsers()}
      />
      {showResetPasswordModal && (
        <ResetPasswordModal
          user={selectedUser}
          open={setShowResetPasswordModal}
          toggle={() => setShowResetPasswordModal(false)}
        />
      )}
    </Loader>
  );
};

export default UserManagement;
