import React, { useEffect, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import CustomModal from '../shared/CustomModal';
import CircleIcon from '../functionalComponents/CircleIcon';
import { getPlayersByStateAndTournamentVariantId } from '../../actions/playerActions';
import useVariant from "../useVariant";
import { formatPlayerName, getPlayerState } from "../../util/playerUtil";
import { formatDate } from "../../util/stringUtil";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import PlayerActionsModal from "../shared/PlayerActionsModal";
import { resolveCurrentLevel } from "../../util/tournamentUtil";

const ListOfPlayersModal = (props) => {

    const dispatch = useDispatch();
    const playersInTournament = useSelector((state) => state.player.playersInTournament.data);

    const [searchText, setSearchText] = useState("");

    const [playersInTable, setPlayersInTable] = useState([]);

    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const [variantWithLevel, setVariantWithLevel] = useState(null);

    const variant = useVariant(props.variant.id) || {};

    const allPlayers = playersInTournament.map((player) => ({
        ...player,
        stateForFE: getPlayerState(player),
    }));

    if (selectedPlayer) {
        const newPlayer = playersInTournament.find(p => p.playerInTournamentPhaseVariantId === selectedPlayer.playerInTournamentPhaseVariantId)
        if (JSON.stringify(newPlayer) !== JSON.stringify(selectedPlayer)) {
            setSelectedPlayer(newPlayer)
        }
    }

    useEffect(() => {
        dispatch(getPlayersByStateAndTournamentVariantId(`registered`, props.variant.id));
    }, []);

    useEffect(() => {
        setVariantWithLevel(resolveCurrentLevel(variant));
    }, [variant]);

    useEffect(() => {
        setPlayersInTable(playersInTournament);
    }, [playersInTournament]);

    const handleFilterChange = text => {
        setSearchText(text);

        var filtered = playersInTournament.filter(pl => {
            return (pl.firstName + " " + pl.lastName).toLowerCase().includes(text.toLowerCase())
        })

        setPlayersInTable(filtered);
    };

    const clickOnMore = (player) => {
        setSelectedPlayer(player);
    }

    const onClosePlayerDetail = () => {
        setSelectedPlayer(null);
    }

    const formatPosition = (position) => {
        if (!position)
            return "";

        switch (position) {
            case 1:
                return '1st';
            case 2:
                return '2nd';
            case 3:
                return '3rd';
            default:
                return `${position}th`;
        }
    }
    return (

        <>
            <CustomModal
                title='List of players'
                open={props.isOpen}
                closeHandler={props.toggle}
                name="cstmmodal"
                id="cstmmodal"
                className="players-list-modal"
                dialogClassName="modal-width"
                text={(
                    <Form.Label>
                        <CircleIcon color={props.variant.color} />
                        {`${props.variant.tournamentName} ${props.variant.tournamentIsMultiday ? `, ${props.variant.name}` : ''
                            }`}
                    </Form.Label>
                )}
            >
                <InputGroup className="mb-3 input-right-icon-control align-self-start">
                    <FormControl
                        id="filter"
                        placeholder="Search player"
                        name="filter"
                        className="input-part"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => handleFilterChange(e.target.value)}
                        value={searchText}
                    />
                    <InputGroup.Text className="icon-part" id="basic-addon1">
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                </InputGroup>
                <div style={{ height: "70%", display: "flex", flexDirection: "column", width: "100%" }}>
                    <div
                        className="players"
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <Row className="no-gutters header">
                            <Col noGutters md={2}>
                                PLAYER
                            </Col>
                            <Col noGutters md={2}>
                                PERSONAL ID
                            </Col>
                            <Col noGutters md={2}>
                                DATE OF BIRTH
                            </Col>
                            <Col noGutters md={2}>
                            </Col>
                        </Row>
                        {playersInTable.map((player, index) => {
                            return (
                                <Row className="no-gutters" key={index}>
                                    <Col noGutters md={2} className="three-dots">
                                        {formatPlayerName(player)}
                                    </Col>
                                    <Col noGutters md={2} className="three-dots">
                                        {player.idCardNum}
                                    </Col>
                                    <Col noGutters md={2}>
                                        {formatDate(player.dateOfBirth)}
                                    </Col>
                                    <Col noGutters>
                                        {player.state === "finished" ? <div>{"Finished " + formatPosition(player.position)}</div> :
                                            <Button color="primary" className="fit" onClick={() => clickOnMore(player)}>
                                                More
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            );
                        })}
                    </div>
                </div>
            </CustomModal>
            <PlayerActionsModal
                isOpen={selectedPlayer !== null}
                player={selectedPlayer}
                toggle={onClosePlayerDetail}
                players={allPlayers}
                variant={variantWithLevel}
                isHidden={true}
            />
        </>
    );
}

export default ListOfPlayersModal;