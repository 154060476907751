const Currency = {
  EUR: 'EUR',
  USD: 'USD'
};

const display = (currency, value) => {
  const cur = currency ? currency.toUpperCase() : '';
  const val = value !== undefined ? value : '';
  switch (cur) {
    case 'EUR':
      return `€ ${val}`;
    case 'USD':
      return `$ ${val}`;
    default:
      return `${val} ${cur}`;
  }
};

export { display };
export default Currency;
