import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-mini";
import {
  getClockSettings,
  cleanClockSetting,
  updateClockSettings
} from "../../actions/tournamentActions";
import { getTournamentPayouts } from "../../actions/tournamentPayoutActions";
import VariantPicker from "../shared/VariantPicker";
import Loader from "../shared/Loader";
import RotatingTables from "./RotatingTables";
import { Col, Row, Button } from "react-bootstrap";
import { toBoolean } from "../../util/stringUtil";
import useVariantStats from "../shared/useVariantStats";
import StartPauseClock from "./directorClock/StartPauseClock";
import AddRemoveMinutes from "./directorClock/AddRemoveMinutes";
import LevelUpDown from "./directorClock/LevelUpDown";
import FinishMtt from "./directorClock/FinishMtt";
import DirectorTimes from "./directorClock/DirectorTimes";
import ManageBackground from "./directorClock/ManageBackground";
import HidePayouts from "./directorClock/HidePayouts";
import MttDescription from "./directorClock/MttDescription";
import ClockInNewTab from "./directorClock/ClockInNewTab";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { history } from "../../service/history";

function Content(props) {
  const { variant } = props;
  const dispatch = useDispatch();
  const clockSettings = useSelector((state) => state.tournaments.clockSettings);

  const [hidePayouts, setHidePayouts] = useState(false);
  const [showBgPreview, setShowBgPreview] = useState(props.showBgPreview || false);
  const [bgPreviewVersion, setBgPreviewVersion] = useState(1);

  const [currentTime, setCurrentTime] = useState(moment());

  const tournamentStats = useVariantStats(variant.id, "VariantClock", variant.fetchDataTime);

  if (clockSettings.isLoaded && toBoolean(clockSettings.data.hide_payouts) !== hidePayouts) {
    setHidePayouts(toBoolean(clockSettings.data.hide_payouts))
  }

  const node = useRef();

  useEffect(() => {
    if (variant && variant.id) {

      if (!clockSettings || (!clockSettings.isLoaded && !clockSettings.isLoading)) {
        dispatch(getClockSettings(variant.id));
      }
      dispatch(getTournamentPayouts(props.tournament.id));
    }
  }, [variant.id, variant.fetchDataTime]);

  useEffect(() => {
    if (props.tournament && props.tournament.id) {
      dispatch(getTournamentPayouts(props.tournament.id));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(cleanClockSetting())
    };
  }, []);

  // to update time each second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [variant.id]);

  if (!variant.id) {
    return <Loader />;
  }

  let isSeating = clockSettings && clockSettings.data && clockSettings.data.seating;

  const closeSeating = () => {
    dispatch(updateClockSettings(variant.id, clockSettings.data.id, false));
  };

  const redirect = (path) => {
    history.push(path);
  };

  return (
    <Loader>
      <div>
        {isSeating && !isMobile && (
          <RotatingTables variant={variant} clockSettings={clockSettings} />
        )}

        {(!isSeating || isMobile) && (
          <div className={`clock director-clock `} >
            <Row>
              <ClockInNewTab variant={variant} tournament={props.tournament} showBgPreview={showBgPreview} />
            </Row>

            <Row>
              <div className="line" />
            </Row>

            <Row>
              <DirectorTimes variant={variant} tournamentStats={tournamentStats} hidePayouts={hidePayouts} />
            </Row>

            <Row>
              <StartPauseClock variant={variant} />
              <AddRemoveMinutes variant={variant} />
              <LevelUpDown variant={variant} />
              <FinishMtt variant={variant} tournament={props.tournament} />
              {clockSettings.displayReseatWin &&
                <div>
                  <Button onClick={closeSeating} className="float-right btn-dark outline fit mt-5">
                    Close reseat window
                    <FontAwesomeIcon icon={faCog} className="btn-icon" />
                  </Button>
                </div>}
            </Row>

            <Row>
              <ManageBackground variant={variant} tournament={props.tournament} setShowBgPreview={setShowBgPreview} showBgPreview={showBgPreview} setBgPreviewVersion={setBgPreviewVersion} />
            </Row>

            <Row>
              <HidePayouts variant={variant} />
            </Row>

            <Row>
              <MttDescription variant={variant} />
            </Row>
          </div>
        )}
      </div>
    </Loader>
  );
}

const DirectorClock = (props) => {
  const tournament = useSelector((state) => state.tournaments.tournament);
  return (
    <VariantPicker
      id={"ManageClock"}
      tournament={tournament}
      className="mb-0"
      displayHeaderForSingleVariantTournament={false}
      getContent={(variant) => {
        return (
          <Content tournament={tournament} hidePayouts={props.hidePayouts} variant={variant} />
        );
      }}
    />
  );
};
export default DirectorClock;
