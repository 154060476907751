import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { login } from "../../actions/commonActions";
import socket from "../../service/EvSocket";
import Logo from "../shared/images/Logo";

const GeneralLogin = (props) => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const userChange = (e) => {
    setIdentifier(e.target.value);
  };

  const pswChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      login(
        {
          identifier,
          password,
        },
        socket
      )
    );
  };
  return (
    <Row className="justify-content-md-center">
      <Col sm={6}>
        <div className="text-center pt-5">
          <Logo/>
          <h1 className="pt-5" size="huge">
            Login
          </h1>
        </div>
        <Form onSubmit={(e) => handleSubmit(e, socket)}>
          <Form.Label>E-mail as login</Form.Label>
          <Form.Control
            placeholder="E-mail as login"
            autoComplete="username"
            value={identifier}
            onChange={userChange}
          />
          <Form.Label>Password</Form.Label>
          <Form.Control
            placeholder="Password"
            type="password"
            autoComplete="password"
            value={password}
            onChange={pswChange}
          />
          <div className="pt-3 text-center">
            <Button type="submit">Login</Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default GeneralLogin;
