export default {
  USER_GET_REQUEST: 'USER_GET_REQUEST',
  USER_GET_RECEIVE: 'USER_GET_RECEIVE',
  FIND_USER_RECEIVE: 'FIND_USER_RECEIVE',
  USER_PUT_REQUEST: 'USER_PUT_REQUEST',
  USER_PUT_RECEIVE: 'USER_PUT_RECEIVE',
  USER_POST_REQUEST: 'USER_POST_REQUEST',
  USER_POST_RECEIVE: 'USER_POST_RECEIVE',
  USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
  USER_DELETE_RECEIVE: 'USER_DELETE_RECEIVE',
  USER_RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
  USER_RESET_PASSWORD_RECEIVE: 'USER_RESET_PASSWORD_RECEIVE',
  USER_UPDATE_PASSWORD_REQUEST: 'USER_UPDATE_PASSWORD_REQUEST',
  USER_UPDATE_PASSWORD_SUCCESS: 'USER_UPDATE_PASSWORD_SUCCESS',
  USER_UPDATE_PASSWORD_CLEAN: 'USER_UPDATE_PASSWORD_CLEAN',
  USER_UPDATE_PASSWORD_ERROR: 'USER_UPDATE_PASSWORD_ERROR',
};
