import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'react-bootstrap';
import {Input} from 'reactstrap';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ToggleSwitch from '../../functionalComponents/ToggleSwitch';

const StructureLevel = ({
                            structure,
                            validationErrors,
                            index,
                            handleChange,
                            addLevel,
                            deleteLevel,
                            deleteBreak,
                            saveBreak,
                            deleteDisabled
                        }) => {
    const {
        level, smallBlind, bigBlind, ante, durationInMin, colorUp, readOnly = false
    } = structure;
    const validations = validationErrors || {};
    const levelHasBreak = !!structure.breakInMin;

    const [newBreakCreated, setBreakCreated] = useState(levelHasBreak);
    const [newBreakSaved, setBreakSaved] = useState(levelHasBreak);
    const [breakInMin, setBreakInMin] = useState(structure.breakInMin);

    const renderBreakAddBtn = useCallback(() => (
        <Button
            className="mr-2"
            variant="outline-primary"
            disabled={readOnly}
            onClick={(e) => {
                setBreakCreated(true);
            }}
        >
            Add break
        </Button>
    ), [readOnly, setBreakCreated]);

    const renderBreakDeleteBtn = useCallback(() => (
        <Button
            className="mr-2 delete-break"
            disabled={readOnly}
            onClick={(e) => {
                deleteBreak(structure);
                setBreakCreated(false);
                setBreakSaved(false);
                setBreakInMin('');
            }}
        >
            Del. break
        </Button>
    ), [readOnly, deleteBreak, setBreakCreated, setBreakSaved, setBreakInMin]);

    const renderNewBreakFrom = useCallback(() => (
        <Row>
            <Col sm={1}/>
            <Col sm={8} className="d-inline-flex pb-4 pt-2 break">
                <Input
                    id="breakInMin"
                    type="number"
                    name="breakInMin"
                    placeholder="Type break length"
                    className="mr-2"
                    disabled={readOnly}
                    onChange={(e) => {
                        setBreakInMin(Math.abs(parseInt(e.target.valueAsNumber)) || '');
                    }}
                    value={breakInMin}
                    autoFocus
                />
                <span className="no-wrap mr-2">Color up</span>
                <ToggleSwitch
                    field="colorUp"
                    disabled={readOnly}
                    switchValue={(field, value) => {
                        handleChange(index, 'colorUp', value);
                    }}
                    isSwitchedOn={colorUp}
                />
                <Button
                    className="mr-2"
                    disabled={readOnly || !breakInMin}
                    onClick={(e) => {
                        saveBreak(structure, breakInMin);
                        setBreakSaved(true);
                    }}
                >
                    Save break
                </Button>
                <Button
                    className="mr-2"
                    variant="outline-primary"
                    disabled={readOnly}
                    onClick={(e) => {
                        setBreakCreated(false);
                        setBreakInMin('');
                    }}
                >
                    Cancel
                </Button>
            </Col>
            <Col sm={3}/>
        </Row>
    ), [setBreakInMin, breakInMin, handleChange, index, colorUp, breakInMin, structure, setBreakSaved, saveBreak, setBreakCreated, setBreakInMin]);

    const renderBreakInfo = useCallback(() => (
        <Row>
            <Col sm={10} className="break-in pb-3">
                BREAK
                {' '}
                {breakInMin}
                {' '}
                min
                {colorUp ? ' - color up' : ''}
            </Col>
        </Row>
    ), [breakInMin, colorUp]);

    let breakElement;
    if (newBreakCreated && !newBreakSaved) {
        breakElement = renderNewBreakFrom();
    } else if (newBreakCreated && newBreakSaved || breakInMin > 0) {
        breakElement = renderBreakInfo();
    }

    return (
        <>
            <Row className="structure-table-content px-3 pb-3">
                <Col sm={1} className="my-auto pl-2">
                    {level}
                </Col>
                <Col sm={2}>
                    <Input
                        id="smallBlind"
                        name="smallBlind"
                        type="number"
                        invalid={validations.smallBlind}
                        disabled={readOnly}
                        onChange={(e) => {
                            if (handleChange) {
                                const newValue = parseInt(e.target.valueAsNumber);
                                handleChange(index, 'smallBlind', newValue);
                            }
                        }}
                        value={smallBlind || ""}
                    />
                </Col>
                <Col sm={2}>
                    <Input
                        id="bigBlind"
                        name="bigBlind"
                        type="number"
                        invalid={validations.bigBlind}
                        disabled={readOnly}
                        onChange={(e) => {
                            handleChange(index, 'bigBlind', parseInt(e.target.valueAsNumber));
                        }}
                        value={bigBlind || ""}
                    />
                </Col>
                <Col sm={2}>
                    <Input
                        id="ante"
                        name="ante"
                        type="number"
                        invalid={validations.ante}
                        disabled={readOnly}
                        onChange={(e) => {
                            handleChange(index, 'ante', parseInt(e.target.valueAsNumber));
                        }}
                        value={ante || ""}
                    />
                </Col>
                <Col sm={2}>
                    <Input
                        id="durationInMin"
                        name="durationInMin"
                        type="number"
                        invalid={validations.durationInMin}
                        disabled={readOnly}
                        onChange={(e) => {
                            const newValue = parseInt(e.target.valueAsNumber);
                            if (newValue > 0) {
                                handleChange(index, 'durationInMin', newValue);
                            } else {
                                handleChange(index, 'durationInMin', '');
                            }
                        }}
                        value={durationInMin || ""}
                    />
                </Col>
                <Col sm={3} className="structure-level-options">
                    <Button
                        className="mr-2"
                        disabled={readOnly}
                        onClick={(e) => {
                            addLevel(index);
                        }}
                    >
                        + Add level
                    </Button>
                    {newBreakSaved ? renderBreakDeleteBtn() : renderBreakAddBtn()}
                    {!deleteDisabled && (
                        <FontAwesomeIcon
                            visibility="visible"
                            className="delete-icon"
                            disabled={readOnly}
                            onClick={(e) => {
                                if (!readOnly) {
                                    deleteLevel(index);
                                }
                            }}
                            icon={faTrash}
                        />
                    )}
                </Col>
            </Row>
            {breakElement}
        </>
    );
};

StructureLevel.propTypes = {
    handleChange: PropTypes.func.isRequired,
    addLevel: PropTypes.func.isRequired,
    deleteLevel: PropTypes.func.isRequired,
    deleteBreak: PropTypes.func.isRequired,
    saveBreak: PropTypes.func.isRequired,
};

export default StructureLevel;
