export default {
  GET_TOURNAMENT_PAYOUT_REQUEST: 'GET_TOURNAMENT_PAYOUT_REQUEST',
  GET_TOURNAMENT_PAYOUT_RESPONSE: 'GET_TOURNAMENT_PAYOUT_RESPONSE',
  GET_TOURNAMENT_PAYOUT_ERROR: 'GET_TOURNAMENT_PAYOUT_ERROR',

  ADD_TOURNAMENT_PAYOUT_ITEM: 'ADD_TOURNAMENT_PAYOUT_ITEM',

  GET_PAYOUT_RECALCULATION_REQUEST: 'GET_PAYOUT_RECALCULATION_REQUEST',
  GET_PAYOUT_RECALCULATION_RESPONSE: 'GET_PAYOUT_RECALCULATION_RESPONSE',
  CLEAR_PAYOUT_RECALCULATION_RESPONSE: 'CLEAR_PAYOUT_RECALCULATION_RESPONSE',
  CLEAR_PAYOUT_RESPONSE: 'CLEAR_PAYOUT_RESPONSE',
};
