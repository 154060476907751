import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import {
  getSeatingPlan, cleanSeatingPlan
} from '../../../actions/tablesActions';

const Overview = (props) => {

  const dispatch = useDispatch();
  const seatingPlan = useSelector((state) => state.table.seatingPlan);

  useEffect(() => {
    const seatingObject = {
      "numOfTables": props.selectedTables.length,
      "tableMaxOccupancy": props.maxPlayersAtTable,
      "players": props.playersForReseat
    }

    dispatch(getSeatingPlan(seatingObject));

    return () => {
      dispatch(cleanSeatingPlan());
    };

  }, []);

  useEffect(() => {
    if (seatingPlan && seatingPlan.data && seatingPlan.isLoaded) {
      const seatPlan = seatingPlan.data.map((p, index) => { return { ...p, "table": props.selectedTables[index] } });
      props.setFinalSeatingPlan(seatPlan);
    }
  }, [seatingPlan.isLoading, seatingPlan.isLoaded]);

  const generateRandom = (length, exclude) => {
    let random;
    while (!random) {
      const x = Math.floor(Math.random() * (length + 1));
      if (x !== exclude + 1) random = x;
    }

    return random - 1;
  }

  const swapPlayer = (player, finalseatingIndex, seatIndex) => {
    const fromTableIndex = finalseatingIndex;
    const newTableIndex = generateRandom(props.finalSeatingPlan.length, fromTableIndex);
    const playerFromNewTableIndex = Math.floor(Math.random() * props.finalSeatingPlan[fromTableIndex].players.length);
    const newPlayer = props.finalSeatingPlan[newTableIndex].players[playerFromNewTableIndex];

    const newPlayerNewSeatNum = newPlayer.newSeatNum
    const playerNewSeatNum = player.newSeatNum

    player.newSeatNum = newPlayerNewSeatNum
    newPlayer.newSeatNum = playerNewSeatNum

    const finalSeatingPlanCopy = props.finalSeatingPlan.map(item => { return {...item}})

    finalSeatingPlanCopy[newTableIndex].players[playerFromNewTableIndex] = player
    finalSeatingPlanCopy[finalseatingIndex].players[seatIndex] = newPlayer

    props.setFinalSeatingPlan(finalSeatingPlanCopy);
  }

  return (
    <div>
      <h3>Overview</h3>
      <Row className="graySeparator" />
      {props.finalSeatingPlan.length > 0 && props.selectedTables.map((table, index) => {
        return <SeatingTable
            swapPlayer={swapPlayer}
            tableMaxOccupancy={props.tableSize}
            tournament={props.tournament}
            table={table}
            players={props.finalSeatingPlan[index] ? props.finalSeatingPlan[index].players : []}
            finalseatingIndex={index}/>
      })
      }
    </div>
  );
};

const SeatingTable = (props) => {

  const getVariantName = (id) => {
    const t = props.tournament.tournamentPhases.find(t => t.variants.find(v => v.id === id));
    return t.variants.find(v => v.id === id).name;
  }

  const getAvgChipCount = () => {
    return Math.round((props.players.reduce((previousValue, currentValue) => previousValue + currentValue.stack, 0)) / props.players.length);
  }

  return (
    <div className="seating-table">
      <Row >
        <Col className="table-name">{`Table ${props.table.tableName}`}</Col>
        <Col>{`${props.players.length}/${props.tableMaxOccupancy} players`}</Col>
        <Col>{`Avg. chipcount of table: ${getAvgChipCount()}`}</Col>
      </Row>

      {props.players.map((player, index) => {
        return (<>
          <Row className="graySeparator" />
          <Row className="player">
            <Col><><span className="mr-3">{player.newSeatNum}</span><span>{player.firstName + " " + player.lastName}</span><span className="gray-label ml-4">{getVariantName(player.tournamentPhaseVariantId)}</span></></Col>
            <Col>{player.stack}</Col>
            <Col><Button className="swap" onClick={() => props.swapPlayer(player, props.finalseatingIndex, index)}>Swap player</Button></Col>
          </Row></>)
      })}
    </div>
  );
}

export default Overview;
