import cookie from 'react-cookies';

const isLoggedIn = () => cookie.load('evplus-islogin') === 'true';

const getUserIdentifier = () => {
  if (isLoggedIn()) {
    return localStorage.getItem('currentUserIdentifier');
  }
  return undefined;
};
const getUserRole = () => {
  if (isLoggedIn()) {
    return localStorage.getItem('currentUserRole');
  }
  return undefined;
};

const getUserAlias = () => {
  if (isLoggedIn()) {
    return localStorage.getItem('currentUserAlias');
  }
  return undefined;
};

export default {
  isLoggedIn,
  getUserIdentifier,
  getUserRole,
  getUserAlias,
};
