import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment-mini";
import HandImage from "../../../image/icon-hand.svg";
import LockImage from "../../../image/icon-lock.svg";
import UnlockImage from "../../../image/icon-unlock.svg";
import restClient from '../../../util/restClient';
import { isMobile } from 'react-device-detect';

const TableCard = (props) => {
    const [seatsData, setSeatsData] = useState([]);
    const [seatingLocked, setSeatingLocked] = useState(props.table.seatingLocked);

    useEffect(() => {
        if (props.table.state === "open") {
            restClient.getAsyncWrap(`/tournament/table/${props.table.id}/seats`, seatsData, setSeatsData)
        }
    }, [props.table.id, props.forceReload])

    const seats = seatsData.data || []
    const tableSize = seats.filter(seat => seat.active).length
    const occupiedSeats = seats.filter(seat => seat.playerId !== null)

    const setLockUnlockState = (e) => {
        e.preventDefault();
        props.lockUnlockChangeHandler({
            table: {
                ...props.table,
                seatingLocked: !seatingLocked,
            },
        });
        props.lockUnlockEventHandler();

        setSeatingLocked(!seatingLocked);
    }

    const getText = (table) => {
        if (props.table.hideText) {
            return "";
        }
        const texts = [];
        if (table.state === "open") {
            if (props.table.allInCall) {
                texts.push(
                    <Col xs="12" className="list-card-opened-title list-card-title">
                        All in / Call
                    </Col>
                );
            }
            if (props.variant.h4h && props.h4hFinished) {
                texts.push(
                    <Col xs="12" className="list-card-opened-title list-card-title-green">
                        Hand finished
                    </Col>
                );
            } else if (props.variant.h4h) {
                texts.push(
                    <Col xs="12" className="list-card-opened-title list-card-title-purple">
                        Hand in progress
                    </Col>
                );
            } else {
                texts.push(
                    <Col xs="12" key={table.id} className="list-card-opened-title list-card-title-green">
                        Running
                    </Col>
                );
            }
            if (table.seatingLocked) {
                texts.push(
                    <Col xs="12" className="list-card-opened-title">
                        SEATING IS LOCKED
                    </Col>
                );
            } else {
                texts.push(
                    <Col xs="12" key={`locked-${table.id}`} className="list-card-opened-title">
                        SEATING IS UNLOCKED
                    </Col>
                );
            }
        } else if (props.table.usedByVariants > 0) {
            texts.push(
                <Col xs="12" className="list-card-opened-title list-card-title">
                    Reserved for another MTT
                </Col>
            );
        }
        if (props.reseat) {
            texts.push(
                <Col className="list-card-opened-title d-none d-md-block">
                    <Button onClick={() => props.onClickEvent(table)}>Choose seat</Button>
                </Col>
            );
        }
        return <>{texts}</>;
    }

    let clickMe;
    if (props.isLockUnlock) {
        clickMe = (e) => {
            setLockUnlockState(e);
        };
    }
    if (props.table.state === "closed") {
        clickMe = props.onClick;
    } else {
        if (props.reseat) {
            clickMe = () => {
                props.onClickEvent(props.table);
            };
        }
        if (props.showMoveHere) {
            clickMe = props.onMoveHere;
        }
    }

    let cardClassName = `${props.table.state !== "closed" ? "list-card" : "list-card-closed"} ${seatingLocked && props.isLockUnlock ? " seating-locked" : ""
        }`;

    if (clickMe) {
        cardClassName += " pointer";
    }

    cardClassName += ` ${props.className}`;

    const cardBody = (
        <>
            <div>
                <Card className={cardClassName}>
                    <div onClick={clickMe} id="abcd">
                        <Card.Text as="div" className="p-2">
                            <Row className="no-wrap">
                                <div
                                    xs={7} className="list-card-dots">
                                    {props.table.state !== "closed" &&
                                        seats.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`table-seat-dot ${item.playerId ? "table-seat-dot-active" : ""} ${!item.active ? "table-seat-dot-closed" : ""}`}
                                            />
                                        ))}
                                </div>
                                <div className="list-card-id-text">{props.table.tableName}</div>
                            </Row>
                            {props.table.state === "closed" ? (
                                <>
                                    {!props.isLockUnlock ? (
                                        <div onClick={props.onClick} className="pb-3">
                                            <Row>
                                                <Col className="list-card-closed-title">Closed</Col>
                                            </Row>
                                            <Row>
                                                <Col className="list-card-closedAt-title">
                                                    {props.table.closedAt && `at ${moment(props.table.closedAt).format("HH:mm")}`}
                                                </Col>
                                            </Row>
                                            {props.table.usedByVariants === 1 && (
                                                <Row>
                                                    <Col className="list-card-message">Reserved for another MTT</Col>
                                                </Row>
                                            )}
                                            {props.table.closedTableChipCounts && (
                                                <Row>
                                                    <Col className="list-card-message">Chip counts confirmed</Col>
                                                </Row>
                                            )}
                                        </div>
                                    ) : (
                                        <div onClick={props.onClick}>
                                            <Row>
                                                <Col className="list-card-closed-title">Closed</Col>
                                            </Row>
                                            <Row>
                                                <Col className="list-card-closedAt-title">
                                                    {props.table.closedAt && `at ${moment(props.table.closedAt).format("HH:mm")}`}
                                                </Col>
                                            </Row>
                                            {props.table.usedByVariants === 1 && (
                                                <Row>
                                                    <Col className="list-card-message">Reserved for another MTT</Col>
                                                </Row>
                                            )}
                                            {props.table.closedTableChipCounts && (
                                                <Row>
                                                    <Col className="list-card-message">Chip counts confirmed</Col>
                                                </Row>
                                            )}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div>
                                    <Row className="marg-min30">
                                        <Col className="list-card-players-text">{`${occupiedSeats ? occupiedSeats.length : 0
                                            }/${tableSize !== undefined ? tableSize : '-'} players`}</Col>
                                    </Row>
                                    {!props.isLockUnlock ? (
                                        <Row className="marg-min30">
                                            {getText(props.table)}
                                            {!!props.table.h4h &&
                                                <img alt="H4H" src={HandImage} className="list-card-hand-icon" />}
                                        </Row>
                                    ) : (
                                        <Row className="marg-min30">
                                            <Col>
                                                <div>
                                                    <p className="mt-2 lock-label">
                                                        {seatingLocked
                                                            ? "SEATING IS LOCKED"
                                                            : "SEATING IS UNLOCKED"}
                                                    </p>
                                                    <Button className="lock-btn" variant="dark">
                                                        <div>
                                                            <img
                                                                alt="Lock"
                                                                src={seatingLocked ? UnlockImage : LockImage}
                                                                className={`list-card-lock-icon ${seatingLocked ? "locked" : ""
                                                                    }`}
                                                            />
                                                            <div className="list-card-lock-state">
                                                                {seatingLocked ? "Unlock" : "Lock"}
                                                            </div>
                                                        </div>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            )}
                            {props.showChooseSeat && (
                                <Button
                                    className="btt-link"
                                    onClick={() => {
                                        props.onChooseSeat();
                                    }}
                                >
                                    choose seat
                                </Button>
                            )}
                            {props.showMoveHere && (
                                <Button
                                    className="btt-link"
                                    onClick={() => {
                                        props.onMoveHere();
                                    }}
                                >
                                    Move here
                                </Button>
                            )}
                        </Card.Text>
                    </div>
                </Card>
            </div>

        </>
    );

    if (props.table.state === "closed") {
        return (
            <>
                <div key={`col_${props.table.id}`} onClick={props.onClickEvent} className="table-card" role="button" tabIndex={0}>
                    {cardBody}
                </div>
            </>
        );
    }

    if (!props.isLockUnlock && props.link) {
        return (
            <>
                <div key={`${props.table.id}`} className="table-card">
                    <Link to={props.link} onClick={props.onClickEvent}>
                        {cardBody}
                    </Link>
                </div>
            </>
        );
    }
    return (
        <>
            <div key={props.table.id} className="table-card">
                {cardBody}
            </div >
        </>
    );
}

export default TableCard;
