import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import CustomModal from '../../shared/CustomModal';

const SetNoImtPlayerModal = (props) => {
  const [num, setNum] = useState(0);
  useEffect(() => {
    setNum(props.noImtPlayers);
  }, [props.noImtPlayers]);
  return (
    <CustomModal open={props.isOpen} closeHandler={props.toggle} className="action-confirm-modal" dialogClassName="modal-small-width">
      <>
        <h3>Set NO. IMT players</h3>
        <div>
          <Input
            id="noImtPlayerModal"
            placeholder="Type No.ITM players"
            name="firstName"
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              if (Number.isInteger(parsed)) {
                setNum(parsed);
              } else {
                setNum(0);
              }
            }}
            value={num}
          />
          <Button
            color="primary"
            className="confirm-btn"
            onClick={() => {
              props.saveNoImtPlayerModal(num);
            }}
          >
            Save
          </Button>
        </div>
      </>
    </CustomModal>
  );
};

export default SetNoImtPlayerModal;
