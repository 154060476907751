import React, { useCallback, useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { connect } from "react-redux";
import {
  addonPlayerInVariant,
  insertPlayer,
  rebuyPlayerInVariant,
  updatePlayerInGame,
  bonusPlayerInVariant,
} from "../../actions/playerActions";
import useFreeSeat from "../useFreeSeat";
import CustomModal from "./CustomModal";
import Loader from "./Loader";
import { ReactComponent as Rebuy } from "../../image/icon-rebuy.svg";
import PlayerActionConfirmationModal from "./PlayerActionConfirmationModal";
import SwapSeatsButton from "./playerActionButtons/SwapSeatsButton";
import UnregPlayerButton from "./playerActionButtons/UnregPlayerButton";
import DQButton from "./playerActionButtons/DQButton";
import ReseatButton from "./playerActionButtons/ReseatButton";
import WaitingListButton from "./playerActionButtons/WaitingListButton";
import ActionLogsButton from "./playerActionButtons/ActionLogsButton";
import { formatDate } from "../../util/stringUtil";
import { FormCheck } from "react-bootstrap";
import PlusMinus from "../functionalComponents/PlusMinus";
import ConfirmationModal from "../modals/ConfirmationModal";
import SeatingByFloormanButton from "./playerActionButtons/SeatingByFloormanButton";

const ReentryModalContent = (props) => {
  const player = props.player;
  const variant = props.variant;

  const [bonusCount, setBonusCount] = useState(0);
  const [addonCount, setAddonCount] = useState(0);
  const [rebuyCount, setRebuyCount] = useState(0);
  const [keepSeat, setKeepSeat] = useState(false);
  const freeSeatData = useFreeSeat(variant.id);
  const getTotalChips = useCallback(() => {
    let totalChips = variant.reEntryChips;
    if (variant.addon) {
      totalChips += addonCount * variant.addonChips;
    }
    if (variant.bonus) {
      totalChips += bonusCount * variant.bonusChips;
    }
    if (variant.reBuy) {
      totalChips += rebuyCount * variant.reBuyChips;
    }
    return totalChips;
  }, [variant.id, addonCount, bonusCount, rebuyCount]);

  const getTotalToPay = useCallback(() => {
    let totalPay = variant.reEntryFee + variant.reEntryPool;
    if (variant.addon) {
      totalPay += addonCount * (variant.addonFee + variant.addonPool);
    }
    if (variant.reBuy) {
      totalPay += rebuyCount * (variant.reBuyFee + variant.reBuyPool);
    }
    return totalPay;
  }, [variant.id, addonCount, rebuyCount]);

  const totalChips = getTotalChips();
  const totalToPay = getTotalToPay();

  return (
    <>
      <Row className="mb-5">
        <Col>
          <p className="mb-3">Seat open and make re-entry</p>

          <p className="mb-1">Additional actions:</p>

          <div className="mb-2">
            <PlusMinus
              id="bonus"
              onChange={(e) => {
                const value = e.target.value;
                setBonusCount(value);
              }}
              name="bonus"
              max={variant.bonusMaxPerPlayer}
              disabled={!variant.bonus}
            />
            <span className="ml-2">Bonus chips</span>
          </div>

          <div className="mb-2">
            <PlusMinus
              id="addon"
              onChange={(e) => {
                const value = e.target.value;
                setAddonCount(value);
              }}
              name="addon"
              max={variant.addonMaxPerPlayer}
              disabled={!variant.addon || (variant.currentLevel.level >= variant.addonEndAtLevel)}
            />
            <span className="ml-2">Addon</span>
          </div>

          <div className="mb-2">
            <PlusMinus
              id="reBuy"
              onChange={(e) => {
                const value = e.target.value;
                setRebuyCount(value);
              }}
              name="reBuy"
              max={variant.reBuyMaxPerPlayer}
              disabled={!variant.reBuy || (variant.currentLevel.level >= variant.reBuyEndAtLevel)}
            />
            <span className="ml-2">Rebuy</span>
          </div>

          <div className="mb-2">
            <p className="mb-0">Total:</p>
            <p>{`${totalToPay} € / ${totalChips} chips`}</p>
          </div>

          <div className="mb-2">
            <p className="mb-0">Seat:</p>
            {keepSeat
              ? `${player.tableName} / ${player.seatNum}`
              : freeSeatData.isLoading
                ? "Loading..."
                : `${freeSeatData.data.tableName} / ${freeSeatData.data.seat}`}
          </div>

          <div className="mb-2">
            <FormCheck
              name="paymentMethod"
              type="checkbox"
              id="custom-checkbox8"
              label="Keep previous seat if available"
              value={keepSeat}
              onChange={() => {
                setKeepSeat(!keepSeat);
              }}
            />
          </div>

          <div className="mb-2">
            <Button
              color="primary"
              className="chipcount-btn"
              onClick={() => {
                const tableId = keepSeat ? null : freeSeatData.data.tableId;
                const seat = keepSeat ? null : freeSeatData.data.seat;
                props
                  .insertPlayer({
                    playerId: props.player.playerId,
                    playerInVariantIdForClose: props.player.playerInTournamentPhaseVariantId,
                    tournamentPhaseVariantId: props.variant.id,
                    firstName: props.player.firstName,
                    lastName: props.player.lastName,
                    idCardNum: props.player.idCardNum,
                    membershipCardNum: props.player.membershipCardNum,
                    email: props.player.email,
                    nationality: props.player.nationality,
                    dateOfBirth: props.player.dateOfBirth,
                    reBuy: rebuyCount,
                    addon: addonCount,
                    bonus: bonusCount,
                    reEntry: true,
                    keepSameSeatAsBefore: keepSeat,
                    tableId,
                    seat,
                  })
                  .then(() => {
                    props.onProcessHandler();
                    props.toggleParent();
                  });
              }}
            >
              Confirm re-entry
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

class PlayerActionsModal extends React.Component {
  constructor() {
    super();
    this.state = {
      confirmModal: false,
      action: undefined,
      chipCount: null,
      hideMe: false,
      reEntryContent: false,
      confirmReentryModal: false,
    };
  }

  actions = {
    REBUY: "Rebuy",
    ADDON: "Addon",
    REENTRY: "Reentry",
    BONUS: "Bonus"
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSaveChipsClick = () => {
    this.props.updatePlayerInGame(
      this.props.player.playerInTournamentPhaseVariantId,
      undefined,
      undefined,
      this.state.chipCount
    );
  };

  toggleConfirmModal = (actionType) => {
    if (actionType !== this.actions.REBUY && actionType !== this.actions.ADDON && actionType !== this.actions.BONUS) {
      this.setState({ confirmModal: !this.state.confirmModal });
    } else {
      this.setState({
        confirmModal: !this.state.confirmModal,
        action: actionType,
      });
    }
  };

  addonOrRebuyAction = () => {
    if (this.state.action === this.actions.REBUY) {
      this.props.rebuyPlayerInVariant(
        this.props.player.tournamentPhaseVariantId,
        this.props.player.playerInTournamentPhaseVariantId
      );
    } else if (this.state.action === this.actions.ADDON) {
      this.props.addonPlayerInVariant(
        this.props.player.tournamentPhaseVariantId,
        this.props.player.playerInTournamentPhaseVariantId
      );
    } else if (this.state.action === this.actions.BONUS) {
      this.props.bonusPlayerInVariant(
        this.props.player.tournamentPhaseVariantId,
        this.props.player.playerInTournamentPhaseVariantId
      );
    }
    this.setState({ confirmModal: !this.state.confirmModal });
  };

  togleMe = () => {
    this.setState({
      hideMe: !this.state.hideMe,
    });
  };

  disableAddOn = () => {
    if (!this.props.variant.addon) return true;
    else if (this.props.variant.addonEndAtLevel < this.props.variant.currentLevel.level)
      return true;
    else return this.props.player.addonCount >= this.props.variant.addonMaxPerPlayer;
  };

  disableReEntry = () => {
    if (!this.props.variant.reEntry) return true;
    else if (this.props.variant.registrationCloseAtLevel < this.props.variant.currentLevel.level)
      return true;
    else return this.props.player.reEntryCount >= this.props.variant.reEntryMaxPerPlayer;
  };

  disableRebuy = () => {
    if (!this.props.variant.reBuy) return true;
    else if (this.props.variant.reBuyEndAtLevel < this.props.variant.currentLevel.level)
      return true;
    else return this.props.player.rebuyCount >= this.props.variant.reBuyMaxPerPlayer;
  };

  disableBonus = () => {
    if (!this.props.variant.bonus) return true;
    else return this.props.player.bonusCount >= this.props.variant.bonusMaxPerPlayer;
  }

  render() {
    return (
      <>
        {this.props.isOpen && (
          <CustomModal
            open={true}
            closeHandler={this.props.toggle}
            className="player-actions-modal"
            dialogClassName={`modal-small-width ${this.state.hideMe ? "hidden" : ""}`}
            title={`${this.props.player.firstName} ${this.props.player.lastName}`}
          >
            <Loader>
              {!this.state.reEntryContent && (
                <>
                  <div>
                    <table className="table table-borderless modal-table">
                      <thead>
                        <tr>
                          <th>Nationality</th>
                          <th>System ID</th>
                          <th>Date of birth</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.props.player.nationality}</td>
                          <td>{this.props.player.playerId}</td>
                          <td>{formatDate(this.props.player.dateOfBirth)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Row className="rebuy-addon-row">
                      <div className="rebuy-addon">
                        <Rebuy className="rebuy-icon" />
                        <div className="sub-text">
                          <a>Rebuy</a>
                          <a className="sub">{`${this.props.player.rebuyCount}x`}</a>
                        </div>
                        <Button
                          disabled={this.disableRebuy()}
                          onClick={() => {
                            this.toggleConfirmModal(this.actions.REBUY);
                          }}
                        >
                          Make rebuy
                        </Button>
                      </div>
                    </Row>
                    <Row className="rebuy-addon-row">
                      <div className="rebuy-addon">
                        <Rebuy className="rebuy-icon" />
                        <div className="sub-text">
                          <a>Add-on</a>
                          <a className="sub">{`${this.props.player.addonCount}x`}</a>
                        </div>
                        <Button
                          disabled={this.disableAddOn()}
                          onClick={() => {
                            this.toggleConfirmModal(this.actions.ADDON);
                          }}
                        >
                          Make add-on
                        </Button>
                      </div>
                    </Row>
                    {!this.props.isHidden && <Row className="rebuy-addon-row">
                      <div className="rebuy-addon">
                        <Rebuy className="rebuy-icon" />
                        <div className="sub-text">
                          <a>Re-entry</a>
                          <a className="sub">{`${this.props.player.reEntryCount ? this.props.player.reEntryCount : 0
                            }x`}</a>
                        </div>
                        <Button
                          disabled={this.disableReEntry()}
                          onClick={() => {
                            this.setState({ reEntryContent: true });
                          }}
                        >
                          Make Re-entry
                        </Button>
                      </div>
                    </Row>}
                    <Row className="rebuy-addon-row">
                      <div className="rebuy-addon">
                        <Rebuy className="rebuy-icon" />
                        <div className="sub-text">
                          <a>Bonus</a>
                          <a className="sub">{`${this.props.player.bonusCount ? this.props.player.bonusCount : 0
                            }x`}</a>
                        </div>
                        <Button
                          disabled={this.disableBonus()}
                          onClick={() => {
                            this.toggleConfirmModal(this.actions.BONUS);
                          }}
                        >
                          Make Bonus
                        </Button>
                      </div>
                    </Row>
                    <Row className="graySeparator" />
                    {!this.props.isHidden &&
                      <><Row>Chip count</Row>
                        <Row>
                          <Col>
                            <Input
                              className="chipcount"
                              name="chipCount"
                              placeholder="Type chipcounts"
                              onChange={this.handleChange}
                              value={
                                this.state.chipCount !== null
                                  ? this.state.chipCount
                                  : this.props.player.stack
                              }
                            />
                          </Col>
                          <Col>
                            <Button
                              color="primary"
                              className="chipcount-btn"
                              onClick={this.onSaveChipsClick}
                            >
                              Save chipcount
                            </Button>
                          </Col>
                        </Row></>}
                    <Row className="action-row">
                      {!this.props.isHidden && <> <SwapSeatsButton
                        player={this.props.player}
                        players={this.props.players}
                        variant={this.props.variant}
                        toggleParent={this.togleMe}
                        onAction={this.props.toggle}
                      />
                        <UnregPlayerButton
                          player={this.props.player}
                          toggleParent={this.togleMe}
                          onAction={this.props.toggle}
                        />
                        <DQButton
                          player={this.props.player}
                          toggleParent={this.togleMe}
                          onAction={this.props.toggle}
                        />
                        <ReseatButton
                          player={this.props.player}
                          variant={this.props.variant}
                          toggleParent={this.togleMe}
                          onAction={this.props.toggle}
                        />
                      </>}
                      <ActionLogsButton
                        player={this.props.player}
                        toggleParent={this.togleMe}
                        onAction={this.props.toggle}
                      />
                      {!this.props.isHidden && <SeatingByFloormanButton
                        player={this.props.player}
                        toggleParent={this.togleMe}
                        onAction={this.props.toggle}
                      />}
                    </Row>
                  </div>
                </>
              )}
              {this.state.reEntryContent && (
                <>
                  <ReentryModalContent
                    player={this.props.player}
                    variant={this.props.variant}
                    insertPlayer={this.props.insertPlayer}
                    toggleParent={this.togleMe}
                    disableAddon={this.disableAddOn}
                    disableBonus={this.disableBonus}
                    disableRebuy={this.disableRebuy}
                    onProcessHandler={() => {
                      this.setState({ reEntryContent: false, confirmReentryModal: true });
                    }}
                  />
                </>
              )}
            </Loader>
          </CustomModal>
        )}
        {this.state.confirmModal && (
          <PlayerActionConfirmationModal
            icon={<Rebuy className="modal-action-icon" />}
            isOpen={this.state.confirmModal}
            toggle={this.toggleConfirmModal}
            action={this.state.action}
            doAction={this.addonOrRebuyAction}
            text={`Are you sure you want to make a  ${this.state.action} for ${this.props.player.firstName} ${this.props.player.lastName}`}
          />
        )}

        {this.state.confirmReentryModal && (
          <ConfirmationModal
            confirmationText={"Reentry confirmed!"}
            buttonText="Ok"
            open={this.state.confirmReentryModal}
            close={() => { }}
            onSubmitButtonClick={() => {
              this.setState({ confirmReentryModal: false });
              this.props.toggle();
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payouts: state.player.payouts,
  };
};

const mapDispatchToProps = {
  updatePlayerInGame,
  rebuyPlayerInVariant,
  addonPlayerInVariant,
  bonusPlayerInVariant,
  insertPlayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerActionsModal);
