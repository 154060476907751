import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSquare } from "@fortawesome/free-solid-svg-icons";
import RoomSpaceGrid from "../functionalComponents/RoomSpaceGrid";
import TableOccupancyState from "../../enum/TableOccupancyState";
import GridLegend from "./GridLegend";
import { setTableStyle } from "../../util/tournamentUtil";

const TablesV2 = (props) => {
  const [activeRoom, setActiveRoom] = useState({});

  useEffect(() => {
    if (props.rooms && props.rooms.length >= 1) {
      setActiveRoom(props.rooms[0]);
    }
  }, [props.rooms.length]);

  const onRoomButtonClick = (room) => {
    if (activeRoom.id === room.id) return;
    setActiveRoom(room);
  };

  const isTableDefined = (table) => table.id > 0;

  const isOccupiedTable = (table) => table.tournamentVariant;

  const isOccupiedTableByThisVariant = (table) =>
    props.variantId && table.tournamentVariant && table.tournamentVariant.id === props.variantId;

  function setTableStyleWrap(table, conflictTables) {
    if (props.setTableStyle) {
      return props.setTableStyle(table, props.selectedTables, props.variantId, conflictTables);
    } else {
      return setTableStyle(table, props.selectedTables, props.variantId, conflictTables);
    }
  }

  const onTableClick = (table, i, j) => {
    if (props.onTableClick) {
      let state;
      if (!isTableDefined(table)) {
        state = TableOccupancyState.NO_TABLE;
      } else if (isOccupiedTableByThisVariant(table)) {
        state = TableOccupancyState.CURRENT_VARIANT_TABLE;
      } else if (isOccupiedTable(table)) {
        state = TableOccupancyState.VARIANT_TABLE;
      } else {
        state = TableOccupancyState.EMPTY_TABLE;
      }
      props.onTableClick(table, state, i, j);
    }
  };

  const roomButtons = (props.rooms || []).map((room) => (
    <input
      type="button"
      className={room.id === activeRoom.id ? "border-line-bottom-active" : "border-line-bottom"}
      onClick={() => onRoomButtonClick(room)}
      value={room.roomName}
      key={room.id}
    />
  ));

  return (
    <>
      {props.title && (
        <Row>
          <Col>
            <h2>{props.title}</h2>
          </Col>
          <div className="line" />
        </Row>
      )}
      <Row>{roomButtons}</Row>
      <Row>
        <Col xs={12} md={10}>
          <RoomSpaceGrid
            tables={activeRoom.tables}
            onTableClick={onTableClick}
            spaceGridType="spaceGrid"
            tableStyle={setTableStyleWrap}
          />
        </Col>
        <Col md={2} className="info-text d-none d-md-block" style={{ textAlign: "left" }}>
          <GridLegend />
          <FontAwesomeIcon className="info-icon" icon={faInfoCircle} />
          -click on field to add or remove table
        </Col>
      </Row>
    </>
  );
};

export default TablesV2;
