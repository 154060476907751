import React, { useEffect, useState } from "react";
import TemplateImage from "../../image/template.png";

import { Col, Row } from "react-bootstrap";
import { Button, Input, Label } from "reactstrap";
import CustomModal from "../shared/CustomModal";

const SaveStructureTemplate = (props) => {
  const [templateName, setTemplateName] = useState("");
  return (
    <CustomModal
      title="Save as a template"
      text="How would you like to save this structure template?"
      open={props.openModal}
      className="save-tournament-modal"
      dialogClassName="modal-small-width"
      closeHandler={props.closeModal}
      imagesrc={TemplateImage}
      height="145px"
    >
      <Col className="content">
        <Label for="templateName">Template name</Label>
        <Input
          id="templateName"
          placeholder="Type MTT template name"
          name="templateName"
          onChange={(e) => setTemplateName(e.target.value)}
          value={templateName}
        />

        <Button
          disabled={templateName === ""}
          color="primary mt-3"
          onClick={() => {
            props.saveTemplate(templateName);
            props.closeModal();
          }}
        >
          Save template
        </Button>
      </Col>
    </CustomModal>
  );
};

export default SaveStructureTemplate;
