import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ScrollContainer from 'react-indiana-drag-scroll';
import PokerTable from '../mttdirector/roomLayout/PokerTable';

export default function ExpansibleSpaceGrid(props) {
  const [gridTables, setGridTables] = useState([]);

  useEffect(() => {
    setGridTables(getPokerTables(props.grid));
  }, [props.grid, props.grid.length]);

  const getPokerTables = (grid) => {
    if (grid) {
      return grid.map((r, i) => (
        <tr key={`row_${i}`}>
          {r.map((d, j) => (
            <PokerTable
              key={i + j}
              {...props}
              i={i}
              j={j}
              table={grid[i][j]}
            />
          ))}
        </tr>
      ));
    }
  };

  return (
    <>
      <Col sm="auto">
        <Row>
          <ScrollContainer
            className="scrollable-space"
            vertical
            horizontal
          >
            <span className="tooltiptext">&harr; Drag and move with mouse</span>
            <table id="table">
              <tbody>
                {gridTables}
              </tbody>
            </table>
          </ScrollContainer>
        </Row>
        <Row>
          <div>
            <input
              type="button"
              id="addx"
              value="+"
              className="poker-table"
              onClick={() => props.expandRoomSpace('x')}
            />
            <label htmlFor="addx" />
          </div>
          <div>
            <input
              type="button"
              id="removex"
              value="-"
              className="poker-table"
              onClick={() => props.shrinkRoomSpace('x')}
            />
            <label htmlFor="removex" />
          </div>
        </Row>
      </Col>
      <Col sm="auto">
        <div>
          <input
            type="button"
            id="addy"
            value="+"
            className="poker-table"
            onClick={() => props.expandRoomSpace('y')}
          />
          <label htmlFor="addy" />
        </div>
        <div>
          <input
            type="button"
            id="removey"
            value="-"
            className="poker-table"
            onClick={() => props.shrinkRoomSpace('y')}
          />
          <label htmlFor="removey" />
        </div>
      </Col>
    </>
  );
}

ExpansibleSpaceGrid.defaultProps = {
  grid: [],
};
