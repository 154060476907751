import React from "react";
import { connect } from "react-redux";
import { getVariants, getFinishedVariants, cleanFinishedTournaments, notifyVariantUpdate, notifyNewTournamentUpdate, notifyTournamentUpdate } from "../../actions/tournamentActions";
import socket from "../../service/EvSocket";

class _getVariantDataWrapper extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.activeVariants !== nextProps.activeVariants) {
      this.fetchVariantDetailAndRegisterChangeHandler(nextProps.activeVariants);
    }
  }

  componentWillUnmount = () => {
    socket.removeHandler("updateTournamentVariant", "GetVariantDataWrapper");
    socket.removeHandler("newTournament", "GetVariantDataWrapper");
    socket.removeHandler("updateTournament", "GetTournamentDataWrapper");
    this.props.cleanFinishedTournaments();
  };

  componentDidMount() {
    this.fetchVariantDetailAndRegisterChangeHandler(this.props.activeVariants);
    socket.on("updateTournamentVariant", "GetVariantDataWrapper", (payload, dispatch) => {    
      this.props.notifyVariantUpdate(payload);
    });

    socket.on("newTournament", "GetVariantDataWrapper", (payload, dispatch) => {     
      this.props.notifyNewTournamentUpdate(payload);
    });

    socket.on("updateTournament", "GetTournamentDataWrapper", (payload, dispatch) => {    
        this.props.notifyTournamentUpdate(payload);
    });
  }

  fetchVariantDetailAndRegisterChangeHandler = activeVariants => {    
    if (activeVariants) {
      this.props.getVariants();
    } else {
      this.props.getFinishedVariants();
    }
  };

  render() {
    return <>{this.props.children}</>;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  getVariants,
  getFinishedVariants,
  cleanFinishedTournaments,
  notifyVariantUpdate,
  notifyNewTournamentUpdate,
  notifyTournamentUpdate
};

const InnerVariantDataWrapper = connect(mapStateToProps, mapDispatchToProps)(_getVariantDataWrapper);

const GetVariantDataWrapper  = (props) =>{
    return (<InnerVariantDataWrapper {...props}>{props.children}</InnerVariantDataWrapper>);
}

export default GetVariantDataWrapper;

