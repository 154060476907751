import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import TableCard from "./TableCard";

import CustomModal from "../../shared/CustomModal";
import { moveTable } from "../../../actions/tablesActions";
import { groupBy } from "../../../util/groupByUtil";

const MoveTablesModal = ({ isOpen, variant, table, toggle }) => {
  const dispatch = useDispatch();
  const [activeRoomId, setActiveRoomId] = useState(null);
  const [roomTables, setRoomTables] = useState([]);
  const [roomsTables, setRoomsTables] = useState([]);

  useEffect(() => {
    getTables();
  }, []);

  function getTables() {
    if (!variant || !variant.tables) {
      return [];
    }

    var tt = variant.tables
      .filter((item) => item.id !== table.id)
      .filter((t) => !t.occupiedSeats || t.occupiedSeats.length === 0);
    if (tt.length > 0) getRoomsTables(tt);
  }

  function onMoveTable(item) {
    dispatch(moveTable(variant.id, table.id, item.tournamentTableId));
    toggle();
  }

  function getRoomsTables(tables) {
    var gg = groupBy(tables, "roomId");
    var tt = [];
    for (var key in gg) {
      var value = gg[key];
      tt.push(value);
    }

    setRoomsTables(tt);
    onRoomButtonClick(tt[0]);
  }

  const onRoomButtonClick = (tables) => {
    setActiveRoomId(tables[0].roomId);
    setRoomTables(tables);
  };

  const getRoomsButtons = () => {
    return roomsTables.map((table) => {
      return (
        <input
          type="button"
          className={
            table[0].roomId === activeRoomId ? "border-line-bottom-active" : "border-line-bottom"
          }
          onClick={() => onRoomButtonClick(table)}
          value={table[0].room}
          key={table[0].roomId}
        />
      );
    });
  };

  return (
    <>
      {isOpen && (
        <CustomModal
          open={isOpen}
          closeHandler={toggle}
          className="move-tables-modal dark"
          title={`Move table ${table.tableName}`}
        >
          <div className="rooms">{getRoomsButtons()}</div>
          <Row className="tables">
            {variant &&
              roomTables.map((item) => (
                <TableCard
                  key={item.id}
                  table={{...item}}
                  isLockUnlock={false}
                  showMoveHere
                  onMoveHere={() => onMoveTable(item)}
                  seatingLocked={item.seatingLocked}
                  variant={variant}
                  hideText
                />
              ))}

            {roomTables.length === 0 && <span className="no-tables">No tables where move to</span>}
          </Row>
        </CustomModal>
      )}
    </>
  );
};

export default MoveTablesModal;
