import React, { useRef, useState } from "react";
import { display } from "../../enum/Currency";
import { getTournamentVariantTitle, getMttBgColorCss } from "../../util/tournamentUtil";
import { useSelector } from "react-redux";
import { ReactComponent as TournamentIcon } from "../../image/icon-tournament.svg";

const TournamentClockHeader = (props) => {
  const clockSettings = useSelector((state) => state.tournaments.clockSettings);

  function getClockColorStyle() {
    if (!props.tournament.color) {
      return "";
    }

    return getMttBgColorCss(props.tournament);
  }

  const rebuyInfo = `${
    props.variant.reBuyEndAtLevel !== null ? `Through Round ${props.variant.reBuyEndAtLevel}, ` : ""
  }${
    props.variant.reBuyMaxPerPlayer !== null
      ? `Max ${props.variant.reBuyMaxPerPlayer} per player`
      : ""
  }`;

  const getSubtitle = () => {
    if (clockSettings && clockSettings.data && clockSettings.data.description) {
      return clockSettings.data.description;
    } else {
      return "";
    }

    // if (props.variant.id) {
    //   return `
    //           ${display(
    //             props.tournament.currency,
    //             props.variant.buyInFee + props.variant.buyInPool
    //           )} Buy-in
    //           ${
    //             props.variant.reBuy && props.variant.reBuyFee !== null
    //               ? `, ${display(
    //                   props.tournament.currency,
    //                   props.variant.reBuyFee + props.variant.reBuyPool
    //                 )} rebuy ${rebuyInfo.length > 0 ? `(${rebuyInfo})` : ""}`
    //               : ""
    //           }
    //           ${
    //             props.variant.addon && props.variant.addonFee !== null
    //               ? `, ${display(
    //                   props.tournament.currency,
    //                   props.variant.addonFee + props.variant.addonPool
    //                 )} add-ons`
    //               : ""
    //           }`;
    // } else {
    //   return "";
    // }
  };

  return (
    <div className="clock-title action-panel">
      <span className="mainTitle">
        {getTournamentVariantTitle(props.tournament, props.variant)}
      </span>
      <span className="subtitle">{getSubtitle()}</span>

      <div className={`tournament-icon tournament-clock-header ${getClockColorStyle()} not-transparent`} >
        <TournamentIcon />
      </div>

    </div>
  );
};

export default TournamentClockHeader;
