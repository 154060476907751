import React from "react";
import { Row } from "react-bootstrap";
import { Button, Col } from "reactstrap";
import moment from "moment-mini";
import PlayerState from "../../../enum/PlayerState";
import { formatTableSeat } from "../../../util/playerUtil";

const Seat = ({ player }) => {
  function showSeat(state) {
    return state === PlayerState.PLAYING.key;
  }

  if (showSeat(player.stateForFE)) {
    return (
      <div className="right mr-4">
        <p className="big-text no-wrap">{`${formatTableSeat(player)}`}</p>
        <p className="small-text">Seat</p>
      </div>
    );
  }

  return null;
};

const PlayerCard = ({ player, clickOnMoreAction, clickOnGoToTable, hideGoToTable }) => {
  function getColorForState(state) {
    if (state === PlayerState.PLAYING.key) {
      return "state-playing";
    }
    if (state === PlayerState.WAITING_LIST.key) {
      return "state-waiting";
    }

    return "";
  }

  function getLabelForState(state) {
    const stateEnum = PlayerState[state];

    if (stateEnum) {
      return stateEnum.label;
    }

    return "";
  }

  const isFinished = (state) => state === PlayerState.FINISHED.key;

  return (
    <>
      <div className={"d-none d-md-block"}>
        <Row className="player-card no-wrap">
          <div className="col1">
            <div className="name big-text">
              {`${player.firstName} ${player.lastName}`}
              {(player.reEntryCount === null || player.reEntryCount === 0) && (
                <span className="green-label">Buyin</span>
              )}
              {player.reEntryCount !== null && player.reEntryCount > 0 && (
                <span className="violet-label">Reentry</span>
              )}
            </div>
            <div className="lables">
              <span className={`small-text ${getColorForState(player.stateForFE)}`}>
                {`${getLabelForState(player.stateForFE) +
                  (isFinished(player.stateForFE)
                    ? ` ${moment(player.finishedAt).format("DD.MM.YYYY HH:mm")}`
                    : "")
                  }`}
              </span>
            </div>
          </div>
          <Seat player={player} />
          <div className="col3 mr-2">
            {!hideGoToTable && (
              <Button color="primary" className="fit" onClick={() => clickOnGoToTable(player)}>
                Go to table
              </Button>
            )}
          </div>
          <div className="col3">
            <Button color="primary" className="fit" onClick={() => clickOnMoreAction(player)}>
              More Details
            </Button>
          </div>
        </Row>
      </div>
      <div className={"d-block d-md-none"}>
        <Row xs={12} className="player-card mb-25 pb-25">
          <Col xs={5}>
            <h4 className="name big-text">{`${player.firstName} ${player.lastName}`}</h4>
            <span className={`small-text ${getColorForState(player.stateForFE)}`}>
              {`${getLabelForState(player.stateForFE) +
                (isFinished(player.stateForFE)
                  ? ` ${moment(player.finishedAt).format("DD.MM.YYYY HH:mm")}`
                  : "")
                }`}
            </span>
          </Col>
          <Col xs={3}>
            <Seat player={player} />
          </Col>
          <Col xs={4}>
            <Button color="primary" size="xs" onClick={() => clickOnMoreAction(player)}>
              More ...
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PlayerCard;
