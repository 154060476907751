export default {
  PLAYING: { key: 'PLAYING', label: 'Playing' },
  PROCEED: { key: 'PROCEED', label: 'Advanced' },
  FINISHED: { key: 'FINISHED', label: 'Finished' },
  NOT_SEATED: { key: 'NOT_SEATED', label: 'Not seated' },
  WAITING_LIST: { key: 'WAITING_LIST', label: 'Waiting list' },
  UNREGISTERED: { key: 'UNREGISTERED', label: 'Unregistered' },
  UNREGISTERING: { key: 'UNREGISTERING', label: 'Unregistering' },
  REGISTERED: { key: 'REGISTERED', label: 'Registered' },
  DQ: { key: 'DQ', label: 'Disqualified' },
  GIVE_UP: { key: 'GIVE_UP', label: 'Give up' },
};
