import React from "react";
import ConfirmationModal from "../../modals/ConfirmationModal";

const ReseatBackConfirmModal = (props) => {
    const {open, submit, close } = props
    return (
        <ConfirmationModal
            confirmationText={`All players will be reseated back to their previous flight and seat`}
            open={open}
            buttonText="Ok"
            buttonText2="Cancel"
            close={() => close()}
            onSubmitButtonClick={() => submit()}
            onSubmitButton2Click={() => close()}
        />
    )
}

export default ReseatBackConfirmModal;