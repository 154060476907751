import React, { useEffect, useState } from "react";
import { Button, Input, Label } from "reactstrap";
import CustomModal from "../../shared/CustomModal";

const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const EditPayoutItemModal = (props) => {
  const [item, setItem] = useState({});
  const [poolPrc, setPoolPrc] = useState(0);
  const [fixedValue, setFixedValue] = useState(0);
  const [bonus, setBonus] = useState("");

  const id = props.editItem._id || props.editItem.id;
  const totalPool = props.totalPool;
  useEffect(() => {
    setPoolPrc(100 * props.editItem.poolPrc);
    setFixedValue(props.editItem.poolPrc * totalPool);
    setBonus(props.editItem.bonus);
    setItem(props.editItem);
  }, [id]);

  const updatePayoutItem = (poolPrc, fixedValue, bonus) => {
    if (poolPrc !== undefined) {

     if (poolPrc === "") {
        setPoolPrc(null);
        setFixedValue(null);
      } else {
        const poolPrcParsed = Number(poolPrc);
        setPoolPrc(poolPrcParsed);
        setFixedValue((poolPrcParsed * totalPool) / 100);
      }
    }
    if (fixedValue !== undefined) {
      if (fixedValue === "") {
        setPoolPrc(null);
        setFixedValue(null);
      } else {
        const fixedValueParsed = Number(fixedValue);
        setPoolPrc((100 * parseFloat(fixedValueParsed)) / totalPool);
        setFixedValue(fixedValueParsed);
      }
    }
    if (bonus !== undefined) {
      setBonus(bonus);
    }
  };
  return (
    <CustomModal
      open={props.isOpen}
      closeHandler={props.toggle}
      className="action-confirm-modal dark"
      dialogClassName="modal-small-width"
    >
      <>
        <Label for="poolPrc">Payout %</Label>
        <Input
          type="number"
          min="0"
          max="100"
          id="poolPrc"
          name="poolPrc"
          onChange={(e) => {
            updatePayoutItem(e.target.value);
          }}
          value={poolPrc}
        />
        <Label className="mt-2" for="poolPrc">{`Payout ${props.currency}`}</Label>
        <Input
          type="number"
          id="fixedValue"
          name="fixedValue"
          onChange={(e) => {
            updatePayoutItem(undefined, e.target.value);
          }}
          value={fixedValue}
        />
        <Label className="mt-2" for="poolPrc">
          Text to prize
        </Label>
        <Input
          className="dark"
          type="textarea"
          id="bonus"
          name="bonus"
          onChange={(e) => {
            updatePayoutItem(undefined, undefined, e.target.value);
          }}
          value={bonus}
        />
        <Button
          className="mt-5"
          onClick={() => {
            const updated = { ...item };
            updated.poolPrc = poolPrc / 100;
            updated.fixedValue = fixedValue;
            updated.bonus = bonus;
            props.onEdit(updated);
          }}
        >
          Save payout changes
        </Button>
      </>
    </CustomModal>
  );
};

export default EditPayoutItemModal;
