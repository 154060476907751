import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import CustomModal from "../../shared/CustomModal";
import ToggleSwitch from "../../functionalComponents/ToggleSwitch";
import { displayMoney, displayNumber } from "../../../util/stringUtil";

const EditPrizePoolModal = (props) => {
  const [adjustedPool, setAdjustedPool] = useState(0);
  const [initialAdjustedPool, setInitialAdjustedPool] = useState(0);
  const [adjustedFee, setAdjustedFee] = useState(0);
  const [adjustedFeePerc, setAdjustedFeePerc] = useState(0);
  const [cutted, setCutted] = useState(0);
  const [cuttedPerc, setCuttedPerc] = useState(0);

  const [feeIsOn, setFeeIsOn] = useState(false);
  const [cutIsOn, setCutIsOn] = useState(false);

  const adjustedPoolChanged = () => {
    if (initialAdjustedPool !== 0) {
      setInitialAdjustedPool(0);
    } else {
      setInitialAdjustedPool(adjustedPool);
    }
    resetFields();
  };

  const resetFields = () => {
    setAdjustedFee(0);
    setAdjustedFeePerc(0);
    setCutIsOn(false);
    setCutted(0);
    setCuttedPerc(0);
    setFeeIsOn(false);
  };

  useEffect(() => {
    if (feeIsOn) {
      var prizepool = initialAdjustedPool;
      var poolValue = prizepool - adjustedFee;
      setAdjustedPool(poolValue);
    }
  }, [adjustedFee]);

  useEffect(() => {
    if (cutIsOn) {
      var prizepool = initialAdjustedPool;
      var poolValue = prizepool - cutted;
      setAdjustedPool(poolValue);
    }
  }, [cutted]);

  useEffect(() => {
    if (feeIsOn && adjustedFee > 0) {
      setAdjustedPool(adjustedPool - adjustedFee);
    }
    if (feeIsOn === false && adjustedFee > 0) {
      setAdjustedPool(adjustedPool + adjustedFee);
    }
  }, [feeIsOn]);

  useEffect(() => {
    if (cutIsOn && cutted > 0) {
      setAdjustedPool(adjustedPool - cutted);
    }
    if (cutIsOn === false && cutted > 0) {
      setAdjustedPool(adjustedPool + cutted);
    }
  }, [cutIsOn]);

  const adjustedFeeChanged = () => {
    var prizepool = initialAdjustedPool;

    var feePerc = (adjustedFee * 100) / prizepool;
    setAdjustedFeePerc(feePerc.toFixed(1));
  };

  const adjustedFeePercChanged = () => {
    var prizepool = initialAdjustedPool;

    var feeValue = (prizepool / 100) * adjustedFeePerc;
    setAdjustedFee(feeValue);
  };

  const cutChanged = () => {
    var prizepool = initialAdjustedPool;

    var cutPerc = (cutted * 100) / prizepool;
    setCuttedPerc(cutPerc.toFixed(1));
  };

  const adjustedCutPercChanged = () => {
    var prizepool = initialAdjustedPool;

    var cuttedValue = (prizepool / 100) * cuttedPerc;
    setCutted(cuttedValue);
  };

  const submitNewValues = () => {
    props.saveValues({
      id: props.tournament.id,
      adjustedPool: adjustedPool > 0 ? adjustedPool : 0,
      fixedTotalFee: adjustedFee > 0 ? adjustedFee : 0,
      cutted: cutted > 0 ? cutted : 0,
    });

    props.toggle();
  };

  return (
    <CustomModal
      open={props.isOpen}
      closeHandler={props.toggle}
      className="prize-pool"
      dialogClassName="modal-small-width"
    >
      <>
        <Row>
          <Col xs={12}>
            Prize pool{" "}
            {initialAdjustedPool !== 0
              ? `(` + displayMoney(initialAdjustedPool, props.tournament.currency) + `)`
              : 0}
            <div class="input-group mb-3">
              <input
                disabled={initialAdjustedPool !== 0 ? true : false}
                type="number"
                className="black-input form-control"
                placeholder="Prize pool"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setAdjustedPool(e.target.valueAsNumber);
                }}
                value={adjustedPool}
              />
              <div className="input-group-append">
                <Button
                  className="m0 btn btn-outline-secondary"
                  id="button-addon2"
                  onClick={adjustedPoolChanged}
                >
                  {initialAdjustedPool !== 0 ? "Change" : "Save"}
                </Button>
              </div>
            </div>
          </Col>
          <Row className={initialAdjustedPool > 0 ? "visible" : "invisible"}>
            <div className="graySeparator m-2" />

            <Col xs={6}>Fee %</Col>
            <Col xs={6}>Fee </Col>

            <Col xs={6}>
              <Input
                id="adjustedFeePerc"
                name="adjustedFeePerc"
                type="number"
                onBlur={adjustedFeePercChanged}
                onChange={(e) => {
                  setAdjustedFeePerc(e.target.valueAsNumber);
                }}
                value={adjustedFeePerc}
              />
            </Col>
            <Col xs={6}>
              <Input
                id="adjustedFee"
                name="adjustedFee"
                type="number"
                onBlur={adjustedFeeChanged}
                onChange={(e) => {
                  setAdjustedFee(e.target.valueAsNumber);
                }}
                value={adjustedFee}
              />
            </Col>
            <Col xs={6}>
              <span>Deduct fee from prize pool</span>
            </Col>
            <Col xs={6}>
              <ToggleSwitch
                field="fee"
                switchValue={() => setFeeIsOn(!feeIsOn)}
                isSwitchedOn={feeIsOn}
              />
            </Col>

            <div className="graySeparator m-2" />

            <Col xs={6}>Cut %</Col>
            <Col xs={6}>Cut </Col>

            <Col xs={6}>
              <Input
                id="cuttedPerc"
                name="cuttedPerc"
                type="number"
                onBlur={adjustedCutPercChanged}
                onChange={(e) => {
                  setCuttedPerc(e.target.valueAsNumber);
                }}
                value={cuttedPerc}
              />
            </Col>

            <Col xs={6}>
              <Input
                id="cutted"
                name="cutted"
                type="number"
                onBlur={cutChanged}
                onChange={(e) => {
                  setCutted(e.target.valueAsNumber);
                }}
                value={cutted}
              />
            </Col>
            <Col xs={6}>
              <span>Deduct cut from prize pool</span>
            </Col>
            <Col xs={6}>
              <ToggleSwitch
                field="cut"
                switchValue={() => setCutIsOn(!cutIsOn)}
                isSwitchedOn={cutIsOn}
              />
            </Col>
          </Row>
          <Col xs={12} className={initialAdjustedPool > 0 ? "text-center" : "invisible"}>
            <Button className="w-50 mt-5" color="secondary" onClick={submitNewValues}>
              Save
            </Button>
          </Col>
        </Row>
      </>
    </CustomModal>
  );
};

export default EditPrizePoolModal;
