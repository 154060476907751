import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const MenuItem = ({ item, index, select }) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Row className="item" key={index}>
        <span onClick={() => setOpened(!opened)}>{item.name}</span>
        <Button className="scroll" onClick={() => setOpened(!opened)}>
          {opened ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
        </Button>
      </Row>

      {opened &&
        item.subitems.map((sub) => {
          return (
            <Row className="sub-item" onClick={() => select(sub)}>
              {sub.name}
            </Row>
          );
        })}
    </>
  );
};

const MultilevelDropdown = ({ items, select }) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className="multiselect-dropdown">
      <Row>
        <span className="header" onClick={() => setOpened(!opened)} >Use existing template</span>
        <Button className="scroll" onClick={() => setOpened(!opened)}>
          {opened ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
        </Button>
      </Row>
      {opened &&
        items.map((item1, index) => {
          return <MenuItem item={item1} index={index} select={select}></MenuItem>;
        })}
    </div>
  );
};

export default MultilevelDropdown;
