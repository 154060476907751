export default {
  ALBANIA: 'Albania',
  ALGERIA: 'Algeria',
  ARGENTINA: 'Argentina',
  AUSTRALIA: 'Australia',
  AUSTRIA: 'Austria',
  BANGLADESH: 'Bangladesh',
  BELGIUM: 'Belgium',
  BOLIVIA: 'Bolivia',
  BOTSWANA: 'Botswana',
  BRAZIL: 'Brazil',
  BULGARIA: 'Bulgaria',
  CAMBODIA: 'Cambodia',
  CAMEROON: 'Cameroon',
  CANADA: 'Canada',
  CHILE: 'Chile',
  CHINA: 'China',
  COLOMBIA: 'Colombia',
  COSTA_RICA: 'Costa Rica',
  CROATIA: 'Croatia',
  CUBA: 'Cuba',
  CZECH_REPUBLIC: 'Czech Republic',
  DENMARK: 'Denmark',
  DOMINICAN_REPUBLIC: 'Dominican Republic',
  ECUADOR: 'Ecuador',
  EGYPT: 'Egypt',
  EL_SALVADOR: 'El Salvador',
  ENGLAND: 'England',
  ESTONIA: 'Estonia',
  ETHIOPIA: 'Ethiopia',
  FIJI: 'Fiji',
  FINLAND: 'Finland',
  FRANCE: 'France',
  GERMANY: 'Germany',
  GHANA: 'Ghana',
  GREECE: 'Greece',
  GUATEMALA: 'Guatemala',
  HAITI: 'Haiti',
  HONDURAS: 'Honduras',
  HUNGARY: 'Hungary',
  ICELAND: 'Iceland',
  INDIA: 'India',
  INDONESIA: 'Indonesia',
  IRAN: 'Iran',
  IRAQ: 'Iraq',
  IRELAND: 'Ireland',
  ISRAEL: 'Israel',
  ITALY: 'Italy',
  JAMAICA: 'Jamaica',
  JAPAN: 'Japan',
  JORDAN: 'Jordan',
  KENYA: 'Kenya',
  KUWAIT: 'Kuwait',
  LAOS: 'Laos',
  LATVIA: 'Latvia',
  LEBANON: 'Lebanon',
  LIBYA: 'Libya',
  LITHUANIA: 'Lithuania',
  MADAGASCAR: 'Madagascar',
  MALAYSIA: 'Malaysia',
  MALI: 'Mali',
  MALTA: 'Malta',
  MEXICO: 'Mexico',
  MONGOLIA: 'Mongolia',
  MOROCCO: 'Morocco',
  MOZAMBIQUE: 'Mozambique',
  NAMIBIA: 'Namibia',
  NEPAL: 'Nepal',
  NETHERLANDS: 'Netherlands',
  NEW_ZEALAND: 'New Zealand',
  NICARAGUA: 'Nicaragua',
  NIGERIA: 'Nigeria',
  NORWAY: 'Norway',
  PAKISTAN: 'Pakistan',
  PANAMA: 'Panama',
  PARAGUAY: 'Paraguay',
  PERU: 'Peru',
  PHILIPPINES: 'Philippines',
  POLAND: 'Poland',
  PORTUGAL: 'Portugal',
  ROMANIA: 'Romania',
  RUSSIA: 'Russia',
  SAUDI_ARABIA: 'Saudi Arabia',
  SCOTLAND: 'Scotland',
  SENEGAL: 'Senegal',
  SERBIA: 'Serbia',
  SINGAPORE: 'Singapore',
  SLOVAKIA: 'Slovakia',
  SOUTH_AFRICA: 'South Africa',
  SOUTH_KOREA: 'South Korea',
  SPAIN: 'Spain',
  SRI_LANKA: 'Sri Lanka',
  SUDAN: 'Sudan',
  SWEDEN: 'Sweden',
  SWITZERLAND: 'Switzerland',
  SYRIA: 'Syria',
  TAIWAN: 'Taiwan',
  TAJIKISTAN: 'Tajikistan',
  THAILAND: 'Thailand',
  TONGA: 'Tonga',
  TUNISIA: 'Tunisia',
  TURKEY: 'Turkey',
  UKRAINE: 'Ukraine',
  UNITED_ARAB_EMIRATES: 'United Arab Emirates',
  UNITED_KINGDOM: 'United Kingdom',
  UNITED_STATES: 'United States',
  URUGUAY: 'Uruguay',
  VENEZUELA: 'Venezuela',
  VIETNAM: 'Vietnam',
  WALES: 'Wales',
  ZAMBIA: 'Zambia',
  ZIMBABWE: 'Zimbabwe',
};
