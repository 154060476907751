import React from 'react';
import { Row } from 'react-bootstrap';
import RoomSpaceGrid from './RoomSpaceGrid';

export default function ExpansibleRoomSpaceGrid(props) {
  return (
    <>
      <Row>
        <RoomSpaceGrid {...props} spaceGridType="expansibleSpaceGrid" />
      </Row>
    </>
  );
}
