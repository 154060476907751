import React from "react";
import { getMainClockTime } from "../../../../util/tournamentUtil";
import { Col, Row } from "reactstrap";

const TileMainTimeMobile = ({ variant, curLevel }) => {
  const label = (status) => {
    switch (status) {
      case "break":
        return <div className={`status-box`}>Break</div>;
      case "level":
        return <div className={`status-box green`}>Level</div>;
      default:
        return "";
    }
  };

  return (
    <Row xs={12}>
      <Col xs={7}>
        <h1 className={"title-main-time-mobile-text"}>{getMainClockTime(variant, curLevel)}</h1>
      </Col>
      <Col xs={4} className={"py-2"}>
        {label(curLevel.type)}
        <div className={`${variant ? "" : "gray"}`}>{curLevel.level}</div>
      </Col>
    </Row>
  );
};

export default TileMainTimeMobile;
