import React, { useEffect, useState } from 'react';
import restClient from '../util/restClient';

const useGet = (url, initData = {}) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(initData);

  useEffect(() => {
    setLoading(true);
    setError(false);
    restClient.get(url)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        setError(true);
        setData(initData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  return { isLoading, isError, data };
};

export default useGet;
