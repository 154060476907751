import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import CustomModal from "../../shared/CustomModal";
import {formatPlayerName, getSeats} from "../../../util/playerUtil";
import restClient from "../../../util/restClient";

const ReseatPlayerSeatsModal = (props) => {
    const [hideMe, setHideMe] = useState(false);
    const tableId = props.selectedTable.id;

    const players = useSelector(state => state.player.playersInTournament.data);

    const [seatsData, setSeatsData] = useState([]);

    useEffect(() => {
        restClient.getAsyncWrap(`/tournament/table/${tableId}/seats`, seatsData, setSeatsData)
    }, [tableId])

    const seats = (seatsData.data || []).filter(seat => seat.active)
    const findPlayer = (players, seatNum, tableId) => {
        const player = players.find((p) => p.seatNum === seatNum && p.tableId === tableId);
        return player ? formatPlayerName(player) : "";
    }

    const isReserved = (seat) => {
        if (!props.reservedSeats) return false;

        return props.reservedSeats.some((s) => s.seat === seat);
    };

    return (
        <>
            {props.isOpen && (
                <CustomModal
                    title={`Choose seat - Table "${props.selectedTable.tableName}"`}
                    open={props.isOpen}
                    closeHandler={props.toggle}
                    className="reseat-player-seats"
                    dialogClassName={`modal-small-width ${hideMe ? "hidden" : ""}`}
                >
                    <>
                        <div className={`d-none d-md-block`}>
                            <div className="seats">
                                {seats
                                    .map((seat) => {
                                        const seatNum = seat.seatNum;
                                        const isFav = (props.favorite || []).includes(seatNum);
                                        if (seat.playerId) {
                                            return {
                                                seatNum,
                                                isFav,
                                                item: (
                                                    <Col>
                                                        <Row>
                                                            <Col xs={3} md={1}>
                                                                <h3 style={{marginBottom: 0}}>
                                                                    {`0${seatNum}`.substr(`0${seatNum}`.length - 2)}
                                                                </h3>
                                                                Seat
                                                            </Col>
                                                            <Col xs={9} md={11} className="text-center">
                                                                {findPlayer(players, seatNum, tableId)}
                                                            </Col>
                                                        </Row>
                                                        <Row className="graySeparator"/>
                                                    </Col>
                                                ),
                                            };
                                        } else {
                                            return {
                                                seatNum,
                                                isFav,
                                                item: (
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <h3 style={{marginBottom: 0}}>
                                                                    {`0${seatNum}`.substr(`0${seatNum}`.length - 2)}
                                                                </h3>
                                                                Seat
                                                            </Col>
                                                            <Col>{isReserved(seatNum) ? "Reserved" : "Free seat"}</Col>
                                                            <Button
                                                                disabled={isReserved(seatNum)}
                                                                variant="secondary"
                                                                onClick={() => {
                                                                    props.setNewSeat(seatNum);
                                                                    props.toggle();
                                                                }}
                                                            >
                                                                Choose this
                                                            </Button>
                                                        </Row>
                                                        <Row className="graySeparator"/>
                                                    </Col>
                                                ),
                                            };
                                        }
                                    })
                                    .sort((e1, e2) => {
                                        if (!e1.isFav && e2.isFav) return 1;
                                        if (e1.isFav && !e2.isFav) return -1;
                                        return e1.seatNum - e2.seatNum;
                                    })
                                    .map((each) => each.item)}
                            </div>
                        </div>
                        <div className={`d-block d-md-none`}>
                            <Col xs={12}>
                                {seats
                                    .map((seat) => {
                                        const seatNum = seat.seatNum;
                                        const isFav = (props.favorite || []).includes(seatNum);
                                        if (seat.playerId) {
                                            return {
                                                seatNum,
                                                isFav,
                                                item: (
                                                    <Row>
                                                        <Col xs={3} md={1}>
                                                            <h3 style={{marginBottom: 0}}>
                                                                {`0${seatNum}`.substr(`0${seatNum}`.length - 2)}
                                                            </h3>
                                                            Seat
                                                        </Col>
                                                        <Col xs={9} md={11} className="text-center">
                                                            {findPlayer(players, seatNum, tableId)}
                                                        </Col>
                                                        <Row className="graySeparator"/>
                                                    </Row>
                                                ),
                                            };
                                        } else {
                                            return {
                                                seatNum,
                                                isFav,
                                                item: (
                                                    <Row>
                                                        <Col xs={4}>
                                                            <h3 style={{marginBottom: 0}}>
                                                                {`0${seatNum}`.substr(`0${seatNum}`.length - 2)}
                                                            </h3>
                                                            Seat
                                                        </Col>
                                                        <Col xs={4}>
                                                            {isReserved(seatNum) ? "Reserved" : "Free seat"}
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Button
                                                                disabled={isReserved(seatNum)}
                                                                variant="secondary"
                                                                onClick={() => {
                                                                    props.setNewSeat(seatNum);
                                                                    props.toggle();
                                                                }}
                                                            >
                                                                Choose this
                                                            </Button>
                                                        </Col>
                                                        <Row className="graySeparator"/>
                                                    </Row>
                                                ),
                                            };
                                        }
                                    })
                                    .sort((e1, e2) => {
                                        if (!e1.isFav && e2.isFav) return 1;
                                        if (e1.isFav && !e2.isFav) return -1;
                                        return e1.seatNum - e2.seatNum;
                                    })
                                    .map((each) => each.item)}
                            </Col>
                        </div>
                    </>
                </CustomModal>
            )}
        </>
    );
}

ReseatPlayerSeatsModal.defaultProps = {
    variant: {tables: []},
    selectedTable: {},
};

export default ReseatPlayerSeatsModal;
