import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import ChatSender from './ChatSender';
import ChatHistory from './ChatHistory';
import { publishEvent } from '../../../util/publishSocetUtil';

class Chat extends React.Component {
  componentDidMount() {
    const payload = { chatIdentifier: `VARIANT_${this.props.chatVariantId}` };
    publishEvent('chatGetHistoryRequest', payload, this.props.socket);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.chatVariantId && nextProps.chatVariantId !== this.props.chatVariantId) {
      const payload = { chatIdentifier: `VARIANT_${nextProps.chatVariantId}` };
      publishEvent('chatGetHistoryRequest', payload, nextProps.socket);
    }
  }

  render() {
    const chatIdentifier = `VARIANT_${this.props.chatVariantId}`;
    const currentChat = this.props.chats.find((eachChat) => eachChat && eachChat.chatIdentifier === chatIdentifier) || {};
    return (
      <>
        <Col>
          <Row>
            <Col><ChatHistory messages={currentChat.messages} /></Col>
          </Row>
          <Row>
            <Col>
              <ChatSender
                socket={this.props.socket}
                chatIdentifier={chatIdentifier}
                isFreeTextSender
              />
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

Chat.defaultProps = {
  chats: [],
};

const mapStateToProps = (state) => ({
  chats: state.chat.chatList,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Chat);
