import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import Loader from "../../shared/Loader";
import UpdatePlayerStateWrapper from "../../shared/UpdatePlayerStateWrapper";
import VariantPicker from "../../shared/VariantPicker";
import StructureLevelMobile from "./StructureLevelMobile";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StructureMobile = (props) => {

    const { tournament } = props;


    const [showInfoModal, setShowInfoModal] = useState(false);

    if (!tournament) {
        return <Loader />;
    }

    const TableHeader = () => (
        <Row className="structure-table-header p-3">
            <Col key="lvl">
                Lvl
            </Col>
            <Col key="smallBlind">
                SB
            </Col>
            <Col key="bigBlind">
                BB
            </Col>
            <Col key="ante">
                Ante
            </Col>
            <Col key="levelLength">
                Length
            </Col>
        </Row>
    );

    return (
        <div id="editStructure">
            <div className={"flex pl-2 pr-2 d-block d-md-none mb-3"}>
                <Row>
                    <Col>
                        <div className="float-right info-text">
                            <div>
                                <FontAwesomeIcon
                                    className={"info-icon"}
                                    icon={faInfoCircle}
                                    onClick={() => setShowInfoModal(!showInfoModal)} /></div>
                            <div>{!showInfoModal ? "" : "If you would like to change the structure, please use a desktop PC or tablet device"}</div>

                        </div>
                    </Col>
                </Row>
            </div>
            <UpdatePlayerStateWrapper>
                <VariantPicker id={"structureMobile"} hideHeader tournament={tournament} contentClassName="scrl"
                    getContent={(variant) => {
                        return (
                            <>
                                <div className="structure-title">
                                    <h2 className="title">Structure</h2>
                                </div>
                                <Loader actionId="updateVariant">
                                    <div className="structure">
                                        <TableHeader />
                                        {variant.structure && variant.structure.map((structure, index) => (
                                            <StructureLevelMobile
                                                key={structure.id || structure.uniqueId}
                                                structure={structure}
                                                index={index}
                                            />
                                        ))}
                                    </div>
                                </Loader>
                            </>
                        );
                    }} />
            </UpdatePlayerStateWrapper>
        </div>
    )
}

export default StructureMobile;