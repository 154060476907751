import React from "react";
import TemplateList from "./template/TemplateList";
import TemplateType from "../../enum/TemplateType";

const TemplateMtt = (props) => (
  <div className="p-4">
    <TemplateList templateType={TemplateType.TOURNAMENT_DETAIL} />
  </div>
);

export default TemplateMtt;
