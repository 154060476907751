import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const SliderBox = withStyles({
  root: {
    color: '#5b38b9',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    '& *': {
      transform: 'none',
    },
    '& > span': {
      borderRadius: '2px',
      width: 'max-content',
      padding: '0px 10px',
      fontSize: '16px',
    },
  },
  mark: {
    display: 'none',
  },
  markLabel: {
    color: '#fff',
    fontSize: '16px',
    marginTop: '12px',
    transform: 'translateX(0%)',
    '& ~ .MuiSlider-markLabel': {
      transform: 'translateX(-100%)',
    },
  },
  track: {
    height: 8,
    borderRadius: 100,
  },
  rail: {
    height: 8,
    borderRadius: 100,
    backgroundColor: '#e6e6e6',
    opacity: 1,
  },
})(Slider);

export default SliderBox;
