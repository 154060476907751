import React from "react";
import {
  resolveState,
  getTournamentVariantTitle,
  getMttBgColorCss,
} from "../../util/tournamentUtil";
import TournamentState from "../../enum/TournamentState";
import { ReactComponent as TournamentIcon } from "../../image/icon-tournament.svg";
import { Col, Row } from "reactstrap";
import { history } from "../../service/history";

export default function TournamentHeader(props) {
  const tournament = props.tournament || {};
  const { variant } = props;
  let state;
  let stateLabel;
  let stateColor;

  if ((variant || {}).state) {
    state = variant.state;
  } else if ((tournament || {}).state) {
    state = resolveState(tournament);
  }

  state = state ? state.toUpperCase() : "";
  const title = getTournamentVariantTitle(props.tournament, props.variant);

  switch (state) {
    case TournamentState.ACTIVE:
      stateColor = "green";
      stateLabel = "RUNNING";
      break;
    case TournamentState.FUTURE:
      stateColor = "blue";
      stateLabel = "UPCOMING";
      break;
    case TournamentState.FINISHED:
      stateColor = "red";
      stateLabel = "FINISHED";
      break;
    default: {
      stateLabel = state;
      stateColor = "green";
    }
  }

  const redirect = (path) => {
    history.push(path);
  };

  return (
    <div>
      <div className={"d-none d-md-block"}>
        <div className="mt-2 tournamentHeader">
          <div className={`tournament-icon ${getMttBgColorCss(tournament)}`}>
            <TournamentIcon className="icon2" />
          </div>
          <div className="tournamentHeaderTitle">
            <div className={`${title ? "" : "gray"}`}>{title || ""}</div>
            {stateLabel && <div className={`status-box ${stateColor}`}>{stateLabel}</div>}
          </div>
        </div>
      </div>
      <div className={"d-block d-md-none"}>
        {props.id !== "tableList" && !tournament.isMultiday && <Row>
          <Col
            color="primary"
            onClick={() => {
              redirect(`/tournament/${sessionStorage.getItem("tournamentId")}`);
            }}
          >
            <p className="text-uppercase">
              {" "}
              <span style={{ color: "#5b38b9" }}>{`<`}</span>
              {` all tables`}
            </p>
          </Col>
        </Row>}
        <Row className={"mt-2"}>
          <Col xs={10} className="tournamentHeaderTitle">
            <h3 className={`${title ? "" : "gray"}`}>{title || ""}</h3>
            {stateLabel && (
              <span
                className={`status-box px-2 border-radius-5`}
                style={{ backgroundColor: `${stateColor}`, backdropFilter: "blur(10px)" }}
              >
                {stateLabel}
              </span>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}
