export default {
  TABLES_GET_SEATINGPLAN_RESPONSE:'TABLES_GET_SEATINGPLAN_RESPONSE',
  TABLES_GET_SEATINGPLAN_REQUEST:'TABLES_GET_SEATINGPLAN_REQUEST',
  TABLES_GET_SEATINGPLAN_CLEAN:'TABLES_GET_SEATINGPLAN_CLEAN',
  TABLES_GET_REQUEST: 'TABLES_GET_REQUEST',
  TABLES_GET_RESPONSE: 'TABLES_GET_RESPONSE',
  TABLES_SET_LOCK_STATE: 'TABLES_SET_LOCK_STATE',
  TABLES_CHANGE_LOCK_STATE: 'TABLES_SET_LOCK_STATE',

  TABLE_SET_LOCK_STATE: 'TABLE_SET_LOCK_STATE',
  TABLE_MOVE: 'TABLE_MOVE',
};
