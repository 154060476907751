import actionTypes from '../actiontypes/chatActionTypes';
import currentUserUtil from '../util/currentUser';

const initState = {
  chatList: [],
};

const insertRepyOnMessage = (messages, replyOnMsg) => {
  const index = messages.findIndex((msg) => msg.id === replyOnMsg.replyOnId);
  if (index >= 0) {
    messages.splice(index + 1, 0, replyOnMsg);
  }
};

const formatChat = (chat) => {
  if (chat !== undefined) {
    const replyOnMessages = chat.messages.filter((message) => message.replyOnId).reverse();
    const messages = chat.messages.filter((message) => !message.replyOnId);
    replyOnMessages.forEach((each) => {
      insertRepyOnMessage(messages, each);
    });
    return { ...chat, messages };
  }
  return {};
};

export default function chatReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.SOCKET_MSG_HISTORY:
      let isIn = false;
      const updatedChatList = state.chatList.map((each) => {
        if (each.id === action.data.id) {
          isIn = true;
          return formatChat({ ...action.data, unreadMsg: true });
        }
        return each;
      });
      if (!isIn) {
        updatedChatList.push(formatChat({ ...action.data, unreadMsg: true }));
      }
      return {
        ...state,
        chatList: updatedChatList,
      };
    case actionTypes.SOCKET_MSG_RECEIVE:
      const { chatId, ...newMessage } = action.data;
      const chatList = state.chatList.map((each) => {
        if (each.id === chatId) {
          each.unreadMsg = newMessage.identifier !== currentUserUtil.getUserIdentifier();
          each.messages.push(newMessage);
        }
        return formatChat(each);
      });
      return {
        ...state,
        chatList: chatList,
      };
    default:
      return state;
  }
}
