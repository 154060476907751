import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

class ConfirmationModal extends React.Component {
  onSubmitButtonClick() {
    this.props.onSubmitButtonClick();
    this.props.close();
  }

  onSubmitButton2Click() {
    this.props.onSubmitButton2Click();
    this.props.close();
  }

  render() {
    return (
      <>
        {this.props.open && (
          <Modal isOpen={this.props.open} onClosed={this.clearForm} style={{ width: '349px', height: '258px' }}>
            <ModalBody className="light-modal-body">
              <FontAwesomeIcon onClick={() => this.props.close()} icon={faTimes} size="xs" className="string-filter-icon" />
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <label
                  style={{
                    display: 'block',
                    position: 'relative',
                    margin: '12px',
                  }}
                >
                  {this.props.confirmationText}
                </label>
              </div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Button style={{ width: '40%', marginRight: '10px' }} onClick={() => this.onSubmitButtonClick()}>
                  {this.props.buttonText}
                </Button>
                <Button style={{ width: '50%', display: this.props.buttonText2 ? 'visible' : 'none' }} onClick={() => this.onSubmitButton2Click()}>
                  {this.props.buttonText2}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

ConfirmationModal.propTypes = {
  confirmationText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  buttonText2: PropTypes.string,
  onSubmitButton2Click: PropTypes.func,
};

export default ConfirmationModal;
