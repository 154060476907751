import React, { useState } from "react";
import { Input } from "reactstrap";
import { Button, Row, InputGroup } from "react-bootstrap";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createNewVariant } from "../../../util/tournamentUtil";

const FlightInput = ({ updatePhaseInfo, flight, setIsEditing, generateFlightName }) => {
  const [flightName, setFlightName] = useState(flight.name);

  const removeFirstWord = (str) => {
    const indexOfSpace = str.indexOf(' ');
    if (indexOfSpace === -1) {
      return '';
    }
    return str.substring(indexOfSpace + 1);
  }
  const namePrefixReg = /^(\d[A-Z]{1})\_?(.*)/;
  const namePrefixRegNumberOnly = /^(\d)\_?(.*)/;
  const partNameMatch = flightName ? flightName.split(" ") : [];

  const noDay = removeFirstWord(flightName);
  const withoutDay = noDay.match(namePrefixReg) ? noDay.match(namePrefixReg) : noDay.match(namePrefixRegNumberOnly);
  const prefixName = withoutDay.length > 1 ? "Day " + withoutDay[1] : "";
  const customName = withoutDay.length > 2 ? withoutDay[2] : "";
  return (
    <div className="input-group prefix">
      <span className="input-group-addon">{`${prefixName}`}</span>
      <Input
        type="text"
        placeholder="Type flight name"
        onChange={(e) => {
          const fullName = `${prefixName}${e.target.value.length > 0 ? `${e.target.value}` : ""}`;
          setFlightName(fullName);
        }}
        onBlur={(e) => {
          setIsEditing(null);
          if (flightName && flightName !== flight.name) {
            flight.name = generateFlightName(flightName);

            updatePhaseInfo();
          }
        }}
        value={customName}
        autoFocus
      />
    </div>
  );
};

const Phase = ({ dayOrder, onRemoveDayClick, phaseData, updatePhaseInfo, flightTableSize, flightTableMaxOccupancy }) => {
  const [isEditing, setIsEditing] = useState(null);
  const deleteFlight = (idx) => {
    if (idx === 0) {
      onRemoveDayClick();
      return;
    }

    phaseData.variants.splice(idx, 1);
    if (phaseData.variants.length === 1) {
      phaseData.variants[0].name = phaseData.variants[0].name.slice(0, -1);
    }

    updatePhaseInfo();
  };

  const addFlight = () => {
    if (phaseData.variants.length === 1)
      phaseData.variants[0].name += "A";

    const flightName = generateFlightName();
    phaseData.variants.push(createNewVariant(flightName, dayOrder === 0 ? flightTableSize : undefined, flightTableMaxOccupancy));
    updatePhaseInfo();
  };

  const generateFlightName = (name) => {
    let possibleName = name || "Day " + phaseData.phase + String.fromCharCode(1 + 64).toUpperCase();
    let i = 1;

    const flightNameExists = (nameToCheck) =>
      phaseData.variants.find((flight) => flight.name.includes(nameToCheck));

    while (flightNameExists(possibleName)) {
      possibleName = "Day " + phaseData.phase + String.fromCharCode(i + 64).toUpperCase();
      i++;
    }

    return possibleName;
  };

  return (
    <div className="my-1 pt-3 pl-3 pb-3 single-phase mr-2">
      {phaseData.variants.map((eachVariant, idx) => {
        const deleteFlightBtn = dayOrder > 1 || idx > 0 ? (
          <Button
            className="p-1 ml-1"
            variant="outline-secondary"
            onClick={(e) => {
              deleteFlight(idx);
            }}
          >
            <FontAwesomeIcon visibility="visible" onClick={(e) => { }} icon={faTrash} />
          </Button>
        ) : null;

        return (
          <InputGroup className={`mb-2 ${isEditing === idx ? "editing-flight" : ""}`} key={idx}>
            {isEditing === idx ? (
              <FlightInput
                setIsEditing={setIsEditing}
                updatePhaseInfo={updatePhaseInfo}
                generateFlightName={generateFlightName}
                flight={eachVariant}
              />
            ) : (
              <Input type="text" value={eachVariant.name} disabled />
            )}

            <div class="input-group-append flight-options pr-3">
              <Button
                className="p-1"
                variant="outline-secondary"
                onClick={(e) => {
                  setIsEditing(idx);
                }}
              >
                <FontAwesomeIcon visibility="visible" onClick={(e) => { }} icon={faPencilAlt} />
              </Button>
              {deleteFlightBtn}
            </div>
          </InputGroup>
        );
      })}
      <Button
        className="col-6"
        onClick={() => {
          addFlight();
        }}
      >
        Add flight
      </Button>
    </div>
  );
};

const Phases = ({
  onRemoveDayClick,
  tournamentPhases,
  updatePhaseInfo,
  flightTableSize,
  flightTableMaxOccupancy,
}) => (
  <Row className="mtt-settings-phases p-3 d-flex">
    {tournamentPhases.map((eachPhase, idx) => (
      <Phase
        key={idx}
        dayOrder={idx}
        onRemoveDayClick={onRemoveDayClick}
        phaseData={eachPhase}
        updatePhaseInfo={updatePhaseInfo}
        flightTableSize={flightTableSize}
        flightTableMaxOccupancy={flightTableMaxOccupancy}
      />
    ))}
  </Row>
);

Phases.propTypes = {};

export default Phases;
