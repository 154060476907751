import React, { useState } from 'react';
import { Button, ModalFooter, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import PlayerState from '../../../enum/PlayerState';
import { formatTableSeat, formatPlayerName } from '../../../util/playerUtil';
import { formatDate, formatTime, formatAmount } from '../../../util/stringUtil';
import { reactivatePlayerFromVariant } from '../../../actions/playerActions';
import TablesModal from './TablesModal';
import ChooseSeatModal from './ChooseSeatModal';
import UnregPlayerButton from '../../shared/playerActionButtons/UnregPlayerButton';
import SwapSeatsButton from '../../shared/playerActionButtons/SwapSeatsButton';
import DQButton from '../../shared/playerActionButtons/DQButton';
import CustomModal from '../../shared/CustomModal';
import WaitingListButton from '../../shared/playerActionButtons/WaitingListButton';
import SeatingByFloormanButton from '../../shared/playerActionButtons/SeatingByFloormanButton';
import ReseatPlayerTablesModal from './ReseatPlayerTablesModal';

const PlayerDetail = (props) => {
  const dispatch = useDispatch();

  const { player, players } = props;
  const [tablesModalIsOpen, setTablesModalIsOpen] = useState(false);
  const [isReturnToGameModalOpen, setReturnToGameModalOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState();

  function getFormatedFinishedBy() {
    return `${player.finishedByRol || ''} ${player.finishedBy || ''}`.trim();
  }

  function toggleReturnToGameModal() {
    setReturnToGameModalOpen(!isReturnToGameModalOpen);
  }

  function performReturnToGame(table, seatNum) {
    dispatch(
      reactivatePlayerFromVariant(player.playerInTournamentPhaseVariantId, table.id, seatNum),
    );
    props.closeAction();
  }

  function sitInPlayer() {
    setTablesModalIsOpen(true);
  }

  const BasicData = () => (
    <>
      <thead>
        <th>Nationality</th>
        <th>ID number</th>
        <th>Date of birth</th>
      </thead>
      <tbody>
        <tr>
          <td>{player.nationality}</td>
          <td>{player.idCardNum}</td>
          <td>{formatDate(player.dateOfBirth)}</td>
        </tr>
      </tbody>
    </>
  );

  const BustedData = () => (
    <>
      <thead>
        <th>From table</th>
        <th>Finished date</th>
        <th>Finished time</th>
        <th>Finished at position</th>
      </thead>
      <tbody>
        <tr>
          <td>{formatTableSeat(player)}</td>
          <td>{formatDate(player.finishedAt)}</td>
          <td>{formatTime(player.finishedAt)}</td>
          <td>{player.position}</td>
        </tr>
      </tbody>
      <thead>
        <th>Payout</th>
        <th>Amount</th>
      </thead>
      <tbody>
        <tr>
          <td>{player.toPayout > 0 ? 'Paid' : ''}</td>
          <td>{formatAmount(player.payoutAmount)}</td>
        </tr>
      </tbody>
    </>
  );

  const WaitingListButtons = () => (
    <Row className="action-row">
      <UnregPlayerButton player={player} onAction={() => props.closeAction()} />
    </Row>
  );

  const RegisteredButtons = () => (
    <Row className="action-row">
      <SwapSeatsButton player={player} players={players} variant={props.variant} />
      <UnregPlayerButton player={player} onAction={() => props.closeAction()} />
      <DQButton player={player} onAction={() => props.closeAction()} />
      <SeatingByFloormanButton player={player} onAction={() => props.closeAction()} />
    </Row>
  );

  const NotSeatedButtons = () => (
    <Row className="action-row">
      <UnregPlayerButton player={player} onAction={() => props.closeAction()} />
      <DQButton player={player} onAction={() => props.closeAction()} />
      <WaitingListButton player={player} onAction={() => props.closeAction()} />
    </Row>
  );

  const UnregisteredData = () => (
    <>
      <thead>
        <th>Unreg. at</th>
        <th>Unregistered by</th>
      </thead>
      <tbody>
        <tr>
          <td>{formatTime(player.finishedDate)}</td>
          <td>{getFormatedFinishedBy()}</td>
        </tr>
      </tbody>
    </>
  );

  const DQData = () => (
    <>
      <thead>
        <th>From table</th>
        <th>DQ date</th>
        <th>DQ time</th>
      </thead>
      <tbody>
        <tr>
          <td>{formatTableSeat(player)}</td>
          <td>{formatDate(player.finishedDate)}</td>
          <td>{formatTime(player.finishedDate)}</td>
        </tr>
      </tbody>
      <thead>
        <th>Payout</th>
        <th>Amount</th>
        <th> </th>
      </thead>
      <tbody>
        <tr>
          <td>-</td>
          <td>-</td>
        </tr>
      </tbody>
    </>
  );

  return (
    <>
      <CustomModal
        className="player-actions-modal"
        dialogClassName="modal-small-width"
        id="playerDetail"
        open
        closeHandler={props.closeAction}
        title={formatPlayerName(player)}
      >
        <table className="table table-borderless modal-table">
          <BasicData />
          {player.stateForFE === PlayerState.FINISHED.key && <BustedData />}
          {(player.stateForFE === PlayerState.UNREGISTERED.key || player.stateForFE === PlayerState.UNREGISTERING.key) && <UnregisteredData />}
          {player.stateForFE === PlayerState.DQ.key && <DQData />}
        </table>

        {player.stateForFE === PlayerState.WAITING_LIST.key && <WaitingListButtons />}
        {player.stateForFE === PlayerState.NOT_SEATED.key && <NotSeatedButtons />}
        {player.stateForFE === PlayerState.REGISTERED.key && <RegisteredButtons />}

        {player.stateForFE === PlayerState.FINISHED.key && (
          <ModalFooter>
            <Button color="secondary" onClick={toggleReturnToGameModal}>
              Return to game
            </Button>
          </ModalFooter>
        )}
        {(player.stateForFE === PlayerState.WAITING_LIST.key
          || player.stateForFE === PlayerState.REGISTERED.key) && (
          <ModalFooter>
            <Button color="secondary" onClick={sitInPlayer}>
              Sit in player
            </Button>
          </ModalFooter>
        )}
        {!player.stateForFE && (
          <ModalFooter>
            <Button color="secondary" onClick={props.closeAction} className="fit">
              Close details
            </Button>
          </ModalFooter>
        )}
      </CustomModal>

      {tablesModalIsOpen && (
        <TablesModal
          variant={props.variant}
          isOpen={tablesModalIsOpen}
          onCancel={() => setTablesModalIsOpen(false)}
          onChooseSeat={(table) => {
            setTablesModalIsOpen(false);
            setSelectedTable(table);
          }}
        />
      )}
      {selectedTable && Boolean(selectedTable) && (
        <ChooseSeatModal
          variant={props.variant}
          player={props.player}
          isOpen={Boolean(selectedTable)}
          table={selectedTable}
          onCancel={() => setSelectedTable(null)}
          onChooseSeat={() => {
            setSelectedTable(null);
            props.closeAction();
          }}
        />
      )}
      {isReturnToGameModalOpen && (
        <ReseatPlayerTablesModal
          isOpen={isReturnToGameModalOpen}
          toggle={toggleReturnToGameModal}
          variant={props.variant}
          selectedPlayer={props.player}
          favoriteSeats={props.player.seatNum ? [props.player.seatNum] : undefined}
          setNewSeat={(table, seatNum) => {
            performReturnToGame(table, seatNum);
          }}
        />
      )}
    </>
  );
};

export default PlayerDetail;
