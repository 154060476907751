import React from 'react';
import {
  ToggleButton, ToggleButtonGroup,
} from 'react-bootstrap';

export const SeatingPreferences = (props) => {
    const onClick = (state) => {
      if(props.disabled) {
        return
      } else {
        props.onChange(state)
      }
    };

  return (<ToggleButtonGroup
    className="custom-toggle mt-1"
    name="value"
    type="radio"
    value={props.value}
  >
    <ToggleButton  type="checkbox" value="automatic" active={props.value === "automatic"} enabled={false} disabled={props.disabled || false} onClick={() => {onClick("automatic");}} className="disabled-dark" >
      Automatic seating
    </ToggleButton>
    <ToggleButton type="checkbox" value="floorman" active={props.value === "floorman"} enabled={false} disabled={props.disabled || false} onClick={() => {onClick("floorman");}} className="disabled-dark" >
      Seating by florman
    </ToggleButton>
  </ToggleButtonGroup>)
}

export default SeatingPreferences;
