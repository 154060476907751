import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-mini';
import CustomModal from '../../shared/CustomModal';
import useVariantStats from '../../shared/useVariantStats';
import useTournamentStats from '../../shared/useTournamentStats';
import { getTournamentTitle, isMultiday, resolveCurrentLevel } from '../../../util/tournamentUtil';
import { formatDate, formatTime } from '../../../util/stringUtil';
import { getPlayersByTournamentVariantIdState, getPlayersByTournamentId } from '../../../actions/playerActions';
import PlayerListModal from '../../cashier/PlayerListModal';

const Statistics = (props) => {
  const { stats } = props;
  return (
    <table className="table table-borderless modal-table mt-3">
      <thead>
        <th>Entries</th>
        <th>Unique entries</th>
        <th>Reentry</th>
      </thead>
      <tbody>
        <td>{!stats ? '-' : stats.buyInCount + stats.rebuyCount + stats.reentryCount}</td>
        <td>{!stats ? '-' : stats.buyInCount}</td>
        <td>{!stats ? '-' : stats.reentryCount}</td>
      </tbody>
      <thead>
        <th>Advanced players</th>
        <th>Money collected</th>
        <th>Fee</th>
      </thead>
      <tbody>
        <td>{!stats ? '-' : stats.numOfPlayers}</td>
        <td>{!stats ? '-' : stats.reentryPool + stats.buyInPool + stats.rebuyPool + stats.addonPool}</td>
        <td>{!stats ? '-' : stats.reentryFee + stats.buyInFee + stats.rebuyFee + stats.addonFee}</td>
      </tbody>
    </table>
  );
};

const VariantButtons = (props) => {
  const { tournament } = props;
  const { activeTab } = props;

  function chooseTab(variant) {
    props.onChooseTab(variant);
  }

  if (!isMultiday(tournament)) return null;
  return (
    <ButtonGroup className="btn-switch tournament-tab">
      <Button className="tab-button" variant="dark" active={!activeTab} onClick={() => chooseTab({})}>
        Total
      </Button>
      {tournament.tournamentPhases
        .flatMap((phase) => phase.variants)
        .map((variant) => (
          <Button className="tab-button" variant="dark" active={activeTab === variant.id} onClick={() => chooseTab(variant)}>
            {variant.name}
          </Button>
        ))}
    </ButtonGroup>
  );
};

const VariantInfo = (props) => {
  const { selectedVariant } = props;
  const { tournament } = props;

  function getVariantLeftTime(variant) {
    if (variant.currentLevel.level) {
      const startAt = variant.currentLevel.mStartAt;
      startAt.add((variant.currentLevel.durationInMin || 0) + (variant.currentLevel.extraTime || 0) + (variant.currentLevel.breakInMin || 0), 'minutes');
      let diff = variant.currentLevel.mEndAt.diff(startAt, 'seconds');
      const isNegative = diff < 0;
      diff *= isNegative ? -1 : 1;
      const duration = moment.duration(diff, 'seconds');
      const formattedDiff = `${isNegative ? '-' : ''} ${Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(':mm:ss')}`;

      return formattedDiff;
    }
    return '';
  }

  if (!selectedVariant) {
    const variants = tournament.tournamentPhases.flatMap((phase) => phase.variants);
    let lastVariant = variants[variants.length - 1];
    lastVariant = resolveCurrentLevel(lastVariant);
    return (
      <div className="finish-info mt-3">
        <span className="title">MTT finished at:</span>
        <span className="value">{`${formatDate(tournament.endAt)} at ${formatTime(tournament.endAt)}`}</span>
        {lastVariant.currentLevel.level && <span className="value">{`lvl ${lastVariant.currentLevel.level} at ${getVariantLeftTime(lastVariant)} left`}</span>}
      </div>
    );
  }
  const variant = resolveCurrentLevel(selectedVariant);
  if (variant.id) {
    return (
      <div className="finish-info mt-3">
        <span className="title">Variant finished at:</span>
        <span className="value">{`${formatDate(variant.currentLevel.mEndAt)} at ${formatTime(variant.currentLevel.mEndAt)}`}</span>
        <span className="value">{`lvl ${variant.currentLevel.level} at ${getVariantLeftTime(variant)} left`}</span>
      </div>
    );
  }
  return null;
};

const TournamentHistoryDetailModal = ({ open, toggle, tournament }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(null);
  const [playerListModalOpen, setPlayerListModalOpen] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [playersUrl, setPlayersUrl] = useState(`/player/payout/tournament/${tournament.id}?onlyInCash=false&returnAsExcel=true`);

  const tournamentStatistic = useTournamentStats(tournament.id);
  const variantStatistic = useVariantStats(selectedVariant.id, 'TournamentHistoryDetailModal');

  function playerListBntOnClick() {
    if (selectedVariant && activeTab) {
      dispatch(getPlayersByTournamentVariantIdState(tournament.id, activeTab));
    } else {
      dispatch(getPlayersByTournamentId(tournament.id, false));
    }
    setPlayerListModalOpen(true);
  }

  return (
    <CustomModal open={open} closeHandler={toggle} className="mtt-history-detail dark" dialogClassName="modal-small-width" title={getTournamentTitle(tournament)}>
      <VariantButtons
        tournament={tournament}
        activeTab={activeTab}
        onChooseTab={(variant) => {
          const variantId = variant ? variant.id : null;
          setSelectedVariant(variant);
          setActiveTab(variantId);

            if (variantId) {
                setPlayersUrl(`/player/tournament/${tournament.id}/tournamentvariant/${variantId}?returnAsExcel=true`)
            } else {
                setPlayersUrl(`/player/payout/tournament/${tournament.id}?onlyInCash=false&returnAsExcel=true`)
            }

        }}
      />
      <VariantInfo selectedVariant={selectedVariant} tournament={tournament} />
      {activeTab && <><Statistics stats={variantStatistic} /></>}
      {!activeTab && <><Statistics stats={tournamentStatistic} /></>}
      <div className="mt-3">
        <Button className="fit outline" onClick={playerListBntOnClick}>
          Player list
        </Button>
      </div>
      {playerListModalOpen && (
        <PlayerListModal
          isOpen
          tournament={tournament}
          toggle={() => setPlayerListModalOpen(false)}
          variant={selectedVariant}
          showChipCount
          playersUrl={playersUrl}
        />
      )}
    </CustomModal>
  );
};

export default TournamentHistoryDetailModal;
